import { Formik } from 'formik';
// import * as Yup from 'yup';
import { Box, Button, Grid } from '@material-ui/core';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EditAbout = ({ handleClose, fetchCommunityDetails }) => {
	const { id } = useParams();
	const { t } = useTranslation();

	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	const updateCommunity = async (data) => {
		var requestParams = {
			community: {
				id: Number(id),
				post_about: {
					data: data?.about,
					post_id: communityDetails?.post_about?.id,
				},
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateCommunity, requestParams);

			if (status === 200) {
				fetchCommunityDetails();
				setApiMessage('success', data?.message);
				handleClose();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	var post_content =
		communityDetails && communityDetails?.post_about?.PostLists.find((item) => item?.Item?.type === 'text');

	return (
		<Box>
			<Formik
				initialValues={{
					about: post_content ? post_content?.Item?.data : '',
				}}
				// validationSchema={Yup.object().shape({
				//   about: Yup.string().required(t('validation.required_message'))
				// })}
				onSubmit={(requestdata) => updateCommunity(requestdata)}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.about && errors.about)}
									fullWidth
									helperText={touched.about && errors.about}
									variant="filled"
									InputProps={{
										endAdornment: touched.about && errors.about ? <ErrorIcon /> : null,
									}}
									label={t('community.about')}
									placeholder={t('community.about')}
									className="matrix-textfield"
									size="small"
									name="about"
									multiline
									rows={10}
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.about}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<Box className="dialog-submit-button">
									<Button variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
