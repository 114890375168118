import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { TextField } from 'src/components/ui';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import './athelete.scss';
import { useTranslation } from 'react-i18next';

export const Athelete = ({ title, id, data, token, getMyProfile, closeDialog }) => {
	const { t } = useTranslation();

	const [check, setCheck] = useState(id || 0);

	const checkHandler = (value) => {
		setCheck(value);
	};

	let aboutVal = data?.UserData?.UserAboutData;
	let first_time = aboutVal ? false : true;

	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	let haveTypes = data?.data?.profile_types
		? typeList?.filter((obj) => {
				return data?.data?.profile_types.indexOf(obj?.id) !== -1;
		  })
		: [];

	return (
		<Box>
			<Formik
				initialValues={{
					about: first_time ? '' : aboutVal?.Post?.name || '',
				}}
				validationSchema={Yup.object().shape({
					about: Yup.string().required(t('validation.required_message')),
				})}
				onSubmit={async (val) => {
					let firstReuestData = {
						user_about: {
							name: val.about,
						},
					};

					let editRequestData = {
						user_about: {
							name: val.about,
							post_id: aboutVal?.post_id,
							type: Number(check || id),
						},
					};

					let requestData = first_time ? firstReuestData : editRequestData;

					try {
						const { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							setApiMessage('success', t('myprofile.profile_updated_success'));
							getMyProfile();
							closeDialog();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.about && errors.about)}
									fullWidth
									helperText={touched.about && errors.about}
									variant="filled"
									InputProps={{
										endAdornment: touched.about && errors.about ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.edit_about')}
									placeholder={t('myprofile.edit_about')}
									className="matrix-textfield"
									size="small"
									name="about"
									multiline
									rows={10}
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.about}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<Box className="selected-about-types">
									<List>
										{haveTypes?.map((type, index) => {
											return (
												<ListItem
													key={index}
													role={undefined}
													dense
													button
													onClick={() => {
														checkHandler(type?.id);
													}}
												>
													<ListItemIcon>
														<Checkbox
															icon={<CheckBoxIcon />}
															checkedIcon={<CheckedBoxIcon />}
															edge="start"
															checked={type?.id === Number(check)}
															disableRipple
															disableTouchRipple
															disableFocusRipple
														/>
													</ListItemIcon>
													<ListItemText primary={type?.name} />
												</ListItem>
											);
										})}
									</List>
								</Box>
							</Grid>

							<Grid item xs={12} md={12}>
								<Box className="dialog-submit-button">
									<Button variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
