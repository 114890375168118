import { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Avatar, Box, Button, Grid, Typography, Link, CircularProgress, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { DialogBox, ButtonGroups, ProfilePictureView, EditProfile } from 'src/components/ui';

import { ViewPortContext } from 'src/App';
import { URLS } from 'src/helpers/constants/urls';
// import { clearStorage } from 'src/helpers/context';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/tapology.svg';
import { ReactComponent as SnapchatIcon } from 'src/assets/images/icons/snapchat.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/twitch.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/linkedin.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/tiktok.svg';
import { ReactComponent as SmoothcompIcon } from 'src/assets/images/icons/smoothcomp.svg';
import { ReactComponent as AddConnectionIcon } from 'src/assets/images/icons/add-connection.svg';
import { ReactComponent as ConnectedIcon } from 'src/assets/images/icons/connected-icon.svg';
import { ReactComponent as ConnectPendingIcon } from 'src/assets/images/icons/connect-pending.svg';
// import { ReactComponent as ConnectedIconIcon } from 'src/assets/images/icons/connected-icon.svg';
import SherdogIcon from 'src/assets/images/icons/bsherdog.png';
import { CFFC } from './CFFC';
import { EditSocialLink } from './EditSocialLink';
import { EditBio } from './EditBio';
import './right.scss';
// eslint-disable-next-line import/order
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	button: {
		width: '100%',
		'@media (max-width: $mobileBreakPoint)': {
			width: '49%',
		},
	},
});

var linksObj = {
	facebook: ' https://www.facebook.com',
	instagram: 'https://www.instagram.com',
	twitter: 'https://twitter.com',
	tapology: 'https://tapology.com/fightcenter/fighters',
	snapchat: 'https://www.snapchat.com',
	youtube: 'https://youtube.com',
	twitch: 'https://twitch.tv',
	linkedin: 'https://www.linkedin.com/in',
	tiktok: 'https://www.tiktok.com/@',
	sherdog: 'https://sherdog.com/fighter/',
	smoothcomp: 'https://smoothcomp.com/en/profile',
};

// SOCIAL MEDIA ICON HANDLER
function SocialIconHandler(data) {
	var checkForSlash = data?.data?.charAt(0) === '/' ? true : false;

	switch (data?.type) {
		case 'URL_facebook':
			var socialLink = checkForSlash ? linksObj?.facebook + data?.data : linksObj?.facebook + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<FaceBookIcon />
				</Link>
			);
		case 'URL_instagram':
			socialLink = checkForSlash ? linksObj?.instagram + data?.data : linksObj?.instagram + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<InstagramIcon />
				</Link>
			);
		case 'URL_twitter':
			socialLink = checkForSlash ? linksObj?.twitter + data?.data : linksObj?.twitter + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitterIcon />
				</Link>
			);
		case 'URL_tapology':
			socialLink = checkForSlash ? linksObj?.tapology + data?.data : linksObj?.tapology + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TapologyIcon />
				</Link>
			);
		case 'URL_snapchat':
			socialLink = checkForSlash ? linksObj?.snapchat + data?.data : linksObj?.snapchat + '/add/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SnapchatIcon />
				</Link>
			);
		case 'URL_youtube':
			socialLink = checkForSlash ? linksObj?.youtube + data?.data : linksObj?.youtube + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<YouTubeIcon />
				</Link>
			);
		case 'URL_twitch':
			socialLink = checkForSlash ? linksObj?.twitch + data?.data : linksObj?.twitch + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitchIcon />
				</Link>
			);
		case 'URL_linkedin':
			socialLink = checkForSlash ? linksObj?.linkedin + data?.data : linksObj?.linkedin + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<LinkedInIcon />
				</Link>
			);
		case 'URL_tiktok':
			socialLink = checkForSlash ? linksObj?.tiktok + data?.data : linksObj?.tiktok + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TikTokIcon />
				</Link>
			);
		case 'URL_sherdog':
			socialLink = checkForSlash ? linksObj?.sherdog + data?.data : linksObj?.sherdog + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<img src={SherdogIcon} alt="sherdog-icon" />
				</Link>
			);
		case 'URL_smoothcomp':
			socialLink = checkForSlash ? linksObj?.smoothcomp + data?.data : linksObj?.smoothcomp + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SmoothcompIcon />
				</Link>
			);
		default:
		// socialLink = checkForSlash
		//   ? linksObj?.facebook + data?.data
		//   : linksObj?.facebook + '/' + data?.data;
		// return (
		//   <Link href={socialLink} target="_blank">
		//     <FaceBookIcon />
		//   </Link>
		// );
	}
}

export const Right = ({
	isView,
	ProfileAvatar,
	getMyProfile,
	user,
	token,
	loader,
	badgeLoader,
	badges,
	connectionCFFCLoader,
	followingCFFCLoader,
	followersCFFCLoader,
	fightLoader,
	followingCFFC,
	followersCFFC,
	connectionsCFFC,
	fightData,
	fetchUserFight,
	fightTotalRecord,
	handlePaginationButtonOnClick,
	handlePaginationOnClick,
	recentPage,
	fightLimit,
	communitiesList,
	location,
}) => {
	const { id } = useParams(); // USER ID
	const navigate = useNavigate();
	const classes = useStyles();
	const viewPortContext = useContext(ViewPortContext);
	const { t } = useTranslation();
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	// USERNAME
	let userName = user?.UserMetaData?.filter((data) => data?.type === 'other')[0]?.data;

	const [isModalLoading, setIsModalLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [model, setModel] = useState('');
	const [followLoader, setFollowLoader] = useState(false);
	const [connectionLoader, setConnectionLoader] = useState(false);
	const [blockLoader, setBlockLoader] = useState(false);
	const [unblockLoader, setUnblockLoader] = useState(false);
	const [rejectLoader, setRejectLoader] = useState(false);
	const [youFollow, setYouFollow] = useState(false);
	const [blockedByUser, setBlockedByUser] = useState(false);
	const [youBlocked, setYouBlocked] = useState(false);
	const [youConnected, setYouConnected] = useState(false);
	const [connectionRecieved, setConnectionRecieved] = useState(false);
	const [connectionPending, setConnectionPending] = useState(false);
	const [readMore, setReadMore] = useState(false);

	// DIALOG HANDLERS
	const combatDialogHandler = (type, title, modal) => {
		setOpen(true);
		setTitle(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	const onDialogTransitionEnd = () => setIsModalLoading(false);

	// AUTHORIZATION HEADER
	let authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// FOR BLOCK-ACCEPT-REJECT LOADERS
	const connectionLoaderHandler = (type, value) => {
		switch (type) {
			case 'accept':
				return setConnectionLoader(value);
			case 'reject':
				return setRejectLoader(value);
			case 'block':
				return setBlockLoader(value);
			case 'unblock':
				return setUnblockLoader(value);
			default:
				return '';
		}
	};

	// FOR REMOVE-UNBLOCK-CANCEL LOADERS
	const connectionRemoveLoaderHandler = (type, value) => {
		switch (type) {
			case 'remove':
				return setConnectionLoader(value);
			// case 'unblock':
			//   return setUnblockLoader(value);
			case 'cancel':
				return setConnectionLoader(value);
			default:
				return '';
		}
	};

	// CONNECTION ACCEPT-REJECT-BLOCK
	const connectionChangeHandler = async (type) => {
		connectionLoaderHandler(type, true);

		try {
			let requestData = {
				status:
					type === 'accept'
						? 'accepted'
						: type === 'reject'
						? 'rejected'
						: type === 'block'
						? 'blocked'
						: type === 'unblock'
						? 'unblock'
						: '',
				user_friend_id: user?.data?.id,
			};
			let { status } = await axiosInstance.put(URLS.connectionRequestUpdate, requestData, authHeader);

			if (status === 200) {
				getMyProfile();
				connectionLoaderHandler(type, false);
			}
		} catch (error) {
			connectionLoaderHandler(type, false);
		}
	};

	// FOR CONNECTION REMOVE-REQUEST_CANCEL
	const removeConnectionHandler = async (type) => {
		connectionRemoveLoaderHandler(type, true);

		try {
			let { status } = await axiosInstance.delete(
				URLS.deleteConnection + `?user_friend_id=${user?.data?.id}`,
				authHeader,
			);

			if (status === 200) {
				getMyProfile();
				connectionRemoveLoaderHandler(type, false);
			}
		} catch (error) {
			connectionRemoveLoaderHandler(type, false);
		}
	};

	// FOR ADD NEW CONNECTION
	const addConnection = async () => {
		setConnectionLoader(true);
		const requestData = { user_friend_id: user?.data?.id };

		try {
			let { status } = await axiosInstance.post(URLS.addConnection, requestData, authHeader);

			if (status === 200) {
				setConnectionLoader(false);
				getMyProfile();
			}
		} catch (error) {
			setConnectionLoader(false);
		}
	};

	// FOLLOW USER
	const followUser = async () => {
		setFollowLoader(true);
		let requestData = { user_following_id: user?.data?.id };

		try {
			let { status } = await axiosInstance.post(URLS.doFollow, requestData, authHeader);

			if (status === 200) {
				setFollowLoader(false);
				setYouFollow(true);
				getMyProfile();
			}
		} catch (error) {
			setFollowLoader(false);
		}
	};

	const unFollowUser = async () => {
		setFollowLoader(true);
		let requestData = { user_following_id: user?.data?.id };

		try {
			let { status } = await axiosInstance.post(URLS.doUnfollow, requestData, authHeader);

			if (status === 200) {
				setFollowLoader(false);
				setYouFollow(false);
				getMyProfile();
			}
		} catch (error) {
			setFollowLoader(false);
		}
	};

	// USER DATAS
	let userData = user?.data;
	let socialMeta = user?.UserMetaData?.filter((data) => data?.data !== '');
	let statistics = user?.UserStatisticsDetails;
	let userBio = user?.UserData?.UserBioData?.PostLists[0]?.Item?.data;

	useEffect(() => {
		setYouFollow(user?.isFollow); // SET YOU FOLLOWING OR NOT

		// REQUEST RECIEVED
		if (user?.isFriendStatus === 'Received') {
			setConnectionRecieved(true);
		} else {
			setConnectionRecieved(false);
		}

		// CONNECTION ACCEPTED
		if (user?.isFriendStatus === 'accepted') {
			setYouConnected(true);
		} else {
			setYouConnected(false);
		}

		// YOU BLOCKED BY USER
		if (user?.isFriendStatus === 'blackFromUser') {
			setBlockedByUser(true);
		} else {
			setBlockedByUser(false);
		}

		// YOU BLOCKED THIS USER
		if (user?.isFriendStatus === 'blackUser') {
			setYouBlocked(true);
		} else {
			setYouBlocked(false);
		}

		// ALREADY REQUEST SENT
		if (user?.isFriendStatus === 'sent') {
			setConnectionPending(true);
		} else {
			setConnectionPending(false);
		}

		// eslint-disable-next-line
	}, [user?.isFollow, user?.isFriend, user?.isFriendStatus]);

	// WHEN ID CHANGE ALL DATAS ARE SET AS DEFAULT
	useEffect(() => {
		setYouFollow(false);
		setBlockedByUser(false);
		setYouBlocked(false);
		setYouConnected(false);
		setConnectionPending(false);
		setConnectionRecieved(false);

		// eslint-disable-next-line
	}, [id]);

	const bioText = readMore
		? user?.UserData?.UserBioData?.PostLists[0]?.Item?.data
		: user?.UserData?.UserBioData?.PostLists[0]?.Item?.data.substring(0, 100 - 3) + '...';

	let buttonGroups = {
		accepted: [
			{
				title: t('remove_connection'),
				clickFn: token
					? !connectionLoader
						? () => removeConnectionHandler('cancel')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
			{
				title: t('block'),
				clickFn: token
					? !blockLoader
						? () => connectionChangeHandler('block')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
		],
		received: [
			{
				title: t('accept'),
				clickFn: token
					? !connectionLoader
						? () => connectionChangeHandler('accept')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
			{
				title: t('reject'),
				clickFn: token
					? !rejectLoader
						? () => connectionChangeHandler('reject')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
			{
				title: t('block'),
				clickFn: token
					? !blockLoader
						? () => connectionChangeHandler('block')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
		],
		sent: [
			{
				title: t('revoke_connection'),
				clickFn: token
					? !connectionLoader
						? () => removeConnectionHandler('cancel')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
			{
				title: t('block'),
				clickFn: token
					? !blockLoader
						? () => connectionChangeHandler('block')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
		],
		addConnect: [
			{
				title: t('add_connection'),
				clickFn: token
					? !connectionLoader
						? addConnection
						: () => {}
					: () => {
							navigate('/');
					  },
			},
			{
				title: t('block'),
				clickFn: token
					? !blockLoader
						? () => connectionChangeHandler('block')
						: () => {}
					: () => {
							navigate('/');
					  },
			},
		],
	};

	const domContent = (
		<>
			<Box className="my-right-top">
				<Box className="my-right-img">
					<Box className="my-right-avatar">
						<Box className="right-img">
							{loader ? (
								<Skeleton variant="circle" width={115} height={115} animation="wave" />
							) : isView ? (
								<Avatar
									style={{ cursor: 'pointer' }}
									onClick={() => {
										combatDialogHandler(
											'view',
											`${userData?.first_name ? userData?.first_name : ''} ${
												userData?.last_name ? userData?.last_name : ''
											}${!userData?.first_name && !userData?.last_name ? 'Combat Matrix User' : ''}`,
										);
										setModel('dp-view');
									}}
									src={userData?.user_profile ? userData?.user_profile : UserPlaceholderIcon}
								/> // VISITER VIEW PROFILE IMAGE
							) : (
								ProfileAvatar // OWNER IMAGE EDIT COMPONENT
							)}
						</Box>
					</Box>

					<Box className="myright-names">
						{/* NICKNAME */}
						<Typography className="myright-wildcard">
							{loader ? <Skeleton variant="text" /> : userName ? `"${userName}"` : ''}
						</Typography>

						<Typography className="right-username">
							{loader ? (
								<Skeleton variant="text" />
							) : (
								`${userData?.first_name ? userData?.first_name : ''} ${userData?.last_name ? userData?.last_name : ''}${
									!userData?.first_name && !userData?.last_name ? 'Combat Matrix User' : ''
								}`
							)}
						</Typography>

						<Typography className="member-since">
							{loader ? (
								<Skeleton variant="text" width={'100px'} />
							) : (
								`${t('myprofile.member_since')}: ${moment(userData?.timestamp_created).format('DD MMM YY')}`
							)}

							{/* {loader ? null : isView ? null : (
                <Button
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  className="logout-btn"
                  onClick={() => {
                    clearStorage();
                    window.location = '/';
                  }}
                >
                  Logout
                </Button>
              )} */}
						</Typography>

						<Box className="social-icons">
							<Grid container>
								{loader ? (
									<>
										{/* SOCIAL MEDIA LOADER SKELETON */}
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
									</>
								) : socialMeta ? (
									// USER SOCIAL MEDIA URLS
									socialMeta.length > 0 ? (
										socialMeta
											?.filter((data) => data?.type !== '')
											?.filter((data) => data?.type !== 'URI')
											?.filter((data) => data?.type !== 'other')
											?.filter((data) => data?.type !== 'weight_class')
											?.filter((data) => data?.type !== 'location_fighting')
											?.filter((data) => data?.type !== 'weight_low_kg')
											?.filter((data) => data?.type !== 'weight_high_kg')
											?.filter((data) => data?.type !== 'weight_low_lb')
											?.filter((data) => data?.type !== 'weight_high_lb')
											?.map((data, index) => {
												return (
													<Grid item className="social-link" key={index}>
														{/* <Link href={data?.data} target="_blank"> */}
														{SocialIconHandler(data)}
														{/* </Link> */}
													</Grid>
												);
											})
									) : null
								) : null}
							</Grid>
						</Box>
					</Box>
				</Box>

				{/* Following button, connection button, block button */}
				<Box className="profile-relation-btns">
					{/* FOLLOW BUTTON */}
					{isView ? (
						loader ? (
							<Skeleton variant="rect" height={'30px'} className={`profile-follow-btn`} />
						) : youFollow ? (
							// FOLLOWING BUTTON (REMOVE FRIEND)
							<Button
								fullWidth
								variant="contained"
								className="profile-following-btn"
								disabled={blockedByUser ? true : youBlocked ? true : false}
								onClick={!followLoader ? unFollowUser : () => {}}
							>
								{followLoader ? <CircularProgress size={18} color="secondary" /> : t('following')}
							</Button>
						) : (
							// FOLLOW BUTTON
							<Button
								fullWidth
								variant="contained"
								className={
									blockedByUser ? 'profile-following-btn' : youBlocked ? 'profile-following-btn' : 'profile-follow-btn'
								}
								disabled={blockedByUser ? true : youBlocked ? true : false}
								onClick={
									token
										? !followLoader
											? followUser
											: () => {}
										: () => {
												navigate('/');
										  }
								}
							>
								{followLoader ? <CircularProgress size={18} color="secondary" /> : t('follow')}
							</Button>
						)
					) : null}

					{/* CONNECTION BUTTON */}
					{isView ? (
						loader ? (
							<Box display="flex" justifyContent="space-between">
								<Skeleton variant="rect" style={{ width: '49%' }} height={'30px'} className="connection-have-btn" />
								<Skeleton variant="rect" style={{ width: '49%' }} height={'30px'} className="connection-have-btn" />
							</Box>
						) : blockedByUser ? (
							<Button
								disableElevation
								disableTouchRipple
								disableRipple
								disableFocusRipple
								variant="contained"
								className={`${classes.button} connection-have-btn`}
							>
								{t('user_blocked_you')}
							</Button>
						) : youBlocked ? (
							<Button
								variant="contained"
								className={`${classes.button} connection-have-btn`}
								onClick={
									token
										? !unblockLoader
											? () => connectionChangeHandler('unblock')
											: () => {}
										: () => {
												navigate('/');
										  }
								}
							>
								{unblockLoader ? <CircularProgress size={18} color="secondary" /> : t('unblock')}
							</Button>
						) : // Connection button group
						windowWidth > breakPoint ? (
							<Box display="flex" justifyContent="space-between">
								{connectionRecieved ? (
									// CONNECTION REQUEST RECIEVED
									<Button
										style={{ width: '32%' }}
										variant="contained"
										className="connection-send-btn"
										onClick={
											token
												? !connectionLoader
													? () => connectionChangeHandler('accept')
													: () => {}
												: () => {
														navigate('/');
												  }
										}
									>
										{connectionLoader ? <CircularProgress size={18} color="secondary" /> : t('accept')}
									</Button>
								) : connectionPending ? (
									// CONNECTION REQUEST IS SENDED
									<Button
										style={{ width: '49%' }}
										variant="contained"
										className="connection-have-btn"
										onClick={
											token
												? !connectionLoader
													? () => removeConnectionHandler('cancel')
													: () => {}
												: () => {
														navigate('/');
												  }
										}
									>
										{connectionLoader ? <CircularProgress size={18} color="secondary" /> : t('peding_connection')}
									</Button>
								) : youConnected ? (
									// CONNECTION SUCCESSFULLY CONNECTED
									<Button
										style={{ width: '49%' }}
										variant="contained"
										className="connection-have-btn"
										onClick={
											token
												? !connectionLoader
													? () => removeConnectionHandler('remove')
													: () => {}
												: () => {
														navigate('/');
												  }
										}
									>
										{connectionLoader ? <CircularProgress size={18} color="secondary" /> : t('remove_connection')}
									</Button>
								) : (
									// ADD NEW CONNECTION BUTTON
									<Button
										style={{ width: '49%' }}
										variant="contained"
										className="connection-send-btn"
										startIcon={!connectionLoader ? <AddConnectionIcon /> : null}
										onClick={
											token
												? !connectionLoader
													? addConnection
													: () => {}
												: () => {
														navigate('/');
												  }
										}
									>
										{connectionLoader ? <CircularProgress size={18} color="secondary" /> : t('add_connection')}
									</Button>
								)}
								{/* CONNECTION REQUEST REJECT BUTTON */}
								{connectionRecieved ? (
									<Button
										style={{ width: '32%' }}
										variant="contained"
										className="connection-have-btn"
										onClick={
											token
												? !rejectLoader
													? () => connectionChangeHandler('reject')
													: () => {}
												: () => {
														navigate('/');
												  }
										}
									>
										{rejectLoader ? <CircularProgress size={18} color="secondary" /> : t('reject')}
									</Button>
								) : null}
								{/* Block Button */}
								<Button
									style={{ width: connectionRecieved ? '32%' : '49%' }}
									variant="contained"
									className="connection-have-btn blue-button"
									onClick={
										token
											? !blockLoader
												? () => connectionChangeHandler('block')
												: () => {}
											: () => {
													navigate('/');
											  }
									}
								>
									{blockLoader ? <CircularProgress size={18} color="secondary" /> : t('block')}
								</Button>
							</Box>
						) : (
							<Box className={'profile-btn-group-container'}>
								{connectionRecieved ? (
									<ButtonGroups
										title={t('connection_pending')}
										content={buttonGroups.received}
										className={'btngroup-can-click'}
										disabled={false}
										startIcon={<ConnectPendingIcon />}
									/>
								) : connectionPending ? (
									<ButtonGroups
										title={t('connection_pending')}
										content={buttonGroups.sent}
										className={'btngroup-cannot-click'}
										disabled={false}
										startIcon={<ConnectPendingIcon />}
									/>
								) : youConnected ? (
									<ButtonGroups
										title={t('connected')}
										content={buttonGroups.accepted}
										className={'btngroup-can-click'}
										disabled={false}
										startIcon={<ConnectedIcon />}
									/>
								) : (
									<ButtonGroups
										title={t('add_connection')}
										content={buttonGroups.addConnect}
										className={'btngroup-can-click'}
										disabled={false}
										startIcon={<AddConnectionIcon />}
									/>
								)}
							</Box>
						)
					) : null}
				</Box>

				{isView ? null : (
					<Box className="social-right-edit-actions">
						{loader ? (
							<Skeleton variant="rect" width={'49%'} className="skel-edit-ps" />
						) : (
							// SOCIAL MEDIA EDIT BUTTON
							<Button
								variant="contained"
								// className="edt-scl-lnkbtn"
								className="edt-profile-btn"
								startIcon={<SmallPencil color={'#fff'} />}
								// startIcon={<SmallPencil />}
								onClick={() => {
									combatDialogHandler('edit', t('myprofile.edit_social'));
									setModel('social');
								}}
							>
								{t('myprofile.edit_social')}
							</Button>
						)}
						{loader ? (
							<Skeleton variant="rect" width={'49%'} className="skel-edit-ps" />
						) : (
							// USER PROFILE EDIT BUTTON
							<Button
								variant="contained"
								className="edt-profile-btn"
								startIcon={<SmallPencil color={'#fff'} />}
								onClick={() => {
									combatDialogHandler('edit', t('myprofile.edit_profile'));
									setModel('profile');
								}}
							>
								{t('myprofile.edit_profile')}
							</Button>
						)}
					</Box>
				)}

				{/* USER FOLLOWRS COUNT */}
				<Box className="right-prof-counts">
					<Box className="social-count-bx">
						<Typography className="social-count">
							{loader ? <Skeleton variant="text" /> : statistics?.followers}
						</Typography>
						<Typography className="social-act-name">{t('myprofile.folowers')}</Typography>
					</Box>

					{/* USER CONNECTIONS COUNT */}
					<Box className="social-count-bx">
						<Typography className="social-count">
							{loader ? <Skeleton variant="text" /> : statistics?.connections}
						</Typography>
						<Typography className="social-act-name">{t('myprofile.connections')}</Typography>
					</Box>

					{/* USER FOLLOWING COUNT */}
					<Box className="social-count-bx">
						<Typography className="social-count">
							{loader ? <Skeleton variant="text" /> : statistics?.following}
						</Typography>
						<Typography className="social-act-name">{t('myprofile.following')}</Typography>
					</Box>

					{/* USER REFFERALS COUNT */}
					<Box className="social-count-bx">
						<Typography className="social-count">
							{loader ? <Skeleton variant="text" /> : statistics?.refferrals}
						</Typography>
						<Typography className="social-act-name">{t('myprofile.refferals')}</Typography>
					</Box>
				</Box>

				{/* USER BIO */}
				<Box className="bio-block">
					<Typography className="bio-title">{t('myprofile.bio')}</Typography>

					<Typography className="bio-text" style={{ textAlign: userBio ? 'left' : 'center' }}>
						{loader ? (
							<>
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
							</>
						) : userBio ? (
							<>
								{/* <Typography className="bio-description"> */}
								{bioText && // USER PROFILE TYPE ABOUT TEXT
									renderHTML(
										anchorme({
											input: bioText.toString().replace(/\n/g, '<br/>'),
											options: {
												attributes: {
													target: '_blank',
													class: 'detected',
												},
											},
										}),
									)}
								{/* </Typography> */}
								{Number(user?.UserData?.UserBioData?.PostLists[0]?.Item?.data?.length) > 100 ? (
									readMore ? (
										<Button
											disableElevation
											disableRipple
											disableTouchRipple
											disableFocusRipple
											className="readmore-btn"
											onClick={() => {
												setReadMore(false);
											}}
										>
											{t('read_less')}
										</Button>
									) : (
										<Button
											disableElevation
											disableRipple
											disableTouchRipple
											disableFocusRipple
											className="readmore-btn"
											onClick={() => {
												setReadMore(true);
											}}
										>
											{t('read_more')}
										</Button>
									)
								) : null}
							</>
						) : isView ? (
							<Typography className="no-info-txt">{t('myprofile.user_has_no_bio')}</Typography>
						) : (
							''
						)}
					</Typography>

					{isView ? null : loader ? (
						<Skeleton variant="rect" width={'100%'} style={{ marginTop: '18px' }} className="skel-edit-ps" />
					) : (
						<Button
							variant="contained"
							className="edit-bio-btn"
							startIcon={<SmallPencil color={'#fff'} />}
							onClick={() => {
								combatDialogHandler('edit', t('myprofile.edit_bio'));
								setModel('bio');
							}}
							style={{ marginTop: userBio ? '18px' : '0px' }}
						>
							{t('myprofile.edit_bio')}
						</Button>
					)}
				</Box>
			</Box>

			{/* USER BADGE */}
			{/* HIDE-MVP: CM-72 */}
			{/*<UserBadges isView={isView} badgeLoader={badgeLoader} badges={badges} />*/}

			{/* CONNECTIONS | FOLLOWRS | FOLLOWING | COMMUNITIES */}
			{/* {token ? ( */}
			<CFFC
				token={token}
				isView={isView}
				connectionLoader={connectionCFFCLoader}
				followingLoader={followingCFFCLoader}
				followersLoader={followersCFFCLoader}
				following={followingCFFC}
				followers={followersCFFC}
				connections={connectionsCFFC}
				user={user}
				communitiesList={communitiesList}
				location={location}
			/>
			{/* ) : null} */}

			{/* FIGHT RECORDS */}
			{/* HIDE-MVP: CM-72 */}
			{/*<FightRecord*/}
			{/*	isView={isView}*/}
			{/*	token={token}*/}
			{/*	fightData={fightData}*/}
			{/*	fightLoader={fightLoader}*/}
			{/*	fetchUserFight={fetchUserFight}*/}
			{/*	fightTotalRecord={fightTotalRecord}*/}
			{/*	handlePaginationButtonOnClick={handlePaginationButtonOnClick}*/}
			{/*	handlePaginationOnClick={handlePaginationOnClick}*/}
			{/*	recentPage={recentPage}*/}
			{/*	fightLimit={fightLimit}*/}
			{/*/>*/}
		</>
	);

	return (
		<>
			{/* CHECK THE WINDOW SIZE IS MOBILE OR DESKTOP */}
			{/* REMOVE THE BOX IF SIZE IS MOBILE AS EACH PART NEED TO BE REORDERED */}
			{windowWidth > breakPoint ? <Box className="my-profile-right">{domContent}</Box> : <>{domContent}</>}

			{/* ADD-EDIT FORM DIALOG */}
			<DialogBox
				onDialogTransitionEnd={onDialogTransitionEnd}
				open={open}
				handleClose={closeDialog}
				title={title}
				className={'user-profile-dialog'}
				content={
					model === 'profile' ? (
						// EDIT PROFILE
						<EditProfile
							setIsModalLoading={setIsModalLoading}
							isModalLoading={isModalLoading}
							userData={userData}
							user={user}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					) : model === 'social' ? (
						// EDIT SOCIALS
						<EditSocialLink
							setIsModalLoading={setIsModalLoading}
							isModalLoading={isModalLoading}
							data={socialMeta}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					) : model === 'bio' ? (
						// EDIT BIO
						<EditBio
							setIsModalLoading={setIsModalLoading}
							isModalLoading={isModalLoading}
							user={user}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					) : model === 'dp-view' ? (
						<ProfilePictureView src={userData?.user_profile ? userData?.user_profile : UserPlaceholderIcon} />
					) : (
						// EDIT PRODILE
						<EditProfile
							setIsModalLoading={setIsModalLoading}
							isModalLoading={isModalLoading}
							userData={userData}
							user={user}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					)
				}
			/>
		</>
	);
};

// EDIT PENCIL ICON COMPONENT (FOR DIFF COLORS)
const SmallPencil = ({ color }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="11.238" height="11.236" viewBox="0 0 11.238 11.236">
			<g id="Group_490" data-name="Group 490" transform="translate(0.5 0.609)">
				<path
					id="Path_99"
					data-name="Path 99"
					d="M3.754,10.425,10.678,3.5a1.448,1.448,0,0,1,2.048,2.048L5.8,12.473a1.132,1.132,0,0,1-.578.309L3,13.227,3.445,11A1.132,1.132,0,0,1,3.754,10.425Z"
					transform="translate(-3 -3.1)"
					fill="none"
					stroke={color ? '#d9e8f3' : '#9d9d9d'}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1"
				/>
				<path
					id="Path_100"
					data-name="Path 100"
					d="M14.5,6.5l1.7,1.7"
					transform="translate(-7.994 -4.576)"
					fill="none"
					stroke={color ? '#d9e8f3' : '#9d9d9d'}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1"
				/>
			</g>
		</svg>
	);
};
