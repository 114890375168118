import { useState, useEffect, useRef } from 'react';
import {
	List,
	Button,
	ListItem,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Box,
	Popover,
	Avatar,
	InputAdornment,
	Typography,
	Grid,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { TextField } from 'src/components/ui';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/community/arrow-down.svg';
import { useTranslation } from 'react-i18next';

export const LocationCheckmarks = ({
	onSelect,
	EVENT_FILTER,
	checked,
	count,
	labelValue,
	dropdownArray,
	formData,
	handleFormData,
}) => {
	const { t } = useTranslation();

	const formikRef = useRef();
	// const [checked, setChecked] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const [DDArray, setDDArray] = useState([]);
	const [searchAddress, SetSearchAddress] = useState('');
	const [city, setCity] = useState('');
	const [addrStored, setAddrStored] = useState(false);
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [lattitude, setLattitude] = useState('');
	const [longitude, setLongitude] = useState('');
	const handleToggle = (value) => () => {
		// checked === value ? setChecked('') : setChecked(value);
		onSelect(value);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setDDArray(dropdownArray);
	};
	const AddressHandler = (address) => {
		SetSearchAddress(address);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const HandleSelectAddress = (address) => {
		geocodeByAddress(address).then((results) => {
			setCity('');
			setState('');
			setLattitude('');
			setLongitude('');

			let address = results[0].address_components;
			let geoCode = results[0].geometry.location;
			let name = results[0].formatted_address;

			SetSearchAddress(name);
			setLattitude(geoCode.lat());
			setLongitude(geoCode.lng());

			address.map((val) => {
				return val.types.map((com) => {
					switch (com) {
						case 'country':
							setCountry(val.long_name);

							return val.long_name;
						case 'administrative_area_level_1':
							setState(val.long_name);

							return val.long_name;
						case 'administrative_area_level_2':
							setCity(val.long_name);

							return val.long_name;
						default:
							return val;
					}
				});
			});
		});
	};

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.setFieldValue('address', searchAddress);
			formikRef.current.setFieldValue('city', city);
			formikRef.current.setFieldValue('state', state);
			formikRef.current.setFieldValue('country', country);
		}
	}, [searchAddress, city, state, country]);
	useEffect(() => {
		setDDArray(dropdownArray);
	}, [dropdownArray]);
	useEffect(() => {
		if (!checked.includes('venue')) {
			SetSearchAddress('');
			setLattitude('');
			setLongitude('');
		}
	}, [checked]);
	// useEffect(() => {
	//     checked !== undefined && onSelect(checked);
	// }, [checked]);
	useEffect(() => {
		if (EVENT_FILTER?.queryParams?.locationName && !addrStored) {
			SetSearchAddress(EVENT_FILTER?.queryParams?.locationName);
			HandleSelectAddress(EVENT_FILTER?.queryParams?.locationName);
			setAddrStored(true);
		}
	}, [EVENT_FILTER]);
	useEffect(() => {
		if (searchAddress === '') {
			setLattitude('');
			setLongitude('');
		}
	}, [searchAddress]);

	return (
		<>
			<Button
				disableElevation
				disableTouchRipple
				disableRipple
				disableFocusRipple
				style={{ width: '100%' }}
				variant="contained"
				endIcon={<ArrowDown className={open ? 'toggle-down' : 'toggle-up'} />}
				startIcon={count > 0 && <Avatar className="circle-blue">{count}</Avatar>}
				className="filter-button"
				onClick={handleClick}
			>
				{labelValue}
			</Button>
			<Popover
				className="bcdp-popover"
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box className="checkmark-dropdown">
					<Box className="dropdown-header-sec">
						<Typography className="label-header">{labelValue}</Typography>
						{/* <Typography className='label-text'>
                            {t('search.select_unit')}
                        </Typography> */}
					</Box>
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						{DDArray.map((value, index) => {
							const labelId = `checkbox-list-label-${index}`;

							return (
								<ListItem onClick={handleToggle(value?.id)} key={index} disablePadding>
									{/* <ListItemButton role={undefined} onClick={handleToggle(value)} dense> */}
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={checked.indexOf(value?.id) !== -1}
											// checked={value?.id === checked ? true : false}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={value?.name} />
									{/* </ListItemButton> */}
								</ListItem>
							);
						})}
					</List>
					{checked && checked.includes('venue') && (
						<Box className="lower-upper-values">
							<Formik
								innerRef={formikRef}
								initialValues={{
									location: formData?.location ? formData?.location : '',
								}}
								validationSchema={Yup.object().shape({})}
								onSubmit={(requestdata) => {
									var req = {
										lat: lattitude,
										long: longitude,
										requestdata: requestdata,
									};

									handleFormData(req);
									setAnchorEl(null);
									setAddrStored(false);
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} className="text-field-grid">
												<PlacesAutocomplete
													value={searchAddress}
													onChange={AddressHandler}
													onSelect={HandleSelectAddress}
												>
													{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
														<div>
															<TextField
																error={Boolean(touched.address && errors.address)}
																fullWidth
																helperText={touched.address && errors.address}
																variant="filled"
																InputProps={{
																	endAdornment: touched.address && errors.address ? <ErrorIcon /> : null,
																}}
																label={t('personal_details.address')}
																placeholder={t('personal_details.address')}
																className="matrix-textfield"
																size="small"
																name="address"
																{...getInputProps()}
																onBlur={handleBlur}
															/>
															<div className="autocomplete-dropdown-container">
																{suggestions.map((suggestion, index) => {
																	const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
																	// inline style for demonstration purpose
																	const style = suggestion.active
																		? {
																				backgroundColor: '#fff',
																				color: '#000',
																				cursor: 'pointer',
																		  }
																		: {
																				backgroundColor: 'transparent',
																				cursor: 'pointer',
																		  };

																	return (
																		<div
																			key={index}
																			{...getSuggestionItemProps(suggestion, {
																				className,
																				style,
																			})}
																		>
																			<MenuItem
																				disableGutters
																				dense
																				style={{
																					border: '1px solid #f2f2f2',
																					paddingLeft: '10px',
																					paddingRight: '10px',
																				}}
																			>
																				{suggestion.description}
																			</MenuItem>
																		</div>
																	);
																})}
															</div>
														</div>
													)}
												</PlacesAutocomplete>
											</Grid>
											<Grid item xs={12} md={12} className="apply-filter-sec">
												<Button variant="contained" type="submit" className="filter-btn">
													{t('button_text.submit')}
												</Button>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>
						</Box>
					)}
				</Box>
			</Popover>
		</>
	);
};
