import { getUserStatistics } from 'src/redux-state/actions/userProfile';
import { connect } from 'react-redux';
import { Notification as NotificationComponent } from './Notification';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserStatistics: (data) => dispatch(getUserStatistics(data)),
	};
};

export const Notification = connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
