import React from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { Right } from './Right';
import { Left } from './Left';
import './news-feed.scss';
import { Page } from 'src/components/ui';
import NewsFeedImage from '../../../assets/images/seo/newsfeed.png';

export const NewsFeed = () => {
	const auth = useSelector((state) => state.Combat.authData);
	const user = auth ? auth : fetchFromStorage('authData');
	const token = fetchFromStorage('authData')?.token;

	// const fetchNewsfeedPostList = async (orderBy, vStatus) => {
	//   try {
	//     const { status, data } = await axiosInstance.get(URLS.getNewsfeedPostList, {
	//       params: {
	//         order_by: orderBy,
	//         view_status: vStatus,
	//         page: 1,
	//         limit: 5
	//       },
	//     });
	//     if (status === 200) {
	//       getNewsfeedPostList(data?.data);
	//       setTotalRecords(data?.data?.totalRecords)
	//     }
	//   } catch (error) { }
	// }
	// useEffect(() => {
	//   fetchNewsfeedPostList('best', 'my');
	// }, []);

	return (
		<Page
			title="Newsfeed"
			description={
				'Newsfeed: Get the latest updates from your favorite athletes and participate in weekly competitions!'
			}
			image={NewsFeedImage}
		>
			<Box>
				<Container maxWidth="lg">
					<Box className="combat-container newsfeed-container">
						{/* <ComingSoon /> */}

						<Box className="newsfeed-grid-container">
							<Box className="newsfeed-right show-breakpoint">
								<Right />
							</Box>
							<Box className="newsfeed-left">
								<Left
									token={token}
									// fetchNewsfeedPostList={fetchNewsfeedPostList}
									user={user}
								/>
							</Box>
							<Box className="newsfeed-right hide-breakpoint">
								<Right />
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};
