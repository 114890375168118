/* eslint-disable linebreak-style */
import { useState, useEffect, useContext } from 'react';
import {
	Box,
	CircularProgress,
	Container,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Button,
	Typography,
	IconButton,
	Avatar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Page, DialogBox } from 'src/components/ui';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/smallBEdit.svg';

import { BadgeForm } from './BadgeForm';
import { BadgeCategoryForm } from './BadgeCategoryForm';
import './badge.scss';

export const Badge = () => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState();
	const [model, setModel] = useState('');
	const [loader, setLoader] = useState(false);
	const [itemData, setItemData] = useState('');
	const [badgeList, setBadgeList] = useState([]);

	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		fetchBadgeList();
	}, []);

	const combatDialogHandler = (heading) => {
		setOpen(true);
		setTitle(heading);
	};

	const closeDialog = () => {
		setOpen(false);
	};

	const fetchBadgeList = async () => {
		// setLoader(true);
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllBadgeList);

			if (status === 200) {
				// setLoader(false);
				setBadgeList(data?.getBadgesData);
			}
		} catch (error) {
			// setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const badgeTypeList = [
		// { id: 0, name: t('admin_panel.combat_badge.none') },
		{ id: 1, name: t('admin_panel.combat_badge.post') },
		{ id: 2, name: t('admin_panel.combat_badge.user') },
		{ id: 4, name: t('admin_panel.combat_badge.community') },
		{ id: 8, name: t('admin_panel.combat_badge.organisation') },
	];

	return (
		<Page title="Badges">
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-main">
					<Typography className="admin-page-haeding">{t('admin_panel.combat_badge.title')}</Typography>
					<Box>
						<Button
							variant="outlined"
							onClick={() => {
								combatDialogHandler(t('admin_panel.combat_badge.addbadge'));
								setModel('addbadge');
							}}
							className="mr-10"
						>
							{t('admin_panel.combat_badge.addbadge')}
						</Button>
						<Button
							variant="outlined"
							onClick={() => {
								combatDialogHandler(t('admin_panel.combat_badge.addbadge_category'));
								setModel('addbadgecategory');
							}}
						>
							{t('admin_panel.combat_badge.addbadge_category')}
						</Button>
					</Box>
					<Box className="admin-data-tables">
						{loader ? (
							<Box display="flex" justifyContent="center">
								<CircularProgress size={18} color="secondary" />
							</Box>
						) : (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>ID</TableCell>
											<TableCell>Name</TableCell>
											<TableCell>Description</TableCell>
											<TableCell>Icon Id</TableCell>
											<TableCell>Type</TableCell>
											<TableCell>Category Id</TableCell>
											<TableCell>Status</TableCell>
											<TableCell> </TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{badgeList.map((data, index) => {
											const badgeTypeArray = data?.type_val;
											const result = _.intersectionWith(badgeTypeList, badgeTypeArray, (o, num) => o.id === num);
											const badgeNameString = result
												.map(function (elem) {
													return elem.name;
												})
												.join(', ');

											return (
												// eslint-disable-next-line react/no-array-index-key
												<TableRow key={index}>
													<TableCell>{data?.id}</TableCell>
													<TableCell>{data?.name}</TableCell>
													<TableCell>{data?.description}</TableCell>
													<TableCell>
														{' '}
														<Avatar src={data?.item_icon_id} />
													</TableCell>
													<TableCell>{badgeNameString}</TableCell>
													<TableCell>{data?.badge_category_id}</TableCell>
													<TableCell>{data?.status}</TableCell>
													<TableCell align="right">
														<Box className="action-box">
															<IconButton
																disableTouchRipple
																disableRipple
																disableFocusRipple
																onClick={() => {
																	combatDialogHandler(t('admin_panel.combat_badge.update_badge'));
																	setModel('updatebadge');
																	setItemData(data);
																}}
															>
																<EditIcon />
															</IconButton>
														</Box>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Box>
				</Container>
			</Box>
			<DialogBox
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					model === 'addbadgecategory' ? (
						<BadgeCategoryForm />
					) : // <BadgeCategoryForm />
					model === 'addbadge' ? (
						<BadgeForm isUpdate={false} fetchBadgeList={fetchBadgeList} closeDialog={closeDialog} />
					) : model === 'updatebadge' ? (
						<BadgeForm isUpdate={true} itemData={itemData} fetchBadgeList={fetchBadgeList} closeDialog={closeDialog} />
					) : (
						<BadgeForm />
					)
				}
			/>
		</Page>
	);
};
