import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { Avatar, Button, Box, Typography, Grid, Link, IconButton } from '@material-ui/core';
import { fetchFromStorage } from 'src/helpers/context';
import { DialogBox, ProfilePictureView } from 'src/components/ui';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';

import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/tapology.svg';
import { ReactComponent as SnapchatIcon } from 'src/assets/images/icons/snapchat.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/twitch.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/linkedin.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/tiktok.svg';
import { ReactComponent as SmoothcompIcon } from 'src/assets/images/icons/smoothcomp.svg';
import { ReactComponent as VerifiedIcon } from 'src/assets/images/icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'src/assets/images/icons/Unverified User.svg';
import { ReactComponent as ShadowUserIcon } from 'src/assets/images/icons/shadow-user.svg';
import SherdogIcon from 'src/assets/images/icons/bsherdog.png';
import { setApiMessage } from 'src/helpers/commonFunctions';

// import { ReactComponent as AddConnection } from 'src/assets/images/icons/add-connection.svg';
// import { ReactComponent as ConnectedIcon } from 'src/assets/images/icons/connected-icon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';

var linksObj = {
	facebook: ' https://www.facebook.com',
	instagram: 'https://www.instagram.com',
	twitter: 'https://twitter.com',
	tapology: 'https://tapology.com/fightcenter/fighters',
	snapchat: 'https://www.snapchat.com',
	youtube: 'https://youtube.com',
	twitch: 'https://twitch.tv',
	linkedin: 'https://www.linkedin.com/in',
	tiktok: 'https://www.tiktok.com/@',
	sherdog: 'https://sherdog.com/fighter/',
	smoothcomp: 'https://smoothcomp.com/en/profile',
};

// SOCIAL MEDIA ICON HANDLER
function SocialIconHandler(data) {
	var checkForSlash = data?.data?.charAt(0) === '/' ? true : false;

	switch (data?.type) {
		case 'URL_facebook':
			var socialLink = checkForSlash ? linksObj?.facebook + data?.data : linksObj?.facebook + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<FaceBookIcon />
				</Link>
			);
		case 'URL_instagram':
			socialLink = checkForSlash ? linksObj?.instagram + data?.data : linksObj?.instagram + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<InstagramIcon />
				</Link>
			);
		case 'URL_twitter':
			socialLink = checkForSlash ? linksObj?.twitter + data?.data : linksObj?.twitter + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitterIcon />
				</Link>
			);
		case 'URL_tapology':
			socialLink = checkForSlash ? linksObj?.tapology + data?.data : linksObj?.tapology + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TapologyIcon />
				</Link>
			);
		case 'URL_snapchat':
			socialLink = checkForSlash ? linksObj?.snapchat + data?.data : linksObj?.snapchat + '/add/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SnapchatIcon />
				</Link>
			);
		case 'URL_youtube':
			socialLink = checkForSlash ? linksObj?.youtube + data?.data : linksObj?.youtube + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<YouTubeIcon />
				</Link>
			);
		case 'URL_twitch':
			socialLink = checkForSlash ? linksObj?.twitch + data?.data : linksObj?.twitch + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitchIcon />
				</Link>
			);
		case 'URL_linkedin':
			socialLink = checkForSlash ? linksObj?.linkedin + data?.data : linksObj?.linkedin + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<LinkedInIcon />
				</Link>
			);
		case 'URL_tiktok':
			socialLink = checkForSlash ? linksObj?.tiktok + data?.data : linksObj?.tiktok + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TikTokIcon />
				</Link>
			);
		case 'URL_sherdog':
			socialLink = checkForSlash ? linksObj?.sherdog + data?.data : linksObj?.sherdog + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<img src={SherdogIcon} alt="sherdog-icon" />
				</Link>
			);
		case 'URL_smoothcomp':
			socialLink = checkForSlash ? linksObj?.smoothcomp + data?.data : linksObj?.smoothcomp + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SmoothcompIcon />
				</Link>
			);
		default:
		// socialLink = checkForSlash
		//   ? linksObj?.facebook + data?.data
		//   : linksObj?.facebook + '/' + data?.data;
		// return (
		//   <Link href={socialLink} target="_blank">
		//     <FaceBookIcon />
		//   </Link>
		// );
	}
}

export const UserProfile = ({
	user,
	// ownerID,
	fetchUsers,
	currentPage,
	updateUserData,
	isSearchPage,
	userPage,
}) => {
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState({});
	const [readMore, setReadMore] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	let socialMeta = user?.authUser?.UserMetaData?.filter((data) => data?.data !== '');

	// DIALOG HANDLERS
	const combatDialogHandler = (data) => {
		setOpen(true);
		setTitle(data);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	// const headers = {
	//   headers: {
	//     Authorization: `Bearer ${token}`,
	//     'Content-Type': 'application/json'
	//   }
	// };

	// CLAIM UNCLAIM USER
	const claimUserProfile = async () => {
		var requestParams = {
			user_other_id: user?.user_following_id ? user?.user_following_id : user?.user_id,
			user_actual_id: fetchFromStorage('authData')?.user_id,
		};
		var claimStatus = user?.claim_status === 'suspended' || user?.claim_status === '' ? 'claim' : 'unclaim';

		try {
			const { status, data } = await axiosInstance.post(URLS.claimUserProfile + `?type=${claimStatus}`, requestParams);

			if (status === 200) {
				isSearchPage ? updateUserData('users', userPage, user) : fetchUsers(currentPage, user);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const bioText = user?.UserBioData
		? readMore
			? user?.UserBioData?.PostLists[0]?.Item?.data
			: user?.UserBioData?.PostLists[0]?.Item?.data.substring(0, 100 - 3) + '...'
		: '';

	var USER_NAME = user?.user_full_name;
	var DEFAULT_IMAGE = user?.item_profile_data ? user?.item_profile_data : UserPlaceholderIcon;
	var USER_IMAGE = user?.authUser?.item_profile_id;
	var FOLLOW_COUNT = user?.authUser?.UserStatisticsDetails?.followers;

	return (
		<Box className="connection-user">
			<Box className="user-image">
				{/* USER PROFILE PICTURE */}
				<Avatar
					onClick={() => {
						combatDialogHandler({
							name: USER_NAME,
							img: user?.item_profile_id ? user?.item_profile_id : UserPlaceholderIcon,
						});
					}}
					src={USER_IMAGE}
				/>
				<Box className="user-name">
					<NavLink
						to={`/user/shadow/${user?.user_following_id ? user?.user_following_id : user?.authUser?.user_id}`}
						// to={`/user/${user?.user_meta?.data !== ''
						//   ? user?.user_meta?.data
						//   : user?.user_id
						//   }`}
						className="username-icon"
					>
						<Typography className="name">{USER_NAME}</Typography>
						<IconButton className="user-icon">
							{user?.authUser?.type === 'user' && user?.authUser?.veritication_status === 'yes' ? (
								<VerifiedIcon />
							) : user?.authUser?.type === 'user' && user?.authUser?.veritication_status === 'no' ? (
								<UnverifiedIcon />
							) : user?.authUser?.type === 'user_other' ? (
								<ShadowUserIcon />
							) : (
								<></>
							)}
						</IconButton>
					</NavLink>
					<Typography className="user-dob">
						{t('connections.member_since')} {moment(user?.Member_Since).format('Do MMMM YYYY')}
					</Typography>
					<Box className="social-icons">
						<Grid container>
							{socialMeta
								? socialMeta.length > 0
									? socialMeta
											?.filter((data) => data?.type !== 'other')
											?.map((data, index) => {
												return (
													<Grid item className="social-link" key={index}>
														{SocialIconHandler(data)}
													</Grid>
												);
											})
									: null
								: null}
						</Grid>
					</Box>
				</Box>
			</Box>
			<Box className="border-gray tool-tip-conainer">
				<Button
					variant="contained"
					fullWidth
					className="user-f-btn"
					onClick={() => (token ? claimUserProfile() : navigate('/'))}
				>
					{user?.claim_status === 'suspended' ? 'Reclaim' : user?.claim_status === '' ? 'Claim' : 'Unclaim'}
				</Button>
			</Box>

			{/* SOCIAL STASTICS */}
			<Box className="right-prof-counts">
				<Box className="social-count-bx">
					<Typography className="social-count">
						{/* {user?.UserStatisticsDetails?.followers} */}
						{FOLLOW_COUNT}
					</Typography>
					<Typography className="social-act-name">{t('myprofile.folowers')}</Typography>
				</Box>
			</Box>
			{user?.UserBioData !== null && (
				<Box className="pt-14">
					<Typography className="bio-head">bio</Typography>
					<Typography className="bio-description">
						{bioText && // USER PROFILE TYPE ABOUT TEXT
							renderHTML(
								anchorme({
									input: bioText.toString().replace(/\n/g, '<br/>'),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
					</Typography>
					{Number(user?.UserBioData?.PostLists[0]?.Item?.data.length) > 100 ? (
						readMore ? (
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								className="readmore-btn"
								onClick={() => {
									setReadMore(false);
								}}
							>
								{t('read_less')}
							</Button>
						) : (
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								className="readmore-btn"
								onClick={() => {
									setReadMore(true);
								}}
							>
								{t('read_more')}
							</Button>
						)
					) : null}
				</Box>
			)}

			<DialogBox
				open={open}
				handleClose={closeDialog}
				title={title?.name}
				content={<ProfilePictureView src={title?.img} />}
			/>
		</Box>
	);
};
