import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
	palette: {
		background: {
			dark: '#ffffff',
			default: colors.common.white,
			paper: colors.common.white,
		},
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#fff',
		},
		text: {
			primary: colors.blueGrey[900],
			secondary: colors.blueGrey[600],
			white: '#fff',
		},
	},
	shadows,
	typography,
});

export default theme;
