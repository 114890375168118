import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';

export const setApiMessage = (type, message) => {
	// return () => {
	var commonProps = {
		position: 'bottom-center',
		autoClose: 5000,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		hideProgressBar: true,
		style: {
			background: '#438ac1',
			color: '#ffffff',
			minHeight: 'unset',
		},
	};

	switch (type) {
		case 'info':
			toast.info(message, commonProps);
			break;
		case 'success':
			toast.info(message, commonProps);
			break;
		case 'warning':
			toast.warning(message, commonProps);
			break;
		case 'error':
			toast.error(message, commonProps);
			break;
		default:
			break;
	}
	// toast.clearWaitingQueue();
	// };
};

export const removeHTML = (str) => {
	var tmp = document.createElement('DIV');

	tmp.innerHTML = str;

	return tmp.textContent || tmp.innerText || '';
};

export const getFields = (list, field) => {
	return list.reduce(function (carry, item) {
		if (typeof item === 'object' && field in item) {
			carry.push(item[field]);
		}

		return carry;
	}, []);
};
export function getIdFromUrl() {
	const url = window.location.pathname;
	const spliturl = url.split('/');
	const id = spliturl[spliturl.length - 1];

	return Number(id);
}
export const objectToQueryString = (obj) => {
	var str = [];

	for (var p in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, p)) {
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
		}
	}

	return str.join('&');
};
export const arrayJoin = (array1, array2, key) => {
	var intersection = array1.filter((e) => array2.indexOf(e.id) !== -1);
	var result = _.map(intersection, key).join(',');

	return result;
};
export const youTubeVideoCheck = (url) => {
	let flag = true;

	if (url != undefined || url != '') {
		const regExp =
			/^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*?(?:\/|v\/|u\/|embed\/|shorts\/|watch\?v=|(?<username>user\/))(?<id>[\w\-]{11})(?:\?|&|$)/;
		const match = url.match(regExp);

		if (match && match[2].length == 11) {
			flag = true;
		} else {
			flag = false;
		}
	}

	return flag;
};
