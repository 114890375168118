import { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Button, MenuItem, Chip, IconButton, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../badge.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import { identifiers } from 'src/helpers/constants/identifier';

export const BadgeForm = ({ itemData, fetchBadgeList, isUpdate, closeDialog }) => {
	const [badgeCategoryList, setBadgeCategory] = useState([]);
	const [badgeImage, setBadgeImage] = useState('');
	const [badgePicture, setBadgePicture] = useState('');
	const [loader, setLoader] = useState(false);
	const [selected, setSelected] = useState([]);
	// eslint-disable-next-line camelcase
	const { t } = useTranslation();

	const badgeTypeList = [
		// { id: 0, name: t('admin_panel.combat_badge.none') },
		{ id: 1, name: t('admin_panel.combat_badge.post') },
		{ id: 2, name: t('admin_panel.combat_badge.user') },
		{ id: 4, name: t('admin_panel.combat_badge.community') },
		{ id: 8, name: t('admin_panel.combat_badge.organisation') },
	];

	const handleToggle = (value) => {
		const currentIndex = selected.indexOf(value);
		const newSelected = [...selected];

		if (currentIndex === -1) {
			newSelected.push(value);
		} else {
			newSelected.splice(currentIndex, 1);
		}

		setSelected(newSelected);
	};

	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		getBadgeCategoryList();
	}, []);

	useEffect(() => {
		if (itemData?.item_icon_id !== undefined && itemData?.item_icon_id !== null) {
			let splitdata = itemData?.item_icon_id.split('/');

			splitdata = splitdata[splitdata.length - 1];
			setBadgePicture(splitdata);
		}

		isUpdate && setSelected(itemData?.type_val);
		// eslint-disable-next-line
	}, [itemData]);

	const getBadgeCategoryList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getBadgeCategory);

			if (status === 200) {
				setBadgeCategory(data?.getBadgeCategoryData);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const addBadge = async (data) => {
		setLoader(true);
		let types = selected.length > 0 && selected.reduce((a, b) => a + b);
		const requestParams = {
			...(isUpdate && { badgeId: itemData?.id }),
			name: data?.badgename,
			description: data?.badgedescription,
			...(badgeImage !== '' && { itemIconId: badgeImage?.id }),
			type: types,
			badgeCategoryId: data?.badgecategory,
			status: identifiers?.active,
		};
		const method = isUpdate ? 'put' : 'post';
		const apiEndPoint = isUpdate ? 'updateBadge' : 'createBadge';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				// setBadgeCategory(data?.getBadgeCategoryData);
				setLoader(false);
				setApiMessage('success', data?.message);
				fetchBadgeList();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setLoader(false);
		}
	};
	const ProfileAvatar = () => {
		const token = fetchFromStorage('authData')?.token;

		const imgHandler = async (e) => {
			e.persist();
			const formData = new FormData();

			if (e.target.files.length > 0) {
				const type = e.target.files[0].type.includes('image')
					? 'image'
					: e.target.files[0].type.includes('video')
					? 'video'
					: e.target.files[0].type.includes('text')
					? 'text'
					: e.target.files[0].type.includes('audio')
					? 'audio'
					: e.target.files[0].type.includes('blob')
					? 'blob'
					: '';

				formData.append('location', e.target.files[0]);
				formData.append('type', type);

				try {
					setLoader(true);
					const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					});

					if (status === 200) {
						setBadgeImage(data?.data);
						setBadgePicture(data?.data?.location);
						setApiMessage('success', data?.message);
						setLoader(false);
					}
				} catch (error) {
					setApiMessage('error', error?.response?.data?.message);
					setLoader(false);
				}
			}
		};

		return (
			<>
				<input
					accept="image/*"
					id="admin-badge-file"
					type="file"
					multiple
					onChange={imgHandler}
					style={{ display: 'none' }}
				/>
				<label htmlFor="admin-badge-file">
					<IconButton component="span" className="p-0">
						<Avatar src={badgePicture !== '' && URLS.mediaURL + badgePicture} />
						<CameraIcon className="camera-icon" />
					</IconButton>
				</label>
			</>
		);
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Typography className="admin-page-haeding">{/* {t('admin_panel.combat_users.title')} */}</Typography>
					<Box className="form-content">
						<ProfileAvatar />
						<Formik
							initialValues={{
								badgename: itemData?.name,
								badgedescription: itemData?.description,
								badgecategory: itemData?.badge_category_id,
								badgetype: itemData?.type_val,
							}}
							validationSchema={Yup.object().shape({
								badgename: Yup.string().required(t('validation.required_message')),
								badgedescription: Yup.string().required(t('validation.required_message')),
								badgecategory: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => addBadge(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.badgename && errors.badgename)}
												fullWidth
												helperText={touched.badgename && errors.badgename}
												variant="filled"
												placeholder={t('admin_panel.combat_badge.badgename')}
												label={t('admin_panel.combat_badge.badgename')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.badgename && errors.badgename ? <ErrorIcon /> : null,
												}}
												size="small"
												name="badgename"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.badgename}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.badgedescription && errors.badgedescription)}
												fullWidth
												helperText={touched.badgedescription && errors.badgedescription}
												variant="filled"
												placeholder={t('admin_panel.combat_badge.badgedescription')}
												label={t('admin_panel.combat_badge.badgedescription')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.badgedescription && errors.badgedescription ? <ErrorIcon /> : null,
												}}
												size="small"
												name="badgedescription"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.badgedescription}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.badgecategory && errors.badgecategory)}
												fullWidth
												helperText={touched.badgecategory && errors.badgecategory}
												variant="filled"
												InputProps={{
													endAdornment: touched.badgecategory && errors.badgecategory ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_badge.badgedecategory')}
												placeholder={t('admin_panel.combat_badge.badgedecategory')}
												className="matrix-textfield"
												size="small"
												name="badgecategory"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.badgecategory}
												select
											>
												{badgeCategoryList.map((obj, index) => {
													return (
														<MenuItem key={index} value={obj?.id}>
															{obj?.name}
														</MenuItem>
													);
												})}
											</TextField>
										</Grid>

										<Grid item xs={12} md={12}>
											<Box className="type-box">
												<Typography className="restriction-txt">{t('admin_panel.combat_badge.badge_type')}</Typography>
												<Box className="restriction-chips">
													<Grid container>
														{badgeTypeList?.map((data, index) => {
															return (
																<Grid item key={index}>
																	<Chip
																		size="small"
																		className={
																			selected.indexOf(data?.id) !== -1 ? 'rest-chip checked-chip' : 'rest-chip'
																		}
																		icon={selected.indexOf(data?.id) !== -1 ? <FilterCheckIcon /> : null}
																		label={data?.name}
																		onClick={() => handleToggle(data?.id)}
																		// onBlur={handleBlur}
																		// onChange={handleChange}
																	/>
																</Grid>
															);
														})}
													</Grid>
												</Box>
											</Box>
										</Grid>

										<Grid item xs={12} md={12}>
											<Button
												variant="contained"
												fullWidth
												type="submit"
												className="signup-button"
												onClick={closeDialog}
											>
												{isUpdate ? t('button_text.update') : t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};
