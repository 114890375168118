export const getQueryParams = () => {
	if (window) {
		const search = window.location.search;

		const paramsInString = search.replace('?', '').split('&');

		return paramsInString
			.map((item) => {
				return item.split('=');
			})
			.reduce((acc, item) => {
				acc[item[0]] = item[1];

				return acc;
			}, {});
	}

	return { email: '', otp: '' };
};
