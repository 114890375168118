import { Box, Fade } from '@material-ui/core';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import './UpdatePost.scss';

import { TextEditor } from 'src/components/ui';

export const UpdatePost = ({ user, postData, fetchPostDetails, cancelBtnClick, token, allUsersList }) => {
	const initialRequestData = {
		receiver_type: 'post',
	};

	const updateNewsfeedPost = async (requestParameter) => {
		try {
			const { status, data } = await axiosInstance.put(URLS.updatePost, requestParameter);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchPostDetails(postData.id);
				cancelBtnClick();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Fade in>
			<Box className="creating-post-update-box">
				<TextEditor
					selectedItem={''}
					submit={updateNewsfeedPost}
					user={user}
					token={token}
					postData={postData}
					cancelBtnClick={cancelBtnClick}
					allUsersList={allUsersList}
					initialRequestData={initialRequestData}
				/>
			</Box>
		</Fade>
	);
};
