import { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { DialogBox } from 'src/components/ui';
import { EditWelcome } from './EditWelcome';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/community/edit-white.svg';
import './welcome.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const Welcome = ({ isView, fetchCommunityDetails }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const combatDialogHandler = () => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	return (
		<Box className="welcome-box">
			<Typography className="welcome-title">{t('community.welcome')}</Typography>
			{communityDetails?.communityData?.description !== null &&
			communityDetails?.communityData?.description !== '' &&
			communityDetails?.communityData?.description !== undefined ? (
				<Typography className="welcome-text">{communityDetails?.communityData?.description}</Typography>
			) : (
				<Typography className="no-welcome-txt">{t('community.comm_nowlcm')}</Typography>
			)}
			{isView ? null : (
				<Button
					startIcon={<EditIcon />}
					variant="contained"
					fullWidth
					disabled={communityDetails?.communityData?.status === 'closed' ? true : false}
					className="edit-welcome-btn"
					onClick={combatDialogHandler}
				>
					{t('community.edit_welcome')}
				</Button>
			)}

			{/* <Typography className="no-welcome-txt">
        This Community has no welcome
      </Typography> */}

			<DialogBox
				open={open}
				handleClose={closeDialog}
				onDialogTransitionEnd={() => setIsLoading(false)}
				title={t('community.edit_welcome')}
				content={
					<EditWelcome
						handleClose={closeDialog}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						fetchCommunityDetails={fetchCommunityDetails}
					/>
				}
			/>
		</Box>
	);
};
