import { Box, Container, Typography } from '@material-ui/core';

import { Page } from 'src/components/ui';

import './dashboard.scss';

export const Dashboard = () => {
	return (
		<Page title="Badges">
			<Box className="admin-container-box">
				<Container maxWidth="lg">
					<Typography className="admin-page-haeding">Admin Dashboard</Typography>
				</Container>
			</Box>
		</Page>
	);
};
