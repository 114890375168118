import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './MessageLeft.scss';
import { Box, Typography, InputAdornment, withStyles, TextField, CircularProgress } from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { ReactComponent as WriteIcon } from 'src/assets/images/icons/write-icon.svg';
import { MessagePersons } from './MessagePersons';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const Left = ({
	handleCreateGroup,
	fetchGroupList,
	fetchMessageList,
	grpLoader,
	onUserChatClick,
	currentGroupChat,
	userListData,
}) => {
	// eslint-disable-next-line camelcase
	const { t } = useTranslation();

	return (
		<Box className="messages-left-side-sec">
			<Box className="messages-title-sec">
				<Typography className="message-text">{t('messages.Messages')}</Typography>
				<Typography className="write-icon">
					<WriteIcon
						className="svg-write-icon"
						onClick={() => {
							handleCreateGroup(t('messages.create_chat'));
						}}
					/>
				</Typography>
			</Box>
			<Box className="Messages-search-sec">
				<SearchTextField
					fullWidth
					placeholder="Search"
					size="small"
					variant="outlined"
					onChange={(e) => fetchGroupList(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Box>
			<Box className="message-person-details">
				{grpLoader ? (
					<CircularProgress color="#438ac1" className="loader-chat" />
				) : (
					<MessagePersons
						onUserChatClick={onUserChatClick}
						currentGroupChat={currentGroupChat}
						userListData={userListData}
						fetchMessageList={fetchMessageList}
						fetchGroupList={fetchGroupList}
					/>
				)}
			</Box>
		</Box>
	);
};
