import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const DeleteUser = ({ handleClose, handleClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="verify-user">
				<Typography className="modal-heading">{t('myprofile.delete_discipline')}</Typography>
				<Button variant="outlined" onClick={handleClose}>
					{t('button_text.cancel')}
				</Button>
				<Button variant="contained" onClick={handleClick}>
					{t('button_text.confirm')}
				</Button>
			</Box>
		</>
	);
};
