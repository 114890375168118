export const initialValue = [
	{
		type: 'paragraph',
		children: [
			{
				text: '',
			},
		],
	},
];
