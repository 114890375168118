import { useEffect, useState, useRef } from 'react';
import { Box, Grid, makeStyles, Button, Avatar, IconButton, Typography, MenuItem } from '@material-ui/core';
import { TextField } from 'src/components/ui';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as ProfileUploadIcon } from 'src/assets/images/icons/com-img-placeholder.svg';
// import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
// import globogym from 'src/assets/images/icons/globogym.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
// import { ReactComponent as DownIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import './form.scss';
import * as Yup from 'yup';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	input: {
		display: 'none',
	},
});

export const AddEditOrg = ({ fetchMyOrganisation, handleClose, currentPage, modal, orgData, searchText }) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const [organisation_type, setorganisationtype] = useState([]);
	// const organisation_type = ['gym', 'gym 1', 'gym 2', 'gym 3'];
	const [imageId, setImageId] = useState('');
	const [previewImg, setPreviewImg] = useState('');
	const [searchAddress, SetSearchAddress] = useState('');
	const [cityName, setCity] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [lattitude, setLattitude] = useState('');
	const [longitude, setLongitude] = useState('');
	const formikRef = useRef();

	const auth = useSelector((state) => state?.Combat?.authData) || fetchFromStorage('authData');

	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	// console.log(orgData, modal, "data fetch");

	const orgAbout = modal === 'updateOrg' && orgData && orgData?.PostLists?.find((data) => data?.Item?.type === 'text');

	const createOrganisation = async (data) => {
		// console.log(data, 'createOrganisation');
		var requestParams = {
			...(modal === 'updateOrg' && { id: orgData?.id }),
			name: data?.orgName,
			organisation_type_id: data?.organisationtype,
			item_profile_id: imageId !== '' ? imageId : 0,
			// item_cover_id: 1,
			post_about: {
				data: data?.about,
				...(modal === 'updateOrg' && { post_id: orgAbout?.post_id }),
			},
			address: {
				address: data?.address,
				lat: String(lattitude),
				long: String(longitude),
				city: cityName,
				state: state,
				country: data?.country,
			},
			// address: {
			//   ...(data?.address !== '' && { address: data?.address }),
			//   ...(String(lattitude) !== '' && { lat: String(lattitude) }),
			//   ...(String(longitude) !== '' && { long: String(longitude) }),
			//   ...(cityName !== '' && { city: cityName }),
			//   ...(state !== '' && { state: state }),
			//   ...(country !== '' && { country: country }),
			// }
		};
		const method = modal === 'updateOrg' ? 'put' : 'post';
		const apiEndPoint = modal === 'updateOrg' ? 'updateOrganisation' : 'createOrganisation';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchMyOrganisation(currentPage, searchText);
				handleClose();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const AddressHandler = (address) => {
		SetSearchAddress(address);
	};

	const ImageUploadHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setImageId(data?.data?.id);
				setPreviewImg(data?.data?.location);
			}
		}
	};

	const fetchorganisationTypeList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getOrganisationType);

			if (status === 200) {
				setorganisationtype(data?.organizationsTypes);

				// setorganisationtype(data?.organizationsTypes?.name);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchorganisationTypeList();
	}, []);

	useEffect(() => {
		const splitUrl = orgData?.item_profile_id?.split('/');

		SetSearchAddress(modal === 'updateOrg' && orgData?.address ? orgData?.address : '');
		setCity(modal === 'updateOrg' ? orgData?.city : '');
		setState(modal === 'updateOrg' ? orgData?.state : '');
		setCountry(modal === 'updateOrg' ? orgData?.country : '');
		setLattitude(modal === 'updateOrg' ? orgData?.lat : '');
		setLongitude(modal === 'updateOrg' ? orgData?.long : '');
		setPreviewImg(modal === 'updateOrg' ? splitUrl[splitUrl.length - 1] : '');
	}, [orgData]);
	// console.log('orgData', orgData?.item_profile_id, orgData?.item_profile_id?.split('/'), splitUrl[splitUrl.length - 1], orgData)
	const HandleSelectAddress = (address) => {
		geocodeByAddress(address).then((results) => {
			setCity('');
			setState('');
			setLattitude('');
			setLongitude('');

			let address = results[0].address_components;
			let geoCode = results[0].geometry.location;
			let name = results[0].formatted_address;

			SetSearchAddress(name);
			setLattitude(geoCode.lat());
			setLongitude(geoCode.lng());

			address.map((val) => {
				return val.types.map((com) => {
					switch (com) {
						case 'country':
							setCountry(val.long_name);

							return val.long_name;
						case 'administrative_area_level_1':
							setState(val.long_name);

							return val.long_name;
						case 'administrative_area_level_2':
							setCity(val.long_name);

							return val.long_name;
						default:
							return val;
					}
				});
			});
		});
	};

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.setFieldValue('address', searchAddress);
			formikRef.current.setFieldValue('city', cityName);
			formikRef.current.setFieldValue('state', state);
			formikRef.current.setFieldValue('country', country);
		}
		// eslint-disable-next-line
	}, [searchAddress, cityName, state, country]);

	return (
		<>
			<Formik
				innerRef={formikRef}
				initialValues={{
					orgName: modal === 'updateOrg' && orgData?.name ? orgData?.name : '',
					address: modal === 'updateOrg' && orgData?.address !== null ? orgData?.address : '',
					about: modal === 'updateOrg' && orgAbout?.Item?.data ? orgAbout?.Item?.data : '',
					organisationtype: modal === 'updateOrg' && orgData?.organisation_type_id ? orgData?.organisation_type_id : '',
				}}
				validationSchema={Yup.object().shape({
					orgName: Yup.string().required(t('validation.required_message')),
					address: Yup.string().required(t('validation.required_message')),
					// searchAddress: Yup.string().required(
					//   t('validation.required_message')
					// ),
					about: Yup.string().required(t('validation.required_message')),
					organisationtype: Yup.string().required(t('validation.required_message')),
				})}
				onSubmit={(requestdata) => createOrganisation(requestdata)}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container>
							{/* PROFILE PHOTO */}

							{/* <Grid item xs={12} md={12}> */}
							{/*  <Box className="organisation-form-img">
                <Box className="my-right-top">
                  <Box className="my-right-img">
                    <Box className="my-right-avatar">
                      <Box className="right-img">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          multiple
                          className={classes.input}
                        />
                        <label htmlFor="contained-button-file">
                          <IconButton component="span">
                            <Avatar src={UserPlaceholderIcon} />
                            <Avatar src={globogym} />
                            <CameraIcon className="camera-icon" />
                          </IconButton>
                        </label>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box> */}
							<Box className="profile-picture-box">
								{previewImg !== '' ? (
									<Box textAlign={'center'}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="icon-button-file"
											type="file"
											onChange={ImageUploadHandler}
										/>
										<label htmlFor="icon-button-file">
											<IconButton
												color="primary"
												aria-label="upload picture"
												component="span"
												style={{ position: 'relative' }}
											>
												<Avatar src={URLS.mediaURL + previewImg} alt="profile-picture" />
												<CameraIcon className="camera-icon" />
											</IconButton>
										</label>
									</Box>
								) : (
									<Box textAlign={'center'}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="icon-button-file"
											type="file"
											onChange={ImageUploadHandler}
										/>
										<label htmlFor="icon-button-file">
											<IconButton color="primary" aria-label="upload picture" component="span">
												<ProfileUploadIcon />
											</IconButton>
										</label>
									</Box>
								)}

								<Box className="profile-pic-recomandations">
									<Typography className="rec-heding">{t('profile_picture.img_rec')}</Typography>
									<Typography className="rec-types">{`.jpg ${t('or')} .png`}</Typography>
									<Typography className="rec-types">500x500</Typography>
									<Typography className="rec-types">{t('profile_picture.lessthan')}</Typography>
								</Box>
							</Box>

							{/* </Grid> */}

							{/* ORGANISATION TYPE FIELD */}
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.organisationtype && errors.organisationtype)}
									fullWidth
									helperText={touched.organisationtype && errors.organisationtype}
									variant="filled"
									InputProps={{
										endAdornment: touched.organisationtype && errors.organisationtype ? <ErrorIcon /> : null,
									}}
									label={t('organisation.organisation_type')}
									placeholder={t('organisation.organisation_type')}
									className="matrix-textfield"
									size="small"
									name="organisationtype"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.organisationtype}
									select
								>
									{organisation_type?.map((type, index) => {
										return (
											<MenuItem key={index} value={type?.id}>
												{type?.name}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>

							{/* ORGANISATION NAME FIELD */}
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									fullWidth
									variant="filled"
									error={Boolean(touched.orgName && errors.orgName)}
									inputProps={{
										maxLength: 200,
										endAdornment: touched.badgecategoryname && errors.badgecategoryname ? <ErrorIcon /> : null,
									}}
									// inputProps={{ maxLength: 200 }}
									label={t('organisation.organisation_name')}
									placeholder={t('organisation.organisation_name')}
									helperText={touched.orgName && errors.orgName}
									className="matrix-textfield"
									size="small"
									name="orgName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.orgName}
								/>
							</Grid>

							{/* ADDRESS FIELD */}
							{/* <Grid item xs={12} md={12} className="text-field-grid">
                <TextField
                  fullWidth
                  variant="filled"
                  inputProps={{ maxLength: 200 }}
                  label={t('organisation.organisation_address')}
                  placeholder={t('organisation.organisation_address')}
                  className="matrix-textfield"
                  size="small"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  onSelect={HandleSelectAddress}
                // value="123 Lane Street, Suburbville STATE 9999 Australia"
                />
              </Grid> */}

							<Grid item xs={12} md={12} className="text-field-grid">
								<PlacesAutocomplete value={searchAddress} onChange={AddressHandler} onSelect={HandleSelectAddress}>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<TextField
												error={Boolean(touched.address && errors.address)}
												fullWidth
												helperText={touched.address && errors.address}
												variant="filled"
												InputProps={{
													endAdornment: touched.address && errors.address ? <ErrorIcon /> : null,
												}}
												label={`${t('personal_details.address')}*`}
												placeholder={`${t('personal_details.address')}*`}
												className="matrix-textfield"
												size="small"
												name="address"
												{...getInputProps()}
												onBlur={handleBlur}
												InputLabelProps={{
													shrink: true,
												}}
											/>
											<div className="autocomplete-dropdown-container">
												{suggestions.map((suggestion, index) => {
													const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
													// inline style for demonstration purpose
													const style = suggestion.active
														? {
																background: '#fff',
																color: '#000',
																cursor: 'pointer',
														  }
														: {
																backgroundColor: 'transparent',
																cursor: 'pointer',
														  };

													return (
														<div
															key={index}
															{...getSuggestionItemProps(suggestion, {
																className,
																style,
															})}
														>
															<MenuItem disableGutters dense className="address-menuitem">
																{suggestion.description}
															</MenuItem>
														</div>
													);
												})}
											</div>
										</div>
									)}
								</PlacesAutocomplete>
							</Grid>

							{/* ABOUT FIELD */}
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.about && errors.about)}
									fullWidth
									variant="filled"
									inputProps={{ maxLength: 200 }}
									label={t('organisation.about')}
									placeholder={t('organisation.about')}
									helperText={touched.about && errors.about}
									className="matrix-textfield"
									multiline
									rows={5}
									name="about"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.about}
									// value="Tired of the same old you? Tired of being out of shape and out of luck with the opposite sex? Tired of being over weight and under attractive? Here at Globo Gym we understand that ugliness and fatness are genetic disorders, much like baldness or necrophilia. And its only your fault if you don't hate yourself enough to do something about it. And that's where we come in!"
								/>
							</Grid>

							{/* CREATE BUTTON */}
							<Grid item xs={12} md={12} className="dialog-submit-container dialog-sticky-btn">
								<Box className="dialog-submit-button">
									<Button
										variant="contained"
										type="submit"
										className="dialog-submit"
										// onClick={() => { handleClose() }}
									>
										{modal === 'updateOrg' ? t('organisation.save') : t('organisation.create')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</>
	);
};
