import { connect } from 'react-redux';
import { getAccountSettingData } from 'src/redux-state/actions/actions';
import { Email as EmailComponent } from './Email';

const mapDispatchToProps = (dispatch) => {
	return {
		getAccountSettingData: (data) => dispatch(getAccountSettingData(data)),
	};
};

export const Email = connect(mapDispatchToProps)(EmailComponent);
