import { useState, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { fetchFromStorage } from 'src/helpers/context';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import * as rdd from 'react-device-detect';

import BackgroundImage from 'src/assets/images/banner/banner-2.png';

import { Footer, NavBar, useLayoutStyles } from '../components';

import { TopBar } from './TopBar';
import menu from './menu';

export const SignupProfileDetailsLayout = ({ home }) => {
	const classes = useLayoutStyles(BackgroundImage)();
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const statics = useSelector((state) => state?.Combat?.user_statics);
	const redux_auth = useSelector((state) => state?.Combat?.authData);
	const user_statics = statics ? statics : fetchFromStorage('user_statics');
	const auth = redux_auth ? redux_auth : fetchFromStorage('authData');
	const verify_count = useSelector((state) => state?.Combat?.user_profile_count);
	const containerRef = useRef(null);
	const location = useLocation();

	const isProfileDetails = location.pathname === '/profile/details';
	let innerHeight = 600;

	if (window) {
		innerHeight = window.innerHeight;
	}

	// logic to avoid problems with keyboard on android in webview appilcation

	if (containerRef.current) {
		if (isProfileDetails && typeof emitterObject !== 'undefined' && rdd.isAndroid) {
			containerRef.current.style.paddingBottom = `${innerHeight / 2}px`;
		} else {
			containerRef.current.style.paddingBottom = '';
		}
	}

	return (
		<div className={classes.wrapper}>
			<ToastContainer />
			<TopBar home={home} onMobileNavOpen={() => setMobileNavOpen(true)} />
			<NavBar items={menu} onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
			<div className={cn(classes.content, 'combat-container')} ref={containerRef}>
				<Outlet />
			</div>
			<Footer auth={auth} user_statics={user_statics} verify_count={verify_count} />
		</div>
	);
};
