import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Avatar, Typography, IconButton, Popover } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ThreeDots } from 'src/assets/images/icons/3-vertical-dots.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import moment from 'moment';
import './MessagePerson.scss';

export const MessagePerPerson = ({
	Name,
	currentGroupId,
	profileId,
	userListData,
	unRead,
	fetchMessageList,
	fetchGroupList,
	id,
	msgContent,
	onUserChatClick,
	singleItem,
	currentGroupChat,
}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [dcBox, setDCBox] = useState(false);
	const open = Boolean(anchorEl);
	const pid = open ? 'simple-popover' : undefined;

	const handleDCBox = (event) => {
		setAnchorEl(event.currentTarget);
		dcBox ? setDCBox(false) : setDCBox(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleRemove = async (type) => {
		var requestData = {
			type: type,
		};

		try {
			let { status, data } = await axiosInstance.delete(URLS.removeMessage + `${id}`, {
				data: requestData,
			});

			if (status === 200) {
				setApiMessage('success', data?.message);
				type === 1 ? fetchMessageList() : fetchGroupList('');
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	var lDate =
		singleItem && singleItem?.lastMessageObj?.message_timestamp_created !== null
			? moment(singleItem?.lastMessageObj?.message_timestamp_created).format('hh:mm A')
			: moment(singleItem?.lastMessageObj?.timestamp_created).format('hh:mm A');

	var groupObj = userListData.find((item) => item?.id === currentGroupId);

	return (
		<NavLink to={`/messages/${id}`}>
			<Box
				className={
					groupObj?.id === id
						? 'active-chat person-details-sec'
						: unRead > 0
						? 'person-details-sec unread-chat'
						: 'person-details-sec'
				}
				onClick={() => {
					onUserChatClick(singleItem);
					// navigate(`/message/${keyValue}`);
				}}
			>
				<Box className="person-detail">
					<Avatar src={profileId} className="avatar-sec" />
					<Box className="details-sec">
						<Box className="name-details-sec">
							<Typography className="name-sec">{Name}</Typography>
							<Box className="noti-msg-sec">
								{unRead !== 0 && <Typography className="notification-sec">{unRead}</Typography>}
								{dcBox ? (
									<Popover
										className="cr-popover"
										id={pid}
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										transformOrigin={{
											horizontal: 'right',
											vertical: 'top',
										}}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
									>
										<Box className="message-remove-forword-sec">
											<Typography
												className="message-rf-text"
												onClick={() => {
													handleRemove(1);
												}}
											>
												{t('messages.clear_chat')}
											</Typography>
											<Typography
												className="message-rf-text"
												onClick={() => {
													handleRemove(2);
												}}
											>
												{t('messages.delete_chat')}
											</Typography>
										</Box>
									</Popover>
								) : (
									<></>
								)}
								<IconButton
									className="message-three-dots"
									onClick={(e) => {
										handleDCBox(e);
									}}
								>
									<ThreeDots className="svg-three-dots" />
								</IconButton>
							</Box>
						</Box>
						<Typography className="lastseen-sec">{lDate}</Typography>
					</Box>
				</Box>
				{/* <Typography className='Sponsored-Mail-text'>
                    {t('messages.Sponsored_Mail')}
                </Typography> */}
				<Typography className="message-content-text">{msgContent}</Typography>
			</Box>
		</NavLink>
	);
};
