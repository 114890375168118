import { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Avatar,
	Box,
	Button,
	Grid,
	IconButton,
	makeStyles,
	Checkbox,
	MenuItem,
	Typography,
	ListItem,
	ListItemText,
	List,
	ListItemIcon,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as UpArrowIcon } from 'src/assets/images/icons/up-icon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import './combatant.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const CombatantDetailsTab = ({
	allUsers,
	weightClasses,
	eventFightSides,
	titleTypeList,
	eventFightData,
	fightIndex,
	eventFightCard,
	create_data,
	superEventFightDetails,
	eventStatus,
}) => {
	const { t } = useTranslation();

	const formRef = useRef();
	const super_event_details = useSelector((state) => state?.Events);
	const [combatant, setCombatant] = useState(true);
	const [validCheck, setValidCheck] = useState(false);
	const [addDetails, setAddDeatails] = useState(false);
	const filter = createFilterOptions();
	const handleCombatant = (index, Cindex) => {
		combatant === `S${index}C${Cindex}` ? setCombatant(false) : setCombatant(`S${index}C${Cindex}`);
	};
	const [comDetails, setComDetails] = useState([
		{
			fight_side_name: '',
			fightContestants: [
				{
					userName: '',
					title_meta_category_id: '',
					meta_weight_class_id: '',
				},
			],
		},
		{
			fight_side_name: '',
			fightContestants: [
				{
					userName: '',
					title_meta_category_id: '',
					meta_weight_class_id: '',
				},
			],
		},
	]);
	const [eventSidesList, setEventSidesList] = useState(eventFightSides);
	const [eventUserList, setEventUserList] = useState(allUsers);
	// const [createdCom, setCreatedCom] = useState([
	//     {
	//         userId: '',
	//         title_meta_category_id: '',
	//         meta_weight_class_id: ''
	//     }
	// ]);
	let { fight_details, event_fight_card, event_confirm, event_status, current_tab, event_details } = create_data;
	const submitHandler = () => {
		setValidCheck(true);
	};
	const addCombatantHandler = (index) => {
		let newVal = comDetails[index]?.fightContestants || [];
		let dummy = {
			userName: '',
			title_meta_category_id: '',
			meta_weight_class_id: '',
		};

		newVal.push(dummy);
		let updatedData = comDetails.map((x, comIndex) => {
			if (comIndex === index) {
				return { ...x, fightContestants: newVal };
			}

			return x;
		});

		setComDetails(updatedData);
	};
	const removeComHandler = (Pindex, Cindex) => {
		let combs = comDetails[Pindex]?.fightContestants;

		if (combs?.length > 1) {
			let updateData = comDetails.map((x, xindex) => {
				var comData = x.fightContestants.filter((item, index) => index !== Cindex);

				if (xindex === Pindex) {
					return { ...x, fightContestants: comData };
				}

				return x;
			});

			setComDetails(updateData);
		}
	};

	const addSideComDetails = () => {
		setComDetails([
			...comDetails,
			{
				...(event_fight_card &&
					event_fight_card[fightIndex] &&
					event_fight_card[fightIndex].event_fight_combatant &&
					event_fight_card[fightIndex].event_fight_combatant[0] &&
					event_fight_card[fightIndex].event_fight_combatant[0].event_fight_id && {
						event_fight_id: event_fight_card[fightIndex].event_fight_combatant[0].event_fight_id,
					}),
				fight_side_name: '',
				fightContestants: [],
			},
		]);
	};
	const deleteSide = (index) => {
		var deletedSide = comDetails.filter((item, findex) => findex !== index);

		setComDetails(deletedSide);
	};

	// const addValueComDeatails = (e, index, fieldName) => {
	//     var urlArray = [...comDetails];
	//     if (fieldName === 'side') {
	//         urlArray[index] = { ...urlArray[index], fight_side_name: e.target.value };
	//         setComDetails(urlArray);
	//     }
	// }
	useEffect(() => {
		// fight_details?.combatant_details && setComDetails(fight_details?.combatant_details);
		EventEmitter.subscribe('submit_Fight_details', submitHandler);
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (event_status === 'draft' && current_tab === 1) {
			var eventAllData = {
				event_details: event_details,
				event_fight_card: event_fight_card,
			};

			EventEmitter.dispatch('create_draft_event', eventAllData);
			eventStatus('published');
		}
	}, [event_status]);
	useEffect(() => {
		comDetails && eventFightData({ ...fight_details, combatant_details: comDetails });
	}, [comDetails]);
	useEffect(() => {
		if (fight_details) {
			if (
				event_fight_card === undefined ||
				event_fight_card[fightIndex] === undefined ||
				event_fight_card[fightIndex].length === 0 ||
				event_fight_card[fightIndex]?.event_fight_combatant === undefined
			) {
				let superEventFDetails = super_event_details?.super_event_fight_details;
				let eventIndex = `eventindex-${create_data?.super_event_page}`;
				let fightDetailsData =
					event_fight_card &&
					event_fight_card.map((item, index) => {
						if (fightIndex === index) {
							return { ...item, event_fight_combatant: fight_details?.combatant_details };
						}

						return item;
					});

				fightDetailsData && eventFightCard(fightDetailsData);
				fightDetailsData && setAddDeatails(true);
				superEventFightDetails({ ...superEventFDetails, [eventIndex]: fightDetailsData });
			} else if (
				event_fight_card &&
				event_fight_card[fightIndex] &&
				event_fight_card[fightIndex]?.event_fight_combatant
			) {
				if (fight_details?.combatant_details !== event_fight_card[fightIndex]?.event_fight_combatant) {
					let superEventFDetails = super_event_details?.super_event_fight_details;
					let eventIndex = `eventindex-${create_data?.super_event_page}`;
					let fightDetailsData =
						event_fight_card &&
						event_fight_card.map((item, index) => {
							if (fightIndex === index) {
								return { ...item, event_fight_combatant: fight_details?.combatant_details };
							}

							return item;
						});

					fightDetailsData && eventFightCard(fightDetailsData);
					fightDetailsData && setAddDeatails(true);
					superEventFightDetails({ ...superEventFDetails, [eventIndex]: fightDetailsData });
				}
			}
		}
	}, [fight_details]);

	useEffect(() => {
		event_fight_card &&
			event_fight_card[fightIndex] &&
			event_fight_card[fightIndex].event_fight_combatant &&
			setComDetails(event_fight_card[fightIndex].event_fight_combatant);

		if (
			event_fight_card &&
			event_fight_card[fightIndex] &&
			event_fight_card[fightIndex].event_fight_combatant &&
			!addDetails
		) {
			setComDetails(event_fight_card[fightIndex].event_fight_combatant);
		} else if (event_confirm && event_confirm === 'update' && fightIndex) {
			event_fight_card[fightIndex] && setComDetails([]);
		}
	}, [fightIndex]);
	useEffect(() => {
		if (comDetails) {
			comDetails &&
				comDetails.map((data, ind) => {
					var eventFightSidesList = eventSidesList.filter((item) => item !== data.fight_side_name);

					setEventSidesList(eventFightSidesList);
				});
		}
	}, [comDetails]);
	useEffect(() => {
		if (comDetails) {
			var comData =
				comDetails &&
				comDetails.map((data, ind) => {
					var comData = data?.fightContestants.map((combatantData, cind) => {
						var eventUList = combatantData && eventUserList?.filter((item) => item !== combatantData?.userName);

						setEventUserList(eventUList);

						return '';
					});

					return '';
				});
		}
	}, [comDetails]);

	return (
		<Box className="combatant-details-sec">
			<Formik
				innerRef={formRef}
				initialValues={{
					fight_side_id: '',
					userId: '',
					title_meta_category_id: '',
					meta_weight_class_id: '',
				}}
				validationSchema={Yup.object().shape({})}
				onSubmit={(data) => {
					console.log('submit_combatant_details 666');
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} className="add-com-btn-grid">
								<Button
									className="add-combatant-btn"
									onClick={() => {
										addSideComDetails();
									}}
								>
									{t('events.add_side')}
								</Button>
							</Grid>
						</Grid>

						{comDetails &&
							comDetails.map((comSide, index) => {
								return (
									<Grid container>
										{index === 0 ? (
											<></>
										) : (
											<Grid item xs={12} sm={12} md={12} lg={12} className="add-com-btn-grid combatant-vs-btn-sec">
												<Typography className="combatant-vs-text">{t('combat_fight_record.vs')}</Typography>

												<Button
													className="add-combatant-btn"
													onClick={() => {
														deleteSide(index);
													}}
												>
													{t('events.delete_side')}
												</Button>
											</Grid>
										)}
										<Grid item sm={12} md={12} lg={12} className="text-field-grid">
											{/* <TextField
                                                error={Boolean(touched.side && errors.side)}
                                                fullWidth
                                                helperText={touched.side && errors.side}
                                                variant="filled"
                                                InputProps={{
                                                    endAdornment:
                                                        touched.side && errors.side ? (
                                                            <ErrorIcon />
                                                        ) : null
                                                }}
                                                label={t('events.side')}
                                                placeholder={t('events.side')}
                                                className="matrix-textfield"
                                                size="small"
                                                name={sideName}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    addValueComDeatails(e, index, "side");
                                                }}
                                                value={values.sideName}
                                                select
                                            >
                                                {eventFightSides?.map((obj, index) => {
                                                    return (
                                                        <MenuItem key={index} value={obj?.name}>
                                                            {obj?.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField> */}
											<Autocomplete
												id="event-sides"
												options={eventSidesList}
												value={comSide?.fight_side_name}
												onChange={(e, newValue) => {
													let newVal = comDetails?.map((x, xindex) => {
														if (xindex === index) {
															return { ...x, fight_side_name: newValue };
														}

														return x;
													});

													setComDetails(newVal);
												}}
												filterOptions={(options, params) => {
													const filtered = filter(options, params);
													const { inputValue } = params;
													// Suggest the creation of a new value
													const isExisting = options.some((option) => inputValue === option.name);

													if (inputValue !== '' && !isExisting) {
														filtered.push({
															inputValue,
															name: `Add "${inputValue}"`,
														});
													}

													return filtered;
												}}
												selectOnFocus
												clearOnBlur
												handleHomeEndKeys
												getOptionLabel={(option) => {
													// Value selected with enter, right from the input
													if (typeof option === 'string') {
														return option;
													}

													// Add "xxx" option created dynamically
													if (option.inputValue) {
														return option.inputValue;
													}

													// Regular option
													return option.name;
												}}
												renderOption={(props, option) => {
													return props.name;
												}}
												renderInput={(params) => (
													<TextField
														fullWidth
														{...params}
														error={validCheck && comSide.fight_side_name === ''}
														helperText={validCheck && comSide.fight_side_name === '' && 'This field is required'}
														variant="filled"
														label={t('events.side')}
														placeholder={t('events.side')}
														className="matrix-textfield"
														size="small"
													/>
												)}
											/>
										</Grid>
										{comSide?.fight_side_name && (
											<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
												{/* <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="date-field"
                                    >
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemIcon >
                                                    <Checkbox
                                                        edge="start"
                                                        // checked={checked.indexOf(value?.id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        className='check-box'
                                                        inputProps={{ 'aria-labelledby': 'checkbox-list-label-1' }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={'checkbox-list-label-1'} primary={t('events.catchweight')} />
                                            </ListItem>
                                        </List>
                                    </Grid> */}
												<Grid item xs={12} sm={12} md={12} lg={12} className="add-com-btn-grid">
													<Button
														className="add-combatant-btn"
														onClick={() => {
															addCombatantHandler(index);
														}}
													>
														{t('events.add_combatant')}
													</Button>
												</Grid>
											</Grid>
										)}

										{comSide?.fightContestants &&
											comSide?.fightContestants.map((combatantData, Cindex) => {
												var userId = `userId-${Cindex + 1}`;
												var title_meta = `title_meta_category_id-${Cindex + 1}`;
												var meta_weight = `meta_weight_class_id-${Cindex + 1}`;

												return (
													<Box className="event-bg-sec">
														<Box className="event-bg-grid">
															<Typography className="date-bg-text">
																{t('events.combatant') + ` ${Cindex + 1}`}
															</Typography>
															<Box>
																<IconButton className="delete-date">
																	<DeleteIcon
																		onClick={() => {
																			removeComHandler(index, Cindex);
																		}}
																	/>
																</IconButton>
																<IconButton className="delete-date" onClick={() => handleCombatant(index, Cindex)}>
																	{combatant !== `S${index}C${Cindex}` ? <DownArrowIcon /> : <UpArrowIcon />}
																</IconButton>
															</Box>
														</Box>
														{combatant !== `S${index}C${Cindex}` && (
															<Box>
																<Grid container>
																	<Grid item sm={12} md={6} lg={6} className="text-field-grid pr-12">
																		{/* <TextField
                                                                    error={Boolean(touched.userId && errors.userId)}
                                                                    fullWidth
                                                                    helperText={touched.userId && errors.userId}
                                                                    variant="filled"
                                                                    InputProps={{
                                                                        endAdornment:
                                                                            touched.userId && errors.userId ? (
                                                                                <ErrorIcon />
                                                                            ) : null
                                                                    }}
                                                                    label={t('events.athlete')}
                                                                    placeholder={t('events.athlete')}
                                                                    className="matrix-textfield"
                                                                    size="small"
                                                                    name={userId}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.userId}
                                                                    select
                                                                >
                                                                    {allUsers?.map((obj, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={obj?.user_id}>
                                                                                {obj?.user_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </TextField> */}
																		<Autocomplete
																			id="athlete"
																			options={eventUserList}
																			value={combatantData?.userName}
																			onChange={(e, newValue) => {
																				let combs = comDetails.filter((s, sindex) => sindex === index)[0]
																					.fightContestants;
																				let newComb = combs?.map((x, xindex) => {
																					if (xindex === Cindex) {
																						return { ...x, userName: newValue };
																					}

																					return x;
																				});
																				let updateData = comDetails.map((x, xind) => {
																					if (xind === index) {
																						return { ...x, fightContestants: newComb };
																					}

																					return x;
																				});

																				setComDetails(updateData);
																			}}
																			filterOptions={(options, params) => {
																				const filtered = filter(options, params);
																				const { inputValue } = params;
																				// Suggest the creation of a new value
																				const isExisting = options.some((option) => inputValue === option.user_name);

																				if (inputValue !== '' && !isExisting) {
																					filtered.push({
																						inputValue,
																						user_name: `Add "${inputValue}"`,
																					});
																				}

																				return filtered;
																			}}
																			selectOnFocus
																			clearOnBlur
																			handleHomeEndKeys
																			getOptionLabel={(option) => {
																				// Value selected with enter, right from the input
																				if (typeof option === 'string') {
																					return option;
																				}

																				// Add "xxx" option created dynamically
																				if (option.inputValue) {
																					return option.inputValue;
																				}

																				// Regular option
																				return option.user_name;
																			}}
																			renderOption={(props, option) => {
																				return props.user_name;
																			}}
																			renderInput={(params) => (
																				<TextField
																					fullWidth
																					{...params}
																					error={validCheck && combatantData?.userName === ''}
																					helperText={
																						validCheck && combatantData?.userName === '' && 'This field is required'
																					}
																					variant="filled"
																					label={t('events.athlete')}
																					placeholder={t('events.athlete')}
																					className="matrix-textfield"
																					size="small"
																				/>
																			)}
																		/>
																	</Grid>
																	<Grid item sm={12} md={6} lg={6} className="text-field-grid">
																		<TextField
																			// error={Boolean(touched.title_meta && errors.title_meta)}
																			fullWidth
																			error={validCheck && combatantData?.title_meta_category_id === ''}
																			helperText={
																				combatantData &&
																				combatantData?.title_meta_category_id === '' &&
																				'This field is required'
																			}
																			// helperText={touched.title_meta && errors.title_meta}
																			variant="filled"
																			InputProps={{
																				endAdornment:
																					touched.title_meta_category_id && errors.title_meta_category_id ? (
																						<ErrorIcon />
																					) : null,
																			}}
																			label={t('events.title_type')}
																			placeholder={t('events.title_type')}
																			className="matrix-textfield"
																			size="small"
																			name={title_meta}
																			onBlur={handleBlur}
																			onChange={(e) => {
																				let combs = comDetails.filter((s, sindex) => sindex === index)[0]
																					.fightContestants;
																				let newComb = combs?.map((x, xindex) => {
																					if (xindex === Cindex) {
																						return { ...x, title_meta_category_id: e.target.value };
																					}

																					return x;
																				});
																				let updateData = comDetails.map((x, xind) => {
																					if (xind === index) {
																						return { ...x, fightContestants: newComb };
																					}

																					return x;
																				});

																				setComDetails(updateData);
																			}}
																			value={combatantData.title_meta_category_id}
																			select
																		>
																			{titleTypeList?.map((obj, index) => {
																				return (
																					<MenuItem key={index} value={obj?.id}>
																						{obj?.name}
																					</MenuItem>
																				);
																			})}
																		</TextField>
																		{/* <Autocomplete
                                                                    id="title_type"
                                                                    options={titleTypeList}
                                                                    value={combatantData?.title_meta_category_id}
                                                                    onChange={(e, newValue) => {

                                                                        let combs = comDetails.filter(
                                                                            (s, sindex) => sindex === index
                                                                        )[0].fightContestants;
                                                                        let newComb = combs?.map((x, xindex) => {
                                                                            if (xindex === Cindex) {
                                                                                return { ...x, title_meta_category_id: newValue };
                                                                            }
                                                                            return x;
                                                                        });
                                                                        let updateData = comDetails.map((x, xind) => {
                                                                            if (xind === index) {
                                                                                return { ...x, fightContestants: newComb };
                                                                            }
                                                                            return x;
                                                                        });
                                                                        setComDetails(updateData);
                                                                    }}
                                                                    filterOptions={(options, params) => {
                                                                        const filtered = filter(options, params);
                                                                        const { inputValue } = params;
                                                                        // Suggest the creation of a new value
                                                                        const isExisting = options.some(
                                                                            option => inputValue === option.name
                                                                        );
                                                                        if (inputValue !== '' && !isExisting) {
                                                                            filtered.push({
                                                                                inputValue,
                                                                                name: `Add "${inputValue}"`
                                                                            });
                                                                        }
                                                                        return filtered;
                                                                    }}
                                                                    selectOnFocus
                                                                    clearOnBlur
                                                                    handleHomeEndKeys
                                                                    getOptionLabel={option => {
                                                                        // Value selected with enter, right from the input
                                                                        if (typeof option === 'string') {
                                                                            return option;
                                                                        }
                                                                        // Add "xxx" option created dynamically
                                                                        if (option.inputValue) {
                                                                            return option.inputValue;
                                                                        }
                                                                        // Regular option
                                                                        return option.name;
                                                                    }}
                                                                    renderOption={(props, option) => {
                                                                        return props.name;
                                                                    }}
                                                                    renderInput={params => (
                                                                        <TextField
                                                                            fullWidth
                                                                            {...params}
                                                                            error={validCheck && combatantData?.title_meta_category_id === ''}
                                                                            helperText={
                                                                                validCheck &&
                                                                                combatantData?.title_meta_category_id === '' &&
                                                                                'This field is required'
                                                                            }
                                                                            variant="filled"
                                                                            label={t('events.title_type')}
                                                                            placeholder={t('events.title_type')}
                                                                            className="matrix-textfield"
                                                                            size="small"
                                                                        />
                                                                    )}
                                                                /> */}
																	</Grid>
																</Grid>

																<Grid item sm={12} md={12} lg={12} className="text-field-grid">
																	<TextField
																		error={validCheck && combatantData?.meta_weight_class_id === ''}
																		helperText={
																			validCheck &&
																			combatantData?.meta_weight_class_id === '' &&
																			'This field is required'
																		}
																		// error={Boolean(touched.meta_weight && errors.meta_weight)}
																		fullWidth
																		// helperText={touched.meta_weight && errors.meta_weight}
																		variant="filled"
																		InputProps={{
																			endAdornment: touched.meta_weight && errors.meta_weight ? <ErrorIcon /> : null,
																		}}
																		label={t('events.weight_class')}
																		placeholder={t('events.weight_class')}
																		className="matrix-textfield"
																		size="small"
																		name={meta_weight}
																		onBlur={handleBlur}
																		onChange={(e) => {
																			let combs = comDetails.filter((s, sindex) => sindex === index)[0]
																				.fightContestants;
																			let newComb = combs?.map((x, xindex) => {
																				if (xindex === Cindex) {
																					return { ...x, meta_weight_class_id: e.target.value };
																				}

																				return x;
																			});
																			let updateData = comDetails.map((x, xind) => {
																				if (xind === index) {
																					return { ...x, fightContestants: newComb };
																				}

																				return x;
																			});

																			setComDetails(updateData);
																		}}
																		value={combatantData.meta_weight_class_id}
																		select
																	>
																		{weightClasses?.map((obj, index) => {
																			return (
																				<MenuItem key={index} value={obj?.id}>
																					{obj?.name}
																				</MenuItem>
																			);
																		})}
																	</TextField>
																	{/* <Autocomplete
                                                                id="athlete"
                                                                options={weightClasses}
                                                                value={combatantData?.meta_weight_class_id}
                                                                onChange={(e, newValue) => {

                                                                    let combs = comDetails.filter(
                                                                        (s, sindex) => sindex === index
                                                                    )[0].fightContestants;
                                                                    let newComb = combs?.map((x, xindex) => {
                                                                        if (xindex === Cindex) {
                                                                            return { ...x, meta_weight_class_id: newValue };
                                                                        }
                                                                        return x;
                                                                    });
                                                                    let updateData = comDetails.map((x, xind) => {
                                                                        if (xind === index) {
                                                                            return { ...x, fightContestants: newComb };
                                                                        }
                                                                        return x;
                                                                    });
                                                                    setComDetails(updateData);
                                                                }}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);
                                                                    const { inputValue } = params;
                                                                    // Suggest the creation of a new value
                                                                    const isExisting = options.some(
                                                                        option => inputValue === option.name
                                                                    );
                                                                    if (inputValue !== '' && !isExisting) {
                                                                        filtered.push({
                                                                            inputValue,
                                                                            name: `Add "${inputValue}"`
                                                                        });
                                                                    }
                                                                    return filtered;
                                                                }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                getOptionLabel={option => {
                                                                    // Value selected with enter, right from the input
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    // Add "xxx" option created dynamically
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    // Regular option
                                                                    return option.name;
                                                                }}
                                                                renderOption={(props, option) => {
                                                                    return props.name;
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        fullWidth
                                                                        {...params}
                                                                        error={validCheck && combatantData?.userName === ''}
                                                                        helperText={
                                                                            validCheck &&
                                                                            combatantData?.userName === '' &&
                                                                            'This field is required'
                                                                        }
                                                                        variant="filled"
                                                                        label={t('events.weight_class')}
                                                                        placeholder={t('events.weight_class')}
                                                                        className="matrix-textfield"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            /> */}
																</Grid>
															</Box>
														)}
													</Box>
												);
											})}
									</Grid>
								);
							})}
					</form>
				)}
			</Formik>
		</Box>
	);
};
