import { useState, useEffect } from 'react';
import {
	List,
	Button,
	ListItem,
	Avatar,
	TextField as TextFieldMui,
	withStyles,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Box,
	Popover,
	InputAdornment,
	Typography,
	Grid,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './dropdown.scss';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { TextField } from '../TextField';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/community/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		backgroundColor: '#202020',
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextFieldMui);
export const SingleSelectCheckmarks = ({ onSelect, count, labelValue, dropdownArray, formData, handleFormData }) => {
	const { t } = useTranslation();

	const [checked, setChecked] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [DDArray, setDDArray] = useState([]);

	const handleToggle = (value) => () => {
		onSelect(value);
		setChecked(value);
		// const currentIndex = checked.indexOf(value);
		// const newChecked = [...checked];
		// if (currentIndex === -1) {
		//     newChecked.push(value);
		// } else {
		//     newChecked.splice(currentIndex, 1);
		// }
		// setChecked(newChecked);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setDDArray(dropdownArray);
	};
	// const handleChange = (e) => {
	//     const { value } = e.target;
	//     const filtered = dropdownArray.filter(obj => obj.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
	//     setDDArray(filtered)
	// }
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	// useEffect(() => {
	//     onSelect(checked)
	// }, [checked]);

	useEffect(() => {
		setDDArray(dropdownArray);
	}, [dropdownArray]);

	useEffect(() => {
		formData && console.log(Object.keys(formData).length, 'formDataformData');
		formData && Object.keys(formData).length === 0 && setChecked([]);
	}, [formData]);

	return (
		<>
			<Button
				disableElevation
				disableTouchRipple
				disableRipple
				disableFocusRipple
				style={{ width: '100%' }}
				variant="contained"
				endIcon={<ArrowDown className={open ? 'toggle-down' : 'toggle-up'} />}
				startIcon={count > 0 && <Avatar className="circle-blue">{count}</Avatar>}
				className="filter-button"
				onClick={handleClick}
			>
				{labelValue}
			</Button>
			<Popover
				className="bcdp-popover"
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box className="checkmark-dropdown">
					<Box className="dropdown-header-sec">
						<Typography className="label-header">{labelValue}</Typography>
						<Typography className="label-text">{t('search.select_unit')}</Typography>
					</Box>
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						{/*SEARCH DROPDOWN */}
						{/* <Box className='community-search-box'>
                            <Box className="search-field-box p-10 b-0">
                                <SearchTextField
                                    onChange={handleChange}
                                    fullWidth
                                    placeholder={t('search.title')}
                                    size="small"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Box> */}

						{DDArray.map((value, index) => {
							const labelId = `checkbox-list-label-${index}`;

							return (
								<ListItem onClick={handleToggle(value?.id)} key={index} disablePadding>
									{/* <ListItemButton role={undefined} onClick={handleToggle(value)} dense> */}
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={value?.id === checked ? true : false}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={value?.name} />
									{/* </ListItemButton> */}
								</ListItem>
							);
						})}
					</List>
					<Box className="lower-upper-values">
						<Formik
							initialValues={{
								lowerWeight: formData?.lowerWeight ? formData?.lowerWeight : '',
								upperWeight: formData?.upperWeight ? formData?.upperWeight : '',
							}}
							validationSchema={Yup.object().shape({
								lowerWeight: Yup.string().required(t('validation.required_message')),
								upperWeight: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => {
								handleFormData(requestdata);
								handleClose();
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.lowerWeight && errors.lowerWeight)}
												fullWidth
												helperText={touched.lowerWeight && errors.lowerWeight}
												variant="filled"
												placeholder={t('search.lower_weight')}
												label={t('search.lower_weight')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.lowerWeight && errors.lowerWeight ? <ErrorIcon /> : null,
												}}
												size="small"
												name="lowerWeight"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.lowerWeight}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.upperWeight && errors.upperWeight)}
												fullWidth
												helperText={touched.upperWeight && errors.upperWeight}
												variant="filled"
												placeholder={t('search.upper_weight')}
												label={t('search.upper_weight')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.upperWeight && errors.upperWeight ? <ErrorIcon /> : null,
												}}
												size="small"
												name="upperWeight"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.upperWeight}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="apply-filter-sec">
											<Button variant="contained" type="submit" className="filter-btn">
												{t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Box>
			</Popover>
		</>
	);
};
