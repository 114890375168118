import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import './thumbnailUpload.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

export const ThumbnailUpload = ({ setThumbnail,token }) => {
	const [loader, setLoader] = useState(false);
	const [controller, setController] = useState(new AbortController());

	const onImageChange = async (e) => {
        setLoader(true);
		e.preventDefault();
		e.stopPropagation();
		const formData = new FormData();
		const file = e.target.files[0];


		if (!file) {
			setLoader(false);

			return;
		}

		if (file) {
			formData.append('thumbnail', file);
			formData.append('type', file.type.split('/')[0]);
		}

		try {
			const { status, data } = await axiosInstance.post(URLS.uploadThumbnail, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
				signal: controller.signal,
			});

            if (status === 200) {
                var responseArray = data?.data;

                setThumbnail(responseArray);
                setLoader(false);
            }
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setLoader(false);
		}

        setController(new AbortController());
	};

	return (
		<>
			<input
				accept="*"
				type="file"
				id="upload-thumbnail"
				style={{ display: 'none' }}
				multiple={false}
				onChange={onImageChange}
			/>
			<label htmlFor="upload-thumbnail">
				<Button
					variant="outlined"
					startIcon={loader ? <CircularProgress color="inherit" size={20} /> : <FileUploadIcon />}
					className="upload-btn"
					component="span"
				>
					Upload
				</Button>
			</label>
		</>
	);
};
