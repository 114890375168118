import { Box, Typography, Grid } from '@material-ui/core';
import { ReactComponent as BadgeVerificationIcon } from 'src/assets/images/icons/verification_icon.svg';
import '../generalbadge.scss';
import { useTranslation } from 'react-i18next';

const accverification = [
	{
		name: 'bronze',
		content: ' Completed level 2 verification',
	},
	{
		name: 'silver',
		content: ' Completed level 3 verification',
	},
	{
		name: 'gold',
		content: 'Completed level 4 verification',
	},
	{
		name: 'blue',
		content: ' Completed level 5 verification',
	},
];

export const AccountVerification = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="badge_block_wraper">
				<Typography className="general_subtitle">{t('badge.account_verification')}</Typography>
				<Grid container spacing={1}>
					{accverification.map((item) => {
						return (
							<>
								<Grid item xs={12} md={6} lg={4}>
									<Box className="badge_block">
										<Box className="icon_block">
											<BadgeVerificationIcon />
										</Box>
										<Box className="badge_content">
											<Typography className="block_title">{item.name}</Typography>
											<Typography className="nbsb_title">{item.content}</Typography>
										</Box>
									</Box>
								</Grid>
							</>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};
