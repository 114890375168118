import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Logo } from 'src/components/ui';

import styles from './TopBar.module.scss';

const useStyles = makeStyles((theme) => ({
	root: { background: 'transparent' },
	title: {
		marginRight: 'auto',
	},
}));

export const TopBar = ({ className, home, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();

	return (
		<AppBar className={`${clsx(classes.root, className)} ${styles['app-bar']}`} elevation={0} {...rest}>
			<Toolbar>
				<NavLink to={home ? home : '/'} className={styles['logo-anchor']}>
					<Logo />
				</NavLink>
				<Box flexGrow={1} />
				<Hidden xsUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};
