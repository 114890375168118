import { eventInvite, eventStatus } from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';
import { EventInvite as EventInviteComponent } from './EventInvite';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		eventInvite: (data) => dispatch(eventInvite(data)),
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const EventInvite = connect(mapStateToProps, mapDispatchToProps)(EventInviteComponent);
