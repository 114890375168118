import { useEffect } from 'react';
import { Box, Fade } from '@material-ui/core';
import './update-post.scss';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { TextEditor } from 'src/components/ui';

export const UpdatePost = ({
	user,
	token,
	postData,
	cancelBtnClick,
	fetchCommunityPostList,
	tab,
	editAccess,
	allUsersList,
}) => {
	const { t } = useTranslation();

	const initialRequestData = {
		receiver_type: 'community',
	};

	const updateCommunityPost = async (requestParameter) => {
		try {
			const { status, data } = await axiosInstance.put(URLS.updatePost, requestParameter);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchCommunityPostList(tabValue);
				cancelBtnClick();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Fade in>
			<Box className="creating-post-box">
				<TextEditor
					selectedItem={''}
					submit={updateCommunityPost}
					user={user}
					token={token}
					postData={postData}
					cancelBtnClick={cancelBtnClick}
					allUsersList={allUsersList}
					initialRequestData={initialRequestData}
				/>
			</Box>
		</Fade>
	);
};
