import {
	category,
	currentCreateEventTab,
	currentFightTab,
	eventAdmin,
	eventConfirm,
	eventDetails,
	eventFightCard,
	eventInvite,
	eventStatus,
	eventTicket,
	eventType,
	superEventStatus,
} from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';

import { YouHaveCreated as YouHaveCreatedComponent } from './YouHaveCreated';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		currentCreateEventTab: (data) => dispatch(currentCreateEventTab(data)),
		eventDetails: (data) => dispatch(eventDetails(data)),
		eventType: (data) => dispatch(eventType(data)),
		category: (data) => dispatch(category(data)),
		eventAdmin: (data) => dispatch(eventAdmin(data)),
		eventInvite: (data) => dispatch(eventInvite(data)),
		eventFightCard: (data) => dispatch(eventFightCard(data)),
		eventTicket: (data) => dispatch(eventTicket(data)),
		superEventStatus: (data) => dispatch(superEventStatus(data)),
		eventConfirm: (data) => dispatch(eventConfirm(data)),
		currentFightTab: (data) => dispatch(currentFightTab(data)),
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const YouHaveCreated = connect(mapStateToProps, mapDispatchToProps)(YouHaveCreatedComponent);
