import { useEffect, useState } from 'react';
import {
	Box,
	Avatar,
	List,
	ListItem,
	ListItemText,
	Typography,
	Button,
	InputAdornment,
	TextField,
	withStyles,
} from '@material-ui/core';
import './role.scss';
import { ReactComponent as ApproveMemberIcon } from 'src/assets/images/icons/community/approveicon.svg';
import { ReactComponent as RemoveMemberIcon } from 'src/assets/images/icons/community/rejecticon.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams } from 'react-router-dom';
// import CustomPagination from 'src/views/component/UI/CustomPaginations';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const MakeAdminModeratorModal = ({
	// memberList,
	modal,
	// handleClick,
	tab,
	updateMemberList,
	// searchString,
	// fetchCommunityMember
}) => {
	const { t } = useTranslation();

	const [searchString] = useState(''); //setSearchString
	const [memberList, setMemberList] = useState([]);

	const { id } = useParams();
	const fetchCommunityMember = async (tabVal, ss, type) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCommunityMember + `/${id}`, {
				params: {
					type: tabVal,
					name: ss,
					...(type && { admin_type: type }),
				},
			});

			if (status === 200) {
				setMemberList(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const handleClick = async (item, type, status) => {
		var requestParams = {
			user_id: item?.user_id,
			community_id: item?.community_id,
			type: type,
			status: status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.assignRole, requestParams);

			if (status === 200) {
				updateMemberList(tab, '');
				setApiMessage('success', data?.message);
				fetchCommunityMember(tab, searchString, modal);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchCommunityMember(tab, searchString, modal);
	}, []);
	const handleChange = (e) => {
		fetchCommunityMember(tab, e.target.value, modal);
	};

	return (
		<>
			<Box className="member-listing-main">
				<Box className="search-box">
					<SearchTextField
						variant="outlined"
						fullWidth
						size="small"
						placeholder="Search"
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Box className="member-inner">
						<List>
							{memberList && memberList?.connectionCommunityData?.length ? (
								<>
									{memberList?.connectionCommunityData.map((item, index) => {
										var adminbtntxt = item.type === null ? t('community.add_as_admin') : t('community.remove_as_admin');
										var moderatorbtntxt =
											item.type === null ? t('community.add_as_moderator') : t('community.remove_as_moderator');
										var status = item.type === null ? 'active' : 'cancelled';

										return (
											<ListItem key={index}>
												<Avatar src={item?.item_profile_data} />
												<ListItemText>
													<Typography variant="span" className="member-name">
														{item?.user_name}
													</Typography>
												</ListItemText>
												<Button
													disableRipple
													disableFocusRipple
													disableTouchRipple
													edge="end"
													className={item.type === null ? 'member-btn' : 'member-btn gray-background'}
													onClick={() => handleClick(item, modal, status)}
													startIcon={item.type === null ? <ApproveMemberIcon /> : <RemoveMemberIcon />}
												>
													{modal === 'admin' ? adminbtntxt : moderatorbtntxt}
												</Button>
											</ListItem>
										);
									})}
								</>
							) : (
								<Typography className="no-data-txt">{t('community.no_member_found')}</Typography>
							)}
						</List>
					</Box>
				</Box>
			</Box>
		</>
	);
};
