import { useState, useRef, useEffect } from 'react';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
import moment from 'moment';
import {
	Avatar,
	Box,
	// Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	Button,
	Tooltip,
	Popover,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { ReactComponent as StaticsUpIcon } from 'src/assets/images/icons/statics-arrow-up.svg';
import { ReactComponent as StaticsDownIcon } from 'src/assets/images/icons/statics-arrow-down.svg';
import { ReactComponent as CommentIcon } from 'src/assets/images/icons/comment.svg';
import { ReactComponent as ReactionsIcon } from 'src/assets/images/icons/reactions.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/comment-report.svg';
import { Report } from '../../../Report';
import { DialogBox } from '../../../DialogBox';
import renderHTML from 'react-render-html';
import { useParams, useLocation } from 'react-router-dom';
import anchorme from 'anchorme';
import { parse } from 'query-string';
import { Reactions } from '../../../Reactions';
import { AddComment } from '../commentComponent';
import { useTranslation } from 'react-i18next';

// import { MentionsInput, Mention } from 'react-mentions';
// import { MatrixTextField } from '../../../textfield';
// import CommentReply from './CommentReply';

const CommentBox = ({
	cmt,
	isView,
	storage_authData,
	fetchCommentData,
	editCommentClick,
	addVote,
	postId,
	reportReasonList,
	allUsersList,
	user,
	reactionData,
	likedStatus,
	reactions,
	navigate,
	fetchNotificationCommentData,
	showAllComments,
	fetchPostDetails,
	isJoin,
	formikRef,
	createComment,
	loader,
	commentLevel,
	hideShowMore,
	setCommentData,
}) => {
	const { t } = useTranslation();

	const defaultStyle = {
		control: {
			backgroundColor: '#1B1B1B',
			fontSize: 16,
			fontWeight: 'normal',
		},
		highlighter: {
			overflow: 'hidden',
		},
		input: {
			margin: 0,
			overflow: 'auto',
			minHeight: 100,
			color: '#fff',
		},
		'&multiLine': {
			control: {
				fontFamily: 'Roboto',
				border: '1px solid #2E353A',
			},
			highlighter: {
				padding: 9,
			},
			input: {
				padding: 9,
				minHeight: 100,
				outline: 0,
				border: 0,
			},
		},
		suggestions: {
			bottom: '0',
			top: 'unset',
			color: 'black',
			list: {
				backgroundColor: 'white',
				border: '1px solid rgba(0,0,0,0.15)',
				fontSize: 14,
			},
			item: {
				padding: '5px 15px',
				borderBottom: '1px solid rgba(0,0,0,0.15)',
				'&focused': {
					backgroundColor: '#cee4e5',
				},
			},
		},
	};
	const location = useLocation();
	const query = parse(location.search);
	const [first, setFirst] = useState(2);
	// const [first, setFirst] = useState(2);
	const [commentReply, setCommentReply] = useState([]);
	const [userComment, setUserComment] = useState('');
	const [threadComment, setThreadComment] = useState([]);
	const [reactionList, setReactionList] = useState([]);
	const [totalRecord, setTotalRecord] = useState();
	const [recentPage, setCurrentPage] = useState(1);
	const [reactTab, setReactTab] = useState('1');
	const [list] = useState(5);
	// 18th april start
	// const [reply, setReply] = useState(false);
	const [reply, setReply] = useState(false);
	// end
	const [anchorEl, setAnchorEl] = useState(null);
	const [opens, setOpen] = useState(false);
	const [model, setModel] = useState('');
	const [addReply, setAddReply] = useState(false);
	const [clickedReplyData, setClickedReplyData] = useState('');
	const [editClick, setEditClick] = useState(false);
	const [selectedComment, setSelectedComment] = useState(null);
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const open = Boolean(anchorEl);
	const replyFormikRef = useRef();
	const myRef = useRef(null);
	const [anchorElReact, setAnchorElReact] = useState(null);
	const { id } = useParams();

	const scrollTop = () => {
		document.body.scrollTo({ top: 0, behavior: 'smooth' });
	};

	// FETCH COMMENT REPLIES
	const fetchAddCommentReply = async (flag, pageNumber, startpostid) => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getAllComments + cmt?.id, {
				params: {
					// page: pageNumber || commentReply[commentReply.length - 1]?.page + 1,
					// limit: '5',
					level: Number(cmt?.level) + 1,
					...(startpostid && { start_post_id: startpostid }),
				},
			});

			if (status === 200) {
				setThreadComment([...threadComment, ...data?.post_list]);
			}
		} catch (err) {
			// TODO: Add logging
		}
	};

	const fetchCommentReply = async (flag, pageNumber) => {
		try {
			let { status, data } = await axiosInstance.get(
				// URLS.getAllComments + cmt?.id
				// URLS.getAllComments + cmt?.receiver_id + `?page=${first}&limit=10`
				URLS.getAllComments + cmt?.id,
				{
					params: {
						page: pageNumber || commentReply[commentReply.length - 1]?.page + 1,
						limit: '10',
						level: Number(cmt?.level) + 1,
					},
				},
			);

			if (status === 200) {
				// const hasMore = data?.post_list.length < 5 ? false : true;
				const hasMore = cmt?.total_reply_comment < 10 ? false : true;

				flag && setFirst(first + 1);
				setHasMoreItems(hasMore);
				// setCommentReply([...commentReply, ...data?.post_list])
				// setCommentReply([...commentReply, ...data?.post_list]);
				pageNumber ? setCommentReply(data?.post_list) : setCommentReply([...commentReply, ...data?.post_list]);
			}
		} catch (err) {
			// TODO: Add logging
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// DELETE COMMENT
	const deletComment = async () => {
		try {
			let { status, data } = await axiosInstance.delete(URLS.deleteComment + cmt?.id);

			if (status === 200) {
				setCommentData((prev) => prev.filter((comment) => comment.id !== cmt?.id));
			}
		} catch (err) {
			// TODO: Add logging
		}
	};

	// ADD VOTE TO REPLY
	// const addVoteToReply = async (value, type) => {
	//   var requestParams = {
	//     post_id: value?.id,
	//     vote_name: type,
	//     main_post_id: Number(id)
	//   };
	//   try {
	//     let { status, data } = await axiosInstance.post(
	//       URLS.addVote,
	//       requestParams
	//     );

	//     if (status === 200) {
	//       fetchCommentReply();
	//       fetchCommentData();
	//       location?.state?.comment_id && fetchNotificationCommentData();
	//     }
	//   } catch (error) { }
	// };

	// ADD REACTION TO COMMENT
	const addReactionToCmt = async (value, type) => {
		var requestParams = {
			post_id: Number(cmt?.id),
			meta_like_id: type, // heart, thumbs_up, clap_hands, light_bulb
			main_post_id: Number(id),
		};

		try {
			let { status, data } = await axiosInstance.post(URLS.addLike, requestParams);

			if (status === 200) {
				// fetchCommentData();
				fetchCommentData('', '', 1);
				location?.state?.comment_id && fetchNotificationCommentData();
			}
		} catch (err) {
			// TODO: Add logging
		}
	};

	// CLICK REPLY
	const clickReplyBtn = (item) => {
		setClickedReplyData(item);
		setReply(!reply);
		// if (!reply) {
		//   fetchCommentReply();
		// }
	};

	const combatDialogHandler = () => {
		setOpen(true);
	};

	const closeDialog = () => {
		setOpen(false);
	};
	// ADD NEW COMMENT REPLY
	const addNewReply = async (val) => {
		try {
			const content = [{ type: 'text', data: val }];

			let requestData = {
				comment: {
					// post_id:
					//   cmt?.user_id === storage_authData?.user_id ? postId : cmt?.id,
					// post_id: clickedReplyData?.id,
					// post_id: cmt?.user_id === storage_authData?.user_id ? postId : cmt?.id,
					post_id: clickedReplyData?.level > 2 ? postId : cmt?.id,
					status: 'active',
					main_post_id: id,
					postList: content,
					...(clickedReplyData?.level === 3 && { reply_post_id: clickedReplyData?.id }),
				},
			};

			let { status, data } = await axiosInstance.post(URLS.createComment, requestData);

			if (status === 200) {
				setReply(false);
				fetchCommentData('', '', 1);
				setAddReply(false);
				fetchPostDetails();

				if (replyFormikRef.current) {
					replyFormikRef.current.setFieldValue('bio', '');
				}

				location?.state?.comment_id && fetchNotificationCommentData();
				// fetchCommentReply();
				// fetchCommentData();
				fetchCommentReply('', 1);
				fetchAddCommentReply('', 1, data?.createPost?.post_id);
				// fetchCommentData('', clickedReplyData?.level, 1);
				setUserComment();
				showAllComments();
			}
		} catch (err) {
			// TODO: Add logging
		}
	};
	const AddReactions = (event) => {
		setAnchorElReact(event.currentTarget);
	};
	const handleCloseReact = () => {
		setAnchorElReact(null);
	};
	// UPDATE COMMENT REPLY
	const updateComment = async (val) => {
		try {
			const content = [{ type: 'text', data: val, item_id: selectedComment?.item_id }];

			let requestData = {
				comment: {
					post_id: selectedComment?.post_id,
					receiver_id: cmt?.id,
					postList: content,
				},
			};

			let { status, data } = await axiosInstance.put(URLS.updateComment, requestData);

			if (status === 200) {
				fetchCommentData('', clickedReplyData?.level, 1);

				if (replyFormikRef.current) {
					replyFormikRef.current.setFieldValue('bio', '');
				}

				// showAllComments();
				setReply(false);
				setEditClick(false);
				setSelectedComment(null);
				fetchCommentReply('', 1);
				// fetchCommentReply();
				// fetchCommentData();
				setUserComment();
				// fetchPostDetails()
			}
		} catch (err) {
			// TODO: Add logging
		}
	};
	// EDIT COMMENT REPLY CLICK
	const editReplyClick = (val, cmtText) => {
		setSelectedComment(cmtText);
		setEditClick(true);
		setAddReply(true);

		if (replyFormikRef.current) {
			replyFormikRef.current.setFieldValue('bio', val);
			setUserComment(val);
		}
	};
	const onInputChange = (event) => {
		const value = event.target.value;

		const regex = /[^{}]+(?=})/g;
		const mentions = value.match(regex);

		setUserComment(event.target.value);
	};

	// const showReply = (newReply) => {
	//   addNewReply(newReply);
	//   setReply(false);
	// };
	const executeScroll = () => myRef.current.scrollIntoView();

	// REACTION LIST
	const fetchReactionsLists = async (postId, likeId, cPage) => {
		var metaLikeId = likeId === 0 || likeId === '' || likeId === undefined ? '' : likeId;

		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsList + `${postId}`, {
				params: {
					meta_like_id: metaLikeId,
					page: cPage,
					limit: list,
				},
			});

			if (status === 200) {
				setReactionList(data?.reactionData);
				setTotalRecord(data?.totalRecord);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const reactiontabChangeHandler = (event, newValue) => {
		setReactTab(newValue);
		const tabValue = newValue - 1;

		setCurrentPage(1);
		fetchReactionsLists(cmt?.id, tabValue, 1);
	};
	// PAGINATION FOR REACTION LIST
	const handlePaginationButtonClicks = async (navDirection) => {
		if (navDirection === 'prev') {
			if (recentPage > 1) {
				setCurrentPage(recentPage - 1);
				fetchReactionsLists(cmt?.id, '', recentPage - 1);
			}
		} else {
			if (recentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(recentPage + 1);
				fetchReactionsLists(cmt?.id, '', recentPage + 1);
			}
		}
	};
	const handlePaginationClicks = async (event, page) => {
		if (recentPage !== page) {
			setCurrentPage(Number(page));
			fetchReactionsLists(cmt?.id, '', Number(page));
		}
	};

	useEffect(() => {
		// const hasMore = cmt?.total_comments < 10 ? false : true;
		// setHasMoreItems(hasMore);
		cmt?.id === location?.state?.post_comment_id && executeScroll();
		cmt?.id === Number(query?.highlight_post_id) && executeScroll();
		setCommentReply(cmt?.reply_post_list);
	}, [cmt]);

	// console.log(cmt?.id === notificationCmt, cmt?.id, notificationCmt, "notifiac")
	const openReact = Boolean(anchorElReact);
	const idReact = openReact ? 'simple-popover' : undefined;

	var isHighlight =
		cmt?.id === location?.state?.post_comment_id || cmt?.id === Number(query?.highlight_post_id) ? true : false;

	return (
		<>
			<Box
				ref={myRef}
				className="comment-list-box"
				style={{
					padding: isHighlight && '10px',
					backgroundColor: isHighlight && '#0079d31a',
				}}
			>
				{/* <CommentBox /> */}
				<Box className="user-box">
					<Avatar src={cmt?.user_item_profile_data} className="user-img" />
					<Box className="name-time">
						<Typography className="user-name">
							<Typography className="user-name" onClick={() => navigate(`/user/${cmt?.user_id}`)}>
								{cmt?.first_name + ' ' + cmt?.last_name}
							</Typography>

							{(cmt?.user_id === storage_authData?.user_id || !isView) && (
								<IconButton
									aria-label="more"
									aria-controls="long-menu"
									aria-haspopup="true"
									onClick={handleClick}
									className="cmt-actionbtn"
								>
									<MoreVertIcon color="secondary" />
								</IconButton>
							)}

							<Menu
								id="long-menu"
								anchorEl={anchorEl}
								keepMounted
								open={open}
								onClose={handleClose}
								PaperProps={{
									style: {
										maxHeight: 48 * 4.5,
										width: '20ch',
									},
								}}
							>
								<MenuItem
									onClick={() => {
										editCommentClick(
											cmt?.PostLists[0]?.Item?.data ? cmt?.PostLists[0]?.Item?.data : '',
											cmt?.PostLists[0],
											cmt,
										);
										scrollTop();
										handleClose();
									}}
								>
									{t('acount_setting.edit')}
								</MenuItem>
								<MenuItem
									onClick={() => {
										deletComment();
										handleClose();
									}}
								>
									{t('community.delete')}
								</MenuItem>
							</Menu>
						</Typography>
						<Box style={{ display: 'flex', marginTop: '3px', justifyContent: 'space-between' }}>
							<Typography className="post-time">
								{moment.utc(cmt?.timestamp_created).fromNow().replace('in ', '')}
							</Typography>
							<Box
								className="badges-box"
								onClick={() => {
									combatDialogHandler(t('community.reactions'));
									setModel('reaction');
								}}
							>
								{cmt?.MetaLikeLists &&
									cmt?.MetaLikeLists.map((item, index) => {
										return <Avatar src={item?.item_data} className="badge-avatar" />;
									})}
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className="ml-55">
					<Box className="user-comments">
						<Typography className="cmt-hdln">
							{cmt?.PostLists[0]?.Item?.data &&
								renderHTML(
									anchorme({
										input: cmt?.PostLists[0]?.Item?.data.toString().replace(/\n/g, '<br/>'),
										options: {
											attributes: {
												target: '_blank',
												class: 'detected',
											},
										},
									}),
								)}
						</Typography>
					</Box>
					<Box className="header-right">
						<Box className="stastics-box">
							<Box display="flex" alignItems="center">
								<IconButton
									className={cmt?.vote_up_status === 'yes' && 'fill-blue'}
									onClick={() => addVote(cmt, 'vote_up')}
								>
									<StaticsUpIcon />
								</IconButton>
								<Typography className="statics-val up-val">{cmt?.total_vote_up}</Typography>
							</Box>
							<Box display="flex" alignItems="center">
								<IconButton
									className={cmt?.vote_down_status === 'yes' && 'fill-red'}
									onClick={() => addVote(cmt, 'vote_down')}
								>
									<StaticsDownIcon />
								</IconButton>
								<Typography className="statics-val">{cmt?.total_vote_down}</Typography>
							</Box>
							{/*<Box display="flex" alignItems="center">*/}
							{/*	<IconButton onClick={() => clickReplyBtn(cmt)}>*/}
							{/*		<CommentIcon height={26} width={26} />*/}
							{/*		<Typography className="statics-val">*/}
							{/*			Reply {`${cmt?.total_comments > 0 ? `(${cmt?.total_comments})` : ''}`}*/}
							{/*		</Typography>*/}
							{/*	</IconButton>*/}
							{/*</Box>*/}
							{/* REACTIONS */}
							{/* HIDE-MVP: CM-75*/}
							{/*<Box display="flex" alignItems="center">*/}
							{/*	<Popover*/}
							{/*		className="cr-popover reaction-popover"*/}
							{/*		id={idReact}*/}
							{/*		open={openReact}*/}
							{/*		anchorEl={anchorElReact}*/}
							{/*		onClose={handleCloseReact}*/}
							{/*		anchorOrigin={{*/}
							{/*			vertical: 'top',*/}
							{/*			horizontal: 'left',*/}
							{/*		}}*/}
							{/*		transformOrigin={{*/}
							{/*			vertical: 'bottom',*/}
							{/*			horizontal: 'left',*/}
							{/*		}}*/}
							{/*	>*/}
							{/*		{reactionData?.map((item, index) => {*/}
							{/*			return (*/}
							{/*				<Tooltip title={item?.name} arrow>*/}
							{/*					<IconButton*/}
							{/*						className={item?.id === likedStatus ? 'react-icon react-selected' : 'react-icon'}*/}
							{/*						onClick={() => {*/}
							{/*							addReactionToCmt(cmt, item?.id);*/}
							{/*							handleCloseReact();*/}
							{/*						}}*/}
							{/*					>*/}
							{/*						<Avatar src={item?.item_data} className="reaction-img" />*/}
							{/*					</IconButton>*/}
							{/*				</Tooltip>*/}
							{/*			);*/}
							{/*		})}*/}
							{/*	</Popover>*/}
							{/*	<IconButton onClick={(e) => user && AddReactions(e)}>*/}
							{/*		<ReactionsIcon height={26} width={26} />*/}
							{/*	</IconButton>*/}
							{/*	<Typography className="statics-val">*/}
							{/*		{t('community.reactions')} ({reactions || 0})*/}
							{/*	</Typography>*/}
							{/*</Box>*/}

							{/* <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => {
                  user && addReactionToCmt(cmt, 'thumbs_up');
                }}
              >
                <ReactionsIcon height={26} width={26} />
              </IconButton>
              <Typography className="statics-val">
                React {cmt?.total_likes ? `(${cmt?.total_likes})` : ''}
              </Typography>
            </Box> */}
							<Box
								display="flex"
								alignItems="center"
								onClick={() => {
									combatDialogHandler();
								}}
								sx={{ cursor: 'pointer' }}
							>
								<ReportIcon />
								<Typography className="statics-val">{t('report')}</Typography>
							</Box>
						</Box>
						{/* {cmt?.level === 3 && cmt?.total_comments > 0 && (
              <Box>
                <Button className="continue-thread"
                  onClick={() =>
                    navigate(`/post/${id}`, {
                      state: { previous_parent_id: cmt?.id }
                    })
                  }
                >
                  Continue this thread
                </Button>
              </Box>
            )} */}
					</Box>
				</Box>
				<DialogBox
					open={opens}
					handleClose={closeDialog}
					title={model === 'reaction' ? t('community.reactions') : t('report')}
					content={
						model === 'reaction' ? (
							<Reactions
								reactionList={reactionList}
								fetchReactionsLists={fetchReactionsLists}
								postId={cmt?.id}
								handlePaginationButtonClicks={handlePaginationButtonClicks}
								handlePaginationClicks={handlePaginationClicks}
								totalRecord={totalRecord}
								list={list}
								recentPage={recentPage}
								tabChangeHandler={reactiontabChangeHandler}
								tab={reactTab}
							/>
						) : (
							<Report handleClose={closeDialog} reportReasonList={reportReasonList} type="post" receiverId={postId} />
						)
					}
				/>
			</Box>
			{reply && (
				<Box className="create-reply-bx" style={{ marginLeft: cmt?.level === 3 && '10px' }}>
					<AddComment
						user={user}
						storage_authData={storage_authData}
						isJoin={isJoin}
						formikRef={formikRef}
						updateComment={updateComment}
						createComment={addNewReply}
						onInputChange={onInputChange}
						editClick={editClick}
						userComment={userComment}
						allUsersList={allUsersList}
						loader={loader}
					/>
				</Box>
			)}
			{commentReply.length ? (
				<Box style={{ marginLeft: '24px' }}>
					{commentReply.map((reply) => {
						return (
							<CommentBox
								commentLevel={reply?.level}
								fetchPostDetails={fetchPostDetails}
								fetchNotificationCommentData={fetchNotificationCommentData}
								cmt={reply}
								isView={isView}
								storage_authData={storage_authData}
								fetchCommentData={fetchCommentData}
								editCommentClick={editCommentClick}
								addVote={addVote}
								// postId={postId}
								postId={reply?.receiver_id}
								reportReasonList={reportReasonList}
								allUsersList={allUsersList}
								user={user}
								reactionData={reactionData}
								likedStatus={reply?.like_status}
								reactions={reply?.total_likes}
								navigate={navigate}
							/>
						);
					})}
				</Box>
			) : (
				<></>
			)}
			{/* {cmt?.total_reply_comment > 5 && ( */}
			{!hideShowMore ? (
				''
			) : (
				<>
					{cmt?.total_reply_comment > commentReply.length && (
						<Button
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							className="readmore-btn"
							style={{ marginLeft: '45px' }}
							onClick={() => fetchCommentReply(true)}
							// onClick={() => console.log('click on see more', cmt)}
						>
							See more
						</Button>
					)}
				</>
			)}

			{cmt?.total_reply_comment > commentReply.length && (
				<>
					{threadComment.length ? (
						<Box style={{ marginLeft: '45px' }}>
							{threadComment.map((reply) => {
								return (
									<CommentBox
										commentLevel={reply?.level}
										fetchPostDetails={fetchPostDetails}
										fetchNotificationCommentData={fetchNotificationCommentData}
										cmt={reply}
										isView={isView}
										storage_authData={storage_authData}
										fetchCommentData={fetchCommentData}
										editCommentClick={editCommentClick}
										addVote={addVote}
										// postId={postId}
										postId={reply?.receiver_id}
										reportReasonList={reportReasonList}
										allUsersList={allUsersList}
										user={user}
										reactionData={reactionData}
										likedStatus={reply?.like_status}
										reactions={reply?.total_likes}
										navigate={navigate}
									/>
								);
							})}
						</Box>
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
};

export default CommentBox;
