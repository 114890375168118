import { Avatar, Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilWhite.svg';

import moment from 'moment';
import './confirm.scss';
import { useTranslation } from 'react-i18next';

export const Confirm = ({ create_data, currentSuperEventPage, currentCreateEventTab }) => {
	const { t } = useTranslation();

	let {
		event_details,
		event_type,
		event_fight_card,
		event_ticket,
		event_admin,
		event_invite,
		category,
		super_event_page,
		current_tab,
		super_event_status,
	} = create_data;

	const location_typesData = [
		{ id: 'venue', name: 'venue' },
		{ id: 'both', name: 'venue & online' },
		{ id: 'online', name: 'online' },
	];
	var locationType =
		event_details &&
		event_details?.formData &&
		event_details?.formData?.location_type &&
		location_typesData.filter((item) => item?.id === event_details?.formData?.location_type);

	return (
		<Box className="fight-confirm-box">
			<Typography className="confirm-title">{t('events.confirm')}</Typography>
			<Box className="box-heading">
				<Typography className="box-title">{t('events.general')}</Typography>
				<IconButton>
					<BpencilIcon
						className="pencil-icon"
						onClick={() => {
							currentCreateEventTab(0);
						}}
					/>
				</IconButton>
			</Box>
			{event_details?.imgCoverId?.image ? (
				<Avatar variant="square" src={event_details?.imgCoverId?.image} className="event-cover-confirm" />
			) : (
				<Avatar variant="square" src="" className="event-cover-confirm" />
			)}

			{/* EVENT NAME */}
			<Typography className="event-field">{t('events.event_name')}</Typography>
			<Typography className="event-field-value">{event_details?.formData?.event_name}</Typography>

			<Typography className="event-field">{t('events.description')}</Typography>
			<Typography className="event-field-value">{event_details?.formData?.description}</Typography>

			{/* fightcard
      <Box className="box-heading fight-cc-bx">
        <Typography className="box-title">Fight Card</Typography>
        <IconButton>
          <BpencilIcon />
        </IconButton>
      </Box>

      <Box className="card-confirm-tab">
        <Button className="card-btn main-card active">Main Card</Button>
        <Button className="card-btn">Under Card</Button>
      </Box>

      {[1, 2].map((card, index) => {
        return (
          <Box key={index}>
            <Typography className="event-field">BENTAMWEIGHT</Typography>
            <Typography className="event-field-value">
              Danny Kingad v Kairat Akhmetov
            </Typography>
          </Box>
        );
      })} */}
			{(event_details?.formData?.timezone || event_details?.eventDates) && (
				<Box className="box-heading fight-cc-bx">
					<Typography className="box-title">{t('events.time_date')}</Typography>
					<IconButton>
						<BpencilIcon
							className="pencil-icon"
							onClick={() => {
								currentCreateEventTab(0);
							}}
						/>
					</IconButton>
				</Box>
			)}
			<Box>
				{event_details?.formData?.timezone && (
					<Box>
						<Typography className="event-field">{t('events.time_zone')}</Typography>
						<Typography className="event-field-value">{event_details?.formData?.timezone}</Typography>
					</Box>
				)}
				{event_details && event_details?.eventDates && (
					<>
						{event_details?.eventDates?.map((dates, index) => {
							var startDate = dates?.start_time ? moment(dates?.start_time).format('YYYY-MM-DD') : '';
							var startTime = dates?.start_time ? moment(dates?.start_time).format('h:mm:ss') : '';
							var endDate = dates?.end_time ? moment(dates?.end_time).format('YYYY-MM-DD') : '';
							var endTime = dates?.end_time ? moment(dates?.end_time).format('h:mm:ss') : '';

							return (
								<Box>
									<Typography className="event-field">{t('events.date') + ` ${index + 1}`}</Typography>
									<Grid container className="event-field-grid">
										<Grid item xs={12} md={6}>
											<Typography className="event-field-value event-field-title">
												{t('events.start_datetime')}
											</Typography>
											<Typography className="event-field-value">{startDate + ' ' + startTime}</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<Typography className="event-field-value event-field-title">
												{t('events.end_datetime')}
											</Typography>
											<Typography className="event-field-value">{endDate + ' ' + endTime}</Typography>
										</Grid>
									</Grid>
								</Box>
							);
						})}
					</>
				)}
			</Box>
			{(event_details?.formData?.location_type || event_details?.eventLocations) && (
				<Box className="box-heading fight-cc-bx">
					<Typography className="box-title">{t('events.location')}</Typography>
					<IconButton>
						<BpencilIcon
							className="pencil-icon"
							onClick={() => {
								currentCreateEventTab(0);
							}}
						/>
					</IconButton>
				</Box>
			)}
			<Box>
				{event_details?.formData?.location_type && (
					<Box>
						<Typography className="event-field">{t('events.location_type')}</Typography>
						<Typography className="event-field-value">{locationType[0]?.name}</Typography>
					</Box>
				)}

				{event_details && event_details?.eventLocations && (
					<>
						{event_details?.eventLocations?.map((location, index) => {
							return (
								<Box>
									<Typography className="event-field">{t('events.location') + ` ${index + 1}`}</Typography>
									<Typography className="event-field-value">{location?.address}</Typography>
								</Box>
							);
						})}
					</>
				)}
			</Box>
			{event_details && event_details?.eventUrls && (
				<Box className="box-heading fight-cc-bx">
					<Typography className="box-title">{t('events.url')}</Typography>
					<IconButton>
						<BpencilIcon
							className="pencil-icon"
							onClick={() => {
								currentCreateEventTab(0);
							}}
						/>
					</IconButton>
				</Box>
			)}

			<Box>
				{event_details && event_details?.eventUrls && (
					<>
						{event_details?.eventUrls?.map((url, index) => {
							return (
								<Box>
									<Typography className="event-field">{t('events.url') + ` ${index + 1}`}</Typography>
									<Typography className="event-field-value">{url?.data}</Typography>
								</Box>
							);
						})}
					</>
				)}
			</Box>
			<Box className="box-heading fight-cc-bx">
				<Typography className="box-title">{t('events.admins')}</Typography>
				<IconButton>
					<BpencilIcon
						className="pencil-icon"
						onClick={() => {
							if (super_event_status && category?.name === 'Card') {
								currentSuperEventPage(1);
								currentCreateEventTab(3);
							} else {
								currentCreateEventTab(3);
							}
						}}
					/>
				</IconButton>
			</Box>
			<Box>
				<Typography className="event-field event-field-user">{t('events.admins')}</Typography>
				{event_admin &&
					event_admin?.map((admin, index) => {
						return admin.receiver_type === 'user' ? (
							admin.receiver_id.map((adminUser, uindex) => {
								return adminUser?.id ? (
									<Box className="event-user-profile">
										<Avatar className="event-profile" src={adminUser?.item_profile_data} />
										<Typography className="event-field-value">{adminUser?.user_name}</Typography>
									</Box>
								) : (
									<Box className="event-user-profile">
										<Avatar className="event-profile" src={adminUser?.profile_pic} />
										<Typography className="event-field-value">{adminUser?.OauthUserData?.user_name}</Typography>
									</Box>
								);
							})
						) : (
							<></>
						);
					})}
				{/*
        <Box className="event-user-profile">
          <Avatar className="event-profile" />
          <Typography className="event-field-value">jahanvi patoliya</Typography>
        </Box> */}
			</Box>
		</Box>
	);
};
