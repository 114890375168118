import { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, Grid, MenuItem, IconButton, Popover } from '@material-ui/core';
// import { ChromePicker } from 'react-color';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/images/icons/events/info.svg';
import './category.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { superEventCategoryData } from 'src/redux-state/actions/createEvent';
import { useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const Category = ({ superEventCategoryData }) => {
	const { t } = useTranslation();

	const super_event_details = useSelector((state) => state?.Events);
	const formRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [cardTypeList, setCardTypeList] = useState([]);
	const [selectedData, setSelectedData] = useState('');
	// const [foreGround, setForeGround] = useState('#FFFFFF');
	// const [clrPicker, setClrPicker] = useState(false);
	// const [backGround, setBackGround] = useState('#FFFFFF');
	// const [backClrPicker, setBackClrPicker] = useState(false);

	const opens = Boolean(anchorEl);
	const ids = opens ? 'simple-popover' : undefined;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	// const onColorPickerInfoChange = color => {
	//     console.log("Main Color Change", color);
	//     setForeGround(color?.hex)
	// };
	// const handleColorPicker = () => {
	//     clrPicker ? setClrPicker(false) : setClrPicker(true);
	// }
	// const handleBackColorPicker = () => {
	//     backClrPicker ? setBackClrPicker(false) : setBackClrPicker(true);
	// }
	// const onBackColorPickerInfoChange = color => {
	//     console.log("Main Color Change", color);
	//     setBackGround(color?.hex)
	// };
	const fetchCardsTypeList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCardsTypeList);

			if (status === 200) {
				setCardTypeList(data?.getEventType);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchCardsTypeList();
	}, []);
	useEffect(() => {
		var superEventCDetails = super_event_details?.super_event_category_details;
		var eventIndex = `eventindex-${super_event_details?.super_event_page}`;
		var obj = {
			type: 'standard',
			name_meta_category_id: selectedData?.parent_id,
			type_meta_category_id: selectedData?.id,
		};

		superEventCategoryData({ ...superEventCDetails, [eventIndex]: obj });
	}, [selectedData]);

	return (
		<Box className="category-sec">
			<Typography className="category-title">{t('events.category')}</Typography>
			<Formik
				innerRef={formRef}
				initialValues={{
					name: '',
					type: '',
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required(t('validation.required_message')),
					type: Yup.string().required(t('validation.required_message')),
				})}
				onSubmit={(data) => {}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.name && errors.name)}
									fullWidth
									helperText={touched.name && errors.name}
									variant="filled"
									InputProps={{
										endAdornment: touched.name && errors.name ? <ErrorIcon /> : null,
									}}
									label={t('events.name')}
									placeholder={t('events.name')}
									className="matrix-textfield"
									size="small"
									name="name"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.name}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid d-flex ">
								<TextField
									error={Boolean(touched.type && errors.type)}
									fullWidth
									helperText={touched.type && errors.type}
									variant="filled"
									InputProps={{
										endAdornment: touched.type && errors.type ? <ErrorIcon /> : null,
									}}
									label={t('events.type')}
									placeholder={t('events.type')}
									className="matrix-textfield"
									size="small"
									name="type"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.type}
									select
								>
									{cardTypeList &&
										cardTypeList.map((obj, index) => {
											return (
												<MenuItem key={index} value={obj?.id} onClick={() => setSelectedData(obj)}>
													{obj?.name}
												</MenuItem>
											);
										})}
								</TextField>
								<Popover
									className="cr-popover"
									id={ids}
									open={opens}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
								>
									<Box className="tooltip-data">
										<Typography className="tooltip-header">{t('events.cat_tooltipHead')}</Typography>
										<Typography>{t('events.cat_tooltip')}</Typography>
									</Box>
								</Popover>
								<IconButton>
									<InfoIcon onClick={(e) => handleClick(e)} />
								</IconButton>
							</Grid>
							{/* <Grid
                                item
                                xs={12}
                                md={12}
                                className="text-field-grid date-birth-field  chromePicker-parent-sec"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    className="date-field"
                                >
                                    {/* <TextField
                                        error={Boolean(touched.start_time && errors.start_time)}
                                        fullWidth
                                        helperText={touched.start_time && errors.start_time}
                                        variant="filled"
                                        // InputProps={{
                                        //   endAdornment: <ClockIcon />
                                        // }}
                                        label={t('events.start_datetime')}
                                        placeholder={t('events.start_datetime')}
                                        className="matrix-textfield"
                                        size="small"
                                        name="start_time"
                                        type="color"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={values.start_time}
                                    />
                                     <SketchPicker />
                                    <Box className='color-picker-box'>
                                        <Box className='color-box' onClick={handleBackColorPicker} style={{ backgroundColor: backGround }}></Box>
                                        <Typography className='color-name'>{backGround}</Typography>
                                    </Box>
                                </Grid>
                                {
                                    backClrPicker && <ChromePicker
                                        onChangeComplete={onBackColorPickerInfoChange}
                                        disableAlpha={true}
                                        className='chromePicker-back'
                                    />
                                }
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    className="age-field"
                                >
                                    <Box className='color-picker-box'>
                                        <Box className='color-box' onClick={handleColorPicker} style={{ backgroundColor: foreGround }}></Box>
                                        <Typography className='color-name'>{foreGround}</Typography>
                                    </Box>
                                </Grid>
                                {
                                    clrPicker && <ChromePicker
                                        onChangeComplete={onColorPickerInfoChange}
                                        disableAlpha={true}
                                        className='chromePicker-fore'
                                    />
                                }
                            </Grid> */}
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
