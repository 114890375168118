import React, { useEffect, useMemo, useState } from 'react';
import { Page } from 'src/components/ui';
import { colors, Fade } from '@material-ui/core';
import {
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableBody,
	TableHead,
	TableCell,
	Button,
	CircularProgress,
	Box,
	Radio
} from '@material-ui/core';
import './user-sales.scss';
import { useNavigate } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const UserSales = () => {
	// const [rowData, setRowData] = useState([]);
	const navigate = useNavigate();
	const [fetchedData, setFetchedData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (fetchedData.length > 0) {
			setLoading(false);

			return;
		}

		(async () => {
			const { data, status } = await axiosInstance.get('/private/user/get-purchase');

			if (status === 200) {
				const formattedData = data.data.map((item) => {
					return {
						...item,
						date: new Date(item.date).toLocaleDateString(),
						amount: item.amount / 100 + ' USD',
						post_id: item.post_id,
					};
				});

				setFetchedData(formattedData);
				setLoading(false);
			}
		})();
	}, []);

	const rowData = useMemo(() => {
		return fetchedData;
	}, [fetchedData]);

	const columns = [
		{
			width: 100,
			label: 'Post Title',
			dataKey: 'name',
		},
		{
			width: 50,
			label: 'Price Paid',
			dataKey: 'amount',
			type: 'string',
		},
		{
			width: 50,
			label: 'Purchase Date',
			dataKey: 'date',
			type: 'date',
		},
		{
			width: 50,
			label: 'User Name',
			dataKey: 'user_full_name',
			type: 'string'
		},
		{
			width: 50,
			label: 'Remitted',
			dataKey: 'is_remitted',
		},
		{
			width: 50,
			label: 'Amount Remitted',
			dataKey: 'amount_remitted',
			type: 'number'
		},
		{
			width: 50,
			label: 'Date Remitted',
			dataKey: 'remitted_at',
			type: 'date',
		},
		{
			width: 50,
			label: 'View Post',
			dataKey: 'post_id',
			numeric: true,
		},
	];

	const VirtuosoTableComponents = {
		Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
		Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
		TableHead,
		TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
		TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
	};

	function fixedHeaderContent() {
		return (
			<TableRow style={{ backgroundColor: colors.common.black }}>
				{columns.map((column) => (
					<TableCell
						key={column.dataKey}
						variant="head"
						align={column.numeric || false ? 'right' : 'left'}
						style={{ width: column.width, color: colors.common.white }}
					>
						{column.label}
					</TableCell>
				))}
			</TableRow>
		);
	}

	function rowContent(_index, row) {
		return (
			<React.Fragment>
				{columns.map((column) => (
					<TableCell
						style={{ backgroundColor: colors.common.black, color: colors.common.white }}
						key={column.dataKey}
						align={column.numeric || false ? 'right' : 'left'}
					>
						{column.dataKey === 'post_id' ? (
							<Button
								variant="contained"
								onClick={() => {
									navigate(`/post/${row[column.dataKey]}`);
								}}
							>
								View
							</Button>
						) : column.dataKey === 'is_remitted' ? (
							<>{row[column.dataKey] ? <CheckIcon /> : <ClearIcon />}</>
						) : (
							row[column.dataKey]
						)}
					</TableCell>
				))}
			</React.Fragment>
		);
	}

	return (
		<Page title="User total sales">
			<Paper style={{ height: '700px', width: '100%' }} variant="outlined" className="table-container">
				{loading ? (
					<Box
						sx={{
							backgroundColor: 'black',
							height: '100%',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<CircularProgress style={{ margin: 'auto', display: 'block', color: 'white' }} />
					</Box>
				) : (
					<Fade in={!loading} timeout={500}>
						<TableVirtuoso
							data={rowData}
							components={VirtuosoTableComponents}
							fixedHeaderContent={fixedHeaderContent}
							itemContent={rowContent}
						/>
					</Fade>
				)}
			</Paper>
		</Page>
	);
};

export default UserSales;
