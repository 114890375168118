import React from 'react'; // useContext
import { Box } from '@material-ui/core';
import { ReferralBanner } from './ReferralBanner';
import { ReferralSection } from './ReferralSection';
import './referrals.scss';
import { Page } from 'src/components/ui';

const ReferralView = () => {
	return (
		<Page title="Referrals | Combat Matrix">
			<Box>
				<Box className="referral-container">
					<ReferralBanner />
					<ReferralSection />
				</Box>
			</Box>
		</Page>
	);
};

export default ReferralView;
