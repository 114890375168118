import { makeStyles } from '@material-ui/core';

export const useLayoutStyles = (backgroundImage) =>
	makeStyles((theme) => {
		const properties = {
			wrapper: {
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
				backgroundColor: '#202020',
			},
			content: {
				flex: '1',
			},
			button: {
				color: '#fff',
				fontSize: '21px',
				fontFamily: 'Staatliches, Alumni Sans, sans-serif',
				fontWeight: '400',
				justifyContent: 'flex-start',
				letterSpacing: 0,
				padding: '0px 8px',
				textTransform: 'none',
				'&:hover': {
					backgroundColor: 'transparent',
					color: '#438ac1',
				},
			},
			buttonActive: {
				color: '#438ac1',
			},
		};

		if (backgroundImage) {
			properties.wrapper = {
				...properties.wrapper,
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5) 100%), url(${backgroundImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			};
		}

		return properties;
	});
