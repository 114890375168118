import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Grid, Tab, Typography, MenuItem } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import './eventFight.scss';
import { FightDetailsTab } from './FightDetailsTab';
import { CombatantDetailsTab } from './CombatantDetailsTab';
import { useTranslation } from 'react-i18next';

export const EventFight = ({
	fightIndex,
	fightTitleList,
	tourFightList,
	fightRuleList,
	titleTypeList,
	eventFightSides,
	disciplinesData,
	allUsers,
	weightClasses,
	create_data,
}) => {
	const { t } = useTranslation();

	const [tab, setTab] = useState('1');
	const tab_list = [
		{ id: 1, name: t('events.fight_details') },
		{ id: 2, name: t('events.combatant_details') },
	];
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		setTab('1');
	}, [fightIndex]);

	return (
		<Box className="event-fight-cover">
			<Typography className="event-fight-heading">{t('events.fight')}</Typography>
			<TabContext value={String(tab)}>
				<Box className="actions-header mb-18 mt-27">
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler} aria-label="action tabs">
						{tab_list.map((obj, index) => {
							return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
						})}
					</TabList>
				</Box>
				<TabPanel value="1" className="p-0">
					<FightDetailsTab
						fightIndex={fightIndex}
						fightTitleList={fightTitleList}
						tourFightList={tourFightList}
						fightRuleList={fightRuleList}
						disciplinesData={disciplinesData}
						// eventFightCard={eventFightCard}
						// eventFightData={eventFightData}
						create_data={create_data}
						tab={tab}
					/>
				</TabPanel>
				<TabPanel value="2" className="p-0">
					<CombatantDetailsTab
						fightIndex={fightIndex}
						titleTypeList={titleTypeList}
						eventFightSides={eventFightSides}
						allUsers={allUsers}
						weightClasses={weightClasses}
						// eventFightCard={eventFightCard}
						create_data={create_data}
						// eventFightData={eventFightData}
						tab={tab}
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
};
