import { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

import { ScrollToTop } from '../ScrollTop';
import { Config } from 'src/helpers/context';

const useStyles = makeStyles(() => ({
	wrapper: {
		color: 'white',
	},
}));

export const Page = forwardRef(
	({ children, title = '', description = '', image = '', url = `${Config?.DomainName}`, className, ...rest }, ref) => {
		const classes = useStyles();

		return (
			<div ref={ref} {...rest} className={cn(className, classes.wrapper)}>
				<ScrollToTop />
				<Helmet>
					<title>{title}</title>
					<meta property="og:title" content={title} />
					<meta property="og:image" content={image} />
					<meta property="og:description" content={description} />
					<meta property="og:url" content={url} />
				</Helmet>
				{children}
			</div>
		);
	},
);

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
};
