import { Box, Button, ButtonGroup } from '@material-ui/core';
import './request.scss';

export const RequestBlock = ({ id, acceptText, ignoreText, onAccept, onIgnore }) => {
	return (
		<Box className="request-block">
			<ButtonGroup>
				<Button variant="outlined" onClick={onIgnore}>
					{ignoreText}
				</Button>
				<Button variant="contained" onClick={onAccept}>
					{acceptText}
				</Button>
			</ButtonGroup>
		</Box>
	);
};
