import { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Typography, Avatar, Grid, Link } from '@material-ui/core';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
import { ReactComponent as PencilIcon } from 'src/assets/images/icons/pencilWhite.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import * as rdd from 'react-device-detect';
import { ViewPortContext } from 'src/App';
// import { ReactComponent as ProfileUploadIcon } from 'src/assets/images/icons/com-img-placeholder.svg';
import { ReactComponent as ShareIcon } from 'src/assets/images/icons/share.svg';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useParams } from 'react-router-dom';
import { DialogBox } from 'src/components/ui';
import Viewer from 'react-viewer';
import { Share, MobileDrawer } from 'src/components/ui';
// import EditProfileDetails from 'src/views/component/UI/EditProfile';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/tapology.svg';
import { ReactComponent as SnapchatIcon } from 'src/assets/images/icons/snapchat.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/twitch.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/linkedin.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/tiktok.svg';
// import { ReactComponent as SmoothcompIcon } from 'src/assets/images/icons/smoothcomp.svg';
import SherdogIcon from 'src/assets/images/icons/bsherdog.png';
import { DrawerContent } from '../../MobileBlock/DrawerContent';
import { useTranslation } from 'react-i18next';

var linksObj = {
	facebook: ' https://www.facebook.com',
	instagram: 'https://www.instagram.com',
	twitter: 'https://twitter.com',
	tapology: 'https://tapology.com/fightcenter/fighters',
	snapchat: 'https://www.snapchat.com',
	youtube: 'https://youtube.com',
	twitch: 'https://twitch.tv',
	linkedin: 'https://www.linkedin.com/in',
	tiktok: 'https://www.tiktok.com/@',
	sherdog: 'https://sherdog.com/fighter/',
	// smoothcomp: 'https://smoothcomp.com/en/profile'
};

// SOCIAL MEDIA ICON HANDLER
function SocialIconHandler(data) {
	var checkForSlash = data?.data?.charAt(0) === '/' ? true : false;

	switch (data?.type) {
		case 'URL_facebook':
			var socialLink = checkForSlash ? linksObj?.facebook + data?.data : linksObj?.facebook + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<FaceBookIcon />
				</Link>
			);
		case 'URL_instagram':
			socialLink = checkForSlash ? linksObj?.instagram + data?.data : linksObj?.instagram + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<InstagramIcon />
				</Link>
			);
		case 'URL_twitter':
			socialLink = checkForSlash ? linksObj?.twitter + data?.data : linksObj?.twitter + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitterIcon />
				</Link>
			);
		case 'URL_tapology':
			socialLink = checkForSlash ? linksObj?.tapology + data?.data : linksObj?.tapology + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TapologyIcon />
				</Link>
			);
		case 'URL_snapchat':
			socialLink = checkForSlash ? linksObj?.snapchat + data?.data : linksObj?.snapchat + '/add/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SnapchatIcon />
				</Link>
			);
		case 'URL_youtube':
			socialLink = checkForSlash ? linksObj?.youtube + data?.data : linksObj?.youtube + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<YouTubeIcon />
				</Link>
			);
		case 'URL_twitch':
			socialLink = checkForSlash ? linksObj?.twitch + data?.data : linksObj?.twitch + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitchIcon />
				</Link>
			);
		case 'URL_linkedin':
			socialLink = checkForSlash ? linksObj?.linkedin + data?.data : linksObj?.linkedin + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<LinkedInIcon />
				</Link>
			);
		case 'URL_tiktok':
			socialLink = checkForSlash ? linksObj?.tiktok + data?.data : linksObj?.tiktok + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TikTokIcon />
				</Link>
			);
		case 'URL_sherdog':
			socialLink = checkForSlash ? linksObj?.sherdog + data?.data : linksObj?.sherdog + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<img src={SherdogIcon} alt="sherdog-icon" />
				</Link>
			);
		// case 'URL_smoothcomp':
		//   socialLink = checkForSlash
		//     ? linksObj?.smoothcomp + data?.data
		//     : linksObj?.smoothcomp + '/' + data?.data;
		//   return (
		//     <Link href={socialLink} target="_blank">
		//       <SmoothcompIcon />
		//     </Link>
		//   );
		default:
		// socialLink = checkForSlash
		//   ? linksObj?.facebook + data?.data
		//   : linksObj?.facebook + '/' + data?.data;
		// return (
		//   <Link href={socialLink} target="_blank">
		//     <FaceBookIcon />
		//   </Link>
		// );
	}
}
export const RightUserInfo = ({
	UserPlaceholderIcon,
	orgDetails,
	classes,
	onopenform,
	userStatus,
	fetchOrganisationDetails,
	editSocialLink,
	EditProfile,
}) => {
	const { t } = useTranslation();

	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	const [imageId, setImageId] = useState('');
	const [previewImg, setPreviewImg] = useState('');
	const [open, setOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const { id } = useParams();

	const auth = useSelector((state) => state?.Combat?.authData) || fetchFromStorage('authData');

	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	// OPEN OR CLOSE DRAWER
	const toggleDrawer = (open) => {
		setOpenDrawer(open);
	};

	const followOrganisation = async (id, type) => {
		var requestParams = {
			organisation_id: id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.followOrganisation + `?type=${type}`, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchOrganisationDetails();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const updateOrganisation = async (imgId) => {
		var requestParams = {
			id: orgDetails?.id,
			item_profile_id: imgId,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateOrganisation, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchOrganisationDetails();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const ImageUploadHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setImageId(data?.data?.id);
				setPreviewImg(data?.data?.location);
				updateOrganisation(data?.data?.id);
			}
		}
	};
	// DIALOG HANDLER
	const combatDialogHandler = () => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	useEffect(() => {
		let splitdata = orgDetails && orgDetails?.item_profile_id?.split('/');

		splitdata = splitdata && splitdata[splitdata.length - 1];
		splitdata && setPreviewImg(splitdata);
	}, [orgDetails]);

	return (
		<>
			<Box className="my-right-top">
				<IconButton
					className="org-share"
					onClick={() => {
						combatDialogHandler();
					}}
				>
					<ShareIcon />
				</IconButton>
				<Box className="my-right-img">
					<Box className="my-right-avatar">
						<Box className="right-img">
							{/* <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                multiple
                className={classes.input}
              />
              <label htmlFor="contained-button-file">
                <IconButton component="span">
                 <Avatar src={UserPlaceholderIcon} />
                  <Avatar src={orgDetails?.item_profile_id} />

                  <CameraIcon className="camera-icon" />
                </IconButton>
              </label> */}
							{userStatus === 'community_owner' ? (
								previewImg !== '' ? (
									windowWidth > breakPoint ? (
										// DESKTOP SIZE AVATAR WITHOUT PIC
										<Box textAlign={'center'}>
											<input
												accept="image/*"
												style={{ display: 'none' }}
												id="icon-button-file"
												type="file"
												onChange={ImageUploadHandler}
											/>
											<label htmlFor="icon-button-file">
												<IconButton
													color="primary"
													aria-label="upload picture"
													component="span"
													style={{ position: 'relative' }}
												>
													<Avatar src={URLS.mediaURL + previewImg} alt="profile-picture" />
													<CameraIcon className="camera-icon" />
												</IconButton>
											</label>
										</Box>
									) : (
										// MOBILE SIZE AVATAR WITH PIC
										<Box textAlign={'center'}>
											{rdd.isIOS && (
												<input
													accept="image/*"
													style={{ display: 'none' }}
													id="icon-button-file"
													type="file"
													onChange={ImageUploadHandler}
												/>
											)}
											<label htmlFor={rdd.isIOS && 'icon-button-file'}>
												<IconButton
													color="primary"
													aria-label="upload picture"
													component="span"
													style={{ position: 'relative' }}
													onClick={
														rdd.isIOS
															? () => {}
															: () => {
																	toggleDrawer(true);
															  }
													}
												>
													<Avatar src={URLS.mediaURL + previewImg} alt="profile-picture" />
													<CameraIcon className="camera-icon" />
												</IconButton>
											</label>
										</Box>
									)
								) : windowWidth > breakPoint ? (
									<Box textAlign={'center'}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="icon-button-file"
											type="file"
											onChange={ImageUploadHandler}
										/>
										<label htmlFor="icon-button-file">
											<IconButton color="primary" aria-label="upload picture" component="span">
												<Avatar src={URLS.mediaURL + previewImg} />
												<CameraIcon className="camera-icon" />
											</IconButton>
										</label>
									</Box>
								) : (
									<Box textAlign={'center'}>
										{rdd.isIOS && (
											<input
												accept="image/*"
												style={{ display: 'none' }}
												id="icon-button-file"
												type="file"
												onChange={ImageUploadHandler}
											/>
										)}
										<label htmlFor={rdd.isIOS && 'icon-button-file'}>
											<IconButton
												color="primary"
												aria-label="upload picture"
												component="span"
												onClick={
													rdd.isIOS
														? () => {}
														: () => {
																toggleDrawer(true);
														  }
												}
											>
												<Avatar src={URLS.mediaURL + previewImg} />
												<CameraIcon className="camera-icon" />
											</IconButton>
										</label>
									</Box>
								)
							) : (
								<Box textAlign={'center'}>
									<label htmlFor="icon-button-file">
										<IconButton color="primary" aria-label="upload picture" component="span">
											<Avatar
												src={URLS.mediaURL + previewImg}
												onClick={() => {
													setVisible(true);
												}}
											/>
											{previewImg && (
												<Viewer
													visible={visible}
													onClose={() => {
														setVisible(false);
													}}
													images={[
														{
															src: URLS.mediaURL + previewImg,
															title: 'cover image',
														},
													]}
													downloadable
													noFooter={true}
													drag={false}
													zoomable={false}
													rotatable={false}
													scalable={false}
													disableMouseZoom={true}
												/>
											)}
										</IconButton>
									</label>
								</Box>
							)}
						</Box>
					</Box>
					<Box className="myright-names">
						{/* NICKNAME */}
						<Typography className="myright-wildcard">{orgDetails?.org_type_name}</Typography>
						<Typography className="right-username">{orgDetails?.name}</Typography>
						<Typography className="member-since">{orgDetails?.status}</Typography>
						<Box className="social-icons">
							<Grid container>
								{orgDetails?.meta_data // USER SOCIAL MEDIA URLS
									? orgDetails?.meta_data?.length > 0
										? orgDetails?.meta_data
												?.filter((data) => data?.type !== '')
												?.filter((data) => data?.type !== 'URI')
												?.filter((data) => data?.type !== 'other')
												?.filter((data) => data?.type !== 'weight_class')
												?.filter((data) => data?.type !== 'location_fighting')
												?.map((data, index) => {
													return (
														<>
															{data?.data !== '' && (
																<Grid item className="social-link" key={index}>
																	{SocialIconHandler(data)}
																</Grid>
															)}
														</>
													);
												})
										: null
									: null}
							</Grid>
						</Box>
					</Box>
				</Box>

				<Box className="social-right-edit-actions">
					{userStatus === 'community_owner' ? (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Button
									variant="contained"
									className="edt-profile-btn"
									fullWidth
									startIcon={<PencilIcon />}
									onClick={() => {
										onopenform();
										editSocialLink();
									}}
								>
									{t('myprofile.edit_social')}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant="contained"
									className="edt-profile-btn"
									fullWidth
									startIcon={<PencilIcon />}
									onClick={() => {
										onopenform();
										EditProfile();
									}}
								>
									{t('myprofile.edit_profile')}
								</Button>
							</Grid>
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12}>
								<Button
									variant="contained"
									className={orgDetails?.isFollow ? 'connection-have-btn' : 'edt-profile-btn'}
									fullWidth
									onClick={() =>
										orgDetails?.isFollow
											? followOrganisation(orgDetails?.id, 'unfollow')
											: followOrganisation(orgDetails?.id, 'follow')
									}
								>
									{orgDetails?.isFollow ? t('following') : t('follow')}
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>

				{/* USER FOLLOWRS & MEMBERS COUNT */}
				<Box className="right-prof-counts">
					{/* USER MEMBERS COUNT */}
					<Box className="social-count-bx">
						<Typography className="social-count">{orgDetails?.total_member}</Typography>
						<Typography className="social-act-name">{t('organisation.members')}</Typography>
					</Box>

					{/* USER FOLLOWRS COUNT */}
					<Box className="social-count-bx">
						<Typography className="social-count">{orgDetails?.total_follower}</Typography>
						<Typography className="social-act-name">{t('organisation.followers')}</Typography>
					</Box>
				</Box>

				{/* USER ADDRESS */}
				<Box className="address-block">
					<Typography className="address-title">{t('organisation.address')}</Typography>
					<Typography className="address">{orgDetails?.address}</Typography>
				</Box>
			</Box>
			<DialogBox
				open={open}
				className="share-dailog-box"
				handleClose={closeDialog}
				title={t('community.share')}
				content={
					<Share fromWhere="organisation-details/" urlId={id} landType="organisation" handleClose={closeDialog} />
				}
			/>
			<MobileDrawer
				anchor={'bottom'}
				open={openDrawer}
				onClose={() => toggleDrawer(false)}
				className="take-upload-photo"
				title={t('myprofile.upload_profile_cover_image')}
				content={<DrawerContent imgHandler={ImageUploadHandler} toggleDrawer={toggleDrawer} />}
			/>
		</>
	);
};
