import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { Logo } from 'src/components/ui';
import { clearStorage } from 'src/helpers/context';

import items from '../menu';
import styles from './TopBar.module.scss';

const useStyles = makeStyles((theme) => ({
	root: { background: '#000' },
	title: {
		marginRight: 'auto',
	},
	button: {
		color: '#fff',
		fontSize: '21px',
		fontFamily: 'Staatliches, Alumni Sans, sans-serif',
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '34px',
		'&:last-child': {
			marginRight: 0,
		},
	},
	active: {
		color: 'red',
		fontWeight: 400,
	},
}));

export const TopBar = ({ logoutUser, className, home, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<AppBar className={`${clsx(classes.root, className)} ${styles.appBar}`} elevation={0} {...rest}>
			<Toolbar>
				<NavLink to={home ? home : '/'} className={styles.logoAnchor}>
					<Logo />
				</NavLink>
				<Box flexGrow={1} />
				<Hidden mdDown>
					{items.length > 0 &&
						items.map((menu, index) => {
							return (
								<Button
									key={index}
									className={classes.button}
									component={NavLink}
									to={menu.href}
									disableElevation
									disableFocusRipple
									disableRipple
									activeClassName={location.pathname.includes(menu.href) ? classes.active : ''}
								>
									<span className={classes.title}>{menu.title}</span>
								</Button>
							);
						})}
				</Hidden>
				<Button
					className={styles.authLoginButton}
					variant="contained"
					onClick={() => {
						logoutUser({ token: null });
						clearStorage();
						window.location = '/';
					}}
				>
					Logout
				</Button>
				{/* <Hidden lgUp> */}
				<Hidden xsUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};
