import { eventDetails, eventStatus, superEventDetails } from '../../../../../../redux-state/actions/createEvent';
import { connect } from 'react-redux';
import { EventDetails as EventDetailsComponent } from './EventDetails';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		superEventDetails: (data) => dispatch(superEventDetails(data)),
		eventDetails: (data) => dispatch(eventDetails(data)),
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const EventDetails = connect(mapStateToProps, mapDispatchToProps)(EventDetailsComponent);
