export * from './MobileMenu';

import { connect } from 'react-redux';
import { logoutUser } from 'src/redux-state/actions/actions';
import { MobileMenu as MobileMenuComponent } from './MobileMenu';

const mapDispatchToProps = (dispatch) => {
	return {
		logoutUser: (data) => dispatch(logoutUser(data)),
	};
};

export const MobileMenu = connect(null, mapDispatchToProps)(MobileMenuComponent);
