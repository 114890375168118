import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'src/redux-state';

import App from './App';
import initI18n from './utils/i18n';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Config } from './helpers/context';

initI18n();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<GoogleOAuthProvider clientId={Config.googleClientID}>
				<App />
			</GoogleOAuthProvider>
		</BrowserRouter>
	</Provider>,
);

serviceWorker.unregister();
