import { getNewsfeedPostList } from '../../../../redux-state/actions/actions';
import { connect } from 'react-redux';
import { Left as LeftComponent } from './Left';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getNewsfeedPostList: (data) => dispatch(getNewsfeedPostList(data)),
	};
};

export const Left = connect(mapStateToProps, mapDispatchToProps)(LeftComponent);
