import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { TextField } from 'src/components/ui';
import './editSocialLink.scss';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/bFaceBook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/bInstagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/bTwitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/bTapology.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/bLinkedin.svg';
import ShareDogIcon from 'src/assets/images/icons/bsherdog.png';
import { ReactComponent as SnapChatIcon } from 'src/assets/images/icons/bSnapchat.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/bTiktok.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/bTwitch.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/bYouTube.svg';
import { ReactComponent as SmoothCompIcon } from 'src/assets/images/icons/bsmoothcomp.svg';
import { useTranslation } from 'react-i18next';

export const EditSocialLink = ({ data, token, getMyProfile, closeDialog, setIsModalLoading, isModalLoading }) => {
	const { t } = useTranslation();

	// const isHtmlIncludes = (val) => {
	//   console.log('isHtmlIncludes', val)
	//   var regexAllTags = /<([a-zA-Z1-6]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/gim
	//   let result = val.match(regexAllTags)
	//   return !result
	// }
	return (
		<Box className="edit-social-block">
			<Formik
				initialValues={{
					facebook: data?.find((data) => data?.type === 'URL_facebook')?.data || '',
					instagram: data?.find((data) => data?.type === 'URL_instagram')?.data || '',
					twitter: data?.find((data) => data?.type === 'URL_twitter')?.data || '',
					tapology: data?.find((data) => data?.type === 'URL_tapology')?.data || '',
					snapchat: data?.find((data) => data?.type === 'URL_snapchat')?.data || '',
					youtube: data?.find((data) => data?.type === 'URL_youtube')?.data || '',
					twitch: data?.find((data) => data?.type === 'URL_twitch')?.data || '',
					linkedin: data?.find((data) => data?.type === 'URL_linkedin')?.data || '',
					tiktok: data?.find((data) => data?.type === 'URL_tiktok')?.data || '',
					sherdog: data?.find((data) => data?.type === 'URL_sherdog')?.data || '',
					smoothcomp: data?.find((data) => data?.type === 'URL_smoothcomp')?.data || '',
				}}
				validationSchema={Yup.object().shape({
					// facebook: Yup.string().test('facebook', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// instagram: Yup.string().test('instagram', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// twitter: Yup.string().test('twitter', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// tapology: Yup.string().test('tapology', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// snapchat: Yup.string().test('snapchat', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// youtube: Yup.string().test('youtube', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// twitch: Yup.string().test('twitch', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// linkedin: Yup.string().test('linkedin', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// tiktok: Yup.string().test('tiktok', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// sherdog: Yup.string().test('sherdog', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value)),
					// smoothcomp: Yup.string().test('smoothcomp', t('validation.enter_valid_text'), (value) => value !== undefined && value !== '' && isHtmlIncludes(value))
					// facebook: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// instagram: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// twitter: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// tapology: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// snapchat: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// youtube: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// twitch: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// linkedin: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// tiktok: Yup.string().url(
					//   t('validation.enter_new_password')
					// ),
					// sherdog: Yup.string().url(
					//   t('validation.enter_new_password')
					// )
				})}
				// validationSchema={Yup.object().shape({
				//   facebook: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   instagram: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   twitter: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   tapology: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   snapchat: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   youtube: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   twitch: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   linkedin: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   tiktok: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   ),
				//   sherdog: Yup.string().matches(
				//     /((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
				//     'Enter correct url!'
				//   )
				// })}
				onSubmit={async (val) => {
					let requestData = [
						{ type: 'URL_facebook', data: val.facebook },
						{ type: 'URL_instagram', data: val.instagram },
						{ type: 'URL_twitter', data: val.twitter },
						{ type: 'URL_tapology', data: val.tapology },
						{ type: 'URL_snapchat', data: val.snapchat },
						{ type: 'URL_youtube', data: val.youtube },
						{ type: 'URL_twitch', data: val.twitch },
						{ type: 'URL_linkedin', data: val.linkedin },
						{ type: 'URL_tiktok', data: val.tiktok },
						{ type: 'URL_sherdog', data: val.sherdog },
						{ type: 'URL_smoothcomp', data: val.smoothcomp },
					];

					try {
						setIsModalLoading(true);
						const { status } = await axiosInstance.put(
							URLS.updateProfile,
							{
								user_meta: requestData,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
									'Content-Type': 'application/json',
								},
							},
						);

						if (status === 200) {
							setApiMessage('success', t('myprofile.profile_updated_success'));
							getMyProfile();
							closeDialog();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							{/* FACEBOOK */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<FaceBookIcon />
									<Typography className="social-url">{t('myprofile.facebook_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.facebook && errors.facebook)}
										fullWidth
										helperText={
											touched.facebook ? (
												touched.facebook && (
													<Box disply="flex" justifyContent="center">
														{errors.facebook}
														<span className="field-character-count">{values.facebook.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.facebook.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.facebook && errors.facebook ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('facebook')}
										placeholder={t('facebook')}
										className="matrix-textfield"
										size="small"
										name="facebook"
										onBlur={handleBlur}
										onChange={(e) => {
											// var re = /[a-zA-Z0-9\\\\/]$/
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										// onChange={handleChange}
										value={values.facebook}
									/>
								</Grid>
							</Grid>

							{/* INSTAGRAM */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<InstagramIcon />
									<Typography className="social-url">{t('myprofile.instagram_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.instagram && errors.instagram)}
										fullWidth
										helperText={
											touched.instagram ? (
												touched.instagram && (
													<Box disply="flex" justifyContent="center">
														{errors.instagram}
														<span className="field-character-count">{values.instagram.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.instagram.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.instagram && errors.instagram ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('instagram')}
										placeholder={t('instagram')}
										className="matrix-textfield"
										size="small"
										name="instagram"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.instagram}
									/>
								</Grid>
							</Grid>

							{/* TWITTER */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<TwitterIcon />
									<Typography className="social-url">{t('myprofile.twitter_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.twitter && errors.twitter)}
										fullWidth
										helperText={
											touched.twitter ? (
												touched.twitter && (
													<Box disply="flex" justifyContent="center">
														{errors.twitter}
														<span className="field-character-count">{values.twitter.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.twitter.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.twitter && errors.twitter ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('twitter')}
										placeholder={t('twitter')}
										className="matrix-textfield"
										size="small"
										name="twitter"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.twitter}
									/>
								</Grid>
							</Grid>

							{/* TAPOLOGY */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<TapologyIcon />
									<Typography className="social-url">{t('myprofile.tapology_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.tapology && errors.tapology)}
										fullWidth
										helperText={
											touched.tapology ? (
												touched.tapology && (
													<Box disply="flex" justifyContent="center">
														{errors.tapology}
														<span className="field-character-count">{values.tapology.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.tapology.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.tapology && errors.tapology ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('tapology')}
										placeholder={t('tapology')}
										className="matrix-textfield"
										size="small"
										name="tapology"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.tapology}
									/>
								</Grid>
							</Grid>

							{/* SNAPCHAT */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<SnapChatIcon />
									<Typography className="social-url">{t('myprofile.Snapchat')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.snapchat && errors.snapchat)}
										fullWidth
										helperText={
											touched.snapchat ? (
												touched.snapchat && (
													<Box disply="flex" justifyContent="center">
														{errors.snapchat}
														<span className="field-character-count">{values.snapchat.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.snapchat.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.snapchat && errors.snapchat ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('snapchat')}
										placeholder={t('snapchat')}
										className="matrix-textfield"
										size="small"
										name="snapchat"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.snapchat}
									/>
								</Grid>
							</Grid>

							{/* YOUTUBE */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<YouTubeIcon />
									<Typography className="social-url">{t('myprofile.youtube_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.youtube && errors.youtube)}
										fullWidth
										helperText={
											touched.youtube ? (
												touched.youtube && (
													<Box disply="flex" justifyContent="center">
														{errors.youtube}
														<span className="field-character-count">{values.youtube.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.youtube.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.youtube && errors.youtube ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('youtube')}
										placeholder={t('youtube')}
										className="matrix-textfield"
										size="small"
										name="youtube"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.youtube}
									/>
								</Grid>
							</Grid>

							{/* TWITCH */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<TwitchIcon />
									<Typography className="social-url">{t('myprofile.twitch_tv')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.twitch && errors.twitch)}
										fullWidth
										helperText={
											touched.twitch ? (
												touched.twitch && (
													<Box disply="flex" justifyContent="center">
														{errors.twitch}
														<span className="field-character-count">{values.twitch.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.twitch.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.twitch && errors.twitch ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('twitch')}
										placeholder={t('twitch')}
										className="matrix-textfield"
										size="small"
										name="twitch"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.twitch}
									/>
								</Grid>
							</Grid>

							{/* LINKEDIN */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<LinkedInIcon />
									<Typography className="social-url">{t('myprofile.linkedin_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.linkedin && errors.linkedin)}
										fullWidth
										helperText={
											touched.linkedin ? (
												touched.linkedin && (
													<Box disply="flex" justifyContent="center">
														{errors.linkedin}
														<span className="field-character-count">{values.linkedin.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.linkedin.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.linkedin && errors.linkedin ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('linkedin')}
										placeholder={t('linkedin')}
										className="matrix-textfield"
										size="small"
										name="linkedin"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.linkedin}
									/>
								</Grid>
							</Grid>

							{/* TIKTOK */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<TikTokIcon />
									<Typography className="social-url"> {t('myprofile.tiktok_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.tiktok && errors.tiktok)}
										fullWidth
										helperText={
											touched.tiktok ? (
												touched.tiktok && (
													<Box disply="flex" justifyContent="center">
														{errors.tiktok}
														<span className="field-character-count">{values.tiktok.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.tiktok.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.tiktok && errors.tiktok ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('tiktok')}
										placeholder={t('tiktok')}
										className="matrix-textfield"
										size="small"
										name="tiktok"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.tiktok}
									/>
								</Grid>
							</Grid>

							{/* SHERDOG */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<img src={ShareDogIcon} alt="sherdog-icon" />
									<Typography className="social-url">{t('myprofile.sherdog_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.sherdog && errors.sherdog)}
										fullWidth
										helperText={
											touched.sherdog ? (
												touched.sherdog && (
													<Box disply="flex" justifyContent="center">
														{errors.sherdog}
														<span className="field-character-count">{values.sherdog.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.sherdog.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.sherdog && errors.sherdog ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('sherdog')}
										placeholder={t('sherdog')}
										className="matrix-textfield"
										size="small"
										name="sherdog"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.sherdog}
									/>
								</Grid>
							</Grid>

							{/* SMOOTHCOMP */}
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={6} lg={6} className="age-field h-45">
									<SmoothCompIcon />
									<Typography className="social-url">{t('myprofile.smoothcomp_com')}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
									<TextField
										error={Boolean(touched.smoothcomp && errors.smoothcomp)}
										fullWidth
										helperText={
											touched.smoothcomp ? (
												touched.smoothcomp && (
													<Box disply="flex" justifyContent="center">
														{errors.smoothcomp}
														<span className="field-character-count">{values.smoothcomp.length}/200</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.smoothcomp.length}/200</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 200,
											endAdornment: touched.smoothcomp && errors.smoothcomp ? <ErrorIcon /> : null,
										}}
										inputProps={{ maxLength: 200 }}
										label={t('smoothcomp')}
										placeholder={t('smoothcomp')}
										className="matrix-textfield"
										size="small"
										name="smoothcomp"
										onBlur={handleBlur}
										onChange={(e) => {
											var re = /[a-zA-Z0-9\/\\_\.]$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										value={values.smoothcomp}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="dialog-submit-container dialog-sticky-btn">
								<Box className="dialog-submit-button">
									<Button disabled={isModalLoading} variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
