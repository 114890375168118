import { useNavigate } from 'react-router-dom'; //useNavigate
import { Box, Typography, Link } from '@material-ui/core';
import { RequestBlock } from 'src/components/ui';
import { ReactComponent as NotificationLogo } from 'src/assets/images/icons/noti-tras-logo.svg';
import './notification.scss';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';

import { getRedirectPath } from '../../../../helpers/getRedirectPath';

export const NotificationItem = ({
	name,
	image,
	request,
	requestData,
	notificationItem,
	readNotification,
	time,
	user,
}) => {
	const navigate = useNavigate();

	const notificationType =
		notificationItem && typeof notificationItem?.payload === 'string'
			? JSON.parse(notificationItem?.payload)
			: notificationItem?.payload;

	const redirectPath = getRedirectPath(user, notificationType);

	// community_connect
	// community_assign_role
	// post_delete
	// post_comment
	// account_verified
	// follow_user
	// friend_request

	const tabValue =
		notificationType?.type === 'transfer_owner'
			? 'members'
			: notificationType?.type === 'community_connect'
			? 'members'
			: notificationType?.type === 'accept_request'
			? 'members'
			: notificationType?.type === 'invite_community'
			? 'discussions'
			: notificationType?.type === 'community_assign_role'
			? 'members'
			: notificationType?.type === 'reject_request'
			? 'discussions'
			: notificationType?.type === 'block_request'
			? 'members'
			: notificationType?.type === 'unblock_request'
			? 'members'
			: 'discussions';

	const nestedTab = notificationType?.type === 'community_connect' ? 'request' : 'member';
	const fromUserId = notificationType?.type === 'invite_community' ? notificationType?.from_userId : '';
	const memeberTabValue = notificationType?.type === 'transfer_owner' ? 'transfer' : 'accepted';

	return (
		<Box
			className="notification-item"
			style={{ background: notificationItem?.status === 'unread' && '#438ac1' }}
			onClick={() => {
				readNotification(notificationItem?.id);
				navigate(redirectPath, {
					state: {
						modalText: name,
						memeberTabValue: memeberTabValue,
						showSelectedTab: tabValue,
						showNestedTab: nestedTab,
						passUserId: fromUserId,
						fromWhere: notificationType?.type,
						// comment_id: notificationItem?.id,
						post_id: notificationType?.post_id,
						comment_id:
							notificationType?.type === 'post_reaction' || notificationType?.type === 'post_vote'
								? notificationType?.post_comment_id
								: notificationType?.post_id,
						post_comment_id: notificationType?.post_comment_id,
						category_id: notificationType?.meta_category_id,
						fight_id: notificationType?.event_id,
					},
				});
				// notificationRedirect(notificationType)
				// notificationType?.type && notificationType?.type !== "account_verified" && redirectToUser()
			}}
		>
			<Link to={{ pathname: redirectPath, state: { showSelectedTab: tabValue } }}>
				<Box
					className="noti-left"
					// onClick={() => {
					//   navigate(redirectPath, { state: { showSelectedTab: tabValue } })
					// }}
				>
					<Box className="notification-image">
						<NotificationLogo />
						{/* <Avatar src={image} /> */}
					</Box>
					<Box className="notification-description">
						<Typography className="desc-txt">
							{renderHTML(
								anchorme({
									input: name.toString(),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
						</Typography>
						<Typography
							style={{
								color: notificationItem?.status === 'unread' ? '#D9E8F3' : '#9D9D9D',
							}}
							className="time-ago"
						>
							{time}
						</Typography>
					</Box>
				</Box>
			</Link>
			{request && (
				<Box className="noti-right">
					<RequestBlock acceptText={'Accept'} ignoreText={'Ignore'} id={12} onAccept={() => {}} onIgnore={() => {}} />
				</Box>
			)}
		</Box>
	);
};
