import { connect } from 'react-redux';
import { authUser } from 'src/redux-state/actions/actions';

import { VerifyOTP as VerifyOTPComponent } from './VerifyOTP';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (data) => dispatch(authUser(data)),
	};
};

export const VerifyOTP = connect(mapStateToProps, mapDispatchToProps)(VerifyOTPComponent);
