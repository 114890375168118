import { useNavigate } from 'react-router-dom';
import {
	Avatar,
	Box,
	Button,
	// Grid,
	IconButton,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { saveToStorage, fetchFromStorage } from 'src/helpers/context';
import { setApiMessage } from 'src/helpers/commonFunctions';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import { ReactComponent as PencilIcon } from 'src/assets/images/icons/pencil.svg';
import './confirm.scss';
import { identifiers } from 'src/helpers/constants/identifier';
import { useTranslation } from 'react-i18next';

export const Confirm = ({ dataa, authUser, profileConfirm, token, message }) => {
	const authData = dataa?.authData ? dataa?.authData : fetchFromStorage('authData');
	const navigate = useNavigate();
	const { t } = useTranslation();
	var previousPath = fetchFromStorage(identifiers?.previousPath);
	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	const submitHandler = async () => {
		let ItemId = dataa?.profile_details?.image?.itemId;

		let requestData = {
			personal_details: {
				first_name: dataa?.profile_details?.firstName,
				last_name: dataa?.profile_details?.surName,
				dob: dataa?.additional_details?.dob ? moment(dataa?.additional_details?.dob).utc().format() : '',
				country: dataa?.additional_details?.country,
			},
			claimProfileArr: dataa?.additional_details?.claimProfileID,
			// claim_profile_id: dataa?.additional_details?.claimProfileID,
			phone: dataa?.additional_details?.phone,
			type: dataa?.profile_type?.total,
			item_profile_id: ItemId,
			user_meta: [
				{ type: 'other', data: dataa?.profile_details?.username },
				{
					type: 'weight_high_kg',
					data: dataa?.additional_details?.measurment === 'Kilograms' ? dataa?.additional_details?.height_weight : '',
				},
				{
					type: 'weight_high_lb',
					data: dataa?.additional_details?.measurment === 'Pounds' ? dataa?.additional_details?.height_weight : '',
				},
				{
					type: 'weight_low_kg',
					data: dataa?.additional_details?.measurment === 'Kilograms' ? dataa?.additional_details?.lower_weight : '',
				},
				{
					type: 'weight_low_lb',
					data: dataa?.additional_details?.measurment === 'Pounds' ? dataa?.additional_details?.lower_weight : '',
				},
				{
					type: 'location_fighting',
					data: dataa?.additional_details?.fighting_out_of?.inputValue
						? dataa?.additional_details?.fighting_out_of?.inputValue
						: dataa?.additional_details?.fighting_out_of,
				},
			],
			user_about: {
				type: Number(dataa?.setup_org?.type),
				name: dataa?.setup_org?.about,
			},
			user_bio: {
				name: dataa?.additional_details?.bio,
			},
			address: {
				address: dataa?.additional_details?.address,
				lat: dataa?.additional_details?.lat,
				long: dataa?.additional_details?.long,
				city: dataa?.additional_details?.city,
				state: dataa?.additional_details?.state,
				country: dataa?.additional_details?.country,
			},
		};

		let orgData = {
			...requestData,
			org_item_profile_id: dataa?.setup_org?.image?.itemId || '',
			orgTypeId: dataa?.setup_org?.type,
			orgName: dataa?.setup_org?.name,
			orgAbout: dataa?.setup_org?.about,
		};

		try {
			const { status } = await axiosInstance.put(URLS.updateProfile, dataa?.setup_org_check ? orgData : requestData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setApiMessage('success', 'Profile Updated Successfully');
				profileConfirm(true);
				let setData = {
					...authData,
					OauthUserData: {
						...authData.OauthUserData,
						first_name: dataa?.profile_details?.firstName,
						last_name: dataa?.profile_details?.surName,
					},
					profile_status: true,
				};

				saveToStorage('authData', setData);
				authUser(setData);
				// try {
				// 	if ((rdd.isAndroid || rdd.isIOS) && emitterObject) {
				// 		emitterObject.postMessage('logout');
				// 	}
				// } catch (e) {
				// 	console.log(e);
				// }
				previousPath !== null
					? navigate(previousPath)
					: navigate(`/user/${authData?.user_meta?.data}`, { state: { owner: true } });
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const profileImg =
		dataa?.profile_details?.image?.img && dataa?.profile_details?.image?.img !== ''
			? URLS.mediaURL + dataa?.profile_details?.image?.img
			: '';

	const orgImg =
		dataa?.setup_org?.image?.img && dataa?.setup_org?.image?.img !== ''
			? URLS.mediaURL + dataa?.setup_org?.image?.img
			: '';

	const selectedTypes = dataa?.profile_type?.check
		? typeList.filter((obj) => {
				return dataa?.profile_type?.check.indexOf(obj?.id) !== -1;
		  })
		: [];

	return (
		<Box className="profile-details-form confirm-form">
			<Typography className="profile-details-title">{t('profile_confirm.title')}</Typography>

			<Box className="confirm-profile-box">
				<Box className="confirm-right">
					<Box className="confirmation-title-box">
						<Typography className="conf-title">{t('profile_confirm.user_profile')}</Typography>
						<IconButton
							onClick={() => {
								navigate('/profile/details');
							}}
						>
							<PencilIcon />
						</IconButton>
					</Box>

					<Box className="confirm-left">
						<Box className="profile-img">
							<IconButton
								color="primary"
								aria-label="upload picture"
								component="span"
								style={{ position: 'relative' }}
								onClick={() => {
									navigate('/profile/details');
								}}
							>
								<Avatar src={profileImg !== '' ? profileImg : UserPlaceholderIcon} />
								<CameraIcon className="camera-icon" />
							</IconButton>
						</Box>
					</Box>

					{/* Name */}
					<Typography className="conf-field-name">{t('profile_confirm.name')}</Typography>
					<Typography className="conf-user-name">
						{`${dataa?.profile_details?.firstName ? dataa?.profile_details?.firstName : ''} ${
							dataa?.profile_details?.surName ? dataa?.profile_details?.surName : ''
						}`}
					</Typography>

					{/* Nickname */}
					<Typography className="conf-field-name">{t('personal_details.nickname')}</Typography>
					<Box className="checkbox-field">
						<Typography className="email-mobile-text">
							{dataa?.profile_details?.username ? dataa?.profile_details?.username : ''}
						</Typography>
					</Box>

					<Box className="confirmation-title-box prof-type-head">
						<Typography className="conf-title">{t('profile_confirm.profile_types')}</Typography>
						<IconButton
							onClick={() => {
								navigate('/profile/type');
							}}
						>
							<PencilIcon />
						</IconButton>
					</Box>
					{/* Selected Profile Types */}
					<Box className="profile-types">
						{selectedTypes.map((data, index) => {
							return (
								<Typography key={index} className="conf-prof-type">
									{data?.name}
								</Typography>
							);
						})}
					</Box>

					{/* If Setup Organisation */}
					{dataa?.setup_org_check && (
						<Box>
							<Box className="confirmation-title-box prof-type-head">
								<Typography className="conf-title">{t('profile_confirm.org_profile')}</Typography>
								<IconButton
									onClick={() => {
										navigate('/profile/organisation');
									}}
								>
									<PencilIcon />
								</IconButton>
							</Box>

							{/* Organisation Image */}
							<Box className="confirm-left">
								<Box className="profile-img">
									<IconButton
										color="primary"
										aria-label="upload picture"
										component="span"
										style={{ position: 'relative' }}
										onClick={() => {
											navigate('/profile/organisation');
										}}
									>
										<Avatar src={orgImg ? orgImg : UserPlaceholderIcon} />
										<CameraIcon className="camera-icon" />
									</IconButton>
								</Box>
							</Box>

							{/*  Organisation Type */}
							<Typography className="conf-field-name">{t('setup_org.org_type')}</Typography>
							<Box className="checkbox-field">
								<Typography className="email-mobile-text">{dataa?.setup_org?.orgTypeName}</Typography>
							</Box>

							{/*  Organisation Name */}
							<Typography className="conf-field-name">{t('setup_org.name')}</Typography>
							<Box className="checkbox-field">
								<Typography className="email-mobile-text">{dataa?.setup_org?.name}</Typography>
							</Box>

							{/*  Organisation About */}
							<Typography className="conf-field-name">{t('setup_org.about')}</Typography>
							<Box className="checkbox-field">
								<Typography className="email-mobile-text">{dataa?.setup_org?.about}</Typography>
							</Box>
						</Box>
					)}
					{/* MVP-HIDE: CM-99*/}
					{/*<Box className="confirmation-title-box prof-type-head">*/}
					{/*	<Typography className="conf-title">{t('additional_details.optional_details')}</Typography>*/}
					{/*	<IconButton*/}
					{/*		onClick={() => {*/}
					{/*			navigate('/profile/optional');*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<PencilIcon />*/}
					{/*	</IconButton>*/}
					{/*</Box>*/}

					{/*/!* DOB *!/*/}
					{/*<Typography className="conf-field-name">{t('personal_details.dob')}</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">*/}
					{/*		{dataa?.additional_details?.dob ? moment(dataa?.additional_details?.dob).format('MM/DD/YYYY') : ''}*/}
					{/*	</Typography>*/}
					{/*</Box>*/}

					{/*/!* AGE *!/*/}
					{/*<Typography className="conf-field-name">{t('personal_details.age')}</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">*/}
					{/*		{dataa?.additional_details?.age ? dataa?.additional_details?.age : ''}*/}
					{/*	</Typography>*/}
					{/*</Box>*/}

					{/*/!* Phone Number *!/*/}
					{/*<Typography className="conf-field-name">{t('personal_details.phone')}</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">{dataa?.additional_details?.phone}</Typography>*/}
					{/*</Box>*/}

					{/*/!* ADDRESS *!/*/}
					{/*<Typography className="conf-field-name">{t('profile_confirm.address')}</Typography>*/}
					{/*<Box className="address-box">*/}
					{/*	<Typography className="conf-add-field">{dataa?.additional_details?.address}</Typography>*/}
					{/*</Box>*/}

					{/*/!* COUNTRY *!/*/}
					{/*<Typography className="conf-field-name">{t('personal_details.country')}</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">{dataa?.additional_details?.country}</Typography>*/}
					{/*</Box>*/}

					{/*/!* BIO *!/*/}
					{/*<Typography className="conf-field-name">{t('profile_confirm.bio')}</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">{dataa?.additional_details?.bio}</Typography>*/}
					{/*</Box>*/}

					{/*/!* FIGHTING OUT OF *!/*/}
					{/*<Typography className="conf-field-name">{t('profile_confirm.figting_out_of')}</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">*/}
					{/*		{dataa?.additional_details?.fighting_out_of?.inputValue*/}
					{/*			? dataa?.additional_details?.fighting_out_of?.inputValue*/}
					{/*			: dataa?.additional_details?.fighting_out_of}*/}
					{/*	</Typography>*/}
					{/*</Box>*/}
					{/*/!* WEIGHT CLASSES *!/*/}
					{/*<Typography className="conf-field-name">Weight Range</Typography>*/}
					{/*<Box className="checkbox-field">*/}
					{/*	<Typography className="email-mobile-text">*/}
					{/*		{dataa?.additional_details?.lower_weight}*/}
					{/*		{dataa?.additional_details?.measurment === 'Kilograms'*/}
					{/*			? 'Kg'*/}
					{/*			: dataa?.additional_details?.measurment === 'Pounds'*/}
					{/*			? 'lb'*/}
					{/*			: ''}*/}
					{/*		- {dataa?.additional_details?.height_weight}*/}
					{/*		{dataa?.additional_details?.measurment === 'Kilograms'*/}
					{/*			? 'Kg'*/}
					{/*			: dataa?.additional_details?.measurment === 'Pounds'*/}
					{/*			? 'lb'*/}
					{/*			: ''}*/}
					{/*	</Typography>*/}
					{/*</Box>*/}
				</Box>
			</Box>

			<Box className="confirm-submit-box">
				<Button fullWidth onClick={submitHandler} varinat="contained" className="confirm-btn">
					{t('confirm')}
				</Button>
			</Box>
		</Box>
	);
};
