import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid } from '@material-ui/core';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { useTranslation } from 'react-i18next';

export const EditBio = ({ user, token, getMyProfile, closeDialog, isModalLoading, setIsModalLoading }) => {
	const { t } = useTranslation();

	let bioVal = user?.UserData?.UserBioData?.PostLists[0]?.Item?.data;
	let first_time = bioVal ? false : true;

	return (
		<Box>
			<Formik
				initialValues={{
					bio: first_time ? '' : bioVal || '',
				}}
				validationSchema={Yup.object().shape({
					bio: Yup.string().required(t('validation.required_message')),
				})}
				onSubmit={async (val) => {
					let firstReuestData = {
						user_bio: {
							name: val.bio,
						},
					};

					let editRequestData = {
						user_bio: {
							name: val.bio,
							post_id: bioVal?.id,
						},
					};

					let requestData = first_time ? firstReuestData : editRequestData;

					try {
						setIsModalLoading(true);
						const { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							setApiMessage('success', t('myprofile.profile_updated_success'));
							getMyProfile();
							closeDialog();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.bio && errors.bio)}
									fullWidth
									helperText={
										touched.bio ? (
											touched.bio && (
												<Box disply="flex" justifyContent="center">
													{errors.bio}
													<span className="field-character-count">{values.bio.length}/10000</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.bio.length}/10000</span>
										)
									}
									variant="filled"
									InputProps={{
										maxLength: 10000,
										endAdornment: touched.bio && errors.bio ? <ErrorIcon /> : null,
									}}
									inputProps={{ maxLength: 10000 }}
									label={t('myprofile.bio')}
									placeholder={t('myprofile.bio')}
									className="matrix-textfield"
									size="small"
									name="bio"
									multiline
									rows={10}
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.bio}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="dialog-sticky-btn">
								<Box className="dialog-submit-button">
									<Button disabled={isModalLoading} variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
