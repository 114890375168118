import { useState } from 'react';
import { Avatar, Box, Button, Typography, Popover } from '@material-ui/core';
import { ReactComponent as VerifiedBadgeIcon } from 'src/assets/images/icons/verified-badge.svg';
import './listview.scss';
import Viewer from 'react-viewer';
// import { ReactComponent as AddConnection } from 'src/assets/images/icons/add-connection.svg';
import { ReactComponent as LeaveCommunity } from 'src/assets/images/icons/community/leave-community.svg';
import { ReactComponent as JoinIcon } from 'src/assets/images/icons/join-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CommunityListView = ({ createdAt, name, data, communityAction, communityimage, showButton }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState(null);
	const [visible, setVisible] = useState(false);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Box className="community-list-block community-list-view">
			<Box className="com-list-head-box">
				<Box className="left-about">
					<>
						<Avatar
							src={communityimage}
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setVisible(true);
							}}
						/>
						{communityimage !== '' && communityimage !== undefined && communityimage !== null && (
							<Viewer
								visible={visible}
								onClose={() => {
									setVisible(false);
								}}
								images={[
									{
										src: communityimage,
										title: 'cover image',
									},
								]}
								downloadable
								noFooter={true}
								drag={false}
								zoomable={false}
								rotatable={false}
								scalable={false}
								disableMouseZoom={true}
							/>
						)}
					</>
					<Box className="com-name-created">
						<Typography
							className="com-name"
							onClick={() => {
								navigate(`/communities/${data?.id}`);
							}}
						>
							{name}
							<VerifiedBadgeIcon />
						</Typography>
						<Typography className="com-created">
							{t('community.created')}: <span>{createdAt}</span>
						</Typography>
					</Box>
				</Box>
			</Box>
			{showButton && (
				<Button
					disableElevation
					disableTouchRipple
					disableRipple
					disableFocusRipple
					style={{ width: '100%' }}
					variant="contained"
					startIcon={
						data?.join_status === 'sent' || data?.join_status === 'accepted' ? <LeaveCommunity /> : <JoinIcon />
					}
					className="community-btn"
					onClick={(e) => {
						data?.join_status === 'accepted'
							? communityAction(data, 'leave')
							: data?.join_status === 'sent'
							? handleClick(e)
							: communityAction(data, 'sent');
					}}
				>
					{data?.join_status === 'accepted'
						? t('community.leave_community')
						: data?.join_status === 'sent'
						? t('community.join_req_pending')
						: t('community.join')}
				</Button>
			)}
			<Popover
				className="cr-popover"
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Button
					onClick={() => {
						handleClose();
						communityAction(data, 'leave');
					}}
				>
					{t('community.cancel_request')}
				</Button>
			</Popover>
		</Box>
	);
};
