export const identifiers = {
	token: 'authData',
	active: 'active',
	dropDown: 'Drop down',
	visitorUserId: 'visitorUserId',
	authData: 'authData',
	referralcode: 'referralcode',
	previousPath: 'previousPath',
	siteversion: 'siteversion',
	siteversionmin: 'siteversionmin',
	siteversionserver: 'siteversionserver',
	siteversionminserver: 'siteversionminserver',
	referralType: {
		user_signup: 'user_signup',
		community: 'community',
		post: 'post',
		user: 'user',
	},
	userIdFromMail: 'userIdFromMail',
	pathFromMail: 'pathFromMail',
	childAccesibilityArr: [
		{ id: 1, name: 'white' },
		{ id: 2, name: 'yellow' },
		{ id: 3, name: 'purple' },
		{ id: 4, name: 'red' },
		{ id: 5, name: 'black' },
	],
};
export const eventTypeList = [
	{ id: 'fight', name: 'Fight' },
	{ id: 'press_conference', name: 'Press Conference' },
	{ id: 'session', name: 'Session' },
	{ id: 'seminar', name: 'Seminar' },
	{ id: 'other', name: 'Other' },
];
