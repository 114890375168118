import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Chip, Typography, Popover, Grid, Link } from '@material-ui/core';
import Viewer from 'react-viewer';
import { ReactComponent as VerifiedBadgeIcon } from 'src/assets/images/icons/verified-badge.svg';
import { ReactComponent as WriteIcon } from 'src/assets/images/icons/write-icon.svg';
import { ReactComponent as ShareIcon } from 'src/assets/images/icons/share-icon.svg';
import { ReactComponent as JoinIcon } from 'src/assets/images/icons/join-icon.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/report-icon.svg';
import { ReactComponent as LeaveCommunity } from 'src/assets/images/icons/community/leave-community.svg';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import './community.scss';
import { DialogBox } from '../DialogBox';
import { fetchFromStorage } from 'src/helpers/context';
import { setApiMessage, youTubeVideoCheck } from 'src/helpers/commonFunctions';
import { Report } from '../Report';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import ReactPlayer from 'react-player';
import { Share } from '../Share';
import { useTranslation } from 'react-i18next';

export const CommunityItem = ({
	name,
	communityimage,
	createdAt,
	members,
	communityFor,
	postedBy,
	postTime,
	postImg,
	postText,
	postId,
	userProfile,
	isPostAvail,
	data,
	communityAction,
	showButton,
	reportReasonList,
	linkList,
	postTitle,
	LastAllMediaList,
	singleItem,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [opens, setOpen] = useState(false);
	const [model, setModel] = useState(false);
	const [title, setTitle] = useState(false);
	const verificationWall = fetchFromStorage('verificationWall');
	const [visible, setVisible] = useState(false);

	// const filterList = [
	//   { id: 1, name: t('community.atheletes') },
	//   { id: 2, name: t('community.coaches') },
	//   { id: 3, name: t('community.instructors') },
	//   { id: 4, name: t('community.fans') },
	//   { id: 5, name: t('community.promotors') },
	//   { id: 6, name: t('community.sponsors') },
	//   { id: 7, name: t('community.gyms') },
	//   { id: 8, name: t('community.under_18s') },
	//   { id: 9, name: t('community.unverified') }
	// ];

	const filterList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	const comFor = filterList?.filter((data) => {
		return communityFor?.indexOf(data?.id) !== -1;
	});
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const renderMedia = (item) => {
		switch (item?.Item?.type) {
			case 'doc':
				return (
					<Box className="post-image-slider doc-sec">
						<Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						</Box>
					</Box>
				);
			case 'image':
				return (
					<Box className="post-image-slider">
						<Avatar src={item?.Item?.data} variant="square" />
						{/* <LightBox
                imageurl={imageurlArray}
                image={item?.Item?.data}
                otherUserId=""
              /> */}
					</Box>
				);
			case 'video':
				return (
					<Box className="post-image-slider">
						<Box className="post-video-slider">
							<ReactPlayer
								className="react-player"
								url={item?.Item?.data}
								playing={false}
								width="100%"
								height="100%"
								controls={true}
							/>
						</Box>
					</Box>
				);
			case 'audio':
				return (
					<Box className="post-image-slider">
						<ReactPlayer
							className="react-player audio-player"
							url={item?.Item?.data}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
			default:
				return (
					<Box className="post-image-slider">
						<Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						</Box>
					</Box>
				);
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const combatDialogHandler = (title, model) => {
		setOpen(true);
		setModel(model);
		setTitle(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const open = Boolean(anchorEl);

	const isOwner = data?.user_owner_id === fetchFromStorage('authData')?.user_id ? true : false;

	const id = open ? 'simple-popover' : undefined;

	return (
		<Box className="community-list-block">
			<Box className="com-list-head-box">
				<Box className="left-about">
					<>
						<Avatar
							src={communityimage}
							style={{ cursor: 'pointer' }}
							onClick={() => {
								communityimage && setVisible(true);
							}}
						/>
						{communityimage !== '' && communityimage !== undefined && communityimage !== null && (
							<Viewer
								visible={visible}
								onClose={() => {
									setVisible(false);
								}}
								images={[
									{
										src: communityimage,
										title: 'cover image',
									},
								]}
								downloadable
								noFooter={true}
								drag={false}
								zoomable={false}
								rotatable={false}
								scalable={false}
								disableMouseZoom={true}
							/>
						)}
					</>

					<Box className="com-name-created">
						<Typography
							className="com-name"
							onClick={() => {
								navigate(`/communities/${data?.id}`);
							}}
						>
							{name} <VerifiedBadgeIcon />
						</Typography>

						<Typography className="com-created">
							{t('community.created')}: <span>{createdAt}</span>
						</Typography>
					</Box>
				</Box>
				<Box className="right-about">
					<Typography className="com-members">
						{t('community.members')}: <span>{members}</span>
					</Typography>
					<Box className="com-for-box">
						<Typography className="com-for-txt">{t('community.community_for')}:</Typography>
						<Box className="com-for-chips">
							{comFor?.map((data, index) => {
								return <Chip key={index} className="com-chip" label={data?.name} />;
							})}
						</Box>
					</Box>
				</Box>
			</Box>
			{isPostAvail && (
				<>
					<Typography className="last-post-title">{t('community.last_post')}</Typography>
					<Typography
						className="com-last-post-by"
						onClick={() => {
							navigate(`/user/${userProfile}`);
							// navigate(`/user/shadow/${user?.user_meta?.data}`);
						}}
					>
						{postedBy} · {postTime}
					</Typography>
					<Box
						className="com-last-post"
						onClick={() => {
							navigate(`/post/${data?.post_list?.id}`);
						}}
					>
						{/* <Avatar variant="square" src={postImg} /> */}

						{LastAllMediaList && LastAllMediaList[0] && (
							<Box className="last-post-media">{renderMedia(LastAllMediaList[0])}</Box>
						)}
						<Box>
							<Typography className="post-title">{postTitle}</Typography>
							<Typography className="last-point-text">
								{postText && // USER PROFILE TYPE ABOUT TEXT
									renderHTML(
										anchorme({
											input: postText.toString().replace(/\n/g, '<br/>'),
											options: {
												attributes: {
													target: '_blank',
													class: 'detected',
												},
											},
										}),
									)}
							</Typography>
						</Box>
					</Box>
					{linkList &&
						linkList?.map((item) => {
							var checkYTLink = youTubeVideoCheck(item);

							return (
								checkYTLink && (
									<Box style={{ margin: '10px', height: '250px' }}>
										<ReactPlayer
											className="react-player"
											url={item}
											playing={false}
											width="100%"
											height="100%"
											controls={true}
										/>
									</Box>
								)
							);
						})}
				</>
			)}

			{/* Community Actions */}
			<Box className="com-actions">
				{isOwner ? (
					<Button
						disableRipple
						disableTouchRipple
						disableFocusRipple
						className="com-act-btn"
						startIcon={<WriteIcon />}
						onClick={() => {
							navigate(`/communities/${data?.id}`);
						}}
					>
						{t('community.post')}
					</Button>
				) : null}
				<Button
					disableRipple
					disableTouchRipple
					disableFocusRipple
					className="com-act-btn"
					startIcon={<ShareIcon />}
					onClick={() => {
						combatDialogHandler(t('community.share'), 'share');
					}}
				>
					{t('community.share')}
				</Button>
				{showButton && (
					<Button
						disableRipple
						disableTouchRipple
						disableFocusRipple
						className="com-act-btn"
						startIcon={
							data?.join_status === 'sent' || data?.join_status === 'accepted' ? <LeaveCommunity /> : <JoinIcon />
						}
						onClick={(e) => {
							// verificationWall?.joinCommunity ? communityAction(data, 'sent') : setApiMessage('success', 'You must be verified to make this action');
							data?.join_status === 'accepted'
								? communityAction(data, 'leave', singleItem)
								: data?.join_status === 'sent'
								? handleClick(e)
								: verificationWall?.joinCommunity
								? communityAction(data, 'sent', singleItem)
								: setApiMessage('success', 'You must be verified to make this action');
						}}
					>
						{data?.join_status === 'accepted'
							? t('community.leave_community')
							: data?.join_status === 'sent'
							? t('community.join_req_pending')
							: t('community.join')}
					</Button>
				)}
				<Popover
					className="cr-popover"
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<Button
						onClick={() => {
							handleClose();
							communityAction(data, 'leave', singleItem);
						}}
					>
						{t('community.cancel_request')}
					</Button>
				</Popover>
				<Button
					disableRipple
					disableTouchRipple
					disableFocusRipple
					className="com-act-btn"
					startIcon={<ReportIcon />}
					onClick={() => {
						combatDialogHandler(t('report'), 'report');
					}}
				>
					{t('community.report')}
				</Button>
			</Box>
			<DialogBox
				className={model === 'share' && 'share-dailog-box'}
				open={opens}
				handleClose={closeDialog}
				title={model === 'report' && title}
				content={
					model === 'share' ? (
						<Share fromWhere="communities/" urlId={data?.id} landType="community" handleClose={closeDialog} />
					) : model === 'report' ? (
						<Report
							handleClose={closeDialog}
							reportReasonList={reportReasonList}
							type="community"
							receiverId={data?.id}
						/>
					) : (
						<Share fromWhere="communities/" urlId={data?.id} landType="community" handleClose={closeDialog} />
					)
				}
			/>
		</Box>
	);
};
