import { Avatar, Box, Typography, Button, IconButton } from '@material-ui/core';
import { ReactComponent as ReactionsIcon } from 'src/assets/images/icons/reactions.svg';
import { ReactComponent as StaticsUpIcon } from 'src/assets/images/icons/statics-arrow-up.svg';
import { ReactComponent as StaticsDownIcon } from 'src/assets/images/icons/statics-arrow-down.svg';
import './fightRecordData.scss';

export const FightRecordData = ({
	superEvent,
	sideItem,
	enterResults1v1,
	lastItem,
	is1v1,
	addEventFightVote,
	renderReactionList,
}) => {
	var contestant1 = is1v1 ? sideItem?.EventFightContestantsDetails[0]?.EventFightContestants[0] : null;
	var contestant2 = is1v1 ? sideItem?.EventFightContestantsDetails[1]?.EventFightContestants[0] : null;

	return (
		<Box className="event-fight-card-side-sec">
			{is1v1 ? (
				<Box className="event-1v1-sec">
					{contestant1 && (
						<Box>
							<Typography className="side-text">{contestant1?.EventFightSide?.name}</Typography>
							<Box className="event-fight-record-left-sec">
								<Avatar src={contestant1?.user_profile_data} className="event-fight-profile" />
								<Box className="event-user-data">
									<Typography className="event-user-name">{contestant1?.user_name}</Typography>
									<Typography className="event-user-status">{contestant1?.MetaCategory?.name}</Typography>
									<Typography className="event-user-status">{contestant1?.MetaWeightClass?.name}</Typography>
								</Box>
							</Box>
						</Box>
					)}
					<Box className="event-fight-record-center-sec">
						{/* <Typography className='weight-text'>Lightweight</Typography> */}
						<Typography className="weight-text">v</Typography>
						<Typography
							className="result-link"
							onClick={() => {
								enterResults1v1(sideItem);
							}}
						>
							Enter Results
						</Typography>
						<Box className="event-reaction-data">
							{/* STASTICS UP VALUE */}
							<Box display="flex" alignItems="center" className="statics-btn-sec">
								<IconButton
									onClick={() => {
										superEvent
											? addEventFightVote(sideItem?.id, 'vote_up')
											: addEventFightVote(sideItem?.id, 'vote_up', 'fight');
									}}
									className={sideItem?.vote_up_status === 'yes' ? 'fill-blue statics-btn' : 'statics-btn'}
								>
									<StaticsUpIcon />
								</IconButton>
								<Typography className="statics-val up-val">{sideItem?.total_vote_up}</Typography>
							</Box>

							{/* STASTICS DOWN VALUE */}
							<Box display="flex" alignItems="center" className="statics-btn-sec">
								<IconButton
									className={sideItem?.vote_down_status === 'yes' ? 'fill-red statics-btn' : 'statics-btn'}
									onClick={() => {
										superEvent
											? addEventFightVote(sideItem?.id, 'vote_down')
											: addEventFightVote(sideItem?.id, 'vote_down', 'fight');
									}}
								>
									<StaticsDownIcon className="statics-down-icon" />
								</IconButton>
								<Typography className="statics-val"> {sideItem?.total_vote_down}</Typography>
							</Box>
							{/* <Button
                                    variant="contained"
                                    disableElevation
                                    disableRipple
                                    disableFocusRipple
                                    disableTouchRipple
                                    className="act-btn"
                                    startIcon={<ReactionsIcon />}
                                >
                                    {t('community.reactions')}
                                    {' '}
                                    (
                                    {0}
                                    )
                                </Button> */}
							{superEvent ? renderReactionList(sideItem, 'superevent') : renderReactionList(sideItem)}
						</Box>
					</Box>
					{contestant2 && (
						<Box>
							<Typography className="side-text">{contestant2?.EventFightSide?.name}</Typography>
							<Box className="event-fight-record-right-sec">
								<Box className="event-user-data">
									<Typography className="event-user-name">{contestant2?.user_name}</Typography>
									<Typography className="event-user-status">{contestant2?.MetaCategory?.name}</Typography>
									<Typography className="event-user-status">{contestant2?.MetaWeightClass?.name}</Typography>
								</Box>
								<Avatar className="event-fight-profile" src={contestant2?.user_profile_data} />
							</Box>
						</Box>
					)}
				</Box>
			) : (
				<>
					<Typography className="side-text">{sideItem?.EventFightSide?.name}</Typography>
					<Box className="event-side-sec">
						{sideItem?.EventFightContestants.map((combatant, index) => {
							return (
								<Box className="event-fight-profile-sec">
									<Avatar className="event-fight-profile" src={combatant?.user_profile_data} />
									<Box className="event-user-data">
										<Typography className="event-user-name">{combatant?.user_name}</Typography>
										<Typography className="event-user-status">{combatant?.MetaCategory?.name}</Typography>
									</Box>
								</Box>
							);
						})}
					</Box>

					{lastItem && (
						<Typography variant="h2" className="v-sec">
							<Typography variant="span" className="v-text">
								v
							</Typography>
						</Typography>
					)}
				</>
			)}
		</Box>

		//     <Box className='event-fight-record-left-sec'>
		//         <Avatar className='event-fight-profile' />
		//         <Box className='event-user-data'>
		//             <Typography className='event-user-name'>Charles Oliveira</Typography>
		//             <Typography className='event-user-status'>champion</Typography>
		//         </Box>
		//     </Box>
		//     <Box className='event-fight-record-center-sec'>
		//         <Typography className='weight-text'>Lightweight</Typography>
		//         <Typography className='weight-text'>v</Typography>
		//         <Typography className='result-link'>Enter Results</Typography>
		//         <Box className='event-reaction-data'>

		//             {/* STASTICS UP VALUE */}
		//             <Box display="flex" alignItems="center" className='statics-btn-sec'>
		//                 <IconButton
		//                     className={'fill-blue statics-btn'}
		//                 >
		//                     <StaticsUpIcon />
		//                 </IconButton>
		//                 <Typography className="statics-val up-val">
		//                     {0}
		//                 </Typography>
		//             </Box>

		//             {/* STASTICS DOWN VALUE */}
		//             <Box display="flex" alignItems="center" className='statics-btn-sec'>
		//                 <IconButton
		//                     className={'statics-btn'}
		//                 >
		//                     <StaticsDownIcon className="statics-down-icon" />
		//                 </IconButton>
		//                 <Typography className="statics-val">{0}</Typography>
		//             </Box>
		//             <Button
		//                 variant="contained"
		//                 disableElevation
		//                 disableRipple
		//                 disableFocusRipple
		//                 disableTouchRipple
		//                 className="act-btn"
		//                 startIcon={<ReactionsIcon />}
		//             >
		//                 {t('community.reactions')}
		//                 {' '}
		//                 (
		//                 {0}
		//                 )
		//             </Button>
		//         </Box>
		//     </Box>
		//     <Box className='event-fight-record-right-sec'>
		//         <Box className='event-user-data'>
		//             <Typography className='event-user-name'>Justin Gaethje</Typography>
		//             <Typography className='event-user-status'>champion</Typography>
		//         </Box>
		//         <Avatar className='event-fight-profile' />
		//     </Box>
	);
};
