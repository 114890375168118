import {
	EVENT_DETAILS,
	EVENT_TYPE,
	EVENT_FIGHT_CARD,
	EVENT_TICKET,
	EVENT_CONFIRM,
	CURRENT_CREATE_EVENT_TAB,
	CATEGORY,
	EVENT_ADMIN,
	EVENT_INVITE,
	SUPER_EVENT_STATUS,
	EVENT_FIGHT_DATA,
	CURRENT_FIGHT_TAB,
	CURRENT_SUPER_EVENT_PAGE,
	CARD_CATEGORY,
	SUPER_EVENT_CARD_DATA,
	SUPER_EVENT_DETAILS,
	SUPER_EVENT_FIGHT_DETAILS,
	EVENT_STATUS,
	SUPER_EVENT_CATEGORY_DATA,
} from '../constants';

export const eventDetails = (data) => {
	return {
		type: EVENT_DETAILS,
		data: data,
	};
};

export const eventType = (data) => {
	return {
		type: EVENT_TYPE,
		data: data,
	};
};
export const category = (data) => {
	return {
		type: CATEGORY,
		data: data,
	};
};
export const superEventStatus = (data) => {
	return {
		type: SUPER_EVENT_STATUS,
		data: data,
	};
};
export const eventFightCard = (data) => {
	return {
		type: EVENT_FIGHT_CARD,
		data: data,
	};
};
export const eventFightData = (data) => {
	return {
		type: EVENT_FIGHT_DATA,
		data: data,
	};
};
export const eventTicket = (data) => {
	return {
		type: EVENT_TICKET,
		data: data,
	};
};
export const eventAdmin = (data) => {
	return {
		type: EVENT_ADMIN,
		data: data,
	};
};
export const eventInvite = (data) => {
	return {
		type: EVENT_INVITE,
		data: data,
	};
};
export const eventConfirm = (data) => {
	return {
		type: EVENT_CONFIRM,
		data: data,
	};
};
export const cardCategory = (data) => {
	return {
		type: CARD_CATEGORY,
		data: data,
	};
};
export const supurEventCardData = (data) => {
	return {
		type: SUPER_EVENT_CARD_DATA,
		data: data,
	};
};
export const currentCreateEventTab = (data) => {
	return {
		type: CURRENT_CREATE_EVENT_TAB,
		data: data,
	};
};
export const currentFightTab = (data) => {
	return {
		type: CURRENT_FIGHT_TAB,
		data: data,
	};
};
export const currentSuperEventPage = (data) => {
	return {
		type: CURRENT_SUPER_EVENT_PAGE,
		data: data,
	};
};
export const superEventDetails = (data) => {
	return {
		type: SUPER_EVENT_DETAILS,
		data: data,
	};
};
export const superEventFightDetails = (data) => {
	return {
		type: SUPER_EVENT_FIGHT_DETAILS,
		data: data,
	};
};
export const eventStatus = (data) => {
	return {
		type: EVENT_STATUS,
		data: data,
	};
};
export const superEventCategoryData = (data) => {
	return {
		type: SUPER_EVENT_CATEGORY_DATA,
		data: data,
	};
};
