import { useState, useEffect, useContext } from 'react';
import {
	Box,
	Button,
	Container,
	Grid,
	Typography,
	// Tab,
	withStyles,
	TextField,
	InputAdornment,
} from '@material-ui/core';
// import { TabContext, TabList, TabPanel } from '@material-ui/lab';
// import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import { UserProfile } from './UserProfile';
import './claimed-profile.scss';
import { Page, CustomPaginations } from 'src/components/ui';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const ClaimProfile = () => {
	const navigate = useNavigate();
	const { type } = useParams();
	const { state } = useLocation();

	const [searchText, setSearchText] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [usersData, setUsersData] = useState([]);
	const [list] = useState(9); //setList
	const [totalRecord, setTotalRecords] = useState('');
	const [userTotalRecord, setUserTotalRecord] = useState('');

	const { t } = useTranslation();
	const auth = useSelector((state) => state?.Combat?.authData);
	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	let ownURI = auth ? auth?.user_meta?.data : fetchFromStorage('authData')?.user_meta?.data;

	let ownerID = useSelector((state) => state?.Combat?.authData)?.user_id || fetchFromStorage('authData')?.user_id;

	const fetchUsers = async (pageNumber) => {
		var page = pageNumber !== undefined ? pageNumber : 1;

		try {
			let { status, data } = await axiosInstance.get(URLS.getClaimedProfile, {
				params: {
					page: page,
					limit: list,
					name: searchText !== '' ? searchText : null,
				},
				headers,
			});

			if (status === 200) {
				setUsersData(data?.OtherUserList);
				setUserTotalRecord(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const previousPathHandler = () => {
		navigate(`/user/${ownURI}`);
	};

	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchUsers(currentPage - 1);
			} else {
				if (currentPage < Math.ceil(totalRecord / list)) {
					setCurrentPage(currentPage + 1);
					fetchUsers(currentPage + 1);
				}
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchUsers(Number(page));
		}
	};

	//   SEARCG BUTTON CLICK HANDLER
	const searchButtonClickHandler = (e) => {
		e.preventDefault();
		setCurrentPage(1);
		fetchUsers(1);
	};

	useEffect(() => {
		fetchUsers(1, true);

		// eslint-disable-next-line
	}, []);

	return (
		<Page title="Claim Profile | Combat Matrix">
			<Container maxWidth="lg">
				<Box className="combat-container connection-page">
					<Typography className="connection-page-title">{t('connections.title')}</Typography>
					<Box className="connection-user-list">
						<Box className="back-to-profile">
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								startIcon={<BackIcon />}
								className="back-text"
								onClick={previousPathHandler}
							>
								{t('connections.backtoprofile')}
							</Button>
						</Box>

						<Typography className="cp-txt">Claimed Profiles</Typography>
						<form onSubmit={searchButtonClickHandler} className="search-field-box">
							<SearchTextField
								fullWidth
								placeholder="Search"
								size="small"
								variant="outlined"
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value);
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>

							<Button type="submit" className="search-btn">
								{t('search_btn')}
							</Button>
						</form>

						<Grid container spacing={1}>
							{usersData && usersData?.length > 0 ? (
								usersData.map((user, index) => {
									return (
										<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
											<UserProfile user={user} ownerID={ownerID} fetchUsers={fetchUsers} currentPage={currentPage} />
										</Grid>
									);
								})
							) : (
								<Typography className="no-data-text">No Data Found</Typography>
							)}
						</Grid>
						<CustomPaginations
							paginationArray={[]} //usersData
							currentPage={currentPage}
							list={list}
							handlePaginationButtonClick={handlePaginationButtonClick}
							handlePaginationClick={handlePaginationClick}
							totalRecord={userTotalRecord}
						/>
					</Box>
				</Box>
			</Container>
		</Page>
	);
};
