import { connect } from 'react-redux';
import { authUser } from 'src/redux-state/actions/actions';

import { Register as RegisterComponent } from './Register';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (data) => dispatch(authUser(data)),
	};
};
export const Register = connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
