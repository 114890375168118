import { makeStyles } from '@material-ui/core';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
// import globogym from 'src/assets/images/icons/globogym.svg';
// import joshua from 'src/assets/images/icons/joshua-rawson.svg';
// import jessica from 'src/assets/images/icons/jessica.svg';
// import andrew from 'src/assets/images/icons/andrew.svg';
// import sam from 'src/assets/images/icons/sam-j.svg';
import './right.scss';
import { SocialActivity } from './SocialActivity';
import { RightUserInfo } from './RightUserInfo';
import { Badge } from './Badge';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	input: {
		display: 'none',
	},
});

export const Right = ({
	orgDetails,
	orgUsers,
	editSocialLink,
	EditProfile,
	onopenform,
	userStatus,
	fetchOrganisationUsers,
	fetchOrganisationDetails,
	toggleDrawer,
	setUploadType,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const tab_list = [
		{ id: 1, name: t('organisation.members') },
		{ id: 2, name: t('organisation.followers') },
		{ id: 3, name: t('organisation.tab_admin') },
	];

	return (
		<>
			{/* <Box className="right-organisation-block"> */}
			{/* RIGHT TOP SECTION */}
			<RightUserInfo
				UserPlaceholderIcon={UserPlaceholderIcon}
				orgDetails={orgDetails}
				classes={classes}
				onopenform={onopenform}
				userStatus={userStatus}
				fetchOrganisationDetails={fetchOrganisationDetails}
				editSocialLink={editSocialLink}
				EditProfile={EditProfile}
				toggleDrawer={toggleDrawer}
				setUploadType={setUploadType}
			/>

			{/* BADGE SECTION  */}
			<Badge orgDetails={orgDetails} />

			{/* SOCIAL ACTIVITY */}
			<SocialActivity
				tab_list={tab_list}
				orgUsers={orgUsers}
				fetchOrganisationUsers={fetchOrganisationUsers}
				userStatus={userStatus}
			/>
			{/* </Box> */}
		</>
	);
};
