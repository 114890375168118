import { useEffect, useState } from 'react';
import { Box, Fade } from '@material-ui/core';
import './update-post.scss';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { fetchFromStorage } from 'src/helpers/context';
import { TextEditor } from 'src/components/ui';

export const UpdatePost = ({ postData, cancelBtnClick, fetchCommunityPostList, tab, fetchPostDetails, user }) => {
	const auth = useSelector((state) => state.Combat.authData);
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	const { post_data } = postData;

	const [allUsersList, setAllUsersList] = useState([]);

	useEffect(() => {
		fetchAllUsersList();
	}, []);

	const updateCommunityPost = async (requestParameter) => {
		try {
			const { status, data } = await axiosInstance.put(URLS.updatePost, requestParameter);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchCommunityPostList(tabValue);
				cancelBtnClick();

				if (fetchPostDetails) {
					fetchPostDetails();
				}
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// FETCH ALL USER LIST FOR TAG PEOPLE
	const fetchAllUsersList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var formattedArray = [];

				filteredData.map((item, i) => {
					var urlType =
						item?.type === 'user'
							? `user/${item?.id}`
							: item?.type === 'community'
							? `communities/${item?.id}`
							: `organisation-details/${item?.id}`;
					var urlValue = urlType;

					const object = {
						text: item?.user_full_name,
						value: item?.user_full_name,
						url: urlValue,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.text !== '');

				setAllUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	return (
		<Fade in>
			<Box className="creating-post-box">
				<TextEditor
					allUsersList={allUsersList}
					selectedItem={''}
					submit={updateCommunityPost}
					user={user}
					receiverType={post_data.receiver_type}
					token={token}
					postData={post_data}
					cancelBtnClick={cancelBtnClick}
					initialRequestData={{}}
				/>
			</Box>
		</Fade>
	);
};
