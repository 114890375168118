import { useMemo } from 'react';
import { Paper, Box, Grid, Typography, MenuItem } from '@material-ui/core';
import { Config } from 'src/helpers/context/config';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as EnIcon } from 'src/assets/images/icons/language/en.svg';
import { TextField } from 'src/components/ui';
import { ReactComponent as RuIcon } from 'src/assets/images/icons/language/ru.svg';
import { ReactComponent as ThIcon } from 'src/assets/images/icons/language/th.svg';
import { ReactComponent as PtIcon } from 'src/assets/images/icons/language/pt.svg';
import { whitelist } from 'src/utils/i18n';

import styles from './Footer.module.scss';

const LANGUAGE_CODE_TO_ICON = {
	ru: RuIcon,
	en: EnIcon,
	th: ThIcon,
	pt: PtIcon,
};

const getLanguageOptions = (t) =>
	whitelist
		.map((code) => ({
			code,
			label: t(`language.${code}`),
			icon: LANGUAGE_CODE_TO_ICON[code],
		}))
		.sort((langA, langB) => langA.label.localeCompare(langB.label));

export const Footer = ({ auth, user_statics, verify_count }) => {
	const { t, i18n } = useTranslation();

	const handleChangeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	};

	const languageOptions = useMemo(() => getLanguageOptions(t), [t]);

	return (
		<Paper className={styles['footer-main']} elevation={3}>
			<Box className={styles['footer-container']}>
				<Typography className={styles['cm-user-number']}>
					{auth ? (
						<Typography className={styles['cm-user-title']}>
							CM {t('top_menu_options.users')}:&nbsp;<span>{user_statics?.verify_user_count}</span>
						</Typography>
					) : verify_count !== undefined ? (
						verify_count !== null ? (
							<Typography className={styles['cm-user-title']}>
								CM {t('top_menu_options.users')}:&nbsp;
								<span>{verify_count !== undefined ? (verify_count !== null ? verify_count : null) : null}</span>
							</Typography>
						) : null
					) : null}
				</Typography>
				<Typography>
					{t('version')} {Config.siteVersion}
				</Typography>
				<div className={styles['language-dd']}>
					<Grid item xs={12} md={12}>
						<TextField
							select
							fullWidth
							size="small"
							variant="filled"
							SelectProps={{
								MenuProps: {
									PopoverClasses: {
										paper: styles['sitelanguage-paper'],
									},
								},
							}}
							value={i18n.language}
							// TODO:[STYLE] delete global class
							className={cn('matrix-textfield', styles['language-selector'])}
							onChange={handleChangeLanguage}
						>
							{languageOptions.map(({ code, icon: Icon, label }) => (
								<MenuItem key={code} value={code}>
									<Icon className={styles['language-selector__icon']} />
									<Typography className={styles['flag-name']}>{label}</Typography>
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</div>
			</Box>
		</Paper>
	);
};
