import { useState } from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { ReactComponent as DownIcon } from 'src/assets/images/icons/down-icon.svg';
import './categoryQ.scss';

export const CategoryQuestion = () => {
	return (
		<>
			<Accordion className="acc_tab">
				<AccordionSummary expandIcon={<DownIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography>Question 1</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed ullamcorper lorem. Ut suscipit pretium
						ornare. Mauris ultricies vel nisi at dapibus. Vivamus viverra nisi quis pulvinar vestibulum. Donec ac felis
						molestie, eleifend felis nec, faucibus nibh. Morbi placerat commodo velit, sed pellentesque est gravida
						quis. Sed rhoncus erat metus. Donec id nisi sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer at urna nec
						magna cursus sodales et vel ipsum.
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion className="acc_tab">
				<AccordionSummary expandIcon={<DownIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography>Question 2</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed ullamcorper lorem. Ut suscipit pretium
						ornare. Mauris ultricies vel nisi at dapibus. Vivamus viverra nisi quis pulvinar vestibulum. Donec ac felis
						molestie, eleifend felis nec, faucibus nibh. Morbi placerat commodo velit, sed pellentesque est gravida
						quis. Sed rhoncus erat metus. Donec id nisi sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer at urna nec
						magna cursus sodales et vel ipsum.
					</Typography>
				</AccordionDetails>
			</Accordion>
		</>
	);
};
