import { useState, useEffect } from 'react';
import {
	List,
	Button,
	ListItem,
	Avatar,
	TextField,
	withStyles,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Box,
	Popover,
	InputAdornment,
} from '@material-ui/core';
import './dropdown.scss';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/community/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		backgroundColor: '#202020',
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const MultipleSelectCheckmarks = ({ checked, labelValue, dropdownArray, onSelect, count }) => {
	const { t } = useTranslation();

	// const [checked, setChecked] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [DDArray, setDDArray] = useState([]);

	const handleToggle = (value) => () => {
		onSelect(value);
		// const currentIndex = checked.indexOf(value);
		// const newChecked = [...checked];
		// if (currentIndex === -1) {
		//     newChecked.push(value);
		// } else {
		//     newChecked.splice(currentIndex, 1);
		// }
		// setChecked(newChecked);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setDDArray(dropdownArray);
	};
	const handleChange = (e) => {
		const { value } = e.target;
		const filtered = dropdownArray.filter((obj) => obj.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);

		setDDArray(filtered);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	// useEffect(() => {
	//     onSelect(checked)
	// }, [checked]);

	useEffect(() => {
		setDDArray(dropdownArray);
	}, [dropdownArray]);

	return (
		<>
			<Button
				disableElevation
				disableTouchRipple
				disableRipple
				disableFocusRipple
				style={{ width: '100%' }}
				variant="contained"
				endIcon={<ArrowDown className={open ? 'toggle-down' : 'toggle-up'} />}
				startIcon={count > 0 && <Avatar className="circle-blue">{count}</Avatar>}
				className="filter-button"
				onClick={handleClick}
			>
				{labelValue}
			</Button>
			<Popover
				className="bcdp-popover"
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box className="checkmark-dropdown">
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						{/*SEARCH DROPDOWN */}
						<Box className="community-search-box">
							<Box className="search-field-box p-10 b-0">
								<SearchTextField
									onChange={handleChange}
									fullWidth
									placeholder={t('search.title')}
									size="small"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Box>
						</Box>

						{DDArray.map((value, index) => {
							const labelId = `checkbox-list-label-${index}`;

							return (
								<ListItem onClick={handleToggle(value?.id)} key={index} disablePadding>
									{/* <ListItemButton role={undefined} onClick={handleToggle(value)} dense> */}
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={checked.indexOf(value?.id) !== -1}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={value?.name} />
									{/* </ListItemButton> */}
								</ListItem>
							);
						})}
					</List>
				</Box>
			</Popover>
		</>
	);
};
