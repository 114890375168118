import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Box,
	Button,
	Grid,
	Checkbox,
	MenuItem,
	Typography,
	ListItem,
	ListItemText,
	List,
	ListItemIcon,
	IconButton,
} from '@material-ui/core';
import moment from 'moment';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as GeneralIcon } from 'src/assets/images/icons/events/general.svg';
import { ReactComponent as TimeDateIcon } from 'src/assets/images/icons/events/time-date.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const FightDetailsTab = ({
	fightTitleList,
	fightIndex,
	create_data,
	eventFightData,
	tourFightList,
	eventFightCard,
	disciplinesData,
	fightRuleList,
	superEventFightDetails,
	eventStatus,
}) => {
	const { t } = useTranslation();

	let { fight_details, event_fight_card, event_confirm, event_status, event_details, current_tab } = create_data;
	const formRef = useRef();
	const super_event_details = useSelector((state) => state?.Events);
	const [roundValue, setRoundValue] = useState();
	const [addDetails, setAddDeatails] = useState(false);
	const [fightDetails, setFightDetails] = useState();
	const [roundArr, setRoundArr] = useState([]);
	const [overTime, setOverTime] = useState(false);

	const fightDetailsSubmit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();

			if (formRef.current.isValid) {
				// eventFightData([{
				//     event_fight: formRef.current.values
				// }])
				// // var eventFight = [formRef.current.values, { rounds: [] }];
				// setFightDetails(formRef.current.values);
			}
		}
	};
	const weightArr = [
		{ id: 1, name: 'kg' },
		{ id: 2, name: 'lbs' },
	];

	const handleRoundValues = (e) => {
		setRoundValue(e.target.value);
	};
	const deleteRounds = (index) => {
		var deletedArr = roundArr.filter((item, rind) => rind !== index);

		setRoundArr(deletedArr);
	};
	const addRoundValues = (e, index, fieldName) => {
		var finalRoundArr = [];
		var existRound = [...roundArr];

		existRound.map((item, i) => {
			var obj = {
				label: i === index && fieldName === 'roundName' ? e.target.value : item.label,
				rules_meta_category_id: i === index && fieldName === 'rules' ? e.target.value : item.rules_meta_category_id,
				time: i === index && fieldName === 'duration' ? e.target.value : item.time,
			};

			finalRoundArr[i] = obj;
		});
		finalRoundArr && setRoundArr(finalRoundArr);
		setFightDetails({
			...fightDetails,
			rounds: finalRoundArr,
		});
	};

	// useEffect(() => {
	//     // if (fightDetails) {
	//     //     fightDetails.rounds = roundArr;
	//     // }
	//     roundArr && setFightDetails({
	//         ...fightDetails,
	//         rounds: roundArr
	//     })
	// }, [roundArr]);
	useEffect(() => {
		EventEmitter.subscribe('submit_Fight_details', fightDetailsSubmit);
	}, []);
	// useEffect(() => {
	//     fight_details?.fight_details_data && setFightDetails(fight_details?.fight_details_data);
	//     fight_details?.event_fight?.rounds && setRoundValue(fight_details?.event_fight?.rounds?.length);
	//     fight_details?.event_fight?.rounds && setRoundArr(fight_details?.event_fight?.rounds);
	//     EventEmitter.subscribe('submit_Fight_details', fightDetailsSubmit);
	//     // eslint-disable-next-line

	//     console.log(fight_details, fight_details?.event_fight?.rounds, "fight_details?.fight_details_data?.rounds")
	// }, [fight_details]);
	useEffect(() => {
		if (event_status === 'draft' && current_tab === 1) {
			var eventAllData = {
				event_details: event_details,
				event_fight_card: event_fight_card,
			};

			EventEmitter.dispatch('create_draft_event', eventAllData);
			eventStatus('published');
		}
	}, [event_status]);
	useEffect(() => {
		var obj = { label: '', rules_meta_category_id: '', time: '' };

		// if (!fight_details?.event_fight?.rounds) {
		roundArr.length === 0 && setRoundArr(Array.from({ length: roundValue }, (x, i) => obj));
		// }
		// else if (fight_details?.event_fight?.rounds) {
		//     var roundObj = fight_details?.event_fight?.rounds && fight_details?.event_fight?.rounds.map((item, index) => {
		//         if (fight_details?.event_fight?.rounds[index]) {
		//             return (item[index]);
		//         }
		//         return ({});
		//     })
		//     setRoundArr(roundObj)
		// }
	}, [roundValue]);
	useEffect(() => {
		// if (create_data?.super_event_status === true && create_data?.category?.name === "Card") {
		//     var eventIndex = `eventindex-${create_data?.super_event_page}`
		//     var superEventFDetails = super_event_details?.super_event_fight_details;
		//     if (create_data?.super_event_page === 2) {
		//         superEventFightDetails({
		//             eventindex2: {
		//                 ...superEventFDetails?.eventindex2, event_fight: fightDetails
		//             }
		//         })
		//     } else if (create_data?.super_event_page === 3) {
		//         superEventFightDetails({
		//             eventindex3: {
		//                 ...superEventFDetails?.eventindex3, event_fight: fightDetails
		//             }
		//         })
		//     } else if (create_data?.super_event_page === 4) {
		//         superEventFightDetails({
		//             eventindex4: {
		//                 ...superEventFDetails?.eventindex4, event_fight: fightDetails
		//             }
		//         })
		// }
		// var superEventFDetails = super_event_details?.super_event_fight_details;

		// var superFightEventData = {
		//     ...superEventFDetails,
		//     [eventIndex]: { ...fight_details, event_fight: fightDetails }
		// }

		// fightDetails && superEventFightDetails({ ...superFightEventData, event_fight: fightDetails })
		// } else {
		var fightDetailsData = {
			id: fightDetails?.id,
			title_fight_meta_category_id: fightDetails?.title_fight_meta_category_id,
			tournament_fight_meta_category_id: fightDetails?.tournament_fight_meta_category_id,
			name: fightDetails?.name,
			description: fightDetails?.description,
			discipline_id: fightDetails?.discipline_id,
			rules_meta_category_id: fightDetails?.rules_meta_category_id,
			start_time: fightDetails?.start_time,
			end_time: fightDetails?.end_time,
			...(fightDetails?.weight_name === 'lbs' && { weight_lb: fightDetails.weight_value }),
			...(fightDetails?.weight_name === 'kg' && { weight_kg: fightDetails.weight_value }),
			ot_time: fightDetails?.ot_time,
		};

		fightDetails &&
			eventFightData({
				...fight_details,
				event_fight: fightDetailsData,
			});
	}, [fightDetails]);
	useEffect(() => {
		if (fight_details) {
			if (
				event_fight_card === undefined ||
				event_fight_card[fightIndex] === undefined ||
				event_fight_card[fightIndex].length === 0 ||
				event_fight_card[fightIndex]?.event_fight === undefined
			) {
				let eventIndex = `eventindex-${create_data?.super_event_page}`;
				let superEventFDetails = super_event_details?.super_event_fight_details;
				let fightDetailsData =
					event_fight_card &&
					event_fight_card.map((item, index) => {
						if (fightIndex === index) {
							return { ...item, event_fight: fight_details?.event_fight };
						}

						return item;
					});

				fightDetailsData && eventFightCard(fightDetailsData);
				fightDetailsData && setAddDeatails(true);
				superEventFightDetails({ ...superEventFDetails, [eventIndex]: fightDetailsData });
			} else if (event_fight_card && event_fight_card[fightIndex] && event_fight_card[fightIndex]?.event_fight) {
				if (fight_details?.event_fight !== event_fight_card[fightIndex]?.event_fight) {
					let eventIndex = `eventindex-${create_data?.super_event_page}`;
					let superEventFDetails = super_event_details?.super_event_fight_details;
					let fightDetailsData =
						event_fight_card &&
						event_fight_card.map((item, index) => {
							if (fightIndex === index) {
								return { ...item, event_fight: fight_details?.event_fight };
							}

							return item;
						});

					fightDetailsData && eventFightCard(fightDetailsData);
					fightDetailsData && setAddDeatails(true);
					superEventFightDetails({ ...superEventFDetails, [eventIndex]: fightDetailsData });
				}
			}
		}
	}, [fight_details]);

	var initialvalue = {};

	useEffect(() => {
		if (event_fight_card && event_fight_card[fightIndex] && event_fight_card[fightIndex]?.event_fight) {
			var startTime =
				event_fight_card[fightIndex]?.event_fight?.start_time &&
				moment(event_fight_card[fightIndex]?.event_fight?.start_time).format('YYYY-MM-DDTHH:mm:ss');
			var endTime =
				event_fight_card[fightIndex]?.event_fight?.end_time &&
				moment(event_fight_card[fightIndex]?.event_fight?.end_time).format('YYYY-MM-DDTHH:mm:ss');

			initialvalue = {
				name: event_fight_card[fightIndex]?.event_fight?.name ? event_fight_card[fightIndex]?.event_fight?.name : '',
				tournament_fight_meta_category_id: event_fight_card[fightIndex]?.event_fight?.tournament_fight_meta_category_id
					? event_fight_card[fightIndex]?.event_fight?.tournament_fight_meta_category_id
					: '',
				title_fight_meta_category_id: event_fight_card[fightIndex]?.event_fight?.title_fight_meta_category_id
					? event_fight_card[fightIndex]?.event_fight?.title_fight_meta_category_id
					: '',
				description: event_fight_card[fightIndex]?.event_fight?.description
					? event_fight_card[fightIndex]?.event_fight?.description
					: '',
				discipline_id: event_fight_card[fightIndex]?.event_fight?.discipline_id
					? event_fight_card[fightIndex]?.event_fight?.discipline_id
					: '',
				rules_meta_category_id: event_fight_card[fightIndex]?.event_fight?.rules_meta_category_id
					? event_fight_card[fightIndex]?.event_fight?.rules_meta_category_id
					: '',
				start_time: startTime ? startTime : '',
				end_time: endTime ? endTime : '',
				round: event_fight_card[fightIndex]?.event_fight?.rounds
					? event_fight_card[fightIndex]?.event_fight?.rounds.length
					: '',
				ot_time: event_fight_card[fightIndex]?.event_fight?.ot_time
					? event_fight_card[fightIndex]?.event_fight?.ot_time
					: '',
				weight_name: event_fight_card[fightIndex]?.event_fight?.weight_lb
					? 'lbs'
					: event_fight_card[fightIndex]?.event_fight?.weight_kg
					? 'kg'
					: '',
				weight_value: event_fight_card[fightIndex]?.event_fight?.weight_lb
					? event_fight_card[fightIndex]?.event_fight?.weight_lb
					: event_fight_card[fightIndex]?.event_fight?.weight_kg
					? event_fight_card[fightIndex]?.event_fight?.weight_kg
					: '',
			};
			formRef.current.setFieldValue('name', initialvalue?.name);
			formRef.current.setFieldValue(
				'tournament_fight_meta_category_id',
				initialvalue?.tournament_fight_meta_category_id,
			);
			formRef.current.setFieldValue('title_fight_meta_category_id', initialvalue?.title_fight_meta_category_id);
			formRef.current.setFieldValue('description', initialvalue?.description);
			formRef.current.setFieldValue('discipline_id', initialvalue?.discipline_id);
			formRef.current.setFieldValue('rules_meta_category_id', initialvalue?.rules_meta_category_id);
			formRef.current.setFieldValue('start_time', initialvalue?.start_time);
			formRef.current.setFieldValue('end_time', initialvalue?.end_time);
			formRef.current.setFieldValue('round', initialvalue?.round);
			formRef.current.setFieldValue('ot_time', initialvalue?.ot_time);
			formRef.current.setFieldValue('weight_name', initialvalue?.weight_name);
			formRef.current.setFieldValue('weight_value', initialvalue?.weight_value);
		}

		if (
			event_fight_card === undefined ||
			event_fight_card[fightIndex] === undefined ||
			event_fight_card[fightIndex]?.event_fight === undefined
		) {
			formRef.current.setFieldValue('name', '');
			formRef.current.setFieldValue('tournament_fight_meta_category_id', '');
			formRef.current.setFieldValue('title_fight_meta_category_id', '');
			formRef.current.setFieldValue('description', '');
			formRef.current.setFieldValue('discipline_id', '');
			formRef.current.setFieldValue('rules_meta_category_id', '');
			formRef.current.setFieldValue('start_time', '');
			formRef.current.setFieldValue('end_time', '');
			formRef.current.setFieldValue('round', '');
			formRef.current.setFieldValue('ot_time', '');
			formRef.current.setFieldValue('weight_name', '');
			formRef.current.setFieldValue('weight_value', '');
			eventFightData([]);
			setFightDetails();
		}
	}, [fightIndex]);
	useEffect(() => {
		if (event_fight_card && !addDetails) {
			event_fight_card[fightIndex] && Object.keys(event_fight_card[fightIndex]).length === 0 && setRoundArr([]);
			event_fight_card[fightIndex] && Object.keys(event_fight_card[fightIndex]).length === 0 && setRoundValue();

			if (event_fight_card[fightIndex] && event_fight_card[fightIndex]?.event_fight) {
				// setFightDetails(event_fight_card[fightIndex]?.event_fight)
				if (event_fight_card[fightIndex]?.event_fight?.rounds !== undefined) {
					setRoundArr(event_fight_card[fightIndex]?.event_fight?.rounds);
				} else {
					setRoundArr([]);
				}

				var eventFightDataAr = event_fight_card[fightIndex]?.event_fight;

				eventFightDataAr.start_time = moment(eventFightDataAr.start_time).format('YYYY-MM-DDTHH:mm:ss');
				eventFightDataAr.end_time = moment(eventFightDataAr.end_time).format('YYYY-MM-DDTHH:mm:ss');
				var fightDataFormatted = {
					id: eventFightDataAr?.id,
					title_fight_meta_category_id: eventFightDataAr?.title_fight_meta_category_id,
					tournament_fight_meta_category_id: eventFightDataAr?.tournament_fight_meta_category_id,
					name: eventFightDataAr?.name,
					description: eventFightDataAr?.description,
					discipline_id: eventFightDataAr?.discipline_id,
					rules_meta_category_id: eventFightDataAr?.rules_meta_category_id,
					start_time: eventFightDataAr?.start_time,
					end_time: eventFightDataAr?.end_time,
					...(eventFightDataAr?.weight_lb && { weight_name: 'lbs', weight_value: eventFightDataAr.weight_lb }),
					...(eventFightDataAr?.weight_kg && { weight_name: 'kg', weight_value: eventFightDataAr.weight_kg }),
					ot_time: fightDetails?.ot_time,
				};

				setFightDetails(fightDataFormatted);
				setAddDeatails(false);
			}
			// else if (event_confirm === 'update' && fightIndex && event_fight_card[fightIndex]) {
			//     event_fight_card[fightIndex]?.event_fight && setFightDetails(event_fight_card[fightIndex]?.event_fight);

			// }
		}
	}, [
		event_fight_card !== undefined &&
			event_fight_card[fightIndex] !== undefined &&
			event_fight_card[fightIndex]?.event_fight !== undefined,
	]);

	return (
		<Box className="event-fight-form">
			<Formik
				innerRef={formRef}
				initialValues={{
					name: '',
					tournament_fight_meta_category_id: '',
					title_fight_meta_category_id: '',
					description: '',
					discipline_id: '',
					rules_meta_category_id: '',
					start_time: '',
					end_time: '',
					round: '',
					roundrules: '',
					roundName: '',
					weight_name: '',
					weight_value: '',
					ot_time: '',
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required(t('validation.required_message')),
					// tournament_fight_meta_category_id: Yup.string().required(
					//     t('validation.required_message')
					// ),
					// title_fight_meta_category_id: Yup.string().required(
					//     t('validation.required_message')
					// ),
					// description: Yup.string().required(
					//     t('validation.required_message')
					// ),
					// discipline_id: Yup.string().required(
					//     t('validation.required_message')
					// ),
					// rules_meta_category_id: Yup.string().required(
					//     t('validation.required_message')
					// ),
					start_time: Yup.date()
						.min(new Date(), 'Please choose future date')
						.required(t('validation.required_message')),
					end_time: Yup.date()
						.min(Yup.ref('start_time'), 'End date has to be more than start date')
						.required(t('validation.required_message')),
					// rounds: Yup.number().required(
					//     t('validation.required_message')
					// ),
					// roundrules: Yup.string().required(
					//     t('validation.required_message')
					// ),
					// roundName: Yup.string().required(
					//     t('validation.required_message')
					// ),
				})}
				onSubmit={(data) => {
					// console.log(data, "fightfight")
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="event-titles">
								<GeneralIcon />
								<Typography className="event-titles-name">{t('events.general')}</Typography>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<Box className="event-cover-box">
									<TextField
										error={Boolean(touched.title_fight_meta_category_id && errors.title_fight_meta_category_id)}
										fullWidth
										helperText={touched.title_fight_meta_category_id && errors.title_fight_meta_category_id}
										variant="filled"
										InputProps={{
											endAdornment:
												touched.title_fight_meta_category_id && errors.title_fight_meta_category_id ? (
													<ErrorIcon />
												) : null,
										}}
										label={t('events.title_fight')}
										placeholder={t('events.title_fight')}
										className="matrix-textfield"
										size="small"
										name="title_fight_meta_category_id"
										onBlur={handleBlur}
										onChange={(e) => {
											handleChange(e);
											setFightDetails({
												...fightDetails,
												title_fight_meta_category_id: e.target.value,
											});
										}}
										value={values.title_fight_meta_category_id}
										select
									>
										{fightTitleList?.map((obj, index) => {
											return (
												<MenuItem key={index} value={obj?.id}>
													{obj?.name}
												</MenuItem>
											);
										})}
									</TextField>
								</Box>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.tournament_fight_meta_category_id && errors.tournament_fight_meta_category_id)}
									fullWidth
									helperText={touched.tournament_fight_meta_category_id && errors.tournament_fight_meta_category_id}
									variant="filled"
									InputProps={{
										endAdornment:
											touched.tournament_fight_meta_category_id && errors.tournament_fight_meta_category_id ? (
												<ErrorIcon />
											) : null,
									}}
									label={t('events.tournament_fight')}
									placeholder={t('events.tournament_fight')}
									className="matrix-textfield"
									size="small"
									name="tournament_fight_meta_category_id"
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										setFightDetails({
											...fightDetails,
											tournament_fight_meta_category_id: e.target.value,
										});
									}}
									value={values.tournament_fight_meta_category_id}
									select
								>
									{tourFightList?.map((obj, index) => {
										return (
											<MenuItem key={index} value={obj?.id}>
												{obj?.name}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.name && errors.name)}
									fullWidth
									helperText={touched.name && errors.name}
									variant="filled"
									InputProps={{
										endAdornment: touched.name && errors.name ? <ErrorIcon /> : null,
									}}
									label={t('events.fight_name')}
									placeholder={t('events.fight_name')}
									className="matrix-textfield"
									size="small"
									name="name"
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										setFightDetails({
											...fightDetails,
											name: e.target.value,
										});
									}}
									value={values.name}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.description && errors.description)}
									fullWidth
									helperText={touched.description && errors.description}
									variant="filled"
									multiline
									rows={4}
									InputProps={{
										endAdornment: touched.description && errors.description ? <ErrorIcon /> : null,
									}}
									label={t('events.description')}
									placeholder={t('events.description')}
									className="matrix-textfield"
									size="small"
									name="description"
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										setFightDetails({
											...fightDetails,
											description: e.target.value,
										});
									}}
									value={values.description}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.discipline_id && errors.discipline_id)}
									fullWidth
									helperText={touched.discipline_id && errors.discipline_id}
									variant="filled"
									InputProps={{
										endAdornment: touched.discipline_id && errors.discipline_id ? <ErrorIcon /> : null,
									}}
									label={t('events.discipline')}
									placeholder={t('events.discipline')}
									className="matrix-textfield"
									size="small"
									name="discipline_id"
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										setFightDetails({
											...fightDetails,
											discipline_id: e.target.value,
										});
									}}
									value={values.discipline_id}
									select
								>
									{disciplinesData?.map((obj, index) => {
										return (
											<MenuItem key={index} value={obj?.id}>
												{obj?.name}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.rules_meta_category_id && errors.rules_meta_category_id)}
									fullWidth
									helperText={touched.rules_meta_category_id && errors.rules_meta_category_id}
									variant="filled"
									InputProps={{
										endAdornment:
											touched.rules_meta_category_id && errors.rules_meta_category_id ? <ErrorIcon /> : null,
									}}
									label={t('events.rules')}
									placeholder={t('events.rules')}
									className="matrix-textfield"
									size="small"
									name="rules_meta_category_id"
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										setFightDetails({
											...fightDetails,
											rules_meta_category_id: e.target.value,
										});
									}}
									value={values.rules_meta_category_id}
									select
								>
									{fightRuleList?.map((obj, index) => {
										return (
											<MenuItem key={index} value={obj?.id}>
												{obj?.name}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={10} lg={10} className="age-field">
									<TextField
										fullWidth
										variant="filled"
										label={t('events.weight_limit')}
										placeholder={t('events.weight_limit')}
										className="matrix-textfield"
										size="small"
										name="weight_value"
										type="number"
										onBlur={handleBlur}
										onChange={(e) => {
											handleChange(e);
											// if (weightName === 'kg') {
											setFightDetails({
												...fightDetails,
												weight_value: e.target.value,
											});
											// }
											// if (weightName === 'lbs') {
											//     setFightDetails({
											//         ...fightDetails,
											//         weight_lb: e.target.value
											//     })
											// }
										}}
										value={values.weight_value}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={2} className="date-field">
									<TextField
										fullWidth
										variant="filled"
										label={t('events.weight')}
										placeholder={t('events.weight')}
										className="matrix-textfield"
										size="small"
										select
										onBlur={handleBlur}
										onChange={(e) => {
											handleChange(e);
											setFightDetails({
												...fightDetails,
												weight_name: e.target.value,
											});
											e.target.value && formRef.current.setFieldValue('weight_name', e.target.value);
										}}
										value={values.weight_name}
									>
										{weightArr.map((w, index) => {
											return (
												<MenuItem key={index} value={w?.name}>
													{w?.name}
												</MenuItem>
											);
										})}
									</TextField>
								</Grid>
							</Grid>
							<Grid item xs={12} md={12} className="event-titles">
								<TimeDateIcon />
								<Typography className="event-titles-name">{t('events.time_date')}</Typography>
							</Grid>
							<Box className="event-cover-box date-birth-grid">
								<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
									<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
										<TextField
											error={Boolean(touched.start_time && errors.start_time)}
											fullWidth
											helperText={touched.start_time && errors.start_time}
											variant="filled"
											// InputProps={{
											//   endAdornment: <ClockIcon />
											// }}
											label={t('events.start_time')}
											placeholder={t('events.start_time')}
											className="matrix-textfield"
											size="small"
											name="start_time"
											type="datetime-local"
											onBlur={handleBlur}
											onChange={(e) => {
												var dateFormatStart = moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss');

												handleChange(e);
												setFightDetails({
													...fightDetails,
													start_time: dateFormatStart,
												});
											}}
											InputLabelProps={{
												shrink: true,
											}}
											value={values.start_time}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} className="age-field">
										<TextField
											error={Boolean(touched.end_time && errors.end_time)}
											fullWidth
											helperText={touched.end_time && errors.end_time}
											variant="filled"
											// InputProps={{
											//   endAdornment: <ClockIcon />
											// }}
											label={t('events.end_time')}
											placeholder={t('events.end_time')}
											className="matrix-textfield"
											size="small"
											name="end_time"
											onBlur={handleBlur}
											onChange={(e) => {
												var dateFormatEnd = moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss');

												handleChange(e);
												setFightDetails({
													...fightDetails,
													end_time: dateFormatEnd,
												});
											}}
											value={values.end_time}
											type="datetime-local"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
								</Grid>
							</Box>

							<Grid item xs={12} md={12} className="event-titles">
								<TimeDateIcon />
								<Typography className="event-titles-name">{t('events.round_structure')}</Typography>
							</Grid>
							<Box className="event-cover-box date-birth-grid">
								<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
									{/* EVENT START TIME */}
									<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
										<List>
											<ListItem disablePadding>
												<ListItemIcon>
													<Checkbox
														edge="start"
														// checked={checked.indexOf(value?.id) !== -1}
														tabIndex={-1}
														disableRipple
														checked={overTime}
														onChange={() => {
															overTime ? setOverTime(false) : setOverTime(true);
														}}
														inputProps={{ 'aria-labelledby': 'checkbox-list-label-1' }}
													/>
												</ListItemIcon>
												<ListItemText id={'checkbox-list-label-1'} primary={t('events.overtime')} />
											</ListItem>
										</List>
									</Grid>

									{/* EVENT END TIME */}
									<Grid item xs={12} sm={12} md={6} lg={6} className="age-field">
										<TextField
											fullWidth
											variant="filled"
											label={t('events.overtime')}
											placeholder={t('events.overtime')}
											className="matrix-textfield"
											size="small"
											onChange={(e) => {
												handleChange(e);
												setFightDetails({
													...fightDetails,
													ot_time: e.target.value,
												});
											}}
											name="ot_time"
											disabled={!overTime}
											type="time"
											InputLabelProps={{
												shrink: true,
											}}
											value={values.ot_time}
										/>
									</Grid>
								</Grid>
							</Box>
							<Box className="date-birth-grid">
								<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
									<Grid item xs={12} sm={12} md={6} lg={6} className="date-field">
										<TextField
											error={Boolean(touched.round && errors.round)}
											fullWidth
											helperText={touched.round && errors.round}
											variant="filled"
											InputProps={{
												endAdornment: touched.round && errors.round ? <ErrorIcon /> : null,
											}}
											label={t('events.rounds')}
											placeholder={t('events.rounds')}
											className="matrix-textfield"
											size="small"
											name="round"
											type="number"
											onBlur={handleBlur}
											onChange={(e) => {
												const re = /^[0-9\b]+$/;

												if (e.target.value === '' || re.test(e.target.value)) {
													handleChange(e);
													handleRoundValues(e);
												}
											}}
											value={values.round}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} className="age-field">
										<TextField
											fullWidth
											variant="filled"
											label={t('events.round_duration')}
											placeholder={t('events.round_duration')}
											className="matrix-textfield"
											size="small"
											// onChange={e => {
											//     let newVal = {
											//         ...searchEvent,
											//         endTime: e.target.value
											//     };

											//     setSearchEventDetails(newVal);
											// }}
											type="time"
											InputLabelProps={{
												shrink: true,
											}}
											// value={searchEvent?.endTime}
										/>
									</Grid>
								</Grid>
							</Box>
							{roundArr &&
								roundArr.map((round, rindex) => {
									var roundName = `roundName-${rindex + 1}`;
									var roundrules = `rules_meta_category_id-${rindex + 1}`;
									var time = `time-${rindex + 1}`;

									return (
										<Box className="date-birth-grid">
											<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
												<Grid item xs={12} sm={12} md={4} lg={4} className="date-field">
													<TextField
														error={Boolean(touched.roundName && errors.roundName)}
														fullWidth
														helperText={touched.roundName && errors.roundName}
														variant="filled"
														InputProps={{
															endAdornment: touched.roundName && errors.roundName ? <ErrorIcon /> : null,
														}}
														label={t('combat_fight_record.name')}
														placeholder={t('combat_fight_record.name')}
														className="matrix-textfield"
														size="small"
														name={roundName}
														onBlur={handleBlur}
														onChange={(e) => {
															handleChange(e);
															addRoundValues(e, rindex, 'roundName');
														}}
														value={round?.label}
													/>
												</Grid>
												<Grid item xs={12} sm={12} md={4} lg={4}>
													<TextField
														error={Boolean(touched.roundrules && errors.roundrules)}
														fullWidth
														helperText={touched.roundrules && errors.roundrules}
														variant="filled"
														InputProps={{
															endAdornment: touched.roundrules && errors.roundrules ? <ErrorIcon /> : null,
														}}
														label={t('events.rules')}
														placeholder={t('events.rules')}
														className="matrix-textfield"
														size="small"
														name={roundrules}
														onBlur={handleBlur}
														onChange={(e) => {
															handleChange(e);
															addRoundValues(e, rindex, 'rules');
														}}
														value={round?.rules_meta_category_id}
														select
													>
														{fightRuleList?.map((obj, index) => {
															return (
																<MenuItem key={index} value={obj?.id}>
																	{obj?.name}
																</MenuItem>
															);
														})}
													</TextField>
												</Grid>
												<Grid item xs={12} sm={12} md={4} lg={4} className="age-field">
													<TextField
														fullWidth
														variant="filled"
														label={t('events.duration')}
														placeholder={t('events.duration')}
														className="matrix-textfield"
														size="small"
														onChange={(e) => {
															handleChange(e);
															addRoundValues(e, rindex, 'duration');
														}}
														type="time"
														name={time}
														InputLabelProps={{
															shrink: true,
														}}
														value={round?.time}
													/>
												</Grid>
												<IconButton onClick={() => deleteRounds(rindex)}>
													<DeleteIcon />
												</IconButton>
											</Grid>
										</Box>
									);
								})}
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
