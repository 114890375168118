import { Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './deleteModal.scss';

export const DeleteModal = ({ onClick, text, handleClose }) => {
	const { t } = useTranslation();

	return (
		<Box className="delete-modal">
			<Typography className="delete-text">{text}</Typography>

			<Box className="delete-btn-sec">
				<Button fullWidth className="decline-btn" onClick={handleClose}>
					{t('cancel')}
				</Button>
				<Button fullWidth className="delete-btn" onClick={onClick}>
					{t('community.delete')}
				</Button>
			</Box>
		</Box>
	);
};
