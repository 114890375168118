import { useState, useEffect } from 'react';
import { Box, InputAdornment, withStyles, TextField, Button, Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { ReactComponent as FilterIcon } from 'src/assets/images/icons/community/filter.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { MultipleSelectCheckmarks, EventCard } from 'src/components/ui';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';

import { useNavigate } from 'react-router-dom';
import { DataRangeTime } from '../DataRangeTime';
import { LocationCheckmarks } from '../LocationCheckmarks';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const YouHaveCreated = ({
	tabName,
	slidebarFil,
	fetchEventList,
	eventList,
	eventTypeList,
	currentCreateEventTab,
	eventDetails,
	eventType,
	eventFightCard,
	eventTicket,
	eventConfirm,
	category,
	eventAdmin,
	superEventStatus,
	eventInvite,
	currentFightTab,
	eventStatus,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchVal, setSearchVal] = useState('');
	const [isStored, setIsStore] = useState(false);
	// const [selectedDateRange, setSelectedDateRange] = useState([]);
	// const [eventDetailsData, setEventDetails] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState([]);
	const [selectedEventType, setSelectedEventType] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [formData, setFormData] = useState({});
	const [locFormData, setLocFormData] = useState({});
	const EVENT_FILTER = fetchFromStorage('eventfilter');
	const locationList = [
		{ id: 'online', name: t('events.online') },
		{ id: 'venue', name: t('events.venue') },
	];
	const statusList = [
		{ id: 'published', name: t('events.published') },
		{ id: 'draft', name: t('events.draft') },
	];

	const handleChange = (e) => {
		setSearchVal(e.target.value);
	};
	const handleFormData = (data) => {
		setFormData(data);
	};
	const handleLocFormData = (data) => {
		setLocFormData(data);
	};
	const onLocationSelect = (data) => {
		const currentIndex = selectedLocation.indexOf(data);
		const newChecked = [...selectedLocation];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedLocation(newChecked);
	};
	const onEventTypeSelect = (data) => {
		const currentIndex = selectedEventType.indexOf(data);
		const newChecked = [...selectedEventType];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedEventType(newChecked);
	};
	const onStatusSelect = (data) => {
		const currentIndex = selectedStatus.indexOf(data);
		const newChecked = [...selectedStatus];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedStatus(newChecked);
	};
	const handleFilter = () => {
		var startDate = formData?.start_time ? moment(formData?.start_time).format('YYYY-MM-DD') : '';
		var startTime = formData?.start_time ? moment(formData?.start_time).format('h:mm:ss') : '';
		var endDate = formData?.end_time ? moment(formData?.end_time).format('YYYY-MM-DD') : '';
		var endTime = formData?.end_time ? moment(formData?.end_time).format('h:mm:ss') : '';
		var queryParams = {
			...(selectedLocation.length > 0 && {
				location_type: selectedLocation.join(','),
			}),
			...(selectedEventType.length > 0 && {
				event_type: selectedEventType.join(','),
			}),
			start_time: `${startDate} ${startTime}`,
			end_time: `${endDate} ${endTime}`,
			...(selectedLocation.includes('venue') && {
				lat: locFormData?.lat,
			}),
			...(selectedLocation.includes('venue') && {
				long: locFormData?.long,
			}),
			...(selectedStatus.length > 0 && {
				status: selectedStatus.join(','),
			}),
		};

		fetchEventList(tabName, queryParams, '', slidebarFil);

		var obj = {
			// ...EVENT_FILTER,
			...(selectedLocation.length > 0 && {
				location_type: selectedLocation.join(','),
			}),
			...(selectedEventType.length > 0 && {
				event_type: selectedEventType.join(','),
			}),
			start_time: `${startDate} ${startTime}`,
			end_time: `${endDate} ${endTime}`,
			...(selectedLocation.includes('venue') && {
				lat: locFormData?.lat,
			}),
			...(selectedLocation.includes('venue') && {
				long: locFormData?.long,
			}),
			...(selectedStatus.length > 0 && {
				status: selectedStatus.join(','),
			}),
			...(selectedLocation.includes('venue') && {
				locationName:
					locFormData?.requestdata?.address !== undefined
						? locFormData?.requestdata?.address
						: EVENT_FILTER?.queryParams?.locationName
						? EVENT_FILTER?.queryParams?.locationName
						: '',
			}),
		};
		// var locationName = selectedLocation.includes('venue') ? locFormData?.requestdata?.address : '';
		var eventFilterObj = {
			...EVENT_FILTER,
			tab: tabName,
			queryParams: obj,
		};

		saveToStorage('eventfilter', eventFilterObj);
		// saveToStorage('eventfilter', { tab: tabName, queryParams: obj, slidebarFilter: slidebarFil });
	};
	// var formlength = Object.keys(formData).length;
	const handleSearch = () => {
		fetchEventList(tabName, '', searchVal, slidebarFil);
	};
	const fetchEventDetails = async (eID) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getEventDetails + `${eID}`);

			if (status === 200) {
				var eventDetailsData = data?.eventDetails[0];
				var inviteData = [];
				// eventDetailsData?.event_admin.event_admin &&
				var locationData =
					eventDetailsData?.event_locations &&
					eventDetailsData?.event_locations.map((locations, index) => {
						// return (locations?.address);
						return {
							address: locations?.address?.address,
							lat: locations?.address?.lat,
							long: locations?.address?.long,
							city: locations?.address?.city,
							state: locations?.address?.state,
							country: locations?.address?.country,
						};
					});
				var event_date =
					eventDetailsData &&
					eventDetailsData?.event_date &&
					eventDetailsData?.event_date.map((item, index) => {
						return {
							start_time: item?.start_time,
							end_time: item?.end_time,
						};
					});

				inviteData.push(
					{
						receiver_type: 'user',
						receiver_id: eventDetailsData?.event_admin?.event_admin,
					},
					{
						receiver_type: 'community',
						receiver_id: eventDetailsData?.event_admin?.event_community,
					},
					{
						receiver_type: 'organisation',
						receiver_id: eventDetailsData?.event_admin?.event_organisation,
					},
				);

				var fightEventsData =
					eventDetailsData &&
					eventDetailsData?.event_fight &&
					eventDetailsData?.event_fight.map((fightData, index) => {
						var contestData = fightData.EventFightContestantsDetails.map((constData, cind) => {
							var fightContestants = constData.EventFightContestants.map((item, i) => {
								return {
									// ...(item?.fighter_type === 'user' && { userId: item?.user_fighter_id }),
									// ...(item?.fighter_type === 'user_other' && { userName: item?.user_name }),
									userName: { user_id: item?.user_fighter_id, user_name: item?.user_name, type: item?.fighter_type },
									fighter_type: item?.fighter_type,
									meta_weight_class_id: item?.meta_weight_class_id,
									title_meta_category_id: item?.title_meta_category_id,
									fight_method_id: item?.EventFightContestantMethods?.MetaFightMethod?.id,
									result: item?.result,
									time: item?.time,
									round: item?.round_number,
								};
							});

							return {
								...(constData && { fightContestants: fightContestants }),
								...(constData && {
									fight_side_name: { id: constData?.EventFightSide?.id, name: constData?.EventFightSide?.name },
									event_fight_id: fightData?.id,
								}),
							};
						});

						var eventsFight = {
							event_fight: {
								id: fightData?.id,
								name: fightData?.name,
								description: fightData?.description,
								discipline_id: fightData?.discipline_id,
								rules_meta_category_id: fightData?.rules_meta_category_id,
								title_fight_meta_category_id: fightData?.title_fight_meta_category_id,
								tournament_fight_meta_category_id: fightData?.tournament_fight_meta_category_id,
								weight_lb: fightData?.weight_lb,
								weight_kg: fightData?.weight_kg,
								start_time: fightData?.start_time,
								end_time: fightData?.end_time,
								ot_time: '',
								rounds: '',
							},
							event_fight_combatant: contestData,
						};

						return eventsFight;
					});

				eventDetailsData && currentCreateEventTab(0);
				eventDetailsData && eventConfirm('update');
				eventDetailsData && currentFightTab(0);
				eventDetailsData && eventStatus('published');
				eventDetailsData && eventType(eventDetailsData?.event_type);
				eventDetailsData &&
					eventDetails({
						formData: {
							event_name: eventDetailsData?.name,
							timezone: eventDetailsData?.timezone,
							location_type: eventDetailsData?.location_type,
							description: eventDetailsData?.description,
						},
						event_id: eventDetailsData?.id,
						imgCoverId: {
							imageId: eventDetailsData?.item_cover_id,
							image: eventDetailsData?.event_profile_data,
						},
						eventDates: event_date,
						eventUrls: eventDetailsData?.event_meta,
						eventLocations: locationData,
					});
				eventDetailsData && eventTicket(eventDetailsData?.event_ticket);
				eventDetailsData && eventAdmin(inviteData);
				eventDetailsData && eventInvite(eventDetailsData?.event_invite);
				eventDetailsData && eventFightCard(fightEventsData);

				// setEventDetails(data?.eventDetails[0]);
				superEventStatus(data?.super_event_status);
				category({
					id: data?.meta_category_id,
					name: data?.meta_category_name,
				});
				setTimeout(() => {
					navigate(`/events/create`);
				}, 500);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const onUpdateEvent = (eventId) => {
		fetchEventDetails(eventId);
	};
	// useEffect(() => {
	//     var inviteData = [];
	//     // eventDetailsData?.event_admin.event_admin &&
	//     var locationData = eventDetailsData?.event_locations &&
	//         eventDetailsData?.event_locations.map((locations, index) => {
	//             // return (locations?.address);
	//             return ({
	//                 address: locations?.address?.address,
	//                 lat: locations?.address?.lat,
	//                 long: locations?.address?.long,
	//                 city: locations?.address?.city,
	//                 state: locations?.address?.state,
	//                 country: locations?.address?.country
	//             })
	//         });
	//     var event_date = eventDetailsData && eventDetailsData?.event_date && eventDetailsData?.event_date.map((item, index) => {
	//         return ({
	//             start_time: item?.start_time,
	//             end_time: item?.end_time
	//         })
	//     })
	//     inviteData.push({
	//         receiver_type: "user",
	//         receiver_id: eventDetailsData?.event_admin?.event_admin
	//     },
	//         {
	//             receiver_type: "community",
	//             receiver_id: eventDetailsData?.event_admin?.event_community
	//         },
	//         {
	//             receiver_type: "organisation",
	//             receiver_id: eventDetailsData?.event_admin?.event_organisation
	//         });

	//     var fightEventsData = eventDetailsData && eventDetailsData?.event_fight && eventDetailsData?.event_fight.map((fightData, index) => {

	//         var contestData = fightData.EventFightContestantsDetails.map((constData, cind) => {
	//             var fightContestants = constData.EventFightContestants.map((item, i) => {

	//                 return ({
	//                     // ...(item?.fighter_type === 'user' && { userId: item?.user_fighter_id }),
	//                     // ...(item?.fighter_type === 'user_other' && { userName: item?.user_name }),
	//                     userName: { user_id: item?.user_fighter_id, user_name: item?.user_name, type: item?.fighter_type },
	//                     fighter_type: item?.fighter_type,
	//                     meta_weight_class_id: item?.meta_weight_class_id,
	//                     title_meta_category_id: item?.title_meta_category_id,
	//                     fight_method_id: item?.EventFightContestantMethods?.MetaFightMethod?.id,
	//                     result: item?.result,
	//                     time: item?.time,
	//                     round: item?.round_number
	//                 });
	//             })

	//             return ({
	//                 ...(constData && { fightContestants: fightContestants }),
	//                 ...(constData && {
	//                     fight_side_name: { id: constData?.EventFightSide?.id, name: constData?.EventFightSide?.name },
	//                     event_fight_id: fightData?.id
	//                 })
	//             });
	//         })

	//         var eventsFight = {
	//             event_fight: {
	//                 id: fightData?.id,
	//                 name: fightData?.name,
	//                 description: fightData?.description,
	//                 discipline_id: fightData?.discipline_id,
	//                 rules_meta_category_id: fightData?.rules_meta_category_id,
	//                 title_fight_meta_category_id: fightData?.title_fight_meta_category_id,
	//                 tournament_fight_meta_category_id: fightData?.tournament_fight_meta_category_id,
	//                 weight_lb: fightData?.weight_lb,
	//                 weight_kg: fightData?.weight_kg,
	//                 start_time: fightData?.start_time,
	//                 end_time: fightData?.end_time,
	//                 ot_time: '',
	//                 rounds: ''
	//             },
	//             event_fight_combatant: contestData
	//         }
	//         return (eventsFight);
	//     })

	//     eventDetailsData && currentCreateEventTab(0);
	//     eventDetailsData && eventConfirm('update');
	//     eventDetailsData && currentFightTab(0);
	//     eventDetailsData && eventStatus("published");
	//     eventDetailsData && eventType(eventDetailsData?.event_type);
	//     eventDetailsData && eventDetails({
	//         formData: {
	//             event_name: eventDetailsData?.name,
	//             timezone: eventDetailsData?.timezone,
	//             location_type: eventDetailsData?.location_type,
	//             description: eventDetailsData?.description
	//         },
	//         event_id: eventDetailsData?.id,
	//         imgCoverId: {
	//             imageId: eventDetailsData?.item_cover_id,
	//             image: eventDetailsData?.event_profile_data
	//         },
	//         eventDates: event_date,
	//         eventUrls: eventDetailsData?.event_meta,
	//         eventLocations: locationData
	//     });
	//     eventDetailsData && eventTicket(eventDetailsData?.event_ticket);
	//     eventDetailsData && eventAdmin(inviteData);
	//     eventDetailsData && eventInvite(eventDetailsData?.event_invite);
	//     eventDetailsData && eventFightCard(fightEventsData);
	// }, [eventDetailsData]);

	useEffect(() => {
		if (!isStored) {
			if (EVENT_FILTER?.queryParams?.location_type) {
				const storedArray = EVENT_FILTER?.queryParams?.location_type.split(',').map((i) => i);
				// var storedArray = eventLocation;
				const areEqual = (selectedLocation, storedArray) => {
					if (selectedLocation.length !== storedArray.length) {
						return false;
					}

					for (let i = 0; i < selectedLocation.length; i++) {
						if (!storedArray.includes(selectedLocation[i])) {
							return false;
						}
					}

					return true;
				};

				if (!areEqual(selectedLocation, storedArray)) {
					setSelectedLocation(storedArray);
				}
			}

			if (EVENT_FILTER?.queryParams?.event_type) {
				var storedEventTypeArray = EVENT_FILTER?.queryParams?.event_type.split(',').map((i) => i);
				const areEqualEventType = (selectedEventType, storedEventTypeArray) => {
					if (selectedEventType.length !== storedEventTypeArray.length) {
						return false;
					}

					for (let i = 0; i < selectedEventType.length; i++) {
						if (!storedEventTypeArray.includes(selectedEventType[i])) {
							return false;
						}
					}

					return true;
				};

				if (!areEqualEventType(selectedEventType, storedEventTypeArray)) {
					setSelectedEventType(storedEventTypeArray);
				}
			}

			if (EVENT_FILTER?.queryParams?.status) {
				var storedStatusArray = EVENT_FILTER?.queryParams?.status.split(',').map((i) => i);
				const areEqualStatus = (selectedStatus, storedStatusArray) => {
					if (selectedStatus.length !== storedStatusArray.length) {
						return false;
					}

					for (let i = 0; i < selectedStatus.length; i++) {
						if (!storedStatusArray.includes(selectedStatus[i])) {
							return false;
						}
					}

					return true;
				};

				if (!areEqualStatus(selectedStatus, storedStatusArray)) {
					setSelectedStatus(storedStatusArray);
				}
			}

			if (EVENT_FILTER?.queryParams?.start_time.trim() || EVENT_FILTER?.queryParams?.end_time.trim()) {
				var sdate = moment(EVENT_FILTER?.queryParams?.start_time).format('YYYY-MM-DD HH:mm:ss');
				var edate = moment(EVENT_FILTER?.queryParams?.end_time).format('YYYY-MM-DD HH:mm:ss');
				var dates = {
					start_time: EVENT_FILTER?.queryParams?.start_time ? sdate : '',
					end_time: EVENT_FILTER?.queryParams?.end_time ? edate : '',
				};

				setFormData(dates);
			}

			setIsStore(true);
		}

		if (EVENT_FILTER === null || !EVENT_FILTER?.queryParams) {
			setSelectedStatus([]);
			setSelectedEventType([]);
			setSelectedLocation([]);
			setFormData({});
		}
	}, [EVENT_FILTER?.queryParams]);

	return (
		<Box className="you-have-created-sec">
			<Box className="search-box">
				<Box className="search-field-box">
					<SearchTextField
						onChange={handleChange}
						fullWidth
						value={searchVal}
						placeholder={t('search.title')}
						size="small"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Button
						type="submit"
						className="search-btn"
						onClick={() => {
							handleSearch();
						}}
					>
						{t('search_btn')}
					</Button>
				</Box>
			</Box>
			<Box className="filter-box">
				<Grid container className="filter-tags">
					<Box style={{ width: '3%' }}>
						<FilterIcon />
					</Box>
					<Box style={{ width: '97%' }} className="filter-options">
						<DataRangeTime
							labelValue={t('events.date_range')}
							formData={formData}
							count={
								formData?.start_time && formData?.end_time ? 2 : formData?.start_time || formData?.end_time ? 1 : 0
							}
							handleFormData={handleFormData}
						/>
						<LocationCheckmarks
							labelValue={t('events.location')}
							count={selectedLocation?.length}
							dropdownArray={locationList}
							onSelect={onLocationSelect}
							checked={selectedLocation}
							formData={locFormData}
							handleFormData={handleLocFormData}
							EVENT_FILTER={EVENT_FILTER}
						/>
						<MultipleSelectCheckmarks
							labelValue={t('events.event_type')}
							count={selectedEventType?.length}
							dropdownArray={eventTypeList}
							onSelect={onEventTypeSelect}
							checked={selectedEventType}
						/>
						<MultipleSelectCheckmarks
							labelValue={t('events.status')}
							count={selectedStatus?.length}
							dropdownArray={statusList}
							onSelect={onStatusSelect}
							checked={selectedStatus}
						/>
					</Box>
				</Grid>
				<Box className="filter-right-buttons">
					<Button
						type="submit"
						className="filter-btn"
						onClick={() => {
							handleFilter();
						}}
					>
						{t('button_text.apply_filter')}
					</Button>
				</Box>
			</Box>
			<Box className="discover-event-box">
				{eventList && eventList?.length > 0 ? (
					<Grid container spacing={3}>
						{eventList?.map((event, index) => {
							return (
								<>
									<Grid item xs={12} sm={12} md={6} lg={6} key={index}>
										<EventCard
											isDiscover={true}
											eventProfile={event?.event_profile_data}
											eventType={event?.event_type}
											isSuperEvent={event?.type === 'super' ? true : false}
											eventName={event?.name}
											startTime={event?.event_date[0]?.start_time}
											eventId={event?.id}
											eventOwner={event?.user_admin_status === 'yes' ? true : false}
											eventTypeList={eventTypeList}
											eventPostId={event?.post_id}
											isDraft={event?.status === 'draft' ? true : false}
											onUpdateEvent={onUpdateEvent}
										/>
									</Grid>
								</>
							);
						})}
					</Grid>
				) : (
					<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
				)}
			</Box>
		</Box>
	);
};
