import { Box, Typography, Grid } from '@material-ui/core';
import { ReactComponent as BadgeVerificationIcon } from 'src/assets/images/icons/verification_icon.svg';
import '../specialbadge.scss';
import { useTranslation } from 'react-i18next';

const sponsor = [
	{
		name: 'verified sponsor',
		content: 'Fully Verified Sponsor',
	},
	{
		name: 'sponsor',
		content: 'Sponsored 1 athlete',
	},
	{
		name: 'leveled sponsor',
		content: 'Sponsors ....... athletes',
	},
	{
		name: 'Connoisseur ',
		content: 'Sponsor athletes from multiple disciplines',
	},
	{
		name: 'King Maker',
		content: 'You sponsored somone who became a world champ for at least 1 year before they became a world champ',
	},
	{
		name: 'loyality',
		content: 'You have sponsored someone for over 5 years',
	},
];

export const SponsorBadge = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="badge_block_wraper">
				<Typography className="general_subtitle">{t('badge.sponsor')}</Typography>
				<Grid container spacing={1}>
					{sponsor.map((item) => {
						return (
							<>
								<Grid item xs={12} md={6} lg={4}>
									<Box className="badge_block">
										<Box className="icon_block">
											<BadgeVerificationIcon />
										</Box>
										<Box className="badge_content">
											<Typography className="block_title">{item.name}</Typography>
											<Typography className="nbsb_title">{item.content}</Typography>
										</Box>
									</Box>
								</Grid>
							</>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};
