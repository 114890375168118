import { useState, useEffect } from 'react';
import { Box, Tab, Typography, Button } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ReactComponent as BestIcon } from 'src/assets/images/icons/best.svg';
import { ReactComponent as PopularIcon } from 'src/assets/images/icons/popular.svg';
import { ReactComponent as DateIcon } from 'src/assets/images/icons/date.svg';
import { ReactComponent as TopIcon } from 'src/assets/images/icons/top.svg';
import { ReactComponent as RecommendedIcon } from 'src/assets/images/icons/recommend.svg';
import './directory.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { CustomPaginations } from 'src/components/ui';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';
import { setApiMessage } from '../../../../../helpers/commonFunctions';

export const CommunityDirectory = ({
	getCommunitiesDirectoryList,
	fetchCommunityList,
	isTab,
	isDirectory,
	setOrderBy,
	orderBy,
}) => {
	const { t } = useTranslation();
	const btnList = [
		{ id: 'best', name: t('community.best') },
		{ id: 'popular', name: t('community.popular') },
		{ id: 'date', name: t('community.date') },
		// { id: 'top', name: t('community.top') },
		// { id: 'recommended', name: t('community.recommended') }
	];

	const [value, setValue] = useState('1');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords
	const [limit] = useState(20); //setList
	const [isLoading, setIsLoading] = useState(false);

	// BUTTON TAG LIST

	//  ALPHABETIC TAB LIST
	const alphabeticTab = [
		{ id: '1', name: 'a' },
		{ id: '2', name: 'b' },
		{ id: '3', name: 'c' },
		{ id: '4', name: 'd' },
		{ id: '5', name: 'e' },
		{ id: '6', name: 'f' },
		{ id: '7', name: 'g' },
		{ id: '8', name: 'h' },
		{ id: '9', name: 'i' },
		{ id: '10', name: 'j' },
		{ id: '11', name: 'k' },
		{ id: '12', name: 'l' },
		{ id: '13', name: 'm' },
		{ id: '14', name: 'n' },
		{ id: '15', name: 'o' },
		{ id: '16', name: 'p' },
		{ id: '17', name: 'q' },
		{ id: '18', name: 'r' },
		{ id: '19', name: 's' },
		{ id: '20', name: 't' },
		{ id: '21', name: 'u' },
		{ id: '22', name: 'v' },
		{ id: '23', name: 'w' },
		{ id: '24', name: 'x' },
		{ id: '25', name: 'y' },
		{ id: '26', name: 'z' },
		{ id: '27', name: '#' },
	];

	const tabChangeHandler = async (event, newValue) => {
		setIsLoading(true);

		try {
			await fetchCommunityList('', newValue, 1);
			setOrderBy(newValue);
			const COMM_FILTER = fetchFromStorage('communityfilter');
			const obj = {
				...COMM_FILTER,
				orderBy: newValue,
			};

			saveToStorage('communityfilter', obj);
		} catch (error) {
			console.log(error);
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};
	const handleChange = (event, newValue) => {
		const charData = alphabeticTab?.find((item) => item?.id === newValue);

		setValue(newValue);
		fetchCommunityDirectory(charData?.name, currentPage);
	};
	const btnIconHandler = (type) => {
		switch (type) {
			case 'best':
				return <BestIcon />;
			case 'popular':
				return <PopularIcon />;
			case 'date':
				return <DateIcon />;
			case 'top':
				return <TopIcon />;
			case 'recommended':
				return <RecommendedIcon />;
			default:
				return <BestIcon />;
		}
	};

	const communityDirectoryList = useSelector((state) => state?.Combat?.communityDirectoryList);
	const fetchCommunityDirectory = async (charData, page) => {
		try {
			const { status, data } = await axiosInstance.get(
				URLS.getCommunitiesDirectory + `?name=${charData}&page=${page}&limit=${limit}`,
			);

			if (status === 200) {
				getCommunitiesDirectoryList(data?.communitiesData);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const handlePaginationButtonClick = async (navDirection) => {
		const charData = alphabeticTab?.find((item) => item?.id === value);

		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchCommunityDirectory(charData?.name, currentPage - 1);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				setCurrentPage(currentPage + 1);
				fetchCommunityDirectory(charData?.name, currentPage + 1);
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		const charData = alphabeticTab?.find((item) => item?.id === value);

		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchCommunityDirectory(charData?.name, Number(page));
		}
	};

	useEffect(() => {
		const charData = alphabeticTab?.find((item) => item?.id === value);

		fetchCommunityDirectory(charData?.name, currentPage);
	}, []);
	const navigate = useNavigate();

	return (
		<>
			{/* BEST / TRENDING / DATE TAB */}
			{isTab && (
				<Box className="bpdtr-box">
					<TabContext value={orderBy}>
						<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler}>
							{btnList?.map((data, index) => {
								return (
									<Tab
										disabled={isLoading}
										key={index}
										icon={btnIconHandler(data?.id)}
										label={data?.name}
										value={data?.id}
										disableRipple
										disableTouchRipple
										disableFocusRipple
									/>
								);
							})}
						</TabList>
					</TabContext>
				</Box>
			)}

			{/* ALPHABETIC TAB */}
			{isDirectory && (
				<Box className="bpdtr-box d-block">
					<Typography className="tab-title">
						{t('community.community_directory')}
						<Button
							disableElevation
							disableTouchRipple
							disableFocusRipple
							disableRipple
							className="see-all-btn-right"
							onClick={() => {
								navigate(`/community-directory`);
							}}
						>
							{t('see_all')}
						</Button>
					</Typography>
					<Box className="scrollable-alphatab">
						<TabContext value={String(value)}>
							<TabList onChange={handleChange} variant="scrollable" scrollButtons="on">
								{alphabeticTab?.map((data, index) => {
									return (
										<Tab
											key={index}
											label={data?.name}
											value={data?.id}
											disableRipple
											disableTouchRipple
											disableFocusRipple
										/>
									);
								})}
							</TabList>
							<TabPanel value={String(value)} className="p-0">
								{communityDirectoryList &&
									communityDirectoryList.map((item, index) => (
										<Typography
											className="comm-dir-title"
											onClick={() => {
												navigate(`/communities/${item?.id}`);
											}}
										>
											{item?.name}
										</Typography>
									))}
							</TabPanel>
							<CustomPaginations
								paginationArray={communityDirectoryList}
								currentPage={currentPage}
								list={limit}
								handlePaginationButtonClick={handlePaginationButtonClick}
								handlePaginationClick={handlePaginationClick}
								totalRecord={totalRecord}
							/>
						</TabContext>
					</Box>
				</Box>
			)}
		</>
	);
};
