import { useState, useEffect } from 'react';
import { List, Button, ListItem, TextField, IconButton, ListItemText, Box, InputAdornment } from '@material-ui/core';
import './dropdown.scss';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/community/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { ReactComponent as CAddIcon } from 'src/assets/images/icons/fightRecord/add-circle.svg';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// const SearchTextField = withStyles({
//     root: {
//         backgroundColor: '#202020',
//         '& label.Mui-focused': {
//             color: '#fff'
//         },
//         '& .MuiInput-underline:after': {
//             borderBottomColor: '#2E353A',
//             border: '1px solid #2E353A'
//         },
//         '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//                 borderColor: '#2E353A'
//             },
//             '&:hover fieldset': {
//                 borderColor: '#2E353A'
//             },
//             '&.Mui-focused fieldset': {
//                 border: '1px solid #2E353A'
//             }
//         }
//     }
// })(TextField);

export const CustomDropDown = ({ labelValue, dropdownArray, handleAdd }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [DDArray, setDDArray] = useState([]);

	const handleChange = (e) => {
		const { value } = e.target;
		const filtered = dropdownArray.filter((obj) => obj.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);

		setDDArray(filtered);
	};

	useEffect(() => {
		setDDArray(dropdownArray);
		setOpen(false);
	}, [dropdownArray]);

	const sortedArray = _.sortBy(DDArray, 'name');

	return (
		<Box className="position-relative">
			<Box className="btn-main">
				<Button
					disableElevation
					disableTouchRipple
					disableRipple
					disableFocusRipple
					variant="contained"
					endIcon={<ArrowDown width={25} height={12} className={open ? 'toggle-down' : 'toggle-up'} />}
					className="dropdown-label"
					onClick={() => setOpen(!open)}
				>
					{labelValue}
				</Button>
			</Box>

			{open && (
				<Box className="cd-dropdown-main">
					<Box className="">
						<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
							{/*SEARCH DROPDOWN */}
							<Box className="community-search-box">
								<Box className="search-field-box p-10 b-0">
									<TextField
										onChange={handleChange}
										fullWidth
										placeholder={t('search.title')}
										size="small"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Box>
							<Box className="dropdown-listing-main">
								{sortedArray.map((value, index) => {
									return (
										<>
											<ListItem key={index} disablePadding>
												<ListItemText primary={value?.name} />
												<IconButton onClick={() => handleAdd(value)}>
													<CAddIcon />
												</IconButton>
											</ListItem>
										</>
									);
								})}
							</Box>
						</List>
					</Box>
				</Box>
			)}
		</Box>
	);
};
