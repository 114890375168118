import { useState, useEffect, useContext } from 'react';
import { Box, Container, Grid, Typography, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';
import { UserCard } from './UserCard';
import './followers.scss';
import { Page } from 'src/components/ui';

export const Followers = ({ setFollowersData, nextPageFollowersData }) => {
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const [page, setPageValue] = useState(1);

	const { t } = useTranslation();
	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	const followersList = useSelector((state) => state?.Combat?.followersList);
	const followersPage = useSelector((state) => state?.Combat?.followersPage);

	const [loader, setLoader] = useState(false); //setLoader
	const [followersData, setFollowersList] = useState([]);

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const fetchFollowersData = async () => {
		// setLoader(true);
		// var pageToPadd = followersPage === undefined ? 1 : followersPage;
		try {
			let { status, data } = await axiosInstance.get(URLS.getFollowers + `&page=${followersPage}&limit=4`, headers);

			if (status === 200) {
				const newFollowersData = data?.followersUser;
				const hasMore = data?.followersUser?.length < 4 ? false : true;

				if (page === 1) {
					setFollowersData(data?.followersUser);
				} else {
					nextPageFollowersData(data?.followersUser);
				}

				setLoader(false);
				setFollowersList([followersData, ...newFollowersData]);
				setHasMoreItems(hasMore);
				setPageValue(2);
			}
		} catch (error) {
			setLoader(false);
		}
	};

	useEffect(() => {
		fetchFollowersData();
		// eslint-disable-next-line
	}, []);

	return (
		<Page title="Followers | Combat Matrix">
			<Container maxWidth="lg">
				<Box className="combat-container connection-page">
					<Typography className="connection-page-title">{t('myprofile.folowers')}</Typography>

					<Box className="connection-user-list">
						<Grid container spacing={3}>
							{loader
								? [{}, {}, {}, {}]?.map((data, index) => {
										return (
											<Grid item xs={12} sm={12} md={3} lg={3} key={index}>
												<Skeleton key={index} className="usercard-skeleton" />
											</Grid>
										);
								  })
								: followersList && followersList?.length > 0
								? followersList?.map((user, index) => {
										return (
											<Grid item xs={12} sm={12} md={3} lg={3} key={index}>
												<UserCard user={user?.authUser} fetchFollowersData={fetchFollowersData} />
											</Grid>
										);
								  })
								: ''}
						</Grid>
					</Box>
					{hasMoreItems && (
						<Grid container lg={12} className="mt-20">
							{followersList && followersList.length > 0 && (
								<Button
									variant="contained"
									className="visit-button m-auto"
									onClick={() => {
										fetchFollowersData();
									}}
								>
									{t('button_text.show_more')}
								</Button>
							)}
						</Grid>
					)}
				</Box>
			</Container>
		</Page>
	);
};
