import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ReactComponent as DefaultCheckedIcon } from 'src/assets/images/icons/defaultCheck.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import './profile_type.scss';
import { useTranslation } from 'react-i18next';

export const ProfileType = ({ profileTypes, signupOrgCheck, data, token, message }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [fanCheck, setFanCheck] = useState(
		data?.profile_type ? (data?.profile_type?.check?.includes(0) ? true : false) : true,
	);

	const [checked, setChecked] = useState(data?.profile_type?.check || [0]);

	const [setup_Org, setSetup_Org] = useState(data?.setup_org_check || false);

	const typeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	const handleToggle = (value) => () => {
		setFanCheck(false);
		let checkedVal = checked; //.filter(x => x !== 0)
		const currentIndex = checkedVal.indexOf(value);
		const newChecked = [...checkedVal];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const setupOrgHandler = (e) => {
		setSetup_Org(e.target.checked);
	};

	const backHandler = () => {
		navigate('/profile/details');
	};

	const nextHandler = () => {
		let totalIDS = checked?.length > 0 ? checked?.reduce((a, b) => a + b) : 0;

		profileTypes({ check: checked, total: totalIDS });
		// HIDE-MVP: CM-98
		// navigate(setup_Org ? '/profile/organisation' : '/profile/optional');
		navigate('/profile/confirm');
	};

	useEffect(() => {
		signupOrgCheck(setup_Org);
		// eslint-disable-next-line
	}, [setup_Org]);

	// useEffect(() => {
	//   if (checked?.length === 0) {
	//     setChecked([0]);
	//     setFanCheck(true);
	//   }
	//   // eslint-disable-next-line
	// }, [checked]);

	return (
		<Box className="profile-details-form profile-types-form">
			<Typography className="profile-details-title">{t('profile_type.title')}</Typography>

			<Box className="profile-type-checks">
				<Box className="default-fan-check">
					<List>
						<ListItem
							role={undefined}
							dense
							button
							// onClick={() => {
							//   setChecked([0]);
							//   setFanCheck(true);
							// }}
						>
							<ListItemIcon>
								<Checkbox
									icon={<CheckBoxIcon />}
									checkedIcon={<DefaultCheckedIcon />}
									edge="start"
									checked={true} //fanCheck
									tabIndex={-1}
									disableRipple
									disableTouchRipple
									disableFocusRipple
								/>
							</ListItemIcon>
							<ListItemText primary={t('profile_type.enthusiast')} secondary={t('profile_type.default_fan_check')} />
						</ListItem>
					</List>
				</Box>

				<Typography className="select-other-types">{t('profile_type.select_other_types')}</Typography>

				<List className="other-types-ul">
					{typeList.map((type, index) => {
						const labelId = `checkbox-list-label-${type?.id}`;

						return (
							<ListItem key={index} role={undefined} dense button onClick={handleToggle(type?.id)}>
								<ListItemIcon>
									<Checkbox
										icon={<CheckBoxIcon />}
										checkedIcon={<CheckedBoxIcon />}
										edge="start"
										checked={checked.indexOf(type?.id) !== -1}
										tabIndex={-1}
										disableRipple
										disableTouchRipple
										disableFocusRipple
										inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItemIcon>
								<ListItemText id={labelId} primary={type?.name} />
							</ListItem>
						);
					})}
				</List>
				{/* HIDE-MVP: CM-82 */}
				{/*<Box className="org-setup-check">*/}
				{/*	<List>*/}
				{/*		<ListItem role={undefined} dense button>*/}
				{/*			<ListItemIcon>*/}
				{/*				<Checkbox*/}
				{/*					icon={<CheckBoxIcon />}*/}
				{/*					checkedIcon={<CheckedBoxIcon />}*/}
				{/*					edge="start"*/}
				{/*					checked={setup_Org}*/}
				{/*					onChange={setupOrgHandler}*/}
				{/*					disableRipple*/}
				{/*					disableTouchRipple*/}
				{/*					disableFocusRipple*/}
				{/*				/>*/}
				{/*			</ListItemIcon>*/}
				{/*			<ListItemText primary={t('profile_type.do_you_want_setup_org')} />*/}
				{/*		</ListItem>*/}
				{/*	</List>*/}
				{/*</Box>*/}
			</Box>

			<Box display="flex" justifyContent="space-between" className="profile-type-actions">
				<Button variant="contained" className="profile-type-sbmtbtn" onClick={backHandler}>
					{t('back')}
				</Button>
				<Button variant="contained" className="profile-type-sbmtbtn" onClick={nextHandler}>
					{t('next')}
				</Button>
			</Box>
		</Box>
	);
};
