import { useEffect, useState } from 'react';
import { Box, InputAdornment, withStyles, Tab, TextField, Grid, Typography, Button } from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { eventTypeList } from 'src/helpers/constants/identifier';
import { EventCard, NewsfeedCommunity, UserProfileCard, CommunityItem, CommunityPost } from 'src/components/ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import TabContext from '@material-ui/lab/TabContext';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { OrgUser } from '../../Organization/YourOrganization/OrgUser';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../UserConnection/UserProfile';
import _ from 'lodash';
import { AllUser } from './AllUser';
import { AllEvent } from './AllEvent';
// import AllTab from './All';
import { AllCommunity } from './AllCommunity';
// import CommunitiesTab from './Communities';
// import OrganisationTab from './Organisation';
import { BPDTRMBlock } from './BPDTRMBlock';
import './search-view.scss';
import { useTranslation } from 'react-i18next';
import { Users } from './Users';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const SearchBox = () => {
	const { t } = useTranslation();

	// const location = useLocation();
	// const query = parse(location.search);
	const [userData, setUserData] = useState([]);
	const [searchVal, setSearchVal] = useState('');
	const [reportReasonList, setReportReasonList] = useState([]);
	const [queryString, setQueryString] = useState('');
	const [orderByStatus, setOrderByStatus] = useState('');
	const [userViewStatus, setUserViewStatus] = useState('');
	const [userHasMore, setUserHasMore] = useState(true);
	const [userPage, setUserPage] = useState(1);
	const [tab, setTab] = useState('1');
	const [list] = useState(9);
	const [reactionData, setReactionData] = useState([]);
	const navigate = useNavigate();

	const btnsList = [
		{ id: '1', name: t('search.all') },
		{ id: '2', name: t('search.users') },
		{ id: '3', name: t('search.post') },
		{ id: '4', name: t('search.communities') },
		{ id: '5', name: t('search.organisations') },
		{ id: '6', name: t('search.events') },
	];

	const auth = useSelector((state) => state?.Combat?.authData) || fetchFromStorage('authData');
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const user = auth ? auth : fetchFromStorage('authData');
	let ownerID = useSelector((state) => state?.Combat?.authData)?.user_id || fetchFromStorage('authData')?.user_id;

	const handleChange = (e) => {
		setSearchVal(e.target.value);
	};
	const tabValue =
		tab === '1'
			? 'all'
			: tab === '2'
			? 'users'
			: tab === '3'
			? 'post'
			: tab === '4'
			? 'community'
			: // HIDE-MVP: CM-79
			// : tab === '5'
			// ? 'organization'
			tab === '6'
			? 'event'
			: 'all';

	const fetchAllSearchData = async (tabName, queryString, orderby, viewstatus, pageVal) => {
		// var queryString = QS !== undefined && QS !== '' ? QS : query;
		// var orderBy = order ? order : 'best';
		// var viewstatus = viewStatus ? viewStatus : 'my';
		pageVal && setUserPage(1);
		var pageNumber = pageVal !== undefined && pageVal !== '' ? pageVal : userPage;

		setQueryString(queryString);
		setOrderByStatus(orderby);
		setUserViewStatus(viewstatus);

		try {
			const { status, data } = await axiosInstance.get(URLS.getAllSearchData, {
				params: {
					tab: tabName,
					...(searchVal !== '' && { name: searchVal }),
					page: pageNumber,
					limit: list,
					badges: queryString?.badges,
					discipline: queryString?.disciplines,
					weightunit: queryString?.weightUnit,
					weightmin: queryString?.weightmin,
					weightmax: queryString?.weightmax,
					profile_type: queryString?.profile_type,
					user_type: queryString?.user_type,
					...(orderby !== '' && { order_by: orderby }),
					...(viewstatus !== '' && { view_status: viewstatus }),
				},
			});

			if (status === 200) {
				const user_HasMore = data?.getUserData?.length < 9 ? false : true;

				setUserHasMore(user_HasMore);
				pageVal !== undefined && pageVal !== '' ? setUserPage(2) : setUserPage(userPage + 1);
				var userDataList = pageVal !== undefined && pageVal !== '' ? [] : userData;
				var mergedArray = userPage === 1 ? data?.getUserData : userDataList.concat(data?.getUserData);

				setUserData(mergedArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const updateUserData = async (tabName, uPage, singleData) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllSearchData, {
				params: {
					tab: tabName,
					id: singleData?.type === 'user' || singleData?.type === 'user_other' ? singleData?.user_id : singleData?.id,
					type: singleData?.type,
				},
			});

			if (status === 200) {
				// var userObj = data?.getUserData[0];
				// var membersArray = [...userData]
				// var index = _.findIndex(membersArray, { user_id: singleData?.user_id });
				// membersArray[index] = userObj;
				const keyTomatch = singleData?.type === 'user' || singleData?.type === 'user_other' ? 'user_id' : 'id';
				var userObj = data?.getUserData[0];
				var filteredData = userData?.map((person) =>
					person[keyTomatch] === singleData[keyTomatch] && person?.type === singleData?.type ? userObj : person,
				);

				setUserData(filteredData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const addVote = async (value, type, upage, val) => {
		var requestParams = {
			post_id: value?.id,
			vote_name: type,
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addVote, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				const tabDataValue =
					tab === '1'
						? 'all'
						: tab === '2'
						? 'users'
						: tab === '3'
						? 'post'
						: tab === '4'
						? 'community'
						: // HIDE-MVP: CM-79
						// : tab === '5'
						// ? 'organization'
						tab === '6'
						? 'events'
						: 'all';

				updateUserData(tabDataValue, upage, val);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// REACTION
	const addLike = async (value, type, upage, val) => {
		var requestParams = {
			post_id: value?.id,
			meta_like_id: type, // heart, thumbs_up, clap_hands, light_bulb
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addLike, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				const tabDataValue =
					tab === '1'
						? 'all'
						: tab === '2'
						? 'users'
						: tab === '3'
						? 'post'
						: tab === '4'
						? 'community'
						: // HIDE-MVP: CM-79
						// : tab === '5'
						// ? 'organization'
						tab === '6'
						? 'events'
						: 'all';

				updateUserData(tabDataValue, upage, val);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const communityAction = async (data, status, val) => {
		var requestParams = {
			user_id: auth?.user_id,
			community_id: data?.id,
			status: status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.joinCommunity, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				updateUserData(tabValue, userPage, val);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchReactions = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsLike);

			if (status === 200) {
				setReactionData(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
		setUserPage(1);
		const tabValue =
			newValue === '1'
				? 'all'
				: newValue === '2'
				? 'users'
				: newValue === '3'
				? 'post'
				: newValue === '4'
				? 'community'
				: // HIDE-MVP: CM-79
				// : newValue === '5'
				// ? 'organization'
				newValue === '6'
				? 'event'
				: 'all';

		var orderby = tabValue === 'post' || tabValue === 'community' ? 'best' : '';
		var viewstatus = tabValue === 'post' || tabValue === 'community' ? 'all' : '';

		setOrderByStatus(orderby);
		fetchAllSearchData(tabValue, '', orderby, viewstatus, 1);
		setQueryString('');
	};

	useEffect(() => {
		fetchAllSearchData('all', '', orderByStatus, userViewStatus);
	}, []);
	useEffect(() => {
		fetchReactions();
		fetchReportReasonList();
	}, []);

	return (
		<>
			<Box className="search-box">
				<Box className="search-field-box">
					<SearchTextField
						onChange={handleChange}
						fullWidth
						value={searchVal}
						placeholder={t('search.title')}
						size="small"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Button
						type="submit"
						className="search-btn"
						onClick={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus, 1)}
					>
						{t('search_btn')}
					</Button>
				</Box>
			</Box>
			<TabContext value={String(tab)}>
				<Box className="select-search-tab">
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler}>
						{btnsList?.map((data, index) => {
							return (
								<Tab
									key={index}
									label={data?.name}
									value={data?.id}
									disableRipple
									disableTouchRipple
									disableFocusRipple
								/>
							);
						})}
					</TabList>
				</Box>
				<TabPanel value="1" className="tabpanel-sec">
					{/* <AllTab
            token={token} /> */}
					<Typography className="result-text">{t('community.results')}</Typography>

					<Box
						id="scrollableDiv"
						style={{
							height: '58vh',
							overflow: 'auto',
							display: 'flex',
							width: '100%',
						}}
						// className='infinity-scroll-view'
						className="infinity-scroll-view-custom"
					>
						{/* {loader ? (
              [{}, {}, {}]?.map((data, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Skeleton key={index} className="usercard-skeleton" />
                  </Grid>
                );
              })
            ) : */}
						{userData && userData.length > 0 ? (
							<InfiniteScroll
								dataLength={userData?.length}
								next={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus, '')}
								style={{ width: '100%' }} //To put endMessage and loader to the top.
								inverse={false}
								hasMore={userHasMore}
								loader={<h4>{t('loading')}...</h4>}
								scrollableTarget="scrollableDiv"
							>
								<Grid container spacing={1}>
									{userData.map((item, index) => {
										const postContent = item?.type === 'post' && item?.PostLists?.find((d) => d?.Item?.type === 'text');
										var postImage = item?.type === 'post' && item?.PostLists?.filter((d) => d?.Item?.type === 'image');
										var postVideos = item?.PostLists?.filter((d) => d?.Item?.type === 'video');
										var postAudio = item?.PostLists?.filter((d) => d?.Item?.type === 'audio');
										var postDoc = item?.PostLists?.filter((d) => d?.Item?.type === 'doc');

										return item?.type === 'user' ? (
											<Grid item xs={12}>
												<AllUser
													user={item}
													ownerID={ownerID}
													fetchAllSearchData={fetchAllSearchData}
													token={token}
													updateUserData={updateUserData}
													userPage={userPage}
												/>
											</Grid>
										) : item?.type === 'post' ? (
											<>
												{' '}
												{item?.receiver_type === 'user' ? (
													<Grid item xs={12}>
														<NewsfeedCommunity
															postedBy={item?.user_full_name}
															time={
																item?.timestamp_created !== null &&
																moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
															}
															activityType={item?.receiver_type === 'community' ? item?.receiver_type : ''}
															communityName={item?.receiver_type === 'community' ? item?.community_name : ''}
															topVal={item?.total_vote_up}
															downVal={item?.total_vote_down}
															comment={item?.total_comments}
															referrals={item?.total_referrals}
															postProfile={item?.user_profile_data}
															addVote={addVote}
															addLike={addLike}
															reactions={item?.total_likes}
															isJoin={item?.join_status}
															editAccess={item?.edit_access}
															data={item}
															userURI={item?.user_meta?.data}
															// fetchNewsfeedPostList={fetchNewsfeedPostList}
															// currentPage={currentPage}
															isSearchPage={true}
															fetchAllSearchData={fetchAllSearchData}
															tab={tab}
															// view={view}
															user={user}
															shares={item?.total_shares}
															reactionData={reactionData}
															likedStatus={item?.like_status}
															userPage={userPage}
															updateUserData={updateUserData}
															tabmain="all"
															singleItem={item}
															verifyUser={item?.veritication_status === 'yes' ? true : false}
														/>
													</Grid>
												) : (
													<Grid item xs={12}>
														<CommunityPost
															linkList={postContent?.Item?.links_list}
															postName={item?.name}
															user={user}
															topVal={item?.total_vote_up}
															downVal={item?.total_vote_down}
															contentText={postContent?.Item?.data}
															contentImg={postImage}
															time={
																item?.timestamp_created !== null &&
																moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
															}
															postedBy={
																item?.receiver_type === 'organisation' ? item?.organisation_name : item?.user_full_name
															}
															communityPost={item?.receiver_type === 'post' ? true : false}
															communityName={
																item?.receiver_type === 'community'
																	? item?.community_name
																	: item?.receiver_type === 'organisation'
																	? item?.user_full_name
																	: ''
															}
															activityType={
																item?.receiver_type === 'community'
																	? item?.receiver_type
																	: item?.receiver_type === 'organisation'
																	? 'Posted By'
																	: ''
															}
															communityID={
																item?.receiver_type === 'community' || item?.receiver_type === 'organisation'
																	? item?.receiver_id
																	: ''
															}
															postProfile={
																item?.receiver_type === 'organisation'
																	? item?.organisation_profile_data
																	: item?.user_profile_data
															}
															comment={item?.total_comments}
															badges={item?.total_badges}
															receiver_type={item?.receiver_type}
															shares={item?.total_shares}
															referrals={item?.total_referrals}
															userURI={item?.user_meta?.data}
															reactions={item?.total_likes}
															editAccess={item?.edit_access}
															postUserId={item?.user_id}
															data={item}
															addVote={addVote}
															addLike={addLike}
															isJoin={item?.join_status}
															PostId={item?.user_id}
															reactionData={reactionData}
															closeCommunity={item?.status === 'closed' ? true : false}
															fetchAllSearchData={fetchAllSearchData}
															likedStatus={item?.like_status}
															userPage={userPage}
															matchBtnCondition="no"
															postVideo={postVideos}
															postAudio={postAudio}
															postDoc={postDoc}
															singleItem={item}
															verifyUser={item?.veritication_status === 'yes' ? true : false}
														/>
													</Grid>
												)}
											</>
										) : item?.type === 'community' ? (
											<Grid item xs={12}>
												<AllCommunity
													name={item?.name}
													communityimage={item?.item_profile_data}
													createdAt={moment(item?.timestamp_created).format('MMM DD YYYY, h:mm')}
													data={item}
													fetchAllSearchData={fetchAllSearchData}
													user={user}
													updateUserData={updateUserData}
													userPage={userPage}
													singleItem={item}
												/>
											</Grid>
										) : // HIDE-MVP: CM-79
										// item?.type === 'organization' ? (
										// <Grid item xs={12}>
										// 	<AllOrg
										// 		profile={item?.item_profile_id}
										// 		status={item?.status}
										// 		name={item?.name}
										// 		// orgType={item?.org_type_name}
										// 		// aboutData={about?.Item?.data}
										// 		// address={item?.address}
										// 		orgData={item}
										// 		orgId={item?.id}
										// 		editBtn={false}
										// 		navigate={navigate}
										// 	/>
										// </Grid>
										// ) :
										item?.type === 'event' ? (
											<Grid item xs={12}>
												<AllEvent
													profile={item?.event_profile_data}
													startTime={item?.event_date ? item?.event_date[0]?.start_time : ''}
													name={item?.name}
													// orgType={item?.org_type_name}
													// aboutData={about?.Item?.data}
													// address={item?.address}
													orgData={item}
													eventId={item?.id}
													// editBtn={false}
													navigate={navigate}
													eventPostId={item?.post_id}
												/>
											</Grid>
										) : (
											<></>
										);
									})}
								</Grid>
							</InfiniteScroll>
						) : (
							<Typography className="no-data-text">No Data Found</Typography>
						)}
					</Box>
				</TabPanel>
				<TabPanel value="2" className="tabpanel-sec">
					<Users token={token} fetchAllSearchData={fetchAllSearchData} />
					<Typography className="result-text">{t('community.results')}</Typography>
					<Box
						id="scrollableDiv"
						style={{
							height: '58vh',
							overflow: 'auto',
							display: 'flex',
							width: '100%',
						}}
						// className='infinity-scroll-view'
						className="infinity-scroll-view-custom"
					>
						{/* {loader ? (
              [{}, {}, {}]?.map((data, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Skeleton key={index} className="usercard-skeleton" />
                  </Grid>
                );
              })
            ) : */}
						{userData && userData.length > 0 ? (
							<InfiniteScroll
								dataLength={userData?.length}
								next={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus)}
								style={{ width: '100%' }} //To put endMessage and loader to the top.
								inverse={false}
								hasMore={userHasMore}
								loader={<h4>{t('loading')}...</h4>}
								scrollableTarget="scrollableDiv"
							>
								<Grid container spacing={1}>
									{userData && userData.length > 0 ? (
										userData.map((item, index) => {
											return (
												<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
													{item?.type === 'user_other' ? (
														<UserProfile
															user={item}
															ownerID={ownerID}
															updateUserData={updateUserData}
															userPage={userPage}
															isSearchPage={true}
															// currentPage={currentPage}
														/>
													) : (
														<UserProfileCard
															user={item}
															isSearchPage={true}
															updateUserData={updateUserData}
															userPage={userPage}
															// fetchConnectionData={fetchConnectionData}
															// fetchFollowingData={fetchFollowingData}
															// fetchPendingConnectionUser={
															//   fetchPendingConnectionUser
															// }
															// fetchFollowersData={fetchFollowersData}
															// tab={tab}
															// fetchUsers={fetchUsers}
															// ownerID={ownerID}
															// currentPage={currentPage}
															// fetchReferralData={fetchReferralData}
														/>
													)}
												</Grid>
											);
										})
									) : (
										<Typography className="no-data-text">No Data Found</Typography>
									)}
								</Grid>
							</InfiniteScroll>
						) : (
							<Typography className="no-data-text">No Data Found</Typography>
						)}
					</Box>
				</TabPanel>
				<TabPanel value="3" className="tabpanel-sec">
					<BPDTRMBlock fetchAllSearchData={fetchAllSearchData} tabValue="post" />
					<Typography className="result-text">{t('community.results')}</Typography>
					<Box
						id="scrollableDiv"
						style={{
							height: '58vh',
							overflow: 'auto',
							display: 'flex',
							width: '100%',
						}}
						// className='infinity-scroll-view'
						className="infinity-scroll-view-custom"
					>
						{/* {loader ? (
              [{}, {}, {}]?.map((data, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Skeleton key={index} className="usercard-skeleton" />
                  </Grid>
                );
              })
            ) : */}
						{userData && userData.length > 0 ? (
							<InfiniteScroll
								dataLength={userData?.length}
								next={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus, '')}
								style={{ width: '100%' }} //To put endMessage and loader to the top.
								inverse={false}
								hasMore={userHasMore}
								loader={<h4>{t('loading')}...</h4>}
								scrollableTarget="scrollableDiv"
							>
								{userData && userData.length > 0 ? (
									userData.map((item, index) => {
										const postContent = item?.PostLists?.find((d) => d?.Item?.type === 'text');
										var postImage = item?.PostLists?.filter((d) => d?.Item?.type === 'image');
										var postVideos = item?.PostLists?.filter((d) => d?.Item?.type === 'video');
										var postAudio = item?.PostLists?.filter((d) => d?.Item?.type === 'audio');
										var postDoc = item?.PostLists?.filter((d) => d?.Item?.type === 'doc');

										return item?.receiver_type === 'user' ? (
											<>
												<NewsfeedCommunity
													postedBy={item?.user_full_name}
													time={
														item?.timestamp_created !== null &&
														moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
													}
													activityType={item?.receiver_type === 'community' ? item?.receiver_type : ''}
													communityName={item?.receiver_type === 'community' ? item?.community_name : ''}
													topVal={item?.total_vote_up}
													downVal={item?.total_vote_down}
													comment={item?.total_comments}
													referrals={item?.total_referrals}
													postProfile={item?.user_profile_data}
													addVote={addVote}
													reactions={item?.total_likes}
													isJoin={item?.join_status}
													editAccess={item?.edit_access}
													data={item}
													userURI={item?.user_meta?.data}
													// fetchNewsfeedPostList={fetchNewsfeedPostList}
													// currentPage={currentPage}
													addLike={addLike}
													isSearchPage={true}
													fetchAllSearchData={fetchAllSearchData}
													tab={tab}
													// view={view}
													user={user}
													updateUserData={updateUserData}
													shares={item?.total_shares}
													reactionData={reactionData}
													likedStatus={item?.like_status}
													userPage={userPage}
													tabmain="post"
													singleItem={item}
													verifyUser={item?.veritication_status === 'yes' ? true : false}
												/>
											</>
										) : (
											<>
												<CommunityPost
													linkList={postContent?.Item?.links_list}
													postName={item?.name}
													user={user}
													topVal={item?.total_vote_up}
													downVal={item?.total_vote_down}
													contentText={postContent?.Item?.data}
													contentImg={postImage}
													time={
														item?.timestamp_created !== null &&
														moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
													}
													postedBy={
														item?.receiver_type === 'organisation' ? item?.organisation_name : item?.user_full_name
													}
													communityPost={item?.receiver_type === 'post' ? true : false}
													communityName={
														item?.receiver_type === 'community'
															? item?.community_name
															: item?.receiver_type === 'organisation'
															? item?.user_full_name
															: ''
													}
													activityType={
														item?.receiver_type === 'community'
															? item?.receiver_type
															: item?.receiver_type === 'organisation'
															? 'Posted By'
															: ''
													}
													communityID={
														item?.receiver_type === 'community' || item?.receiver_type === 'organisation'
															? item?.receiver_id
															: ''
													}
													postProfile={
														item?.receiver_type === 'organisation'
															? item?.organisation_profile_data
															: item?.user_profile_data
													}
													receiver_type={item?.receiver_type}
													comment={item?.total_comments}
													badges={item?.total_badges}
													shares={item?.total_shares}
													referrals={item?.total_referrals}
													userURI={item?.user_meta?.data}
													reactions={item?.total_likes}
													editAccess={item?.edit_access}
													postUserId={item?.user_id}
													data={item}
													addVote={addVote}
													addLike={addLike}
													isJoin={item?.join_status}
													PostId={item?.user_id}
													reactionData={reactionData}
													likedStatus={item?.like_status}
													fetchAllSearchData={fetchAllSearchData}
													closeCommunity={item?.status === 'closed' ? true : false}
													userPage={userPage}
													matchBtnCondition="no"
													postVideo={postVideos}
													postAudio={postAudio}
													postDoc={postDoc}
													singleItem={item}
													verifyUser={item?.veritication_status === 'yes' ? true : false}
												/>
											</>
										);
									})
								) : (
									<Typography className="no-data-text">No Data Found</Typography>
								)}
							</InfiniteScroll>
						) : (
							<Typography className="no-data-text">No Data Found</Typography>
						)}
					</Box>
				</TabPanel>
				<TabPanel value="4" className="tabpanel-sec">
					{/* <CommunitiesTab
          /> */}
					<BPDTRMBlock fetchAllSearchData={fetchAllSearchData} tabValue="community" />
					<Typography className="result-text">{t('community.results')}</Typography>
					<Box
						id="scrollableDiv"
						style={{
							height: '58vh',
							overflow: 'auto',
							display: 'flex',
							width: '100%',
						}}
						// className='infinity-scroll-view'
						className="infinity-scroll-view-custom"
					>
						{userData && userData.length > 0 ? (
							<InfiniteScroll
								dataLength={userData?.length}
								next={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus)}
								style={{ width: '100%' }} //To put endMessage and loader to the top.
								inverse={false}
								hasMore={userHasMore}
								loader={<h4>{t('loading')}...</h4>}
								scrollableTarget="scrollableDiv"
							>
								<Grid container spacing={3} className="community-list">
									{userData && userData.length > 0 ? (
										userData.map((data, index) => {
											var post_image = data?.post_list?.PostLists.find((item) => item?.Item?.type === 'image');
											var post_content = data?.post_list?.PostLists.find((item) => item?.Item?.type === 'text');

											return (
												<Grid item xs={12} key={index}>
													<CommunityItem
														reportReasonList={reportReasonList}
														key={index}
														name={data?.name}
														communityimage={data?.item_profile_data}
														createdAt={moment(data?.timestamp_created).format('MMM DD YYYY, h:mm')}
														members={data?.total_member}
														communityFor={data?.user_type_value}
														postedBy={data?.post_list?.user_full_name}
														userProfile={data?.post_list?.user_meta?.data}
														postTime={
															data?.post_list?.timestamp_created !== null &&
															moment.utc(data?.post_list?.timestamp_created).fromNow().replace('in ', '')
														}
														// postImg={post_image?.Item?.data}
														postImg={data?.post_list?.item_profile_id}
														postText={post_content?.Item?.data}
														isPostAvail={data?.post_list !== null ? true : false}
														data={data}
														communityAction={communityAction}
														showButton={data?.user_owner_id !== fetchFromStorage('authData')?.user_id ? true : false}
														singleItem={data}
													/>
												</Grid>
											);
										})
									) : (
										<Typography className="no-data-text">No Data Found</Typography>
									)}
								</Grid>
							</InfiniteScroll>
						) : (
							<Typography className="no-data-text">No Data Found</Typography>
						)}
					</Box>
				</TabPanel>
				<TabPanel value="5" className="tabpanel-sec">
					{/* <OrganisationTab
          /> */}
					<Typography className="result-text">{t('community.results')}</Typography>
					<Box
						id="scrollableDiv"
						style={{
							height: '58vh',
							overflow: 'auto',
							display: 'flex',
							width: '100%',
						}}
						// className='infinity-scroll-view'
						className="infinity-scroll-view-custom"
					>
						{/* {loader ? (
              [{}, {}, {}]?.map((data, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Skeleton key={index} className="usercard-skeleton" />
                  </Grid>
                );
              })
            ) : */}
						{userData && userData.length > 0 ? (
							<InfiniteScroll
								dataLength={userData?.length}
								next={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus)}
								style={{ width: '100%' }} //To put endMessage and loader to the top.
								inverse={false}
								hasMore={userHasMore}
								loader={<h4>{t('loading')}...</h4>}
								scrollableTarget="scrollableDiv"
							>
								<Grid container spacing={1}>
									{userData && userData.length > 0 ? (
										userData.map((org, index) => {
											const about = org?.PostLists?.find((data) => data?.Item?.type === 'text');

											return (
												<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
													<OrgUser
														profile={org?.item_profile_id}
														status={org?.status}
														name={org?.name}
														orgType={org?.org_type_name}
														aboutData={about?.Item?.data}
														address={org?.address}
														orgData={org}
														orgId={org?.id}
														editBtn={false}
													/>
												</Grid>
											);
										})
									) : (
										<Typography className="no-data-text">No Data Found</Typography>
									)}
								</Grid>
							</InfiniteScroll>
						) : (
							<Typography className="no-data-text">No Data Found</Typography>
						)}
					</Box>
				</TabPanel>
				<TabPanel value="6" className="tabpanel-sec">
					<Typography className="result-text">{t('community.results')}</Typography>
					<Box
						id="scrollableDiv"
						style={{
							height: '58vh',
							overflow: 'auto',
							display: 'flex',
							width: '100%',
						}}
						// className='infinity-scroll-view'
						className="infinity-scroll-view-custom"
					>
						{/* {loader ? (
              [{}, {}, {}]?.map((data, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Skeleton key={index} className="usercard-skeleton" />
                  </Grid>
                );
              })
            ) : */}
						{userData && userData.length > 0 ? (
							<InfiniteScroll
								dataLength={userData?.length}
								next={() => fetchAllSearchData(tabValue, queryString, orderByStatus, userViewStatus)}
								style={{ width: '100%' }} //To put endMessage and loader to the top.
								inverse={false}
								hasMore={userHasMore}
								loader={<h4>{t('loading')}...</h4>}
								scrollableTarget="scrollableDiv"
							>
								<Grid container spacing={1}>
									{userData && userData.length > 0 ? (
										userData.map((event, index) => {
											return (
												<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
													<EventCard
														eventProfile={event?.event_profile_data}
														eventType={event?.event_type}
														eventName={event?.name}
														startTime={event?.event_date ? event?.event_date[0]?.start_time : ''}
														eventId={event?.id}
														// eventOwner={true}
														isSearch={true}
														eventTypeList={eventTypeList}
														locationType={event?.location_type}
														eventPostId={event?.post_id}
													/>
												</Grid>
											);
										})
									) : (
										<Typography className="no-data-text">No Data Found</Typography>
									)}
								</Grid>
							</InfiniteScroll>
						) : (
							<Typography className="no-data-text">No Data Found</Typography>
						)}
					</Box>
				</TabPanel>
			</TabContext>
		</>
	);
};
