import { getAllMessageList } from 'src/redux-state/actions/actions';
import { connect } from 'react-redux';
import { MessagesPage as MessagesPageComponent } from './MessagesPage';

export * from './MessagesPage';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllMessageList: (data) => dispatch(getAllMessageList(data)),
	};
};

export const MessagesPage = connect(mapStateToProps, mapDispatchToProps)(MessagesPageComponent);
