const isEqual = (x, y) => {
	const typeOfX = typeof x;
	const typeOfY = typeof y;

	return x && y && typeOfX === 'object' && typeOfX === typeOfY
		? Object.keys(x).length === Object.keys(y).length && Object.keys(x).every((key) => isEqual(x[key], y[key]))
		: x === y;
};

export default isEqual;
