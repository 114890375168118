import { connect } from 'react-redux';
import {
	additionDetails,
	profileConfirm,
	profileDetails,
	profileTypes,
	signupOrg,
	signupOrgCheck,
} from 'src/redux-state/actions/signupProfile';
import { authUser } from 'src/redux-state/actions/actions';
import { Profile as ProfileComponent } from './Profile';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		profileDetails: (data) => dispatch(profileDetails(data)),
		profileTypes: (data) => dispatch(profileTypes(data)),
		signupOrgCheck: (data) => dispatch(signupOrgCheck(data)),
		setUpOrg: (data) => dispatch(signupOrg(data)),
		profileConfirm: (data) => dispatch(profileConfirm(data)),
		additionDetails: (data) => dispatch(additionDetails(data)),
		authUser: (data) => dispatch(authUser(data)),
	};
};

export const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
