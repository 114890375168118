import { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Radio,
	RadioGroup,
	FormControlLabel,
	TextField,
	withStyles,
	Button,
	Collapse,
	IconButton,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RadioIcon } from 'src/assets/images/icons/radiobox.svg';
import { ReactComponent as RadioCheckedIcon } from 'src/assets/images/icons/radiochecked.svg';
import { ReactComponent as CopyIcon } from 'src/assets/images/icons/white-copy.svg';
import { ReactComponent as DownIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as UpIcon } from 'src/assets/images/icons/up-icon.svg';
import { ReactComponent as WhatsappIcon } from 'src/assets/images/icons/share/whatsapp-icon.svg';
import { ReactComponent as PinterestIcon } from 'src/assets/images/icons/share/pinterest-icon.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/share/twitter-icon.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/images/icons/share/facebook-icon.svg';
import { ReactComponent as LinkdInIcon } from 'src/assets/images/icons/share/linkedin-icon.svg';
import {
	FacebookShareButton,
	PinterestShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
} from 'react-share';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import { Config } from 'src/helpers/context/config';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import './share.scss';
import { useTranslation } from 'react-i18next';
import * as rdd from 'react-device-detect';

const SearchTextField = withStyles({
	root: {
		width: '100%',
		marginRight: '10px',
		backgroundColor: '#1B1B1B',
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const Share = ({ fromWhere, urlId, landType, handleClose, fetchPostDetails }) => {
	const { t } = useTranslation();

	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;
	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	const [activeCodeList, setActiveCodeList] = useState([]);
	const [radioValue, setRadioValue] = useState('');
	const [shareCodeUrl, setShareCodeUrl] = useState('');
	const [isShow, setIsShow] = useState(false);

	const fetchActiveReferralCode = async () => {
		try {
			let { status, data } = await axiosInstance.get(
				URLS.getReferralCodes,
				{
					params: {
						status: identifiers?.active,
					},
				},
				headers,
			);

			if (status === 200) {
				setActiveCodeList(data?.getReferralCode);
				var defaultVal = data?.getReferralCode.find((item) => item?.default === 'yes');
				var urlValue = `${Config?.DomainName}${fromWhere}${urlId}?referralcode=${
					defaultVal?.code ? defaultVal?.code : data?.getReferralCode[0]?.code
				}`;

				setRadioValue(defaultVal?.code);
				setShareCodeUrl(urlValue);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const shareLink = async () => {
		var requestParams = {
			referral_code: radioValue,
			land_type: landType,
			land_id: urlId,
			user_id: fetchFromStorage('authData')?.user_id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.shareLink, requestParams);

			if (status === 200) {
				fetchPostDetails(urlId);
				// setApiMessage('success', data?.message);
			}
		} catch (error) {
			// setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchActiveReferralCode();
	}, []);
	const handleChange = (value) => {
		var urlValue = `${Config?.DomainName}${fromWhere}${urlId}?referralcode=${value}`;

		setRadioValue(value);
		setShareCodeUrl(urlValue);
	};
	const handleCopied = (item) => {
		try {
			if (rdd.isAndroid && emitterObject) {
				emitterObject.postMessage(item);
			} else {
				navigator.clipboard.writeText(item);
			}
		} catch (e) {
			console.log(e);
		}

		setApiMessage('success', t('referral.copied'));
	};
	const onCopied = () => {
		handleCopied(shareCodeUrl);
		shareLink();
	};

	return (
		<Box className="share-main">
			<Typography className="head-main">{t('referral.share_heading')}</Typography>
			{/* HIDE-MVP: CM-73 */}
			{/*<Typography className="sub-head">{t('referral.your_ref_code')}</Typography>*/}
			{/*<Box display="flex" justifyContent="flex-end">*/}
			{/*	{!isShow ? (*/}
			{/*		<DownIcon onClick={() => setIsShow(!isShow)} cursor="pointer" />*/}
			{/*	) : (*/}
			{/*		<UpIcon onClick={() => setIsShow(!isShow)} cursor="pointer" />*/}
			{/*	)}*/}
			{/*</Box>*/}
			{/*<Typography className="sub-content">*/}
			{/*	<Typography variant="span" onClick={() => handleClose()}>*/}
			{/*		{t('referral.select_ref_code')}*/}
			{/*		<NavLink to="/referral-code" className="manage-code-link">*/}
			{/*			{t('referral.manage_code')}*/}
			{/*		</NavLink>*/}
			{/*	</Typography>*/}
			{/*</Typography>*/}

			<Box className="codes-table">
				{/* HIDE-MVP: CM-73 */}
				{/*<>*/}
				{/*	<Collapse in={isShow}>*/}
				{/*		<TableContainer>*/}
				{/*			<Table>*/}
				{/*				<TableHead>*/}
				{/*					<TableRow>*/}
				{/*						<TableCell>{t('referral.referral_name')}</TableCell>*/}
				{/*						<TableCell>{t('referral.share_code')}</TableCell>*/}
				{/*					</TableRow>*/}
				{/*				</TableHead>*/}
				{/*				{activeCodeList && activeCodeList.length > 0 ? (*/}
				{/*					<>*/}
				{/*						<TableBody>*/}
				{/*							{activeCodeList.map((item, index) => {*/}
				{/*								return (*/}
				{/*									<TableRow key={index}>*/}
				{/*										<TableCell>*/}
				{/*											<RadioGroup*/}
				{/*												aria-label="code"*/}
				{/*												name="code"*/}
				{/*												value={radioValue}*/}
				{/*												onChange={(e) => handleChange(e.target.value)}*/}
				{/*											>*/}
				{/*												<FormControlLabel*/}
				{/*													label={item?.label}*/}
				{/*													value={item?.code}*/}
				{/*													control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}*/}
				{/*												/>*/}
				{/*											</RadioGroup>*/}
				{/*										</TableCell>*/}
				{/*										<TableCell>{item?.code}</TableCell>*/}
				{/*									</TableRow>*/}
				{/*								);*/}
				{/*							})}*/}
				{/*						</TableBody>*/}
				{/*					</>*/}
				{/*				) : (*/}
				{/*					<TableRow>*/}
				{/*						<TableCell colSpan={4} className="no-data-txt">*/}
				{/*							{t('referral.no_referral_txt')}*/}
				{/*						</TableCell>*/}
				{/*					</TableRow>*/}
				{/*				)}*/}
				{/*			</Table>*/}
				{/*		</TableContainer>*/}
				{/*	</Collapse>*/}
				{/*</>*/}
				<Box className="share-input-block">
					<SearchTextField
						className="matrix-textfield"
						size="small"
						name="firstName"
						onChange={(e) => setShareCodeUrl(e.target.value)}
						variant="outlined"
						value={shareCodeUrl}
					/>

					<Button className="copy-button" variant="contained" onClick={() => (shareCodeUrl ? onCopied() : '')}>
						<CopyIcon />
						{t('referral.copy')}
					</Button>
				</Box>
				<Box className="social-icon-block">
					<Box className="social-icon fb">
						<IconButton onClick={() => shareLink()}>
							<FacebookShareButton
								url={shareCodeUrl}
								// quote={funraise_Details?.Description}
								// hashtag={'#' + postCaption}
								className="socialMediaIcons"
							>
								<FacebookIcon />
							</FacebookShareButton>
						</IconButton>
					</Box>
					<Box className="social-icon lin">
						<IconButton onClick={() => shareLink()}>
							<LinkedinShareButton
								url={shareCodeUrl}
								// quote={funraise_Details?.Description}
								// hashtag={'#' + postCaption}
								className="socialMediaIcons"
							>
								<LinkdInIcon />
							</LinkedinShareButton>
						</IconButton>
					</Box>
					<Box className="social-icon tw">
						<IconButton onClick={() => shareLink()}>
							<TwitterShareButton
								url={shareCodeUrl}
								// quote={funraise_Details?.Description}
								// hashtag={'#' + postCaption}
								className="socialMediaIcons"
							>
								<TwitterIcon />
							</TwitterShareButton>
						</IconButton>
					</Box>
					<Box className="social-icon pin">
						<IconButton onClick={() => shareLink()}>
							<PinterestShareButton
								// url={shareCodeUrl}
								url={`https://pinterest.com/pin/create/button/?url=${shareCodeUrl}}&media=&description=`}
								media={shareCodeUrl}
								// quote={funraise_Details?.Description}
								// hashtag={'#' + postCaption}
								className="socialMediaIcons"
							>
								<PinterestIcon />
							</PinterestShareButton>
						</IconButton>
					</Box>
					<Box className="social-icon wt">
						<IconButton onClick={() => shareLink()}>
							<WhatsappShareButton
								url={shareCodeUrl}
								// quote={funraise_Details?.Description}
								// hashtag={'#' + postCaption}
								className="socialMediaIcons"
							>
								<WhatsappIcon />
							</WhatsappShareButton>
						</IconButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
