import { eventAdmin, eventStatus } from '../../../../../../redux-state/actions/createEvent';
import { connect } from 'react-redux';

import { EventAdmins as EventAdminsComponent } from './EventAdmins';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		eventAdmin: (data) => dispatch(eventAdmin(data)),
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const EventAdmins = connect(mapStateToProps, mapDispatchToProps)(EventAdminsComponent);
