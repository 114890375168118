import { Box, Grid, Avatar, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './left.scss';

export const Left = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Avatar variant="square" className="left-img-sec"></Avatar>
			<Box className="left-text-sec">
				<Grid container className="text-line-sec">
					<Grid xs={6} className="text-heading-sec">
						{t('combat_fight_record.event_name')}
					</Grid>
					<Grid xs={6} className="text-data-sec">
						Full Metal Dojo 15: Come Out to Play
					</Grid>
				</Grid>

				<Grid container className="text-line-sec">
					<Grid xs={6} className="text-heading-sec">
						{t('combat_fight_record.start_time')}
					</Grid>
					<Grid xs={6} className="text-data-sec">
						4 NOV 2017, 3:00 AM ET
					</Grid>
				</Grid>

				<Grid container className="text-line-sec">
					<Grid xs={6} className="text-heading-sec">
						{t('combat_fight_record.end_time')}
					</Grid>
					<Grid xs={6} className="text-data-sec">
						4 NOV 2017, 5:00 AM ET
					</Grid>
				</Grid>

				<Grid container className="text-line-sec">
					<Grid xs={6} className="text-heading-sec">
						{t('combat_fight_record.location')}
					</Grid>
					<Grid xs={6} className="text-data-sec">
						Insanity Night Club, Bangkok Thailand
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
