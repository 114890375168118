import React from 'react';

import InlineChromiumBugfix from '../InlineChromiumBugfix';

export const LinkComponent = ({ attributes, children, element }) => {
	return (
		<a {...attributes} href={element.target} target={element.newWindow ? '_blank' : '_self'} rel={'noreferrer'}>
			<InlineChromiumBugfix />
			{children}
			<InlineChromiumBugfix />
		</a>
	);
};
