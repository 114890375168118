export const notificationTypes = {
	account_verified: 'account_verified',
	//user
	follow_user: 'follow_user',
	friend_request: 'friend_request',
	accept_request: 'accept_request',
	reject_request: 'reject_request',
	block_request: 'block_request',
	//post
	post_reaction: 'post_reaction',
	post_delete: 'post_delete',
	post_comment: 'post_comment',
	post_vote: 'post_vote',
	post_report: 'post_report',
	mention_user: 'mention_user',
	//community
	community_assign_role: 'community_assign_role',
	mention_community: 'mention_community',
	community_connect: 'community_connect',
	invite_community: 'invite_community',
	transfer_owner: 'transfer_owner',
	//organisation
	mention_organisation: 'mention_organisation',
	invite_organisation: 'invite_organisation',
	follow_organization: 'follow_organization',
	unblock_organisation: 'unblock_organisation',
	block_organisation: 'block_organisation',
	organisation_assign_role: 'organisation_assign_role',
	//chat
	chat_message: 'chat_message',
	//event
	event_invitation: 'event_invitation',
	event_reaction: 'event_reaction',
	event_vote: 'event_vote',
	event_fight_reaction: 'event_fight_reaction',
	event_fight_vote: 'event_fight_vote',
	event_admin: 'event_admin',
	event_comment: 'event_comment',
	join_rsvp: 'join_rsvp',
};
