import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	withStyles,
	Grid,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { ReactComponent as ToggleIcon } from 'src/assets/images/icons/toggleIcon.svg';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { ReactComponent as ActiveIcon } from 'src/assets/images/icons/active_icon.svg';
import { ReactComponent as CompleteIcon } from 'src/assets/images/icons/complete_icon.svg';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as BackArrowicon } from 'src/assets/images/icons/events/back-arrow.svg';

export const CardLeftSide = ({
	create_data,
	currentCreateEventTab,
	createEvents,
	currentSuperEventPage,
	eventFightCard,
	currentFightTab,
	cardCategory,
}) => {
	const { t } = useTranslation();

	const [step, setStep] = useState(0);
	const [cardBtn, setCardBtn] = useState(1);
	const [fightStep, setFightStep] = useState(0);
	const [fightData, setFightData] = useState([
		{
			id: 0,
		},
	]);
	let {
		event_details,
		event_type,
		event_fight_card,
		current_fight_tab,
		event_ticket,
		event_admin,
		event_invite,
		category,
		fight_details,
		event_confirm,
		card_category_data,
		current_tab,
		super_event_status,
		super_event_page,
		super_event_details,
		super_event_category_details,
		super_event_fight_details,
	} = create_data;

	const eventNextHandler = (data, nextButton) => {
		EventEmitter.dispatch('submit_event_details', cardBtn);
		EventEmitter.dispatch('submit_tickets');
		EventEmitter.dispatch('submit_admin');
		EventEmitter.dispatch('submit_invite');
		EventEmitter.dispatch('submit_Fight_details', cardBtn);
		nextButton === 'nextButton' && setStep(data);
		nextButton === 'nextButton' && currentCreateEventTab(data);
	};
	const eventBackHandler = (data) => {
		setStep(data);
		currentCreateEventTab(data);
	};
	const addFights = () => {
		// console.log(fightData.length, "fightData.length")
		setFightData([
			...fightData,
			{
				id: fightData.length,
			},
		]);
	};
	const deleteFight = (index) => {
		var deletedFight = fightData.filter((item) => item?.id !== index);

		setFightData(deletedFight);
		var deletedFightCard = event_fight_card.filter((item, findex) => findex !== index);

		eventFightCard(deletedFightCard);
	};

	useEffect(() => {
		if (cardBtn === 1) {
			// 16-07
			// if (event_details) {
			//     setStep(2);
			//     currentCreateEventTab(2);
			// }
			// if (event_details && event_ticket) {
			//     setStep(3);
			//     currentCreateEventTab(3);
			// }
			// if (event_details && event_ticket && event_admin) {
			//     setStep(4);
			//     currentCreateEventTab(4);
			// }
			// if (event_details && event_ticket && event_admin && event_invite) {
			//     setStep(5);
			//     currentCreateEventTab(5);
			// }
			// end

			if (event_details) {
				setStep(3);
				currentCreateEventTab(3);
			}

			if (event_details && event_admin) {
				setStep(4);
				currentCreateEventTab(4);
			}

			if (event_details && event_admin && event_invite) {
				setStep(5);
				currentCreateEventTab(5);
			}
		} else {
			// if (event_details) {
			//     setStep(1);
			//     currentCreateEventTab(1);
			// }
			// if (event_details && card_category_data) {
			//     setStep(2);
			//     currentCreateEventTab(2);
			// }
			// if (event_details && event_fight_card) {
			//     setStep(3);
			//     currentCreateEventTab(3);
			// }
		}
		// eslint-disable-next-line
	}, [event_details, event_ticket, cardBtn, event_admin, event_invite, event_fight_card]);
	useEffect(() => {
		cardBtn && currentSuperEventPage(cardBtn);
	}, [cardBtn]);
	useEffect(() => {
		super_event_page !== undefined && super_event_page !== '' && setCardBtn(super_event_page);
	}, [super_event_page]);

	useEffect(() => {
		current_tab !== undefined && current_tab !== '' && setStep(current_tab);
	}, [current_tab]);
	useEffect(() => {
		if (super_event_page) {
			setStep(0);
			currentCreateEventTab(0);
		}
	}, [super_event_page]);
	useEffect(() => {
		currentFightTab(fightStep);
	}, [fightStep]);
	useEffect(() => {
		// fightData && eventFightCard(fightData);
		// var obj = {};
		// // fightData && eventFightCard(Array.from({ length: fightData.length }, (x, i) => obj));
		// if (event_confirm === 'update' && event_type === 'fight' && !super_event_status) {
		//   var numberOFfight = event_fight_card && event_fight_card.map((item, index) => {
		//     return ({ id: index })
		//   })
		//   setFightData(numberOFfight);
		// }
		// else {

		if (event_confirm !== undefined) {
			if (fightData && event_confirm !== 'update') {
				let fightobj =
					fightData &&
					fightData.map((item, index) => {
						// event_fight_card && event_fight_card[index] && console.log(event_fight_card[index], index, "event_fight_cardevent_fight_card iii fff")
						if (event_fight_card && event_fight_card[index]) {
							// console.log(event_fight_card[index], "event_fight_cardevent_fight_card iii")
							return event_fight_card[index];
						}

						return {};
					});

				eventFightCard(fightobj);
			}

			if (event_confirm === 'update' && event_type === 'fight' && !super_event_status) {
				if (fightData?.length >= event_fight_card?.length) {
					let fightobj =
						fightData &&
						fightData.map((item, index) => {
							event_fight_card &&
								event_fight_card[index] &&
								console.log(event_fight_card[index], index, 'event_fight_cardevent_fight_card iii fff');

							if (event_fight_card && event_fight_card[index]) {
								return event_fight_card[index];
							}

							return {};
						});

					fightobj && eventFightCard(fightobj);
				}
			}
		}
		// }
	}, [fightData, event_confirm]);

	return (
		<Box className="create-es-bx">
			{cardBtn === 1 ? (
				<Box className="event-stepper-box">
					<List>
						<ListItem button disableElevation disableRipple disableTouchRipple disableFocusRipple selected={step === 0}>
							<ListItemIcon>{step === 0 ? <ActiveIcon /> : event_details ? <CompleteIcon /> : null}</ListItemIcon>
							<ListItemText
								primary="Event Details"
								onClick={() => {
									setStep(0);
									currentCreateEventTab(0);
								}}
							/>
						</ListItem>
						{/* {
                    event_type === 'fight' && !super_event_status ?
                      <Box className='add-fight-sec'>
                        <Typography className='add-fight-text' onClick={() => addFights()}>
                          {t('events.add_fight')}
                        </Typography>
                        <List>
                          {
                            event_type === 'fight' && (
                              fightData.map((data, index) => {
                                return (
                                  <ListItem
                                    button
                                    disableElevation
                                    disableRipple
                                    disableTouchRipple
                                    disableFocusRipple
                                    selected={step === 1}
                                  >
                                    <ListItemIcon>
                                      <ListItemIcon>
                                        {step === 1 && data.id === fightStep ? (
                                          <ActiveIcon />
                                        ) : event_fight_card ? (
                                          <CompleteIcon />
                                        ) : null}
                                      </ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={'Fight ' + (index + 1)} />
                                  </ListItem>
                                );
                              }))
                          }
                        </List>
                      </Box>
                      : null} */}
						{/* <ListItem
                            button
                            disableElevation
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            selected={step === 2}
                        >
                            <ListItemIcon>
                                <ListItemIcon>
                                    {step === 2 ? (
                                        <ActiveIcon />
                                    ) : event_ticket ? (
                                        <CompleteIcon />
                                    ) : null}
                                </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tickets" />
                        </ListItem> */}
						<ListItem
							button
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							selected={step === 3}
							className={event_details ? '' : 'cursor-text'}
						>
							<ListItemIcon>{step === 3 ? <ActiveIcon /> : event_admin ? <CompleteIcon /> : null}</ListItemIcon>
							<ListItemText
								primary="Admins"
								onClick={() => {
									if (event_details) {
										setStep(3);
										currentCreateEventTab(3);
									}
								}}
							/>
						</ListItem>
						<ListItem
							button
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							selected={step === 4}
							className={event_admin ? '' : 'cursor-text'}
						>
							<ListItemIcon>{step === 4 ? <ActiveIcon /> : event_invite ? <CompleteIcon /> : null}</ListItemIcon>
							<ListItemText
								primary="Invite"
								onClick={() => {
									if (event_admin) {
										setStep(4);
										currentCreateEventTab(4);
									}
								}}
							/>
						</ListItem>
						<ListItem
							button
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							selected={step === 5}
							className={event_invite ? '' : 'cursor-text'}
						>
							<ListItemIcon>
								<ListItemIcon>{step === 5 ? <ActiveIcon /> : null}</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								primary="Confirm"
								onClick={() => {
									if (event_invite) {
										setStep(5);
										currentCreateEventTab(5);
									}
								}}
							/>
						</ListItem>
					</List>
				</Box>
			) : (
				<Box className="event-stepper-box">
					<List>
						<ListItem button disableElevation disableRipple disableTouchRipple disableFocusRipple selected={step === 0}>
							<ListItemIcon>{step === 0 ? <ActiveIcon /> : event_details ? <CompleteIcon /> : null}</ListItemIcon>
							<ListItemText
								primary="Event Details"
								onClick={() => {
									setStep(0);
									currentCreateEventTab(0);
								}}
							/>
						</ListItem>
						<ListItem
							button
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							selected={step === 1}
							className={super_event_details && super_event_details[`eventindex-${cardBtn}`] ? '' : 'cursor-text'}
						>
							<ListItemIcon>{step === 1 ? <ActiveIcon /> : <CompleteIcon />}</ListItemIcon>

							<ListItemText
								primary="Category"
								onClick={() => {
									if (super_event_details && super_event_details[`eventindex-${cardBtn}`]) {
										setStep(1);
										currentCreateEventTab(1);
									}
								}}
							/>
						</ListItem>

						<Box className="add-fight-sec">
							<Typography className="add-fight-text" onClick={() => addFights()}>
								{t('events.add_fight')}
							</Typography>
							<List>
								{fightData &&
									fightData.map((data, index) => {
										return (
											<ListItem
												button
												disableElevation
												disableRipple
												disableTouchRipple
												disableFocusRipple
												selected={step === 2}
												className={
													super_event_category_details && super_event_category_details[`eventindex-${cardBtn}`]
														? 'fight-left-sec'
														: 'fight-left-sec cursor-text'
												}
											>
												<ListItemIcon className="active-fight-button">
													<ListItemIcon>
														{step === 2 && data.id === fightStep ? (
															<ActiveIcon />
														) : event_fight_card ? (
															<CompleteIcon />
														) : null}
													</ListItemIcon>
												</ListItemIcon>
												<ListItemIcon>
													<ToggleIcon />
												</ListItemIcon>
												<ListItemText
													primary={'Fight ' + (index + 1)}
													onClick={() => {
														if (super_event_details && super_event_details[`eventindex-${cardBtn}`]) {
															if (
																super_event_category_details &&
																super_event_category_details[`eventindex-${cardBtn}`]
															) {
																setStep(2);
																currentCreateEventTab(2);
																setFightStep(index);
															}
														}
													}}
												/>
												<ListItemIcon
													onClick={() => {
														deleteFight(index);
													}}
												>
													<DeleteIcon />
												</ListItemIcon>
											</ListItem>
										);
									})}
							</List>
						</Box>
						<ListItem button disableElevation disableRipple disableTouchRipple disableFocusRipple selected={step === 3}>
							<ListItemIcon>
								<ListItemIcon>{step === 3 ? <ActiveIcon /> : null}</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								primary="Confirm"
								onClick={() => {
									if (
										super_event_details &&
										super_event_details[`eventindex-${cardBtn}`] &&
										super_event_fight_details &&
										super_event_fight_details[`eventindex-${cardBtn}`]
									) {
										setStep(3);
										currentCreateEventTab(3);
									}
								}}
							/>
						</ListItem>
					</List>
				</Box>
			)}
			<Box className="next-draft-bx">
				<Box className="create-event-card-btn-sec">
					<Button
						className={cardBtn === 1 ? 'create-event-card-btn active-btn' : 'create-event-card-btn'}
						onClick={() => {
							setCardBtn(1);
						}}
					>
						1
					</Button>
					<Button
						className={cardBtn === 2 ? 'create-event-card-btn active-btn' : 'create-event-card-btn'}
						onClick={() => {
							setCardBtn(2);
							setStep(0);
							currentCreateEventTab(0);
						}}
					>
						2
					</Button>
					<Button
						className={cardBtn === 3 ? 'create-event-card-btn active-btn' : 'create-event-card-btn'}
						onClick={() => {
							setCardBtn(3);
							setStep(0);
							currentCreateEventTab(0);
						}}
					>
						3
					</Button>
					<Button
						className={cardBtn === 4 ? 'create-event-card-btn active-btn' : 'create-event-card-btn'}
						onClick={() => {
							setCardBtn(4);
						}}
					>
						4
					</Button>
				</Box>
				{/* NEXT BUTTON */}
				{cardBtn === 1 ? (
					step === 0 ? (
						<Button
							fullWidth
							className="ndraft-btn next-btn"
							onClick={() => {
								event_type === 'fight' && !super_event_status ? eventNextHandler(1) : eventNextHandler(2);
							}}
						>
							{t('next')}
						</Button>
					) : step === 1 ? (
						<>
							{/* {fightData.map((data, index) => {
                                    if (fightStep === index) {
                                        return (
                                            <Box display="flex">
                                                <Button
                                                    fullWidth
                                                    className="ndraft-btn back-btn"
                                                    onClick={() => {
                                                        (fightStep > 0) ?
                                                            setFightStep(fightStep - 1) :
                                                            eventBackHandler(0);
                                                    }}
                                                >
                                                    {t('back')}
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    className="ndraft-btn next-btn"
                                                    onClick={() => {
                                                        (fightStep < fightData.length - 1) ?
                                                            setFightStep(fightStep + 1) :
                                                            eventNextHandler(2)
                                                    }}
                                                >
                                                    {t('next')}
                                                </Button>
                                            </Box>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })} */}
						</>
					) : step === 2 ? (
						<Box display="flex">
							<Button
								fullWidth
								className="ndraft-btn back-btn"
								onClick={() => {
									event_type === 'fight' && !super_event_status ? eventBackHandler(1) : eventBackHandler(0);
								}}
							>
								{t('back')}
							</Button>
							<Button fullWidth className="ndraft-btn next-btn" onClick={() => eventNextHandler(3)}>
								{t('next')}
							</Button>
						</Box>
					) : step === 3 ? (
						<Box display="flex">
							<Button
								fullWidth
								className="ndraft-btn back-btn"
								onClick={() => {
									eventBackHandler(2);
								}}
							>
								{t('back')}
							</Button>
							<Button fullWidth className="ndraft-btn next-btn" onClick={() => eventNextHandler(4)}>
								{t('next')}
							</Button>
						</Box>
					) : step === 4 ? (
						<Box display="flex">
							<Button
								fullWidth
								className="ndraft-btn back-btn"
								onClick={() => {
									eventBackHandler(3);
								}}
							>
								{t('back')}
							</Button>
							<Button fullWidth className="ndraft-btn next-btn" onClick={() => eventNextHandler(5)}>
								{t('next')}
							</Button>
						</Box>
					) : step === 5 ? (
						<Box display="flex">
							<Button
								fullWidth
								className="ndraft-btn back-btn"
								onClick={() => {
									eventBackHandler(4);
								}}
							>
								{t('back')}
							</Button>
							<Button
								fullWidth
								className="ndraft-btn next-btn"
								onClick={() => {
									eventNextHandler(0, 'nextButton');
									setCardBtn(2);
								}}
							>
								{t('next')}
							</Button>
						</Box>
					) : (
						<Button fullWidth className="ndraft-btn next-btn" onClick={() => eventNextHandler(0)}>
							{t('next')}
						</Button>
					)
				) : step === 0 ? (
					<Button
						fullWidth
						className="ndraft-btn next-btn"
						onClick={() => {
							setStep(1);
							currentCreateEventTab(1);
							eventNextHandler(1);
						}}
					>
						{t('next')}
					</Button>
				) : step === 1 ? (
					<Box display="flex">
						<Button
							fullWidth
							className="ndraft-btn back-btn"
							onClick={() => {
								eventBackHandler(0);
							}}
						>
							{t('back')}
						</Button>
						<Button
							fullWidth
							className="ndraft-btn next-btn"
							onClick={() => {
								setStep(2);
								currentCreateEventTab(2);
								eventNextHandler(2);
							}}
						>
							{t('next')}
						</Button>
					</Box>
				) : step === 2 ? (
					<>
						{fightData.map((data, index) => {
							if (fightStep === index) {
								return (
									<Box display="flex">
										<Button
											fullWidth
											className="ndraft-btn back-btn"
											onClick={() => {
												fightStep > 0 ? setFightStep(fightStep - 1) : eventBackHandler(1);
											}}
										>
											{t('back')}
										</Button>
										<Button
											fullWidth
											className="ndraft-btn next-btn"
											onClick={() => {
												if (fightStep < fightData.length - 1) {
													setFightStep(fightStep + 1);
												} else {
													eventNextHandler(3);
													setStep(3);
													currentCreateEventTab(3);
												}
											}}
										>
											{t('next')}
										</Button>
									</Box>
								);
							} else {
								return <></>;
							}
						})}
						{/* <Button
                                    fullWidth
                                    className="ndraft-btn back-btn"
                                    onClick={() => {
                                        eventBackHandler(1);
                                    }}
                                >
                                    {t('back')}
                                </Button>
                                <Button
                                    fullWidth
                                    className="ndraft-btn next-btn"
                                    onClick={() => {
                                        setStep(3);
                                        currentCreateEventTab(3);
                                        eventNextHandler(3)
                                    }}
                                >
                                    {t('next')}
                                </Button> */}
					</>
				) : step === 3 ? (
					<Box display="flex">
						<Button
							fullWidth
							className="ndraft-btn back-btn"
							onClick={() => {
								eventBackHandler(2);
							}}
						>
							{t('back')}
						</Button>
						<Button
							fullWidth
							className="ndraft-btn next-btn"
							onClick={() => {
								if (cardBtn !== 4) {
									setStep(0);
									currentCreateEventTab(0);
									eventNextHandler(0, 'nextButton');
									setCardBtn(cardBtn + 1);
								} else {
									createEvents();
								}
							}}
						>
							{t('next')}
						</Button>
					</Box>
				) : step === 4 ? (
					<Box display="flex">
						<Button
							fullWidth
							className="ndraft-btn back-btn"
							onClick={() => {
								eventBackHandler(3);
							}}
						>
							{t('back')}
						</Button>
						<Button
							fullWidth
							className="ndraft-btn next-btn"
							onClick={() => {
								if (cardBtn !== 4) {
									setStep(0);
									currentCreateEventTab(0);
									eventNextHandler(0, 'nextButton');
									setCardBtn(cardBtn + 1);
								} else {
									createEvents();
								}
							}}
						>
							{cardBtn === 4 ? t('events.publish') : t('next')}
						</Button>
					</Box>
				) : (
					<Button
						fullWidth
						className="ndraft-btn next-btn"
						onClick={() => {
							setStep(0);
							currentCreateEventTab(0);
							eventNextHandler(0);
							setFightStep(0);
						}}
					>
						{t('next')}
					</Button>
				)}
				{/* <Button
                    fullWidth
                    className="ndraft-btn next-btn"
                    onClick={eventNextHandler}
                >
                    {t('next')}
                </Button> */}
				{/* SAVE DRAFT */}
				<Button fullWidth className="ndraft-btn">
					{t('events.save_draft')}
				</Button>
			</Box>
		</Box>
	);
};
