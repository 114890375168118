import { useContext } from 'react';
// HIDE-MVP: CM-75
// import { Box, Typography, Button } from '@material-ui/core';
// eslint-disable-next-line import/order
// import HotActivityRecord from './HotActivityRecord';
import './newsfeed-right.scss';
// HIDE-MVP: CM-75
// import { useSelector } from 'react-redux';
// import moment from 'moment';
// import { useNavigate } from 'react-router-dom';
import { ViewPortContext } from 'src/App';
import { AD } from '../../Community/CommunityView/LeftSide/Discussions/AD';
import { useTranslation } from 'react-i18next';

export const Right = () => {
	const { t } = useTranslation();
	// HIDE-MVP: CM-75
	//	const NewsfeedPostList = useSelector((state) => state.Combat.NewsfeedPostList);
	//	const navigate = useNavigate();
	const viewPortContext = useContext(ViewPortContext);
	//	const windowWidth = viewPortContext?.width;
	//	const breakPoint = viewPortContext?.breakPoint;

	return (
		<>
			{/* HIDE-MVP: CM-75 */}
			{/*		{windowWidth > breakPoint && (*/}
			{/*			<>*/}
			{/*				<Box className="hotactivity-container">*/}
			{/*					<Typography className="record-title">*/}
			{/*						{t('newsfeed.hotactivity')}*/}
			{/*						<Button*/}
			{/*							disableElevation*/}
			{/*							disableTouchRipple*/}
			{/*							disableFocusRipple*/}
			{/*							disableRipple*/}
			{/*							className="see-all-btn-right"*/}
			{/*							onClick={() => navigate('/all-activity')}*/}
			{/*						>*/}
			{/*							{t('see_all')}*/}
			{/*						</Button>*/}
			{/*					</Typography>*/}

			{/*					<Box className="activity-record-mainbox">*/}
			{/*						{NewsfeedPostList && NewsfeedPostList?.hot_activity_list?.length > 0 ? (*/}
			{/*							<>*/}
			{/*								{NewsfeedPostList?.hot_activity_list.map((item, index) => {*/}
			{/*									const postContent = item?.PostLists.find((d) => d?.Item?.type === 'text');*/}

			{/*									return (*/}
			{/*										<>*/}
			{/*											<HotActivityRecord*/}
			{/*												activityType={item?.receiver_type}*/}
			{/*												activityOwner={item?.user_full_name}*/}
			{/*												// communityName={(item?.receiver_type === 'community') ? item?.name : ""}*/}
			{/*												communityName={item?.receiver_type === 'community' ? item?.community_name : ''}*/}
			{/*												time={*/}
			{/*													item?.timestamp_created !== null &&*/}
			{/*													moment.utc(item?.timestamp_created).fromNow().replace('in ', '')*/}
			{/*												}*/}
			{/*												// activityContent="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]"*/}
			{/*												activityContent={postContent?.Item?.data}*/}
			{/*												comId={item?.receiver_id}*/}
			{/*												postId={item?.post_id}*/}
			{/*												userURI={item?.user_meta?.data}*/}
			{/*											/>*/}
			{/*										</>*/}
			{/*									);*/}
			{/*								})}*/}
			{/*							</>*/}
			{/*						) : (*/}
			{/*							<Typography className="no-post-text">/!* {t('community.no_post_txt')} *!/</Typography>*/}
			{/*						)}*/}
			{/* <HotActivityRecord
			           activityType="[Activity Type]"
			           activityOwner="[Activity Owner]"
			           communityName="[Community Name]"
			           time="1 hour ago"
			           activityContent="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]"
			         />{' '}
			         <HotActivityRecord
			           activityType="[Activity Type]"
			           activityOwner="[Activity Owner]"
			           communityName="[Community Name]"
			           time="1 hour ago"
			           activityContent="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]"
			         />{' '}
			         <HotActivityRecord
			           activityType="[Activity Type]"
			           activityOwner="[Activity Owner]"
			           communityName="[Community Name]"
			           time="1 hour ago"
			           activityContent="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]"
			         />{' '}
			         <HotActivityRecord
			           activityType="[Activity Type]"
			           activityOwner="[Activity Owner]"
			           communityName="[Community Name]"
			           time="1 hour ago"
			           activityContent="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]"
			         />{' '}
			*/}
			{/* HIDE-MVP: CM-75 */}
			{/*			</Box>*/}
			{/*		</Box>*/}
			{/*	</>*/}
			{/*)}*/}
			{/* ADVERTISEMENT BANNER */}
			<AD className={'hide-breakpoint'} />
		</>
	);
};
