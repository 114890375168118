import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import './buttonGroup.scss';

export const ButtonGroups = ({ title, className, content, disabled, startIcon }) => {
	const [openButton, setOpenButton] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpenButton((preOpen) => !preOpen);
	};
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpenButton(false);
	};

	return (
		<Box className="btn-groups-container">
			<Button onClick={!disabled && handleToggle} ref={anchorRef} className={className} startIcon={startIcon}>
				{title}
			</Button>
			<Popper open={openButton} anchorEl={anchorRef.current} transition disablePortal className="btn-group-popper">
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList>
									{content.map((option, index) => (
										<MenuItem
											onClick={() => {
												option && option.clickFn();
												setOpenButton(false);
											}}
											key={index}
										>
											{option?.title}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Box>
	);
};

ButtonGroups.propTypes = {
	content: PropTypes.array,
};
