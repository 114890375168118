import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import BackgroundImage from 'src/assets/images/banner/banner-1.jpg';

import { Footer, NavBar, useLayoutStyles } from '../components';
import { TopBar } from './TopBar';
import menu from './menu';
import cn from 'classnames';

export const AuthLayout = ({ home }) => {
	const classes = useLayoutStyles(BackgroundImage)();
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const redux_auth = useSelector((state) => state?.Combat?.authData);
	const auth = redux_auth ? redux_auth : fetchFromStorage('authData');
	const statics = useSelector((state) => state?.Combat?.user_statics);
	const user_statics = statics ? statics : fetchFromStorage('user_statics');
	const verify_count = useSelector((state) => state?.Combat?.user_profile_count);

	return (
		<div className={classes.wrapper}>
			<ToastContainer />
			<TopBar home={home} onMobileNavOpen={() => setMobileNavOpen(true)} />
			<NavBar items={menu} onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
			<div className={cn(classes.content, 'combat-container')}>
				<Outlet />
			</div>
			<Footer auth={auth} user_statics={user_statics} verify_count={verify_count} />
		</div>
	);
};
