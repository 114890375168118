export const saveToStorage = (key, value) => {
	localStorage.setItem(key, JSON?.stringify(value));
};

export const fetchFromStorage = (key) => {
	try {
		return JSON?.parse(localStorage?.getItem(key));
	} catch (err) {
		return undefined;
	}
};

export const removeFromStorage = (key) => {
	localStorage.removeItem(key);
};

export const clearStorage = () => {
	localStorage.clear();
};
