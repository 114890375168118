import { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Typography,
	InputAdornment,
	withStyles,
	TextField,
	Button,
	ListItem,
	Avatar,
	Checkbox,
	ListItemIcon,
	ListItemText,
	IconButton,
} from '@material-ui/core';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { parse } from 'query-string';
import { objectToQueryString } from 'src/helpers/commonFunctions';
import { fetchFromStorage, saveToStorage, removeFromStorage } from 'src/helpers/context';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import { ReactComponent as NextIcon } from 'src/assets/images/icons/next.svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './mobileViewFilter.scss';

const SearchTextField = withStyles({
	root: {
		backgroundColor: '#202020',
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const MobileViewFilter = ({ getCommunityList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const comm_filter = fetchFromStorage('communityfilter');
	const query = parse(location.search);
	const [page, setPage] = useState('filter');
	const [disciplineData, setDisciplineData] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [badgeList, setBadgeList] = useState([]);
	const [selectedBadge, setSelectedBadge] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedDiscipline, setSelectedDiscipline] = useState([]);
	const [selectedProfileType, setSelectedProfileType] = useState([]);
	const [reportReasonList, setReportReasonList] = useState([]);
	const [selectedMemberShip, setSelectedMemberShip] = useState([]);
	const [DDArray, setDDArray] = useState([]);
	const [checked, setChecked] = useState();
	const [searchVal, setSearchVal] = useState(comm_filter?.search ? comm_filter?.search : '');
	const [ordderByVal, setOrdderByVal] = useState(comm_filter?.orderBy);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [loader, setloader] = useState(false);
	const communityList = useSelector((state) => state?.Combat?.communityList);

	const auth = useSelector((state) => state?.Combat?.authData) || fetchFromStorage('authData');
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const handleChange = (e) => {
		setSearchVal(e.target.value);
	};
	const handleToggle = (value) => () => {
		if (page === t('myprofile.badges')) {
			onBadgeSelect(value);
		} else if (page === t('myprofile.categories')) {
			onCategorySelect(value);
		} else if (page === t('myprofile.specialities')) {
			onDisciplineSelect(value);
		} else if (page === t('profile_type.pts')) {
			onProfileTypeSelect(value);
		} else if (page === t('profile_type.membership_types')) {
			onMembershipTypeSelect(value);
		}
		// onSelect(value)
		// const currentIndex = checked.indexOf(value);
		// const newChecked = [...checked];
		// if (currentIndex === -1) {
		//     newChecked.push(value);
		// } else {
		//     newChecked.splice(currentIndex, 1);
		// }
		// setChecked(newChecked);
	};

	const restTypeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];
	const memberTypeList = [
		{ id: 'member', name: t('profile_type.member') },
		{ id: 'moderator', name: t('profile_type.moderator') },
		{ id: 'admin', name: t('profile_type.admin') },
		{ id: 'owner', name: t('profile_type.owner') },
	];
	const fetchCommunityList = async (QS, order, pageVal) => {
		var queryString = QS !== undefined && QS !== '' ? QS : query;
		var OB = order !== undefined && order !== '' ? order : ordderByVal;
		// setloader(true)
		var pageNumber = pageVal !== undefined && pageVal !== '' ? pageVal : currentPage;

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getCommunities,
				{
					params: {
						order_by: OB,
						...(searchVal !== '' && { name: searchVal }),
						badges: queryString?.badges,
						categories: queryString?.categories,
						disciplines: queryString?.disciplines,
						profile_types: queryString?.profile_types,
						membership_type: queryString?.membership_type,
						// page: currentPage,
						page: pageNumber,
						limit: 5,
					},
				},
				authHeader,
			);

			if (status === 200) {
				var hasMore = data?.communitiesData?.length < 5 ? false : true;

				pageVal !== undefined && pageVal !== '' ? setCurrentPage(2) : setCurrentPage(currentPage + 1);
				var COMMUNITY_LISTING = pageVal !== undefined && pageVal !== '' ? [] : communityList;
				var mergedArray = currentPage === 1 ? data?.communitiesData : COMMUNITY_LISTING.concat(data?.communitiesData);

				// getCommunityList(data?.communitiesData);
				getCommunityList(mergedArray);
				setloader(false);
				setHasMore(hasMore);
				// var mergedArray = allCommunityList.concat(data?.communitiesData)
				// setCommunityList(mergedArray)
				// console.log('allCommunityList', mergedArray, allCommunityList)
			}
		} catch (error) {
			setloader(false);
		}
	};
	// FETCH REPORT REASON LIST
	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH DISCIPLINE TYPES DATA
	const fetchDisciplineTypesData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getDisciplinesData, authHeader);

			if (status === 200) {
				setDisciplineData(data?.getDisciplinesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH CATEGORY TYPES DATA
	const fetchCategoryData = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCategory);

			if (status === 200) {
				setCategoryData(data?.metaCategoriesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH BADGE DATA
	const fetchBadgeList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllBadgeList);

			if (status === 200) {
				setBadgeList(data?.getBadgesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const searchData = () => {
		var obj = {
			...comm_filter,
			search: searchVal,
		};

		saveToStorage('communityfilter', obj);
		var QUERY_STRING = objectToQueryString(comm_filter);

		QUERY_STRING !== '' ? navigate(`/communities?${QUERY_STRING}`) : navigate(`/communities`);
	};
	const onBadgeSelect = (data) => {
		// setSelectedBadge(data);
		const currentIndex = selectedBadge.indexOf(data);
		const newChecked = [...selectedBadge];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedBadge(newChecked);
	};
	const onCategorySelect = (data) => {
		// setSelectedCategory(data);
		const currentIndex = selectedCategory.indexOf(data);
		const newChecked = [...selectedCategory];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedCategory(newChecked);
	};
	const onDisciplineSelect = (data) => {
		// setSelectedDiscipline(data);
		const currentIndex = selectedDiscipline.indexOf(data);
		const newChecked = [...selectedDiscipline];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedDiscipline(newChecked);
	};
	const onMembershipTypeSelect = (data) => {
		const currentIndex = selectedMemberShip.indexOf(data);
		const newChecked = [...selectedMemberShip];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedMemberShip(newChecked);
	};
	const onProfileTypeSelect = (data) => {
		// setSelectedProfileType(data);
		const currentIndex = selectedProfileType.indexOf(data);
		const newChecked = [...selectedProfileType];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedProfileType(newChecked);
	};
	const handleFilter = () => {
		var badgeQS = selectedBadge.join(',');
		var queryParams = {
			...(selectedBadge.length > 0 && { badges: badgeQS }),
			...(selectedCategory.length > 0 && {
				categories: selectedCategory.join(','),
			}),
			...(selectedDiscipline.length > 0 && {
				disciplines: selectedDiscipline.join(','),
			}),
			...(selectedProfileType.length > 0 && {
				profile_types: selectedProfileType.join(','),
			}),
			...(selectedMemberShip.length > 0 && {
				membership_type: selectedMemberShip.join(','),
			}),
		};
		var QUERY_STRING = objectToQueryString(queryParams);

		// QUERY_STRING !== '' ? navigate(`/communities?${QUERY_STRING}`) : navigate(`/communities`);
		fetchCommunityList(queryParams, '', 1);

		var obj = {
			...comm_filter,
			badges: queryParams?.badges,
			categories: queryParams?.categories,
			disciplines: queryParams?.disciplines,
			profile_types: queryParams?.profile_types,
			membership_type: queryParams?.membership_type,
			QUERY_STRING: QUERY_STRING,
			queryParams: queryParams,
		};

		saveToStorage('communityfilter', obj);
		setPage('filter');
	};
	const clearFilter = () => {
		navigate('/communities/mobile-view');
		setSelectedBadge([]);
		setSelectedCategory([]);
		setSelectedDiscipline([]);
		setSelectedProfileType([]);
		setSelectedMemberShip([]);
		const queryParams = {};

		fetchCommunityList(queryParams, '', 1);
		removeFromStorage('communityfilter');
	};

	useEffect(() => {
		fetchBadgeList();
		fetchCategoryData();
		fetchDisciplineTypesData();
		fetchReportReasonList();

		const SB = comm_filter?.badges?.split(',').map((i) => Number(i));
		const SC = comm_filter?.categories?.split(',').map((i) => Number(i));
		const SD = comm_filter?.disciplines?.split(',').map((i) => Number(i));
		const SPT = comm_filter?.profile_types?.split(',').map((i) => Number(i));
		const SMS = comm_filter?.membership_type?.split(',').map((i) => Number(i));

		SB && setSelectedBadge(SB);
		SC && setSelectedCategory(SC);
		SD && setSelectedDiscipline(SD);
		SPT && setSelectedProfileType(SPT);
		SMS && setSelectedMemberShip(SMS);

		// console.log('selectedProfileType', SB, SC, SD)

		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (page) {
			if (page === 'filter') {
				setDDArray([]);
			} else if (page === t('myprofile.badges')) {
				setDDArray(badgeList);
			} else if (page === t('myprofile.categories')) {
				setDDArray(categoryData);
			} else if (page === t('myprofile.specialities')) {
				setDDArray(disciplineData);
			} else if (page === t('profile_type.pts')) {
				setDDArray(restTypeList);
			} else if (page === t('profile_type.membership_types')) {
				setDDArray(memberTypeList);
			} else {
				setDDArray([]);
			}
		}
	}, [page]);

	useEffect(() => {
		if (page) {
			if (page === t('myprofile.badges')) {
				setChecked(selectedBadge);
			} else if (page === t('myprofile.categories')) {
				setChecked(selectedCategory);
			} else if (page === t('myprofile.specialities')) {
				setChecked(selectedDiscipline);
			} else if (page === t('profile_type.pts')) {
				setChecked(selectedProfileType);
			} else if (page === t('profile_type.membership_types')) {
				setChecked(selectedMemberShip);
			} else {
				// setChecked([]);
			}
		}
	}, [page, selectedBadge, selectedCategory, selectedDiscipline, selectedProfileType, selectedMemberShip]);

	return (
		<Box className="community-page">
			{page === 'filter' ? (
				<Box className="community-page-main">
					<Container maxWidth="lg">
						<Box className="combat-container filter-position">
							<Box className="community-title-box mobile-view-title">
								<IconButton onClick={() => navigate('/communities')}>
									<BackIcon />
								</IconButton>
								<Typography variant="h1" align="center">
									{t('community.filter')}
								</Typography>
								<Button type="submit" className="filter-btn" onClick={() => clearFilter()}>
									{t('button_text.clear_filter')}
								</Button>
							</Box>
							<Box className="search-field-box">
								<SearchTextField
									onChange={handleChange}
									fullWidth
									value={searchVal}
									placeholder={t('community.search_community')}
									size="small"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box className="mobile-view-filter-options">
								{/*CM-107*/}
								{/*<Box className="mobile-filter">*/}
								{/*	<Typography className="filter-title">{t('myprofile.badges')}</Typography>*/}
								{/*	<Box className="filter-right">*/}
								{/*		<Avatar className="circle-blue">{selectedBadge?.length}</Avatar>*/}
								{/*		<IconButton onClick={() => setPage(t('myprofile.badges'))}>*/}
								{/*			<NextIcon />*/}
								{/*		</IconButton>*/}
								{/*	</Box>*/}
								{/*</Box>*/}
								<Box className="mobile-filter">
									<Typography className="filter-title">{t('myprofile.categories')}</Typography>
									<Box className="filter-right">
										<Avatar className="circle-blue">{selectedCategory?.length}</Avatar>
										<IconButton onClick={() => setPage(t('myprofile.categories'))}>
											<NextIcon />
										</IconButton>
									</Box>
								</Box>
								<Box className="mobile-filter">
									<Typography className="filter-title">{t('myprofile.specialities')}</Typography>
									<Box className="filter-right">
										<Avatar className="circle-blue">{selectedDiscipline?.length}</Avatar>
										<IconButton onClick={() => setPage(t('myprofile.specialities'))}>
											<NextIcon />
										</IconButton>
									</Box>
								</Box>
								<Box className="mobile-filter">
									<Typography className="filter-title">{t('profile_type.pts')}</Typography>
									<Box className="filter-right">
										<Avatar className="circle-blue">{selectedProfileType?.length}</Avatar>
										<IconButton onClick={() => setPage(t('profile_type.pts'))}>
											<NextIcon />
										</IconButton>
									</Box>
								</Box>
								<Box className="mobile-filter">
									<Typography className="filter-title">{t('profile_type.membership_types')}</Typography>
									<Box className="filter-right">
										<Avatar className="circle-blue">{selectedMemberShip?.length}</Avatar>
										<IconButton onClick={() => setPage(t('profile_type.membership_types'))}>
											<NextIcon />
										</IconButton>
									</Box>
								</Box>
							</Box>
							<Box className="filter-button filter-sticky-btn">
								<Button
									type="submit"
									fullWidth
									className="filter-btn"
									onClick={() => {
										fetchCommunityList(query, '', 1);
										searchData();
									}}
								>
									{t('search_btn')}
								</Button>
							</Box>
						</Box>
					</Container>
				</Box>
			) : (
				<Box className="community-page-main">
					<Container maxWidth="lg">
						<Box className="combat-container filter-position">
							<Box className="community-title-box mobile-view-filter-title">
								<IconButton onClick={() => setPage('filter')}>
									<BackIcon />
								</IconButton>
								<Typography variant="h1" align="center">
									{t('community.filter')}
									{':' + page}
								</Typography>
							</Box>
							<Box className="filter-sec">
								{DDArray.map((value, index) => {
									const labelId = `checkbox-list-label-${index}`;

									return (
										<ListItem onClick={handleToggle(value?.id)} key={index} disablePadding className="filter-name-sec">
											{/* <ListItemButton role={undefined} onClick={handleToggle(value)} dense> */}
											<ListItemIcon>
												<Checkbox
													edge="start"
													checked={checked.indexOf(value?.id) !== -1}
													tabIndex={-1}
													disableRipple
													inputProps={{ 'aria-labelledby': labelId }}
													className="filter-check-box"
												/>
											</ListItemIcon>
											<ListItemText id={labelId} primary={value?.name} className="filter-name" />
											{/* </ListItemButton> */}
										</ListItem>
									);
								})}
							</Box>
							<Box className="filter-button filter-sticky-btn">
								<Button type="submit" fullWidth className="filter-btn" onClick={() => handleFilter()}>
									{t('community.apply')}
								</Button>
							</Box>
						</Box>
					</Container>
				</Box>
			)}
		</Box>
	);
};
