import React from 'react';

import { LinkComponent } from '../LinkComponent';

const Element = (props) => {
	const { attributes, children, element } = props;

	switch (element.type) {
		case 'link':
			return <LinkComponent {...props} />;
		default:
			return (
				<p className={'text-editor_text'} {...attributes}>
					{children}
				</p>
			);
	}
};

export default Element;
