import { connect } from 'react-redux';
import { SeeFightRecord as SeeFightRecordComponent } from './SeeFightRecord';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {};
};
export const SeeFightRecord = connect(mapStateToProps, mapDispatchToProps)(SeeFightRecordComponent);
