import { currentSuperEventPage } from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';

import { Confirm as ConfirmComponent } from './Confirm';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		currentSuperEventPage: (data) => dispatch(currentSuperEventPage(data)),
	};
};

export const Confirm = connect(mapStateToProps, mapDispatchToProps)(ConfirmComponent);
