import { nextPageUserData, setUserData } from 'src/redux-state/actions/userProfile';
import { connect } from 'react-redux';
import { UserConnection as UserConnectionComponent } from './UserConnection';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUserData: (data) => dispatch(setUserData(data)),
		nextPageUserData: (data) => dispatch(nextPageUserData(data)),
	};
};

export const UserConnection = connect(mapStateToProps, mapDispatchToProps)(UserConnectionComponent);
