import { Box, Grid } from '@material-ui/core';
import { Left } from './Left';
import { Right } from './Right';

export const FightRecordSec = () => {
	return (
		<Box className="fight-record-sec">
			<Grid container>
				<Grid xs={3} className="fight-record-left-sec">
					<Left />
				</Grid>
				<Grid item xs={9} className="fight-record-right-sec">
					<Right />
				</Grid>
			</Grid>
		</Box>
	);
};
