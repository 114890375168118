import { connect } from 'react-redux';
import { logoutUser } from 'src/redux-state/actions/actions';
import { NavBar as NavBarComponent } from './NavBar';

const mapDispatchToProps = (dispatch) => {
	return {
		logoutUser: (data) => dispatch(logoutUser(data)),
	};
};

export const NavBar = connect(null, mapDispatchToProps)(NavBarComponent);
