import { useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	Grid,
	Typography,
	Popover,
	// List,
	// ListItem,
	// ListItemText,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import Viewer from 'react-viewer';
import { ReactComponent as CorrectIcon } from 'src/assets/images/icons/correct-blue.svg';
import { ReactComponent as VerifiedIcon } from 'src/assets/images/icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'src/assets/images/icons/Unverified User.svg';
import { ReactComponent as StaticsUpIcon } from 'src/assets/images/icons/statics-arrow-up.svg';
import { ReactComponent as StaticsDownIcon } from 'src/assets/images/icons/statics-arrow-down.svg';
import { ReactComponent as CommentIcon } from 'src/assets/images/icons/comment.svg';
import { ReactComponent as ReferralsIcon } from 'src/assets/images/icons/referrals.svg';
import { ReactComponent as SharesIcon } from 'src/assets/images/icons/shares.svg';
import { ReactComponent as ReactionsIcon } from 'src/assets/images/icons/reactions.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/comment-report.svg';
import { Reactions } from '../Reactions';
import { Report } from '../Report';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { DialogBox } from '../DialogBox';
import { Share } from '../Share';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { fetchFromStorage } from 'src/helpers/context';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { DTS } from './DTS';
import { FightRecord } from './FightRecord';
// import PostComment from './Comment';
import { DisciplineRank } from './DisciplineRank';
import './newsfeedPost.scss';
import { identifiers } from 'src/helpers/constants/identifier';
import { useTranslation } from 'react-i18next';
import { FightRecordData } from 'src/pages/application/Events/EventDetails/FightCards/FightRecordData';

export const NewsfeedCommunity = ({
	postedBy,
	time,
	// activityType,
	// communityName,
	addVote,
	topVal,
	downVal,
	comment,
	shares,
	referrals,
	reactions,
	isJoin,
	editAccess,
	addLike,
	data,
	userURI,
	postProfile,
	fetchNewsfeedPostList,
	tab,
	view,
	currentPage,
	user,
	postDetails,
	isSearchPage,
	updateUserData,
	reactionData,
	likedStatus,
	userPage,
	tabmain,
	PostEvent,
	recordData,
	singleItem,
	verifyUser,
}) => {
	const { t } = useTranslation();

	var token = fetchFromStorage(identifiers.authData)?.token;
	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	var tabValue =
		tab === '1'
			? 'best'
			: tab === '2'
			? 'trending'
			: tab === '3'
			? 'date'
			: tab === '4'
			? 'top'
			: tab === '5'
			? 'recommended'
			: 'best';
	// const navigate = useNavigate();
	const [commentClick, setCommentClick] = useState(false);

	// const [anchorEl, setAnchorEl] = useState(null);
	// const open = Boolean(anchorEl);
	// const id = open ? 'simple-popover' : undefined;
	const [opens, setOpen] = useState(false);
	const verificationWall = fetchFromStorage('verificationWall');
	const [anchorElReact, setAnchorElReact] = useState(null);
	const [model, setModel] = useState();
	const [reactionList, setReactionList] = useState([]);
	const [recentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState();
	const [reportReasonList, setReportReasonList] = useState([]);
	const [list] = useState(5);
	const [tabReact, setTabReact] = useState('1');
	const [visible, setVisible] = useState(false);
	// const userId = user?.user_id;

	// const handleClick = event => {
	//     setAnchorEl(event.currentTarget);
	// };
	// const handleClose = () => {
	//     setAnchorEl(null);
	// };

	const combatDialogHandler = (title) => {
		setOpen(true);
		setModel(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const AddReactions = (event) => {
		setAnchorElReact(event.currentTarget);
	};
	const handleCloseReact = () => {
		setAnchorElReact(null);
	};
	const openReact = Boolean(anchorElReact);
	const idReact = openReact ? 'simple-popover' : undefined;
	// const scrooTop = () => {
	//     const anchor = (window.document || document).querySelector(
	//         '#back-to-top-post'
	//     );

	//     if (anchor) {
	//         anchor.scrollIntoView();
	//     }
	// };

	// CREATE DTS ENDORSEMENT
	const createEndorsement = async (requestData, type) => {
		try {
			const { status, data } = await axiosInstance.post(URLS.createEndorsement + `?type=${type}`, requestData);

			if (status === 200) {
				closeDialog();
				setApiMessage('success', data?.message);
				isSearchPage
					? updateUserData(tabmain, userPage, singleItem)
					: fetchNewsfeedPostList(tabValue, view, currentPage);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// DELETE DTS ENDORSEMENT
	const deleteEndorsement = async (requestData, type) => {
		// var isVisitor =
		//     Number(fetchFromStorage(identifiers.visitorUserId)) ===
		//         fetchFromStorage(identifiers.authData)?.user_id
		//         ? 'true'
		//         : '';
		try {
			const { status, data } = await axiosInstance.delete(
				// URLS.deleteEndorsement + `?type=${type}&flag=${isVisitor}`,
				URLS.deleteEndorsement + `?type=${type}`,
				{
					data: requestData,
					headers: authHeader,
				},
			);

			if (status === 200) {
				isSearchPage
					? updateUserData(tabmain, userPage, singleItem)
					: fetchNewsfeedPostList(tabValue, view, currentPage);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			// setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchReactionsLists = async (postId, likeId, cPage) => {
		var metaLikeId = likeId === 0 || likeId === '' || likeId === undefined ? '' : likeId;

		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsList + `${postId}`, {
				params: {
					meta_like_id: metaLikeId,
					page: cPage,
					limit: list,
				},
			});

			if (status === 200) {
				setReactionList(data?.reactionData);
				setTotalRecord(data?.totalRecord);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// CREATE FIGHT ENDORSEMENT
	const createFightContestantEndorsement = async (requestData) => {
		// var requestData = {
		//   user_id: user_id,
		//   event_fight_id: event_fight_id
		// };
		if (verificationWall?.isEndorsement) {
			try {
				const { status, data } = await axiosInstance.post(URLS.createUserEventFightContestantEndorsement, requestData);

				if (status === 200) {
					//   fetchUserFight('', recentPage);
					//   setOpen(false);
					isSearchPage
						? updateUserData(tabmain, userPage, singleItem)
						: fetchNewsfeedPostList(tabValue, view, currentPage);
					setApiMessage('success', data?.message);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		} else {
			setApiMessage('success', 'You must be verified to make this action');
		}
	};
	// DELETE FIGHT ENDORSEMENT
	const deleteFightContestantEndorsement = async (requestData) => {
		try {
			const { status, data } = await axiosInstance.delete(URLS.deleteUserEventFightContestantEndorsement, {
				data: requestData,
				headers: authHeader,
			});

			if (status === 200) {
				isSearchPage
					? updateUserData(tabmain, userPage, singleItem)
					: fetchNewsfeedPostList(tabValue, view, currentPage);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			// setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// PAGINATION FOR ENDORSE USER LIST
	const handlePaginationButtonClicks = async (navDirection) => {
		if (navDirection === 'prev') {
			if (recentPage > 1) {
				setCurrentPage(recentPage - 1);
				fetchReactionsLists(data?.post_id, '', recentPage - 1);
			}
		} else {
			if (recentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(recentPage + 1);
				fetchReactionsLists(data?.post_id, '', recentPage + 1);
			}
		}
	};
	const handlePaginationClicks = async (event, page) => {
		if (recentPage !== page) {
			setCurrentPage(Number(page));
			fetchReactionsLists(data?.post_id, '', Number(page));
		}
	};
	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// DELETE DISCIPLINE ENDORSEMENT RANK
	const deleteUserDisciplineRankEndorsement = async (requestData) => {
		try {
			const { status, data } = await axiosInstance.delete(URLS.deleteUserDisciplineRankEndorsement, {
				data: requestData,
				headers: authHeader,
			});

			if (status === 200) {
				isSearchPage
					? updateUserData(tabmain, userPage, singleItem)
					: fetchNewsfeedPostList(tabValue, view, currentPage);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// CREATE DISCIPLINE ENDORSEMENT
	const createUserDisciplineRankEndorsement = async (requestData) => {
		if (verificationWall?.isEndorsement) {
			try {
				const { status, data } = await axiosInstance.post(URLS.createUserDisciplineRankEndorsement, requestData);

				if (status === 200) {
					//   fetchUserDisciplines('', currentPage);
					isSearchPage
						? updateUserData(tabmain, userPage, singleItem)
						: fetchNewsfeedPostList(tabValue, view, currentPage);
					setApiMessage('success', data?.message);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		} else {
			setApiMessage('success', 'You must be verified to make this action');
		}
	};

	const tabChangeHandler = (event, newValue) => {
		setTabReact(newValue);
		const tabValue = newValue - 1;

		setCurrentPage(1);
		fetchReactionsLists(data?.post_id, tabValue, 1);
	};

	return (
		<>
			<Box className="newsfeed-community-post">
				<Box className="post-header">
					<Box className="header-left">
						<Box className="user-box">
							<>
								<Avatar
									src={postProfile}
									className="user-img"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setVisible(true);
									}}
								/>
								{postProfile !== '' && postProfile !== undefined && postProfile !== null && (
									<Viewer
										visible={visible}
										onClose={() => {
											setVisible(false);
										}}
										images={[
											{
												src: postProfile,
												title: 'cover image',
											},
										]}
										downloadable
										noFooter={true}
										drag={false}
										zoomable={false}
										rotatable={false}
										scalable={false}
										disableMouseZoom={true}
									/>
								)}
							</>
							<Box className="name-time">
								<Box className="user-name-verification-sec">
									<NavLink to={`/user/${userURI}`} className="user-name">
										{postedBy}
									</NavLink>
									{verifyUser ? <VerifiedIcon /> : <UnverifiedIcon />}
								</Box>

								{/* <Typography className="user-name" onClick={() => navigate(`/user/${userURI}`)}>
                                    {postedBy}
                                </Typography> */}
								<Typography className="post-time">{time}</Typography>
							</Box>
						</Box>
						{/* <Typography className="activity-type">
                            {activityType}
                        </Typography>
                        <Typography className="community-name">
                            {communityName}
                        </Typography> */}
					</Box>

					<Box className="header-right">
						{!location.pathname.includes('/user/') && (
							<Box className="stastics-box">
								{/* STASTICS UP VALUE */}
								<Box display="flex" alignItems="center">
									<IconButton
										className={data?.vote_up_status === 'yes' && 'fill-blue'}
										onClick={() => addVote(data, 'vote_up', userPage, singleItem)}
									>
										<StaticsUpIcon />
									</IconButton>
									<Typography className="statics-val up-val">{topVal || 0}</Typography>
								</Box>

								{/* STASTICS DOWN VALUE */}
								<Box display="flex" alignItems="center">
									<IconButton
										className={data?.vote_down_status === 'yes' && 'fill-red'}
										onClick={() => addVote(data, 'vote_down', userPage, singleItem)}
									>
										<StaticsDownIcon className="statics-down-icon" />
									</IconButton>
									<Typography className="statics-val">{downVal || 0}</Typography>
								</Box>
							</Box>
						)}
						<Box
							className="badges-box"
							onClick={() => {
								combatDialogHandler(t('community.reactions'));
								data?.post_id && fetchReactionsLists(data?.post_id, '', recentPage);
							}}
						>
							{data?.MetaLikeLists?.map((item, index) => {
								return <Avatar src={item?.item_data} className="badge-avatar" />;
							})}
						</Box>
					</Box>
				</Box>

				{PostEvent && PostEvent ? (
					recordData &&
					recordData?.map((fightRecords, findex) => {
						var clength = fightRecords?.EventFightContestantsDetails.length;
						var date =
							fightRecords?.EventData?.start_time && moment(fightRecords?.EventData?.start_time).format('Do MMMM YYYY');

						return (
							<Box className="event-fight-post">
								<Typography className="event-title">{date}</Typography>
								<Typography className="event-name">{fightRecords?.EventData?.name}</Typography>
								<Typography className="event-title">Title Fight: {fightRecords?.title_fight}</Typography>
								<Box className="event-fight-record-data fight-sec">
									{fightRecords &&
										fightRecords?.EventFightContestantsDetails?.map((item, index) => {
											return <FightRecordData sideItem={item} lastItem={clength === index + 1 ? false : true} />;
										})}
								</Box>
							</Box>
						);
					})
				) : (
					// <Box>
					//     <Box className='event-fight-left'>
					//         <Box className='event-profile-data'>
					//             <Box className='event-fight-profile-sec'>
					//                 <Avatar className='event-fight-profile' />
					//                 <IconButton className='correct-icon'>
					//                     <CorrectIcon />
					//                 </IconButton>
					//             </Box>
					//             <Typography className='event-fight-name'>Charles Oliveira</Typography>
					//         </Box>
					//         <Typography className='win-text'>Win - R1 3.22 -  Submission (rear-naked choke) </Typography>
					//     </Box>
					//     <Box className='event-fight-center'>
					//         {t('events.def')}
					//     </Box>
					//     <Box className='event-fight-right'>
					//         <Box className='event-profile-data'>
					//             <Typography className='event-fight-name'>Charles Oliveira</Typography>
					//             <Box className='event-fight-profile-sec'>
					//                 <Avatar className='event-fight-profile' />
					//                 <IconButton className='correct-icon'>
					//                     <CorrectIcon />
					//                 </IconButton>
					//             </Box>
					//         </Box>
					//     </Box>
					// </Box>
					<Box className="updated-sec">
						<Typography className="updated-text">
							{data?.profile_update_data?.profile_update_type === 'discpline_rank' && 'Updated their Discipline Rank'}
							{data?.profile_update_data?.profile_update_type === 'endorse' &&
								'Updated their Disciplines, Techniques & Skills'}
							{data?.profile_update_data?.profile_update_type === 'fight_record' && 'Updated their Fight Record'}
						</Typography>
						<Typography className="endorse-text">
							{data?.profile_update_data?.profile_update_type === 'endorse' &&
								`Click the '+' button to endorse ${data?.user_full_name} .`}
							{data?.profile_update_data?.profile_update_type === 'fight_record' &&
								`Click the '+' button to endorse ${data?.user_full_name} .`}
						</Typography>
						<Box className="updated-data-sec">
							{data?.profile_update_data?.profile_update_type === 'discpline_rank' && (
								<DisciplineRank
									data={data}
									createUserDisciplineRankEndorsement={createUserDisciplineRankEndorsement}
									deleteUserDisciplineRankEndorsement={deleteUserDisciplineRankEndorsement}
									user={user}
								/>
							)}
							{data?.profile_update_data?.profile_update_type === 'endorse' && (
								<DTS
									data={data}
									createEndorsement={createEndorsement}
									deleteEndorsement={deleteEndorsement}
									user={user}
								/>
							)}
							{data?.profile_update_data?.profile_update_type === 'fight_record' && (
								<FightRecord
									data={data}
									createFightContestantEndorsement={createFightContestantEndorsement}
									deleteFightContestantEndorsement={deleteFightContestantEndorsement}
									user={user}
								/>
							)}
						</Box>
					</Box>
				)}

				<Box className="post-actions">
					<Grid container className="post-act-btns">
						{/* COMMENTS */}
						{/* {data?.comments === 'yes' && ( */}
						{true && (
							<Grid item>
								<Button
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
									className="act-btn"
									startIcon={<CommentIcon />}
									component={window.location.pathname.includes('/post') ? Button : NavLink}
									to={window.location.pathname.includes('/post') ? null : `/post/${data?.id}`}
									onClick={() => {
										isJoin
											? window.location.pathname.includes('/post') && setCommentClick(!commentClick)
											: window.location.pathname.includes('/post') && setApiMessage('error', 'please join community');
										// navigate(`/post/${data?.id}`, { state: { commentOP: "commentOP" } });
									}}
								>
									{t('community.comment')} ({comment || 0})
								</Button>
							</Grid>
						)}

						{/* SHARES */}
						<Grid item>
							<Button
								variant="contained"
								disableElevation
								disableRipple
								disableFocusRipple
								disableTouchRipple
								className="act-btn"
								startIcon={<SharesIcon />}
								onClick={() => {
									combatDialogHandler(t('community.share'));
								}}
							>
								{t('community.shares')} ({shares || 0})
							</Button>
						</Grid>

						{/* REFERRALS */}
						{/* HIDE-MVP: CM-73 */}
						{/*<Grid item>*/}
						{/*	<Button*/}
						{/*		variant="contained"*/}
						{/*		disableElevation*/}
						{/*		disableRipple*/}
						{/*		disableFocusRipple*/}
						{/*		disableTouchRipple*/}
						{/*		className="act-btn"*/}
						{/*		startIcon={<ReferralsIcon />}*/}
						{/*	>*/}
						{/*		{t('community.referrals')} ({referrals || 0})*/}
						{/*	</Button>*/}
						{/*</Grid>*/}

						{/* REACTIONS */}
						{/* HIDE-MVP: CM-73 */}
						{/*<Grid item>*/}
						{/*	<Popover*/}
						{/*		className="cr-popover reaction-popover"*/}
						{/*		id={idReact}*/}
						{/*		open={openReact}*/}
						{/*		anchorEl={anchorElReact}*/}
						{/*		onClose={handleCloseReact}*/}
						{/*		anchorOrigin={{*/}
						{/*			vertical: 'top',*/}
						{/*			horizontal: 'left',*/}
						{/*		}}*/}
						{/*		transformOrigin={{*/}
						{/*			vertical: 'bottom',*/}
						{/*			horizontal: 'left',*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		{reactionData?.map((item, index) => {*/}
						{/*			return (*/}
						{/*				<Tooltip title={item?.name} arrow>*/}
						{/*					<IconButton*/}
						{/*						className={item?.id === likedStatus ? 'react-icon react-selected' : 'react-icon'}*/}
						{/*						onClick={() => isJoin && addLike(data, item?.id, userPage, singleItem)}*/}
						{/*					>*/}
						{/*						<Avatar src={item?.item_data} className="reaction-img" />*/}
						{/*					</IconButton>*/}
						{/*				</Tooltip>*/}
						{/*			);*/}
						{/*		})}*/}
						{/*	</Popover>*/}
						{/*	<Button*/}
						{/*		variant="contained"*/}
						{/*		disableElevation*/}
						{/*		disableRipple*/}
						{/*		disableFocusRipple*/}
						{/*		disableTouchRipple*/}
						{/*		className="act-btn"*/}
						{/*		startIcon={<ReactionsIcon />}*/}
						{/*		onClick={(e) => isJoin && AddReactions(e)}*/}
						{/*		// onClick={() => isJoin && addLike(data, 'thumbs_up')}*/}
						{/*	>*/}
						{/*		{t('community.reactions')} ({reactions || 0})*/}
						{/*	</Button>*/}
						{/*</Grid>*/}

						{postDetails && postDetails ? (
							<Grid item>
								<Button
									variant="contained"
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
									className="act-btn"
									disabled={!user}
									startIcon={<ReportIcon />}
									onClick={() => {
										combatDialogHandler(t('report'));
									}}
								>
									{t('report')}
								</Button>
							</Grid>
						) : (
							<></>
						)}
					</Grid>

					{/* {((true)) ? (//editAccess || postUserId === userId
                        <> */}
					{/* <Popover
                                className="admin-tools-popover"
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                            >
                                <Box className="admin-tools">
                                    <List>
                                        {editAccess ? (
                                            <ListItem>
                                                <ListItemText
                                                // onClick={() => updateCommunityPost(data, 'yes')}
                                                >
                                                    {t('community.sticky_post')}
                                                </ListItemText>
                                            </ListItem>) : (<></>)
                                        }
                                        <ListItem>
                                            <ListItemText
                                            // onClick={() => {
                                            //     handleClose();
                                            //     updatePostData(data);
                                            //     setTimeout(() => {
                                            //         scrooTop();
                                            //     }, 600);
                                            // }}
                                            >
                                                {t('community.edit_post')}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem
                                        // onClick={() => handleDeletePost(data)}
                                        >
                                            <ListItemText>
                                                {t('community.delete_post')}
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Popover> */}
					{/* <Box className="admin-box">
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disableElevation
                                    disableRipple
                                    disableTouchRipple
                                    disableFocusRipple
                                    className="admin-btn"
                                >
                                    {t('community.admin')}
                                </Button>
                            </Box> */}
					{/* </>
                    ) : null} */}
				</Box>
			</Box>
			{/* <CombatDialog
                className="share-dailog-box"
                open={opens}
                handleClose={closeDialog}
                // title={title}
                content={
                    <Share
                        fromWhere="post/"
                        urlId={data?.id}
                        landType="post"
                        handleClose={closeDialog}
                    />
                }
            /> */}
			<DialogBox
				className={model === 'Share' ? 'share-dailog-box' : model === 'Reactions' ? 'reaction-dialog-box' : ''}
				open={opens}
				handleClose={closeDialog}
				title={model === 'Report' ? t('report') : model === 'Reactions' ? t('community.reactions') : ''}
				content={
					model === 'Share' ? (
						<Share fromWhere="post/" urlId={data?.id} landType="post" handleClose={closeDialog} />
					) : model === 'Report' ? (
						<Report handleClose={closeDialog} reportReasonList={reportReasonList} type="post" receiverId={data?.id} />
					) : model === 'Reactions' ? (
						<Reactions
							reactionList={reactionList}
							fetchReactionsLists={fetchReactionsLists}
							postId={data?.post_id}
							handlePaginationButtonClicks={handlePaginationButtonClicks}
							handlePaginationClicks={handlePaginationClicks}
							totalRecord={totalRecord}
							list={list}
							recentPage={recentPage}
							tabChangeHandler={tabChangeHandler}
							tab={tabReact}
						/>
					) : (
						<></>
					)
				}
			/>
		</>
	);
};
