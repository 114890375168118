import { useEffect, useState, useContext } from 'react';
import { Box, Button, IconButton, makeStyles, Typography, Avatar } from '@material-ui/core';
import { ViewPortContext } from 'src/App';
import * as rdd from 'react-device-detect';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilBlue.svg';
import './left.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { DialogBox } from 'src/components/ui';
import { fetchFromStorage } from 'src/helpers/context';
import { MobileDrawer } from 'src/components/ui';
import Viewer from 'react-viewer';
import { AboutEdit } from './AboutEdit';
import { DrawerContent } from '../MobileBlock/DrawerContent';
import { RecentActivity } from './RecentActivity';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	input: {
		display: 'none',
	},
});

export const Left = ({ userStatus, orgDetails, fetchOrganisationDetails }) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	var orgAbout = orgDetails && orgDetails?.PostLists?.find((data) => data?.Item?.type === 'text');
	// console.log(orgAbout, 'orgDetails');
	const [imageId, setImageId] = useState();
	const [previewImg, setPreviewImg] = useState('');
	const [openDrawer, setOpenDrawer] = useState(false);
	const [visible, setVisible] = useState(false);
	const [allUsersList, setAllUsersList] = useState([]);
	const [recentActivity, setRecentActivity] = useState([]);

	// console.log(orgDetails?.id, "orgDetails")
	const auth = useSelector((state) => state?.Combat?.authData) || fetchFromStorage('authData');

	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	// OPEN OR CLOSE DRAWER
	const toggleDrawer = (open) => {
		setOpenDrawer(open);
	};

	const updateOrganisation = async (imgId) => {
		var requestParams = {
			id: orgDetails?.id,
			item_cover_id: imgId,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateOrganisation, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchOrganisationDetails();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const ImageUploadHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setImageId(data?.data?.id);
				setPreviewImg(data?.data?.location);
				updateOrganisation(data?.data?.id);
			}
		}
	};
	const fetchRecentActivity = async (orgId) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getOrgRecentActivity + `?organisation_id=${orgId}`);

			if (status === 200) {
				setRecentActivity(data?.recent_activity_list);
				// setActivityTotal(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		let splitdata = orgDetails && orgDetails?.item_cover_id?.split('/');

		splitdata = splitdata && splitdata[splitdata.length - 1];
		splitdata && setPreviewImg(splitdata);
		fetchRecentActivity(orgDetails?.id);
	}, [orgDetails]);

	const [open, setOpen] = useState(false);

	const combatDialogHandler = () => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	// FETCH ALL USER LIST FOR TAG PEOPLE
	const fetchAllUsersList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var formattedArray = [];

				filteredData.map((item, i) => {
					var urlType =
						item?.type === 'user'
							? `user/${item?.id}`
							: item?.type === 'community'
							? `communities/${item?.id}`
							: `organisation-details/${item?.id}`;
					var urlValue = urlType;

					const object = {
						text: item?.user_full_name,
						value: item?.user_full_name,
						url: urlValue,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.text !== '');

				setAllUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchAllUsersList();
	}, []);

	return (
		<>
			{/* <Box className="left-organisation-container"> */}
			<Box className="cover-photo-container">
				{/* COVER PHOTO */}
				{/* <input
            accept="image/*"
            id="cover-button-file"
            type="file"
            className={classes.input}
            onChange={ImageUploadHandler}
          />
          <label htmlFor="cover-button-file">
            <Box className="cover-photo-container">
              <Button
                variant="contained"
                endIcon={<CameraIcon />}
                className="upload-button"
                component="span"
              >
                {t('organisation.cover_photo')}
              </Button>
            </Box>
          </label> */}
				{userStatus === 'community_owner' ? (
					previewImg !== '' ? (
						windowWidth > breakPoint ? (
							<>
								<input
									accept="image/*"
									className={classes.input}
									id="cover-button-file"
									type="file"
									onChange={ImageUploadHandler}
								/>
								<label htmlFor="cover-button-file">
									<IconButton variant="contained" component="span">
										{/* <Avatar
                      src={previewImg}
                      variant="square"
                      className="preview-cover ddd"
                    /> */}
										<Avatar
											src={URLS.mediaURL + previewImg}
											alt="profile-picture"
											variant="square"
											className="preview-cover ddd"
										/>
										<CameraIcon className="camera-icon" />
									</IconButton>
								</label>
							</>
						) : (
							<>
								{rdd.isIOS && (
									<input
										accept="image/*"
										className={classes.input}
										id="cover-button-file"
										type="file"
										onChange={ImageUploadHandler}
									/>
								)}
								<label htmlFor={rdd.isIOS && 'cover-button-file'}>
									<IconButton
										variant="contained"
										component="span"
										onClick={
											rdd.isIOS
												? () => {}
												: () => {
														toggleDrawer && toggleDrawer(true);
												  }
										}
									>
										<Avatar src={URLS.mediaURL + previewImg} variant="square" className="preview-cover ddd" />
										<CameraIcon className="camera-icon" />
									</IconButton>
								</label>
							</>
						)
					) : windowWidth > breakPoint ? (
						<>
							<input
								accept="image/*"
								className={classes.input}
								id="cover-button-file"
								type="file"
								onChange={ImageUploadHandler}
							/>
							<label htmlFor="cover-button-file">
								<Button variant="contained" endIcon={<CameraIcon />} className="upload-button" component="span">
									{t('myprofile.cover_photo')}
								</Button>
							</label>
						</>
					) : (
						<>
							{rdd.isIOS && (
								<input
									accept="image/*"
									className={classes.input}
									id="cover-button-file"
									type="file"
									onChange={ImageUploadHandler}
								/>
							)}
							<label htmlFor={rdd.isIOS && 'cover-button-file'}>
								<Button
									variant="contained"
									endIcon={<CameraIcon />}
									className="upload-button"
									component="span"
									onClick={
										rdd.isIOS
											? () => {}
											: () => {
													toggleDrawer && toggleDrawer(true);
											  }
									}
								>
									{t('myprofile.cover_photo')}
								</Button>
							</label>
						</>
					)
				) : (
					<label htmlFor="cover-button-file">
						<IconButton variant="contained" component="span">
							<Avatar
								src={orgDetails?.item_cover_id}
								alt="profile-picture"
								variant="square"
								className="preview-cover ddd"
								onClick={() => {
									setVisible(true);
								}}
							/>
							{orgDetails?.item_cover_id !== '' && (
								<Viewer
									visible={visible}
									onClose={() => {
										setVisible(false);
									}}
									images={[
										{
											src: orgDetails?.item_cover_id,
											title: 'cover image',
										},
									]}
									downloadable
									noFooter={true}
									drag={false}
									zoomable={false}
									rotatable={false}
									scalable={false}
									disableMouseZoom={true}
								/>
							)}
						</IconButton>
					</label>
				)}
			</Box>
			{/* CREATE POST */}
			<Box className="organisation-create-post">
				orgDetails={orgDetails}
				user={auth}
				allUsersList={allUsersList}
				token={token}
				fetchRecentActivity={fetchRecentActivity}
				userStatus={userStatus}
				/>
			</Box>
			{/* ABOUT */}
			<Box className="profile-type-slider org-profile-about">
				<Box className="about-tab">
					<Box className="title-block">
						<Typography className="tab-block-title">{t('organisation.about')}</Typography>
						{userStatus === 'community_owner' ? (
							<Box>
								<Box display={'flex'}>
									<IconButton onClick={combatDialogHandler}>
										<BpencilIcon />
									</IconButton>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
					<Typography className="about_content">{orgAbout?.Item?.data}</Typography>
				</Box>
			</Box>

			{/* RECENT ACTIVITY */}
			<Box className="profile-type-slider org-profile-activity">
				<Box className="about-tab">
					{/* <Box className="title-block"> */}
					{/* <Typography className="tab-block-title">
              {t('organisation.recent_activity')}
            </Typography> */}
					{/* </Box> */}
					<RecentActivity
						recentActivityData={recentActivity}
						user={auth}
						fetchRecentActivity={fetchRecentActivity}
						orgDetails={orgDetails}
						// hasMore={hasMore}
					/>
					{/* <Typography className="no-info-txt">
            {t('organisation.no_info_txt')}
          // </Typography> */}
				</Box>
			</Box>

			{/* Combat Dialog window */}
			<DialogBox
				open={open}
				handleClose={closeDialog}
				title={t('myprofile.edit_about')}
				className="org-pageownner-dialog"
				content={
					<AboutEdit
						fetchOrganisationDetails={fetchOrganisationDetails}
						aboutData={orgAbout?.Item?.data}
						orgDetails={orgDetails}
						handleClose={closeDialog}
					/>
				}
			/>

			{/* Mobile page, drawer from bottom */}
			<MobileDrawer
				anchor={'bottom'}
				open={openDrawer}
				onClose={() => toggleDrawer(false)}
				className="take-upload-photo"
				title={t('myprofile.upload_profile_cover_image')}
				content={<DrawerContent imgHandler={ImageUploadHandler} toggleDrawer={toggleDrawer} />}
			/>
			{/* </Box> */}
		</>
	);
};
