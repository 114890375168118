import { connect } from 'react-redux';
import { authUser } from 'src/redux-state/actions/actions';
import { Login as LoginComponent } from './Login';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (data) => dispatch(authUser(data)),
	};
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
