import { Avatar, Box, Typography } from '@material-ui/core';
// import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilBlue.svg';
import './related.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Related = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	return (
		<Box className="community-related-box">
			<Box className="related-header">
				<Typography className="related-title">{t('community.related')}</Typography>
				{/* {isView ? null : (
          <IconButton>
            <BpencilIcon />
          </IconButton>
        )} */}
			</Box>
			{communityDetails && communityDetails?.related_community_data?.length ? (
				<>
					{communityDetails?.related_community_data.map((item, index) => {
						{
							/* console.log('communityDetails', item) */
						}

						return (
							<Box className="related-content" key={index}>
								<Box className="community-details">
									<Avatar className="community-avatar" src={item?.item_profile_data} />
									<Box className="community-name-date">
										<Typography
											className="community-name"
											onClick={() => {
												navigate(`/communities/${item?.id}`);
											}}
										>
											{item?.name}
										</Typography>
										<Typography className="community-joindate">
											{t('community.created')}:{' '}
											<span>{moment(item?.timestamp_created).format('MMM DD YYYY, h:mm')}</span>
										</Typography>
									</Box>
								</Box>
							</Box>
						);
					})}
				</>
			) : (
				<Typography className="no-data-txt">{t('community.no_related_community')}</Typography>
			)}
		</Box>
	);
};
