import { useState, useContext, useEffect } from 'react';
import { Avatar, Button, Box, Container, IconButton, makeStyles, Typography, Tab, Popover } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Skeleton from '@material-ui/lab/Skeleton';
import { Page, DialogBox, Report, Share, InviteModal, DeleteModal } from 'src/components/ui';
import { useSelector, connect } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import Viewer from 'react-viewer';
import { ReactComponent as ProfileUploadIcon } from 'src/assets/images/icons/community/user-image.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
import { ReactComponent as VerifiedBadgeIcon } from 'src/assets/images/icons/community/com-verified.svg';
import { ReactComponent as MsgIcon } from 'src/assets/images/icons/com-msg.svg';
import { ReactComponent as ShareIcon } from 'src/assets/images/icons/com-share.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/whiteDelete.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/com-report.svg';
import { ReactComponent as UnlockIcon } from 'src/assets/images/icons/unlock.svg';
import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
import './community-view.scss';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { identifiers } from 'src/helpers/constants/identifier';
import { RightSide } from './RightSide';
import { LeftSide } from './LeftSide';
import Community from '../../../../assets/images/seo/community.png';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

export const CommunityView = ({ getCommunityDetails, getCommunityPostList }) => {
	const location = useLocation();
	const query = parse(location.search);
	const verificationWall = fetchFromStorage('verificationWall');

	const classes = useStyles();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	const auth = useSelector((state) => state.Combat.authData);
	const user = auth ? auth : fetchFromStorage('authData');
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	// console.log('user', user?.user_id)
	const [coverImg, setCoverImg] = useState('');
	const [previewImg, setPreviewImg] = useState('');
	const [selected, setSelected] = useState('discussions');
	const [loader, setLoader] = useState(false);
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('hello');
	const [model, setModel] = useState('');
	const [isView, setIsView] = useState(false);
	const [isJoin, setIsJoin] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [reportReasonList, setReportReasonList] = useState([]);
	const [selREQ, setSelREQ] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [communityAccess, setCommunityAccess] = useState(true);
	const [visible, setVisible] = useState(false);
	const [visibleProfile, setVisibleProfile] = useState(false);

	const list = [
		{
			id: 1,
			name: t('community.discussions'),
			value: 'discussions',
		},
		{ id: 2, name: t('community.about'), value: 'about' },
		{ id: 3, name: t('community.members'), value: 'members' },
		// MVP-HIDE: CM-107
		// { id: 4, name: t('community.events'), value: 'events' },
		// { id: 5, name: t('community.related'), value: 'related' },
	];
	const mobileViewList = [
		{
			id: 1,
			name: t('community.discussions'),
			value: 'discussions',
		},
		{ id: 2, name: t('community.about'), value: 'about' },
		{ id: 3, name: t('community.members'), value: 'members' },
		// MVP-HIDE: CM-107
		// { id: 4, name: t('community.events'), value: 'events' },
		// { id: 5, name: t('community.related'), value: 'related' },
		// { id: 6, name: t('community.cat_and_dis'), value: 'cat_dis' },
		// { id: 7, name: t('myprofile.badges'), value: 'badges' },
		{ id: 8, name: t('community.settings'), value: 'settings' },
	];
	// console.log("dhruvit state values", location, location.state);
	// if (location.state !== null) {
	//   if ((location.state.showSelectedTab) === 'member') {
	//     if (selected === 'discussions') {
	//       setSelected('members');
	//       setSelREQ(true);
	//     }
	//   }
	// }

	// console.log('hshd', selREQ);
	const tabChangeHandler = (event, newValue) => {
		setSelected(newValue);
	};

	// DIALOG HANDLER
	const combatDialogHandler = (title, modal) => {
		setOpen(true);
		setTitle(title);
		setModel(modal);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	const opens = Boolean(anchorEl);
	const ids = opens ? 'simple-popover' : undefined;
	// FETCH COMMUNITY DETAILS
	const fetchCommunityDetails = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCommunityDetails + `/${id}`);

			if (status === 200) {
				if (!Object.keys(data.data).length) {
					navigate(`/communities`);
				}

				getCommunityDetails(data?.data);
				data?.data?.communityData?.item_profile_data && setPreviewImg(data?.data?.communityData?.item_profile_data);
				data?.data?.communityData?.item_cover_id && setCoverImg(data?.data?.communityData?.item_cover_data);
				data?.data?.communityData && setIsView(!data?.data?.communityData?.edit_access);
				data?.data?.communityData?.invite_status === 'sent' && setModel('invite');
				setIsJoin(
					Number(fetchFromStorage('authData')?.user_id) === Number(data?.data?.communityData?.user_owner_id)
						? true
						: data?.data?.communityData?.join_status === 'accepted'
						? true
						: false,
				);
				setIsOwner(
					Number(fetchFromStorage('authData')?.user_id) === Number(data?.data?.communityData?.user_owner_id)
						? true
						: false,
				);
				var community_Access = data?.data?.communityData?.guest_access === 'public' ? true : false;

				fetchFromStorage('authData') === null && setCommunityAccess(community_Access);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH COMMUNITY POST
	const fetchCommunityPostList = async (orderBy) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCommunityPostList + `/${id}?order_by=${orderBy}`);

			if (status === 200) {
				getCommunityPostList(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// console.log(location?.state?.passUserId, "ddfdfdf");
	// if (location?.state?.passUserId) {
	//   setTitle(t('community.community_invitation'));
	// }
	const fetchAcceptInvitationData = async (rstatus) => {
		const requestParameter = {
			community_id: Number(id),
			status: rstatus,
			from_userId: communityDetails?.communityData?.user_owner_id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.acceptInvitation, requestParameter);

			if (status === 200) {
				setOpen(false);
				fetchCommunityDetails();
				closeDialog();
				// acceptInvitation(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FOR REFERRAL
	const referralUse = async () => {
		var requestParameter = {
			referral_code: query?.referralcode,
			land_type: identifiers?.referralType?.community,
			land_id: id,
			user_id: user?.user_id,
		};

		if (query?.referralcode !== undefined) {
			try {
				const { status, data } = await axiosInstance.post(URLS.referralUse, requestParameter);

				if (status === 200) {
					// console.log(data);
				}
			} catch (error) {
				// TODO: Add logging
			}
		}
	};

	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchCommunityDetails();
		fetchCommunityPostList('date');

		// eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		// referralUse();
		fetchReportReasonList();
		// communityDetails?.communityData?.join_status === null && communityDetails?.communityData?.invite_status === 'sent' && setOpen(true);
		// location?.state?.passUserId && communityDetails?.communityData?.invite_status === 'sent' && setOpen(true);
		communityDetails?.communityData?.invite_status === 'sent' && setOpen(true);
		// eslint-disable-next-line
	}, [communityDetails]);

	useEffect(() => {
		location?.state?.showSelectedTab && setSelected(location?.state?.showSelectedTab);
		location?.state?.showNestedTab && setSelREQ(location?.state?.showNestedTab);
		var notiType = location?.search.split('=');

		location?.search.includes('type') && notiType[1] === 'accept_request' && setSelected('members');
	}, [location?.state?.showSelectedTab, location?.state?.showNestedTab]);

	// UPDATE COVER IMAGE
	const updateCommunity = async (pId, key, path) => {
		var requestParams = {
			community: {
				id: Number(id),
				[key]: pId,
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateCommunity, requestParams);

			if (status === 200) {
				data?.status && key === 'item_profile_id' && setPreviewImg(URLS.mediaURL + path);
				data?.status && key === 'item_cover_id' && setCoverImg(URLS.mediaURL + path);
				fetchCommunityDetails();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchCommunityClose = async (data) => {
		var reqStatus =
			model === 'closeCommuniy'
				? data === 'accepted' && 'closed'
				: model === 'openCommuniy'
				? data === 'accepted' && 'active'
				: '';
		var requestParams = {
			status: reqStatus,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.closeCommunity + `${id}`, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchCommunityDetails();
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// COVER IMAGE HANDLER
	const coverHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				setLoader(true);
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					//   let id = data?.data?.itemId || data?.data?.id;
					setLoader(false);
					// setCoverImg(URLS.mediaURL + data?.data?.location);
					updateCommunity(data?.data?.id, 'item_cover_id', data?.data?.location);
				}
			} catch (error) {
				setLoader(false);
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	const deleteCommunity = async () => {
		try {
			setLoader(true);
			const { status, message } = await axiosInstance.delete(
				`${URLS.deleteCommunity}/${communityDetails.communityData.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			);

			if (status === 200) {
				setLoader(false);
				navigate(`/communities`);
				setApiMessage('success', message);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// USER IMAGE UPLOAD HANDLER
	const ImageUploadHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				// setPreviewImg(URLS.mediaURL + data?.data?.location);
				updateCommunity(data?.data?.id, 'item_profile_id', data?.data?.location);
				// setPreviewImg(data?.data?.location);
			}
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const communityAction = async (data, status) => {
		var requestParams = {
			user_id: fetchFromStorage('authData')?.user_id,
			community_id: data?.id,
			status: status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.joinCommunity, requestParams);

			if (status === 200) {
				fetchCommunityDetails();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Page
			className={classes.root}
			title={'Community Page'}
			description={
				"Community: Your friend shared this community with you for a reason! Get in there and see what's going on!"
			}
			image={Community}
		>
			<Container maxWidth="lg">
				<Box className="community-view combat-container">
					{communityAccess ? (
						<>
							{/* COMMUNITY COVER IMAGE */}
							<Box className="cover-photo-container">
								{loader ? (
									<Skeleton variant="rect" className="preview-cover" />
								) : isView || communityDetails?.communityData?.status === 'closed' ? (
									// COVER IMAGE
									<>
										<Avatar
											src={coverImg}
											variant="square"
											className="preview-cover"
											onClick={() => {
												setVisible(true);
											}}
										/>
										{coverImg !== '' && (
											<Viewer
												visible={visible}
												onClose={() => {
													setVisible(false);
												}}
												images={[
													{
														src: coverImg,
														title: 'cover image',
													},
												]}
												downloadable
												noFooter={true}
												drag={false}
												zoomable={false}
												rotatable={false}
												scalable={false}
												disableMouseZoom={true}
											/>
										)}
									</>
								) : coverImg !== '' ? (
									<>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="cover-button-file"
											type="file"
											onChange={coverHandler}
										/>
										<label htmlFor="cover-button-file">
											<IconButton variant="contained" component="span">
												<Avatar src={coverImg} variant="square" className="preview-cover" />
												<CameraIcon className="camera-icon" />
											</IconButton>
										</label>
									</>
								) : (
									<>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="cover-button-file"
											type="file"
											onChange={coverHandler}
										/>
										<label htmlFor="cover-button-file">
											<Button variant="contained" endIcon={<CameraIcon />} className="upload-button" component="span">
												{t('myprofile.cover_photo')}
											</Button>
										</label>
									</>
								)}
							</Box>

							{/* COMMUNITY NAME */}
							<Box className="community-img-member">
								<Box display="flex">
									<Box className="comm-image">
										{isView || communityDetails?.communityData?.status === 'closed' ? (
											<>
												<Avatar
													src={previewImg}
													style={{ cursor: 'pointer' }}
													className="community-avatar-img"
													onClick={() => {
														setVisibleProfile(true);
													}}
												/>
												{previewImg !== '' && (
													<Viewer
														visible={visibleProfile}
														onClose={() => {
															setVisibleProfile(false);
														}}
														images={[
															{
																src: previewImg,
																title: 'cover image',
															},
														]}
														downloadable
														noFooter={true}
														drag={false}
														zoomable={false}
														rotatable={false}
														scalable={false}
														disableMouseZoom={true}
													/>
												)}
											</>
										) : previewImg !== '' ? (
											<>
												<input
													accept="image/*"
													style={{ display: 'none' }}
													id="icon-button-file"
													type="file"
													onChange={ImageUploadHandler}
												/>
												<label htmlFor="icon-button-file">
													<IconButton
														color="primary"
														aria-label="upload picture"
														component="span"
														style={{ position: 'relative' }}
													>
														<Avatar src={previewImg} alt="profile-picture" />
														<CameraIcon className="camera-icon" />
													</IconButton>
												</label>
											</>
										) : (
											<>
												<input
													accept="image/*"
													style={{ display: 'none' }}
													id="icon-button-file"
													type="file"
													onChange={ImageUploadHandler}
												/>
												<label htmlFor="icon-button-file">
													<IconButton color="primary" aria-label="upload picture" component="span">
														<ProfileUploadIcon />
														<CameraIcon className="camera-icon" />
													</IconButton>
												</label>
											</>
										)}
									</Box>
									<Box display="flex" alignItems="center">
										<Typography className="community-name">
											{communityDetails && communityDetails?.communityData?.name} <VerifiedBadgeIcon />
										</Typography>
									</Box>
								</Box>
								<Box className="hide-959" justifyContent="end" display="flex" alignItems="center">
									<Typography className="community-member">
										{t('community.members')}: <span>{communityDetails?.communityData?.total_member}</span>
									</Typography>
									{isView ? (
										<>
											<Button
												variant="contained"
												className="cmt-join-btn"
												disabled={communityDetails?.communityData?.status === 'closed' ? true : false}
												onClick={(e) => {
													communityDetails?.communityData?.join_status === 'accepted'
														? communityAction(communityDetails?.communityData, 'leave')
														: communityDetails?.communityData?.join_status === 'sent'
														? handleClick(e)
														: verificationWall?.joinCommunity
														? communityAction(communityDetails?.communityData, 'sent')
														: setApiMessage('success', 'You must be verified to make this action');
												}}
											>
												{communityDetails?.communityData?.join_status === 'accepted'
													? t('community.leave_community')
													: communityDetails?.communityData?.join_status === 'sent'
													? t('community.join_req_pending')
													: t('community.join')}
											</Button>
											<Popover
												className="cr-popover"
												id={ids}
												open={opens}
												anchorEl={anchorEl}
												onClose={handleClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
												}}
											>
												<Button
													onClick={() => {
														handleClose();
														communityAction(communityDetails?.communityData, 'leave');
													}}
												>
													{t('community.cancel_request')}
												</Button>
											</Popover>
										</>
									) : null}
								</Box>
							</Box>

							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								className="show-959-flex show-599-block"
								style={{ marginBottom: 9 }}
							>
								{isView ? (
									<>
										<Button
											variant="contained"
											className="cmt-join-btn"
											disabled={communityDetails?.communityData?.status === 'closed' ? true : false}
											onClick={(e) => {
												communityDetails?.communityData?.join_status === 'accepted'
													? communityAction(communityDetails?.communityData, 'leave')
													: communityDetails?.communityData?.join_status === 'sent'
													? handleClick(e)
													: verificationWall?.joinCommunity
													? communityAction(communityDetails?.communityData, 'sent')
													: setApiMessage('success', 'You must be verified to make this action');
											}}
										>
											{communityDetails?.communityData?.join_status === 'accepted'
												? t('community.leave_community')
												: communityDetails?.communityData?.join_status === 'sent'
												? t('community.join_req_pending')
												: t('community.join')}
										</Button>
										<Popover
											className="cr-popover"
											id={ids}
											open={opens}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}
										>
											<Button
												onClick={() => {
													handleClose();
													communityAction(communityDetails?.communityData, 'leave');
												}}
											>
												{t('community.cancel_request')}
											</Button>
										</Popover>
									</>
								) : null}
								<Typography className="community-member">
									{t('community.members')}: <span>{communityDetails?.communityData?.total_member}</span>
								</Typography>

								<Box className="com-block-actions">
									{user.isAdmin && (
										<IconButton onClick={() => combatDialogHandler(t('community.delete_community'), 'delete')}>
											<DeleteIcon />
										</IconButton>
									)}
									{user && (
										<IconButton>
											<ShareIcon
												onClick={() => {
													communityDetails?.communityData?.status !== 'closed' &&
														combatDialogHandler(t('community.share'), 'share');
												}}
											/>
										</IconButton>
									)}
									{/* MVP-HIDE: CM-77 */}
									{/*<IconButton*/}
									{/*	onClick={() => {*/}
									{/*		combatDialogHandler(t('report'), 'report');*/}
									{/*	}}*/}
									{/*>*/}
									{/*	<ReportIcon />*/}
									{/*</IconButton>*/}
									{isOwner && (
										<>
											{communityDetails && communityDetails?.communityData?.status === 'closed' ? (
												<IconButton
													onClick={() => {
														combatDialogHandler(t('community.reopen_community'), 'openCommuniy');
													}}
												>
													<LockIcon />
												</IconButton>
											) : (
												<IconButton
													onClick={() => {
														combatDialogHandler(t('community.close_community'), 'closeCommuniy');
													}}
												>
													<UnlockIcon />
												</IconButton>
											)}
										</>
									)}
								</Box>
							</Box>

							{/* DISCUSSIONS | ABOUT | MEMBERS | EVENTS | RELATED */}
							<Box className="damer-container hide-breakpoint">
								<Box className="damer-buttons">
									<TabContext value={selected}>
										<Box>
											<TabList
												variant="scrollable"
												scrollButtons="off"
												onChange={tabChangeHandler}
												aria-label="action tabs"
											>
												{list?.map((obj, index) => {
													return <Tab key={index} label={obj.name} value={obj?.value} />;
												})}
											</TabList>
										</Box>
									</TabContext>
								</Box>
								<Box className="com-block-actions hide-959">
									{/* MESSAGE */}
									{/* <IconButton>
                <MsgIcon />
              </IconButton> */}
									{/* SHARE */}
									{user.isAdmin && (
										<IconButton onClick={() => combatDialogHandler(t('community.delete_community'), 'delete')}>
											<DeleteIcon />
										</IconButton>
									)}
									{user && (
										<IconButton>
											<ShareIcon
												onClick={() => {
													communityDetails?.communityData?.status !== 'closed' &&
														combatDialogHandler(t('community.share'), 'share');
												}}
											/>
										</IconButton>
									)}

									{/* REPORT */}
									{/* MVP-HIDE: CM-77 */}
									{/*{user && (*/}
									{/*	<IconButton*/}
									{/*		onClick={() => {*/}
									{/*			communityDetails?.communityData?.status !== 'closed' &&*/}
									{/*				combatDialogHandler(t('report'), 'report');*/}
									{/*		}}*/}
									{/*	>*/}
									{/*		<ReportIcon />*/}
									{/*	</IconButton>*/}
									{/*)}*/}

									{isOwner && (
										<>
											{communityDetails && communityDetails?.communityData?.status === 'closed' ? (
												<IconButton
													onClick={() => {
														combatDialogHandler(t('community.reopen_community'), 'openCommuniy');
													}}
												>
													<LockIcon />
												</IconButton>
											) : (
												<IconButton
													onClick={() => {
														combatDialogHandler(t('community.close_community'), 'closeCommuniy');
													}}
												>
													<UnlockIcon />
												</IconButton>
											)}
										</>
									)}
								</Box>
							</Box>
							<Box className="community-right show-breakpoint">
								{/* SHOW MIN-0 TO MAX-1279PX */}
								<RightSide
									loader={loader}
									token={token}
									user={user}
									isView={isView}
									isJoin={isJoin}
									fetchCommunityDetails={fetchCommunityDetails}
								/>
							</Box>
							<Box className="damer-container  show-breakpoint">
								<Box className="damer-buttons">
									<TabContext value={selected}>
										<Box>
											<TabList
												variant="scrollable"
												scrollButtons="off"
												onChange={tabChangeHandler}
												aria-label="action tabs"
											>
												{mobileViewList?.map((obj, index) => {
													return <Tab key={index} label={obj.name} value={obj?.value} />;
												})}
											</TabList>
										</Box>
									</TabContext>
								</Box>
							</Box>
							<Box className="community-grid-container">
								<Box className="community-left">
									<LeftSide
										loader={loader}
										token={token}
										user={user}
										selected={selected}
										selREQ={selREQ ? selREQ : ''}
										isView={isView}
										isJoin={isJoin}
										fetchCommunityDetails={fetchCommunityDetails}
										fetchCommunityPostList={fetchCommunityPostList}
										id={id}
									/>
								</Box>
								<Box className="community-right hide-breakpoint">
									{/* SHOW AFTER MIN 1280PX */}
									<RightSide
										loader={loader}
										token={token}
										user={user}
										isView={isView}
										isJoin={isJoin}
										fetchCommunityDetails={fetchCommunityDetails}
									/>
								</Box>
							</Box>
						</>
					) : (
						<Typography className="community-not-available-text">This community is not publically available</Typography>
					)}
				</Box>
			</Container>

			<DialogBox
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					model === 'share' ? (
						<Share fromWhere="communities/" urlId={id} landType="community" handleClose={closeDialog} />
					) : model === 'report' ? (
						<Report handleClose={closeDialog} reportReasonList={reportReasonList} type="community" receiverId={id} />
					) : model === 'closeCommuniy' ? (
						<InviteModal model={model} fetchAcceptInvitationData={fetchCommunityClose} handleClose={closeDialog} />
					) : model === 'openCommuniy' ? (
						<InviteModal model={model} fetchAcceptInvitationData={fetchCommunityClose} handleClose={closeDialog} />
					) : model === 'invite' ? (
						<InviteModal fetchAcceptInvitationData={fetchAcceptInvitationData} />
					) : model === 'delete' ? (
						<DeleteModal onClick={deleteCommunity} handleClose={closeDialog} />
					) : (
						<InviteModal fetchAcceptInvitationData={fetchAcceptInvitationData} />
					)
				}
			/>
		</Page>
	);
};
