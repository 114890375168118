import { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	Typography,
	withStyles,
	TextField,
	Button,
	InputAdornment,
	Avatar,
	IconButton,
	Chip,
	ClickAwayListener,
} from '@material-ui/core';
import { getFields } from 'src/helpers/commonFunctions';
import CancelIcon from '@material-ui/icons/Cancel';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { ReactComponent as ApproveMemberIcon } from 'src/assets/images/icons/community/approveicon.svg';
import { ReactComponent as RemoveMemberIcon } from 'src/assets/images/icons/community/rejecticon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { ReactComponent as FilterIcon } from 'src/assets/images/icons/community/filter.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as UpArrowIcon } from 'src/assets/images/icons/up-icon.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { MultipleSelectCheckmarks } from 'src/components/ui';
import './eventAdmin.scss';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const EventAdmins = ({
	user,
	create_data,
	eventStatus,
	eventAdmin,
	fetchEventUserCommunityOrg,
	eventAdminList,
}) => {
	const { t } = useTranslation();

	const [searchValUser, setSearchValUser] = useState('');
	const [searchValCom, setSearchValCom] = useState('');
	const [searchValOrg, setSearchValOrg] = useState('');
	const [selectedProfileType, setSelectedProfileType] = useState([]);
	const [dUserList, setDisplyUser] = useState(false);
	const [dComList, setDisplyCom] = useState(false);
	const [dOrgList, setDisplyOrg] = useState(false);
	const [checkedUser, setCheckedUser] = useState([]);
	const [checkedCom, setCheckedCom] = useState([]);
	const [checkedOrg, setCheckedOrg] = useState([]);
	const [checkedAllUser, setCheckedAllUser] = useState([]);
	const [checkedAllCom, setCheckedAllCom] = useState([]);
	const [checkedAllOrg, setCheckedAllOrg] = useState([]);
	const [submitAdmin, setSubmitAdmin] = useState(false);
	const [eventAdminAllData, setEventAdminData] = useState([]);
	const [profileTypeQS, setProfileTypeQs] = useState();

	let { event_admin, event_fight_card, event_status, event_details, event_invite, current_tab } = create_data;
	const handleChangeUser = (e) => {
		setSearchValUser(e.target.value);
		fetchEventUserCommunityOrg(1, e.target.value, profileTypeQS);
		setDisplyUser(true);
	};
	const handleChangeCom = (e) => {
		setSearchValCom(e.target.value);
		fetchEventUserCommunityOrg(2, e.target.value, '');
		setDisplyCom(true);
	};
	const handleChangeOrg = (e) => {
		setSearchValOrg(e.target.value);
		fetchEventUserCommunityOrg(3, e.target.value, '');
		setDisplyOrg(true);
	};
	const restTypeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];
	const onProfileTypeSelect = (data) => {
		// setSelectedProfileType(data);
		const currentIndex = selectedProfileType.indexOf(data);
		const newChecked = [...selectedProfileType];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedProfileType(newChecked);
	};
	const handleUser = () => {
		fetchEventUserCommunityOrg(1, searchValUser, profileTypeQS);
		dUserList ? setDisplyUser(false) : setDisplyUser(true);
	};

	const memberAddAsAdmin = (value) => {
		const currentIndex = checkedUser.indexOf(value?.id);
		const newChecked = [...checkedUser];
		const objChecked = [...checkedAllUser];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setCheckedUser(newChecked);
		setCheckedAllUser(objChecked);
	};
	const addCommunityEvent = (value) => {
		const currentIndex = checkedCom.indexOf(value?.id);
		const newChecked = [...checkedCom];
		const objChecked = [...checkedAllCom];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setCheckedCom(newChecked);
		setCheckedAllCom(objChecked);
	};
	const addOrgEvent = (value) => {
		const currentIndex = checkedOrg.indexOf(value?.id);
		const newChecked = [...checkedOrg];
		const objChecked = [...checkedAllOrg];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setCheckedOrg(newChecked);
		setCheckedAllOrg(objChecked);
	};

	const handleCom = () => {
		fetchEventUserCommunityOrg(2, searchValCom, '');
		dComList ? setDisplyCom(false) : setDisplyCom(true);
	};
	const hnadleOrg = () => {
		fetchEventUserCommunityOrg(3, searchValOrg, '');
		dOrgList ? setDisplyOrg(false) : setDisplyOrg(true);
	};
	const handleUserFilter = () => {
		var queryParams = {
			...(selectedProfileType.length > 0 && {
				profile_type: selectedProfileType.join(','),
			}),
		};

		setProfileTypeQs(queryParams);
		fetchEventUserCommunityOrg(1, searchValUser, queryParams);
	};
	const adminSubmit = () => {
		setSubmitAdmin(true);
	};

	useEffect(() => {
		EventEmitter.subscribe('submit_admin', adminSubmit);
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		var userArray = [user];

		if (checkedAllUser) {
			for (let j = 0; j < checkedAllUser.length; j++) {
				userArray.push(checkedAllUser[j]);
			}
		}

		var comArray = [];

		if (checkedAllCom) {
			for (let j = 0; j < checkedAllCom.length; j++) {
				comArray.push(checkedAllCom[j]);
			}
		}

		var orgArray = [];

		if (checkedAllOrg) {
			for (let j = 0; j < checkedAllOrg.length; j++) {
				orgArray.push(checkedAllOrg[j]);
			}
		}

		if (submitAdmin === true) {
			setEventAdminData([
				{
					receiver_type: 'user',
					receiver_id: userArray,
				},
				{
					receiver_type: 'community',
					receiver_id: comArray,
				},
				{
					receiver_type: 'organisation',
					receiver_id: orgArray,
				},
			]);
			eventAdminAllData && eventAdmin(eventAdminAllData);
		}
	}, [submitAdmin === true, eventAdminAllData]);
	useEffect(() => {
		var allUser = event_admin && event_admin.filter((data) => data.receiver_type === 'user');
		var allCom = event_admin && event_admin.filter((data) => data.receiver_type === 'community');
		var allOrg = event_admin && event_admin.filter((data) => data.receiver_type === 'organisation');

		var userData =
			allUser &&
			allUser[0]?.receiver_id &&
			allUser[0]?.receiver_id?.filter((item) => item?.id !== undefined && item?.id !== user?.user_id);
		var userIds = userData && getFields(userData, 'id');

		// userData && setDisplyUser(true);
		userData && setCheckedAllUser(userData);
		userData && setCheckedUser(userIds);
		// allCom && setDisplyCom(true);
		var comIds = allCom && allCom[0]?.receiver_id && getFields(allCom[0]?.receiver_id, 'id');

		allCom && setCheckedCom(comIds);
		allCom && setCheckedAllCom(allCom[0]?.receiver_id);
		// allOrg && setDisplyOrg(true);

		var orgIds = allOrg && allOrg[0]?.receiver_id && getFields(allOrg[0]?.receiver_id, 'id');

		allOrg && setCheckedOrg(orgIds);
		allOrg && setCheckedAllOrg(allOrg[0]?.receiver_id);
	}, [event_admin]);
	useEffect(() => {
		if (event_status === 'draft' && current_tab === 3) {
			eventStatus('published');
			var userArray = [user];

			if (checkedAllUser) {
				for (let j = 0; j < checkedAllUser.length; j++) {
					userArray.push(checkedAllUser[j]);
				}
			}

			var comArray = [];

			if (checkedAllCom) {
				for (let j = 0; j < checkedAllCom.length; j++) {
					comArray.push(checkedAllCom[j]);
				}
			}

			var orgArray = [];

			if (checkedAllOrg) {
				for (let j = 0; j < checkedAllOrg.length; j++) {
					orgArray.push(checkedAllOrg[j]);
				}
			}

			var userAdminPassData = [
				{
					receiver_type: 'user',
					receiver_id: userArray,
				},
				{
					receiver_type: 'community',
					receiver_id: comArray,
				},
				{
					receiver_type: 'organisation',
					receiver_id: orgArray,
				},
			];
			var eventAllData = {
				event_details: event_details,
				event_admin: userAdminPassData,
				event_invite: event_invite,
				event_fight_card: event_fight_card,
			};

			EventEmitter.dispatch('create_draft_event', eventAllData);
		}
	}, [event_status]);

	return (
		<Box className="event-admin-sec">
			<Typography className="admins-title">{t('events.admins')}</Typography>
			<Box className="event-user-sec">
				<Typography className="admins-title admins-sub-title">{t('events.add_event_admins')}</Typography>
				<Box className="search-field-box">
					<SearchTextField
						onChange={handleChangeUser}
						fullWidth
						value={searchValUser}
						placeholder={t('search.title')}
						size="small"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end" className="search-dropdown" onClick={() => handleUser()}>
									{dUserList ? <UpArrowIcon /> : <DownArrowIcon />}
								</InputAdornment>
							),
						}}
					/>
				</Box>
				{dUserList && (
					<Box className="search-adminlist-sec">
						{checkedAllUser?.length > 0 && (
							<Box className="restriction-box">
								1
								<Box className="restriction-chips">
									{checkedAllUser?.map((data, index) => {
										return (
											<Grid item key={index} className="rest-chip">
												<Chip
													size="small"
													className="checked-chip"
													deleteIcon={<CancelIcon />}
													label={data?.user_name}
													onDelete={() => memberAddAsAdmin(data)}
													// onClick={() => onMemberSelect(data)}
													// onClick={handleClick}
												/>
											</Grid>
										);
									})}
								</Box>
							</Box>
						)}
						<ClickAwayListener onClickAway={() => setDisplyUser(false)}>
							<Box>
								{eventAdminList &&
									eventAdminList?.map((adminUser, index) => {
										const filteredArray = restTypeList?.filter((value) =>
											adminUser?.profile_types?.includes(value?.id),
										);

										return (
											<Box className="search-admin-list">
												<Box className="search-admin-left">
													<Avatar src={adminUser?.item_profile_data} className="search-profile" />
													<Box className="search-profile-sec">
														<Typography className="search-user-name">{adminUser?.user_name}</Typography>
														<Typography className="search-user-data">
															{filteredArray
																.map(function (elem) {
																	return elem.name;
																})
																.join(', ')}
														</Typography>
													</Box>
												</Box>
												<Box className="search-admin-right">
													<Button
														disableRipple
														disableFocusRipple
														disableTouchRipple
														edge="end"
														className={
															checkedUser.indexOf(adminUser?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '
														}
														onClick={() => memberAddAsAdmin(adminUser)}
														startIcon={
															checkedUser.indexOf(adminUser?.id) !== -1 ? <RemoveMemberIcon /> : <ApproveMemberIcon />
														}
													>
														{checkedUser.indexOf(adminUser?.id) !== -1
															? t('community.remove_as_admin')
															: t('community.add_as_admin')}
													</Button>
												</Box>
											</Box>
										);
									})}
							</Box>
						</ClickAwayListener>
					</Box>
				)}
				<Box className="filter-box">
					<Grid container className="filter-tags">
						<Box style={{ width: '3%' }}>
							<FilterIcon />
						</Box>
						<Box style={{ width: '97%' }} className="filter-options">
							<MultipleSelectCheckmarks
								labelValue={t('profile_type.pts')}
								count={selectedProfileType?.length}
								// labelValue={selectedProfileType.length > 0 ? arrayJoin(restTypeList, selectedProfileType, 'name') : t('profile_type.pts')}
								dropdownArray={restTypeList}
								onSelect={onProfileTypeSelect}
								checked={selectedProfileType}
							/>
						</Box>
					</Grid>
					<Box className="filter-right-buttons">
						<Button
							type="submit"
							className="filter-btn"
							onClick={() => {
								handleUserFilter();
							}}
						>
							{t('button_text.apply_filter')}
						</Button>
					</Box>
				</Box>
				<Box className="user-box">
					<Typography className="user-name-title">{t('events.user')}</Typography>
					<Box className="selected-user-sec">
						<Box className="users-data">
							<Box className="user-data-sec">
								<Avatar src={user?.profile_pic} className="user-profile" />
								<Typography className="user-name">{user?.OauthUserData?.user_name}</Typography>
							</Box>
						</Box>
						{checkedAllUser &&
							checkedAllUser?.map((data, index) => {
								return (
									<Box className="users-data">
										<Box className="user-data-sec">
											<Avatar src={data?.item_profile_data} className="user-profile" />
											<Typography className="user-name">{data?.user_name}</Typography>
										</Box>
										<IconButton className="user-delete-icon" onClick={() => memberAddAsAdmin(data)}>
											<DeleteIcon />
										</IconButton>
									</Box>
								);
							})}
					</Box>
				</Box>
			</Box>
			<Box className="event-community-sec">
				<Typography className="admins-title admins-sub-title">{t('events.add_event_to_community')}</Typography>
				<Typography className="admin-title-text">{t('events.add_community')}</Typography>
				<Box className="search-field-box">
					<SearchTextField
						onChange={handleChangeCom}
						fullWidth
						value={searchValCom}
						placeholder={t('search.title')}
						size="small"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end" className="search-dropdown" onClick={() => handleCom()}>
									{dComList ? <UpArrowIcon /> : <DownArrowIcon />}
								</InputAdornment>
							),
						}}
					/>
				</Box>

				{dComList && (
					<Box className="search-adminlist-sec">
						{checkedAllCom?.length > 0 && (
							<Box className="restriction-box">
								<Box className="restriction-chips">
									{checkedAllCom?.map((data, index) => {
										return (
											<Grid item key={index} className="rest-chip">
												<Chip
													size="small"
													className="checked-chip"
													deleteIcon={<CancelIcon />}
													label={data?.name}
													onDelete={() => addCommunityEvent(data)}
													// onClick={() => onMemberSelect(data)}
													// onClick={handleClick}
												/>
											</Grid>
										);
									})}
								</Box>
							</Box>
						)}
						<ClickAwayListener onClickAway={() => setDisplyCom(false)}>
							<Box>
								{eventAdminList &&
									eventAdminList.map((community, index) => {
										return (
											<Box className="search-admin-list">
												<Box className="search-admin-left">
													<Avatar src={community?.item_profile_data} className="search-profile" />
													<Box className="search-profile-sec">
														<Typography className="search-user-name">{community?.name}</Typography>
													</Box>
												</Box>
												<Box className="search-admin-right">
													<Button
														disableRipple
														disableFocusRipple
														disableTouchRipple
														edge="end"
														className={
															checkedCom.indexOf(community?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '
														}
														onClick={() => addCommunityEvent(community)}
														startIcon={
															checkedCom.indexOf(community?.id) !== -1 ? <RemoveMemberIcon /> : <ApproveMemberIcon />
														}
													>
														{checkedCom.indexOf(community?.id) !== -1
															? t('community.remove_as_admin')
															: t('community.add_as_admin')}
													</Button>
												</Box>
											</Box>
										);
									})}
							</Box>
						</ClickAwayListener>
					</Box>
				)}
				<Box className="user-box">
					<Typography className="user-name-title">{t('events.community')}</Typography>
					<Box className="selected-user-sec">
						{checkedAllCom &&
							checkedAllCom?.map((data, index) => {
								return (
									<Box className="users-data">
										<Box className="user-data-sec">
											<Avatar src={data?.item_profile_data} className="user-profile" />
											<Typography className="user-name">{data?.name}</Typography>
										</Box>
										<IconButton className="user-delete-icon" onClick={() => addCommunityEvent(data)}>
											<DeleteIcon />
										</IconButton>
									</Box>
								);
							})}
					</Box>
				</Box>
			</Box>
			<Box className="event-org-sec">
				<Typography className="admins-title admins-sub-title">{t('events.add_event_to_organisation')}</Typography>
				<Typography className="admin-title-text">{t('events.add_organisation')}</Typography>
				<Box className="search-field-box">
					<SearchTextField
						onChange={handleChangeOrg}
						fullWidth
						value={searchValOrg}
						placeholder={t('search.title')}
						size="small"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end" className="search-dropdown" onClick={() => hnadleOrg()}>
									{dOrgList ? <UpArrowIcon /> : <DownArrowIcon />}
								</InputAdornment>
							),
						}}
					/>
				</Box>
				{dOrgList && (
					<Box className="search-adminlist-sec">
						{checkedAllOrg?.length > 0 && (
							<Box className="restriction-box">
								<Box className="restriction-chips">
									{checkedAllOrg?.map((data, index) => {
										return (
											<Grid item key={index} className="rest-chip">
												<Chip
													size="small"
													className="checked-chip"
													deleteIcon={<CancelIcon />}
													label={data?.name}
													onDelete={() => addOrgEvent(data)}
													// onClick={() => onMemberSelect(data)}
													// onClick={handleClick}
												/>
											</Grid>
										);
									})}
								</Box>
							</Box>
						)}
						<ClickAwayListener onClickAway={() => setDisplyOrg(false)}>
							<Box>
								{eventAdminList &&
									eventAdminList.map((Org, index) => {
										return (
											<Box className="search-admin-list">
												<Box className="search-admin-left">
													<Avatar src={Org?.item_profile_data} className="search-profile" />
													<Box className="search-profile-sec">
														<Typography className="search-user-name">{Org?.name}</Typography>
													</Box>
												</Box>
												<Box className="search-admin-right">
													<Button
														disableRipple
														disableFocusRipple
														disableTouchRipple
														edge="end"
														className={
															checkedOrg.indexOf(Org?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '
														}
														onClick={() => addOrgEvent(Org)}
														startIcon={
															checkedOrg.indexOf(Org?.id) !== -1 ? <RemoveMemberIcon /> : <ApproveMemberIcon />
														}
													>
														{checkedOrg.indexOf(Org?.id) !== -1
															? t('community.remove_as_admin')
															: t('community.add_as_admin')}
													</Button>
												</Box>
											</Box>
										);
									})}
							</Box>
						</ClickAwayListener>
					</Box>
				)}
				<Box className="user-box">
					<Typography className="user-name-title">{t('events.organisation')}</Typography>
					<Box className="selected-user-sec">
						{checkedAllOrg &&
							checkedAllOrg?.map((data, index) => {
								return (
									<Box className="users-data">
										<Box className="user-data-sec">
											<Avatar src={data?.item_profile_data} className="user-profile" />
											<Typography className="user-name">{data?.name}</Typography>
										</Box>
										<IconButton className="user-delete-icon" onClick={() => addOrgEvent(data)}>
											<DeleteIcon />
										</IconButton>
									</Box>
								);
							})}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
