import { useEffect, useState } from 'react';
import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ReactComponent as BOWIcon } from 'src/assets/images/icons/reactions/BOW.svg';
import { ReactComponent as DABIcon } from 'src/assets/images/icons/reactions/DAB.svg';
import { ReactComponent as FistBumpIcon } from 'src/assets/images/icons/reactions/Fist_Bump.svg';
import { ReactComponent as OSSIcon } from 'src/assets/images/icons/reactions/OSS.svg';
import { ReactComponent as PakMeiIcon } from 'src/assets/images/icons/reactions/Pak_Mei.svg';
import { ReactComponent as PakMeiSaluteIcon } from 'src/assets/images/icons/reactions/Pak_Mei_Salute.svg';
import { ReactComponent as ShakaIcon } from 'src/assets/images/icons/reactions/Shaka.svg';
import { ReactComponent as WaiIcon } from 'src/assets/images/icons/reactions/Wai.svg';
import { CustomPaginations } from '../CustomPaginations';
import { ReactedData } from './ReactedData';
import './reactions.scss';
import { useTranslation } from 'react-i18next';

export const Reactions = ({
	fetchReactionsLists,
	postId,
	reactionList,
	handlePaginationButtonClicks,
	handlePaginationClicks,
	totalRecord,
	list,
	recentPage,
	tabChangeHandler,
	tab,
	// setCurrentPagevalue
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		fetchReactionsLists(postId, '', recentPage);
	}, []);

	return (
		<Box className="reaction-sec">
			<TabContext value={String(tab)}>
				<Box>
					<TabList
						variant="scrollable"
						scrollButtons="off"
						onChange={tabChangeHandler}
						aria-label="icon position tabs example"
					>
						<Tab className="tab-sec" iconPosition="start" label={t('reactions.all')} value="1" />
						<Tab className="tab-sec" icon={<ShakaIcon />} iconPosition="start" label={t('reactions.shaka')} value="2" />
						<Tab
							className="tab-sec"
							icon={<FistBumpIcon />}
							iconPosition="start"
							label={t('reactions.fist_bump')}
							value="3"
						/>
						<Tab className="tab-sec" icon={<OSSIcon />} iconPosition="start" label={t('reactions.oss')} value="4" />
						<Tab className="tab-sec" icon={<BOWIcon />} iconPosition="start" label={t('reactions.bow')} value="5" />
						<Tab className="tab-sec" icon={<DABIcon />} iconPosition="start" label={t('reactions.dab')} value="6" />
						<Tab
							className="tab-sec"
							icon={<PakMeiIcon />}
							iconPosition="start"
							label={t('reactions.pak_mei')}
							value="7"
						/>
						<Tab
							className="tab-sec"
							icon={<PakMeiSaluteIcon />}
							iconPosition="start"
							label={t('reactions.pak_mei_salute')}
							value="8"
						/>
						<Tab className="tab-sec" icon={<WaiIcon />} iconPosition="start" label={t('reactions.wai')} value="9" />
					</TabList>
				</Box>
				<TabPanel value="1" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="2" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="3" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								verified={item?.veritication_status}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="4" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="5" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="6" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="7" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="8" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
				<TabPanel value="9" className="reaction-tabpanel-sec">
					{reactionList.map((item, index) => {
						return (
							<ReactedData
								name={item?.user_name}
								profileType={item?.type_data}
								profile={item?.item_profile_data}
								likeId={item?.meta_like_id}
								likedImg={item?.item_data}
							/>
						);
					})}
					<CustomPaginations
						paginationArray={reactionList}
						currentPage={recentPage}
						list={list}
						handlePaginationButtonClick={handlePaginationButtonClicks}
						handlePaginationClick={handlePaginationClicks}
						totalRecord={totalRecord}
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
};
