import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';

const PostSkeleton = () => {
	return (
		<Box sx={{ marginTop: '20px', width: '100%' }}>
			<div style={{display: 'flex', alignItems: 'center', gap: '10px', width: '100%'}}>
				<Skeleton variant="circle" width={50} height={50} />
				<Skeleton variant="text" height={30} width={'20%'} />
			</div>
			<Skeleton variant="text" height={'50px'} />
			<Skeleton variant="text" />
			<Skeleton variant="text" />
			<Skeleton variant="text" />
			<Skeleton variant="rect" style={{ borderRadius: '5px' }} height={'300px'} />
		</Box>
	);
};

export default PostSkeleton;
