import { getCommunitiesDirectoryList } from 'src/redux-state/actions/actions';
import { connect } from 'react-redux';

import { AllCommunityDirectory as AllCommunityDirectoryComponent } from './AllCommunityDirectory';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunitiesDirectoryList: (data) => dispatch(getCommunitiesDirectoryList(data)),
	};
};

export const AllCommunityDirectory = connect(mapStateToProps, mapDispatchToProps)(AllCommunityDirectoryComponent);
