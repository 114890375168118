import { useContext, useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Grid, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField } from 'src/components/ui';
import './email.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';

export const Email = ({ getAccountSettingData, setNewEmailHandler, token, closeDialog }) => {
	const auth = useSelector((state) => state.Combat.authData);
	const { t } = useTranslation();
	const [otp, setOtp] = useState(true);
	const [newEmail, setNewEmail] = useState('');
	const userData = fetchFromStorage('authData');
	const formikRef = useRef();
	// let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const resendOtp = async () => {
		try {
			let { status, data } = await axiosInstance.post(URLS.resendProfOTP, {
				email: newEmail,
			});

			if (status === 200) {
				setApiMessage('success', data.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// const handleSubmit = () => {
	//     if (Email1 === Email2) {
	//         // const fetchAccountSettingData = async () => {
	//         //     try {
	//         //         const { status, data } = await axiosInstance.put(URLS.getAccountSettingData);
	//         //         if (status === 200) {
	//         //             getAccountSettingData(data?.data);
	//         //         }
	//         //     } catch (error) { }

	//         // }
	//         // useEffect(() => {

	//         //     fetchAccountSettingData();
	//         // }, []);

	//         const fetchAccountSettingData = async () => {
	//             console.log("fun call")
	//             let requestData = {
	//                 email: { Email1 }
	//             }
	//             try {
	//                 const { status } = await axiosInstance.put(
	//                     URLS.getAccountSettingData,
	//                     requestData
	//                 );
	//                 if (status === 200) {
	//                     setApiMessage(
	//                         'success', ("ssssss"))
	//                 }
	//             } catch (error) { }
	//         }

	//     }
	//     else {

	//     }
	// }
	// useEffect(() => {

	//     if (formikRef.current) {

	//     }
	// }, []);

	return otp ? (
		<Formik
			innerRef={formikRef}
			initialValues={{
				email1: '',
				email2: '',
			}}
			validationSchema={Yup.object().shape({
				email1: Yup.string().email(t('validation.valid_email')).max(255).required(t('validation.required_message')),

				email2: Yup.string()
					.email('Must be a valid email')
					.when('email1', {
						is: (val) => (val && val.length > 0 ? true : false),
						then: Yup.string().oneOf([Yup.ref('email1')], 'Email and Confirm new email'),
					}),
			})}
			onSubmit={async (datas) => {
				let requestData = {
					email: datas.email1,
				};

				try {
					const { status, data } = await axiosInstance.put(URLS.getAccountSettingData, requestData, authHeader);

					if (status === 200) {
						setApiMessage('success', data?.message);
						setOtp(false);
						setNewEmail(datas?.email1);
					}
				} catch (error) {
					setApiMessage('error', error?.response?.data?.message);
				}
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
				<form onSubmit={handleSubmit}>
					<Box className="changeemail-box">
						<Grid item xs={12} md={12} className="text-field-grid">
							<TextField
								error={Boolean(touched.email1 && errors.email1)}
								fullWidth
								helperText={touched.email1 && errors.email1}
								variant="filled"
								InputProps={{
									endAdornment: touched.email1 && errors.email1 ? <ErrorIcon /> : null,
								}}
								placeholder={t('acount_setting.Enter_new_email')}
								label={t('acount_setting.Enter_new_email')}
								size="small"
								name="email1"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email1}
							/>
						</Grid>
						<Grid item xs={12} md={12} className="text-field-grid">
							<TextField
								error={Boolean(touched.email2 && errors.email2)}
								fullWidth
								helperText={touched.email2 && errors.email2}
								variant="filled"
								InputProps={{
									endAdornment: touched.email2 && errors.email2 ? <ErrorIcon /> : null,
								}}
								placeholder={t('acount_setting.Re_enter_new_email')}
								label={t('acount_setting.Re_enter_new_email')}
								size="small"
								name="email2"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email2}
							/>
						</Grid>

						<Grid item xs={12} md={12}>
							<Button variant="contained" fullWidth type="submit" className="change-btn">
								{t('acount_setting.change')}
							</Button>
						</Grid>
					</Box>
				</form>
			)}
		</Formik>
	) : (
		<Box className="changeemail-box">
			<Typography className="otp-text-sec">{t('acount_setting.OTP_text')}</Typography>

			<Formik
				initialValues={{
					otp: '',
				}}
				validationSchema={Yup.object().shape({
					otp: Yup.string().min(4, t('resetpassword.invalid_otp')).required(t('validation.required_message')),
				})}
				onSubmit={async (datas) => {
					let requestData = {
						email: newEmail,
						otp: datas.otp,
					};

					try {
						let { status, data } = await axiosInstance.post(URLS.otpVerifyForUpdateProfile, requestData);

						if (status === 200) {
							setApiMessage('success', data?.message);
							closeDialog();
							saveToStorage('authData', { ...userData, email: newEmail });
							setNewEmailHandler(newEmail);
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.otp && errors.otp)}
									fullWidth
									helperText={touched.otp && errors.otp}
									variant="filled"
									InputProps={{
										endAdornment: touched.otp && errors.otp ? <ErrorIcon /> : null,
									}}
									label={t('verify_otp.otp')}
									placeholder={t('verify_otp.otp')}
									className="matrix-textfield"
									size="small"
									name="otp"
									onBlur={handleBlur}
									onChange={(e) => {
										const re = /^[0-9\b]+$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);
										}
									}}
									inputProps={{ maxLength: 4 }}
									value={values.otp}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<Typography onClick={resendOtp} className="resend-otp-link">
									{t('acount_setting.Resend_OTP')}
								</Typography>
							</Grid>
							<Grid item xs={12} md={12}>
								<Button variant="contained" fullWidth type="submit" className="change-btn">
									{t('acount_setting.SUBMIT')}
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
