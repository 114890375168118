import { useEffect } from 'react';
import { Box, Avatar, List, ListItem, ListItemText, Typography, Button } from '@material-ui/core';
import './fightstyle.scss';
import { useNavigate } from 'react-router-dom';
import { CustomPaginations } from 'src/components/ui';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useTranslation } from 'react-i18next';

export const FightingStyleModal = ({
	deleteFightContestantEndorsement,
	fetchEventFightContestantUsers,
	fightContestant,
	currentPage,
	fromWhere,
	limit,
	handlePaginationButtonClick,
	handlePaginationClick,
	totalRecord,
	closeDialog,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		{ id: 128, name: t('profile_type.gym') },
		{ id: 256, name: t('profile_type.promotion') },
		{ id: 512, name: t('profile_type.product_service') },
	];

	useEffect(() => {
		fetchEventFightContestantUsers(currentPage);
		// eslint-disable-next-line
	}, []);

	var flag =
		Number(fetchFromStorage(identifiers.visitorUserId)) !== fetchFromStorage(identifiers.authData)?.user_id
			? true
			: false;

	return (
		<>
			<Box className="fightstyle-main">
				<Box className="fightstyle-inner">
					<List>
						{fightContestant && fightContestant.length ? (
							<>
								{fightContestant.map((item, index) => {
									const filteredArray = typeList.filter((value) => item?.profile_types.includes(value?.id));
									// DISCIPLINE RANK ID
									var disRankRequestData = {
										user_id: item?.user_id,
										user_discipline_rank_id: item?.user_discipline_rank_id,
										user_verified_by_id: item?.user_verified_by_id,
									};

									// FIGHT PARAMETERS
									var fightsRequestData = {
										user_id: item?.user_id,
										event_fight_id: item?.event_fight_id,
										user_verified_by_id: item?.user_verified_by_id,
									};
									// DISCIPLINE PARAMETERS
									var disciplineReqData = {
										user_id: flag ? item?.user_id : item?.user_verified_by_id,
										discipline_id: item?.discipline_id,
									};
									// FIGHT METHODS PARAMETERS
									var fightMethodReqData = {
										user_id: flag ? item?.user_id : item?.user_verified_by_id,
										meta_fight_method_id: item?.meta_fight_method_id,
									};
									// SKILLS PARAMETERS
									var skillsReqData = {
										user_id: flag ? item?.user_id : item?.user_verified_by_id,
										meta_skills_id: item?.meta_skills_id,
									};
									// USER TYPE PARAMETERS
									var userTypeReqData = {
										user_id: flag ? item?.user_id : item?.user_verified_by_id,
										profile_type: item?.type,
									};
									var requestData =
										fromWhere === 'disciplinerank'
											? disRankRequestData
											: fromWhere === 'fightrecords'
											? fightsRequestData
											: fromWhere === 'discipline'
											? disciplineReqData
											: fromWhere === 'method'
											? fightMethodReqData
											: fromWhere === 'skill'
											? skillsReqData
											: fromWhere === 'specialities'
											? userTypeReqData
											: fightsRequestData;

									return (
										<ListItem key={index}>
											<Avatar className="fighter-image" src={item?.item_profile_id} />
											<Box className="fighter-intro">
												<ListItemText>
													<Typography
														variant="span"
														className="fighter-name"
														onClick={() => {
															navigate(`/user/${item?.user_verified_by_id}`);
															closeDialog();
														}}
													>
														{`${item?.first_name} ${item?.last_name} ${
															item?.fighter_user_full_name ? '(' + item?.fighter_user_full_name + ')' : ''
														}`}
													</Typography>
													<Typography variant="span" className="fighter-skills">
														{filteredArray
															.map(function (elem) {
																return elem.name;
															})
															.join(', ')}
													</Typography>
												</ListItemText>

												{(Number(fetchFromStorage(identifiers.visitorUserId)) ===
													Number(fetchFromStorage(identifiers.authData)?.user_id) ||
													item?.user_verified_by_id === Number(fetchFromStorage(identifiers.authData)?.user_id)) && (
													<Button
														variant="contained"
														className="remove-button"
														onClick={() => deleteFightContestantEndorsement(requestData)}
													>
														{t('button_text.remove_endorsement')}
													</Button>
												)}
											</Box>
										</ListItem>
									);
								})}
							</>
						) : (
							<Typography className="no-data-txt">No data found</Typography>
						)}
					</List>
				</Box>
				<CustomPaginations
					paginationArray={fightContestant}
					currentPage={currentPage}
					list={limit}
					handlePaginationButtonClick={handlePaginationButtonClick}
					handlePaginationClick={handlePaginationClick}
					totalRecord={totalRecord}
				/>
			</Box>
		</>
	);
};
