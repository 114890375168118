import { getCommunityPostList } from 'src/redux-state/actions/actions';
import { connect } from 'react-redux';
import { CommunityPostView as CommunityPostViewComponent } from './CommunityPostView';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunityPostList: (data) => dispatch(getCommunityPostList(data)),
	};
};

export const CommunityPostView = connect(mapStateToProps, mapDispatchToProps)(CommunityPostViewComponent);
