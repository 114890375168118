import { useContext } from 'react';
import '../fight.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Container, Grid, Button, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { TextField } from 'src/components/ui';

export const AddSpecialities = ({
	isUpdate,
	itemData,
	fetchDisciplineData,
	disciplineData,
	currentPage,
	closeDialog,
}) => {
	const { t } = useTranslation();

	const addSpecialities = async (data) => {
		const requestParams = {
			parent_id: data?.parentcategory,
			name: data?.specialityname,
			// name_short: data?.shortname,
			description: data?.specialitydescr,
			...(isUpdate && { dispId: itemData?.id }),
		};
		const method = isUpdate ? 'put' : 'post';
		const apiEndPoint = isUpdate ? 'updateDiscipline' : 'createDiscipline';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchDisciplineData(currentPage);
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Box className="form-content">
						<Formik
							initialValues={{
								parentcategory: itemData?.parent_id !== null ? itemData?.parent_id : '',
								specialityname: itemData?.name !== null ? itemData?.name : '',
								// shortname:  itemData?.name !== null ? itemData?.name : '',
								specialitydescr: itemData?.description !== null ? itemData?.description : '',
							}}
							validationSchema={Yup.object().shape({
								parentcategory: Yup.string().required(t('validation.required_message')),
								specialityname: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => addSpecialities(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.parentcategory && errors.parentcategory)}
												fullWidth
												helperText={touched.parentcategory && errors.parentcategory}
												variant="filled"
												InputProps={{
													endAdornment: touched.parentcategory && errors.parentcategory ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_speciality.parentspeciality')}
												placeholder={t('admin_panel.combat_speciality.parentspeciality')}
												className="matrix-textfield"
												size="small"
												name="parentcategory"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.parentcategory}
												select
											>
												{disciplineData.map((item, index) => {
													return (
														<MenuItem key={index} value={item?.id}>
															{item?.name}
														</MenuItem>
													);
												})}
											</TextField>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.specialityname && errors.specialityname)}
												fullWidth
												helperText={touched.specialityname && errors.specialityname}
												variant="filled"
												placeholder={t('admin_panel.combat_speciality.specialityname')}
												label={t('admin_panel.combat_speciality.specialityname')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.specialityname && errors.specialityname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="specialityname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.specialityname}
											/>
										</Grid>
										{/* <Grid item xs={12} md={12} className="text-field-grid">
                                            <TextField
                                                error={Boolean(touched.shortname && errors.shortname)}
                                                fullWidth
                                                helperText={touched.shortname && errors.shortname}
                                                variant="filled"
                                                placeholder={
                                                    t('admin_panel.combat_speciality.shortname')
                                                }
                                                label={
                                                    t('admin_panel.combat_speciality.shortname')
                                                }
                                                className="matrix-textfield"
                                                InputProps={{
                                                    endAdornment:
                                                        touched.shortname && errors.shortname ? (
                                                            <ErrorIcon />
                                                        ) : null
                                                }}
                                                size="small"
                                                name="shortname"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.shortname}
                                            />
                                        </Grid> */}
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.specialitydescr && errors.specialitydescr)}
												fullWidth
												helperText={touched.specialitydescr && errors.specialitydescr}
												variant="filled"
												placeholder={t('admin_panel.combat_speciality.specialitydescr')}
												label={t('admin_panel.combat_speciality.specialitydescr')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.specialitydescr && errors.specialitydescr ? <ErrorIcon /> : null,
												}}
												size="small"
												name="specialitydescr"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.specialitydescr}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};
