import { useState, useEffect, useContext } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import { Page } from 'src/components/ui';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as ActiveIcon } from 'src/assets/images/icons/active_icon.svg';
import { ReactComponent as CompleteIcon } from 'src/assets/images/icons/complete_icon.svg';
import { PersonalDetails } from './PersonalDetails';
import { ProfileType } from './ProfileType';
import { Confirm } from './Confirm';
import './profile.scss';

const useStyles = makeStyles({
	root: {
		height: '100%',
	},
});

export const Profile = ({
	data,
	authUser,
	profileDetails,
	profileTypes,
	signupOrgCheck,
	setUpOrg,
	additionDetails,
	profileConfirm,
}) => {
	const classes = useStyles();
	const token = data?.authData?.token || fetchFromStorage('authData')?.token;
	const authData = data?.authData || fetchFromStorage('authData');

	const navigate = useNavigate();
	const { type } = useParams();
	const { t } = useTranslation();
	let setupOrganisationCheck = data?.setup_org_check;
	const [selectedProfile, setSelectedProfile] = useState(0);
	const [page1, setPage1] = useState(false);
	const [page2, setPage2] = useState(false);
	const [page3, setPage3] = useState(false);
	const [page4, setPage4] = useState(false);

	// OnChange Navigate Form
	const changeSelectedProfile = (index) => {
		switch (index) {
			case 0:
				navigate('/profile/details');
				break;
			case 1:
				navigate('/profile/type');
				break;
			case 2:
				navigate('/profile/organisation');
				break;
			case 3:
				navigate('/profile/optional');
				break;
			case 4:
				navigate('/profile/confirm');
				break;

			default:
				break;
		}
	};

	const setProfilePage = (profile) => {
		switch (profile) {
			case 'details':
				setSelectedProfile(0);
				break;
			case 'type':
				setSelectedProfile(1);
				break;
			case 'organisation':
				setSelectedProfile(2);
				break;
			case 'confirm':
				setSelectedProfile(4);
				break;
			case 'optional':
				setSelectedProfile(3);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (data?.profile_details) {
			setPage1(true);
		}

		if (data?.profile_type) {
			setPage2(true);
		}

		if (data?.setup_org) {
			setPage3(true);
		}

		if (data?.additional_details) {
			setPage4(true);
		}
	}, [data]);

	useEffect(() => {
		setProfilePage(type);

		// eslint-disable-next-line
	}, [type]);

	useEffect(() => {
		setSelectedProfile(0);
		navigate('/profile/details');

		// eslint-disable-next-line
	}, []);

	// Form Render
	const renderProfileForms = (type) => {
		switch (type) {
			case 0:
				return <PersonalDetails profileDetails={profileDetails} data={data} token={token} authData={authData} />;
			case 1:
				return <ProfileType signupOrgCheck={signupOrgCheck} profileTypes={profileTypes} data={data} token={token} />;
			// HIDE-MVP: CM-79
			// case 2:
			// 	return <SetUpOrganisation setUpOrg={setUpOrg} dataa={data} token={token} />;
			// HIDE-MVP: CM-98
			// case 3:
			// 	return <AdditionalDetails dataa={data} additionDetails={additionDetails} token={token} />;
			case 4:
				return <Confirm dataa={data} authUser={authUser} profileConfirm={profileConfirm} token={token} />;
			default:
				break;
		}
	};

	// Page Title
	const PageTitle =
		selectedProfile === 0
			? t('signup_profile.details')
			: selectedProfile === 1
			? t('signup_profile.type')
			: selectedProfile === 2
			? t('signup_profile.picture')
			: selectedProfile === 3
			? t('additional_details.optional_details')
			: t('signup_profile.confirm');

	// Background Image
	const PageBanner =
		selectedProfile === 0
			? 'profile-page-1'
			: selectedProfile === 1
			? 'profile-page-2'
			: selectedProfile === 2
			? 'profile-page-3'
			: 'profile-page-4';
	// Different page should have different description
	const chooseDescription = (selectedProfile) => {
		switch (selectedProfile) {
			case 0:
				return t('additional_details.optional_details');
			case 1:
				return t('signup_profile.profile_type_des');
			case 2:
				return (
					<>
						{t('signup_profile.org_details_des_top')}
						<br />
						<br />
						{t('signup_profile.org_details_des_bottom')}
					</>
				);
			case 3:
				return t('signup_profile.addtional_details_des');
			case 4:
				return t('signup_profile.confirm_des');
			default:
				return t('signup_profile.description');
		}
	};

	return (
		<Page className={classes.root} title={PageTitle}>
			<Box className={`profile-page-container ${PageBanner}`}>
				<Container maxWidth="lg">
					<Grid container className="profile-grid-container">
						<Grid item xs={12} sm={12} md={12} lg={5} className="profile-left-grid">
							<Box className="personal-details-headblock">
								<Typography className="title">{t('signup_profile.welcome_combat')}</Typography>

								<Typography className="profile-description">
									{/* {t('signup_profile.description')} */}
									{chooseDescription(selectedProfile)}
								</Typography>
							</Box>

							<Box className="profile-tab-menu">
								<List>
									{/* Profile Details */}
									<ListItem
										button
										selected={selectedProfile === 0}
										// onClick={() => {
										//   changeSelectedProfile(0);
										// }}
										disableelevation="true"
										disablefocusripple="true"
										disableripple="true"
									>
										<ListItemIcon>
											{selectedProfile === 0 ? <ActiveIcon /> : page1 ? <CompleteIcon /> : null}
										</ListItemIcon>
										<ListItemText primary={t('signup_profile.details')} />
									</ListItem>

									{/* Profile Type */}
									<ListItem
										button
										selected={selectedProfile === 1}
										// onClick={() => {
										//   data?.profile_details && changeSelectedProfile(1);
										// }}
									>
										<ListItemIcon>
											{selectedProfile === 1 ? <ActiveIcon /> : page2 ? <CompleteIcon /> : null}
										</ListItemIcon>
										<ListItemText primary={t('signup_profile.type')} />
									</ListItem>

									{/* Organisation Details */}
									{setupOrganisationCheck && (
										<ListItem
											button
											selected={selectedProfile === 2}
											// onClick={() => {
											//   data?.profile_type && changeSelectedProfile(2);
											// }}
										>
											<ListItemIcon>
												{selectedProfile === 2 ? <ActiveIcon /> : page3 ? <CompleteIcon /> : null}
											</ListItemIcon>
											<ListItemText primary={t('signup_profile.org_details')} />
										</ListItem>
									)}
									{/* HIDE-MVP: CM-98*/}
									{/*<ListItem*/}
									{/*	button*/}
									{/*	selected={selectedProfile === 3}*/}
									{/*	// onClick={() => {*/}
									{/*	//   if (setupOrganisationCheck) {*/}
									{/*	//     data?.setup_org && changeSelectedProfile(3);*/}
									{/*	//   } else if (data?.additional_details) {*/}
									{/*	//     changeSelectedProfile(3);*/}
									{/*	//   } else {*/}
									{/*	//     data?.profile_type && changeSelectedProfile(3);*/}
									{/*	//   }*/}
									{/*	// }}*/}
									{/*>*/}
									{/*	<ListItemIcon>*/}
									{/*		{selectedProfile === 3 ? <ActiveIcon /> : page4 ? <CompleteIcon /> : null}*/}
									{/*	</ListItemIcon>*/}
									{/*	<ListItemText primary={t('additional_details.optional_details')} />*/}
									{/*</ListItem>*/}

									{/* Confirm Profile */}
									<ListItem
										button
										selected={selectedProfile === 4}
										// onClick={() => {
										//   data?.profile_details &&
										//     data?.profile_type &&
										//     data?.profile_picture &&
										//     changeSelectedProfile(4);
										// }}
									>
										<ListItemIcon>{selectedProfile === 4 ? <ActiveIcon /> : null}</ListItemIcon>
										<ListItemText primary={t('signup_profile.confirm')} />
									</ListItem>
								</List>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={7} className="profile-right-grid">
							{renderProfileForms(selectedProfile)}
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Page>
	);
};
