import { useContext, useState } from 'react';
import { Box, Container, Grid, Button, MenuItem, IconButton, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import '../fight.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import { TextField } from 'src/components/ui';

// eslint-disable-next-line react/prop-types
export const MetaCategory = ({ currentPage, categoryData, isUpdate, itemData, fetchCategory, closeDialog }) => {
	const [loader, setLoader] = useState(false);
	const [categoryImage, setCategoryImage] = useState('');
	const [categoryPicture, setCategoryPicture] = useState(false);
	// eslint-disable-next-line camelcase
	const { t } = useTranslation();

	const addCategory = async (data) => {
		const requestParams = {
			...(isUpdate !== '' && { metaCatId: itemData?.id }),
			parentId: data?.parentcategory,
			name: data?.categoryname,
			description: data?.categorydescription,
			...(categoryImage !== '' && { itemId: categoryImage?.id }),
			set: 2,
			status: identifiers?.active,
		};
		const method = isUpdate ? 'put' : 'post';
		const apiEndPoint = isUpdate ? 'updateCategory' : 'createCategory';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setLoader(false);
				setApiMessage('success', data?.message);
				fetchCategory(currentPage);
				closeDialog();
				// fetchAdminFightMethod();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setLoader(false);
		}
	};
	const ProfileAvatar = () => {
		const token = fetchFromStorage('authData')?.token;

		const imgHandler = async (e) => {
			e.persist();
			const formData = new FormData();

			if (e.target.files.length > 0) {
				const type = e.target.files[0].type.includes('image')
					? 'image'
					: e.target.files[0].type.includes('video')
					? 'video'
					: e.target.files[0].type.includes('text')
					? 'text'
					: e.target.files[0].type.includes('audio')
					? 'audio'
					: e.target.files[0].type.includes('blob')
					? 'blob'
					: '';

				formData.append('location', e.target.files[0]);
				formData.append('type', type);

				try {
					setLoader(true);
					const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					});

					if (status === 200) {
						setCategoryImage(data?.data);
						setCategoryPicture(data?.data?.location);
						setApiMessage('success', data?.message);
						setLoader(false);
					}
				} catch (error) {
					setApiMessage('error', error?.response?.data?.message);
					setLoader(false);
				}
			}
		};

		return (
			<>
				<input
					accept="image/*"
					id="admin-category-file"
					type="file"
					multiple
					onChange={imgHandler}
					style={{ display: 'none' }}
				/>
				<label htmlFor="admin-category-file">
					<IconButton component="span" className="p-0">
						<Avatar src={URLS.mediaURL + categoryPicture} />
						<CameraIcon className="camera-icon" />
					</IconButton>
				</label>
			</>
		);
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Box className="form-content">
						<ProfileAvatar />
						<Formik
							initialValues={{
								parentcategory: itemData?.parent_id !== null ? itemData?.parent_id : '',
								categoryname: itemData?.name !== null ? itemData?.name : '',
								categorydescription: itemData?.description !== null ? itemData?.description : '',
								// set: ''
							}}
							validationSchema={Yup.object().shape({
								parentcategory: Yup.string().required(t('validation.required_message')),
								categoryname: Yup.string().required(t('validation.required_message')),
								categorydescription: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => addCategory(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.parentcategory && errors.parentcategory)}
												fullWidth
												helperText={touched.parentcategory && errors.parentcategory}
												variant="filled"
												InputProps={{
													endAdornment: touched.parentcategory && errors.parentcategory ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_category.parentcategory')}
												placeholder={t('admin_panel.combat_category.parentcategory')}
												className="matrix-textfield"
												size="small"
												name="parentcategory"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.parentcategory}
												select
											>
												{categoryData.map((item, index) => {
													return (
														<MenuItem key={index} value={item?.id}>
															{item?.name}
														</MenuItem>
													);
												})}
											</TextField>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.categoryname && errors.categoryname)}
												fullWidth
												helperText={touched.categoryname && errors.categoryname}
												variant="filled"
												placeholder={t('admin_panel.combat_category.categoryname')}
												label={t('admin_panel.combat_category.categoryname')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.categoryname && errors.categoryname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="categoryname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.categoryname}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.categorydescription && errors.categorydescription)}
												fullWidth
												helperText={touched.categorydescription && errors.categorydescription}
												variant="filled"
												placeholder={t('admin_panel.combat_category.categorydescription')}
												label={t('admin_panel.combat_category.categorydescription')}
												className="matrix-textfield"
												InputProps={{
													endAdornment:
														touched.categorydescription && errors.categorydescription ? <ErrorIcon /> : null,
												}}
												size="small"
												name="categorydescription"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.categorydescription}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default MetaCategory;
