import { connect } from 'react-redux';
import { userVerifyCount } from 'src/redux-state/actions/userProfile';

import { ManageUser as ManageUserComponent } from './ManageUser';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		userVerifyCount: (data) => dispatch(userVerifyCount(data)),
	};
};

export const ManageUser = connect(mapStateToProps, mapDispatchToProps)(ManageUserComponent);
