import { useState } from 'react';
import { Box, Button, Typography, ListItem, ListItemText } from '@material-ui/core';
import { DialogBox } from 'src/components/ui';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/community/edit-white.svg';
import './categroy-discipline.scss';
import { useSelector } from 'react-redux';
import { EditCategoryDiscipline } from './EditCategoryDiscipline';
import { useTranslation } from 'react-i18next';

export const CategoryDiscipline = ({ isView, fetchCommunityDetails, token }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const combatDialogHandler = () => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	// console.log('communityDetails', communityDetails)
	return (
		<Box className="cd-box">
			<Typography className="cd-title">{t('community.cat_and_dis')}</Typography>

			<Box className="cat-dis-listing-main">
				{/* DISCIPLINE LISTING */}
				{communityDetails && communityDetails?.community_discipline?.length > 0 && (
					<Typography className="list-title">{t('community.disciplines')}</Typography>
				)}
				{communityDetails &&
					communityDetails?.community_discipline?.map((value, index) => {
						return (
							<ListItem key={index} disablePadding>
								<ListItemText primary={value?.Discipline?.name} />
							</ListItem>
						);
					})}
				{/* CATEGORY LISTING */}
				{communityDetails && communityDetails?.community_category?.length > 0 && (
					<Typography className="list-title pt-18">{t('community.categories')}</Typography>
				)}
				{communityDetails &&
					communityDetails?.community_category?.map((value, index) => {
						return (
							<ListItem key={index} disablePadding>
								<ListItemText primary={value?.MetaCategory?.name} />
							</ListItem>
						);
					})}
			</Box>
			{communityDetails?.community_category?.length === 0 && communityDetails?.community_discipline?.length === 0 && (
				<Typography className="no-cd-txt">{t('community.no_cd_text')}</Typography>
			)}
			{isView ? null : (
				<Button
					startIcon={<EditIcon />}
					variant="contained"
					fullWidth
					disabled={communityDetails?.communityData?.status === 'closed' ? true : false}
					className="cat-dis-btn"
					onClick={combatDialogHandler}
				>
					{t('community.edit_cat_and_dis')}
				</Button>
			)}
			<DialogBox
				open={open}
				handleClose={closeDialog}
				className="h-90vh"
				title={t('community.cat_and_dis')}
				content={
					<EditCategoryDiscipline
						handleClose={closeDialog}
						communityDetails={communityDetails}
						token={token}
						fetchCommunityDetails={fetchCommunityDetails}
					/>
				}
			/>
		</Box>
	);
};
