import { useState } from 'react';
import { Avatar, Box, Typography, Button, Grid, Popover } from '@material-ui/core';
// import CommunityBadges from 'src/views/component/application/Community/CommunityView/RightSide/Badges';
import { fetchFromStorage } from 'src/helpers/context';
import { AD } from '../../CommunityView/LeftSide/Discussions/AD';
import '../communityPost.scss';
import moment from 'moment';
import Viewer from 'react-viewer';
import { ReactComponent as AddConnection } from 'src/assets/images/icons/add-connection.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const PostDetailsRight = ({
	token,
	communityDetails,
	user,
	communityAction,
	postData,
	fetchPostDetails,
	redirectGuestToSignUp,
}) => {
	const { t } = useTranslation();

	var postAbout = communityDetails?.post_about?.PostLists?.find((item) => item?.Item?.type === 'text');

	// AUTHORIZATION HEADER

	let authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [visible, setVisible] = useState(false);
	const [visibleCover, setVisibleCover] = useState(false);

	// const navigate = useNavigate();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const isOwner = communityDetails?.user_owner_id === fetchFromStorage('authData')?.user_id ? true : false;

	// CONNECTION ACCEPT-REJECT-BLOCK
	const connectionChangeHandler = async (type) => {
		redirectGuestToSignUp();
		// connectionLoaderHandler(type, true);

		try {
			let requestData = {
				status:
					type === 'accept'
						? 'accepted'
						: type === 'reject'
						? 'rejected'
						: type === 'block'
						? 'blocked'
						: type === 'unblock'
						? 'unblock'
						: '',
				user_friend_id: postData?.post_data?.UserData?.id,
			};
			let { status } = await axiosInstance.put(URLS.connectionRequestUpdate, requestData, authHeader);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FOR CONNECTION REMOVE-REQUEST_CANCEL
	const removeConnectionHandler = async (type) => {
		try {
			let { status } = await axiosInstance.delete(
				URLS.deleteConnection + `?user_friend_id=${postData?.post_data?.UserData?.id}`,
				authHeader,
			);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FOR ADD NEW CONNECTION
	const addConnection = async () => {
		redirectGuestToSignUp();

		const requestData = { user_friend_id: postData?.post_data?.UserData?.id };

		try {
			let { status } = await axiosInstance.post(URLS.addConnection, requestData, authHeader);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FOLLOW USER
	const followUser = async () => {
		redirectGuestToSignUp();

		let requestData = { user_following_id: postData?.post_data?.UserData?.id };

		try {
			let { status } = await axiosInstance.post(URLS.doFollow, requestData, authHeader);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// UNFOLLOW USER
	const unFollowUser = async () => {
		redirectGuestToSignUp();

		let requestData = { user_following_id: postData?.post_data?.UserData?.id };

		try {
			let { status } = await axiosInstance.post(URLS.doUnfollow, requestData, authHeader);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const isGuestUser = !token;
	const userProfileImage = postData?.post_data?.UserData?.user_profile_data;
	const userProfileCoverImage = postData?.post_data?.UserData?.item_cover_data;

	return (
		<>
			<Box className="post-details-main">
				<Box className="post-banner">
					<>
						<Avatar
							src={communityDetails?.item_cover_data ?? userProfileCoverImage}
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setVisibleCover(true);
							}}
						/>
						{(isGuestUser && userProfileCoverImage) ||
						(communityDetails?.item_cover_data !== '' &&
							communityDetails?.item_cover_data !== undefined &&
							communityDetails?.item_cover_data !== null) ? (
							<Viewer
								visible={visibleCover}
								onClose={() => {
									setVisibleCover(false);
								}}
								images={[
									{
										src: communityDetails?.item_cover_data ?? userProfileCoverImage,
										title: 'cover image',
									},
								]}
								downloadable
								noFooter={true}
								drag={false}
								zoomable={false}
								rotatable={false}
								scalable={false}
								disableMouseZoom={true}
							/>
						) : null}
					</>
				</Box>
				<Box className="post-image">
					{/* <Avatar src={communityDetails?.item_profile_id} /> */}
					<>
						<Avatar
							src={communityDetails?.item_profile_data ?? userProfileImage}
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setVisible(true);
							}}
						/>
						{(isGuestUser && userProfileImage) ||
						(communityDetails?.item_profile_data !== '' &&
							communityDetails?.item_profile_data !== undefined &&
							communityDetails?.item_profile_data !== null) ? (
							<Viewer
								visible={visible}
								onClose={() => {
									setVisible(false);
								}}
								images={[
									{
										src: communityDetails?.item_profile_data ?? userProfileImage,
										title: 'cover image',
									},
								]}
								downloadable
								noFooter={true}
								drag={false}
								zoomable={false}
								rotatable={false}
								scalable={false}
								disableMouseZoom={true}
							/>
						) : null}
					</>
					<NavLink
						to={
							communityDetails && Object.keys(communityDetails).length
								? `/communities/${communityDetails?.id}`
								: `/user/${postData?.post_data?.UserData?.id}`
						}
						className="post-name"
					>
						{communityDetails && Object.keys(communityDetails).length === 0
							? postData?.post_data?.UserData?.user_full_name
							: communityDetails?.name}
					</NavLink>

					{/* <Typography className="post-name" onClick={() => { navigate(`/communities/${communityDetails?.id}`) }} >
            {communityDetails && Object.keys(communityDetails).length === 0 ? postData?.post_data?.UserData?.user_full_name : communityDetails?.name}
          </Typography> */}
				</Box>
				{communityDetails &&
					Object.keys(communityDetails).length === 0 &&
					postData?.post_data?.UserData?.id !== fetchFromStorage('authData')?.user_id && (
						<Box className="user-action">
							{postData?.post_data?.UserData?.isFollow ? (
								<Button fullWidth variant="contained" className="profile-following-btn" onClick={() => unFollowUser()}>
									{t('following')}
								</Button>
							) : (
								<Button fullWidth variant="contained" className="profile-follow-btn" onClick={() => followUser()}>
									{t('follow')}
								</Button>
							)}

							<Box display="flex" justifyContent="space-between">
								{postData?.post_data?.UserData?.isFriendStatus !== 'blackUser' && (
									<>
										{postData?.post_data?.UserData?.isFriendStatus === 'Received' ? (
											// CONNECTION REQUEST RECIEVED
											<Button
												style={{ width: '32%' }}
												variant="contained"
												className="connection-send-btn"
												onClick={() => connectionChangeHandler('accept')}
											>
												{t('accept')}
											</Button>
										) : postData?.post_data?.UserData?.isFriendStatus === 'sent' ? (
											// CONNECTION REQUEST IS SENDED
											<Button
												style={{ width: '49%' }}
												variant="contained"
												className="connection-have-btn"
												onClick={() => removeConnectionHandler('cancel')}
											>
												{t('peding_connection')}
											</Button>
										) : postData?.post_data?.UserData?.isFriendStatus === 'accepted' ? (
											// CONNECTION SUCCESSFULLY CONNECTED
											<Button
												style={{ width: '49%' }}
												variant="contained"
												className="connection-have-btn"
												onClick={() => removeConnectionHandler('remove')}
											>
												{t('remove_connection')}
											</Button>
										) : (
											// ADD NEW CONNECTION BUTTON
											<Button
												style={{ width: '49%' }}
												variant="contained"
												className="connection-send-btn"
												startIcon={<AddConnection />}
												onClick={() => addConnection()}
											>
												{t('add_connection')}
											</Button>
										)}
									</>
								)}

								{postData?.post_data?.UserData?.isFriendStatus === 'blackUser' ? (
									<Button
										disableElevation
										disableTouchRipple
										disableRipple
										disableFocusRipple
										style={{ width: '100%' }}
										variant="contained"
										className="connection-have-btn"
										onClick={() => connectionChangeHandler('unblock')}
									>
										{t('unblock')}
									</Button>
								) : (
									<Button
										disableElevation
										disableTouchRipple
										disableRipple
										disableFocusRipple
										style={{ width: '50%' }}
										variant="contained"
										className="connection-have-btn blue-button"
										onClick={() => connectionChangeHandler('block')}
									>
										{t('block')}
									</Button>
								)}
							</Box>
						</Box>
					)}
				<Box className="p-16">
					<Box className="post-content">
						<Typography className="post-description">{postAbout?.Item?.data}</Typography>
					</Box>
					{communityDetails && Object.keys(communityDetails).length !== 0 && (
						<Box className="post-other-data">
							<Typography className="post-description">
								<Typography variant="span">{t('community.created')}:</Typography>
								<Typography variant="span" className="color-blue">
									{moment(communityDetails?.timestamp_created).format('MMM DD YYYY, h:mm')}
								</Typography>
							</Typography>
							<Typography className="post-description">
								<Typography variant="span">{t('community.members')}:</Typography>
								<Typography variant="span" className="color-blue">
									{communityDetails?.total_member}
								</Typography>
							</Typography>
						</Box>
					)}
					{communityDetails && Object.keys(communityDetails).length !== 0 && (
						<Box className="post-other-data">
							{isOwner ? null : (
								<Button
									variant="contained"
									fullWidth
									className="leave-btn"
									disabled={communityDetails?.status === 'closed' ? true : false}
									onClick={(e) => {
										communityDetails?.join_status === 'accepted'
											? communityAction(communityDetails, 'leave')
											: communityDetails?.join_status === 'sent'
											? handleClick(e)
											: communityAction(communityDetails, 'sent');
									}}
								>
									{communityDetails?.join_status === 'accepted'
										? t('community.leave_community')
										: communityDetails?.join_status === 'sent'
										? t('community.join_req_pending')
										: t('community.join')}
								</Button>
							)}
							<Popover
								className="cr-popover"
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<Button
									onClick={() => {
										handleClose();
										communityAction(communityDetails, 'leave');
									}}
								>
									{t('community.cancel_request')}
								</Button>
							</Popover>
						</Box>
					)}
				</Box>
			</Box>
			{/* <CommunityBadges /> */}
			{communityDetails?.community_badge?.length > 0 && (
				<Box className="community-badge-box">
					<Typography className="badge-box-title">{t('community.community_badges')}</Typography>
					<Box className="badges-container">
						<Grid container spacing={3}>
							{/* {communityDetails &&
              communityDetails?.community_badge?.length > 0 ? (
              <> */}
							{communityDetails?.community_badge.map((item, i) => {
								return (
									<Grid key={i} item xs={3} sm={3} md={2} lg={2}>
										<Box className="badge-block">
											<Avatar className="badge-icon" variant="square" src={item?.Badge?.item_icon_data} />
											<Typography className="verified-badge-text">{item?.Badge?.name}</Typography>
										</Box>
									</Grid>
								);
							})}
							{/* </>
            ) : (
              <Typography className="no-community-text">
                {t('community.community_has_no_badges')}
              </Typography>
            )} */}
						</Grid>
					</Box>
				</Box>
			)}
			<AD />
		</>
	);
};
