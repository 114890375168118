import { useEffect, useState } from 'react';
import {
	Box,
	Avatar,
	List,
	ListItem,
	ListItemText,
	Typography,
	// Button,
	InputAdornment,
	TextField,
	withStyles,
} from '@material-ui/core';
import './invite-add-members.scss';
// import { ReactComponent as ApproveMemberIcon } from 'src/assets/images/icons/community/approveicon.svg';
// import { ReactComponent as RemoveMemberIcon } from 'src/assets/images/icons/community/rejecticon.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams } from 'react-router-dom';
import { CustomPaginations } from 'src/components/ui';
import { useTranslation } from 'react-i18next';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
// import { setApiMessage } from 'src/helpers/commonFunctions';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const InviteAddMembers = () => {
	const { t } = useTranslation();

	const [searchString, setSearchString] = useState(''); //setSearchString
	const [memberList, setMemberList] = useState([]);
	const [limit] = useState(8); //setList
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords

	const { id } = useParams();

	const fetchAllMemberList = async (page, ss) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList, {
				params: {
					page: page,
					limit: limit,
					community_id: Number(id),
					name: ss,
				},
			});

			if (status === 200) {
				setMemberList(data?.getAllUsers);
				setTotalRecords(data?.totalRecord);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1, searchString);
				fetchAllMemberList(currentPage - 1, searchString);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				setCurrentPage(currentPage + 1, searchString);
				fetchAllMemberList(currentPage + 1, searchString);
			}
		}
	};
	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchAllMemberList(Number(page), searchString);
		}
	};

	useEffect(() => {
		fetchAllMemberList(currentPage, searchString);
	}, []);
	const handleChange = (e) => {
		fetchAllMemberList(currentPage, e.target.value);
		setSearchString(e.target.value);
	};

	return (
		<>
			<Box className="member-listing-main">
				<Box className="search-box">
					<SearchTextField
						variant="outlined"
						fullWidth
						size="small"
						placeholder="Search"
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Box className="member-inner">
						<List>
							{memberList && memberList?.length ? (
								<>
									{memberList?.map((item, index) => {
										return (
											<ListItem key={index}>
												<Avatar src={item?.item_profile_data} />
												<ListItemText>
													<Typography variant="span" className="member-name">
														{item?.user_full_name}
													</Typography>
												</ListItemText>
												{/* <Button
                                                    disableRipple
                                                    disableFocusRipple
                                                    disableTouchRipple
                                                    edge="end"
                                                    className={item.type === null ? "member-btn" : "member-btn gray-background"}
                                                    // onClick={() => handleClick(item, modal, status)}
                                                    // startIcon={item.type === null ? <ApproveMemberIcon /> : <RemoveMemberIcon />}
                                                >
                                                    {modal === 'admin' ? adminbtntxt : moderatorbtntxt}
                                                </Button> */}
											</ListItem>
										);
									})}
								</>
							) : (
								<Typography className="no-data-txt">{t('community.no_member_found')}</Typography>
							)}
						</List>
					</Box>
				</Box>
			</Box>
			<CustomPaginations
				paginationArray={memberList}
				currentPage={currentPage}
				list={limit}
				handlePaginationButtonClick={handlePaginationButtonClick}
				handlePaginationClick={handlePaginationClick}
				totalRecord={totalRecord}
			/>
		</>
	);
};
