import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	Button,
	IconButton,
	// InputAdornment,
	// TextField,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	// withStyles,
	Grid,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
// import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage, getFields } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { ReactComponent as ActiveIcon } from 'src/assets/images/icons/active_icon.svg';
import { ReactComponent as CompleteIcon } from 'src/assets/images/icons/complete_icon.svg';
import { ReactComponent as BackArrowicon } from 'src/assets/images/icons/events/back-arrow.svg';
import { ReactComponent as ToggleIcon } from 'src/assets/images/icons/toggleIcon.svg';
// import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { ReactComponent as AddIcon } from 'src/assets/images/icons/events/add-icon.svg';
import './events-left.scss';
import moment from 'moment';
import { CardLeftSide } from './CardLeftSide';
import { useTranslation } from 'react-i18next';

export const Left = ({
	type,
	fetchEventList,
	eventListQs,
	eventListTab,
	slidebarList,
	create_data,
	eventFightCard,
	user,
	currentFightTab,
	eventTypeList,
	id,
	handleNewEvent,
	currentCreateEventTab,
	searchData,
	cardCategory,
	currentSuperEventPage,
	supurEventCardData,
	eventStatus,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [step, setStep] = useState(0);
	const [fightStep, setFightStep] = useState(0);
	const [eventdetails, setEventData] = useState([]);
	const [fightData, setFightData] = useState([
		{
			id: 0,
		},
	]);
	const EVENT_FILTER = fetchFromStorage('eventfilter');
	const [fightSubmit, setFightSubmit] = useState('');
	const [sliderListStored, setsliderListStored] = useState(false);
	const [eventCheckBox, setEventCheckBox] = useState([]);
	const [loader, setLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	let {
		event_details,
		event_type,
		event_fight_card,
		current_fight_tab,
		event_ticket,
		event_admin,
		event_invite,
		category,
		fight_details,
		event_confirm,
		event_status,
		current_tab,
		super_event_status,
		super_event_card_data,
		super_event_details,
		super_event_category_details,
	} = create_data;

	console.log(event_fight_card, 'event_fight_cardevent_fight_card');
	// VALIDATE COMBTANTS
	const validate = (val, clickEvent) => {
		let flag1 = val?.event_fight ? true : false;
		let flag2 = false;

		if (val?.event_fight !== undefined) {
			flag1 =
				// val.description !== '' &&
				// val?.event_fight?.description !== null &&
				// val?.event_fight?.description !== undefined &&
				// val?.event_fight?.discipline_id !== '' &&
				// val?.event_fight?.discipline_id !== null &&
				// val?.event_fight?.discipline_id !== undefined &&
				val?.event_fight?.name !== '' &&
				val?.event_fight?.name !== null &&
				val?.event_fight?.name !== undefined &&
				// val?.event_fight?.ot_time !== '' &&
				// val?.event_fight?.ot_time !== null &&
				// val?.event_fight?.ot_time !== undefined &&
				// val?.event_fight?.rules_meta_category_id !== '' &&
				// val?.event_fight?.rules_meta_category_id !== null &&
				// val?.event_fight?.rules_meta_category_id !== undefined &&
				val?.event_fight?.end_time !== '' &&
				val?.event_fight?.end_time !== null &&
				val?.event_fight?.end_time !== undefined &&
				val?.event_fight?.start_time !== '' &&
				val?.event_fight?.start_time !== null &&
				val?.event_fight?.start_time !== undefined
					? true
					: false;
			// val?.event_fight?.title_fight_meta_category_id !== '' &&
			// val?.event_fight?.title_fight_meta_category_id !== null &&
			// val?.event_fight?.title_fight_meta_category_id !== undefined &&
			// val?.event_fight?.tournament_fight_meta_category_id !== '' &&
			// val?.event_fight?.tournament_fight_meta_category_id !== null &&
			// val?.event_fight?.tournament_fight_meta_category_id !== undefined ? true : false;
			// val?.event_fight?.weight_kg !== '' &&
			// val?.event_fight?.weight_kg !== null &&
			// val?.event_fight?.weight_kg !== undefined
		}

		val?.event_fight_combatant &&
			val.event_fight_combatant.map((item) => {
				if (
					item?.fight_side_name?.id !== '' &&
					item?.fight_side_name?.id !== null &&
					item?.fight_side_name?.id !== undefined
				) {
					flag2 = false;
				}

				if (item?.fightContestants.length > 0) {
					flag2 = item?.fightContestants.every(
						(x) =>
							x?.userName !== '' &&
							x?.userName !== null &&
							x?.userName !== undefined &&
							x?.userName?.user_id !== '' &&
							x?.userName?.user_id !== null &&
							x?.userName?.user_id !== undefined &&
							x?.meta_weight_class_id !== '' &&
							x?.meta_weight_class_id !== null &&
							x?.meta_weight_class_id !== undefined &&
							x?.title_meta_category_id !== '' &&
							x?.title_meta_category_id !== null &&
							x?.title_meta_category_id !== undefined,
					);
				}
			});

		if (!flag2 && !clickEvent) {
			setApiMessage('error', t('events.enter_combatant_details'));
		}

		return flag1 && flag2;
	};
	const eventNextHandler = (data, fightNext) => {
		EventEmitter.dispatch('submit_event_details');
		EventEmitter.dispatch('submit_tickets');
		EventEmitter.dispatch('submit_admin');
		EventEmitter.dispatch('submit_invite');
		EventEmitter.dispatch('submit_Fight_details');

		fightNext === 'fight' && setFightSubmit('fight_submit');
	};
	const saveToDraftEvent = () => {
		step === 0 && EventEmitter.dispatch('submit_event_details');
		step === 2 && EventEmitter.dispatch('submit_tickets');
		step === 3 && EventEmitter.dispatch('submit_admin');
		step === 4 && EventEmitter.dispatch('submit_invite');
		step === 1 && EventEmitter.dispatch('submit_Fight_details');
	};
	const eventBackHandler = (data, fightEvent) => {
		fightEvent && setFightSubmit('');
		setStep(data);
		currentCreateEventTab(data);
	};
	const addFights = () => {
		setFightData([
			...fightData,
			{
				id: fightData.length,
			},
		]);
		step === 3 && setFightStep(fightStep + 1);
	};
	// const saveToDraft = () => {
	//   eventStatus('draft');
	//   EventEmitter.dispatch('submit_event_details');
	//   EventEmitter.dispatch('submit_tickets');
	//   EventEmitter.dispatch('submit_admin');
	//   EventEmitter.dispatch('submit_invite');
	//   EventEmitter.dispatch('submit_Fight_details');
	//   console.log(event_status, 'event_statusevent_statusevent_status')

	// }
	const deleteFight = (index) => {
		index === current_fight_tab && step === 1 && currentFightTab(fightStep - 1);
		// var deletedFight = fightData.filter(item => item?.id !== index);
		var deltedFightArray = [];
		var deletedFight =
			fightData &&
			fightData?.map((item, find) => {
				if (item?.id !== index) {
					if (index < find) {
						deltedFightArray.push({ id: find - 1 });
					} else {
						deltedFightArray.push({ id: find });
					}

					return { id: find };
				} else {
					return;
				}
			});

		setFightData(deltedFightArray);
		console.log(index, current_fight_tab, 'indexindexindex');
		var deletedFightCard = event_fight_card.filter((item, findex) => findex !== index);

		eventFightCard(deletedFightCard);
		index === current_fight_tab && step === 1 && setFightStep(fightStep - 1);
	};
	const handleCheckParent = (index, e) => {
		var eventList =
			eventCheckBox &&
			eventCheckBox?.map((item, ind) => {
				if (ind === index) {
					if (item?.subList) {
						var subArr = item?.subList?.map((side, indx) => {
							return { ...side, checkedStatus: e.target.checked };
						});

						return {
							...item,
							checkedStatus: e.target.checked,
							subList: subArr,
						};
					}

					return {
						...item,
						checkedStatus: e.target.checked,
					};
				}

				return item;
			});

		eventList && setEventCheckBox(eventList);
	};
	const handleCheckChild = (index, pindex, e) => {
		var sublist =
			eventCheckBox && eventCheckBox[pindex] && eventCheckBox[pindex]?.subList && eventCheckBox[pindex]?.subList;
		var evtlist =
			sublist &&
			sublist?.map((subl, sindex) => {
				if (sindex === index) {
					return { ...subl, checkedStatus: e.target.checked };
				}

				return subl;
			});
		var parentCheck = evtlist && evtlist?.filter((i) => i?.checkedStatus === false);
		var upList =
			eventCheckBox &&
			eventCheckBox?.map((list, i) => {
				if (i === pindex) {
					return {
						...list,
						checkedStatus: parentCheck.length > 0 ? false : parentCheck.length === 0 ? true : list?.checkedStatus,
						subList: evtlist,
					};
				}

				return list;
			});

		upList && setEventCheckBox(upList);
	};
	const createEvents = async (eventstatus) => {
		// var event_meta = event_details?.eventUrls.concat(event_details?.eventLocations);
		var user_admin_array = [];
		var userAdmins =
			event_admin && getFields(event_admin?.find((item) => item.receiver_type === 'user')?.receiver_id, 'id');
		var comAdmins =
			event_admin && getFields(event_admin?.find((item) => item.receiver_type === 'community')?.receiver_id, 'id');
		var orgAdmins =
			event_admin && getFields(event_admin?.find((item) => item.receiver_type === 'organisation')?.receiver_id, 'id');

		userAdmins = userAdmins && userAdmins.concat(user?.user_id);

		user_admin_array.push(
			{
				receiver_type: 'user',
				receiver_id: userAdmins,
			},
			{
				receiver_type: 'community',
				receiver_id: comAdmins,
			},
			{
				receiver_type: 'organisation',
				receiver_id: orgAdmins,
			},
		);
		var user_invite_array;
		var userInvite = event_invite?.users && getFields(event_invite?.users, 'id');
		var comInvite = event_invite?.community && getFields(event_invite?.community, 'id');
		var orgInvite = event_invite?.organisation && getFields(event_invite?.organisation, 'id');

		userInvite = userInvite && userInvite.concat(user?.user_id);
		user_invite_array = {
			users: userInvite,
			community: comInvite,
			organisation: orgInvite,
			rsvp: event_invite?.rsvp,
			self_community: event_invite?.self_community,
			self_organisation: event_invite?.self_organisation,
			self_tag_rsvp: event_invite?.self_tag_rsvp,
			self_user: event_invite?.self_user,
			send_invitation: event_invite?.send_invitation,
			tag_combat_matrix: event_invite?.tag_combat_matrix,
		};

		if (event_type === 'fight' && !super_event_status) {
			var eventsFightdetails =
				event_fight_card &&
				event_fight_card.map((item, index) => {
					var combData =
						item.event_fight_combatant &&
						item?.event_fight_combatant.map((data, dindex) => {
							var fightDataFor = data?.fightContestants?.map((fightcom, findex) => {
								if (Object.prototype.hasOwnProperty.call(fightcom.userName, 'user_id')) {
									return {
										meta_weight_class_id: fightcom?.meta_weight_class_id,
										title_meta_category_id: fightcom?.title_meta_category_id,
										userId: fightcom.userName.user_id,
										fighter_type: fightcom.userName.type,
										fight_method_id: fightcom?.fight_method_id,
										result: fightcom?.result,
										time: fightcom?.time,
										round: fightcom?.round,
									};
								} else {
									return {
										...fightcom,
										userName: fightcom.userName.inputValue,
										fighter_type: 'user_other',
									};
								}
							});

							if (Object.prototype.hasOwnProperty.call(data.fight_side_name, 'id')) {
								return {
									fight_side_id: data.fight_side_name.id,
									fightContestants: fightDataFor,
									...(data.event_fight_id && { event_fight_id: data.event_fight_id }),
								};
							} else {
								return {
									fight_side_name: data.fight_side_name.inputValue,
									fightContestants: fightDataFor,
								};
							}
						});

					return { ...item, event_fight_combatant: combData };
				});
		}

		var eventFormattedDate = [];
		var dateArray = event_details?.eventDates;

		dateArray &&
			dateArray.map((date, index) => {
				var obj = {
					...date,
					end_time: moment(date?.end_time).utc().format(),
					start_time: moment(date?.start_time).utc().format(),
				};

				return (eventFormattedDate[index] = obj);
			});

		var eventDetailsArray = [
			{
				event: {
					...(event_confirm === 'update' && { event_id: event_details?.event_id }),
					name: event_details?.formData?.event_name,
					description: event_details?.formData?.description,
					item_cover_id: event_details?.imgCoverId?.imageId,
					location_type: event_details?.formData?.location_type,
					timezone: event_details?.formData?.timezone,
					status: eventstatus === 'draft' ? 'draft' : 'published',
					event_date: eventFormattedDate,
					event_meta: event_details?.eventUrls,
					address: event_details?.eventLocations,
				},
				event_ticket: event_ticket,
				event_admin: user_admin_array,
				event_invite: user_invite_array,
				...(super_event_status === true && {
					event_assoc: {
						type: 'super',
						name_meta_category_id: category?.id,
					},
				}),
				event_fight_details: eventsFightdetails,
			},
		];
		var superEventDetailssec = super_event_details?.['eventindex-2'];
		var superEventDetailstr = super_event_details?.['eventindex-3'];
		var superEventDetailsft = super_event_details?.['eventindex-4'];
		var supereventDetailsArray = [
			{
				event: {
					...(event_confirm === 'update' && { event_id: superEventDetailssec?.event_id }),
					name: superEventDetailssec?.formData?.event_name,
					description: superEventDetailssec?.formData?.description,
					item_cover_id: superEventDetailssec?.imgCoverId?.imageId,
					location_type: superEventDetailssec?.formData?.location_type,
					timezone: superEventDetailssec?.formData?.timezone,
					status: eventstatus === 'draft' ? 'draft' : 'published',
					event_date: eventFormattedDate,
					event_meta: superEventDetailssec?.eventUrls,
					address: superEventDetailssec?.eventLocations,
				},
				event_fight_details: [],
				event_assoc: super_event_category_details?.['eventindex-2'],
			},
			{
				event: {
					...(event_confirm === 'update' && { event_id: superEventDetailstr?.event_id }),
					name: superEventDetailstr?.formData?.event_name,
					description: superEventDetailstr?.formData?.description,
					item_cover_id: superEventDetailstr?.imgCoverId?.imageId,
					location_type: superEventDetailstr?.formData?.location_type,
					timezone: superEventDetailstr?.formData?.timezone,
					status: eventstatus === 'draft' ? 'draft' : 'published',
					event_date: eventFormattedDate,
					event_meta: superEventDetailstr?.eventUrls,
					address: superEventDetailstr?.eventLocations,
				},
				event_fight_details: [],
				event_assoc: super_event_category_details?.['eventindex-3'],
			},
			{
				event: {
					...(event_confirm === 'update' && { event_id: superEventDetailsft?.event_id }),
					name: superEventDetailsft?.formData?.event_name,
					description: superEventDetailsft?.formData?.description,
					item_cover_id: superEventDetailsft?.imgCoverId?.imageId,
					location_type: superEventDetailsft?.formData?.location_type,
					timezone: superEventDetailsft?.formData?.timezone,
					status: eventstatus === 'draft' ? 'draft' : 'published',
					event_date: eventFormattedDate,
					event_meta: superEventDetailsft?.eventUrls,
					address: superEventDetailsft?.eventLocations,
				},
				event_fight_details: [],
				event_assoc: super_event_category_details?.['eventindex-4'],
			},
		];
		var requestParams = {
			event_type: event_type,
			...(event_type === 'fight' && { super_event_status: String(super_event_status) }),
			...(super_event_status === true && { meta_category_id: category?.id }),
			event_details:
				create_data?.super_event_status === true && create_data?.category?.name === 'Card'
					? eventDetailsArray?.concat(supereventDetailsArray)
					: eventDetailsArray,
		};
		const method = event_confirm === 'update' ? 'put' : 'post';
		const apiEndPoint = event_confirm === 'update' ? 'updateEvent' : 'createEvent';

		setLoader(true);

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setLoader(false);
				setEventData(data?.createEvent);
				setApiMessage('success', data?.message);
				navigate('/events/discover');
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const createDraftEvent = async (currentTabData) => {
		setLoading(true);
		var user_admin_array = [];
		var userAdmins =
			currentTabData?.event_admin &&
			getFields(currentTabData?.event_admin?.find((item) => item.receiver_type === 'user')?.receiver_id, 'id');
		var comAdmins =
			currentTabData?.event_admin &&
			getFields(currentTabData?.event_admin?.find((item) => item.receiver_type === 'community')?.receiver_id, 'id');
		var orgAdmins =
			currentTabData?.event_admin &&
			getFields(currentTabData?.event_admin?.find((item) => item.receiver_type === 'organisation')?.receiver_id, 'id');

		userAdmins = userAdmins && userAdmins.concat(user?.user_id);

		if (userAdmins || comAdmins || orgAdmins) {
			user_admin_array.push(
				{
					receiver_type: 'user',
					receiver_id: userAdmins,
				},
				{
					receiver_type: 'community',
					receiver_id: comAdmins,
				},
				{
					receiver_type: 'organisation',
					receiver_id: orgAdmins,
				},
			);
		}

		var user_invite_array;
		var userInvite = currentTabData?.event_invite?.users && getFields(currentTabData?.event_invite?.users, 'id');
		var comInvite = currentTabData?.event_invite?.community && getFields(currentTabData?.event_invite?.community, 'id');
		var orgInvite =
			currentTabData?.event_invite?.organisation && getFields(currentTabData?.event_invite?.organisation, 'id');

		userInvite = userInvite && userInvite.concat(user?.user_id);
		user_invite_array = {
			users: userInvite,
			community: comInvite,
			organisation: orgInvite,
			rsvp: currentTabData?.event_invite?.rsvp,
			self_community: currentTabData?.event_invite?.self_community,
			self_organisation: currentTabData?.event_invite?.self_organisation,
			self_tag_rsvp: currentTabData?.event_invite?.self_tag_rsvp,
			self_user: currentTabData?.event_invite?.self_user,
			send_invitation: currentTabData?.event_invite?.send_invitation,
			tag_combat_matrix: currentTabData?.event_invite?.tag_combat_matrix,
		};

		if (event_type === 'fight' && !super_event_status) {
			var eventsFightdetails =
				currentTabData?.event_fight_card &&
				currentTabData?.event_fight_card.map((item, index) => {
					var combData =
						item.event_fight_combatant &&
						item?.event_fight_combatant.map((data, dindex) => {
							var fightDataFor = data?.fightContestants?.map((fightcom, findex) => {
								if (Object.prototype.hasOwnProperty.call(fightcom.userName, 'user_id')) {
									return {
										meta_weight_class_id: fightcom?.meta_weight_class_id,
										title_meta_category_id: fightcom?.title_meta_category_id,
										userId: fightcom.userName.user_id,
										fighter_type: fightcom.userName.type,
										fight_method_id: fightcom?.fight_method_id,
										result: fightcom?.result,
										time: fightcom?.time,
										round: fightcom?.round,
									};
								} else {
									return {
										...fightcom,
										userName: fightcom.userName.inputValue,
										fighter_type: 'user_other',
									};
								}
							});

							if (Object.prototype.hasOwnProperty.call(data.fight_side_name, 'id')) {
								return {
									fight_side_id: data.fight_side_name.id,
									fightContestants: fightDataFor,
									...(data.event_fight_id && { event_fight_id: data.event_fight_id }),
								};
							} else {
								return {
									fight_side_name: data.fight_side_name.inputValue,
									fightContestants: fightDataFor,
								};
							}
						});

					return { ...item, event_fight_combatant: combData };
				});
		}

		var eventFormattedDate = [];

		var dateArray = currentTabData?.event_details?.eventDates;

		dateArray &&
			dateArray.map((date, index) => {
				var obj = {
					...date,
					end_time: moment(date?.end_time).utc().format(),
					start_time: moment(date?.start_time).utc().format(),
				};

				return (eventFormattedDate[index] = obj);
			});

		// if (step === 0) {
		var eventDetailsArray = [
			{
				event: {
					...(event_confirm === 'update' && { event_id: event_details?.event_id }),
					name: currentTabData?.event_details?.formData?.event_name,
					description: currentTabData?.event_details?.formData?.description,
					item_cover_id: currentTabData?.event_details?.imgCoverId?.imageId,
					location_type: currentTabData?.event_details?.formData?.location_type,
					timezone: currentTabData?.event_details?.formData?.timezone,
					status: 'draft',
					...(eventFormattedDate && { event_date: eventFormattedDate }),
					event_meta: currentTabData?.event_details?.eventUrls,
					address: currentTabData?.event_details?.eventLocations,
				},
				...(event_ticket && { event_ticket: event_ticket }),
				...(user_admin_array && { event_admin: user_admin_array }),
				...(user_invite_array && { event_invite: user_invite_array }),
				...(super_event_status === true && {
					event_assoc: {
						type: 'super',
						name_meta_category_id: category?.id,
					},
				}),
				...(eventsFightdetails && { event_fight_details: eventsFightdetails }),
			},
		];
		// } else {
		//   var eventDetailsArray = [
		//     {
		//       event: {
		//         ...(event_confirm === 'update' && { event_id: event_details?.event_id }),
		//         name: event_details?.formData?.event_name,
		//         description: event_details?.formData?.description,
		//         item_cover_id: event_details?.imgCoverId?.imageId,
		//         location_type: event_details?.formData?.location_type,
		//         timezone: event_details?.formData?.timezone,
		//         status: "draft",
		//         event_date: eventFormattedDate,
		//         event_meta: event_details?.eventUrls,
		//         address: event_details?.eventLocations
		//       },
		//       event_ticket: event_ticket,
		//       event_admin: user_admin_array,
		//       event_invite: user_invite_array,
		//       ...(super_event_status === true && {
		//         event_assoc: {
		//           type: "super",
		//           name_meta_category_id: category?.id
		//         }
		//       }),
		//       event_fight_details: eventsFightdetails
		//     }
		//   ]
		// }
		var superEventDetailssec = super_event_details?.['eventindex-2'];
		var superEventDetailstr = super_event_details?.['eventindex-3'];
		var superEventDetailsft = super_event_details?.['eventindex-4'];
		var supereventDetailsArray = [
			{
				event: {
					...(event_confirm === 'update' && { event_id: superEventDetailssec?.event_id }),
					name: superEventDetailssec?.formData?.event_name,
					description: superEventDetailssec?.formData?.description,
					item_cover_id: superEventDetailssec?.imgCoverId?.imageId,
					location_type: superEventDetailssec?.formData?.location_type,
					timezone: superEventDetailssec?.formData?.timezone,
					status: 'draft',
					event_date: eventFormattedDate,
					event_meta: superEventDetailssec?.eventUrls,
					address: superEventDetailssec?.eventLocations,
				},
				event_fight_details: [],
				event_assoc: super_event_category_details?.['eventindex-2'],
			},
			{
				event: {
					...(event_confirm === 'update' && { event_id: superEventDetailstr?.event_id }),
					name: superEventDetailstr?.formData?.event_name,
					description: superEventDetailstr?.formData?.description,
					item_cover_id: superEventDetailstr?.imgCoverId?.imageId,
					location_type: superEventDetailstr?.formData?.location_type,
					timezone: superEventDetailstr?.formData?.timezone,
					status: 'draft',
					event_date: eventFormattedDate,
					event_meta: superEventDetailstr?.eventUrls,
					address: superEventDetailstr?.eventLocations,
				},
				event_fight_details: [],
				event_assoc: super_event_category_details?.['eventindex-3'],
			},
			{
				event: {
					...(event_confirm === 'update' && { event_id: superEventDetailsft?.event_id }),
					name: superEventDetailsft?.formData?.event_name,
					description: superEventDetailsft?.formData?.description,
					item_cover_id: superEventDetailsft?.imgCoverId?.imageId,
					location_type: superEventDetailsft?.formData?.location_type,
					timezone: superEventDetailsft?.formData?.timezone,
					status: 'draft',
					event_date: eventFormattedDate,
					event_meta: superEventDetailsft?.eventUrls,
					address: superEventDetailsft?.eventLocations,
				},
				event_fight_details: [],
				event_assoc: super_event_category_details?.['eventindex-4'],
			},
		];
		var requestParams = {
			event_type: event_type,
			...(event_type === 'fight' && { super_event_status: String(super_event_status) }),
			...(super_event_status === true && { meta_category_id: category?.id }),
			event_details:
				create_data?.super_event_status === true && create_data?.category?.name === 'Card'
					? eventDetailsArray?.concat(supereventDetailsArray)
					: eventDetailsArray,
		};
		const method = event_confirm === 'update' ? 'put' : 'post';
		const apiEndPoint = event_confirm === 'update' ? 'updateEvent' : 'createEvent';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setEventData(data?.createEvent);
				setApiMessage('success', data?.message);
				navigate('/events/discover');
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		var slidebar = slidebarList.map((item, index) => {
			var sublistorg =
				item.subOrg &&
				item.subOrg.map((list, index) => {
					var listobj = {
						...list,
						checkedStatus: true,
					};

					return listobj;
				});
			var sublistcom =
				item.subCommunity &&
				item.subCommunity.map((list, index) => {
					var listobj = {
						...list,
						checkedStatus: true,
					};

					return listobj;
				});
			var obj = {
				...(Object.prototype.hasOwnProperty.call(item, 'type') && {
					id: index,
					name: item.name,
					type: item.type,
					checkedStatus: true,
				}),
				...(sublistorg && { subList: sublistorg }),
				...(sublistcom && { subList: sublistcom }),
			};

			return obj;
		});

		setEventCheckBox(slidebar);
	}, [slidebarList]);
	useEffect(() => {
		// eventCheckBox && setEventCheckBox(eventCheckBox);
		if (eventCheckBox?.length > 0) {
			var selectedOrgId = eventCheckBox?.find((item) => item.type === 'cal_org')?.subList;
			var checkedOrgId = selectedOrgId && selectedOrgId?.filter((item) => item.checkedStatus === true);
			var selectedComId = eventCheckBox?.find((item) => item.type === 'cal_community')?.subList;
			var checkedComId = selectedComId && selectedComId?.filter((item) => item.checkedStatus === true);
			var leftFilter = {
				cal_main: eventCheckBox.find((item) => item.type === 'cal_main')?.checkedStatus ? 'yes' : 'no',
				cal_user: eventCheckBox.find((item) => item.type === 'cal_user')?.checkedStatus ? 'yes' : 'no',
				cal_org: checkedOrgId ? checkedOrgId.map((u) => u.organisation_id).join(',') : '',
				cal_community: checkedComId ? checkedComId.map((u) => u.community_id).join(',') : '',
			};
		}

		if (!_.isEqual(leftFilter, EVENT_FILTER?.slidebarFilter)) {
			leftFilter && fetchEventList(eventListTab, eventListQs, searchData, leftFilter);
			var eventFilterObj = {
				...EVENT_FILTER,
				slidebarFilter: leftFilter,
			};

			leftFilter && saveToStorage('eventfilter', eventFilterObj);
		}
	}, [eventCheckBox]);

	useEffect(() => {
		if (EVENT_FILTER?.slidebarFilter && !sliderListStored) {
			var eventCheckBoxDetails = eventCheckBox?.map((item, ind) => {
				if (ind === 0) {
					return {
						...item,
						checkedStatus: EVENT_FILTER?.slidebarFilter?.cal_main === 'yes' ? true : false,
					};
				} else if (ind === 1) {
					return {
						...item,
						checkedStatus: EVENT_FILTER?.slidebarFilter?.cal_user === 'yes' ? true : false,
					};
				} else if (ind === 2) {
					if (EVENT_FILTER?.slidebarFilter?.cal_org) {
						var subListfalse = [];
						var sublistorgarray = item?.subList?.map((orglist, index) => {
							if (EVENT_FILTER?.slidebarFilter?.cal_org.includes(orglist?.organisation_id)) {
								return {
									...orglist,
									checkedStatus: true,
								};
							} else {
								subListfalse.push({
									...orglist,
									checkedStatus: false,
								});

								return {
									...orglist,
									checkedStatus: false,
								};
							}
						});

						return {
							...item,
							subList: sublistorgarray,
							checkedStatus: subListfalse?.length > 0 ? false : true,
						};
					} else {
						var orgarray = item?.subList?.map((orglist, index) => {
							return {
								...orglist,
								checkedStatus: false,
							};
						});

						return {
							...item,
							subList: orgarray,
							checkedStatus: false,
						};
					}
				} else if (ind === 3) {
					if (EVENT_FILTER?.slidebarFilter?.cal_community) {
						var subListComfalse = [];
						var sublistarray = item?.subList?.map((list, index) => {
							if (EVENT_FILTER?.slidebarFilter?.cal_community.includes(list?.community_id)) {
								return {
									...list,
									checkedStatus: true,
								};
							} else {
								subListComfalse.push({
									...list,
									checkedStatus: false,
								});

								return {
									...list,
									checkedStatus: false,
								};
							}
						});

						return {
							...item,
							subList: sublistarray,
							checkedStatus: subListComfalse?.length > 0 ? false : true,
						};
					} else {
						var comArray = item?.subList?.map((list, index) => {
							return {
								...list,
								checkedStatus: false,
							};
						});

						return {
							...item,
							subList: comArray,
							checkedStatus: false,
						};
					}
				} else {
					return {
						...item,
						checkedStatus: false,
					};
				}
			});

			if (eventCheckBoxDetails?.length > 0) {
				// setEventCheckBox(eventCheckBoxDetails);
				var areEqualData = eventCheckBox?.map((item, index) => {
					if (_.isEqual(item, eventCheckBoxDetails[index])) {
						return true;
					}

					setEventCheckBox(eventCheckBoxDetails);
					setsliderListStored(true);

					return false;
				});
			}
		}
	}, [EVENT_FILTER?.slidebarFilter]);
	useEffect(() => {
		if (event_status !== 'draft') {
			if (event_type === 'fight' && !super_event_status) {
				// 16-07
				// if (event_details) {
				//   setStep(1);
				//   currentCreateEventTab(1);
				// }
				// if (event_details && fightSubmit) {
				//   setStep(2);
				//   currentCreateEventTab(2);
				//   // setFightSubmit('')
				// }
				// if (event_details && fightSubmit && event_ticket) {
				//   setStep(3);
				//   currentCreateEventTab(3);
				// }
				// if (event_details && fightSubmit && event_ticket && event_admin) {
				//   setStep(4);
				//   currentCreateEventTab(4);
				// }
				// if (event_details && fightSubmit && event_ticket && event_admin && event_invite) {
				//   setStep(5);
				//   currentCreateEventTab(5);
				// }
				// end
				if (event_details) {
					setStep(1);
					currentCreateEventTab(1);
				}

				if (event_details && fightSubmit) {
					setStep(3);
					currentCreateEventTab(3);
				}

				if (event_details && fightSubmit && event_admin) {
					setStep(4);
					currentCreateEventTab(4);
				}

				if (event_details && fightSubmit && event_admin && event_invite) {
					setStep(5);
					currentCreateEventTab(5);
				}
			} else if (!category || category?.name !== 'Card') {
				// 16-07
				// if (event_details) {
				//   setStep(2);
				//   currentCreateEventTab(2);
				// }
				// if (event_details && event_ticket) {
				//   setStep(3);
				//   currentCreateEventTab(3);
				// }
				// if (event_details && event_ticket && event_admin) {
				//   setStep(4);
				//   currentCreateEventTab(4);
				// }
				// if (event_details && event_ticket && event_admin && event_invite) {
				//   setStep(5);
				//   currentCreateEventTab(5);
				// }
				// end

				if (event_details) {
					setStep(3);
					currentCreateEventTab(3);
				}

				if (event_details && event_admin) {
					setStep(4);
					currentCreateEventTab(4);
				}

				if (event_details && event_admin && event_invite) {
					setStep(5);
					currentCreateEventTab(5);
				}
			}
		}
		// eslint-disable-next-line
	}, [event_details, event_ticket, event_admin, event_invite, fightSubmit, event_status]);
	useEffect(() => {
		if (event_confirm === 'update' && event_type === 'fight' && !super_event_status) {
			if (event_fight_card?.length === 0) {
				var fightArr = [{ id: 0 }];

				setFightData(fightArr);
			} else {
				var numberOFfight =
					event_fight_card &&
					event_fight_card?.map((item, index) => {
						return { id: index };
					});

				setFightData(numberOFfight);
				var fightobj =
					numberOFfight &&
					numberOFfight?.map((item, index) => {
						event_fight_card &&
							event_fight_card[index] &&
							console.log(event_fight_card[index], index, 'event_fight_cardevent_fight_card iii fff');

						if (event_fight_card && event_fight_card[index]) {
							return event_fight_card[index];
						}

						return {};
					});

				// }
				eventFightCard(fightobj);
			}
		}
	}, [event_confirm]);
	useEffect(() => {
		// if (location.state && location.state.fightCard === 'fightCard') {
		//   setStep(1);
		//   currentCreateEventTab(1)
		//   setFightStep(0);
		// }
		// else if (event_confirm === 'update') {
		// setStep(0);
		// currentCreateEventTab(0);
		// }
		if (event_confirm === 'update') {
			setStep(0);
			currentCreateEventTab(0);
		}
	}, [event_confirm, location.state]);
	useEffect(() => {
		if (event_type !== undefined && fightStep !== current_fight_tab) {
			currentFightTab(fightStep);
		}
	}, [fightStep]);
	useEffect(() => {
		current_tab !== undefined && current_tab !== '' && setStep(current_tab);
	}, [current_tab]);
	useEffect(() => {
		// fightData && eventFightCard(fightData);
		// var obj = {};
		// // fightData && eventFightCard(Array.from({ length: fightData.length }, (x, i) => obj));
		// if (event_confirm === 'update' && event_type === 'fight' && !super_event_status) {
		//   var numberOFfight = event_fight_card && event_fight_card.map((item, index) => {
		//     return ({ id: index })
		//   })
		//   setFightData(numberOFfight);
		// }
		// else {
		if (event_confirm !== undefined) {
			if (fightData && event_confirm !== 'update') {
				let fightobj =
					fightData &&
					fightData.map((item, index) => {
						// event_fight_card && event_fight_card[index] && console.log(event_fight_card[index], index, "event_fight_cardevent_fight_card iii fff")
						if (event_fight_card && event_fight_card[index]) {
							// console.log(event_fight_card[index], "event_fight_cardevent_fight_card iii")
							return event_fight_card[index];
						}

						return {};
					});

				eventFightCard(fightobj);
			}

			if (event_confirm === 'update' && event_type === 'fight' && !super_event_status) {
				if (fightData?.length >= event_fight_card?.length) {
					let fightobj =
						fightData &&
						fightData.map((item, index) => {
							event_fight_card &&
								event_fight_card[index] &&
								console.log(event_fight_card[index], index, 'event_fight_cardevent_fight_card iii fff');

							if (event_fight_card && event_fight_card[index]) {
								return event_fight_card[index];
							}

							return {};
						});

					fightobj && eventFightCard(fightobj);
				}
			}
		}
		// }
	}, [fightData, event_confirm]);
	useEffect(() => {
		if (event_status && event_status === 'draft') {
			EventEmitter.subscribe('create_draft_event', (data) => {
				data && createDraftEvent(data);
			});
		}
	}, [event_status]);
	var eventType = eventTypeList && eventTypeList.find((item) => item?.id === event_type);

	return (
		<>
			{type === 'create' ? (
				// CREATE NEW EVENT
				<Box className="events-left-side-create">
					<Box className="new-event-box-head">
						<Box className="new-event-title">
							<IconButton component={NavLink} to="/events/discover">
								<BackArrowicon />
							</IconButton>
							<Typography className="box-title">{t('events.new_event')}</Typography>
						</Box>
						<IconButton>
							<DeleteIcon />
						</IconButton>
					</Box>
					<Box className="event-type-box">
						<Typography className="event-type">{t('events.event_type')}:</Typography>
						{eventType?.name && <Typography className="event-type event-type-blue">{eventType?.name}</Typography>}
						{/* {eventType && eventType[0].name &&
              <Typography className='event-type event-type-blue'>
                {eventType[0].name}
              </Typography>
            } */}
					</Box>
					{category?.name && category?.name === 'Card' ? (
						<CardLeftSide
							create_data={create_data}
							currentCreateEventTab={currentCreateEventTab}
							createEvents={createEvents}
							currentSuperEventPage={currentSuperEventPage}
							eventFightCard={eventFightCard}
							currentFightTab={currentFightTab}
							cardCategory={cardCategory}
						/>
					) : (
						<Box className="create-es-bx">
							<Box className="event-stepper-box">
								<List>
									<ListItem
										button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										selected={step === 0}
									>
										<ListItemIcon>{step === 0 ? <ActiveIcon /> : event_details ? <CompleteIcon /> : null}</ListItemIcon>
										<ListItemText
											primary="Event Details"
											onClick={() => {
												setStep(0);
												currentCreateEventTab(0);
											}}
										/>
									</ListItem>
									{event_type === 'fight' && !super_event_status ? (
										<Box className="add-fight-sec">
											<Typography className="add-fight-text" onClick={() => addFights()}>
												{t('events.add_fight')}
											</Typography>
											{event_type && event_type === 'fight' && (
												<List className="fight-list-sec">
													{event_type === 'fight' &&
														fightData &&
														fightData?.map((data, index) => {
															console.log(data, 'fightDatafightDatafightData');

															return (
																<ListItem
																	button
																	disableElevation
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	selected={step === 1}
																	className={
																		event_details && event_fight_card && event_fight_card[index]
																			? 'fight-left-sec'
																			: 'fight-left-sec cursor-text'
																	}
																>
																	<ListItemIcon className="active-fight-button">
																		<ListItemIcon>
																			{step === 1 && data.id === fightStep ? (
																				<ActiveIcon />
																			) : event_fight_card &&
																			  event_fight_card[index] &&
																			  event_fight_card[index]?.event_fight ? (
																				<CompleteIcon />
																			) : null}
																		</ListItemIcon>
																	</ListItemIcon>
																	<ListItemIcon>
																		<ToggleIcon />
																	</ListItemIcon>
																	<ListItemText
																		primary={'Fight ' + (index + 1)}
																		onClick={() => {
																			if (
																				event_details &&
																				validate(create_data?.event_fight_card[data?.id], 'clickEvent')
																			) {
																				setStep(1);
																				currentCreateEventTab(1);
																				setFightStep(index);
																				currentFightTab(index);
																			}
																		}}
																	/>
																	{index !== 0 && (
																		<ListItemIcon
																			onClick={() => {
																				deleteFight(index);
																			}}
																		>
																			<DeleteIcon />
																		</ListItemIcon>
																	)}
																</ListItem>
															);
														})}
												</List>
											)}
										</Box>
									) : null}
									{/* <ListItem
                    button
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    selected={step === 2}
                  >
                    <ListItemIcon>
                      <ListItemIcon>
                        {step === 2 ? (
                          <ActiveIcon />
                        ) : event_ticket ? (
                          <CompleteIcon />
                        ) : null}
                      </ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary="Tickets" />
                  </ListItem> */}
									<ListItem
										button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										selected={step === 3}
										className={event_details ? '' : 'cursor-text'}
									>
										<ListItemIcon>{step === 3 ? <ActiveIcon /> : event_admin ? <CompleteIcon /> : null}</ListItemIcon>
										<ListItemText
											primary="Admins"
											onClick={() => {
												if (event_type === 'fight' && !super_event_status) {
													if (fightSubmit) {
														setStep(3);
														currentCreateEventTab(3);
													}
												} else {
													if (event_details) {
														setStep(3);
														currentCreateEventTab(3);
													}
												}
											}}
										/>
									</ListItem>
									<ListItem
										button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										selected={step === 4}
										className={event_admin ? '' : 'cursor-text'}
									>
										<ListItemIcon>{step === 4 ? <ActiveIcon /> : event_invite ? <CompleteIcon /> : null}</ListItemIcon>
										<ListItemText
											primary="Invite"
											onClick={() => {
												if (event_admin) {
													setStep(4);
													currentCreateEventTab(4);
												}
											}}
										/>
									</ListItem>
									<ListItem
										button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										selected={step === 5}
										className={event_invite ? '' : 'cursor-text'}
									>
										<ListItemIcon>
											<ListItemIcon>{step === 5 ? <ActiveIcon /> : null}</ListItemIcon>
										</ListItemIcon>
										<ListItemText
											primary="Confirm"
											onClick={() => {
												if (event_invite) {
													setStep(5);
													currentCreateEventTab(5);
												}
											}}
										/>
									</ListItem>
								</List>
							</Box>

							{/* NEXT - DRAFT BUTTON */}
							<Box className="next-draft-bx">
								{/* NEXT BUTTON */}
								{step === 0 ? (
									<Button
										fullWidth
										className="ndraft-btn next-btn"
										onClick={() => {
											if (event_type === 'fight' && !super_event_status) {
												eventNextHandler(1);
												eventStatus('published');
											} else {
												eventNextHandler(3);
												eventStatus('published');
											}
										}}
									>
										{t('next')}
									</Button>
								) : step === 1 ? (
									fightData.map((data, index) => {
										if (fightStep === index) {
											return (
												<Box display="flex">
													<Button
														fullWidth
														className="ndraft-btn back-btn"
														onClick={() => {
															fightStep > 0 ? setFightStep(fightStep - 1) : eventBackHandler(0);
														}}
													>
														{t('back')}
													</Button>
													<Button
														fullWidth
														className="ndraft-btn next-btn"
														onClick={() => {
															EventEmitter.dispatch('submit_Fight_details');

															if (fightStep < fightData.length - 1) {
																if (validate(create_data?.event_fight_card[data?.id])) {
																	setFightStep(fightStep + 1);
																}
															} else {
																if (validate(create_data?.event_fight_card[data?.id])) {
																	eventNextHandler(3, 'fight');
																}
															}
														}}
													>
														{t('next')}
													</Button>
												</Box>
											);
										} else {
											return <></>;
										}
									})
								) : step === 2 ? (
									<Box display="flex">
										<Button
											fullWidth
											className="ndraft-btn back-btn"
											onClick={() => {
												event_type === 'fight' && !super_event_status
													? eventBackHandler(1, 'fight')
													: eventBackHandler(0);
												// (event_type === 'fight' && !super_event_status) ? eventBackHandler(1, "fight") : eventBackHandler(0);
											}}
										>
											{t('back')}
										</Button>
										<Button
											fullWidth
											className="ndraft-btn next-btn"
											onClick={() => {
												eventNextHandler(3);
											}}
										>
											{t('next')}
										</Button>
									</Box>
								) : step === 3 ? (
									<Box display="flex">
										<Button
											fullWidth
											className="ndraft-btn back-btn"
											onClick={() => {
												// eventBackHandler(2);
												event_type === 'fight' && !super_event_status
													? eventBackHandler(1, 'fight')
													: eventBackHandler(0);
											}}
										>
											{t('back')}
										</Button>
										<Button
											fullWidth
											className="ndraft-btn next-btn"
											onClick={() => {
												eventNextHandler(4);
											}}
										>
											{t('next')}
										</Button>
									</Box>
								) : step === 4 ? (
									<Box display="flex">
										<Button
											fullWidth
											className="ndraft-btn back-btn"
											onClick={() => {
												eventBackHandler(3);
											}}
										>
											{t('back')}
										</Button>
										<Button
											fullWidth
											className="ndraft-btn next-btn"
											onClick={() => {
												eventNextHandler(5);
											}}
										>
											{t('next')}
										</Button>
									</Box>
								) : step === 5 ? (
									<Box display="flex">
										<Button
											fullWidth
											className="ndraft-btn back-btn"
											onClick={() => {
												eventBackHandler(4);
											}}
										>
											{t('back')}
										</Button>
										<Button
											fullWidth
											disabled={loader}
											className="ndraft-btn next-btn"
											onClick={() => {
												eventNextHandler(0);
												createEvents();
											}}
										>
											{t('events.publish')}
										</Button>
									</Box>
								) : (
									<Button fullWidth className="ndraft-btn next-btn" onClick={() => eventNextHandler(0)}>
										{t('next')}
									</Button>
								)}
								{/* <Button
                  fullWidth
                  className="ndraft-btn next-btn"
                  onClick={eventNextHandler}
                >
                  {t('next')}
                </Button> */}

								{/* SAVE DRAFT */}
								<Button
									fullWidth
									className="ndraft-btn"
									disabled={loading}
									onClick={() => {
										// eventStatus('draft');
										saveToDraftEvent();
										// createEvents('draft');
										eventStatus('draft');
									}}
								>
									{t('events.save_draft')}
								</Button>
							</Box>
						</Box>
					)}
				</Box>
			) : type === 'discover' ? (
				// DEFAULT LEFT SIDE VIEW
				<Box className="events-left-side-block">
					<Box className="basic-event-left-side">
						<Typography className="block-title"> {t('events.events')}</Typography>
						{/* <Box className="search-box">
              <SearchTextField
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box> */}
						{/* CREATE EVENT */}
						<Button
							variant="contained"
							fullWidth
							startIcon={<AddIcon />}
							className="create-event-btn"
							// component={NavLink}
							// to="/events/create"
							onClick={() => handleNewEvent()}
						>
							{t('events.create_event')}
						</Button>

						{/* DISCOVER / YOUR EVENTS BUTTON */}
						{/* <Box className="dy-events-btns">
              <Button
                fullWidth
                disableElevation
                disableRipple
                disableTouchRipple
                disableFocusRipple
                component={NavLink}
                to="/events/discover"
                className="dy-btn discover-btn"
                activeClassName="dy-btn discover-btn active"
              >
                Discover Events
              </Button>

              <Button
                fullWidth
                disableElevation
                disableRipple
                disableTouchRipple
                disableFocusRipple
                component={NavLink}
                to="/events/my-events"
                className="dy-btn discover-btn"
                activeClassName="dy-btn discover-btn active"
              >
                Your Events
              </Button>
            </Box> */}
						{/* <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <List>
                    {eventCheckBox.map((value, index) => {
                      const labelId = `checkbox-list-label-${index}`;
                      return (
                        <>
                          <ListItem key={index} disablePadding>
                            <ListItemIcon >
                              <Checkbox
                                edge="start"
                                // checked={checked.indexOf(value?.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value?.name} />
                          </ListItem>
                          <List style={{ paddingLeft: '20px' }}>
                            {
                              value?.subList.map((sitem, j) => {
                                return (
                                  <ListItem key={j} disablePadding>
                                    <ListItemIcon >
                                      <Checkbox
                                        edge="start"
                                        // checked={checked.indexOf(value?.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={sitem?.name} />
                                  </ListItem>
                                )
                              })
                            }
                          </List>
                        </>
                      )
                    })
                    }
                  </List>
                </Grid>
              </Grid>
            </Box> */}
						<Box className="event-check-box-sec">
							<Grid container spacing={3}>
								<Grid item xs={12} md={12}>
									{eventCheckBox?.map((events, index) => {
										const labelId = `checkbox-list-label-${index}`;

										return (
											<>
												{(events?.subList?.length > 0 ||
													events?.type === 'cal_main' ||
													events?.type === 'cal_user') && (
													<FormControlLabel
														label={events?.name}
														control={
															<Checkbox
																checked={events?.checkedStatus}
																// checked={checked[0] && checked[1]}
																// indeterminate={checked[0] !== checked[1]}
																onChange={(e) => {
																	handleCheckParent(index, e);
																}}
															/>
														}
													/>
												)}

												<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
													{events?.subList &&
														events?.subList.map((sitem, sindex) => {
															return (
																<FormControlLabel
																	label={sitem?.name}
																	control={
																		<Checkbox
																			checked={sitem?.checkedStatus}
																			onChange={(e) => {
																				handleCheckChild(sindex, index, e);
																			}}
																		/>
																	}
																/>
															);
														})}
												</Box>
											</>
										);
									})}
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			) : (
				''
			)}
		</>
	);
};
