import { useState, useContext } from 'react';
import { Avatar, Box, Typography, Popover, IconButton, Link, Tooltip } from '@material-ui/core';
import './Messagechats.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThreeDots } from 'src/assets/images/icons/3-vertical-dots.svg';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { LightBox } from 'src/components/ui';
import ReactPlayer from 'react-player';
import moment from 'moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { ReactComponent as ForwardIcon } from 'src/assets/images/icons/forwardIcon.svg';
import { SocketContext } from 'src/helpers/context/socket';
import { getFields } from 'src/helpers/commonFunctions';

// import { ReactComponent as Polygon } from 'src/assets/images/icons/polygon.svg'
// import { setApiMessage } from 'src/helpers/commonFunctions';

export const MessageChats = ({
	refs,
	handleDeleteMessage,
	handleReplyMessage,
	chatHistory,
	user,
	currentGroupChat,
	handleForwardMessage,
}) => {
	// const [perL, setperR] = useState(false);
	const [rfBox, setrfBox] = useState(false);
	const [msg, setMsg] = useState();
	const { t } = useTranslation();
	const userSocket = useContext(SocketContext)?.socket;
	// const handleRfBox = () => {
	//     rfBox ? setrfBox(false) : setrfBox(true);
	// }

	const [anchorEl, setAnchorEl] = useState(null);
	const [lAnchorEl, setlAnchorEl] = useState(null);
	const handleRfBox = (event, data) => {
		setAnchorEl(event.currentTarget);
		setMsg(data);
		rfBox ? setrfBox(false) : setrfBox(true);
	};
	const handleleftRfBox = (event, data) => {
		setlAnchorEl(event.currentTarget);
		setMsg(data);
		rfBox ? setrfBox(false) : setrfBox(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleleftClose = () => {
		setlAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const leftopen = Boolean(lAnchorEl);
	const leftid = leftopen ? 'simple-popover' : undefined;

	const handleRemove = (data) => {
		// console.log(data, "data")
		handleClose();
		handleleftClose();
		userSocket.emit('delete-message', {
			user_id: user?.user_id,
			message_id: data?._id,
			delete_type: 2,
			group_id: currentGroupChat?.id,
		});
		// userSocket.on('DeleteMessageSocket', data => {
		//     const groupChatHistory = [...chatHistory];
		//     var fileterData = groupChatHistory.filter(item => item?._id !== data?._id)
		//     handleDeleteMessage(fileterData);
		//     console.log(data, chatHistory, "DeleteMessageSocket");
		// })
	};
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const renderChatMessage = (item) => {
		const otherUserId = item?.user?._id === user?.user_id ? false : true;

		switch (item?.type) {
			case 'string':
				return (
					<>
						{renderHTML(
							anchorme({
								input: item?.text.toString(),
								options: {
									attributes: {
										target: '_blank',
										class: 'detected',
									},
								},
							}),
						)}
					</>
				);
			case 'image': {
				let imageurlArray = [];

				chatHistory.map((msg) => {
					var imageArr = {
						src: msg?.image,
						downloadUrl: msg?.image,
					};

					msg.type === 'image' && msg.image !== '' && imageurlArray.push(imageArr);

					return msg;
				});

				return (
					<Box className="message-img-sec">
						<LightBox imageurl={imageurlArray} image={item?.image} otherUserId={otherUserId} />
					</Box>
				);
			}
			case 'image_text':
				return (
					<Box className="mix-text">
						<Avatar src={item?.image} />
						<Typography>
							{renderHTML(
								anchorme({
									input: item?.text.toString(),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
						</Typography>
					</Box>
				);
			case 'audio':
				return (
					<ReactPlayer
						className="react-player audio-player"
						url={item?.audiourl}
						playing={false}
						width="100%"
						height="100%"
						controls={true}
					/>
				);
			case 'audio_text':
				return (
					<>
						{/* // <Box className='mix-text'> */}
						<ReactPlayer
							className="react-player audio-player"
							url={item?.audiourl}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
						<Typography>
							{renderHTML(
								anchorme({
									input: item?.text.toString(),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
						</Typography>
						{/* // </Box> */}
					</>
				);
			case 'video':
				return (
					<ReactPlayer
						className="react-player"
						url={item?.videourl}
						playing={false}
						width="100%"
						height="100%"
						controls={true}
					/>
				);
			case 'video_text':
				return (
					<Box className="mix-text">
						<ReactPlayer
							className="react-player"
							url={item?.videourl}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
						<Typography>
							{renderHTML(
								anchorme({
									input: item?.text.toString(),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
						</Typography>
					</Box>
				);
			case 'create_group':
				return (
					<>
						{item?.performed_by.id !== undefined && item?.performed_by.id === user?.user_id
							? 'you have created a group'
							: item?.performed_by.name + ' created group'}
					</>
				);
			case 'doc':
				return (
					<Box className="message-doc-view">
						<Link href={item?.docurl} target="_blank" className="doc-link">
							{renderSwitchForDocumentIcon(item?.docurl)}
						</Link>
					</Box>
				);
			case 'doc_text':
				return (
					<Box className="mix-text">
						<Box className="message-doc-view">
							<Link href={item?.docurl} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.docurl)}
							</Link>
						</Box>
						<Typography>
							{renderHTML(
								anchorme({
									input: item?.text.toString(),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
						</Typography>
					</Box>
				);
		}
	};
	const messageReply = (item) => {
		// const otherUserId = item?.user?._id === user?.user_id ? false : true;
		switch (item?.reply?.messageType) {
			case 'string':
				return (
					<>
						<Box className="message-reply-sec">
							<Box className="message-reply-left-sec">
								<Typography className="messages-user-name">
									{item?.reply?.messageFromID === user?.user_id ? t('messages.you') : item?.reply?.messageFromName}
								</Typography>
								<Typography className="message-type">
									{renderHTML(
										anchorme({
											input: item?.reply?.messageContent.toString(),
											options: {
												attributes: {
													target: '_blank',
													class: 'detected',
												},
											},
										}),
									)}
								</Typography>
							</Box>
						</Box>
						{/* <Typography className='messages-user-name'>{replyMessagesData?.user?.name}</Typography> */}
					</>
				);
			case 'image_text':
			case 'image':
				return (
					<Box className="message-reply-sec">
						<Box className="message-reply-left-sec">
							<Typography className="messages-user-name">
								{item?.reply?.messageFromID === user?.user_id ? t('messages.you') : item?.reply?.messageFromName}
							</Typography>
							<Typography className="message-type">{item?.reply?.messageType}</Typography>
						</Box>
						<Avatar className="reply-image" variant="square" src={item?.reply?.messageContent} />
					</Box>
				);
			case 'audio_text':
			case 'audio':
				return (
					<Box className="message-reply-sec">
						<Box className="message-reply-left-sec">
							<Typography className="messages-user-name">
								{item?.reply?.messageFromID === user?.user_id ? t('messages.you') : item?.reply?.messageFromName}
							</Typography>
							<Typography className="message-type">{item?.reply?.messageType}</Typography>
						</Box>
					</Box>
				);
			case 'video_text':
			case 'video':
				return (
					<Box className="message-reply-sec">
						<Box className="message-reply-left-sec">
							<Typography className="messages-user-name">
								{item?.reply?.messageFromID === user?.user_id ? t('messages.you') : item?.reply?.messageFromName}
							</Typography>
							<Typography className="message-type">{item?.reply?.messageType}</Typography>
						</Box>
					</Box>
				);
			case 'doc_text':
			case 'doc':
				return (
					<Box className="message-reply-sec">
						<Box className="message-reply-left-sec">
							<Typography className="messages-user-name">
								{item?.reply?.messageFromID === user?.user_id ? t('messages.you') : item?.reply?.messageFromName}
							</Typography>
							<Typography className="message-type">{item?.reply?.messageType}</Typography>
						</Box>
					</Box>
				);
		}
	};

	// useEffect(() => {
	//     var filterData = chatHistory.filter(item => item?._id === currentGroupChat?.unread_message_id)
	//     var msgid = filterData?.length > 0 && filterData[0]?._id;
	//     filterData?.length > 0 && currentGroupChat?.unread_message_id && currentGroupChat?.unread_message_id !== 0 && (
	//         refs[msgid].current.scrollIntoView({
	//             behavior: 'smooth',
	//             block: 'start',
	//         })
	//     )
	//     console.log('currentGroupChatcurrentGroupChat', filterData, chatHistory, currentGroupChat)
	// }, [currentGroupChat, chatHistory])
	return (
		<>
			<Box className="messages-chats-box">
				{chatHistory &&
					chatHistory.map((person, index) => {
						var mDate = moment(person?.createdAt).format('hh:mm A');
						var userNameArray = getFields(person?.message_seen_data, 'user_full_name');
						var messageSeenByUser = userNameArray.join(', ');

						return (
							<Box ref={refs[person._id]}>
								{index === 0 && (
									<Typography className="right-date-sec">
										{moment(moment(person?.createdAt)).calendar(null, {
											sameDay: '[Today]',
											nextDay: '[Tomorrow]',
											nextWeek: 'DD/MM/YYYY',
											lastDay: '[Yesterday]',
											lastWeek: 'DD/MM/YYYY',
											sameElse: function (now) {
												var diff = moment(now).diff(moment(person?.createdAt), 'days');

												return diff <= 8 ? 'dddd' : 'DD/MM/YYYY';
											},
										})}
									</Typography>
								)}
								{index > 0 &&
									moment(chatHistory[index - 1]?.createdAt).format('DD/MM/YYYY') !==
										moment(person?.createdAt).format('DD/MM/YYYY') && (
										<Typography className="right-date-sec">
											{moment(moment(person?.createdAt)).calendar(null, {
												sameDay: '[Today]',
												nextDay: '[Tomorrow]',
												nextWeek: 'DD/MM/YYYY',
												lastDay: '[Yesterday]',
												lastWeek: 'DD/MM/YYYY',
												sameElse: function (now) {
													var diff = moment(now).diff(moment(person?.createdAt), 'days');

													return diff <= 8 ? 'dddd' : 'DD/MM/YYYY';
												},
											})}
										</Typography>
									)}
								{person?.user?._id !== user?.user_id ? (
									<Box
										className="chat-left-person"
										style={{ justifyContent: person?.type === 'create_group' && 'center' }}
									>
										{person?.type !== 'create_group' && (
											<Tooltip title={person?.user?.name} arrow>
												<Avatar src={person?.user?.avatar} className="avatar-chat"></Avatar>
											</Tooltip>
										)}
										<Box className="message-chats">
											{person?.forward && (
												<Box className="forwarded-message">
													<ForwardIcon />
													{/* <Typography className='message-forward-text'>
                                               {t('messages.forward')}
                                            </Typography> */}
												</Box>
											)}
											<>{person?.reply && messageReply(person)}</>
											{renderChatMessage(person)}
											<Typography className="time-chat left-time-chat">{mDate}</Typography>
										</Box>
										{rfBox && (
											<Popover
												className="cr-popover"
												id={leftid}
												open={leftopen}
												anchorEl={lAnchorEl}
												onClose={handleleftClose}
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
											>
												<Box className="message-remove-forword-sec">
													{/* <Typography className='message-rf-text' onClick={() => handleRemove(msg)}>
                                                    {t('messages.remove')}
                                                </Typography> */}
													<Typography
														className="message-rf-text"
														onClick={() => {
															handleClose();
															handleleftClose();
															handleForwardMessage(t('messages.forward_message'), msg);
														}}
													>
														{t('messages.forward')}
													</Typography>
													<Typography
														className="message-rf-text"
														onClick={() => {
															handleReplyMessage(msg, true);
															setrfBox(false);
														}}
													>
														{t('messages.reply')}
													</Typography>
												</Box>
											</Popover>
										)}
										{person?.type !== 'create_group' && (
											<IconButton
												className="message-three-dots"
												onClick={(e) => {
													handleleftRfBox(e, person);
												}}
											>
												<ThreeDots className="svg-three-dots" />
											</IconButton>
										)}
									</Box>
								) : (
									<>
										<Box
											className="chat-right-person"
											style={{ justifyContent: person?.type === 'create_group' && 'center' }}
										>
											{rfBox && (
												<Popover
													className="cr-popover"
													id={id}
													open={open}
													anchorEl={anchorEl}
													onClose={handleClose}
													transformOrigin={{
														horizontal: 'right',
														vertical: 'top',
													}}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'left',
													}}
												>
													<Box className="message-remove-forword-sec">
														<Typography className="message-rf-text" onClick={() => handleRemove(msg)}>
															{t('messages.remove')}
														</Typography>
														<Typography
															className="message-rf-text"
															onClick={() => {
																handleClose();
																handleleftClose();
																handleForwardMessage(t('messages.forward_message'), msg);
															}}
														>
															{t('messages.forward')}
														</Typography>
														<Typography
															className="message-rf-text"
															onClick={() => {
																handleReplyMessage(msg, true);
																setrfBox(false);
															}}
														>
															{t('messages.reply')}
														</Typography>
													</Box>
												</Popover>
											)}
											{person?.type !== 'create_group' && (
												<IconButton
													className="message-three-dots"
													onClick={(e) => {
														handleRfBox(e, person);
													}}
												>
													<ThreeDots className="svg-three-dots" />
												</IconButton>
											)}
											<Box className="message-chats">
												{person?.forward && (
													<Box className="forwarded-message">
														<ForwardIcon />
														{/* <Typography className='message-forward-text'>
                                               {t('messages.forward')}
                                            </Typography> */}
													</Box>
												)}
												<>{person?.reply && messageReply(person)}</>
												{renderChatMessage(person)}
												{person?.type !== 'create_group' && (
													<Typography className="time-chat right-time-chat">{mDate}</Typography>
												)}
											</Box>
											{person?.type !== 'create_group' && <Avatar src={user?.profile_pic} className="avatar-chat" />}
										</Box>
										{messageSeenByUser && (
											<Box className="message-seen-by-sec">
												<Typography variant="span" className="message-seen-by-text">
													{t('messages.seen_by')}
												</Typography>
												<Typography variant="span" className="message-seen-name">
													{messageSeenByUser}
												</Typography>
											</Box>
										)}
									</>
								)}
							</Box>
						);
					})}
			</Box>
		</>
	);
};
