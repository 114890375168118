import { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography, IconButton, Button, Popover, ListItemText, MenuItem } from '@material-ui/core';
// import TabContext from '@material-ui/lab/TabContext';
// import TabList from '@material-ui/lab/TabList';
import { CommunityItem, MultipleSelectCheckmarks } from 'src/components/ui';
// import { ReactComponent as BestIcon } from 'src/assets/images/icons/best.svg';
// import { ReactComponent as PopularIcon } from 'src/assets/images/icons/popular.svg';
// import { ReactComponent as DateIcon } from 'src/assets/images/icons/date.svg';
// import { ReactComponent as TopIcon } from 'src/assets/images/icons/top.svg';
// import { ReactComponent as RecommendedIcon } from 'src/assets/images/icons/recommend.svg';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import { ReactComponent as CardIcon } from 'src/assets/images/icons/community/card.svg';
import { ReactComponent as CardSmall } from 'src/assets/images/icons/community/cardsmall.svg';
// import { ReactComponent as ListIcon } from 'src/assets/images/icons/community/lists.svg';
import { ReactComponent as ListView } from 'src/assets/images/icons/community/list.svg';
import { ReactComponent as FilterIcon } from 'src/assets/images/icons/community/filter.svg';
import './browse.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useNavigate } from 'react-router-dom';
import { objectToQueryString } from 'src/helpers/commonFunctions'; // arrayJoin
// import { parse } from 'query-string';
import { fetchFromStorage, saveToStorage, removeFromStorage } from 'src/helpers/context';
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ViewPortContext } from 'src/App';
import { CommunityDirectory } from '../CommunityDirectory';
import { CommunityListView } from '../CommunityListView';
import { useTranslation } from 'react-i18next';

export const CommunityBrowse = ({
	token,
	user,
	fetchCommunityList,
	communityAction,
	orderBy,
	setOrderBy,
	loader,
	hasMore,
	width,
	breakpoint,
}) => {
	const { t } = useTranslation();
	// const location = useLocation();
	// const query = parse(location.search);
	// const [selected, setSelected] = useState([]);

	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	var viewPort = windowWidth <= breakPoint ? true : false;

	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [view, setView] = useState('card');
	const [disciplineData, setDisciplineData] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [badgeList, setBadgeList] = useState([]);
	const [selectedBadge, setSelectedBadge] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedDiscipline, setSelectedDiscipline] = useState([]);
	const [selectedProfileType, setSelectedProfileType] = useState([]);
	const [reportReasonList, setReportReasonList] = useState([]);
	const [selectedMemberShip, setSelectedMemberShip] = useState([]);

	// const [tab, setTab] = useState('1');

	// const tabChangeHandler = (event, newValue) => {
	//   setTab(newValue);
	// };

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// FILTER TAG LIST
	// const filterList = [
	//   { id: 1, name: t('community.atheletes') },
	//   { id: 2, name: t('community.coaches') },
	//   { id: 3, name: t('community.instructors') },
	//   { id: 4, name: t('community.fans') },
	//   { id: 5, name: t('community.promotors') },
	//   { id: 6, name: t('community.sponsors') },
	//   { id: 7, name: t('community.gyms') },
	//   { id: 8, name: t('community.under_18s') },
	//   { id: 9, name: t('community.unverified') }
	// ];

	// // BUTTON TAG LIST
	// const btnList = [
	//   { id: '1', name: t('community.best') },
	//   { id: '2', name: t('community.popular') },
	//   { id: '3', name: t('community.date') },
	//   { id: '4', name: t('community.top') },
	//   { id: '5', name: t('community.recommended') }
	// ];
	// PROFILE TYPE
	const restTypeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];
	const memberTypeList = [
		{ id: 'member', name: t('profile_type.member') },
		{ id: 'moderator', name: t('profile_type.moderator') },
		{ id: 'admin', name: t('profile_type.admin') },
		{ id: 'owner', name: t('profile_type.owner') },
	];

	// FETCH REPORT REASON LIST
	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH DISCIPLINE TYPES DATA
	const fetchDisciplineTypesData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getDisciplinesData, authHeader);

			if (status === 200) {
				setDisciplineData(data?.getDisciplinesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH CATEGORY TYPES DATA
	const fetchCategoryData = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCategory);

			if (status === 200) {
				setCategoryData(data?.metaCategoriesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH BADGE DATA
	const fetchBadgeList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllBadgeList);

			if (status === 200) {
				setBadgeList(data?.getBadgesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FILTER TAG SELET HANDLER
	// const handleToggle = value => () => {
	//   const currentIndex = selected.indexOf(value);
	//   const newSelected = [...selected];
	//   if (currentIndex === -1) {
	//     newSelected.push(value);
	//   } else {
	//     newSelected.splice(currentIndex, 1);
	//   }
	//   setSelected(newSelected);
	// };

	// BUTTON ICON HANDLER
	// const btnIconHandler = type => {
	//   switch (type) {
	//     case '1':
	//       return <BestIcon />;
	//     case '2':
	//       return <PopularIcon />;
	//     case '3':
	//       return <DateIcon />;
	//     case '4':
	//       return <TopIcon />;
	//     case '5':
	//       return <RecommendedIcon />;
	//     default:
	//       return <BestIcon />;
	//   }
	// };

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const onBadgeSelect = (data) => {
		// setSelectedBadge(data);
		const currentIndex = selectedBadge.indexOf(data);
		const newChecked = [...selectedBadge];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedBadge(newChecked);
	};
	const onCategorySelect = (data) => {
		// setSelectedCategory(data);
		const currentIndex = selectedCategory.indexOf(data);
		const newChecked = [...selectedCategory];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedCategory(newChecked);
	};
	const onDisciplineSelect = (data) => {
		// setSelectedDiscipline(data);
		const currentIndex = selectedDiscipline.indexOf(data);
		const newChecked = [...selectedDiscipline];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedDiscipline(newChecked);
	};
	const onMembershipTypeSelect = (data) => {
		const currentIndex = selectedMemberShip.indexOf(data);
		const newChecked = [...selectedMemberShip];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedMemberShip(newChecked);
	};
	const onProfileTypeSelect = (data) => {
		// setSelectedProfileType(data);
		const currentIndex = selectedProfileType.indexOf(data);
		const newChecked = [...selectedProfileType];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedProfileType(newChecked);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const communityList = useSelector((state) => state?.Combat?.communityList);

	const handleFilter = () => {
		var badgeQS = selectedBadge.join(',');
		var queryParams = {
			...(selectedBadge.length > 0 && { badges: badgeQS }),
			...(selectedCategory.length > 0 && {
				categories: selectedCategory.join(','),
			}),
			...(selectedDiscipline.length > 0 && {
				disciplines: selectedDiscipline.join(','),
			}),
			...(selectedProfileType.length > 0 && {
				profile_types: selectedProfileType.join(','),
			}),
			...(selectedMemberShip.length > 0 && {
				membership_type: selectedMemberShip.join(','),
			}),
		};
		var QUERY_STRING = objectToQueryString(queryParams);

		QUERY_STRING !== '' ? navigate(`/communities?${QUERY_STRING}`) : navigate(`/communities`);
		fetchCommunityList(queryParams, '', 1);

		const COMM_FILTER = fetchFromStorage('communityfilter');
		var obj = {
			...COMM_FILTER,
			badges: queryParams?.badges,
			categories: queryParams?.categories,
			disciplines: queryParams?.disciplines,
			profile_types: queryParams?.profile_types,
			membership_type: queryParams?.membership_type,
			QUERY_STRING: QUERY_STRING,
			queryParams: queryParams,
		};

		saveToStorage('communityfilter', obj);
	};

	const clearFilter = () => {
		navigate('/communities');
		setSelectedBadge([]);
		setSelectedCategory([]);
		setSelectedDiscipline([]);
		setSelectedProfileType([]);
		setSelectedMemberShip([]);
		const queryParams = {};

		fetchCommunityList(queryParams, '', 1);
		removeFromStorage('communityfilter');
	};

	useEffect(() => {
		fetchBadgeList();
		fetchCategoryData();
		fetchDisciplineTypesData();
		fetchReportReasonList();

		const COMM_FILTER = fetchFromStorage('communityfilter');
		const SB = COMM_FILTER?.badges?.split(',').map((i) => Number(i));
		const SC = COMM_FILTER?.categories?.split(',').map((i) => Number(i));
		const SD = COMM_FILTER?.disciplines?.split(',').map((i) => Number(i));
		const SPT = COMM_FILTER?.profile_types?.split(',').map((i) => Number(i));
		const SMS = COMM_FILTER?.membership_type?.split(',').map((i) => Number(i));

		SB && setSelectedBadge(SB);
		SC && setSelectedCategory(SC);
		SD && setSelectedDiscipline(SD);
		SPT && setSelectedProfileType(SPT);
		SMS && setSelectedMemberShip(SMS);

		// eslint-disable-next-line
	}, []);

	return (
		<Box className="browse-community">
			{/* FILTER TAGS */}
			{width > breakpoint ? (
				<>
					<Box className="filter-box">
						{/* <Typography className="filter-title">
                {t('community.filter')}
              </Typography> */}
						{/* FILTER TAG BOX */}
						<Grid container className="filter-tags">
							<Box style={{ width: '3%' }}>
								<FilterIcon />
							</Box>
							<Box style={{ width: '97%' }} className="filter-options">
								{/*CM-107*/}
								{/*<MultipleSelectCheckmarks*/}
								{/*	labelValue={t('myprofile.badges')}*/}
								{/*	count={selectedBadge?.length}*/}
								{/*	// labelValue={selectedBadge.length > 0 ? arrayJoin(badgeList, selectedBadge, 'name') : t('myprofile.badges')}*/}
								{/*	dropdownArray={badgeList}*/}
								{/*	onSelect={onBadgeSelect}*/}
								{/*	checked={selectedBadge}*/}
								{/*/>*/}
								<MultipleSelectCheckmarks
									labelValue={t('myprofile.categories')}
									count={selectedCategory?.length}
									// labelValue={selectedCategory.length > 0 ? arrayJoin(categoryData, selectedCategory, 'name') : t('myprofile.categories')}
									dropdownArray={categoryData}
									onSelect={onCategorySelect}
									checked={selectedCategory}
								/>
								<MultipleSelectCheckmarks
									labelValue={t('myprofile.specialities')}
									count={selectedDiscipline?.length}
									// labelValue={selectedDiscipline.length > 0 ? arrayJoin(disciplineData, selectedDiscipline, 'name') : t('myprofile.specialities')}
									dropdownArray={disciplineData}
									onSelect={onDisciplineSelect}
									checked={selectedDiscipline}
								/>
								<MultipleSelectCheckmarks
									labelValue={t('profile_type.pts')}
									count={selectedProfileType?.length}
									// labelValue={selectedProfileType.length > 0 ? arrayJoin(restTypeList, selectedProfileType, 'name') : t('profile_type.pts')}
									dropdownArray={restTypeList}
									onSelect={onProfileTypeSelect}
									checked={selectedProfileType}
								/>
								<MultipleSelectCheckmarks
									labelValue={t('profile_type.membership_types')}
									count={selectedMemberShip?.length}
									// labelValue={selectedMemberShip.length > 0 ? arrayJoin(restTypeList, selectedProfileType, 'name') : t('profile_type.pts')}
									dropdownArray={memberTypeList}
									onSelect={onMembershipTypeSelect}
									checked={selectedMemberShip}
								/>
							</Box>
							{/* {filterList.map((data, index) => {
                  return (
                    <Grid item key={index}>
                      <Chip
                        size="small"
                        className={
                          selected.indexOf(data?.id) !== -1
                            ? 'filter-chip checked-chip'
                            : 'filter-chip'
                        }
                        icon={
                          selected.indexOf(data?.id) !== -1 ? (
                            <FilterCheckIcon />
                          ) : null
                        }
                        label={data?.name}
                        onClick={handleToggle(data?.id)}
                      />
                    </Grid>
                  );
                })} */}
						</Grid>
						<Box className="filter-right-buttons" display={'flex'}>
							<Button type="submit" className="filter-btn" onClick={() => handleFilter()}>
								{t('button_text.apply_filter')}
							</Button>
							<Button type="submit" className="filter-btn" onClick={() => clearFilter()}>
								{t('button_text.clear_filter')}
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}

			{/* BEST | POPULAR | DATE | TOP |RECOMMENDED TAB */}
			{/* <Box className="bpdtr-box">
        <TabContext value={String(tab)}>
          <TabList
            variant="scrollable"
            scrollButtons="off"
            onChange={tabChangeHandler}
          >
            {btnList?.map((data, index) => {
              return (
                <Tab
                  key={index}
                  icon={btnIconHandler(data?.id)}
                  label={data?.name}
                  value={data?.id}
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                />
              );
            })}
          </TabList>
        </TabContext>
      </Box> */}

			{/* COMMUNITY LIST */}
			<Grid container className="community-list">
				<Grid item xs={12}>
					<CommunityDirectory
						setOrderBy={setOrderBy}
						orderBy={orderBy}
						fetchCommunityList={fetchCommunityList}
						isTab={viewPort}
						isDirectory={viewPort ? false : false}
					/>
				</Grid>
				<Grid className="community-list-left-block" item lg={8} xs={12}>
					{/* TITLE */}
					<Box className="community-list-left">
						<Typography className="browse-title">{t('community.results')}</Typography>
						<IconButton color="inherit" onClick={handleClick}>
							{view === 'card' ? <CardIcon /> : <ListView height={20} width={21} />}
						</IconButton>
						<Popover
							className="lc-popover"
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<MenuItem
								className={view === 'list' ? 'my-option-menu activelink' : 'my-option-menu'}
								onClick={() => {
									setView('list');
									handleClose();
								}}
							>
								<ListView />
								<ListItemText primary={t('community.list')} />
							</MenuItem>
							<MenuItem
								className={view === 'card' ? 'my-option-menu activelink' : 'my-option-menu'}
								onClick={() => {
									setView('card');
									handleClose();
								}}
							>
								<CardSmall />
								<ListItemText primary={t('community.card')} />
							</MenuItem>
						</Popover>
					</Box>
					{/* {console.log('communityList', communityList)} */}
					{loader ? (
						<>
							<Skeleton variant="rect" height={80} className="mt-10" />
							<Skeleton variant="rect" height={80} className="mt-10" />
						</>
					) : (
						<Box id="scrollableDiv" className="infinity-scroll-view-custom">
							{communityList && communityList.length > 0 ? (
								<InfiniteScroll
									dataLength={communityList?.length}
									next={fetchCommunityList}
									style={{ width: '100%' }} //To put endMessage and loader to the top.
									inverse={false} //
									hasMore={hasMore}
									loader={<h4>{t('loading')}...</h4>}
									scrollableTarget={viewPort ? undefined : 'scrollableDiv'}
								>
									{communityList?.map((data, index) => {
										var post_image = data?.post_list?.PostLists.find((item) => item?.Item?.type === 'image');
										var LastAllMediaList = data?.post_list?.PostLists?.filter((item) => item?.Item?.type !== 'text');

										var post_content = data?.post_list?.PostLists.find((item) => item?.Item?.type === 'text');

										return view === 'card' ? (
											<CommunityItem
												key={index + 'community'}
												linkList={post_content?.Item?.links_list}
												reportReasonList={reportReasonList}
												name={data?.name}
												communityimage={data?.item_profile_data}
												createdAt={moment(data?.timestamp_created).format('MMM DD YYYY, h:mm')}
												members={data?.total_member}
												communityFor={data?.user_type_value}
												postedBy={data?.post_list?.user_full_name}
												userProfile={data?.post_list?.user_meta?.data}
												postTime={
													data?.post_list?.timestamp_created !== null &&
													moment.utc(data?.post_list?.timestamp_created).fromNow().replace('in ', '')
												}
												LastAllMediaList={LastAllMediaList}
												postImg={data?.post_list?.item_profile_id}
												postText={post_content?.Item?.data}
												postTitle={data?.post_list?.name}
												isPostAvail={data?.post_list !== null ? true : false}
												data={data}
												communityAction={communityAction}
												showButton={data?.user_owner_id !== fetchFromStorage('authData')?.user_id ? true : false}
											/>
										) : (
											// eslint-disable-next-line camelcase
											<CommunityListView
												key={index + 'community'}
												data={data}
												communityAction={communityAction}
												name={data?.name}
												communityimage={data?.item_profile_data}
												createdAt={moment(data?.timestamp_created).format('MMM DD YYYY, h:mm')}
												showButton={data?.user_owner_id !== fetchFromStorage('authData')?.user_id ? true : false}
											/>
										);
									})}
								</InfiniteScroll>
							) : (
								<Typography className="no-data-txt">{t('community.no_result_found')}</Typography>
							)}
						</Box>
					)}
				</Grid>
				<Grid item lg={4} xs={12}>
					<CommunityDirectory
						setOrderBy={setOrderBy}
						orderBy={orderBy}
						fetchCommunityList={fetchCommunityList}
						isMobile={false}
						isTab={viewPort ? false : true}
						isDirectory={true}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
