import { useContext, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Box,
	Typography,
	ListItem,
	Checkbox,
	ListItemText,
	List,
	ListItemIcon,
	Button,
	Grid,
	MenuItem,
	Avatar,
	IconButton,
	Popover,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/images/icons/events/info.svg';
import newEventsImage1 from 'src/assets/images/eventsImage/image1.png';
import newEventsImage2 from 'src/assets/images/eventsImage/image2.png';
import newEventsImage3 from 'src/assets/images/eventsImage/image3.png';
import { useTranslation } from 'react-i18next';
import './newEvents.scss';

export const NewEvent = ({
	handleNextEvent,
	eventTypeList,
	eventDetails,
	eventType,
	eventFightCard,
	eventTicket,
	category,
	currentCreateEventTab,
	eventAdmin,
	eventInvite,
	eventFightData,
	superEventStatus,
	supurEventCardData,
	eventConfirm,
}) => {
	const { t } = useTranslation();
	const [checked, setChecked] = useState(false);
	const [eventTypeName, setEventTypeName] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorSupEl, setAnchorSupEl] = useState(null);
	const [categoryList, setCategoryList] = useState([]);
	const opens = Boolean(anchorEl);
	const ids = opens ? 'simple-popover' : undefined;
	const open = Boolean(anchorSupEl);
	const id = open ? 'simple-popover' : undefined;
	const formRef = useRef();
	const handleCheckValue = () => {
		checked ? setChecked(false) : setChecked(true);
	};
	const navigate = useNavigate();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleSuperClick = (event) => {
		setAnchorSupEl(event.currentTarget);
	};
	const handleSuperClose = () => {
		setAnchorSupEl(null);
	};
	const fetchCategoryList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCategoryList);

			if (status === 200) {
				setCategoryList(data?.getEventType);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchCategoryList();
		eventDetails('');
		eventType('');
		eventFightCard('');
		eventTicket('');
		category('');
		eventAdmin('');
		eventInvite('');
		eventFightData('');
		currentCreateEventTab('');
		supurEventCardData([]);
		eventConfirm('');
		navigate({ state: null });
	}, []);

	useEffect(() => {
		eventTypeName && eventTypeName !== 'fight' && setChecked(false);
	}, [eventTypeName]);

	return (
		<Box className="new-events-sec">
			<Box className="new-event-left-sec">
				<Typography className="new-event-text">{t('events.new_event')}</Typography>
				<Box className="events-type-sec">
					<Formik
						innerRef={formRef}
						initialValues={{
							event_type: '',
							category: '',
							amount: '',
						}}
						validationSchema={Yup.object().shape({
							event_type: Yup.string().required(t('events.choose_event_type')),
							category: checked && Yup.string().required(t('validation.required_message')),
							amount: checked && Yup.string().required(t('validation.required_message')),
						})}
						onSubmit={(data) => {
							handleNextEvent(data);
							superEventStatus(checked);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12} className="text-field-grid">
										<TextField
											error={Boolean(touched.event_type && errors.event_type)}
											fullWidth
											helperText={touched.event_type && errors.event_type}
											variant="filled"
											InputProps={{
												endAdornment: touched.event_type && errors.event_type ? <ErrorIcon /> : null,
											}}
											label="Event Type"
											placeholder="Event Type"
											className="matrix-textfield"
											size="small"
											name="event_type"
											onBlur={handleBlur}
											onChange={(e) => {
												handleChange(e);
												setEventTypeName(e.target.value);
											}}
											value={values.event_type}
											select
										>
											{eventTypeList.map((obj, index) => {
												return (
													<MenuItem key={index} value={obj?.id}>
														{obj?.name}
													</MenuItem>
												);
											})}
										</TextField>
									</Grid>
									{eventTypeName === 'fight' && (
										<Grid item xs={12} md={12} className="super-tooltip-sec">
											<List>
												<ListItem>
													<ListItemIcon>
														<Checkbox
															edge="start"
															checked={checked}
															tabIndex={-1}
															disableRipple
															className="check-box"
															onChange={() => handleCheckValue()}
															inputProps={{ 'aria-labelledby': 'checkbox-list-label-1' }}
														/>
													</ListItemIcon>
													<ListItemText id={'checkbox-list-label-1'} primary={t('events.this_is_super_event')} />
												</ListItem>
											</List>
											<Popover
												className="cr-popover"
												id={id}
												open={open}
												anchorEl={anchorSupEl}
												onClose={handleSuperClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
												}}
												transformOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
												}}
											>
												<Box className="tooltip-data">
													<Typography className="tooltip-header">{t('events.tooltipSupHeader')}</Typography>
													<Typography>{t('events.tooltipSupCard')}</Typography>
												</Box>
											</Popover>
											<IconButton>
												<InfoIcon onClick={(e) => handleSuperClick(e)} />
											</IconButton>
										</Grid>
									)}
									{checked && eventTypeName === 'fight' && (
										<Box className="category-amount-sec">
											<Grid item xs={12} md={12} className="text-field-grid d-flex ">
												<TextField
													error={Boolean(touched.category && errors.category)}
													fullWidth
													helperText={touched.category && errors.category}
													variant="filled"
													InputProps={{
														endAdornment: touched.category && errors.category ? <ErrorIcon /> : null,
													}}
													label={t('events.category')}
													placeholder={t('events.category')}
													className="matrix-textfield"
													size="small"
													name="category"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.category}
													select
												>
													{categoryList.map((obj, index) => {
														return (
															<MenuItem key={index} value={obj}>
																{obj?.name}
															</MenuItem>
														);
													})}
												</TextField>
												<Popover
													className="cr-popover"
													id={ids}
													open={opens}
													anchorEl={anchorEl}
													onClose={handleClose}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'left',
													}}
													transformOrigin={{
														vertical: 'bottom',
														horizontal: 'left',
													}}
												>
													<Box className="tooltip-data">
														<Typography className="tooltip-header">{t('events.tooltipHeader')}</Typography>
														<Typography>{t('events.tooltipCard')}</Typography>
														<Typography>{t('events.tooltipTournament')}</Typography>
													</Box>
												</Popover>
												<IconButton>
													<InfoIcon onClick={(e) => handleClick(e)} />
												</IconButton>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid">
												<TextField
													error={Boolean(touched.amount && errors.amount)}
													fullWidth
													helperText={touched.amount && errors.amount}
													variant="filled"
													InputProps={{
														endAdornment: touched.amount && errors.amount ? <ErrorIcon /> : null,
													}}
													label={t('events.amount')}
													placeholder={t('events.amount')}
													className="matrix-textfield"
													size="small"
													name="amount"
													type="number"
													onBlur={handleBlur}
													onChange={(e) => {
														const re = /^[0-9\b]+$/;

														if (e.target.value === '' || re.test(e.target.value)) {
															handleChange(e);
														}
													}}
													value={values.amount}
												/>
											</Grid>
										</Box>
									)}

									<Grid item xs={12} md={12}>
										<Button
											variant="contained"
											fullWidth
											className={
												Object.keys(errors).length === 0 ? 'new-event-next-btn' : 'new-event-next-btn  white-btn'
											}
											type="submit"
										>
											{t('next')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Box>
			</Box>
			<Box className="new-event-right-sec">
				<Box className="new-events-right-image-sec">
					<Avatar className="image-1" variant="square" src={newEventsImage1} />
				</Box>
				<Box className="new-events-right-image-sec">
					<Avatar className="image-2" variant="square" src={newEventsImage2} />
				</Box>
				<Box className="new-events-right-image-sec">
					<Avatar className="image-3" variant="square" src={newEventsImage3} />
				</Box>
			</Box>
		</Box>
	);
};
