import { Discover } from './Discover';
import { NewEvent } from './NewEvent';
import { MyEvents } from './MyEvents';
import './events-right.scss';

export const Right = ({
	type,
	token,
	create_data,
	currentCreateEventTab,
	eventDetails,
	eventType,
	eventFightCard,
	eventTicket,
	// eventConfirm,
	fetchEventUserCommunityOrg,
	eventAdminList,
	user,
	fightTitleList,
	tourFightList,
	fightRuleList,
	titleTypeList,
	disciplinesData,
	eventFightSides,
	eventAdmin,
	eventInvite,
	allUsers,
	weightClasses,
	eventFightData,
	eventTypeList,
	fetchEventList,
	eventList,
	timeZoneList,
	slidebarFil,
	supurEventCardData,
}) => {
	return type === 'discover' ? (
		<Discover
			token={token}
			eventTypeList={eventTypeList}
			fetchEventList={fetchEventList}
			slidebarFil={slidebarFil}
			eventList={eventList}
		/>
	) : type === 'create' ? (
		<NewEvent
			token={token}
			create_data={create_data}
			currentCreateEventTab={currentCreateEventTab}
			eventDetails={eventDetails}
			eventType={eventType}
			eventFightCard={eventFightCard}
			eventTicket={eventTicket}
			// eventConfirm={eventConfirm}
			fetchEventUserCommunityOrg={fetchEventUserCommunityOrg}
			eventAdminList={eventAdminList}
			user={user}
			eventAdmin={eventAdmin}
			fightTitleList={fightTitleList}
			tourFightList={tourFightList}
			fightRuleList={fightRuleList}
			titleTypeList={titleTypeList}
			disciplinesData={disciplinesData}
			eventFightSides={eventFightSides}
			eventInvite={eventInvite}
			eventFightData={eventFightData}
			allUsers={allUsers}
			weightClasses={weightClasses}
			timeZoneList={timeZoneList}
			supurEventCardData={supurEventCardData}
		/>
	) : (
		<MyEvents />
	);
};
