import { useState, useEffect, useContext } from 'react';
import { Avatar, Box, Button, IconButton, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
import { TypeSlider } from './TypeSlider';
import './left.scss';

const useStyles = makeStyles({
	input: {
		display: 'none',
	},
});

export const LeftProfile = ({ isView, loader, user, token, getMyProfile }) => {
	const classes = useStyles();
	let cover = user?.UserData?.item_cover_path ? user?.UserData?.item_cover_path : '';
	const { t } = useTranslation();

	const [coverImg, setCoverImg] = useState('');

	const coverHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					let id = data?.data?.itemId || data?.data?.id;
					let requestData = { item_cover_id: id };

					try {
						let { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							getMyProfile();
							setApiMessage('success', t('myprofile.profile_updated_success'));
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		if (cover !== '') {
			setCoverImg(cover);
		}
	}, [cover]);

	return (
		<Box className="left-profile-container">
			<Box className="cover-photo-container">
				{loader ? (
					<Skeleton variant="rect" className="preview-cover" />
				) : isView ? (
					<Avatar src={coverImg} variant="square" className="preview-cover" />
				) : coverImg !== '' ? (
					<>
						<input
							accept="image/*"
							className={classes.input}
							id="cover-button-file"
							type="file"
							onChange={coverHandler}
						/>
						<label htmlFor="cover-button-file">
							<IconButton variant="contained" component="span">
								<Avatar src={coverImg} variant="square" className="preview-cover" />
								<CameraIcon className="camera-icon" />
							</IconButton>
						</label>
					</>
				) : (
					<>
						<input
							accept="image/*"
							className={classes.input}
							id="cover-button-file"
							type="file"
							onChange={coverHandler}
						/>
						<label htmlFor="cover-button-file">
							<Button variant="contained" endIcon={<CameraIcon />} className="upload-button" component="span">
								{t('myprofile.cover_photo')}
							</Button>
						</label>
					</>
				)}
			</Box>

			<TypeSlider isView={isView} user={user} token={token} loader={loader} getMyProfile={getMyProfile} />
		</Box>
	);
};
