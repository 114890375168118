import { Box, Container, Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import './add-edit-referral.scss';
import { useTranslation } from 'react-i18next';

export const AddEditReferral = ({
	closeDialog,
	isUpdate,
	item,
	fetchReferralCode,
	currentPage,
	searchVal,
	fetchActiveReferralCode,
	tab,
}) => {
	const { t } = useTranslation();

	const createReferralCode = async (data) => {
		const requestParams = {
			name: data?.referralname,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.createReferralCode, requestParams);

			if (status === 200) {
				fetchActiveReferralCode();
				fetchReferralCode(currentPage, searchVal, tab);
				setApiMessage('success', data?.message);
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const updateReferralCode = async (data) => {
		const requestParams = {
			name: data?.referralname,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateReferral + item?.code, requestParams);

			if (status === 200) {
				fetchReferralCode(currentPage, searchVal, tab);
				setApiMessage('success', data?.message);
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<>
			<Box className="referral-container-box">
				<Container maxWidth="lg" className="addreferral-form-main">
					<Box className="form-content">
						<Formik
							initialValues={{
								referralname: isUpdate ? item?.label : '',
							}}
							validationSchema={Yup.object().shape({
								referralname: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => (isUpdate ? updateReferralCode(requestdata) : createReferralCode(requestdata))}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.referralname && errors.referralname)}
												fullWidth
												helperText={touched.referralname && errors.referralname}
												variant="filled"
												placeholder={t('referral.referral_name')}
												label={t('referral.referral_name')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.referralname && errors.referralname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="referralname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.referralname}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{isUpdate ? t('button_text.update') : t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};
