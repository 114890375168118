import { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	Typography,
	withStyles,
	TextField,
	Button,
	InputAdornment,
	Avatar,
	IconButton,
	Checkbox,
	ListItem,
	ListItemText,
	List,
	ListItemIcon,
	Chip,
	ClickAwayListener,
} from '@material-ui/core';
import { getFields } from 'src/helpers/commonFunctions';
import CancelIcon from '@material-ui/icons/Cancel';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { ReactComponent as ApproveMemberIcon } from 'src/assets/images/icons/community/approveicon.svg';
import { ReactComponent as RemoveMemberIcon } from 'src/assets/images/icons/community/rejecticon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { ReactComponent as FilterIcon } from 'src/assets/images/icons/community/filter.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as UpArrowIcon } from 'src/assets/images/icons/up-icon.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { MultipleSelectCheckmarks } from 'src/components/ui';
import './eventInvite.scss';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

export const EventInvite = ({
	eventStatus,
	create_data,
	eventInvite,
	user,
	fetchEventUserCommunityOrg,
	eventAdminList,
}) => {
	const { t } = useTranslation();

	const [searchValUser, setSearchValUser] = useState('');
	const [searchValCom, setSearchValCom] = useState('');
	const [searchValOrg, setSearchValOrg] = useState('');
	const [selectedProfileType, setSelectedProfileType] = useState([]);
	const [dUserList, setDisplyUser] = useState(false);
	const [dComList, setDisplyCom] = useState(false);
	const [dOrgList, setDisplyOrg] = useState(false);
	const [rsvpCheck, setRsvpCheck] = useState(false);
	const [rsvpCheck2, setRsvpCheck2] = useState(false);
	const [checkedUser, setCheckedUser] = useState([]);
	const [checkedCom, setCheckedCom] = useState([]);
	const [checkedOrg, setCheckedOrg] = useState([]);
	const [checkedAllUser, setCheckedAllUser] = useState([]);
	const [checkedAllCom, setCheckedAllCom] = useState([]);
	const [checkedAllOrg, setCheckedAllOrg] = useState([]);
	const [eventInviteAllData, setEventInviteAll] = useState([]);
	const [submitInvite, setSubmitInvute] = useState(false);
	const [allowUserCheck, setAllowUserCheck] = useState(false);
	const [allowComCheck, setAllowComCheck] = useState(false);
	const [allowOrgCheck, setAllowOrgCheck] = useState(false);
	const [profileTypeQS, setProfileTypeQs] = useState();
	// const rsvpData = [
	//     { id: 1, name: t('events.Enable_attendance_responses') },
	//     { id: 2, name: t('events.Only_those_tagged_can_respond') }
	// ];
	const [tagData, setTagData] = useState([
		{ id: 1, name: t('events.allow_combat_matrix'), checked: false },
		{ id: 2, name: t('events.send_invitation'), checked: false },
	]);

	let { event_invite, event_status, current_tab, event_details, event_admin, event_fight_card } = create_data;
	// const tagUserData = [
	//     { id: 1, name: t('events.allow_users') }
	// ];
	// const tagCommunityData = [
	//     { id: 1, name: t('events.allow_any_community') }
	// ];
	// const tagOrgData = [
	//     { id: 1, name: t('events.allow_any_organisation') }
	// ];
	const handleRsvpCheckBox = (value) => {
		if (rsvpCheck) {
			setRsvpCheck(false);
			setRsvpCheck2(false);
		} else {
			setRsvpCheck(true);
		}
	};
	const handleRsvpCheck2 = () => {
		rsvpCheck2 ? setRsvpCheck2(false) : setRsvpCheck2(true);
	};
	const handleCheckValue = (index, cStatus) => {
		var checkedValue = cStatus ? false : true;
		var arrayTag = [...tagData];

		arrayTag[index] = {
			...arrayTag[index],
			checked: checkedValue,
		};

		setTagData(arrayTag);
	};
	const handleTagComabet = (index, checkedS) => {
		// var arrayTag = [...tagData];
		// arrayTag[index] = [...arrayTag[index], { checked: true }]
		// setTagData(arrayTag);
	};
	const restTypeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];
	const handleChangeUser = (e) => {
		setSearchValUser(e.target.value);
		fetchEventUserCommunityOrg(1, e.target.value, profileTypeQS);
		setDisplyUser(true);
	};
	const handleChangeCom = (e) => {
		setSearchValCom(e.target.value);
		fetchEventUserCommunityOrg(2, e.target.value, '');
		setDisplyCom(true);
	};
	const handleChangeOrg = (e) => {
		setSearchValOrg(e.target.value);
		fetchEventUserCommunityOrg(3, e.target.value, '');
		setDisplyOrg(true);
	};
	const onProfileTypeSelect = (data) => {
		// setSelectedProfileType(data);
		const currentIndex = selectedProfileType.indexOf(data);
		const newChecked = [...selectedProfileType];

		if (currentIndex === -1) {
			newChecked.push(data);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedProfileType(newChecked);
	};
	const handleUser = () => {
		fetchEventUserCommunityOrg(1, '', profileTypeQS);
		dUserList ? setDisplyUser(false) : setDisplyUser(true);
	};

	const handleCom = () => {
		fetchEventUserCommunityOrg(2, '', '');
		dComList ? setDisplyCom(false) : setDisplyCom(true);
	};
	const hnadleOrg = () => {
		fetchEventUserCommunityOrg(3, '', '');
		dOrgList ? setDisplyOrg(false) : setDisplyOrg(true);
	};

	const memberAddAsAdmin = (value) => {
		const currentIndex = checkedUser.indexOf(value?.id);
		const newChecked = [...checkedUser];
		const objChecked = [...checkedAllUser];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setCheckedUser(newChecked);
		setCheckedAllUser(objChecked);
	};
	const addCommunityEvent = (value) => {
		const currentIndex = checkedCom.indexOf(value?.id);
		const newChecked = [...checkedCom];
		const objChecked = [...checkedAllCom];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setCheckedCom(newChecked);
		setCheckedAllCom(objChecked);
	};
	const addOrgEvent = (value) => {
		const currentIndex = checkedOrg.indexOf(value?.id);
		const newChecked = [...checkedOrg];
		const objChecked = [...checkedAllOrg];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setCheckedOrg(newChecked);
		setCheckedAllOrg(objChecked);
	};

	const handleUserFilter = () => {
		var queryParams = {
			...(selectedProfileType.length > 0 && {
				profile_type: selectedProfileType.join(','),
			}),
		};

		setProfileTypeQs(queryParams);
		fetchEventUserCommunityOrg(1, '', queryParams);
	};
	const inviteSubmit = () => {
		setSubmitInvute(true);
	};

	useEffect(() => {
		var userArray = [];

		for (let j = 0; j < checkedAllUser.length; j++) {
			userArray.push(checkedAllUser[j]);
		}

		var comArray = [];

		for (let j = 0; j < checkedAllCom.length; j++) {
			comArray.push(checkedAllCom[j]);
		}

		var orgArray = [];

		for (let j = 0; j < checkedAllOrg.length; j++) {
			orgArray.push(checkedAllOrg[j]);
		}

		userArray = userArray.concat(user);

		if (submitInvite) {
			setEventInviteAll({
				rsvp: rsvpCheck ? 'yes' : 'no',
				self_tag_rsvp: !rsvpCheck ? 'no' : rsvpCheck2 ? 'yes' : 'no',
				tag_combat_matrix: tagData[0].checked ? 'yes' : 'no',
				send_invitation: tagData[1].checked ? 'yes' : 'no',
				self_user: allowUserCheck ? 'yes' : 'no',
				users: userArray,
				self_community: allowComCheck ? 'yes' : 'no',
				community: comArray,
				self_organisation: allowOrgCheck ? 'yes' : 'no',
				organisation: orgArray,
			});
			eventInvite(eventInviteAllData);
		}
	}, [submitInvite === true, eventInviteAllData]);
	useEffect(() => {
		EventEmitter.subscribe('submit_invite', inviteSubmit);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (event_status === 'draft' && current_tab === 4) {
			eventStatus('published');
			var userArray = [];

			for (let j = 0; j < checkedAllUser.length; j++) {
				userArray.push(checkedAllUser[j]);
			}

			var comArray = [];

			for (let j = 0; j < checkedAllCom.length; j++) {
				comArray.push(checkedAllCom[j]);
			}

			var orgArray = [];

			for (let j = 0; j < checkedAllOrg.length; j++) {
				orgArray.push(checkedAllOrg[j]);
			}

			userArray = userArray.concat(user);
			var eventAdminDraftData = {
				rsvp: rsvpCheck ? 'yes' : 'no',
				self_tag_rsvp: !rsvpCheck ? 'no' : rsvpCheck2 ? 'yes' : 'no',
				tag_combat_matrix: tagData[0].checked ? 'yes' : 'no',
				send_invitation: tagData[1].checked ? 'yes' : 'no',
				self_user: allowUserCheck ? 'yes' : 'no',
				users: userArray,
				self_community: allowComCheck ? 'yes' : 'no',
				community: comArray,
				self_organisation: allowOrgCheck ? 'yes' : 'no',
				organisation: orgArray,
			};
			var eventAllData = {
				event_details: event_details,
				event_admin: event_admin,
				event_fight_card: event_fight_card,
				event_invite: eventAdminDraftData,
			};

			EventEmitter.dispatch('create_draft_event', eventAllData);
		}
	}, [event_status]);

	useEffect(() => {
		event_invite && event_invite?.rsvp && setRsvpCheck(event_invite?.rsvp === 'yes' ? true : false);
		event_invite && event_invite?.self_tag_rsvp && setRsvpCheck2(event_invite?.self_tag_rsvp === 'yes' ? true : false);

		if (event_invite) {
			var arrayTag = [...tagData];

			arrayTag[0] = {
				...arrayTag[0],
				checked: event_invite?.tag_combat_matrix === 'yes' ? true : false,
			};
			arrayTag[1] = {
				...arrayTag[1],
				checked: event_invite?.send_invitation === 'yes' ? true : false,
			};
			event_invite && event_invite?.tag_combat_matrix && setTagData(arrayTag);
			event_invite && event_invite?.send_invitation && setTagData(arrayTag);

			event_invite?.self_user && setAllowUserCheck(event_invite?.self_user === 'yes' ? true : false);
			event_invite?.self_community && setAllowComCheck(event_invite?.self_community === 'yes' ? true : false);
			event_invite?.self_organisation && setAllowOrgCheck(event_invite?.self_organisation === 'yes' ? true : false);
		}

		var userData =
			event_invite?.users &&
			event_invite?.users?.filter((item) => item?.id !== undefined && item?.id !== user?.user_id);
		var userIds = userData && getFields(userData, 'id');

		event_invite && setCheckedAllUser(userData);
		userData && setCheckedUser(userIds);

		var comIds = event_invite?.community && getFields(event_invite?.community, 'id');

		event_invite && setCheckedCom(comIds);
		event_invite && setCheckedAllCom(event_invite?.community);

		var orgIds = event_invite?.organisation && getFields(event_invite?.organisation, 'id');

		event_invite && setCheckedOrg(orgIds);
		event_invite && setCheckedAllOrg(event_invite?.organisation);
	}, [event_invite]);

	return (
		<Box className="event-invite-sec">
			<Typography className="invite-title">{t('events.invite')}</Typography>
			<Box className="invite-box-sec">
				<Typography className="invite-title invite-sub-title">{t('events.rsvp')}</Typography>
				<Grid item xs={12} md={12}>
					<List>
						{/* {rsvpData.map((value, index) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                                <ListItem key={index}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            // checked={checked.indexOf(value?.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value?.name} />
                                </ListItem>
                            )
                        })
                        } */}

						<ListItem>
							<ListItemIcon>
								<Checkbox
									edge="start"
									tabIndex={-1}
									disableRipple
									checked={rsvpCheck}
									inputProps={{ 'aria-labelledby': 'checkbox-list-label-1' }}
									onClick={() => handleRsvpCheckBox()}
								/>
							</ListItemIcon>
							<ListItemText id={'checkbox-list-label-1'} primary={t('events.Enable_attendance_responses')} />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<Checkbox
									edge="start"
									// checked={checked.indexOf(value?.id) !== -1}
									tabIndex={-1}
									disableRipple
									checked={rsvpCheck2}
									onChange={() => handleRsvpCheck2()}
									disabled={rsvpCheck === false}
									inputProps={{ 'aria-labelledby': 'checkbox-list-label-2' }}
								/>
							</ListItemIcon>
							<ListItemText id={'checkbox-list-label-2'} primary={t('events.Only_those_tagged_can_respond')} />
						</ListItem>
					</List>
				</Grid>
			</Box>
			<Box className="invite-box-sec">
				<Typography className="invite-title invite-sub-title">{t('events.tag_combat_matrix')}</Typography>
				<Grid item xs={12} md={12}>
					<List>
						{tagData.map((value, index) => {
							const labelId = `checkbox-list-label-${index}`;

							return (
								<ListItem key={index}>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={value?.checked}
											tabIndex={-1}
											disableRipple
											onChange={() => handleCheckValue(index, tagData[index]?.checked)}
											// checked={tagData[index + 1]?.checked}
											// onClick={() => handleTagComabet(index + 1, tagData[index + 1]?.checked)}
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={value?.name} />
								</ListItem>
							);
						})}
					</List>
				</Grid>
			</Box>
			<Box className="invite-box-sec">
				<Typography className="invite-title invite-sub-title">{t('events.tag_users')}</Typography>
				<Grid item xs={12} md={12}>
					<List>
						{/* {tagUserData.map((value, index) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                                <ListItem key={index}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            // checked={checked.indexOf(value?.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value?.name} />
                                </ListItem>
                            )
                        })
                        } */}

						<ListItem>
							<ListItemIcon>
								<Checkbox
									edge="start"
									// checked={checked.indexOf(value?.id) !== -1}
									tabIndex={-1}
									disableRipple
									checked={allowUserCheck}
									onChange={() => {
										allowUserCheck ? setAllowUserCheck(false) : setAllowUserCheck(true);
									}}
									inputProps={{ 'aria-labelledby': 'checkbox-user-list-label' }}
								/>
							</ListItemIcon>
							<ListItemText id={'checkbox-user-list-label'} primary={t('events.allow_users')} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={12}>
					<Box className="search-field-box">
						<SearchTextField
							onChange={handleChangeUser}
							fullWidth
							value={searchValUser}
							placeholder={t('search.title')}
							size="small"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end" className="search-dropdown" onClick={() => handleUser()}>
										{dUserList ? <UpArrowIcon /> : <DownArrowIcon />}
									</InputAdornment>
								),
							}}
						/>
					</Box>
					{dUserList && (
						<Box className="search-adminlist-sec">
							{checkedAllUser?.length > 0 && (
								<Box className="restriction-box">
									<Box className="restriction-chips">
										{checkedAllUser?.map((data, index) => {
											return (
												<Grid item key={index} className="rest-chip">
													<Chip
														size="small"
														className="checked-chip"
														deleteIcon={<CancelIcon />}
														label={data?.user_name}
														onDelete={() => memberAddAsAdmin(data)}
														// onClick={() => onMemberSelect(data)}
														// onClick={handleClick}
													/>
												</Grid>
											);
										})}
									</Box>
								</Box>
							)}
							<ClickAwayListener onClickAway={() => setDisplyUser(false)}>
								<Box>
									{eventAdminList &&
										eventAdminList.map((adminUser, index) => {
											const filteredArray = restTypeList.filter((value) =>
												adminUser?.profile_types.includes(value?.id),
											);

											return (
												<Box className="search-admin-list">
													<Box className="search-admin-left">
														<Avatar src={adminUser?.item_profile_data} className="search-profile" />
														<Box className="search-profile-sec">
															<Typography className="search-user-name">{adminUser?.user_name}</Typography>
															<Typography className="search-user-data">
																{filteredArray
																	.map(function (elem) {
																		return elem.name;
																	})
																	.join(', ')}
															</Typography>
														</Box>
													</Box>
													<Box className="search-admin-right">
														<Button
															disableRipple
															disableFocusRipple
															disableTouchRipple
															edge="end"
															className={
																checkedUser.indexOf(adminUser?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '
															}
															onClick={() => memberAddAsAdmin(adminUser)}
															startIcon={
																checkedUser.indexOf(adminUser?.id) !== -1 ? <RemoveMemberIcon /> : <ApproveMemberIcon />
															}
														>
															{checkedUser.indexOf(adminUser?.id) !== -1 ? t('messages.remove') : t('events.invite')}
														</Button>
													</Box>
												</Box>
											);
										})}
								</Box>
							</ClickAwayListener>
						</Box>
					)}
					<Box className="filter-box">
						<Grid container className="filter-tags">
							<Box style={{ width: '3%' }}>
								<FilterIcon />
							</Box>
							<Box style={{ width: '97%' }} className="filter-options">
								<MultipleSelectCheckmarks
									labelValue={t('profile_type.pts')}
									count={selectedProfileType?.length}
									// labelValue={selectedProfileType.length > 0 ? arrayJoin(restTypeList, selectedProfileType, 'name') : t('profile_type.pts')}
									dropdownArray={restTypeList}
									onSelect={onProfileTypeSelect}
									checked={selectedProfileType}
								/>
							</Box>
						</Grid>
						<Box className="filter-right-buttons">
							<Button
								type="submit"
								className="filter-btn"
								onClick={() => {
									handleUserFilter();
								}}
							>
								{t('button_text.apply_filter')}
							</Button>
						</Box>
					</Box>
					<Box className="user-box">
						<Box className="user-admin-sec">
							<Typography className="user-name-title">{t('events.user')}</Typography>
							{/* <Typography className='user-name-title'>
                                {t('events.admin')}
                            </Typography> */}
						</Box>
						<Box className="selected-user-sec">
							<Box className="users-data">
								<Box className="user-data-sec">
									<Avatar src={user?.profile_pic} className="user-profile" />
									<Typography className="user-name">{user?.OauthUserData?.user_name}</Typography>
								</Box>
								{/* <Box>
                                <IconButton className='user-delete-icon'>
                                    <Checkbox />
                                </IconButton>
                                <IconButton className='user-delete-icon'>
                                    <DeleteIcon />
                                </IconButton>
                            </Box> */}
							</Box>
							{checkedAllUser &&
								checkedAllUser?.map((data, index) => {
									return (
										<Box className="users-data">
											<Box className="user-data-sec">
												<Avatar src={data?.item_profile_data} className="user-profile" />
												<Typography className="user-name">{data?.user_name}</Typography>
											</Box>
											<Box>
												{/* <IconButton className='user-delete-icon'>
                                                    <Checkbox />
                                                </IconButton> */}
												<IconButton className="user-delete-icon" onClick={() => memberAddAsAdmin(data)}>
													<DeleteIcon />
												</IconButton>
											</Box>
										</Box>
									);
								})}
						</Box>
					</Box>
				</Grid>
			</Box>
			<Box className="invite-box-sec">
				<Typography className="invite-title invite-sub-title">{t('events.tag_a_community')}</Typography>
				<Grid item xs={12} md={12}>
					<List>
						{/* {tagCommunityData.map((value, index) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                                <ListItem key={index}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            // checked={checked.indexOf(value?.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value?.name} />
                                </ListItem>
                            )
                        })
                        } */}
						<ListItem>
							<ListItemIcon>
								<Checkbox
									edge="start"
									// checked={checked.indexOf(value?.id) !== -1}
									tabIndex={-1}
									disableRipple
									checked={allowComCheck}
									onChange={() => {
										allowComCheck ? setAllowComCheck(false) : setAllowComCheck(true);
									}}
									inputProps={{ 'aria-labelledby': 'checkbox-com-list-label' }}
								/>
							</ListItemIcon>
							<ListItemText id={'checkbox-com-list-label'} primary={t('events.allow_any_community')} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={12}>
					<Box className="search-field-box">
						<SearchTextField
							onChange={handleChangeCom}
							fullWidth
							value={searchValCom}
							placeholder={t('search.title')}
							size="small"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end" className="search-dropdown" onClick={() => handleCom()}>
										{dComList ? <UpArrowIcon /> : <DownArrowIcon />}
									</InputAdornment>
								),
							}}
						/>
					</Box>
					{dComList && (
						<Box className="search-adminlist-sec">
							{checkedAllCom?.length > 0 && (
								<Box className="restriction-box">
									<Box className="restriction-chips">
										{checkedAllCom?.map((data, index) => {
											return (
												<Grid item key={index} className="rest-chip">
													<Chip
														size="small"
														className="checked-chip"
														deleteIcon={<CancelIcon />}
														label={data?.name}
														onDelete={() => addCommunityEvent(data)}
														// onClick={() => onMemberSelect(data)}
														// onClick={handleClick}
													/>
												</Grid>
											);
										})}
									</Box>
								</Box>
							)}
							<ClickAwayListener onClickAway={() => setDisplyCom(false)}>
								<Box>
									{eventAdminList &&
										eventAdminList.map((community, index) => {
											return (
												<Box className="search-admin-list">
													<Box className="search-admin-left">
														<Avatar src={community?.item_profile_data} className="search-profile" />
														<Box className="search-profile-sec">
															<Typography className="search-user-name">{community?.name}</Typography>
														</Box>
													</Box>
													<Box className="search-admin-right">
														<Button
															disableRipple
															disableFocusRipple
															disableTouchRipple
															edge="end"
															className={
																checkedCom?.indexOf(community?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '
															}
															onClick={() => addCommunityEvent(community)}
															startIcon={
																checkedCom?.indexOf(community?.id) !== -1 ? <RemoveMemberIcon /> : <ApproveMemberIcon />
															}
														>
															{checkedCom.indexOf(community?.id) !== -1 ? t('messages.remove') : t('events.invite')}
														</Button>
													</Box>
												</Box>
											);
										})}
								</Box>
							</ClickAwayListener>
						</Box>
					)}

					<Box className="user-box">
						<Box className="user-admin-sec">
							<Typography className="user-name-title">{t('events.community')}</Typography>
							{/* <Typography className='user-name-title admin-text'>
                                {t('events.admin')}
                            </Typography> */}
						</Box>
						<Box className="selected-user-sec">
							{checkedAllCom &&
								checkedAllCom?.map((data, index) => {
									return (
										<Box className="users-data">
											<Box className="user-data-sec">
												<Avatar src={data?.item_profile_data} className="user-profile" />
												<Typography className="user-name">{data?.name}</Typography>
											</Box>
											<Box>
												{/* <IconButton className='user-delete-icon'>
                                                    <Checkbox />
                                                </IconButton> */}
												<IconButton className="user-delete-icon" onClick={() => addCommunityEvent(data)}>
													<DeleteIcon />
												</IconButton>
											</Box>
										</Box>
									);
								})}
						</Box>
					</Box>
				</Grid>
			</Box>
			<Box className="invite-box-sec">
				<Typography className="invite-title invite-sub-title">{t('events.tag_an_organisation')}</Typography>
				<Grid item xs={12} md={12}>
					<List>
						{/* {tagOrgData.map((value, index) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                                <ListItem key={index} >
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            // checked={checked.indexOf(value?.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value?.name} />
                                </ListItem>
                            )
                        })
                        } */}
						<ListItem>
							<ListItemIcon>
								<Checkbox
									edge="start"
									// checked={checked.indexOf(value?.id) !== -1}
									tabIndex={-1}
									disableRipple
									checked={allowOrgCheck}
									onChange={() => {
										allowOrgCheck ? setAllowOrgCheck(false) : setAllowOrgCheck(true);
									}}
									inputProps={{ 'aria-labelledby': 'checkbox-org-list-label' }}
								/>
							</ListItemIcon>
							<ListItemText id={'checkbox-org-list-label'} primary={t('events.allow_any_organisation')} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={12}>
					<Box className="search-field-box">
						<SearchTextField
							onChange={handleChangeOrg}
							fullWidth
							value={searchValOrg}
							placeholder={t('search.title')}
							size="small"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end" className="search-dropdown" onClick={() => hnadleOrg()}>
										{dOrgList ? <UpArrowIcon /> : <DownArrowIcon />}
									</InputAdornment>
								),
							}}
						/>
					</Box>

					{dOrgList && (
						<Box className="search-adminlist-sec">
							{checkedAllOrg?.length > 0 && (
								<Box className="restriction-box">
									<Box className="restriction-chips">
										{checkedAllOrg?.map((data, index) => {
											return (
												<Grid item key={index} className="rest-chip">
													<Chip
														size="small"
														className="checked-chip"
														deleteIcon={<CancelIcon />}
														label={data?.name}
														onDelete={() => addOrgEvent(data)}
														// onClick={() => onMemberSelect(data)}
														// onClick={handleClick}
													/>
												</Grid>
											);
										})}
									</Box>
								</Box>
							)}
							<ClickAwayListener onClickAway={() => setDisplyOrg(false)}>
								<Box>
									{eventAdminList &&
										eventAdminList.map((Org, index) => {
											return (
												<Box className="search-admin-list">
													<Box className="search-admin-left">
														<Avatar src={Org?.item_profile_data} className="search-profile" />
														<Box className="search-profile-sec">
															<Typography className="search-user-name">{Org?.name}</Typography>
														</Box>
													</Box>
													<Box className="search-admin-right">
														<Button
															disableRipple
															disableFocusRipple
															disableTouchRipple
															edge="end"
															className={
																checkedOrg.indexOf(Org?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '
															}
															onClick={() => addOrgEvent(Org)}
															startIcon={
																checkedOrg.indexOf(Org?.id) !== -1 ? <RemoveMemberIcon /> : <ApproveMemberIcon />
															}
														>
															{checkedOrg.indexOf(Org?.id) !== -1 ? t('messages.remove') : t('events.invite')}
														</Button>
													</Box>
												</Box>
											);
										})}
								</Box>
							</ClickAwayListener>
						</Box>
					)}
					<Box className="user-box">
						<Box className="user-admin-sec">
							<Typography className="user-name-title">{t('events.organisation')}</Typography>
							{/* <Typography className='user-name-title admin-text'>
                                {t('events.admin')}
                            </Typography> */}
						</Box>
						<Box className="selected-user-sec">
							{checkedAllOrg &&
								checkedAllOrg?.map((data, index) => {
									return (
										<Box className="users-data">
											<Box className="user-data-sec">
												<Avatar src={data?.item_profile_data} className="user-profile" />
												<Typography className="user-name">{data?.name}</Typography>
											</Box>
											<Box>
												{/* <IconButton className='user-delete-icon'>
                                                    <Checkbox />
                                                </IconButton> */}
												<IconButton className="user-delete-icon" onClick={() => addOrgEvent(data)}>
													<DeleteIcon />
												</IconButton>
											</Box>
										</Box>
									);
								})}
						</Box>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
};
