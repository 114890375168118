import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	ListItemText,
	Toolbar,
	makeStyles,
	Typography,
	withStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { Logo } from 'src/components/ui';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { fetchFromStorage, saveToStorage, clearStorage } from 'src/helpers/context';
import { ReactComponent as NotificationIcon } from 'src/assets/images/icons/notification.svg';

import items from '../menu';
import styles from './TopBar.module.scss';
import { useLayoutStyles } from '../../components';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #2e353a',
		background: '#171717',
		marginTop: 15,
		borderRadius: '0px',
		boxShadow: '',
		color: '#fff',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
));

const useStyles = makeStyles((theme) => ({
	root: { backgroundColor: '#151515' },
	title: {
		marginRight: 'auto',
	},
	button: {
		color: '#fff',
		fontSize: '21px',
		fontFamily: `Staatliches, Alumni Sans, sans-serif`,
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent' },
		'&:last-child': {
			marginRight: 0,
		},
	},
	avatar: { width: 36, height: 36, cursor: 'pointer' },
	active: {
		color: '#438AC1',
	},
}));

export const TopBar = ({ auth, user_statics, className, onMobileNavOpen, logoutUser, ...rest }) => {
	const classes = useStyles();
	const layoutClasses = useLayoutStyles()();
	const navigate = useNavigate();
	const location = useLocation();
	const user_id = auth ? auth?.user_id : fetchFromStorage('authData')?.user_id;
	const [avt, setAvt] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const { t } = useTranslation();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const setAvatarChangeValue = (val) => {
		setAvt(val);
		var userData = fetchFromStorage('authData');
		let authData = { ...userData, profile_pic: val };

		saveToStorage('authData', authData);
	};

	useEffect(() => {
		EventEmitter.subscribe('user_image_change', setAvatarChangeValue);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (auth?.profile_pic !== '') {
			setAvt(auth?.profile_pic);
		}
		// eslint-disable-next-line
	}, [auth]);

	return (
		<AppBar className={`${clsx(classes.root, className)} ${styles['admin-app-bar']}`} elevation={0} {...rest}>
			<Toolbar>
				<NavLink to="/news-feed" className="logo-anchor">
					<Logo />
				</NavLink>
				<Box className={styles['app-bar-cm-user']}>
					{auth && (
						<Typography className={styles['cm-user-title']}>
							CM {t('top_menu_options.users')}:&nbsp;<span>{user_statics?.verify_user_count}</span>
						</Typography>
					)}
				</Box>
				<Box flexGrow={1} />
				<Hidden mdDown>
					{items.length > 0 &&
						items.map((menu, index) => {
							return (
								<Button
									key={index}
									className={
										location.pathname.includes(menu.href)
											? `${layoutClasses.button} ${layoutClasses.buttonActive} ${styles['app-menu-btn']}`
											: `${layoutClasses.button} ${styles['app-menu-btn']}`
									}
									component={NavLink}
									to={menu.href}
									disableElevation
									disableFocusRipple
									disableRipple
									activeClassName={location.pathname.includes(menu.href) ? layoutClasses.buttonActive : ''}
								>
									<span className={classes.title}>{t(menu.title)}</span>
								</Button>
							);
						})}

					{/*HIDE-MVP: CM-78*/}
					{/*<IconButton*/}
					{/*	className="app-bar-searchbtn"*/}
					{/*	onClick={() => {*/}
					{/*		navigate('/search');*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<SearchIcon />*/}
					{/*</IconButton>*/}

					<IconButton className={styles['app-bar-notification-btn']} onClick={() => {}}>
						<NotificationIcon />
					</IconButton>

					<Avatar
						src={avt}
						className={classes.avatar}
						onClick={() => {
							navigate(`/user/${user_id}`);
						}}
					/>

					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem
							className={styles['my-option-menu']}
							onClick={() => {
								navigate(`/user/${user_id}`);
								handleClose();
							}}
						>
							<ListItemText primary="My Profile" />
						</MenuItem>
						<MenuItem
							className={styles['my-option-menu']}
							onClick={() => {
								logoutUser();
								clearStorage();
								window.location = '/';
								handleClose();
							}}
						>
							<ListItemText primary="Log Out" />
						</MenuItem>
					</StyledMenu>
				</Hidden>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};
