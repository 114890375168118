import { Box, Container, Button, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './allbadges.scss';
import { GeneralBadge } from './GeneralBadge';
import { SpecialBadge } from './SpecialBadge';
import { Page } from 'src/components/ui';

export const AllBadges = () => {
	const { t } = useTranslation();

	return (
		<Page title="All Badges | Combat Matrix">
			{/* BADGE BANNER SECTION */}
			<Box className="badge_banner">
				<Container maxWidth="lg">
					<Box className="combat-container">
						<Typography className="banner-head">{t('badge.title')}</Typography>
					</Box>
				</Container>
			</Box>

			<Container maxWidth="lg">
				<Box className="combat-container">
					<Box className="allbadge_block">
						{/* BADGE GENERAL SECTION */}
						<GeneralBadge />

						{/* BADGE SPECIAL TAGS */}
						<SpecialBadge />
					</Box>
				</Box>
			</Container>
		</Page>
	);
};
