import { connect } from 'react-redux';
import { userVerifyCount } from 'src/redux-state/actions/userProfile';
import { UserProfile as UserProfileComponent } from './UserProfile';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		userVerifyCount: (data) => dispatch(userVerifyCount(data)),
	};
};

export const UserProfile = connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);
