import { connect } from 'react-redux';
import { getCommunityList } from 'src/redux-state/actions/actions';
import { CommunityList as CommunityListComponent } from './CommunityList';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunityList: (data) => dispatch(getCommunityList(data)),
	};
};

export const CommunityList = connect(mapStateToProps, mapDispatchToProps)(CommunityListComponent);
export { MobileViewFilter } from './MobileViewFilter';
