import { useState, useContext, useEffect, createRef } from 'react';
import { ViewPortContext } from 'src/App';
import './MessagesPage.scss';
import { Box, Grid, Avatar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { useParams } from 'react-router-dom';
import { DialogBox } from 'src/components/ui';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { SocketContext } from 'src/helpers/context/socket';
import { getFields } from 'src/helpers/commonFunctions';
import { EventEmitter } from 'src/helpers/EventEmitter';
// import _ from 'lodash';
import { initGroupSocketEvents } from 'src/helpers/context/socket/socket.services';
import { Left } from './Left';
// eslint-disable-next-line import/no-named-as-default
import { Right } from './Right';
import { CreateGroupModal } from './Left/CreateGroupModal';
import { ForwardMessage } from './Right/ForwardMessage';
import { useTranslation } from 'react-i18next';

export const MessagesPage = ({ getAllMessageList, chatHistory, fetchMessageList }) => {
	const [groupDetails, setGroupDetails] = useState();
	const [groupMemberList, setGroupMemberList] = useState([]);
	const [currentGroupChat, setCurrentGroupChat] = useState({});
	const [open, setOpen] = useState(false);
	const auth = useSelector((state) => state.Combat.authData);
	const currentGroupChatList = useSelector((state) => state.Combat.currentGroupChatList);
	const user = auth ? auth : fetchFromStorage('authData');
	const token = fetchFromStorage('authData')?.token;
	const { t } = useTranslation();
	const userSocket = useContext(SocketContext)?.socket;
	const [userListData, setUserListData] = useState([]);
	const [msgList, setMsgList] = useState([]);
	const [model, setModel] = useState();
	const [title, setTitle] = useState('');
	const { id } = useParams();
	const [forwardedData, setForwardedData] = useState([]);
	const [msgLoader, setMsgLoader] = useState(false);
	const [grpLoader, setGrpLoader] = useState(false);
	const [showMessages, setShowMessages] = useState(false);
	// const [unReadMessages, setUnReadMessages] = useState([]);
	// const navigate = useNavigate();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.messagesBreakPoint;

	const handleCreateGroup = (title) => {
		setOpen(true);
		setModel('createChat');
		setTitle(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const handleForwardMessage = (title, data) => {
		setOpen(true);
		setModel('forwardMsg');
		setTitle(title);
		setForwardedData(data);
	};
	const fetchGroupList = async (searchString, sts) => {
		var ss = searchString ? searchString : '';

		sts ? setGrpLoader(false) : setGrpLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getGroupList + `?name=${ss}`);

			if (status === 200) {
				setUserListData(data?.groupListData);
				const currectGroupObj = data?.groupListData?.find((item) => item?.id === Number(id));

				setCurrentGroupChat(currectGroupObj);
				setGrpLoader(false);
			}
		} catch (error) {
			setGrpLoader(true);
		}
	};
	// const fetchMessageList = async () => {
	//     setMsgLoader(true);
	//     try {
	//         const { status, data } = await axiosInstance.get(
	//             URLS.getMessageList + `?group_id=${id}`
	//         );
	//         if (status === 200) {
	//             var groupMessageList = data?.messages_list.reverse()
	//             // setMsgList(data?.messages_list.reverse());
	//             setMsgLoader(false);
	//             getAllMessageList(groupMessageList)
	//             console.log('groupMessageList', data?.messages_list, groupMessageList)
	//             // var otherUserChat = msgGroup?.filter(currentGroupChat => currentGroupChat?.user?._id !== user?.user_id)
	//             // var unseenMessageArray = otherUserChat.reverse
	//             //     ().slice((otherUserChat.length - currentGroupChat?.total_unread_count), otherUserChat.length)
	//             // console.log(unseenMessageArray, msgGroup, 'unseenMessageArray')
	//             // setUnReadMessages(unseenMessageArray)
	//         }
	//     } catch (error) { setMsgLoader(false); }
	// }
	// FETCH GROUP DETAILS
	const fetchGroupDetails = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getGroupDetails + id);

			if (status === 200) {
				const filteredData = data?.getGroupData?.group_user_list;
				var formattedArray = [];

				filteredData.map((item, i) => {
					const object = {
						text: item?.user_full_name,
						value: item?.user_full_name,
						url: `user/${item?.id}`,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.text !== '');

				setGroupMemberList(finalArray);
				setGroupDetails(data?.getGroupData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const onUserChatClick = async (item) => {
		setCurrentGroupChat(item);

		if (currentGroupChatList !== undefined) {
			var otherUserChat = currentGroupChatList?.filter((item) => item?.user?._id !== user?.user_id);
			var unseenMessageArray = otherUserChat.slice(
				otherUserChat.length - item?.total_unread_count,
				otherUserChat.length,
			);

			// console.log(unseenMessageArray, currentGroupChatList, 'unseenMessageArray')
			item?.total_unread_count > 0 &&
				userSocket.emit('message-seen', {
					message_id: getFields(unseenMessageArray, '_id'),
					group_id: item?.id,
					user_id: user?.user_id,
				});
		}

		// var formatterArrayGroupList = [];
		userSocket.on('message-seen', (data) => {
			// var message_list = [...msgList]
			// var index = _.findIndex(message_list, { _id: data?.message_id });
			// message_list[index].message_seen_data = data?.message_seen_data
			// message_list.splice(index, data?.message_id, data);

			// var formattedArrayMessageList = [];
			EventEmitter.dispatch('fetch_notification_count');
			// console.log("message-seen item", msgList, data, formattedArrayMessageList, data);
			// msgList.map((item, i) => {
			//     const object = {
			//         ...item,
			//         message_seen_data: data?.message_id === item?._id ? data?.message_seen_data : item?.message_seen_data
			//     };
			//     return formattedArrayMessageList[i] = object;
			// });
			// setMsgList(formattedArrayMessageList)
			// userListData.map((item, i) => {
			//     const object = {
			//         ...item,
			//         total_unread_count: item?.id === data?.group_id ? 0 : item?.id
			//     };
			//     return formatterArrayGroupList[i] = object;
			// });
			// fetchGroupList('', true);
			fetchGroupList();
		});
		// setUserListData(formatterArrayGroupList)

		windowWidth < breakPoint && setShowMessages(true);

		// redirect to last unseen message
		// var msgid = unseenMessageArray[0]?._id;
		// unseenMessageArray.length > 0 && (
		//     refs[msgid].current.scrollIntoView({
		//         behavior: 'smooth',
		//         block: 'start',
		//     })
		// )
		// console.log('msgid', msgid)
	};

	useEffect(() => {
		initGroupSocketEvents(userSocket);
		fetchGroupList('');
		userSocket.on('created-group', (data) => {
			addGroup();
		});
	}, []);

	useEffect(() => {
		// userSocket.on('add-message', (data) => {
		//     console.log('onaddmessage')
		//     if (currentGroupChatList !== undefined) {
		//         const groupChatHistory = [...currentGroupChatList];
		//         groupChatHistory.push(data);
		//         getAllMessageList(groupChatHistory)
		//         console.log(JSON.stringify(data, null, 2), 'add-message on event');
		//     }
		// });
		// userSocket.on('DeleteMessageSocket', data => {
		//     if (currentGroupChatList !== undefined) {
		//         const groupChatHistory = [...currentGroupChatList];
		//         var fileterData = groupChatHistory.filter(item => item?._id !== data?._id)
		//         getAllMessageList(fileterData)
		//     }
		// })
		// userSocket.on('message-seen', (data) => {
		//     var formattedArrayMessageList = [];
		//     currentGroupChatList && currentGroupChatList.map((item, i) => {
		//         const object = {
		//             ...item,
		//             message_seen_data: data?.message_id === item?._id ? data?.message_seen_data : item?.message_seen_data
		//         };
		//         return formattedArrayMessageList[i] = object;
		//     });
		//     getAllMessageList(formattedArrayMessageList)
		//     // console.log('messageseen on', data, formattedArrayMessageList)
		//     // fetchGroupList();
		// });
	}, [currentGroupChatList]);
	const addGroup = () => {
		fetchGroupList('');
	};

	useEffect(() => {
		fetchMessageList();
		fetchGroupDetails();
	}, [id]);
	useEffect(() => {
		getAllMessageList(chatHistory);
	}, [chatHistory]);
	// useEffect(() => {
	//     setMsgList(currentGroupChatList)
	// }, [currentGroupChatList])
	const handleChange = (e) => {
		fetchGroupList(e.target.value);
		// console.log('e.targer.value', e.targer.value)
	};
	const handleBack = () => {
		windowWidth < breakPoint && setShowMessages(false);
	};
	const addForwardMessage = (array) => {
		// setMsgList(array)
		getAllMessageList(array);
	};
	const refs =
		currentGroupChatList &&
		currentGroupChatList.reduce((acc, value) => {
			acc[value._id] = createRef();

			return acc;
		}, {});
	// useEffect(() => {
	//     setCurrentGroupChat(userListData[0]);
	//     navigate(`/messages/${userListData[0]?.id}`);
	// }, [userListData]);

	return (
		<>
			<Box className="messagesPage-sec">
				<Grid container className="messages-grid-sec">
					{((windowWidth < breakPoint && !showMessages) || windowWidth > breakPoint) && (
						<Grid className="messages-left-sec">
							<Left
								// handleSearch={handleSearch}
								// searchText={searchText}
								handleCreateGroup={handleCreateGroup}
								onUserChatClick={onUserChatClick}
								currentGroupChat={currentGroupChat}
								userListData={userListData}
								fetchMessageList={fetchMessageList}
								handleChange={handleChange}
								fetchGroupList={fetchGroupList}
								grpLoader={grpLoader}
							/>
						</Grid>
					)}
					{id !== undefined ? (
						<>
							{((windowWidth < breakPoint && showMessages) || windowWidth > breakPoint) && (
								<Grid className="messages-right-sec" id="messages-sec">
									<Right
										token={token}
										user={user}
										currentGroupChat={currentGroupChat}
										msgList={currentGroupChatList}
										handleForwardMessage={handleForwardMessage}
										msgLoader={msgLoader}
										windowWidth={windowWidth}
										breakPoint={breakPoint}
										handleBack={handleBack}
										groupMemberList={groupMemberList}
										refs={refs}
										fetchGroupList={fetchGroupList}
										userListData={userListData}
										groupDetails={groupDetails}
										fetchGroupDetails={fetchGroupDetails}
									/>
								</Grid>
							)}
						</>
					) : (
						<Box className="welcome-content">
							<Box className="d-flex">
								<Avatar src={user?.profile_pic} />
								<Box>
									<Typography className="fs-50">{t('messages.welcome')}</Typography>
									<Typography className="fs-50 fw-bold">{user?.OauthUserData?.user_name}</Typography>
								</Box>
							</Box>
						</Box>
					)}
				</Grid>
			</Box>
			<DialogBox
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					model === 'createChat' ? (
						<CreateGroupModal
							// modal={modal}
							// tab={tab}
							// updateMemberList={fetchCommunityMember}
							handleClose={closeDialog}
							fetchGroupList={fetchGroupList}
							userSocket={userSocket}
							user={user}
							currentGroupChat={currentGroupChat}
							windowWidth={windowWidth}
							breakPoint={breakPoint}
						/>
					) : model === 'forwardMsg' ? (
						<ForwardMessage
							handleClose={closeDialog}
							userListData={userListData}
							currentGroupChat={currentGroupChat}
							user={user}
							forwardedData={forwardedData}
							addForwardMessage={addForwardMessage}
							msgList={currentGroupChatList}
						/>
					) : (
						<></>
					)
				}
			/>
		</>
	);
};
