import { useSlate } from 'slate-react';
import { useState } from 'react';
import { Editor, Transforms } from 'slate';
import '../../textEditor.scss';

import { insertLink } from '../../utils/editor';

import LinkSvg from 'src/assets/images/icons/community/link.svg';
import { useTranslation } from 'react-i18next';

export const AddLinkButton = () => {
	const editor = useSlate();
	const { t } = useTranslation();

	const [target, setTarget] = useState('');
	const [title, setTitle] = useState('');
	const [newWindow, setNewWindow] = useState(false);
	const [opened, setOpened] = useState(false);

	const openPopup = () => {
		const { selection } = editor;

		if (selection) {
			const selectedText = Editor.string(editor, selection);

			setTitle(selectedText);
		}

		setOpened(true);
	};

	const closePopup = () => {
		setOpened(false);
		setTarget('');
		setTitle('');
		setNewWindow(false);
	};

	const insertLinkHandler = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const { selection } = editor;

		if (!selection) {
			Transforms.select(editor, { offset: 0, path: [0, 0] });
		}

		insertLink(editor, target, title, newWindow, true);
		closePopup();
	};

	return (
		<div className={'text-editor-toolbar_item-container'}>
			<div onClick={openPopup} className={'text-editor-toolbar_item'}>
				<img src={LinkSvg} alt="link" />
			</div>
			{opened && (
				<div className={'text-editor-popup'}>
					<label htmlFor={'linkTitle'}>{t('community.link_title')}</label>
					<input
						type="text"
						onChange={(e) => setTitle(e.currentTarget.value)}
						value={title}
						name={'linkTitle'}
						className={'text-editor-popup_input'}
					/>
					<label htmlFor={'linkTarget'}>{t('community.link_target')}</label>
					<input
						type="text"
						onChange={(e) => setTarget(e.currentTarget.value)}
						value={target}
						name={'linkTarget'}
						className={'text-editor-popup_input'}
					/>
					<label className={'text-editor-popup_option'}>
						<input type="checkbox" checked={newWindow} onChange={(e) => setNewWindow(e.currentTarget.checked)} />
						<span>{t('community.open_in_new_window')}</span>
					</label>
					<div className={'text-editor-popup_buttons-container'}>
						<button
							type={'button'}
							onClick={insertLinkHandler}
							className={'text-editor-popup_button'}
							disabled={!target || !title}
						>
							{t('myprofile.add')}
						</button>
						<button type={'button'} className={'text-editor-popup_button'} onClick={closePopup}>
							{t('cancel')}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default AddLinkButton;
