import { useContext, useState, useEffect } from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import './seeAllActivity.scss';
import moment from 'moment';
import { CustomPaginations } from 'src/components/ui';
import { useNavigate } from 'react-router-dom';
import { Activities } from './Activities';

export const SeeAllActivity = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [allActivityData, setAllActivityData] = useState([]);
	const [activityTotal, setActivityTotal] = useState();
	const [activityLimit] = useState(6);
	const [currentPage, setCurrentPage] = useState(1);

	// ALL ACTIVITY DATA
	const fetchAllActivityData = async (page) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllActivity + `?page=${page}&limit=${activityLimit}`);

			if (status === 200) {
				setAllActivityData(data?.hot_activity_list);
				setActivityTotal(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchAllActivityData(currentPage - 1);
			}
		} else {
			if (currentPage < Math.ceil(activityTotal / activityLimit)) {
				setCurrentPage(currentPage + 1);
				fetchAllActivityData(currentPage + 1);
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchAllActivityData(Number(page));
		}
	};

	useEffect(() => {
		fetchAllActivityData(1);
	}, []);

	return (
		<>
			<Container maxWidth="lg">
				<Box className="combat-container ">
					<Box className="see-all-activity-page">
						<Box className="back-to-profile">
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								startIcon={<BackIcon />}
								className="back-text"
								onClick={() => navigate('/news-feed')}
							>
								{t('newsfeed.back_to_newsfeed')}
							</Button>
						</Box>
						<Typography className="activity-title">{t('newsfeed.activity')}</Typography>
						<Box className="all-activity">
							{allActivityData ? (
								allActivityData.map((item, index) => {
									const postContent = item?.PostLists.find((d) => d?.Item?.type === 'text');

									return (
										<>
											<Activities
												activityType={item?.receiver_type}
												activityOwner={item?.user_full_name}
												communityName={item?.receiver_type === 'community' ? item?.community_name : ''}
												time={
													item?.timestamp_created !== null &&
													moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
												}
												activityContent={postContent?.Item?.data}
											/>
										</>
									);
								})
							) : (
								<Typography className="no-info-txt">{t('myprofile.user_has_no_activity')}</Typography>
							)}
						</Box>
						<CustomPaginations
							paginationArray={allActivityData}
							currentPage={currentPage}
							list={activityLimit}
							handlePaginationButtonClick={handlePaginationButtonClick}
							handlePaginationClick={handlePaginationClick}
							totalRecord={activityTotal}
						/>
					</Box>
				</Box>
			</Container>
		</>
	);
};
