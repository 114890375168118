import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Drawer, Hidden, IconButton, List, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { clearStorage } from 'src/helpers/context';
import { NavItem } from '../NavItem';
import styles from './NavBar.module.scss';

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: '100%',
	},
}));

export const NavBar = ({ onMobileClose, openMobile, items, showLogout, logoutUser }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column" className={styles['menu-bar']}>
			<Box p={2}>
				<Box className={styles['close-button']}>
					<IconButton onClick={onMobileClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<List>
					{items.map((item) => (
						<NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
					))}
					{showLogout && (
						<Button
							fullWidth
							className={styles['res-logout-app-btn']}
							onClick={() => {
								logoutUser();
								clearStorage();
								window.location = '/';
							}}
						>
							{t('nav_menu_options.logout')}
						</Button>
					)}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<Hidden lgUp>
			<Drawer
				anchor="left"
				classes={{ paper: classes.mobileDrawer }}
				onClose={onMobileClose}
				open={openMobile}
				variant="temporary"
			>
				{content}
			</Drawer>
		</Hidden>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
	items: PropTypes.array,
	showLogout: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
	items: [],
	showLogout: false,
};
