import { Box, Typography, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './banner.scss';

export const ReferralBanner = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Box className="referral-banner">
				<Container maxWidth="lg">
					<Box className="combat-container mt-0">
						<Typography className="banner-head">{t('referral.title')}</Typography>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};
