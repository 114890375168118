import {
	eventFightCard,
	eventFightData,
	eventStatus,
	superEventFightDetails,
} from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';
import { FightDetailsTab as FightDetailsTabComponent } from './FightDetailsTab';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		superEventFightDetails: (data) => dispatch(superEventFightDetails(data)),
		eventFightData: (data) => dispatch(eventFightData(data)),
		eventFightCard: (data) => dispatch(eventFightCard(data)),
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const FightDetailsTab = connect(mapStateToProps, mapDispatchToProps)(FightDetailsTabComponent);
