const EventDetailsAry = [
	{
		side1: 'Light Weight',
		title: 'weight division',
		side2: 'Light Weight',
	},
	{
		side1: 'Win',
		title: 'Result',
		side2: 'Loss',
	},
	{
		side1: '1',
		title: 'Round',
		side2: '1',
	},
	{
		side1: '',
		title: 'Round Name',
		side2: '',
	},
	{
		side1: '',
		title: 'Score',
		side2: '',
	},
	{
		side1: '3.35',
		title: 'RESULT Time',
		side2: '3.35',
	},
	{
		side1: 'Soccer Kicks',
		title: 'MethoD',
		side2: 'Soccer Kicks',
	},
];

export default EventDetailsAry;
