import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import { ReactComponent as AddIcon } from 'src/assets/images/icons/fightRecord/add-circle.svg';
import { ReactComponent as CorrectIcon } from 'src/assets/images/icons/correct-blue.svg';
import './fightRecord.scss';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useTranslation } from 'react-i18next';

export const FightRecord = ({ data, user, createFightContestantEndorsement, deleteFightContestantEndorsement }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<>
			{data &&
				data?.profile_update_data &&
				data?.profile_update_data?.records &&
				data?.profile_update_data?.records?.length > 0 && (
					<>
						{data?.profile_update_data?.records.map((item, index) => {
							return (
								<Box className="update-fight-record-sec">
									<Grid container>
										<Grid xs={12} sm={8} className="update-fight-record-left-sec">
											<Grid container className="fight-record-details-sec">
												<Grid xs={6} className="fight-record-grid-sec fight-record-left-grid">
													<Typography className="updated-record-name">{item?.user_name}</Typography>
													<Typography className="updated-record-header">{item?.DisciplineData?.name}</Typography>
													<Typography className="updated-record-header">
														{item?.EventFightContestants[0]?.EventFightContestants[0]?.MetaWeightClass?.name}
													</Typography>
												</Grid>
												<Grid xs={6} className="fight-record-grid-sec">
													<Typography className="updated-record-text">{item?.start_time}</Typography>
													<Typography className="updated-record-text">
														{item?.EventData?.location_type === 'both'
															? 'Venue + Online'
															: item?.EventData?.location_type === 'venue'
															? 'Venue'
															: item?.EventData?.location_type === 'online'
															? 'Online'
															: 'N/A'}
													</Typography>
													{/* <Typography className='updated-record-text'>Full Metal Dojo</Typography>
                           <Typography className='updated-record-text'>Bangkok, Thailand</Typography> */}
												</Grid>
											</Grid>
											{item?.EventFightContestants[0]?.EventFightContestants[0] && (
												<Grid container className="fight-record-details-sec">
													{item?.EventFightContestants[0]?.EventFightContestants[0]?.result && (
														<Grid xs={3} className="fight-record-grid-sec">
															<Typography className="updated-record-header">{t('myprofile.result')}</Typography>
															<Typography className="updated-record-text">
																{item?.EventFightContestants[0]?.EventFightContestants[0]?.result}
															</Typography>
														</Grid>
													)}
													{
														// item?.EventFightContestants[0]?.EventFightContestants[0]?.round_number && (
														<Grid xs={3} className="fight-record-grid-sec">
															<Typography className="updated-record-header">{t('myprofile.round')}</Typography>
															<Typography className="updated-record-text">
																{item?.EventFightContestants[0]?.EventFightContestants[0]?.round_number || 0}
															</Typography>
														</Grid>
														// )
													}

													{item?.EventFightContestants[0]?.EventFightContestants[0]?.time && (
														<Grid xs={3} className="fight-record-grid-sec">
															<Typography className="updated-record-header">{t('myprofile.time')}</Typography>
															<Typography className="updated-record-text">
																{item?.EventFightContestants[0]?.EventFightContestants[0]?.time}
															</Typography>
														</Grid>
													)}
													{item?.EventFightContestants[0]?.EventFightContestants[0]?.EventFightContestantMethods
														?.MetaFightMethod?.name && (
														<Grid xs={3} className="fight-record-grid-sec">
															<Typography className="updated-record-header">{t('myprofile.method')}</Typography>
															<Typography className="updated-record-text">
																{
																	item?.EventFightContestants[0]?.EventFightContestants[0]?.EventFightContestantMethods
																		?.MetaFightMethod?.name
																}
															</Typography>
														</Grid>
													)}
												</Grid>
											)}
										</Grid>
										<Grid xs={12} sm={4} className="update-fight-record-right-sec">
											<Box className="fight-record-sec">
												<Box className="fight-record-right-data">
													<IconButton className="endorse-btn">{item?.Endorsements_count}</IconButton>
													<Typography className="endorse-text">{t('myprofile.endorsements')}</Typography>
													{user?.user_id !== item?.user_id ? (
														<IconButton
															className="endorse-addicon"
															onClick={() => {
																var requestData = {
																	user_id: item?.user_fighter_id,
																	event_fight_id: item?.id,
																};
																var reqParams = {
																	user_id: item?.user_fighter_id,
																	event_fight_id: item?.id,
																	user_verified_by_id: Number(fetchFromStorage(identifiers.authData)?.user_id),
																};

																item?.login_user_endorsement_status
																	? deleteFightContestantEndorsement(reqParams)
																	: createFightContestantEndorsement(requestData);
															}}
														>
															{item?.login_user_endorsement_status ? <CorrectIcon /> : <AddIcon />}
														</IconButton>
													) : (
														<></>
													)}
												</Box>
											</Box>
										</Grid>
									</Grid>
								</Box>
							);
						})}
					</>
				)}
		</>
	);
};
