import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Avatar, Box, Button, ButtonGroup, Chip, Grid, IconButton, Typography } from '@material-ui/core';
import { TextField } from 'src/components/ui';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as ProfileUploadIcon } from 'src/assets/images/icons/com-img-placeholder.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import './create-new.scss';
import { AddEndorsement } from '../../../../UserProfile/Left/ProfileTypeSlider/SkillsAndorsements/AddEndorsement';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@material-ui/icons/Cancel';
import _ from 'lodash';
import { getFields, setApiMessage } from 'src/helpers/commonFunctions';
// import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

export const Create = ({ token, fetchCommunityList, handleClose, isLoading, setIsLoading }) => {
	const { t } = useTranslation();
	const filter = createFilterOptions();
	const storage_authData = fetchFromStorage('authData');

	let isAdmin = storage_authData?.isAdmin;

	const [previewImg, setPreviewImg] = useState('');
	const [imageId, setImageId] = useState('');
	const [skillsData, setSkills] = useState([]);
	// const [selectedVal, setSelectedVal] = useState('white');
	const [fightMethod, setFightMethod] = useState([]);
	const [disciplineData, setDisciplineData] = useState([]);
	const [formData, setFormValue] = useState({
		checkp: true,
		checkf: true,
		checkc: true,
		checks: true,
		checkt: true,
		checkfr: true,
	});
	const [type, setType] = useState('community');
	const [selected, setSelected] = useState([0]);
	const [selectedData, setSelectedData] = useState({});
	// Category
	const [categoryData, setCategoryData] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [createdCategory, setCreatedCategory] = useState([]);

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	// FETCH SKILLS
	const fetchSkills = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getMetaSkills, authHeader);

			if (status === 200) {
				setSkills(data?.getMetaSkillData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH FIGHT METHOD
	const fetchMetaFightMethod = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getFightMethod, authHeader);

			if (status === 200) {
				setFightMethod(data?.getMetaFightsData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH DISCIPLINE TYPES DATA
	const fetchDisciplineTypesData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getDisciplinesData, authHeader);

			if (status === 200) {
				setDisciplineData(data?.getDisciplinesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH CATEGORY TYPES DATA
	const fetchCategoryData = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCategory);

			if (status === 200) {
				const filteredData = data?.metaCategoriesData?.filter((item) => item?.name !== null);

				setCategoryData(filteredData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchSkills();
		fetchMetaFightMethod();
		fetchDisciplineTypesData();
		fetchCategoryData();
		// eslint-disable-next-line
	}, []);

	// const childAccesibilityArr = identifiers?.childAccesibilityArr
	const restTypeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	const handleToggle = (value) => () => {
		const currentIndex = selected.indexOf(value);
		const newSelected = [...selected];

		if (currentIndex === -1) {
			newSelected.push(value);
		} else {
			newSelected.splice(currentIndex, 1);
		}

		setSelected(newSelected);
	};
	const handleSelect = (name, value) => {
		setFormValue({ ...formData, [name]: !value });
	};

	const typeChangeHandler = (value) => {
		setType(value);
	};

	const ImageUploadHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setImageId(data?.data?.id);
				setPreviewImg(data?.data?.location);
			}
		}
	};

	const createCommunity = async (data) => {
		setIsLoading(true);
		let types = selected.length > 0 && selected.reduce((a, b) => a + b);

		var metaCatArray = [];

		selectedCategory.map((item, i) => {
			const newCat = { name: item?.inputValue };
			const existingCat = { id: item?.id, name: item?.name };
			const object = Object.prototype.hasOwnProperty.call(item, 'id') ? existingCat : newCat;

			return (metaCatArray[i] = object);
		});

		var requestParameter = {
			name: data?.name,
			// description: "Community Description",
			item_profile_id: imageId !== '' ? imageId : 0,
			// item_cover_id: 2,
			post_about: {
				data: data?.about,
			},
			type: type,
			user_type: types, //What types of users is this community for?
			guest_access: formData?.checkp ? 'public' : 'private', // Is this community public?
			user_verified: formData?.checkf ? 'yes' : 'no', //Do users need to be verified to join this community?
			user_access: formData?.checkt ? 'post_create' : 'post_read', //Can users create posts in this community?
			user_age: formData?.checkc ? 'yes' : 'no', //Is this community accessible to children?
			post_comment: formData?.checkfr ? 'yes' : 'no', //Can posts in this community have comments on them?
			disciplinesArr: getFields(selectedData?.discipline, 'id'),
			skillsArr: getFields(selectedData?.skills, 'id'),
			methodsArr: getFields(selectedData?.fightmethod, 'id'),
			// metaCategoriesArr: getFields(selectedCategory, 'id')
			metaCategoriesArr: metaCatArray,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.createCommunity, requestParameter);

			if (status === 200) {
				fetchCommunityList('', '', 1);
				setApiMessage('success', data?.message);
				handleClose();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
		// console.log('requestParameter', requestParameter)
	};
	const fetchAllData = (selectedDiscipline, selectedFightMethod, selectedSkills) => {
		setSelectedData({
			discipline: selectedDiscipline,
			fightmethod: selectedFightMethod,
			skills: selectedSkills,
		});
	};

	return (
		<Box className="create-new-community">
			<Typography className="community-img-txt">{t('community.communityIamge')}</Typography>

			{/* Community Image */}
			<Box className="profile-picture-box">
				{previewImg !== '' ? (
					<Box textAlign={'center'}>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="icon-button-file"
							type="file"
							onChange={ImageUploadHandler}
						/>
						<label htmlFor="icon-button-file">
							<IconButton color="primary" aria-label="upload picture" component="span" style={{ position: 'relative' }}>
								<Avatar src={URLS.mediaURL + previewImg} alt="profile-picture" />
								<CameraIcon className="camera-icon" />
							</IconButton>
						</label>
					</Box>
				) : (
					<Box textAlign={'center'}>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="icon-button-file"
							type="file"
							onChange={ImageUploadHandler}
						/>
						<label htmlFor="icon-button-file">
							<IconButton color="primary" aria-label="upload picture" component="span">
								<ProfileUploadIcon />
							</IconButton>
						</label>
					</Box>
				)}

				<Box className="profile-pic-recomandations">
					<Typography className="rec-heding">{t('profile_picture.img_rec')}</Typography>
					<Typography className="rec-types">{`.jpg ${t('or')} .png`}</Typography>
					<Typography className="rec-types">500x500</Typography>
					<Typography className="rec-types">{t('profile_picture.lessthan')}</Typography>
				</Box>
			</Box>

			{/* Community | Official Switch Button */}
			{isAdmin && (
				<Box className="comm-off-switch-btn">
					<ButtonGroup size="small" color="secondary">
						<Button
							disableRipple
							disableTouchRipple
							disableFocusRipple
							className={type === 'community' ? 'selected-com-type' : 'other-com-type'}
							variant={type === 'community' ? 'contained' : 'outlined'}
							onClick={() => {
								typeChangeHandler('community');
							}}
						>
							{t('community.community')}
						</Button>
						<Button
							disableRipple
							disableTouchRipple
							disableFocusRipple
							className={type === 'official' ? 'selected-com-type' : 'other-com-type'}
							variant={type === 'official' ? 'contained' : 'outlined'}
							onClick={() => {
								typeChangeHandler('official');
							}}
						>
							{t('community.official')}
						</Button>
					</ButtonGroup>
				</Box>
			)}

			{/* Community Form */}
			<Box className="com-create-form-content">
				<Formik
					initialValues={{
						name: '',
						about: '',
					}}
					validationSchema={Yup.object().shape({
						name: Yup.string().required(t('validation.required_message')),
						about: Yup.string().required(t('validation.required_message')),
					})}
					onSubmit={(requestData) => {
						createCommunity(requestData);
					}}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} className="text-field-grid">
									<TextField
										error={Boolean(touched.name && errors.name)}
										fullWidth
										helperText={
											touched.name ? (
												touched.name && (
													<Box disply="flex" justifyContent="center">
														{errors.name}
														<span className="field-character-count">{values.name.length}/150</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.name.length}/150</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 500,
											endAdornment: touched.name && errors.name ? <ErrorIcon /> : null,
										}}
										label={t('community.community_name')}
										placeholder={t('community.community_name')}
										className="matrix-textfield"
										size="small"
										name="name"
										onBlur={handleBlur}
										onChange={handleChange}
										inputProps={{ maxLength: 150 }}
										value={values.name}
									/>
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<TextField
										error={Boolean(touched.about && errors.about)}
										fullWidth
										helperText={
											touched.about ? (
												touched.about && (
													<Box disply="flex" justifyContent="center">
														{errors.about}
														<span className="field-character-count">{values.about.length}/500</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.about.length}/500</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 500,
											endAdornment: touched.about && errors.about ? <ErrorIcon /> : null,
										}}
										label={t('community.about')}
										placeholder={t('community.about')}
										className="matrix-textfield"
										size="small"
										name="about"
										multiline
										rows={6}
										onBlur={handleBlur}
										onChange={handleChange}
										inputProps={{ maxLength: 500 }}
										value={values.about}
									/>
								</Grid>
								{/* Is this community public? */}
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.is_community_public')}</Typography>
										<Box className="restriction-chips">
											<Grid container>
												<Grid>
													<Chip
														size="small"
														className={formData?.checkp ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={formData?.checkp ? <FilterCheckIcon /> : null}
														label={t('switch_txt.yes')}
														onClick={() => handleSelect('checkp', false)}
													/>
												</Grid>
												<Grid>
													<Chip
														size="small"
														className={!formData?.checkp ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={!formData?.checkp ? <FilterCheckIcon /> : null}
														label={t('switch_txt.no')}
														onClick={() => handleSelect('checkp', true)}
													/>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Grid>
								{/* What types of users is this community for? */}
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.community_for_txt')}</Typography>
										<Box className="restriction-chips">
											<Grid container>
												{restTypeList?.map((data, index) => {
													return (
														<Grid item key={index}>
															<Chip
																size="small"
																className={selected.indexOf(data?.id) !== -1 ? 'rest-chip checked-chip' : 'rest-chip'}
																icon={selected.indexOf(data?.id) !== -1 ? <FilterCheckIcon /> : null}
																label={data?.name}
																onClick={handleToggle(data?.id)}
															/>
														</Grid>
													);
												})}
											</Grid>
										</Box>
									</Box>
								</Grid>
								{/* Do users need to be verified to join this community? */}
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.verify_comm_txt')}</Typography>
										<Box className="restriction-chips">
											<Grid container>
												<Grid>
													<Chip
														size="small"
														className={formData?.checkf ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={formData?.checkf ? <FilterCheckIcon /> : null}
														label={t('switch_txt.yes')}
														onClick={() => handleSelect('checkf', false)}
													/>
												</Grid>
												<Grid>
													<Chip
														size="small"
														className={!formData?.checkf ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={!formData?.checkf ? <FilterCheckIcon /> : null}
														label={t('switch_txt.no')}
														onClick={() => handleSelect('checkf', true)}
													/>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Grid>
								{/* Is this community accessible to children? */}
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.acc_for_childrn')}</Typography>
										<Box className="restriction-chips">
											<Grid container>
												<Grid container>
													<Grid>
														<Chip
															size="small"
															className={formData?.checkc ? 'rest-chip checked-chip' : 'rest-chip'}
															icon={formData?.checkc ? <FilterCheckIcon /> : null}
															label={t('switch_txt.yes')}
															onClick={() => handleSelect('checkc', false)}
														/>
													</Grid>
													<Grid>
														<Chip
															size="small"
															className={!formData?.checkc ? 'rest-chip checked-chip' : 'rest-chip'}
															icon={!formData?.checkc ? <FilterCheckIcon /> : null}
															label={t('switch_txt.no')}
															onClick={() => handleSelect('checkc', true)}
														/>
													</Grid>

													{/* {childAccesibilityArr?.map((data, index) => {
                            return (
                              <Grid item key={index}>
                                <Chip
                                  size="small"
                                  className={
                                    selectedVal === data?.name ? 'rest-chip checked-chip' : 'rest-chip'
                                  }
                                  icon={selectedVal === data?.name ? (<FilterCheckIcon />) : null}
                                  label={data?.name}
                                  onClick={() => setSelectedVal(data?.name)}
                                />
                              </Grid>
                            );
                          })} */}
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Grid>
								{/* Can users create posts in this community? */}
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.cancrtcomnt_txt')}</Typography>
										<Box className="restriction-chips">
											<Grid container>
												<Grid>
													<Chip
														size="small"
														className={formData?.checkt ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={formData?.checkt ? <FilterCheckIcon /> : null}
														label={t('switch_txt.yes')}
														onClick={() => handleSelect('checkt', false)}
													/>
												</Grid>
												<Grid>
													<Chip
														size="small"
														className={!formData?.checkt ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={!formData?.checkt ? <FilterCheckIcon /> : null}
														label={t('switch_txt.no')}
														onClick={() => handleSelect('checkt', true)}
													/>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Grid>
								{/* Can posts in this community have comments on them? */}
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.conthave_comnts')}</Typography>
										<Box className="restriction-chips">
											<Grid container>
												<Grid>
													<Chip
														size="small"
														className={formData?.checkfr ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={formData?.checkfr ? <FilterCheckIcon /> : null}
														label={t('switch_txt.yes')}
														onClick={() => handleSelect('checkfr', false)}
													/>
												</Grid>
												<Grid>
													<Chip
														size="small"
														className={!formData?.checkfr ? 'rest-chip checked-chip' : 'rest-chip'}
														icon={!formData?.checkfr ? <FilterCheckIcon /> : null}
														label={t('switch_txt.no')}
														onClick={() => handleSelect('checkfr', true)}
													/>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.add_sp_te_sk')}</Typography>
										<AddEndorsement
											disciplineData={disciplineData}
											fightMethod={fightMethod}
											skillsData={skillsData}
											showButton={false}
											fetchAllData={fetchAllData}
											// handleToggle={handleToggle}
											// handleToggleFight={handleToggleFight}
											// handleToggleSkills={handleToggleSkills}
											// selected={selected}
											// selectedFight={selectedFight}
											// selectedSkills={selectedSkills}
											// handleClick={handleClick}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} md={12}>
									<Box className="restriction-box">
										<Typography className="restriction-txt">{t('community.add_extra_cattxt')}</Typography>
										<Box className="discipline-dropdown-cover mb-15">
											{/* SEARCH TOP DISCIPLINES AND SELECT FROM THE LIST */}
											<Autocomplete
												multiple
												id="fixed-tags-demo"
												value={selectedCategory}
												onChange={(event, newValue) => {
													let newIds = selectedCategory.map((r) => r.id);
													let newValueIds = newValue.map((r) => r.id);
													let check = newValue.filter((s) => {
														return newIds.indexOf(s?.id) === -1;
													});

													let values = [...createdCategory];

													// IF NEW DISCIPLINE SELECTED
													if (newValue.length > selectedCategory.length) {
														// let haveCheck = ranksuggestion.filter(
														//   x => x?.id === check[0]?.id
														// );
														// if (haveCheck.length === 0) {
														//   fetchRankSuggestions(check[0]?.id);
														// }
														values.push(check[0]?.id);
														setCreatedCategory(values);
													}

													// IF REMOVE SELECTED DISCIPLINE
													if (newValue.length < selectedCategory.length) {
														let removedId = selectedCategory.filter((x) => {
															return newValueIds.indexOf(x?.id) === -1;
														})[0]?.id;

														let selectVall = values.filter((x) => x?.id !== removedId);

														setCreatedCategory(selectVall);
													}

													setSelectedCategory(newValue);
												}}
												filterOptions={(options, params) => {
													const filtered = filter(options, params);
													const { inputValue } = params;

													// Suggest the creation of a new value
													let isExisting = options.some((option) => inputValue === option.name);

													if (inputValue !== '' && !isExisting) {
														filtered.push({
															inputValue,
															name: `Add "${inputValue}"`,
														});
													}

													return filtered;
												}}
												options={_.orderBy(categoryData, ['name'], ['asc'])}
												getOptionLabel={(option) => option?.name}
												renderTags={(tagValue, getTagProps) =>
													tagValue.map((option, index) => (
														<Chip
															size="small"
															deleteIcon={<CancelIcon />}
															className="discipline-chip"
															label={option?.name}
															{...getTagProps({ index })}
														/>
													))
												}
												renderInput={(params) => (
													<TextField
														{...params}
														fullWidth
														variant="filled"
														label={t('admin_panel.combat_category.category')}
														placeholder={t('admin_panel.combat_category.category')}
														className="matrix-textfield"
														size="small"
													/>
												)}
											/>
										</Box>
									</Box>
								</Grid>
								{/* <Grid item xs={12} md={12}>
                  <Box className="restriction-box">
                    <Typography className="restriction-txt">
                      {t('community.restriction_txt')}
                    </Typography>

                    <Box className="restriction-chips">
                      <Grid container>
                        {restTypeList?.map((data, index) => {
                          return (
                            <Grid item key={index}>
                              <Chip
                                size="small"
                                className={
                                  selected.indexOf(data?.id) !== -1
                                    ? 'rest-chip checked-chip'
                                    : 'rest-chip'
                                }
                                icon={
                                  selected.indexOf(data?.id) !== -1 ? (
                                    <FilterCheckIcon />
                                  ) : null
                                }
                                label={data?.name}
                                onClick={handleToggle(data?.id)}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Grid> */}
								<Grid item xs={12} md={12}>
									<Box className="dialog-submit-button">
										<Button variant="contained" type="submit" disabled={isLoading} className="dialog-submit">
											{t('button_text.create')}
										</Button>
									</Box>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};
