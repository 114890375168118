import { Avatar, Box, Button, Typography, Grid } from '@material-ui/core';
import '../DisciplineRank.scss';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import moment from 'moment';
import { ReactComponent as AddIcon } from 'src/assets/images/icons/addWhite.svg';
import { ReactComponent as CorrectIcon } from 'src/assets/images/icons/correct-white.svg';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useTranslation } from 'react-i18next';

export const DisciplinerankData = ({
	sitem,
	user,
	createUserDisciplineRankEndorsement,
	deleteUserDisciplineRankEndorsement,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Box className="discipline-rank-data">
			<Box className="cover-img-sec">
				<Avatar variant="square" className="cover-img" src={sitem?.Discipline?.item_cover_data} />
			</Box>
			<Avatar className="profile-img" src={sitem?.Discipline?.item_icon_data} />

			<Typography className="game-name">{sitem?.Discipline?.name}</Typography>
			<Box className="updated-discipline-rank">
				<Typography className="discpline-rank-title">{t('myprofile.rank')}</Typography>
				<Typography className="discpline-rank-data">{sitem?.rank_other}</Typography>
			</Box>
			<Box className="updated-discipline-rank">
				<Typography className="discpline-rank-title">{t('myprofile.granted_by')}</Typography>
				<Typography className="discpline-rank-data">{sitem?.user_details?.user_name}</Typography>
			</Box>
			{/* HIDE-MVP: CM-79 */}
			{/*<Box className="updated-discipline-rank">*/}
			{/*	<Typography className="discpline-rank-title">{t('myprofile.granted_at')}</Typography>*/}
			{/*	<Typography className="discpline-rank-data">{sitem?.Organization?.name}</Typography>*/}
			{/*</Box>*/}
			<Box className="updated-discipline-rank">
				<Typography className="discpline-rank-title">{t('myprofile.date_granted')}</Typography>
				<Typography className="discpline-rank-data">{moment(sitem?.date_start).format('MM/DD/YYYY')}</Typography>
			</Box>
			<Grid item xs={12} md={12} className="endorsed-btn-sec">
				<Button
					variant="contained"
					fullWidth
					className="endorsed-btn"
					onClick={() => {
						if (sitem?.user_id !== user?.user_id) {
							var reqParams = {
								user_id: sitem?.user_id,
								user_discipline_rank_id: sitem?.id,
								user_verified_by_id: fetchFromStorage(identifiers?.authData)?.user_id,
							};
							var requestData = {
								user_id: sitem?.user_id,
								user_discipline_rank_id: sitem?.id,
							};

							{
								sitem?.login_user_endorsement_status
									? deleteUserDisciplineRankEndorsement(reqParams)
									: createUserDisciplineRankEndorsement(requestData);
							}
						}
					}}
				>
					{sitem?.user_id !== user?.user_id ? (
						<>
							{sitem?.login_user_endorsement_status ? (
								<CorrectIcon className="endorsed-icon" />
							) : (
								<AddIcon className="endorsed-icon" />
							)}
						</>
					) : (
						''
					)}
					{sitem?.login_user_endorsement_status ? t('myprofile.endorsed') : t('myprofile.endorse')}
				</Button>
			</Grid>
		</Box>
	);
};
