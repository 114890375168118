import { Box, Grid, Typography } from '@material-ui/core';
import { GeneralSetting } from './GeneralSettings';
import { SecurityLogin } from './SecurityLogin';

export const RightSide = ({ setNo, user, token }) => {
	return (
		<Box className="Acoount-setting-right-sec">
			{setNo === 1 ? <GeneralSetting user={user} /> : setNo === 2 ? <SecurityLogin user={user} token={token} /> : <></>}
		</Box>
	);
};
