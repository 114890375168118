import { Drawer, Box, Typography } from '@material-ui/core';

export const MobileDrawer = ({ className, anchor, open, onClose, title, content }) => {
	return (
		<Drawer className={`mobile-drawer ${className}`} anchor={anchor} onClose={onClose} open={open}>
			<Box className="combat-mobile-drawer-title">
				<Typography className="mobile-drawer-title">{title}</Typography>
			</Box>
			{content}
		</Drawer>
	);
};
