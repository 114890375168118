import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';

const EditorHeader = ({ user, postData }) => {
	const userImage = user?.profile_pic;

	return (
		<Box className="d-flex">
			<Box className="user-details">
				<Avatar src={postData ? postData.user_profile_data : userImage} />
				<Typography className="user-name">
					{postData ? postData.user_full_name : user?.OauthUserData?.user_name}
				</Typography>
			</Box>
		</Box>
	);
};

export default EditorHeader;
