import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Avatar, Box, Button, Grid, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { TextField } from 'src/components/ui';
import { EventEmitter } from 'src/helpers/EventEmitter';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
// import { ReactComponent as ClockIcon } from 'src/assets/images/icons/events/clock-icon.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as GeneralIcon } from 'src/assets/images/icons/events/general.svg';
import { ReactComponent as TimeDateIcon } from 'src/assets/images/icons/events/time-date.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as UpArrowIcon } from 'src/assets/images/icons/up-icon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/delete.svg';
import { ReactComponent as LocationIcon } from 'src/assets/images/icons/events/location.svg';
import { ReactComponent as UrlIcon } from 'src/assets/images/icons/events/url.svg';
import './event-details.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	input: {
		display: 'none',
	},
});

export const EventDetails = ({
	token,
	create_data,
	eventDetails,
	timeZoneList,
	eventType,
	supurEventCardData,
	superEventDetails,
	eventStatus,
}) => {
	const classes = useStyles();
	const super_event_details = useSelector((state) => state?.Events);
	const { t } = useTranslation();
	const formRef = useRef();
	const [coverImg, setCoverImg] = useState('');
	const [coverId, setCoverId] = useState('');
	const [date, setDate] = useState(true);
	const [urlData, setUrl] = useState(true);
	const [locData, setLocData] = useState(true);
	const [loc, setLoc] = useState(false);
	const [searchAddress, SetSearchAddress] = useState('');
	const [eventUrl, setEventUrl] = useState([]);
	const [eventLoc, setEventLoc] = useState([]);
	const [eventDate, setEventDate] = useState([{ end_time: '', start_time: '' }]);
	const [eventDeatilsData, setEventDetailsData] = useState();
	const [submitEventDetails, setSubmitEventDeatils] = useState(false);
	const [selectedlocationType, setSelectedLocationType] = useState('');
	const [eventSatusValue, setEventSatusValue] = useState('');
	const [validationObject, setValidationObj] = useState({
		event_name: Yup.string().required(t('validation.enter_required_information')),
	});
	const filter = createFilterOptions();
	// const eventTypeList = [
	//   t('events.fight'),
	//   t('events.press_conference'),
	//   t('events.session'),
	//   t('events.seminar'),
	//   t('events.other')
	// ];

	// const timezoneList = ['IST', 'GMT', 'UTC'];
	const location_typesData = [
		{ id: 'venue', name: 'venue' },
		{ id: 'both', name: 'venue & online' },
		{ id: 'online', name: 'online' },
	];

	let isView = false;

	let {
		event_details,
		super_event_page,
		event_status,
		event_admin,
		event_invite,
		event_fight_card,
		category,
		current_tab,
		super_event_status,
		super_event_card_data,
	} = create_data;
	// EVENT COVER HANDLER
	const coverHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					//   let id = data?.data?.itemId || data?.data?.id;
					setCoverId(data?.data?.id);
					setCoverImg(URLS.mediaURL + data?.data?.location);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};
	const EventDetailsSubmit = (val) => {
		setSubmitEventDeatils(true);

		if (formRef.current) {
			formRef.current.handleSubmit();

			if (formRef.current.isValid) {
				var eventIndex = `eventindex-${val}`;
				var superDetails = super_event_details?.super_event_details;

				if (val !== undefined && val !== 1) {
					var superEventData = {
						...superDetails,
						[eventIndex]: {
							formData: formRef.current.values,
						},
					};

					superEventDetails({ ...superEventData, formData: formRef.current.values });
				} else {
					setEventDetailsData({
						...eventDeatilsData,
						formData: formRef.current.values,
					});
				}
			}
		}
	};
	const handleDate = (index) => {
		// date ? setDate(false) : setDate(true);
		date === index ? setDate(false) : setDate(index);
	};
	const handleUrl = (index) => {
		urlData === index ? setUrl(false) : setUrl(index);
	};
	const handleLocation = (index) => {
		locData === index ? setLocData(false) : setLocData(index);
	};
	const HandleSelectAddress = (address, index) => {
		geocodeByAddress(address).then((results) => {
			// setCity('');
			// setState('');
			// setLattitude('');
			// setLongitude('');

			let address = results[0].address_components;
			let geoCode = results[0].geometry.location;
			let name = results[0].formatted_address;

			// SetSearchAddress(name);
			var urlArray = [...eventLoc];

			// urlArray[index] = {
			//   type: "address",
			//   data: name
			// };
			urlArray[index] = {
				...urlArray[index],
				address: name,
				lat: geoCode.lat(),
				long: geoCode.lng(),
			};

			SetSearchAddress(name);

			// setLattitude(geoCode.lat());
			// setLongitude(geoCode.lng());

			address &&
				address.map((val) => {
					return val.types.map((com) => {
						switch (com) {
							case 'country':
								// setCountry(val.long_name);
								urlArray[index] = {
									...urlArray[index],
									country: val.long_name,
								};

								return val.long_name;
							case 'administrative_area_level_1':
								// setState(val.long_name);
								urlArray[index] = {
									...urlArray[index],
									state: val.long_name,
								};

								return val.long_name;
							case 'administrative_area_level_2':
								// setCity(val.long_name);
								urlArray[index] = {
									...urlArray[index],
									city: val.long_name,
								};

								return val.long_name;
							default:
								return val;
						}
					});
				});
			setEventLoc(urlArray);
		});
	};
	// URLs
	const addUrlValues = (e, index) => {
		var urlArray = [...eventUrl];

		urlArray[index] = {
			type: 'URL',
			data: e.target.value,
		};
		setEventUrl(urlArray);
	};
	const handleAddUrl = () => {
		setEventUrl([
			...eventUrl,
			{
				url: '',
			},
		]);
	};
	const removeUrlHandler = (id) => {
		if (eventUrl.length > 0) {
			let updatedData = eventUrl.filter((x, index) => index + 1 !== id);

			setEventUrl(updatedData);
		}
	};

	const AddressHandler = (e, index) => {
		SetSearchAddress(e);
		var urlArray = [...eventLoc];

		urlArray[index] = {
			address: e,
		};
		setEventLoc(urlArray);
	};
	// LOCATIONs
	// const addLocationValues = (e, index) => {
	//   var urlArray = [...eventLoc];
	//   urlArray[index] = {
	//     type: "address",
	//     data: e.target.value
	//   };
	//   setEventLoc(urlArray);
	// }
	const handleAddLocation = () => {
		setEventLoc([
			...eventLoc,
			{
				locationData: '',
			},
		]);
	};
	const removeLocHandler = (id) => {
		if (eventLoc.length > 0) {
			let updatedData = eventLoc.filter((x, index) => index + 1 !== id);

			setEventLoc(updatedData);
		}
	};
	// DATEs
	const handleAddDate = () => {
		setEventDate([
			...eventDate,
			{
				start_time: '',
				end_time: '',
			},
		]);
	};
	const removeDateHandler = (id) => {
		if (eventDate.length > 1) {
			let updatedData = eventDate.filter((x, index) => index + 1 !== id);

			setEventDate(updatedData);
		}
	};
	const addDateValues = (e, index, dateName) => {
		var dateFormat = moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss');
		var dateArray = [...eventDate];

		if (dateName === 'startDate') {
			dateArray[index] = { ...dateArray[index], start_time: dateFormat };
		}

		if (dateName === 'endDate') {
			dateArray[index] = { ...dateArray[index], end_time: dateFormat };
		}

		setEventDate(dateArray);
	};

	useEffect(() => {
		if (formRef.current) {
			formRef.current.setFieldValue('address', searchAddress);
		}
		// eslint-disable-next-line
	}, [searchAddress]);
	useEffect(() => {
		EventEmitter.subscribe('submit_event_details', (data) => {
			EventDetailsSubmit(data);
		});

		// EventEmitter.subscribe('submit_event_details', EventDetailsSubmit);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		var evtDate =
			event_details?.eventDates &&
			event_details?.eventDates.map((dates, index) => {
				var sdate = moment(dates.start_time).format('YYYY-MM-DDTHH:mm:ss');
				var edate = moment(dates.end_time).format('YYYY-MM-DDTHH:mm:ss');

				return { ...dates, start_time: sdate, end_time: edate };
			});

		if (evtDate?.length > 0) {
			setEventDate(evtDate);
		} else {
			setEventDate([{ end_time: '', start_time: '' }]);
		}

		event_details?.eventLocations && setEventLoc(event_details?.eventLocations);
		event_details?.eventUrls && setEventUrl(event_details?.eventUrls);
		event_details?.imgCoverId?.image && setCoverImg(event_details?.imgCoverId?.image);
	}, [event_details]);
	useEffect(() => {
		if (submitEventDetails === true) {
			setEventDetailsData({
				...eventDeatilsData,
				imgCoverId: { imageId: coverId, image: coverImg },
				eventUrls: eventUrl,
				eventLocations: eventLoc,
				eventDates: eventDate,
				event_id: event_details?.event_id,
			});
		}
	}, [submitEventDetails]);

	useEffect(() => {
		selectedlocationType === 'online' && setEventLoc([]);
	}, [selectedlocationType]);

	var locationType =
		event_details &&
		event_details?.formData &&
		event_details?.formData?.location_type &&
		location_typesData?.find((item) => event_details?.formData?.location_type === item?.id);

	useEffect(() => {
		if (locationType && locationType[0] && locationType[0]?.id && !loc) {
			setSelectedLocationType(locationType[0]?.id);
		}
	}, [locationType]);
	useEffect(() => {
		locationType =
			event_details &&
			event_details?.formData &&
			event_details?.formData?.location_type &&
			location_typesData.filter((item) => event_details?.formData?.location_type === item?.id);

		// if (create_data?.event_confirm === 'update') {
		if (formRef.current) {
			var eventDateArray = create_data?.event_details?.eventDates;

			formRef.current.setFieldValue(
				'event_name',
				event_details?.formData?.event_name ? event_details?.formData?.event_name : '',
			);
			formRef.current.setFieldValue(
				'timezone',
				event_details?.formData?.timezone ? event_details?.formData?.timezone : '',
			);
			formRef.current.setFieldValue(
				'location_type',
				locationType && (locationType || locationType[0].id || locationType[0].id) ? locationType[0].id : '',
			);
			formRef.current.setFieldValue(
				'description',
				event_details?.formData?.description ? event_details?.formData?.description : '',
			);

			if (eventDateArray !== undefined) {
				for (var i = 0; i < eventDateArray.length; i++) {
					var startDateName = `starttime${i + 1}`;
					var endDateName = `endtime${i + 1}`;

					var sdate = moment(eventDateArray[i]?.start_time).format('YYYY-MM-DDTHH:mm:ss');
					var edate = moment(eventDateArray[i]?.end_time).format('YYYY-MM-DDTHH:mm:ss');

					formRef.current.setFieldValue(startDateName, sdate);
					formRef.current.setFieldValue(endDateName, edate);
				}
			}

			setEventDetailsData({
				...eventDeatilsData,
				formData: formRef.current.values,
			});
		}
		// }
		// else {
		//   if (formRef.current) {
		//     formRef.current.setFieldValue('event_name', '');
		//     formRef.current.setFieldValue('timezone', '');
		//     formRef.current.setFieldValue('location_type', '');
		//     formRef.current.setFieldValue('description', '');
		//   }
		// }
		// eslint-disable-next-line
	}, [create_data?.event_confirm]);
	useEffect(() => {
		if (
			create_data?.event_type === 'fight' &&
			create_data?.category?.name === 'Card' &&
			super_event_page !== 1 &&
			super_event_page
		) {
			var superEventDetailData =
				create_data?.super_event_details && create_data?.super_event_details[`eventindex-${super_event_page}`];

			if (superEventDetailData !== undefined) {
				if (create_data?.super_event_details[`eventindex-${super_event_page}`]) {
					var eventDateArray = superEventDetailData?.eventDates;

					formRef.current.setFieldValue('event_name', superEventDetailData?.formData?.event_name);
					formRef.current.setFieldValue('timezone', superEventDetailData?.formData?.timezone);
					formRef.current.setFieldValue('location_type', superEventDetailData?.formData?.location_type);
					formRef.current.setFieldValue('description', superEventDetailData?.formData?.description);
					setCoverImg(superEventDetailData?.imgCoverId?.image);
					// if (eventDateArray !== undefined) {
					//   for (var i = 0; i < eventDateArray.length; i++) {
					//     var startDateName = `starttime${i + 1}`;
					//     var endDateName = `endtime${i + 1}`
					//     formRef.current.setFieldValue(startDateName, eventDateArray[i]?.start_time);
					//     formRef.current.setFieldValue(endDateName, eventDateArray[i]?.end_time);
					//   }
					// }
					eventDateArray && setEventDate(eventDateArray);
				} else {
					formRef.current.setFieldValue('event_name', '');
					formRef.current.setFieldValue('timezone', '');
					formRef.current.setFieldValue('location_type', '');
					formRef.current.setFieldValue('description', '');
					setCoverImg('');
					setEventDate([{ end_time: '', start_time: '' }]);
				}
			} else {
				formRef.current.setFieldValue('event_name', '');
				formRef.current.setFieldValue('timezone', '');
				formRef.current.setFieldValue('location_type', '');
				formRef.current.setFieldValue('description', '');
				setCoverImg('');
				setEventDate([{ end_time: '', start_time: '' }]);
			}
		}
	}, [create_data?.super_event_page]);
	locationType =
		event_details &&
		event_details?.formData &&
		event_details?.formData?.location_type &&
		location_typesData.filter((item) => event_details?.formData?.location_type === item?.id);

	useEffect(() => {
		if (create_data?.event_type !== 'fight' && create_data?.category?.name !== 'Card') {
			event_details?.formData?.timezone && formRef.current.setFieldValue('timezone', event_details?.formData?.timezone);
		}
	}, [event_details?.formData?.timezone]);
	useEffect(() => {
		// if (super_event_details?.event_status) {
		setEventSatusValue(super_event_details?.event_status);
		// }
	}, [super_event_details]);
	// useEffect(() => {
	//   console.log(super_event_details?.event_status, "1111", current_tab, "drafteventsdrafteventsdraftevents 0")
	//   if (super_event_details?.event_status === "draft") {
	//     console.log(super_event_details?.event_status, "1111", "drafteventsdrafteventsdraftevents 3333")
	//     eventStatus('published');
	//     var eventAllData = {
	//       event_details: eventDeatilsData
	//     }
	//     EventEmitter.dispatch('create_draft_event', eventAllData);
	//   }
	// }, [super_event_details?.event_status])

	useEffect(() => {
		eventDate &&
			eventDate.map((item, i) => {
				let startDateName = `starttime${i + 1}`,
					endDateName = `endtime${i + 1}`;
				var validationObj = {
					// [startDateName]: Yup.string().required(
					//   t('validation.enter_required_information')
					// ),
					// [endDateName]: Yup.string().required(
					//   t('validation.enter_required_information')
					// )
					[startDateName]: Yup.date()
						.min(new Date(), 'Please choose future date')
						.required(t('validation.enter_required_information')),
					[endDateName]: Yup.date()
						.min(Yup.ref(`starttime${i + 1}`), 'End date has to be more than start date')
						.required(t('validation.enter_required_information')),
					// starttime2: Yup.date().min(new Date(), 'Please choose future date').required(t('validation.enter_required_information')),
					// endtime2: Yup.date().min(Yup.ref('starttime2'), "End date has to be more than start date").required(t('validation.enter_required_information')),
				};

				setValidationObj({ ...validationObject, ...validationObj });

				if (formRef.current) {
					var startval = item?.start_time ? item?.start_time : '';
					var endval = item?.end_time ? item?.end_time : '';

					formRef.current.setFieldValue(startDateName, startval);
					formRef.current.setFieldValue(endDateName, endval);
					formRef.current.errors && setSubmitEventDeatils(false);
				}
			});
	}, [eventDate]);

	return (
		<Box className="event-details-cover">
			<Typography className="event-details-heading">{t('events.event_details')}</Typography>
			<Box className="event-details-form">
				<Formik
					innerRef={formRef}
					initialValues={{
						event_name: event_details?.formData?.event_name ? event_details?.formData?.event_name : '',
						url: '',
						date: '',
						timezone: event_details?.formData?.timezone ? event_details?.formData?.timezone : '',
						start_time: '',
						end_time: '',
						location_type:
							locationType && (locationType || locationType[0].id || locationType[0].id) ? locationType[0].id : '',
						loc: '',
						description: event_details?.formData?.description ? event_details?.formData?.description : '',
						// starttime1: '',
						// starttime2: '',
						// starttime3: '',
						// starttime4: '',
						// starttime5: '',
						// starttime6: '',
						// endtime1: '',
						// endtime2: '',
						// endtime3: '',
						// endtime4: '',
						// endtime5: '',
						// endtime6: '',
					}}
					validationSchema={Yup.object().shape(validationObject)}
					// validationSchema={eventSatusValue === 'published' && Yup.object().shape({
					// validationSchema={Yup.object().shape({
					// event_name: Yup.string().required(
					//   t('validation.enter_required_information')
					// ),
					// starttime1: Yup.string().required(
					//   t('validation.enter_required_information')
					// ),
					// endtime1: Yup.string().required(
					//   t('validation.enter_required_information')
					// )
					// url: Yup.string().required(
					//   t('validation.required_message')
					// ),
					// date: Yup.string().required(t('validation.required_message')),
					// timezone: Yup.string().required(
					//   t('validation.required_message')
					// ),
					// // start_time: Yup.string().required(
					// //   t('validation.required_message')
					// // ),
					// // end_time: Yup.string().required(
					// //   t('validation.required_message')
					// // ),
					// location_type: Yup.string().required(
					//   t('validation.required_message')
					// ),
					// // loc: Yup.string().required(
					// //   t('validation.required_message')
					// // ),
					// description: Yup.string().required(
					//   t('validation.required_message')
					// )
					// })}
					onSubmit={(data) => {
						// eventDetails(data);
						eventDetails(eventDeatilsData);
						var eventAllData = {
							event_details: eventDeatilsData,
							event_admin: event_admin,
							event_invite: event_invite,
							event_fight_card: event_fight_card,
						};

						eventSatusValue === 'draft' && EventEmitter.dispatch('create_draft_event', eventAllData);
						eventSatusValue === 'draft' && eventStatus('published');
					}}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} className="event-titles">
									<GeneralIcon />
									<Typography className="event-titles-name">{t('events.general')}</Typography>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<Box className="event-cover-box">
										{isView ? (
											<Avatar src="" variant="square" className="preview-cover" />
										) : coverImg !== '' ? (
											<>
												<input
													accept="image/*"
													className={classes.input}
													id="cover-button-file"
													type="file"
													onChange={coverHandler}
												/>
												<label htmlFor="cover-button-file">
													<IconButton variant="contained" component="span">
														<Avatar src={coverImg} variant="square" className="preview-cover" />
														<CameraIcon className="camera-icon" />
													</IconButton>
												</label>
											</>
										) : (
											<>
												<input
													accept="image/*"
													className={classes.input}
													id="cover-button-file"
													type="file"
													onChange={coverHandler}
												/>
												<label htmlFor="cover-button-file">
													<Button
														variant="contained"
														endIcon={<CameraIcon />}
														className="upload-button"
														component="span"
													>
														Upload Event Cover Photo
													</Button>
												</label>
											</>
										)}
									</Box>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<TextField
										error={Boolean(touched.event_name && errors.event_name)}
										fullWidth
										helperText={touched.event_name && errors.event_name}
										variant="filled"
										InputProps={{
											endAdornment: touched.event_name && errors.event_name ? <ErrorIcon /> : null,
										}}
										label={t('events.event_name') + '*'}
										placeholder={t('events.event_name') + '*'}
										className="matrix-textfield"
										size="small"
										name="event_name"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.event_name}
									/>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<TextField
										error={Boolean(touched.description && errors.description)}
										fullWidth
										helperText={touched.description && errors.description}
										variant="filled"
										multiline
										rows={4}
										InputProps={{
											endAdornment: touched.description && errors.description ? <ErrorIcon /> : null,
										}}
										label={t('events.description')}
										placeholder={t('events.description')}
										className="matrix-textfield"
										size="small"
										name="description"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.description}
									/>
								</Grid>
								<Grid item xs={12} md={12} className="event-titles">
									<TimeDateIcon />
									<Typography className="event-titles-name">{t('events.time_date')}</Typography>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<Box className="event-cover-box">
										<Autocomplete
											id="grouped-demo"
											options={timeZoneList}
											value={values.timezone}
											// getOptionLabel={option => option.name}
											onChange={(e, newValue) => {
												handleChange(e);
												setFieldValue('timezone', newValue?.name);
											}}
											selectOnFocus
											clearOnBlur
											handleHomeEndKeys
											filterOptions={(options, params) => {
												const filtered = filter(options, params);
												const { inputValue } = params;
												// Suggest the creation of a new value
												const isExisting = options.some((option) => inputValue === option.name);

												// if (inputValue !== '' && !isExisting) {
												// filtered.push({
												//   'No option'
												//   });
												// }
												return filtered;
											}}
											getOptionLabel={(option) => {
												// Value selected with enter, right from the input
												if (typeof option === 'string') {
													return option;
												}

												// Add "xxx" option created dynamically
												// if (option.inputValue) {
												//   return option.inputValue;
												// }
												// Regular option
												return String(option.name);
											}}
											renderOption={(props, option) => {
												return props.name;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													// error={timezone === ''}
													// helperText={
													//   timezone === '' &&
													//   'This field is required'
													// }
													name="timezone"
													// value={'values.timezone'}
													label={t('events.timezone')}
													placeholder={t('events.timezone')}
													variant="filled"
													className="matrix-textfield"
													size="small"
													fullWidth
												/>
											)}
										/>
									</Box>
								</Grid>
								{/* <Grid item xs={12} md={12} className="text-field-grid">
                  <Box className="event-cover-box">
                    <TextField
                      error={Boolean(touched.timezone && errors.timezone)}
                      fullWidth
                      helperText={touched.timezone && errors.timezone}
                      variant="filled"
                      InputProps={{
                        endAdornment:
                          touched.timezone && errors.timezone ? (
                            <ErrorIcon />
                          ) : null
                      }}
                      label={t('events.timezone')}
                      placeholder={t('events.timezone')}
                      className="matrix-textfield"
                      size="small"
                      name="timezone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.timezone}
                      select
                    >
                      {timeZoneList?.map((obj, index) => {
                        return (
                          <MenuItem key={index} value={obj?.name}>
                            {obj?.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                </Grid> */}
								<Grid item xs={12} md={12} className="add-date-btn-sec ">
									<Button variant="contained" className="add-date-btn" onClick={handleAddDate}>
										{t('events.add_date')}
									</Button>
								</Grid>

								{eventDate &&
									eventDate?.map((evnDate, index) => {
										let startDateName = `starttime${index + 1}`,
											endDateName = `endtime${index + 1}`;

										return (
											<Box className="event-bg-sec">
												<Grid item xs={12} md={12} className="event-bg-grid">
													<Typography className="date-bg-text">{t('events.date') + ` ${index + 1}`}</Typography>
													<Box>
														<IconButton className={index === 0 ? 'hide-date' : 'delete-date'}>
															<DeleteIcon onClick={() => removeDateHandler(index + 1)} />
														</IconButton>
														<IconButton className="delete-date" onClick={() => handleDate(index)}>
															{date !== index ? <DownArrowIcon /> : <UpArrowIcon />}
														</IconButton>
													</Box>
												</Grid>
												{date !== index && (
													<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
														<Grid item xs={12} sm={12} md={12} lg={6} className="date-field">
															<TextField
																error={Boolean(touched[startDateName] && errors[startDateName])}
																fullWidth
																helperText={touched[startDateName] && errors[startDateName]}
																variant="filled"
																// InputProps={{
																//   endAdornment: <ClockIcon />
																// }}
																label={t('events.start_datetime')}
																placeholder={t('events.start_datetime')}
																className="matrix-textfield"
																size="small"
																name={startDateName}
																type="datetime-local"
																onBlur={handleBlur}
																InputLabelProps={{
																	shrink: true,
																}}
																// value={values.start_time}
																onChange={(e) => {
																	handleChange(e);
																	addDateValues(e, index, 'startDate');
																}}
																// value={evnDate?.start_time}
																value={values[startDateName]}
															/>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={6} className="age-field">
															<TextField
																error={Boolean(touched[endDateName] && errors[endDateName])}
																fullWidth
																helperText={touched[endDateName] && errors[endDateName]}
																variant="filled"
																// InputProps={{
																//   endAdornment: <ClockIcon />
																// }}
																label={t('events.end_datetime')}
																placeholder={t('events.end_datetime')}
																className="matrix-textfield"
																size="small"
																name={endDateName}
																onBlur={handleBlur}
																// value={values.end_time}
																type="datetime-local"
																InputLabelProps={{
																	shrink: true,
																}}
																onChange={(e) => {
																	handleChange(e);
																	addDateValues(e, index, 'endDate');
																}}
																value={values[endDateName]}
																// value={evnDate?.end_time}
															/>
														</Grid>
													</Grid>
												)}
											</Box>
										);
									})}
								<Grid item xs={12} md={12} className="event-titles">
									<LocationIcon />
									<Typography className="event-titles-name">{t('events.location')}</Typography>
								</Grid>

								{/* <Grid item xs={12} md={12} className="text-field-grid">
                  <TextField
                    error={Boolean(touched.date && errors.date)}
                    fullWidth
                    helperText={touched.date && errors.date}
                    variant="filled"
                    InputProps={{
                      endAdornment:
                        touched.date && errors.date ? <ErrorIcon /> : null
                    }}
                    label="Date"
                    placeholder="Date"
                    className="matrix-textfield"
                    size="small"
                    name="date"
                    type="date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid> */}
								<Grid item xs={12} md={12} className="text-field-grid">
									<Box className="event-cover-box">
										<TextField
											error={Boolean(touched.location_type && errors.location_type)}
											fullWidth
											helperText={touched.location_type && errors.location_type}
											variant="filled"
											InputProps={{
												endAdornment: touched.location_type && errors.location_type ? <ErrorIcon /> : null,
											}}
											label={t('events.location_type')}
											placeholder={t('events.location_type')}
											className="matrix-textfield"
											size="small"
											name="location_type"
											onBlur={handleBlur}
											onChange={(e) => {
												handleChange(e);
												e.target.value && setLoc(true);
												e.target.value && setSelectedLocationType(e.target.value);
											}}
											value={values.location_type}
											select
										>
											{location_typesData?.map((obj, index) => {
												return (
													<MenuItem key={index} value={obj?.id}>
														{obj?.name}
													</MenuItem>
												);
											})}
										</TextField>
									</Box>
								</Grid>
								{selectedlocationType && selectedlocationType !== 'online' && (
									<Grid item xs={12} md={12} className="add-date-btn-sec ">
										<Box>
											<Button variant="contained" className="add-date-btn" onClick={handleAddLocation}>
												{t('events.add_location')}
											</Button>
										</Box>
									</Grid>
								)}

								{selectedlocationType !== 'online' &&
									eventLoc &&
									eventLoc.map((evnLoc, index) => {
										var locName = `location-${index + 1}`;

										return (
											<Box className="event-bg-sec">
												<Grid item xs={12} md={12} className="event-bg-grid">
													<Typography className="date-bg-text">{t('events.location') + ` ${index + 1}`}</Typography>
													<Box>
														<IconButton className="delete-date" onClick={() => removeLocHandler(index + 1)}>
															<DeleteIcon />
														</IconButton>
														<IconButton className="delete-date" onClick={() => handleLocation(index)}>
															{locData !== index ? <DownArrowIcon /> : <UpArrowIcon />}
														</IconButton>
													</Box>
												</Grid>
												{locData !== index && (
													<Grid item xs={12} md={12} className="text-field-grid">
														<PlacesAutocomplete
															value={evnLoc?.address}
															onChange={(e) => AddressHandler(e, index)}
															onSelect={(e) => HandleSelectAddress(e, index)}
														>
															{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																<div>
																	<TextField
																		error={Boolean(touched.loc && errors.loc)}
																		fullWidth
																		helperText={touched.loc && errors.loc}
																		variant="filled"
																		InputProps={{
																			endAdornment: touched.loc && errors.loc ? <ErrorIcon /> : null,
																		}}
																		label={t('events.location')}
																		placeholder={t('events.location')}
																		className="matrix-textfield"
																		size="small"
																		name={locName}
																		{...getInputProps()}
																		onBlur={handleBlur}
																		// onChange={(e) => {
																		//   handleChange(e);
																		//   // addLocationValues(e, index)
																		// }}
																	/>
																	<div className="autocomplete-dropdown-container">
																		{suggestions &&
																			suggestions.map((suggestion, index) => {
																				const className = suggestion.active
																					? 'suggestion-item--active'
																					: 'suggestion-item';
																				// inline style for demonstration purpose
																				const style = suggestion.active
																					? {
																							background: '#fff',
																							color: '#000',
																							cursor: 'pointer',
																					  }
																					: {
																							backgroundColor: 'transparent',
																							cursor: 'pointer',
																					  };

																				return (
																					<div
																						key={index}
																						{...getSuggestionItemProps(suggestion, {
																							className,
																							style,
																						})}
																					>
																						<MenuItem disableGutters dense className="address-menuitem">
																							{suggestion.description}
																						</MenuItem>
																					</div>
																				);
																			})}
																	</div>
																</div>
															)}
														</PlacesAutocomplete>
													</Grid>
												)}
											</Box>
										);
									})}
								<Grid item xs={12} md={12} className="event-titles">
									<UrlIcon />
									<Typography className="event-titles-name">{t('events.url')}</Typography>
								</Grid>

								<Grid item xs={12} md={12} className="add-date-btn-sec ">
									<Box className="event-cover-box">
										<Button variant="contained" className="add-date-btn" onClick={handleAddUrl}>
											{t('events.add_url')}
										</Button>
									</Box>
								</Grid>
								{eventUrl &&
									eventUrl.map((evnUrl, index) => {
										var urlName = `url-${index + 1}`;

										return (
											<Box className="event-bg-sec">
												<Grid item xs={12} md={12} className="event-bg-grid">
													<Typography className="date-bg-text">{t('events.url') + ` ${index + 1}`}</Typography>
													<Box>
														<IconButton className="delete-date" onClick={() => removeUrlHandler(index + 1)}>
															<DeleteIcon />
														</IconButton>
														<IconButton className="delete-date" onClick={() => handleUrl(index)}>
															{urlData !== index ? <DownArrowIcon /> : <UpArrowIcon />}
														</IconButton>
													</Box>
												</Grid>
												{urlData !== index && (
													<Grid item xs={12} md={12} className="text-field-grid">
														<TextField
															error={Boolean(touched.url && errors.url)}
															fullWidth
															helperText={touched.url && errors.url}
															variant="filled"
															InputProps={{
																endAdornment: touched.url && errors.url ? <ErrorIcon /> : null,
															}}
															label={t('events.url')}
															placeholder={t('events.url')}
															className="matrix-textfield"
															size="small"
															name={urlName}
															onBlur={handleBlur}
															onChange={(e) => {
																// handleChange(e);
																// addUrlValues(e, index);
																var re = /[a-zA-Z0-9\/\\_\.]$/;

																if (e.target.value === '' || re.test(e.target.value)) {
																	handleChange(e);
																	// e.target.value === '' &&
																	addUrlValues(e, index);
																}
															}}
															value={evnUrl?.data}
														/>
													</Grid>
												)}
											</Box>
										);
									})}
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};
