import {
	CATEGORY,
	EVENT_TYPE,
	EVENT_ADMIN,
	EVENT_INVITE,
	EVENT_STATUS,
	EVENT_TICKET,
	CARD_CATEGORY,
	EVENT_CONFIRM,
	EVENT_DETAILS,
	EVENT_FIGHT_DATA,
	EVENT_FIGHT_CARD,
	CURRENT_FIGHT_TAB,
	SUPER_EVENT_STATUS,
	SUPER_EVENT_DETAILS,
	SUPER_EVENT_CARD_DATA,
	CURRENT_SUPER_EVENT_PAGE,
	CURRENT_CREATE_EVENT_TAB,
	SUPER_EVENT_CATEGORY_DATA,
	SUPER_EVENT_FIGHT_DETAILS,
} from '../constants';

const initialState = Object.freeze({
	category: undefined,
	event_type: undefined,
	current_tab: undefined,
	event_admin: undefined,
	event_ticket: undefined,
	event_invite: undefined,
	event_status: undefined,
	fight_details: undefined,
	event_confirm: undefined,
	event_details: undefined,
	event_fight_card: undefined,
	super_event_page: undefined,
	current_fight_tab: undefined,
	super_event_status: undefined,
	card_category_data: undefined,
	super_event_details: undefined,
	super_event_card_data: undefined,
	super_event_fight_details: undefined,
	super_event_category_details: undefined,
});

export default function Events(state = initialState, action) {
	switch (action.type) {
		case EVENT_DETAILS:
			return { ...state, event_details: action.data };
		case EVENT_TYPE:
			return { ...state, event_type: action.data };
		case CATEGORY:
			return { ...state, category: action.data };
		case CARD_CATEGORY:
			return { ...state, card_category_data: action.data };
		case SUPER_EVENT_CARD_DATA:
			return { ...state, super_event_card_data: action.data };
		case EVENT_FIGHT_DATA:
			return { ...state, fight_details: action.data };
		case EVENT_CONFIRM:
			return { ...state, event_confirm: action.data };
		case SUPER_EVENT_STATUS:
			return { ...state, super_event_status: action.data };
		case EVENT_ADMIN:
			return { ...state, event_admin: action.data };
		case EVENT_INVITE:
			return { ...state, event_invite: action.data };
		case EVENT_FIGHT_CARD:
			return { ...state, event_fight_card: action.data };
		case EVENT_TICKET:
			return { ...state, event_ticket: action.data };
		case CURRENT_CREATE_EVENT_TAB:
			return { ...state, current_tab: action.data };
		case CURRENT_FIGHT_TAB:
			return { ...state, current_fight_tab: action.data };
		case CURRENT_SUPER_EVENT_PAGE:
			return { ...state, super_event_page: action.data };
		case SUPER_EVENT_DETAILS:
			return { ...state, super_event_details: action.data };
		case SUPER_EVENT_FIGHT_DETAILS:
			return { ...state, super_event_fight_details: action.data };
		case EVENT_STATUS:
			return { ...state, event_status: action.data };
		case SUPER_EVENT_CATEGORY_DATA:
			return { ...state, super_event_category_details: action.data };
		default:
			return state;
	}
}
