import { useContext, useEffect, useState } from 'react'; //  useContext
import { useParams } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';
import { eventDetails } from 'src/redux-state/actions/createEvent';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { Config } from 'src/helpers/context/config';
import { eventTypeList } from 'src/helpers/constants/identifier';
import { Left } from './Left';
import { Right } from './Right';
import { NewEvent } from './NewEvent';
import { ComingSoon, Page } from 'src/components/ui';
import './events.scss';

export const Events = ({
	currentCreateEventTab,
	eventDetails,
	eventType,
	eventFightCard,
	eventTicket,
	eventConfirm,
	category,
	eventAdmin,
	superEventStatus,
	currentFightTab,
	eventInvite,
	eventFightData,
	currentSuperEventPage,
	cardCategory,
	supurEventCardData,
}) => {
	const auth = useSelector((state) => state.Combat?.authData);
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const { t } = useTranslation();
	const create_data = useSelector((state) => state.Events);
	const { type } = useParams();
	const { id } = useParams();
	const [eventPage, setEventPage] = useState('eventDetails');
	const [eventAdminList, setEventAdminList] = useState([]);
	const user = auth ? auth : fetchFromStorage('authData');
	const [fightTitleList, setFightTitleList] = useState([]);
	const [tourFightList, setTourFightList] = useState([]);
	const [fightRuleList, setFightRuleList] = useState([]);
	const [titleTypeList, setTitleTypeList] = useState([]);
	const [disciplinesData, setDisciplinesData] = useState([]);
	const [eventFightSides, setEventFightSides] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [eventListTab, setEventListTab] = useState();
	const [eventListQs, setEventListQs] = useState();
	const [searchData, setSearchData] = useState();
	const [slidebarFil, setSlidebarFil] = useState();
	const [slidebarList, setSlidebarList] = useState([]);
	const [weightClasses, setWeightClasses] = useState([]);
	const [timeZoneList, setTimezoneList] = useState([]);
	// const [eventTypeList, setEventTypeList] = useState([
	//   { id: 'fight', name: "Fight" },
	//   { id: 'press_conference', name: "Press Conference" },
	//   { id: 'session', name: "Session" },
	//   { id: 'seminar', name: "Seminar" },
	//   { id: 'other', name: "Other" }
	// ]);
	const [eventList, setEventList] = useState([]);

	let { event_type } = create_data;
	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// useEffect(() => {
	//   if (location.state) {
	//     location.state.fightCard === 'fightCard' && currentCreateEventTab(1)
	//   }
	// }, [location])
	const handleNextEvent = (data) => {
		setEventPage('eventDetails');
		eventType(data?.event_type);
		category(data?.category);
		eventConfirm('create');
	};
	const handleNewEvent = () => {
		setEventPage('newEvent');
		window.location.href = '/events/create';
	};
	// const fetchEventTypeList = async () => {
	//   try {
	//     const { status, data } = await axiosInstance.get(URLS.getEventTypeList);
	//     if (status === 200) {
	//       setEventTypeList(data?.getEventType);
	//     }
	//   } catch (error) { }
	// };
	const fetchEventUserCommunityOrg = async (typeVal, nameVal, queryString) => {
		setEventAdminList([]);

		try {
			let { status, data } = await axiosInstance.get(URLS.getEventsUserComOrgList, {
				params: {
					type: typeVal,
					name: nameVal ? nameVal : '',
					profile_type: queryString?.profile_type ? queryString?.profile_type : '',
					// page: 1,
					// limit: 50
				},
				authHeader,
			});

			if (status === 200) {
				setEventAdminList(data?.eventDataList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchFightTitleList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getTitleFightList);

			if (status === 200) {
				setFightTitleList(data?.getEventType);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchTournamentFightList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getTournamentFightList);

			if (status === 200) {
				setTourFightList(data?.getEventType);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchFightRuleList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getFightRuleList);

			if (status === 200) {
				setFightRuleList(data?.getEventType);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchTitleTypeList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getTitleTypeList);

			if (status === 200) {
				setTitleTypeList(data?.getEventType);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchDisciplinesData = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getdisciplineTypes);

			if (status === 200) {
				setDisciplinesData(data?.getDisciplinesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchEventFightSides = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getEventFightSides);

			if (status === 200) {
				setEventFightSides(data?.getMetaFightsData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchWeightClasses = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getWeightClasses);

			if (status === 200) {
				setWeightClasses(data?.getMetaWeightClassesData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchAllUsers = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsers);

			if (status === 200) {
				setAllUsers(data?.usersData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchTimeZoneList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getTimeZoneList);

			if (status === 200) {
				setTimezoneList(data?.getTimeZoneList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const fetchEventList = async (tabValue, queryString, searchVal, leftFilter) => {
		tabValue && setEventListTab(tabValue);
		queryString && setEventListQs(queryString);
		searchVal && setSearchData(searchVal);
		leftFilter && setSlidebarFil(leftFilter);

		try {
			let { status, data } = await axiosInstance.get(URLS.getEventList, {
				params: {
					type: tabValue,
					status: queryString?.status ? queryString?.status : '',
					event_type: queryString?.event_type ? queryString?.event_type : '',
					...(queryString?.start_time !== ' ' && { start_time: queryString?.start_time }),
					...(queryString?.end_time !== ' ' && { end_time: queryString?.end_time }),
					lat: queryString?.lat,
					long: queryString?.long,
					name: searchVal,
					location_type: queryString?.location_type,
					// page: 1,
					// limit: 20,
					cal_main: leftFilter.cal_main,
					cal_user: leftFilter.cal_user,
					cal_org: leftFilter.cal_org,
					cal_community: leftFilter.cal_community,
				},
				authHeader,
			});

			if (status === 200) {
				setEventList(data?.getEventList);
				setEventListQs();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchSidebarList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getSidebarList);

			if (status === 200) {
				setSlidebarList(data?.sideBarListArr);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchFightTitleList();
		fetchTournamentFightList();
		fetchFightRuleList();
		fetchTitleTypeList();
		fetchDisciplinesData();
		fetchEventFightSides();
		fetchWeightClasses();
		fetchAllUsers();
		fetchSidebarList();
		fetchTimeZoneList();
	}, [type]);

	// useEffect(() => {
	//   if(EVENT_FILTER){
	//     fetchEventList(EVENT_FILTER?.tab, EVENT_FILTER?.queryParams, '', EVENT_FILTER?.slidebarFilter);
	//   }

	// }, []);
	return (
		<Page title="Events | Combat Matrix">
			<Box className="events-page-main-container">
				<Container maxWidth="lg">
					<Box className="combat-container ">
						{Config.isEventUnable === 'true' ? (
							<>
								{eventPage === 'newEvent' || (type === 'create' && !event_type) ? (
									<NewEvent
										handleNextEvent={handleNextEvent}
										superEventStatus={superEventStatus}
										eventTypeList={eventTypeList}
										eventDetails={eventDetails}
										eventType={eventType}
										eventFightCard={eventFightCard}
										eventTicket={eventTicket}
										category={category}
										eventAdmin={eventAdmin}
										eventInvite={eventInvite}
										eventFightData={eventFightData}
										currentCreateEventTab={currentCreateEventTab}
										supurEventCardData={supurEventCardData}
										eventConfirm={eventConfirm}
									/>
								) : eventPage === 'eventDetails' ? (
									<Box className="events-container">
										<Box className="events-grid-container">
											<Box className="events-left">
												<Left
													type={type}
													id={id}
													create_data={create_data}
													token={token}
													user={user}
													handleNewEvent={handleNewEvent}
													slidebarList={slidebarList}
													fetchEventList={fetchEventList}
													eventListTab={eventListTab}
													eventListQs={eventListQs}
													searchData={searchData}
													currentCreateEventTab={currentCreateEventTab}
													currentFightTab={currentFightTab}
													eventFightCard={eventFightCard}
													currentSuperEventPage={currentSuperEventPage}
													eventTypeList={eventTypeList}
													cardCategory={cardCategory}
													supurEventCardData={supurEventCardData}
												/>
											</Box>
											<Box className="events-right">
												<Box className="right-event-content">
													<Right
														type={type}
														create_data={create_data}
														currentCreateEventTab={currentCreateEventTab}
														eventDetails={eventDetails}
														eventType={eventType}
														eventFightCard={eventFightCard}
														eventTicket={eventTicket}
														// eventConfirm={eventConfirm}
														token={token}
														user={user}
														fetchEventUserCommunityOrg={fetchEventUserCommunityOrg}
														eventAdminList={eventAdminList}
														eventAdmin={eventAdmin}
														eventInvite={eventInvite}
														fightTitleList={fightTitleList}
														tourFightList={tourFightList}
														fightRuleList={fightRuleList}
														titleTypeList={titleTypeList}
														disciplinesData={disciplinesData}
														eventFightSides={eventFightSides}
														allUsers={allUsers}
														weightClasses={weightClasses}
														eventFightData={eventFightData}
														eventTypeList={eventTypeList}
														fetchEventList={fetchEventList}
														slidebarFil={slidebarFil}
														eventList={eventList}
														timeZoneList={timeZoneList}
														supurEventCardData={supurEventCardData}
													/>
												</Box>
											</Box>
										</Box>
									</Box>
								) : (
									<NewEvent
										handleNextEvent={handleNextEvent}
										superEventStatus={superEventStatus}
										eventTypeList={eventTypeList}
										eventDetails={eventDetails}
										eventType={eventType}
										eventFightCard={eventFightCard}
										eventTicket={eventTicket}
										category={category}
										eventAdmin={eventAdmin}
										currentCreateEventTab={currentCreateEventTab}
										eventInvite={eventInvite}
										eventFightData={eventFightData}
										supurEventCardData={supurEventCardData}
										eventConfirm={eventConfirm}
									/>
								)}
							</>
						) : (
							<ComingSoon />
						)}
						{/* <ComingSoon /> */}
					</Box>
				</Container>
			</Box>
		</Page>
	);
};
