import { Box, Typography, Avatar, IconButton } from '@material-ui/core';
import { ReactComponent as CorrectSign } from 'src/assets/images/icons/correct-blue.svg';
import { useTranslation } from 'react-i18next';

export const ReactedData = ({ name, profileType, profile, likeId, likedImg }) => {
	const { t } = useTranslation();

	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		{ id: 128, name: t('profile_type.gym') },
		{ id: 256, name: t('profile_type.promotion') },
		{ id: 512, name: t('profile_type.product_service') },
	];

	const filteredArray = typeList.filter((value) => profileType.includes(value?.id));

	return (
		<Box className="reacted-data-sec">
			<Box className="reacted-user-profile-sec">
				<Avatar src={profile} className="user-profile" />
				<Avatar src={likedImg} className="user-reacted" />
			</Box>

			<Box className="reacted-user-sec">
				<Box className="user-name-sec">
					<Typography className="user-name">{name}</Typography>
					<IconButton className="user-sign">
						<CorrectSign />
					</IconButton>
				</Box>

				<Typography className="user-skill">
					{filteredArray
						.map(function (elem) {
							return elem.name;
						})
						.join(', ')}
				</Typography>
			</Box>
		</Box>
	);
};
