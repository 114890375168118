import { useRef } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'src/components/ui';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';

export const EditEmail = ({ message, token, handleClose, singleItemData, fetchUsersData, currentPage }) => {
	const { t } = useTranslation();

	const formikRef = useRef();
	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	return (
		<Formik
			innerRef={formikRef}
			initialValues={{
				email1: '',
				email2: '',
			}}
			validationSchema={Yup.object().shape({
				email1: Yup.string().email(t('validation.valid_email')).max(255).required(t('validation.required_message')),

				email2: Yup.string()
					.email('Must be a valid email')
					.when('email1', {
						is: (val) => (val && val.length > 0 ? true : false),
						then: Yup.string().oneOf([Yup.ref('email1')], 'Email and Confirm new email'),
					}),
			})}
			onSubmit={async (datas) => {
				let requestData = {
					email: datas.email1,
				};

				try {
					const { status, data } = await axiosInstance.put(
						URLS.updateEmailAddress + `/${singleItemData?.user_id}`,
						requestData,
						authHeader,
					);

					if (status === 200) {
						setApiMessage('success', data?.message);
						fetchUsersData(currentPage);
						handleClose();
					}
				} catch (error) {
					setApiMessage('error', error?.response?.data?.message);
				}
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
				<form onSubmit={handleSubmit}>
					<Box className="changeemail-box">
						<Grid item xs={12} md={12} className="text-field-grid">
							<TextField
								error={Boolean(touched.email1 && errors.email1)}
								fullWidth
								helperText={touched.email1 && errors.email1}
								variant="filled"
								InputProps={{
									endAdornment: touched.email1 && errors.email1 ? <ErrorIcon /> : null,
								}}
								placeholder={t('acount_setting.Enter_new_email')}
								label={t('acount_setting.Enter_new_email')}
								size="small"
								name="email1"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email1}
							/>
						</Grid>
						<Grid item xs={12} md={12} className="text-field-grid">
							<TextField
								error={Boolean(touched.email2 && errors.email2)}
								fullWidth
								helperText={touched.email2 && errors.email2}
								variant="filled"
								InputProps={{
									endAdornment: touched.email2 && errors.email2 ? <ErrorIcon /> : null,
								}}
								placeholder={t('acount_setting.Re_enter_new_email')}
								label={t('acount_setting.Re_enter_new_email')}
								size="small"
								name="email2"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email2}
							/>
						</Grid>

						<Grid item xs={12} md={12}>
							<Button variant="contained" fullWidth type="submit" className="change-btn">
								{t('acount_setting.change')}
							</Button>
						</Grid>
					</Box>
				</form>
			)}
		</Formik>
	);
};
