import { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, Container, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import NotificationLogo from 'src/assets/images/icons/notification/notification-logo.svg';
import moment from 'moment';
import { NotificationItem } from './NotificationItem';
import './notification.scss';
import { Page } from 'src/components/ui';

export const Notification = ({ getUserStatistics }) => {
	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;
	// const statics = useSelector(state => state?.Combat?.user_statics);
	// const user_statics = statics ? statics : fetchFromStorage('user_statics');
	const { t } = useTranslation();

	const [loader, setLoader] = useState(false);
	const [notification, setNotification] = useState([]);
	const auth = useSelector((state) => state.Combat.authData);
	const user = auth ? auth : fetchFromStorage('authData');

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// GET ALL NOTIFICATIONS
	const fetchNotifications = async () => {
		setLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.getNotification, headers);

			if (status === 200) {
				setNotification(data?.getNotification);
				setLoader(false);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	const fetchUserStaticData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getStatics, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				saveToStorage('user_statics', data?.UserStatisticsDetails);
				getUserStatistics(data?.UserStatisticsDetails);
				fetchNotifications();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// READ NOTIFICATION
	const readNotification = async (id) => {
		try {
			let { status } = await axiosInstance.put(
				URLS.readNotification + id,
				{
					status: 'read',
				},
				headers,
			);

			if (status === 200) {
				fetchUserStaticData();
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchNotifications();
		fetchUserStaticData();
		// user_statics?.unreadNotification > 0 && readNotification();
		// eslint-disable-next-line
	}, []);

	return (
		<Page title="Notifications | Combat Matrix">
			<Box className="notification-screen">
				<Container maxWidth="lg">
					<Box className="combat-container">
						{loader ? (
							<Box display="flex" justifyContent="center" alignItems="center">
								<CircularProgress size={18} color="secondary" />
							</Box>
						) : notification?.length > 0 ? (
							notification.map((data, index) => {
								return (
									<NotificationItem
										key={index}
										name={data?.message}
										image={NotificationLogo}
										requestData={data?.UserMeta}
										notificationItem={data}
										readNotification={readNotification}
										time={moment.utc(data?.timestamp_created).fromNow()}
										// time={moment.utc(data?.timestamp_created).fromNow().replace('in ', '')}
										// request={true}
										user={user}
									/>
								);
							})
						) : (
							<Box>
								<Typography align="center" className="no-notification-text">
									{t('no_notification')}
								</Typography>
							</Box>
						)}
					</Box>
				</Container>
			</Box>
		</Page>
	);
};
