export const AUTH_USER = 'AUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const PROFILE_DETAILS = 'PROFILE_DETAILS';
export const PROFILE_TYPE = 'PROFILE_TYPE';
export const PROFILE_CONFIRM = 'PROFILE_CONFIRM';
export const SETUP_ORG_CHECK = 'SETUP_ORG_CHECK';
export const SETUP_ORG = 'SETUP_ORG';
export const ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS';
export const USER_VERIFY_COUNT = 'USER_VERIFY_COUNT';
export const USER_STATICS_DATA = 'USER_STATICS_DATA';
export const FIGHT_METHOD = 'FIGHT_METHOD';
export const FIGHT_WEIGHT = 'FIGHT_WEIGHT';
export const USER_DATA = 'USER_DATA';
export const USER_NEXT_PAGE_DATA = 'USER_NEXT_PAGE_DATA';
export const FOLLOWERS_DATA = 'FOLLOWERS_DATA';
export const FOLLOWERS_NEXT_PAGE_DATA = 'FOLLOWERS_NEXT_PAGE_DATA';
export const EVENT_DETAILS = 'EVENT_DETAILS';
export const EVENT_TYPE = 'EVENT_TYPE';
export const CATEGORY = 'CATEGORY';
export const CARD_CATEGORY = 'CARD_CATEGORY';
export const EVENT_ADMIN = 'EVENT_ADMIN';
export const EVENT_INVITE = 'EVENT_INVITE';
export const EVENT_FIGHT_DATA = 'EVENT_FIGHT_DATA';
export const SUPER_EVENT_STATUS = 'SUPER_EVENT_STATUS';
export const EVENT_FIGHT_CARD = 'FIGHT_CARD';
export const EVENT_TICKET = 'EVENT_TICKET';
export const EVENT_CONFIRM = 'EVENT_CONFIRM';
export const SUPER_EVENT_CARD_DATA = 'SUPER_EVENT_CARD_DATA';
export const CURRENT_CREATE_EVENT_TAB = 'CURRENT_CREATE_EVENT_TAB';
export const CURRENT_FIGHT_TAB = 'CURRENT_FIGHT_TAB';
export const CURRENT_SUPER_EVENT_PAGE = 'CURRENT_SUPER_EVENT_PAGE';
export const GET_COMMUNITY = 'GET_COMMUNITY';
export const EVENT_STATUS = 'EVENT_STATUS';
export const GET_COMMUNITY_DIRECTORY = 'GET_COMMUNITY_DIRECTORY';
export const GET_COMMUNITY_DETAILS = 'GET_COMMUNITY_DETAILS';
export const GET_COMMUNITY_POSTS_DETAILS = 'GET_COMMUNITY_POSTS_DETAILS';
export const REMOVE_OR_ADD_STICKY_POST_IN_COMMUNITY = 'REMOVE_OR_ADD_STICKY_POST_IN_COMMUNITY';
export const UPDATE_COMMUNITY_POST_DETAILS = 'UPDATE_COMMUNITY_POST_DETAILS';
export const GET_NEWSFEED_POST_DETAILS = 'GET_NEWSFEED_POST_DETAILS';
export const GET_ACCOUNT_SETTING_DETAILS = 'GET_ACCOUNT_SETTING_DETAILS';
export const GET_ALL_ACTIVITY = 'GET_ALL_ACTIVITY';
export const GET_ALL_MESSAGE_LIST = 'GET_ALL_MESSAGE_LIST';
export const SUPER_EVENT_DETAILS = 'SUPER_EVENT_DETAILS';
export const SUPER_EVENT_FIGHT_DETAILS = 'SUPER_EVENT_FIGHT_DETAILS';
export const SUPER_EVENT_CATEGORY_DATA = 'SUPER_EVENT_CATEGORY_DATA';
