import { eventStatus } from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';

import { Left as LeftComponent } from './Left';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const Left = connect(mapStateToProps, mapDispatchToProps)(LeftComponent);
