import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { Box, Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
// import _ from 'lodash';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { TextField } from 'src/components/ui';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import './about.scss';
import { useTranslation } from 'react-i18next';

export const AboutProfileType = ({
	id,
	isCreating,
	availableAbouts,
	pendingIds,
	data,
	types,
	token,
	getMyProfile,
	closeDialog,
	isModalLoading,
	setIsModalLoading,
}) => {
	const { t } = useTranslation();
	const [check, setCheck] = useState([]);
	const [showingIds, setShowingIds] = useState([]);
	const [profileAbouts, setProfileAbouts] = useState([]);
	const [showMore, setShowMore] = useState(false);
	const formikRef = useRef();

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// PROFILE TYPES
	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	let pendingProfileTypes = data?.map((v) =>
		v?.userAboutTypes?.filter((c) => {
			return id.indexOf(c) !== -1;
		}),
	);
	let aboutVal = pendingProfileTypes
		?.map((f, i) => {
			if (f.length > 0) {
				return data[i];
			}

			return f;
		})
		.filter((b) => b.length !== 0);

	// PROFILE TYPE CHECK HANDLER
	const EditcheckHandler = (value) => () => {
		const currentIndex = check.indexOf(value);
		const newCheck = [...check];

		if (currentIndex === -1) {
			newCheck.push(value);
			// let tcount = newCheck?.reduce((a, b) => a + b);

			let addedAbout = [...profileAbouts];

			addedAbout.push({
				type: value,
				post_id: profileAbouts[0]?.post_id,
				name: profileAbouts[0]?.name,
				for: id.includes(value) ? profileAbouts[0]?.for : [],
			});

			setProfileAbouts(addedAbout);
		} else {
			// REMOVE CHECK
			if (newCheck.length > 1) {
				newCheck.splice(currentIndex, 1);

				let newCheckAbout = newCheck.map((ss) => {
					return {
						type: ss,
						post_id: profileAbouts[0]?.post_id,
						name: profileAbouts[0]?.name,
						for: profileAbouts[0]?.for,
					};
				});

				setProfileAbouts(newCheckAbout);
			}
		}

		setCheck(newCheck);
	};

	// SET SHOWING IDS (PENDING IDS TO CREATE PROFILE TYPE ABOUT)
	useEffect(() => {
		// EDIT ABOUT
		if (!isCreating) {
			setCheck(id);

			let editAbt = id.map((ss) => {
				return {
					type: ss,
					post_id: aboutVal[0]?.post_id,
					name: aboutVal[0]?.Post?.PostLists?.Item?.data,
				};
			});

			setProfileAbouts(editAbt);
		} else {
			// ADD ABOUT
			// setShowingIds(pendingIds);
			setCheck(id);
		}

		setShowingIds(id);

		// eslint-disable-next-line
	}, []);

	// SET ABOUT TEXT TO TEXTFIELD
	useEffect(() => {
		if (!isCreating) {
			if (formikRef.current) {
				formikRef.current.setFieldValue('about', aboutVal[0]?.Post?.PostLists?.Item?.data);
			}
		}
		// if

		// eslint-disable-next-line
	}, []);

	// SAVE SUBMIT HANDLER
	const submitHandler = async (dataVal) => {
		// let haveToKeep = id
		//   .filter(c => {
		//     return check.indexOf(c) === -1;
		//   })
		//   .map(s => {
		//     return {
		//       name: aboutVal[0]?.Post?.name,
		//       type: s
		//       // post_id: aboutVal[0]?.post_id
		//     };
		//   });

		let checkedData = {
			name: dataVal?.about,
			type: check.reduce((a, b) => a + b),
			post_id: aboutVal[0]?.post_id,
		};

		let EditedData = [checkedData]; // ...haveToKeep

		let createdData = [
			{
				name: dataVal?.about,
				type: check.reduce((a, b) => a + b),
			},
		];

		const requestData = { user_about: isCreating ? createdData : EditedData };

		try {
			setIsModalLoading(true);
			const { status } = await axiosInstance.put(URLS.updateProfile, requestData, authHeader);

			if (status === 200) {
				setApiMessage('success', t('myprofile.profile_updated_success'));
				getMyProfile();
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// ADD COMMON ABOUT TO OTHER PROFILE TYPES HANDLER
	const showMoreHandler = () => {
		setShowMore(true);
		// setShowingIds(types);
	};

	let pendingTypes = types
		.filter((x) => {
			return id.indexOf(x) === -1;
		})
		.filter((x) => x !== 0); // to remove fan-member

	return (
		<Box className="about-add-edit-form">
			<Formik
				innerRef={formikRef}
				initialValues={{
					about: aboutVal?.length > 0 ? aboutVal[0]?.Post?.PostLists?.Item?.data : [],
				}}
				onSubmit={(dataVal) => {
					submitHandler(dataVal);
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3} className="about-dialog-container">
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									fullWidth
									helperText={
										touched.about ? (
											touched.about && (
												<Box disply="flex" justifyContent="center">
													{errors.about}
													<span className="field-character-count">{values.about.length}/10000</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.about.length}/10000</span>
										)
									}
									InputProps={{
										maxLength: 10000,
									}}
									inputProps={{ maxLength: 10000 }}
									variant="filled"
									label={t('myprofile.edit_about')}
									placeholder={t('myprofile.edit_about')}
									className="matrix-textfield"
									size="small"
									name="about"
									multiline
									rows={10}
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										let updateabout = profileAbouts.map((obj) => {
											return { ...obj, name: e.target.value };
										});

										setProfileAbouts(updateabout);
									}}
									value={values.about}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<Box className="selected-about-types">
									<Typography className="attach-abt-txt">{t('myprofile.attach_about')}</Typography>
									<List>
										{showingIds?.map((type, index) => {
											return (
												// EDIT PROFILE TYPE ABOUT
												<ListItem key={index} role={undefined} dense button onClick={EditcheckHandler(type)}>
													<ListItemIcon>
														<Checkbox
															icon={<CheckBoxIcon />}
															checkedIcon={<CheckedBoxIcon />}
															edge="start"
															checked={check.indexOf(type) !== -1}
															disableRipple
															disableTouchRipple
															disableFocusRipple
														/>
													</ListItemIcon>
													<ListItemText primary={typeList.filter((v) => Number(v.id) === Number(type))[0]?.name} />
												</ListItem>
											);
										})}
									</List>
								</Box>

								{pendingTypes?.length > 0 ? (
									!showMore ? (
										<Box className="selected-about-types add-confirmation">
											<Typography className="attach-abt-txt">
												{t('myprofile.attach_more')}
												<Button
													disableElevation
													disableTouchRipple
													disableRipple
													disableFocusRipple
													variant="contained"
													className="add-this-to-other"
													onClick={showMoreHandler}
												>
													{t('switch_txt.yes')}
												</Button>
											</Typography>
										</Box>
									) : (
										<Box className="selected-about-types add-confirmation">
											<Typography className="attach-abt-txt">{t('myprofile.attach_more')}</Typography>
										</Box>
									)
								) : null}

								{showMore ? (
									<Box className="selected-about-types">
										<List>
											{pendingTypes?.map((type, index) => {
												return (
													// EDIT PROFILE TYPE ABOUT
													<ListItem key={index} role={undefined} dense button onClick={EditcheckHandler(type)}>
														<ListItemIcon>
															<Checkbox
																icon={<CheckBoxIcon />}
																checkedIcon={<CheckedBoxIcon />}
																edge="start"
																checked={check.indexOf(type) !== -1}
																disableRipple
																disableTouchRipple
																disableFocusRipple
															/>
														</ListItemIcon>
														<ListItemText primary={typeList.filter((v) => Number(v.id) === Number(type))[0]?.name} />
													</ListItem>
												);
											})}
										</List>
									</Box>
								) : null}
							</Grid>
							<Grid item xs={12} className="dialog-submit-container dialog-sticky-btn">
								<Box className="dialog-submit-button">
									<Button disabled={isModalLoading} variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
