import {
	cardCategory,
	category,
	currentCreateEventTab,
	currentFightTab,
	currentSuperEventPage,
	eventAdmin,
	eventConfirm,
	eventDetails,
	eventFightCard,
	eventFightData,
	eventInvite,
	eventTicket,
	eventType,
	superEventStatus,
	supurEventCardData,
} from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';
import { Events as EventsComponent } from './Events';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		currentCreateEventTab: (data) => dispatch(currentCreateEventTab(data)),
		eventDetails: (data) => dispatch(eventDetails(data)),
		eventType: (data) => dispatch(eventType(data)),
		category: (data) => dispatch(category(data)),
		eventAdmin: (data) => dispatch(eventAdmin(data)),
		eventInvite: (data) => dispatch(eventInvite(data)),
		eventFightCard: (data) => dispatch(eventFightCard(data)),
		eventTicket: (data) => dispatch(eventTicket(data)),
		superEventStatus: (data) => dispatch(superEventStatus(data)),
		eventFightData: (data) => dispatch(eventFightData(data)),
		eventConfirm: (data) => dispatch(eventConfirm(data)),
		currentFightTab: (data) => dispatch(currentFightTab(data)),
		currentSuperEventPage: (data) => dispatch(currentSuperEventPage(data)),
		cardCategory: (data) => dispatch(cardCategory(data)),
		supurEventCardData: (data) => dispatch(supurEventCardData(data)),
	};
};

export const Events = connect(mapStateToProps, mapDispatchToProps)(EventsComponent);
