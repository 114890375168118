import { nextPageFollowersData, setFollowersData } from '../../../redux-state/actions/userProfile';
import { connect } from 'react-redux';
import { Followers as FollowersComponent } from './Followers';

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setFollowersData: (data) => dispatch(setFollowersData(data)),
		nextPageFollowersData: (data) => dispatch(nextPageFollowersData(data)),
	};
};

export const Followers = connect(mapStateToProps, mapDispatchToProps)(FollowersComponent);
