import { Box, Typography, Grid } from '@material-ui/core';
import { ReactComponent as BadgeVerificationIcon } from 'src/assets/images/icons/verification_icon.svg';
import '../specialbadge.scss';
import { useTranslation } from 'react-i18next';

const journalist = [
	{
		name: 'verified journalist',
		content: 'Fully Verified Journalist',
	},
	{
		name: 'verified photographer',
		content: 'Fully Verified Photographer',
	},
	{
		name: 'leveled journalist',
		content: 'Has written ....... segments',
	},
	{
		name: 'leveled photographer',
		content: 'Featured in ........ publications',
	},
];

export const JournalistBadge = () => {
	const { t } = useTranslation();

	return (
		<>
			<Typography className="general_subtitle">{t('badge.journalist')}</Typography>
			<Grid container spacing={1}>
				{journalist.map((item) => {
					return (
						<>
							<Grid item xs={12} md={6} lg={4}>
								<Box className="badge_block">
									<Box className="icon_block">
										<BadgeVerificationIcon />
									</Box>

									<Box className="badge_content">
										<Typography className="block_title">{item.name}</Typography>
										<Typography className="nbsb_title">{item.content}</Typography>
									</Box>
								</Box>
							</Grid>
						</>
					);
				})}
			</Grid>
		</>
	);
};
