import { connect } from 'react-redux';
import { getAccountSettingData } from 'src/redux-state/actions/actions';
import { Password as PasswordComponent } from './Password';

const mapDispatchToProps = (dispatch) => {
	return {
		getAccountSettingData: (data) => dispatch(getAccountSettingData(data)),
	};
};

export const Password = connect(mapDispatchToProps)(PasswordComponent);
