import './sales.scss';
import React, { useEffect } from 'react';
import { CustomPaginations, Page } from 'src/components/ui';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Container,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Input,
	Button,
	CircularProgress,
} from '@material-ui/core';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { toast } from 'react-toastify';
import { URLS } from 'src/helpers/constants/urls';

const Sales = () => {
	const { t } = useTranslation();
	const [sales, setSales] = React.useState([]);
	const [remitAmount, setRemitAmount] = React.useState([]);
	const [remLoader, setRemLoader] = React.useState({});
	const [sortOrder, setSortOrder] = React.useState({ is_remitted: 'desc', amount_received: 'desc' });
	const [currentPage, setCurrentPage] = React.useState(1);
	const [totalRecord, setTotalRecords] = React.useState(0);
	const [limit] = React.useState(20);
	const [loadingCSV, setLoadingCSV] = React.useState(false);
	const [salesLoader, setSalesLoader] = React.useState(false);

	useEffect(() => {
		if (sales.length === 0) {
			setSalesLoader(true);
			(async () => {
				const { data, status } = await axiosInstance.get(`${URLS.allSales}?page=${currentPage}&limit=${limit}`);

				if (status === 200) {
					setSales(data.data);
					setTotalRecords(data.metaData.total);
					setSalesLoader(false);
				} else {
					toast.success('No Sales Yet');
					setSalesLoader(false);
				}
			})();
		} else {
			return;
		}
	}, []);

	const paginateSales = (curPage) => {
		(async () => {
			setSalesLoader(true);
			const { data, status } = await axiosInstance.get(`${URLS.allSales}?page=${curPage}&limit=${limit}`);

			if (status === 200) {
				setSales(data.data);
				setTotalRecords(data.metaData.total);
				setSalesLoader(false);
			} else {
				toast.success('No Sales Yet');
				setSalesLoader(false);
			}
		})();
	};

	const handleRemitAmount = (value, index) => {
		if (value > sales[index].amount_received) {
			toast.error('Remit amount cannot be greater than amount received');

			return;
		} else {
			const newRemitAmounts = [...remitAmount];

			newRemitAmounts[index] = value;
			setRemitAmount(newRemitAmounts);
		}
	};

	const handleRemit = async (index) => {
		setRemLoader({ [index]: true });
		const amount = remitAmount[index];
		const invoice_id = sales[index].invoice_id;

		if (!amount) {
			toast.error('Remit amount cannot be zero');
			setRemLoader({});

			return;
		}

		if (amount > sales[index].amount_received) {
			toast.error('Remit amount cannot be greater than amount received');
			setRemLoader({});

			return;
		}

		if (amount === 0) {
			toast.error('Remit amount cannot be zero');
			setRemLoader({});

			return;
		}

		const { data, status } = await axiosInstance.post(`${URLS.remitSale}`, {
			amount: +amount,
			invoice_id: invoice_id,
		});

		if (status === 200) {
			setSales((prevSale) => {
				const newSales = [...prevSale];

				newSales[index].amount_remitted = amount;
				newSales[index].is_remitted = 1;
				newSales[index].remitted_at = new Date();

				return newSales;
			});

			setRemitAmount((prevRemitAmount) => {
				const newRemitAmount = [...prevRemitAmount];

				newRemitAmount.splice(index, 1);

				return newRemitAmount;
			});
			setRemLoader({});
			toast.success('Remitted Successfully');
		} else {
			setRemLoader({});
			toast.error('Something went wrong');
		}
	};

	const onSortClick = (key) => {
		setRemitAmount([]);

		if (sortOrder[key] === 'desc') {
			setSales((prevSales) => {
				const newSales = [...prevSales].sort((a, b) => {
					return a[key] - b[key];
				});

				return newSales;
			});
			setSortOrder((prevOrder) => {
				const newSortOrder = { ...prevOrder, [key]: 'asc' };

				return newSortOrder;
			});
		} else {
			setSales((prevSales) => {
				const newSales = [...prevSales].sort((a, b) => {
					return b[key] - a[key];
				});

				return newSales;
			});
			setSortOrder((prevOrder) => {
				const newSortOrder = { ...prevOrder, [key]: 'desc' };

				return newSortOrder;
			});
		}
	};

	const handlePaginationButtonClick = (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				paginateSales(currentPage - 1);
			}
		} else if (currentPage < Math.ceil(totalRecord / limit)) {
			setCurrentPage(currentPage + 1);
			paginateSales(currentPage + 1);
		}
	};

	const handlePaginationClick = (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			paginateSales(Number(page));
		}
	};

	const downloadFile = ({ data, fileName, fileType }) => {
		const blob = new Blob([data], { type: fileType });

		const a = document.createElement('a');

		a.download = fileName;

		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});

		a.dispatchEvent(clickEvt);
		a.remove();
	};

	const exportToCsv = async (e) => {
		setLoadingCSV(true);
		const headers = [
			'DID,Author Name,User Name,Post Title,Price Paid,Purchase Date,Is Remitted,Remitted On,Remitted Amount',
		];

		try {
			const { data, status } = await axiosInstance.get(`${URLS.allSales}`);

			if (status === 200) {
				let salesCsv = data.data.reduce((acc, sale) => {
					const count = acc.length + 1;
					const {
						authorFullName,
						userFullName,
						PostName,
						amount_received,
						purchaseDate,
						is_remitted,
						remitted_at,
						amount_remitted,
					} = sale;

					acc.push(
						[
							count,
							authorFullName,
							userFullName,
							PostName,
							amount_received,
							purchaseDate,
							is_remitted,
							remitted_at,
							amount_remitted,
						].join(','),
					);

					return acc;
				}, []);

				downloadFile({
					data: [...headers, ...salesCsv].join('\n'),
					fileName: 'sales.csv',
					fileType: 'data:text/csv;charset=utf-8',
				});
				setLoadingCSV(false);
			} else {
				setLoadingCSV(false);
			}
		} catch (e) {
			setLoadingCSV(false);
			console.log(e);
		}

		e.preventDefault();
	};

	return (
		<Page title="All Sales">
			<Box className="admin-container-box">
				<Container maxWidth="lg">
					<Typography className="admin-page-haeding">{t('admin_panel.combat_sales.title')}</Typography>
					{salesLoader ? (
						<Box display="flex" justifyContent="center">
							<CircularProgress size={18} color="secondary" />
						</Box>
					) : (
						<>
							<Box className="admin-data-tables remit-button">
								<Button disabled={loadingCSV} onClick={exportToCsv} variant="outlined" style={{ marginBottom: '12px' }}>
									{t('admin_panel.combat_sales.download_sales_scv')}
								</Button>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>DID</TableCell>
												<TableCell>{t('admin_panel.combat_sales.author_name')}</TableCell>
												<TableCell>{t('admin_panel.combat_sales.user_name')}</TableCell>
												<TableCell>{t('admin_panel.combat_sales.post_title')}</TableCell>
												<TableCell>
													<TableSortLabel
														onClick={() => onSortClick('amount_received')}
														style={{ color: 'white' }}
														active={true}
														direction={sortOrder.amount_received}
													>
														{t('admin_panel.combat_sales.price_paid')}
													</TableSortLabel>
												</TableCell>
												<TableCell>{t('admin_panel.combat_sales.purchase_date')}</TableCell>
												<TableCell>
													<TableSortLabel
														onClick={() => onSortClick('is_remitted')}
														style={{ color: 'white' }}
														active={true}
														direction={sortOrder.is_remitted}
													>
														{t('admin_panel.combat_sales.is_remitted')}
													</TableSortLabel>
												</TableCell>
												<TableCell>{t('admin_panel.combat_sales.date_remitted')}</TableCell>
												<TableCell>{t('admin_panel.combat_sales.amount_to_remit')}</TableCell>
												<TableCell>{t('admin_panel.combat_sales.remit')}</TableCell>
												<TableCell />
											</TableRow>
										</TableHead>
										<TableBody>
											{sales.map((sale, index) => {
												return (
													<>
														<TableRow key={index}>
															<TableCell>{index + 1}</TableCell>
															<TableCell>{sale.authorFullName}</TableCell>
															<TableCell>{sale.userFullName}</TableCell>
															<TableCell>{sale.PostName}</TableCell>
															<TableCell>{'$' + sale.amount_received}</TableCell>
															<TableCell>{new Date(sale.purchaseDate).toLocaleDateString()}</TableCell>
															<TableCell>{sale.is_remitted ? <CheckIcon /> : <ClearIcon />}</TableCell>
															<TableCell>
																{sale.remitted_at ? new Date(sale.remitted_at).toLocaleDateString() : 'N/A'}
															</TableCell>
															<TableCell className="remit-amount-input">
																{sale.is_remitted ? (
																	<Typography>{'$' + sale.amount_remitted}</Typography>
																) : (
																	<Input
																		disabled={sale.is_remitted}
																		value={remitAmount[index] ? remitAmount[index] : 0}
																		onChange={(e) => {
																			handleRemitAmount(e.target.value, index);
																		}}
																		inputProps={{ min: 1, max: sale.amount_received }}
																		type="number"
																	/>
																)}
															</TableCell>
															<TableCell className="remit-button">
																<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{remLoader[index] ? (
																		<CircularProgress style={{ color: 'white', height: '20px', width: '20px' }} />
																	) : (
																		<Button
																			loading={remLoader[index]}
																			disabled={sale.is_remitted}
																			onClick={() => {
																				handleRemit(index);
																			}}
																			variant="outlined"
																		>
																			Remit
																		</Button>
																	)}
																</Box>
															</TableCell>
														</TableRow>
													</>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
								<CustomPaginations
									paginationArray={sales}
									currentPage={currentPage}
									list={limit}
									handlePaginationButtonClick={handlePaginationButtonClick}
									handlePaginationClick={handlePaginationClick}
									totalRecord={totalRecord}
								/>
							</Box>
						</>
					)}
				</Container>
			</Box>
		</Page>
	);
};

export default Sales;
