import { Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './inviteModal.scss';
import { useLocation } from 'react-router-dom';

export const InviteModal = ({ fetchAcceptInvitationData, model, handleClose }) => {
	const { t } = useTranslation();
	const location = useLocation();

	return (
		<Box className="invite-modal-sec">
			<Typography className="invite-text">
				{model === 'closeCommuniy'
					? t('community.close_this_community')
					: model === 'openCommuniy'
					? t('community.open_this_community')
					: location?.state?.modalText}
			</Typography>
			{/* <Grid container>
                <Grid xs={6}>

                </Grid>
                <Grid xs={6}>

                </Grid>
            </Grid> */}
			<Box className="invite-btn-sec">
				<Button
					fullWidth
					className="decline-btn"
					onClick={() => {
						model === 'closeCommuniy'
							? handleClose()
							: model === 'openCommuniy'
							? handleClose()
							: fetchAcceptInvitationData('rejected');
					}}
				>
					{model === 'closeCommuniy' ? t('cancel') : model === 'openCommuniy' ? t('cancel') : t('community.Decline')}
				</Button>
				<Button
					fullWidth
					className="accept-invitation-btn"
					onClick={() => {
						fetchAcceptInvitationData('accepted');
					}}
				>
					{model === 'closeCommuniy'
						? t('close')
						: model === 'openCommuniy'
						? t('community.reopen')
						: t('community.accept_invitation')}
				</Button>
			</Box>
		</Box>
	);
};
