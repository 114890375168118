import { connect } from 'react-redux';
import { AccountSettings as AccountSettingsComponent } from './AccountSettings';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
// const mapDispatchToProps = dispatch => {
//     return {

//         getAccountSettingData: data => dispatch(getAccountSettingData(data))
//     };
// };

export const AccountSettings = connect(mapStateToProps)(AccountSettingsComponent);
