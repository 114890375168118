import { Box, Button, Typography, Grid, Link } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { TextField } from 'src/components/ui';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';

export const Password = ({ getAccountSettingData, closeDialog }) => {
	const { t } = useTranslation();
	const userData = fetchFromStorage('authData');

	return (
		<Box className="changeemail-box">
			<Formik
				initialValues={{
					currnetPassword: '',
					password: '',
					confirmPassword: '',
				}}
				validationSchema={Yup.object().shape({
					currnetPassword: Yup.string()
						.required(t('validation.required_message'))
						.min(6, t('validation.passowrd_length')),
					password: Yup.string().required(t('validation.required_message')).min(6, t('validation.passowrd_length')),
					confirmPassword: Yup.string()
						.required(t('validation.required_message'))
						.min(6, t('validation.passowrd_length'))
						.when('password', {
							is: (val) => (val && val.length > 0 ? true : false),
							then: Yup.string().oneOf([Yup.ref('password')], t('validation.password_confpass_not_match')),
						}),
				})}
				onSubmit={async (datas) => {
					let requestData = {
						oldPassword: datas?.currnetPassword,
						newPassword: datas?.password,
					};

					try {
						let { status, data } = await axiosInstance.put(URLS.getAccountSettingData, requestData);

						if (status === 200) {
							setApiMessage('success', data?.message);
							closeDialog();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.currnetPassword && errors.currnetPassword)}
									fullWidth
									helperText={touched.currnetPassword && errors.currnetPassword}
									variant="filled"
									InputProps={{
										endAdornment: touched.currnetPassword && errors.currnetPassword ? <ErrorIcon /> : null,
									}}
									label={t('acount_setting.Current_password') + '*'}
									placeholder={t('acount_setting.Current_password') + '*'}
									className="matrix-textfield"
									size="small"
									name="currnetPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.currnetPassword}
									type={'password'}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid position-relative">
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									variant="filled"
									InputProps={{
										endAdornment: touched.password && errors.password ? <ErrorIcon /> : null,
									}}
									label={`${t('password')}*`}
									placeholder={`${t('password')}*`}
									className="matrix-textfield"
									size="small"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.password}
									type={'password'}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid position-relative">
								<TextField
									error={Boolean(touched.confirmPassword && errors.confirmPassword)}
									fullWidth
									helperText={touched.confirmPassword && errors.confirmPassword}
									variant="filled"
									InputProps={{
										endAdornment: touched.confirmPassword && errors.confirmPassword ? <ErrorIcon /> : null,
									}}
									label={`${t('register.repeat_password')}*`}
									placeholder={`${t('register.repeat_password')}*`}
									className="matrix-textfield"
									size="small"
									name="confirmPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.confirmPassword}
									type={'password'}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<Button variant="contained" fullWidth type="submit" className="signup-button">
									{t('acount_setting.change')}
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
