import { Box, Container, Typography, Button } from '@material-ui/core';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { FightRecordSec } from './FightRecordSec';
import './SeeFightRecord.scss';

export const SeeFightRecord = () => {
	const navigate = useNavigate();
	const auth = useSelector((state) => state?.Combat?.authData);
	const { t } = useTranslation();

	let ownURI = auth ? auth?.user_meta?.data : fetchFromStorage('authData')?.user_meta?.data;

	const previousPathHandler = () => {
		navigate(`/user/${ownURI}`);
	};

	return (
		<Container maxWidth="lg">
			<Box className="combat-container see-fight-record-page">
				<Box className="see-fight-record-sec">
					<Box className="back-to-profile">
						<Button
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							startIcon={<BackIcon />}
							className="back-text"
							onClick={previousPathHandler}
						>
							{t('connections.backtoprofile')}
						</Button>
					</Box>
					<Typography className="fight-record-text">{t('combat_fight_record.fight_record')}</Typography>
					<Box className="see-all-fight-details">
						<FightRecordSec />
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
