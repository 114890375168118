import { useEffect, useState } from 'react';
import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { YouHaveCreated } from './YouHaveCreated';
import './discover-event.scss';
import { useTranslation } from 'react-i18next';

export const Discover = ({ slidebarFil, fetchEventList, eventList, eventTypeList }) => {
	const { t } = useTranslation();

	const [tab, setTab] = useState('1');
	const EVENT_FILTER = fetchFromStorage('eventfilter');
	const tab_list = [
		{ id: 1, name: t('events.all') },
		{ id: 2, name: t('events.you_created') },
		{ id: 3, name: t('events.you_are_admin') },
		{ id: 4, name: t('events.tagged_in') },
		{ id: 5, name: t('events.rsvp') },
	];
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
		const tabValue =
			newValue === '1'
				? 'all'
				: newValue === '2'
				? 'my-event'
				: newValue === '3'
				? 'admin'
				: newValue === '4'
				? 'tagged'
				: newValue === '5'
				? 'rsvp'
				: 'all';

		fetchEventList(tabValue, '', '', slidebarFil);
		var eventFilterObj = {
			...EVENT_FILTER,
			tab: tabValue,
			queryParams: undefined,
		};

		saveToStorage('eventfilter', eventFilterObj);
		// removeFromStorage('eventfilter');
	};
	const tabValue =
		tab === '1'
			? 'all'
			: tab === '2'
			? 'my-event'
			: tab === '3'
			? 'admin'
			: tab === '4'
			? 'tagged'
			: tab === '5'
			? 'rsvp'
			: 'all';

	useEffect(() => {
		if (EVENT_FILTER) {
			var storedTab = EVENT_FILTER?.tab ? EVENT_FILTER?.tab : 'all';
			var storedQp = EVENT_FILTER?.queryParams ? EVENT_FILTER?.queryParams : '';
			var storedFil = EVENT_FILTER?.slidebarFilter ? EVENT_FILTER?.slidebarFilter : '';
			var tabS =
				storedTab === 'all'
					? '1'
					: storedTab === 'my-event'
					? '2'
					: storedTab === 'admin'
					? '3'
					: storedTab === 'tagged'
					? '4'
					: storedTab === 'rsvp'
					? '5'
					: '1';

			setTab(tabS);
			fetchEventList(storedTab, storedQp, '', storedFil);
		} else {
			fetchEventList('all', '', '', slidebarFil);
		}
	}, []);

	return (
		<Box className="created-evets-sec">
			<TabContext value={String(tab)}>
				<Box className="actions-header mb-18 mt-27">
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler} aria-label="action tabs">
						{tab_list.map((obj, index) => {
							return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
						})}
					</TabList>
				</Box>
				<TabPanel value={tab} className="p-0">
					<YouHaveCreated
						eventTypeList={eventTypeList}
						eventList={eventList}
						fetchEventList={fetchEventList}
						slidebarFil={slidebarFil}
						tabName={tabValue}
					/>
				</TabPanel>
			</TabContext>
		</Box>
		// <Box className="discover-event-box">
		//   <Grid container spacing={3}>
		//     {[1, 2].map((event, index) => {
		//       return (
		//         <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
		//           <EventCard isDiscover={true} />
		//         </Grid>
		//       );
		//     })}
		//   </Grid>
		// </Box>
	);
};
