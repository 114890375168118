import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, IconButton, useMediaQuery } from '@material-ui/core';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import * as rdd from 'react-device-detect';
import { MobileDrawer } from '../MobileDrawer';
import UploadIcon from 'src/assets/images/icons/cameraTextEditor.svg';
import { UploadContent } from 'src/pages/application/UserProfile/MobileBlock/UploadContent';
import styles from './customOption.module.scss';

export const CustomOption = ({ setImageList, deletedItemArray, token }) => {
	const [images, setImages] = useState([]);
	const [loader, setLoader] = useState(false);
	const [previewImages, setPreviewImage] = useState([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [loadedPercent, setLoadedPercent] = useState(0);
	const [controller, setController] = useState(new AbortController());

	const { t } = useTranslation();

	const isMobile = useMediaQuery('(max-width: 1020px)');

	useEffect(() => {
		if (previewImages.length < 1) {
			return;
		}

		const newImageUrls = [];

		previewImages.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
		setImageList(images, newImageUrls);
	}, [images, previewImages]);

	const onImageChange = async (e) => {
		setLoader(true);
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			var typeArray = [];

			for (var i = 0; i < e.target.files.length; i++) {
				let type = e.target.files[i].type.includes('image')
					? 'image'
					: e.target.files[i].type.includes('video')
					? 'video'
					: e.target.files[i].type.includes('text')
					? 'doc'
					: e.target.files[i].type.includes('audio')
					? 'audio'
					: 'doc';

				formData.append('location', e.target.files[i]);
				typeArray.push(type);
			}

			formData.append('type', typeArray);

			try {
				const { status, data } = await axiosInstance.post(URLS.multipleMediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
					onUploadProgress: (e) => {
						setLoadedPercent(Math.ceil((e.loaded / e.total) * 100));
					},
					signal: controller.signal,
				});

				if (status === 200) {
					var tempArray = [...images];
					var responseArray = data?.data;

					setImages([...tempArray, ...responseArray]);
					// setPreviewImage([...previewImages, ...e.target.files]);
					setImageList(images, previewImages);
					setLoader(false);
					setPreviewImage([...previewImages, ...e.target.files]);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
				setLoader(false);
			}
		}

		setLoadedPercent(0);
		setController(new AbortController());
	};

	useEffect(() => {
		deletedItemArray && setImages(deletedItemArray);
	}, [deletedItemArray]);

	const openDrawerHandler = () => {
		if (rdd.isAndroid) {
			setOpenDrawer(true);
		}
	};

	const closeDrawerHandler = (e) => {
		e.stopPropagation();
		setOpenDrawer(false);
	};

	return (
		<>
			<Box className="post-image-uploader" onClick={openDrawerHandler}>
				{rdd.isAndroid && isMobile ? (
					<MobileDrawer
						anchor={'bottom'}
						open={openDrawer}
						onClose={closeDrawerHandler}
						className="take-upload-photo"
						title={t('community.upload_media')}
						content={<UploadContent imgHandler={onImageChange} toggleDrawer={setOpenDrawer} actionWithPost={true} />}
					/>
				) : (
					<input
						accept="*"
						type="file"
						id="select-image"
						style={{ display: 'none' }}
						multiple
						onChange={onImageChange}
					/>
				)}
				<label htmlFor="select-image">
					{loader ? (
						<Box display="flex" justifyContent="center">
							<CircularProgress size={18} color="secondary" />
							<div className={styles.percent}> {`${loadedPercent}% `}</div>
						</Box>
					) : (
						<div className={styles.iconButton}>
							<img src={UploadIcon} alt={'camera icon'} />
						</div>
					)}
				</label>
			</Box>
			{loader && (
				<Button
					className={styles.cancelButton}
					onClick={() => controller.abort()}
					variant="outlined"
					color="secondary"
					component="span"
				>
					Cancel
				</Button>
			)}
		</>
	);
};
