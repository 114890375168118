import { useContext, useState } from 'react';
import { Box, Container, Grid, Button, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../fight.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { identifiers } from 'src/helpers/constants/identifier';
import { TextField } from 'src/components/ui';

var techniqueType = [
	{ name: 'decision_knockout', value: 'Decision Knockout' },
	{ name: 'decision_score', value: 'Decision Score' },
	{ name: 'decision_other', value: 'Decision Other' },
	{ name: 'strike', value: 'Strike' },
	{ name: 'grappling', value: 'Grappling' },
	{ name: 'strategy', value: 'Strategy' },
	{ name: 'weapon', value: 'Weapon' },
];

// eslint-disable-next-line react/prop-types
export const FightMethod = ({ fetchAdminFightMethod, itemData, isUpdate, closeDialog, currentPage }) => {
	const [loader, setLoader] = useState(false);
	// eslint-disable-next-line camelcase
	const { t } = useTranslation();
	const addFightMethod = async (data) => {
		const requestParams = {
			name: data?.fightname,
			description: data?.fightdescription,
			status: identifiers?.active,
			type: data?.fighttype,
			...(isUpdate && { id: itemData?.id }),
		};
		const method = isUpdate ? 'put' : 'post';
		const apiEndPoint = isUpdate ? 'updateFightMethod' : 'createFightMethod';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setLoader(false);
				setApiMessage('success', data?.message);
				fetchAdminFightMethod(currentPage);
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setLoader(false);
		}
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Box className="form-content">
						<Formik
							initialValues={{
								fighttype: itemData?.type !== null ? itemData?.type : '',
								fightname: itemData?.name !== null ? itemData?.name : '',
								fightdescription: itemData?.description !== null ? itemData?.description : '',
							}}
							validationSchema={Yup.object().shape({
								fightname: Yup.string().required(t('validation.required_message')),
								// fightdescription: Yup.string().required(
								//   t('validation.required_message')
								// )
							})}
							onSubmit={(requestdata) => addFightMethod(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.fighttype && errors.fighttype)}
												fullWidth
												helperText={touched.fighttype && errors.fighttype}
												variant="filled"
												InputProps={{
													endAdornment: touched.fighttype && errors.fighttype ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_fight.fighttype')}
												placeholder={t('admin_panel.combat_fight.fighttype')}
												className="matrix-textfield"
												size="small"
												name="fighttype"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.fighttype}
												select
											>
												{techniqueType.map((item, index) => {
													return (
														<MenuItem key={index} value={item?.name}>
															{item?.value}
														</MenuItem>
													);
												})}
											</TextField>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.fightname && errors.fightname)}
												fullWidth
												helperText={touched.fightname && errors.fightname}
												variant="filled"
												placeholder={t('admin_panel.combat_fight.fightname')}
												label={t('admin_panel.combat_fight.fightname')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.fightname && errors.fightname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="fightname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.fightname}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.fightdescription && errors.fightdescription)}
												fullWidth
												helperText={touched.fightdescription && errors.fightdescription}
												variant="filled"
												placeholder={t('admin_panel.combat_fight.fightdescription')}
												label={t('admin_panel.combat_fight.fightdescription')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.fightdescription && errors.fightdescription ? <ErrorIcon /> : null,
												}}
												size="small"
												name="fightdescription"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.fightdescription}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{isUpdate ? t('button_text.update') : t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};
