import { FIGHT_METHOD, FIGHT_WEIGHT } from '../constants';

export const adminFightMethod = (data) => {
	return {
		type: FIGHT_METHOD,
		data: data,
	};
};
export const adminFightWeight = (data) => {
	return {
		type: FIGHT_WEIGHT,
		data: data,
	};
};
