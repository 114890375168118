import { useEffect, useState } from 'react';
import {
	Box,
	Avatar,
	List,
	ListItem,
	ListItemText,
	Typography,
	Button,
	InputAdornment,
	TextField as TextFieldMui,
	withStyles,
} from '@material-ui/core';
// import './invite-add-members-admin.scss';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { CustomPaginations } from 'src/components/ui';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useTranslation } from 'react-i18next';
// import { setApiMessage } from 'src/helpers/commonFunctions';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextFieldMui);

export const OrganisationListModal = ({ selectedOrgData }) => {
	const { t } = useTranslation();

	const [selectedItem, setSelectedItem] = useState({});
	const [searchString, setSearchString] = useState(''); //setSearchString
	const [organisationList, setOrganisationList] = useState([]);
	const [limit] = useState(8); //setList
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords

	const token = fetchFromStorage(identifiers?.token);

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	// GET ALL ORGANISATION LIST
	const fetchAllOrganisationData = async (page, search) => {
		try {
			let { status, data } = await axiosInstance.get(
				URLS.getMyOrganisations,
				{
					params: {
						page: page,
						limit: limit,
						name: search,
						user_role: 1,
					},
				},
				headers,
			);

			if (status === 200) {
				setOrganisationList(data?.orgData);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1, searchString);
				fetchAllOrganisationData(currentPage - 1, searchString);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				setCurrentPage(currentPage + 1, searchString);
				fetchAllOrganisationData(currentPage + 1, searchString);
			}
		}
	};
	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchAllOrganisationData(Number(page), searchString);
		}
	};
	const handleChange = (e) => {
		fetchAllOrganisationData(currentPage, e.target.value);
		setSearchString(e.target.value);
	};

	useEffect(() => {
		fetchAllOrganisationData(currentPage, searchString);
	}, []);

	return (
		<>
			<Box className="member-listing-main">
				<Box className="search-box">
					<SearchTextField
						variant="outlined"
						fullWidth
						size="small"
						placeholder="Search"
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Box className="member-inner">
						<List>
							{/* INVITE MEMBER */}
							{organisationList && organisationList?.length ? (
								<>
									{organisationList?.map((item, index) => {
										return (
											<ListItem key={index}>
												<Avatar src={item?.item_profile_id} />
												<ListItemText>
													<Typography variant="span" className="member-name">
														{item?.name}
													</Typography>
												</ListItemText>
												<Button
													disableRipple
													disableFocusRipple
													disableTouchRipple
													edge="start"
													// className={'member-btn'}
													className={item.id === selectedItem?.id ? 'member-btn gray-background' : 'member-btn '}
													onClick={() => {
														setSelectedItem(item);
														selectedOrgData(item);
													}}
												>
													{item.id === selectedItem?.id ? 'Selected' : 'select'}
												</Button>
											</ListItem>
										);
									})}
								</>
							) : (
								<Typography className="no-data-txt">{t('community.no_member_found')}</Typography>
							)}
						</List>
					</Box>
				</Box>
			</Box>
			<CustomPaginations
				paginationArray={organisationList}
				currentPage={currentPage}
				list={limit}
				handlePaginationButtonClick={handlePaginationButtonClick}
				handlePaginationClick={handlePaginationClick}
				totalRecord={totalRecord}
			/>
		</>
	);
};
