import { memo, useState, useEffect } from 'react';
import { Box, Grid, Chip, Button } from '@material-ui/core';
import '../skills.scss';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CancelIcon from '@material-ui/icons/Cancel';
import { TextField } from 'src/components/ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export const AddEndorsement = ({
	// selected,
	// selectedFight,
	// selectedSkills,
	handleClick,
	disciplineData,
	fightMethod,
	skillsData,
	showButton,
	fetchAllData,
	userType,
	// handleToggle,
	// handleToggleFight,
	// handleToggleSkills
	// handleClose
	isModalLoading,
}) => {
	const { t } = useTranslation();
	// discipline
	const [selectedDiscipline, setSelectedDiscipline] = useState([]);
	const [createdDiscipline, setCreatedDiscipline] = useState([]);
	// methods
	const [selectedFightMethod, setSelectedFightMethod] = useState([]);
	const [createdFightMethod, setCreatedFightMethod] = useState([]);
	// skills
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [createdSkills, setCreatedSkills] = useState([]);
	// usertype
	const [selectedUserType, setSelectedUserType] = useState([]);
	const [createdUserType, setCreatedUserType] = useState([]);
	const filter = createFilterOptions();

	useEffect(() => {
		!showButton && fetchAllData(selectedDiscipline, selectedFightMethod, selectedSkills);
	}, [selectedDiscipline, selectedFightMethod, selectedSkills]);

	return (
		<Grid item xs={12} md={12}>
			<Box className="restriction-box endorsement-main">
				{/* Discipline dropdown */}
				<Box className="discipline-dropdown-cover mb-15">
					{/* SEARCH TOP DISCIPLINES AND SELECT FROM THE LIST */}
					<Autocomplete
						multiple
						id="fixed-tags-demo"
						value={selectedDiscipline}
						onChange={(event, newValue) => {
							const newIds = selectedDiscipline.map((r) => r.id);
							const newValueIds = newValue.map((r) => r.id);
							const check = newValue.filter((s) => {
								return newIds.indexOf(s?.id) === -1;
							});

							const values = [...createdDiscipline];

							// IF NEW DISCIPLINE SELECTED
							if (newValue.length > selectedDiscipline.length) {
								// let haveCheck = ranksuggestion.filter(
								//   x => x?.id === check[0]?.id
								// );
								// if (haveCheck.length === 0) {
								//   fetchRankSuggestions(check[0]?.id);
								// }
								values.push(check[0]?.id);
								setCreatedDiscipline(values);
							}

							// IF REMOVE SELECTED DISCIPLINE
							if (newValue.length < selectedDiscipline.length) {
								const removedId = selectedDiscipline.filter((x) => {
									return newValueIds.indexOf(x?.id) === -1;
								})[0]?.id;

								const selectVall = values.filter((x) => x?.id !== removedId);

								setCreatedDiscipline(selectVall);
							}

							setSelectedDiscipline(newValue);
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							const { inputValue } = params;

							// Suggest the creation of a new value
							let isExisting = options.some((option) => inputValue === option);

							if (inputValue !== '' && !isExisting) {
								filtered.push({
									inputValue,
									name: `Add "${inputValue}"`,
								});
							}

							return filtered;
						}}
						getOptionLabel={(option) => {
							// Value selected with enter, right from the input
							if (typeof option === 'string') {
								return option;
							}

							// Add "xxx" option created dynamically
							if (option.name) {
								return option.name;
							}

							// Regular option
							return String(option.name);
						}}
						options={_.orderBy(disciplineData, ['name'], ['asc'])}
						// getOptionLabel={option => option?.name}
						renderTags={(tagValue, getTagProps) =>
							tagValue.map((option, index) => (
								<Chip
									size="small"
									deleteIcon={<CancelIcon />}
									className="discipline-chip"
									label={option?.name}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								variant="filled"
								// eslint-disable-next-line react/prop-types
								label={t('myprofile.disciplines')}
								// eslint-disable-next-line react/prop-types
								placeholder={t('myprofile.disciplines')}
								className="matrix-textfield"
								size="small"
							/>
						)}
					/>
				</Box>
				{/* Fight dropdown */}
				<Box className="discipline-dropdown-cover mb-15">
					{/* SEARCH TOP DISCIPLINES AND SELECT FROM THE LIST */}
					<Autocomplete
						multiple
						id="fixed-tags-demo"
						value={selectedFightMethod}
						onChange={(event, newValue) => {
							let newIds = selectedFightMethod.map((r) => r.id);
							let newValueIds = newValue.map((r) => r.id);
							let check = newValue.filter((s) => {
								return newIds.indexOf(s?.id) === -1;
							});

							let values = [...createdFightMethod];

							// IF NEW DISCIPLINE SELECTED
							if (newValue.length > selectedFightMethod.length) {
								// let haveCheck = ranksuggestion.filter(
								//   x => x?.id === check[0]?.id
								// );
								// if (haveCheck.length === 0) {
								//   fetchRankSuggestions(check[0]?.id);
								// }
								values.push(check[0]?.id);
								setCreatedFightMethod(values);
							}

							// IF REMOVE SELECTED DISCIPLINE
							if (newValue.length < selectedFightMethod.length) {
								let removedId = selectedFightMethod.filter((x) => {
									return newValueIds.indexOf(x?.id) === -1;
								})[0]?.id;

								let selectVall = values.filter((x) => x?.id !== removedId);

								setCreatedFightMethod(selectVall);
							}

							setSelectedFightMethod(newValue);
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							const { inputValue } = params;

							// Suggest the creation of a new value
							let isExisting = options.some((option) => inputValue === option.name);

							if (inputValue !== '' && !isExisting) {
								filtered.push({
									inputValue,
									name: `Add "${inputValue}"`,
								});
							}

							return filtered;
						}}
						getOptionLabel={(option) => {
							// Value selected with enter, right from the input
							if (typeof option === 'string') {
								return option;
							}

							// Add "xxx" option created dynamically
							if (option.name) {
								return option.name;
							}

							// Regular option
							return String(option.name);
						}}
						options={_.orderBy(fightMethod, ['name'], ['asc'])}
						// getOptionLabel={option => option?.name}
						renderTags={(tagValue, getTagProps) =>
							tagValue.map((option, index) => (
								<Chip
									size="small"
									deleteIcon={<CancelIcon />}
									className="discipline-chip"
									label={option?.name}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								variant="filled"
								label={t('myprofile.fightmethod')}
								placeholder={t('myprofile.fightmethod')}
								className="matrix-textfield"
								size="small"
							/>
						)}
					/>
				</Box>
				{/* Skills */}
				<Box className="discipline-dropdown-cover mb-15">
					{/* SEARCH TOP DISCIPLINES AND SELECT FROM THE LIST */}
					<Autocomplete
						multiple
						id="fixed-tags-demo"
						value={selectedSkills}
						onChange={(event, newValue) => {
							let newIds = selectedSkills.map((r) => r.id);
							let newValueIds = newValue.map((r) => r.id);
							let check = newValue.filter((s) => {
								return newIds.indexOf(s?.id) === -1;
							});

							let values = [...createdSkills];

							// IF NEW DISCIPLINE SELECTED
							if (newValue.length > selectedSkills.length) {
								// let haveCheck = ranksuggestion.filter(
								//   x => x?.id === check[0]?.id
								// );
								// if (haveCheck.length === 0) {
								//   fetchRankSuggestions(check[0]?.id);
								// }
								values.push(check[0]?.id);
								setCreatedSkills(values);
							}

							// IF REMOVE SELECTED DISCIPLINE
							if (newValue.length < selectedSkills.length) {
								let removedId = selectedSkills.filter((x) => {
									return newValueIds.indexOf(x?.id) === -1;
								})[0]?.id;

								let selectVall = values.filter((x) => x?.id !== removedId);

								setCreatedSkills(selectVall);
							}

							setSelectedSkills(newValue);
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							const { inputValue } = params;

							// Suggest the creation of a new value
							let isExisting = options.some((option) => inputValue === option.name);

							if (inputValue !== '' && !isExisting) {
								filtered.push({
									inputValue,
									name: `Add "${inputValue}"`,
								});
							}

							return filtered;
						}}
						getOptionLabel={(option) => {
							// Value selected with enter, right from the input
							if (typeof option === 'string') {
								return option;
							}

							// Add "xxx" option created dynamically
							if (option.name) {
								return option.name;
							}

							// Regular option
							return String(option.name);
						}}
						options={_.orderBy(skillsData, ['name'], ['asc'])}
						// getOptionLabel={option => option?.name}
						renderTags={(tagValue, getTagProps) =>
							tagValue.map((option, index) => (
								<Chip
									size="small"
									deleteIcon={<CancelIcon />}
									className="discipline-chip"
									label={option?.name}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								variant="filled"
								label={t('myprofile.skills')}
								placeholder={t('myprofile.skills')}
								className="matrix-textfield"
								size="small"
							/>
						)}
					/>
				</Box>
				{/* User profile dropdown */}
				{showButton && (
					<Box className="discipline-dropdown-cover mb-15">
						{/* SEARCH TOP DISCIPLINES AND SELECT FROM THE LIST */}
						<Autocomplete
							multiple
							id="fixed-tags-demo"
							value={selectedUserType}
							onChange={(event, newValue) => {
								let newIds = selectedUserType.map((r) => r.id);
								let newValueIds = newValue.map((r) => r.id);
								let check = newValue.filter((s) => {
									return newIds.indexOf(s?.id) === -1;
								});

								let values = [...createdUserType];

								// IF NEW DISCIPLINE SELECTED
								if (newValue.length > selectedUserType.length) {
									// let haveCheck = ranksuggestion.filter(
									//   x => x?.id === check[0]?.id
									// );
									// if (haveCheck.length === 0) {
									//   fetchRankSuggestions(check[0]?.id);
									// }
									values.push(check[0]?.id);
									setCreatedUserType(values);
								}

								// IF REMOVE SELECTED DISCIPLINE
								if (newValue.length < selectedUserType.length) {
									let removedId = selectedUserType.filter((x) => {
										return newValueIds.indexOf(x?.id) === -1;
									})[0]?.id;

									let selectVall = values.filter((x) => x?.id !== removedId);

									setCreatedUserType(selectVall);
								}

								setSelectedUserType(newValue);
							}}
							options={_.orderBy(userType, ['name'], ['asc'])}
							getOptionLabel={(option) => option?.name}
							renderTags={(tagValue, getTagProps) =>
								tagValue.map((option, index) => (
									<Chip
										size="small"
										deleteIcon={<CancelIcon />}
										className="discipline-chip"
										label={option?.name}
										{...getTagProps({ index })}
									/>
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									variant="filled"
									label={t('myprofile.specialities')}
									placeholder={t('myprofile.specialities')}
									className="matrix-textfield"
									size="small"
								/>
							)}
						/>
					</Box>
				)}
				{showButton && (
					<Grid item xs={12} md={12} className="dialog-submit-button dialog-sticky-btn">
						<Button
							variant="contained"
							fullWidth
							type="submit"
							className="mt-20 dialog-submit"
							disabled={isModalLoading}
							onClick={() => handleClick(selectedDiscipline, selectedFightMethod, selectedSkills, selectedUserType)}
						>
							{t('save')}
						</Button>
					</Grid>
				)}
				{/* {disciplineData.length === 0 &&
          fightMethod.length === 0 &&
          skillsData?.length === 0 && (
            <Typography className="skills-heading">
              {t('myprofile.noendorsementskill')}
            </Typography>
          )}
        <Box className="restriction-chips mb-30">
          {disciplineData?.length > 0 && (
            <Typography className="skills-heading">
              {t('myprofile.discipline')}
            </Typography>
          )}
          <Grid container>
            {disciplineData &&
              disciplineData?.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Chip
                      size="small"
                      className={
                        selected.indexOf(data?.id) !== -1
                          ? 'rest-chip checked-chip'
                          : 'rest-chip'
                      }
                      icon={
                        selected.indexOf(data?.id) !== -1 ? (
                          <FilterCheckIcon />
                        ) : null
                      }
                      label={data?.name}
                      onClick={handleToggle(data?.id)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Box className="restriction-chips mb-30">
          {fightMethod?.length > 0 && (
            <Typography className="skills-heading">
              {t('myprofile.fightmethod')}
            </Typography>
          )}
          <Grid container>
            {fightMethod &&
              fightMethod?.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Chip
                      size="small"
                      className={
                        selectedFight.indexOf(data?.id) !== -1
                          ? 'rest-chip checked-chip'
                          : 'rest-chip'
                      }
                      icon={
                        selectedFight.indexOf(data?.id) !== -1 ? (
                          <FilterCheckIcon />
                        ) : null
                      }
                      label={data?.name}
                      onClick={handleToggleFight(data?.id)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Box className="restriction-chips mb-30">
          {skillsData?.length > 0 && (
            <Typography className="skills-heading">
              {t('myprofile.skills')}
            </Typography>
          )}
          <Grid container>
            {skillsData &&
              skillsData?.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Chip
                      size="small"
                      className={
                        selectedSkills.indexOf(data?.id) !== -1
                          ? 'rest-chip checked-chip'
                          : 'rest-chip'
                      }
                      icon={
                        selectedSkills.indexOf(data?.id) !== -1 ? (
                          <FilterCheckIcon />
                        ) : null
                      }
                      label={data?.name}
                      onClick={handleToggleSkills(data?.id)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            className="mt-20"
            onClick={() => handleClick()}
          >
            {t('save')}
          </Button>
        </Grid> */}
			</Box>
		</Grid>
	);
};
