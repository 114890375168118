import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import React from 'react';

export const renderSwitchForDocumentIcon = (param) => {
	const splitdata = param?.split('.');
	const docurl = splitdata?.[splitdata?.length - 1];

	switch (docurl) {
		case 'pdf':
			return (
				<>
					<PictureAsPdfIcon fontSize="inherit" />
					<Typography className="doc-name">{docurl}</Typography>
				</>
			);
		case 'txt':
		default:
			return (
				<>
					<InsertDriveFileIcon fontSize="inherit" />
					<Typography className="doc-name">{docurl}</Typography>
				</>
			);
	}
};
