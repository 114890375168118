import { Box, Typography } from '@material-ui/core';
import { StickiedCommunityPost } from 'src/components/ui';
import { ReactComponent as PinnedIcon } from 'src/assets/images/icons/community/stickied-icon.svg';
import './stickied-post.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import { ColorLensOutlined } from '@material-ui/icons';

export const StickiedPost = ({
	reactionData,
	addVote,
	addLike,
	user,
	updateCommunityPost,
	updatePostData,
	cancelBtnClick,
	handleDeletePost,
	fetchPostDetails,
}) => {
	const { t } = useTranslation();

	const NewsfeedPostList = useSelector((state) => state.Combat.NewsfeedPostList);

	const Slength = NewsfeedPostList && NewsfeedPostList?.stickied_post_list?.length;

	return (
		Slength > 0 && (
			<Box className="stickied-post-container">
				<Box className="stickied-box">
					{NewsfeedPostList && NewsfeedPostList?.stickied_post_list?.length > 0 && (
						<>
							{NewsfeedPostList?.stickied_post_list?.length > 0 ? (
								<Box className="box-header">
									<PinnedIcon />
									<Typography className="box-title">{t('community.combat_matrix_stickied_posts')}</Typography>
								</Box>
							) : (
								''
							)}
						</>
					)}

					{NewsfeedPostList && NewsfeedPostList?.stickied_post_list?.length > 0 && (
						<Box className={'post-conatainer post-scroll'}>
							<>
								{NewsfeedPostList?.stickied_post_list.map((item, index) => {
									var postImage = item?.PostLists.find((d) => d?.Item?.type === 'image');
									const postContent = item?.PostLists.find((d) => d?.Item?.type === 'text');

									return (
										<StickiedCommunityPost
											fetchPostDetails={fetchPostDetails}
											key={index}
											postName={item?.name}
											topVal={item?.total_vote_up}
											downVal={item?.total_vote_down}
											date={
												item?.timestamp_created !== null &&
												moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
											}
											postedBy={item?.user_full_name}
											// isView={isView}
											userURI={item?.user_meta?.data}
											PostId={item?.user_id}
											user={user}
											postUserId={item?.user_id}
											// contentText={
											//   '[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity'
											// }
											contentText={postContent?.Item?.data}
											contentImg={postImage?.Item?.data}
											// contentImg="/static/com-event-1.png"
											comment={item?.total_comments}
											updateCommunityPost={updateCommunityPost}
											updatePostData={updatePostData}
											handleDeletePost={handleDeletePost}
											cancelBtnClick={cancelBtnClick}
											referrals={item?.total_referrals}
											shares={item?.total_shares}
											reactions={item?.total_likes}
											editAccess={item?.edit_access}
											data={item}
											addVote={addVote}
											addLike={addLike}
											isJoin={true}
											reactionData={reactionData}
											likedStatus={item?.like_status}
										/>
									);
								})}
							</>
						</Box>
					)}

					{/*
         {[{}, {}, {}, {}].map((data, index) => {
        //   return (
        //     <StickiedCommunityPost
        //       key={index}
        //       postName="[Post Name]"
        //       topVal={45}
        //       downVal={10}
        //       date={'[Date] [Time]'}
        //       postedBy="[Posted By]"
        //       isView={isView}
        //        contentText={
        //          '[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity'
        //        }
        //       contentImg="/static/com-event-1.png"
        //       comment={58}
        //       referrals={12}
        //       shares={8}
        //       reactions={3}
        //     />
        //   );
        // })} */}
				</Box>
			</Box>
		)
	);
};
