import { connect } from 'react-redux';
import { superEventCategoryData } from 'src/redux-state/actions/createEvent';

import { Category as CategoryComponent } from './Category';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		superEventCategoryData: (data) => dispatch(superEventCategoryData(data)),
	};
};

export const Category = connect(mapStateToProps, mapDispatchToProps)(CategoryComponent);
