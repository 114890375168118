import { Button, makeStyles } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './MobileMenuItem.module.scss';

const useStyles = makeStyles((theme) => ({
	root: { backgroundColor: '#151515' },
	title: {
		marginRight: 'auto',
	},
	button: {
		color: '#fff',
		fontSize: '11px',
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent', color: '#438AC1' },
		'&:last-child': {
			marginRight: 0,
		},
	},
	avatar: { width: 36, height: 36, cursor: 'pointer' },
	active: {
		// color: '#438AC1',
		fontSize: '11px',
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent' },
		'&:last-child': {
			marginRight: 0,
		},
	},
}));

export const MobileMenuItem = ({ href, icon, title }) => {
	const classes = useStyles();
	const location = useLocation();

	return (
		<NavLink to={href}>
			<Button
				disableRipple
				disableElevation
				disableTouchRipple
				disableFocusRipple
				className={
					location.pathname.includes(`${href}`)
						? // if the link is active, the icon's color should change
						  `${classes.active} ${styles['app-menu-btn']} ${styles['icon-active']}`
						: `${classes.button} ${styles['app-menu-btn']}`
				}
			>
				{icon}
				<span>{title}</span>
			</Button>
		</NavLink>
	);
};

MobileMenuItem.propTypes = {
	href: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.element,
};
