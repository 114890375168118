import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { Box, Button, Grid, MenuItem } from '@material-ui/core';
import { TextField } from 'src/components/ui';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

export const EditProfile = ({ getMyProfile, closeDialog, data, user, token }) => {
	const { t } = useTranslation();
	let nicknameVal = user?.UserMetaData.filter((data) => data.type === 'other')[0]?.data;
	let uNameCheck = user?.UserMetaData.filter((data) => data.type === 'other').length > 0 ? true : false;

	const [searchAddress, SetSearchAddress] = useState(data?.address?.address || '');
	const [city, setCity] = useState(data?.address?.city || '');
	const [state, setState] = useState(data?.address?.state || '');
	const [country, setCountry] = useState(data?.address?.country || '');
	const [lattitude, setLattitude] = useState(data?.address?.lat || '');
	const [longitude, setLongitude] = useState(data?.address?.long || '');
	const formikRef = useRef();

	const AddressHandler = (address) => {
		SetSearchAddress(address);
	};

	const HandleSelectAddress = (address) => {
		geocodeByAddress(address).then((results) => {
			setCity('');
			setState('');
			setLattitude('');
			setLongitude('');

			let address = results[0].address_components;
			let geoCode = results[0].geometry.location;
			let name = results[0].formatted_address;

			SetSearchAddress(name);
			setLattitude(geoCode.lat());
			setLongitude(geoCode.lng());

			address.map((val) => {
				return val.types.map((com) => {
					switch (com) {
						case 'country':
							setCountry(val.long_name);

							return val.long_name;
						case 'administrative_area_level_1':
							setState(val.long_name);

							return val.long_name;
						case 'administrative_area_level_2':
							setCity(val.long_name);

							return val.long_name;
						default:
							return val;
					}
				});
			});
		});
	};

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.setFieldValue('address', searchAddress);
			formikRef.current.setFieldValue('city', city);
			formikRef.current.setFieldValue('state', state);
			formikRef.current.setFieldValue('country', country);
		}
	}, [searchAddress, city, state, country]);

	return (
		<Box>
			<Formik
				innerRef={formikRef}
				initialValues={{
					firstName: data?.first_name || '',
					surName: data?.last_name || '',
					nickname: uNameCheck ? nicknameVal : '',
					dob: moment(data?.dob).format('YYYY-MM-DD'),
					age: new Date().getFullYear() - new Date(data?.dob).getFullYear() || '',
					phone: data?.phone || '',
					address: data?.address?.address || '',
					country: data?.address?.country || '',
				}}
				validationSchema={Yup.object().shape({
					firstName: Yup.string().required(t('validation.required_message')),
					surName: Yup.string().required(t('validation.required_message')),
					nickname: Yup.string().required(t('validation.required_message')),
					dob: Yup.string()
						.required(t('validation.required_message'))
						.test('dob', t('personal_details.wrong_dob'), function (dob) {
							return dob < moment(new Date(new Date()).setDate(new Date(new Date()).getDate())).format();
						}),
					age: Yup.string()
						.required(t('validation.required_message'))
						.test('dob', t('personal_details.wrong_age'), function (age) {
							return Number(age) === new Date().getFullYear() - new Date(this.parent.dob).getFullYear();
						}),
					phone: Yup.string().required(t('validation.required_message')),
					address: Yup.string().required(t('validation.required_message')),
					country: Yup.string().required(t('validation.required_message')),
				})}
				onSubmit={async (data) => {
					let socials = user?.UserMetaData?.filter((data) => data?.type === 'other');
					let userNameVall = { type: 'other', data: data?.nickname };

					socials.push(userNameVall);
					let requestData = {
						personal_details: {
							first_name: data.firstName,
							last_name: data.surName,
							dob: data.dob,
							country: data.country,
						},
						user_meta: socials,
						phone: data.phone,
						address: {
							address: data?.address,
							lat: lattitude,
							long: longitude,
							city: city,
							state: state,
							country: data?.country,
						},
					};

					try {
						const { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							setApiMessage('success', t('myprofile.profile_updated_success'));
							getMyProfile();
							closeDialog();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.firstName && errors.firstName)}
									fullWidth
									helperText={touched.firstName && errors.firstName}
									variant="filled"
									InputProps={{
										endAdornment: touched.firstName && errors.firstName ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.firstName')}
									placeholder={t('personal_details.firstName')}
									className="matrix-textfield"
									size="small"
									name="firstName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.firstName}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.surName && errors.surName)}
									fullWidth
									helperText={touched.surName && errors.surName}
									variant="filled"
									InputProps={{
										endAdornment: touched.surName && errors.surName ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.surName')}
									placeholder={t('personal_details.surName')}
									className="matrix-textfield"
									size="small"
									name="surName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.surName}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.nickname && errors.nickname)}
									fullWidth
									helperText={touched.nickname && errors.nickname}
									variant="filled"
									InputProps={{
										endAdornment: touched.nickname && errors.nickname ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.nickname')}
									placeholder={t('personal_details.nickname')}
									className="matrix-textfield"
									size="small"
									name="nickname"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.nickname}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={10} lg={10} className="date-field">
									<TextField
										error={Boolean(touched.dob && errors.dob)}
										fullWidth
										helperText={touched.dob && errors.dob}
										variant="filled"
										InputProps={{
											endAdornment: touched.dob && errors.dob ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.dob')}
										placeholder={t('personal_details.dob')}
										className="matrix-textfield"
										size="small"
										name="dob"
										type="date"
										onBlur={handleBlur}
										onChange={(e) => {
											let vall = new Date().getFullYear() - new Date(e.target.value).getFullYear();

											handleChange(e);
											setFieldValue('age', vall > 0 && vall < 150 ? vall : '');
										}}
										InputLabelProps={{
											shrink: true,
										}}
										value={values.dob}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={2} className="age-field">
									<TextField
										error={Boolean(touched.age && errors.age)}
										fullWidth
										helperText={touched.age && errors.age}
										variant="filled"
										InputProps={{
											endAdornment: touched.age && errors.age ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.age')}
										placeholder={t('personal_details.age')}
										className="matrix-textfield"
										size="small"
										name="age"
										onBlur={handleBlur}
										onChange={(e) => {
											const re = /^[0-9\b]+$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										inputProps={{ maxLength: 4 }}
										value={values.age}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.phone && errors.phone)}
									fullWidth
									helperText={touched.phone && errors.phone}
									variant="filled"
									InputProps={{
										endAdornment: touched.phone && errors.phone ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.phone')}
									placeholder={t('personal_details.phone')}
									className="matrix-textfield"
									size="small"
									name="phone"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.phone}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<PlacesAutocomplete value={searchAddress} onChange={AddressHandler} onSelect={HandleSelectAddress}>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<TextField
												error={Boolean(touched.address && errors.address)}
												fullWidth
												helperText={touched.address && errors.address}
												variant="filled"
												InputProps={{
													endAdornment: touched.address && errors.address ? <ErrorIcon /> : null,
												}}
												label={t('personal_details.address')}
												placeholder={t('personal_details.address')}
												className="matrix-textfield"
												size="small"
												name="address"
												{...getInputProps()}
												onBlur={handleBlur}
												InputLabelProps={{
													shrink: true,
												}}
											/>

											<div className="autocomplete-dropdown-container">
												{suggestions.map((suggestion, index) => {
													const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
													// inline style for demonstration purpose
													const style = suggestion.active
														? {
																background: '#fff',
																color: '#000',
																cursor: 'pointer',
														  }
														: {
																backgroundColor: 'transparent',
																cursor: 'pointer',
														  };

													return (
														<div
															key={index}
															{...getSuggestionItemProps(suggestion, {
																className,
																style,
															})}
														>
															<MenuItem disableGutters dense className="address-menuitem">
																{suggestion.description}
															</MenuItem>
														</div>
													);
												})}
											</div>
										</div>
									)}
								</PlacesAutocomplete>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<TextField
									error={Boolean(touched.country && errors.country)}
									fullWidth
									helperText={touched.country && errors.country}
									variant="filled"
									InputProps={{
										endAdornment: touched.country && errors.country ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.country')}
									placeholder={t('personal_details.country')}
									className="matrix-textfield"
									size="small"
									name="country"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.country}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<Box className="dialog-submit-button">
									<Button variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
