import { Box, Typography, Grid } from '@material-ui/core';
import { ReactComponent as BadgeBlueIcon } from 'src/assets/images/icons/badge_b_icon.svg';
import { ReactComponent as BadgeVerificationIcon } from 'src/assets/images/icons/verification_icon.svg';
import '../generalbadge.scss';
import { useTranslation } from 'react-i18next';

const acctype = [
	{
		name: 'athlete',
		content: "Select 'Athlete' as Primary account",
	},
	{
		name: 'coach',
		content: "Select 'Coach' as Primary account",
	},
	{
		name: 'promotor',
		content: "Select 'Promotor' as Primary account",
	},
	{
		name: 'sponsor',
		content: "Select 'Sponsor' as Primary account",
	},
	{
		name: 'instructor',
		content: "Select 'Instructor' as Primary account",
	},
];

export const AccountType = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="badge_block_wraper">
				<Typography className="general_subtitle">{t('badge.account_type')}</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6} lg={4}>
						<Box className="badge_block badge_signup">
							<Box className="icon_block">
								<BadgeBlueIcon />
							</Box>
							<Box className="badge_content">
								<Typography className="block_title">fan</Typography>
								<Typography className="nbsb_title">Select 'Fan' as Primary account</Typography>
								<Typography className="nbsb_title achieved_date">Achieved on DD MMM YYYY</Typography>
							</Box>
						</Box>
					</Grid>
					{acctype.map((item) => {
						return (
							<>
								<Grid item xs={12} md={6} lg={4}>
									<Box className="badge_block">
										<Box className="icon_block">
											<BadgeVerificationIcon />
										</Box>
										<Box className="badge_content">
											<Typography className="block_title">{item.name}</Typography>
											<Typography className="nbsb_title">{item.content}</Typography>
										</Box>
									</Box>
								</Grid>
							</>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};
