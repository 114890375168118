import {
	eventFightCard,
	eventFightData,
	eventStatus,
	superEventFightDetails,
} from 'src/redux-state/actions/createEvent';
import { connect } from 'react-redux';
import { CombatantDetailsTab as CombatantDetailsTabComponent } from './CombatantDetailsTab';

const mapStateToProps = (state) => {
	return {
		data: state.Events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		superEventFightDetails: (data) => dispatch(superEventFightDetails(data)),
		eventFightData: (data) => dispatch(eventFightData(data)),
		eventFightCard: (data) => dispatch(eventFightCard(data)),
		eventStatus: (data) => dispatch(eventStatus(data)),
	};
};

export const CombatantDetailsTab = connect(mapStateToProps, mapDispatchToProps)(CombatantDetailsTabComponent);
