import {
	USER_DATA,
	SETUP_ORG,
	AUTH_USER,
	LOGOUT_USER,
	PROFILE_TYPE,
	FIGHT_METHOD,
	FIGHT_WEIGHT,
	GET_COMMUNITY,
	FOLLOWERS_DATA,
	PROFILE_CONFIRM,
	SETUP_ORG_CHECK,
	PROFILE_DETAILS,
	USER_VERIFY_COUNT,
	USER_STATICS_DATA,
	ADDITIONAL_DETAILS,
	USER_NEXT_PAGE_DATA,
	GET_ALL_MESSAGE_LIST,
	GET_COMMUNITY_DETAILS,
	GET_COMMUNITY_DIRECTORY,
	FOLLOWERS_NEXT_PAGE_DATA,
	GET_NEWSFEED_POST_DETAILS,
	GET_COMMUNITY_POSTS_DETAILS,
	GET_ACCOUNT_SETTING_DETAILS,
	UPDATE_COMMUNITY_POST_DETAILS,
	REMOVE_OR_ADD_STICKY_POST_IN_COMMUNITY,
} from '../constants';

const initialState = Object.freeze({
	authData: undefined,
	userPage: undefined,
	allUsersList: undefined,
	setup_org: undefined,
	user_statics: undefined,
	profile_type: undefined,
	followersPage: undefined,
	followersList: undefined,
	communityList: undefined,
	communityDetails: undefined,
	NewsfeedPostList: undefined,
	communityPostList: undefined,
	AccoutSettingData: undefined,
	communityDirectoryList: undefined,
	fightWeightList: undefined,
	fightMethodList: undefined,
	profile_details: undefined,
	setup_org_check: undefined,
	user_profile_count: undefined,
	currentGroupChatList: undefined,
});

export default function Combat(state = initialState, action) {
	switch (action.type) {
		case AUTH_USER:
			return { authData: action.data };
		case LOGOUT_USER:
			return { authData: { token: false } };
		case PROFILE_DETAILS:
			return { ...state, profile_details: action.data };
		case PROFILE_TYPE:
			return { ...state, profile_type: action.data };
		case SETUP_ORG_CHECK:
			return { ...state, setup_org_check: action.data };
		case SETUP_ORG:
			return { ...state, setup_org: action.data };
		case ADDITIONAL_DETAILS:
			return { ...state, additional_details: action.data };
		case PROFILE_CONFIRM:
			return {
				...state,
				authData: { profile_status: action.data },
			};
		case USER_VERIFY_COUNT:
			return {
				...state,
				user_profile_count: action.data,
			};
		case USER_STATICS_DATA:
			return {
				...state,
				user_statics: action.data,
			};
		case FIGHT_METHOD:
			return {
				...state,
				fightMethodList: action.data,
			};
		case FIGHT_WEIGHT:
			return {
				...state,
				fightWeightList: action.data,
			};
		case USER_DATA: {
			const page = state.userPage === undefined ? 1 : state.userPage;

			return {
				...state,
				userPage: page + 1,
				allUsersList: action.data,
			};
		}
		case USER_NEXT_PAGE_DATA:
			return {
				// ...state,
				userPage: state.userPage + 1,
				allUsersList: [...state.allUsersList, ...action.data],
			};

		case FOLLOWERS_DATA: {
			const pageNumber = state.followersPage === undefined ? 1 : state.followersPage;

			return {
				...state,
				followersPage: pageNumber + 1,
				followersList: action.data,
			};
		}
		case FOLLOWERS_NEXT_PAGE_DATA:
			return {
				...state,
				followersPage: state.followersPage + 1,
				followersList: [...state.followersList, ...action.data],
			};
		case GET_COMMUNITY:
			return {
				...state,
				communityList: action.data,
			};
		case GET_COMMUNITY_DIRECTORY:
			return {
				...state,
				communityDirectoryList: action.data,
			};
		case GET_COMMUNITY_DETAILS:
			return {
				...state,
				communityDetails: action.data,
			};
		case GET_COMMUNITY_POSTS_DETAILS:
			return {
				...state,
				communityPostList: action.data,
			};
		case REMOVE_OR_ADD_STICKY_POST_IN_COMMUNITY: {
			return {
				...state,
				communityPostList: {
					...state.communityPostList,
					stickied_post_list:
						action.data.sticky === 'no'
							? state.communityPostList.stickied_post_list.filter((post) => {
									return post.id !== action.data.post_id;
							  })
							: [
									...state.communityPostList.stickied_post_list,
									{
										...state.communityPostList.post_list.find((post) => {
											return post.id === action.data.post_id;
										}),
										sticky: 'yes',
									},
							  ],
					post_list: state.communityPostList.post_list.map((post) => {
						if (post.id === action.data.post_id) {
							return { ...post, ...action.data };
						} else {
							return post;
						}
					}),
				},
			};
		}
		case UPDATE_COMMUNITY_POST_DETAILS:
			return {
				...state,
				communityPostList: {
					...state.communityPostList,
					post_list: state.communityPostList.post_list.map((post) => {
						if (post.id === action.data.id) {
							return action.data;
						} else {
							return post;
						}
					}),
					stickied_post_list: state.communityPostList.stickied_post_list.map((post) => {
						if (post.id === action.data.id) {
							return action.data;
						} else {
							return post;
						}
					}),
				},
			};
		case GET_NEWSFEED_POST_DETAILS:
			return {
				...state,
				NewsfeedPostList: action.data,
			};
		case GET_ACCOUNT_SETTING_DETAILS:
			return {
				...state,
				AccoutSettingData: action.data,
			};
		case GET_ALL_MESSAGE_LIST:
			return {
				...state,
				currentGroupChatList: action.data,
			};
		default:
			return state;
	}
}
