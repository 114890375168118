import {
	PROFILE_DETAILS,
	PROFILE_TYPE,
	SETUP_ORG_CHECK,
	SETUP_ORG,
	ADDITIONAL_DETAILS,
	PROFILE_CONFIRM,
} from '../constants';

export const profileDetails = (data) => {
	return {
		type: PROFILE_DETAILS,
		data: data,
	};
};

export const profileTypes = (data) => {
	return {
		type: PROFILE_TYPE,
		data: data,
	};
};

export const signupOrgCheck = (data) => {
	return {
		type: SETUP_ORG_CHECK,
		data: data,
	};
};

export const signupOrg = (data) => {
	return {
		type: SETUP_ORG,
		data: data,
	};
};

export const additionDetails = (data) => {
	return {
		type: ADDITIONAL_DETAILS,
		data: data,
	};
};

export const profileConfirm = (data) => {
	return {
		type: PROFILE_CONFIRM,
		data: data,
	};
};
