import Axios from 'axios/index';
import i18n from 'i18next';

import { Config, errorHandler, fetchFromStorage } from '../context';
import { identifiers } from '../constants/identifier';

const axiosInstance = Axios.create({
	baseURL: Config.baseURL,
	headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use((config) => {
	const token = fetchFromStorage(identifiers.token);
	const clonedConfig = config;

	if (token) {
		clonedConfig.headers.common = {
			Authorization: `Bearer ${token?.token}`,
			'x-access-token': `Bearer ${token?.token}`,
			'Accept-Language': i18n.language,
			locale: i18n.language,
		};
	} else {
		clonedConfig.headers.common = {
			'Accept-Language': i18n.language,
			locale: i18n.language,
		};
	}

	return clonedConfig;
});

axiosInstance.interceptors.response.use(
	(config) => {
		return config;
	},
	(error) => {
		errorHandler(error);

		return Promise.reject(error);
	},
);

export default axiosInstance;
