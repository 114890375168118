import { Box, Typography } from '@material-ui/core';
import './activity.scss';

export const Activities = ({ activityType, activityOwner, communityName, time, activityContent }) => {
	return (
		<Box className="activity-record-cover-sec">
			<Box className="activity-record-top">
				<Box className="d-flex">
					<Typography className="activity-type">{activityType}</Typography>
					<Typography className="activity-owner">{activityOwner}</Typography>
				</Box>
				<Box className="d-flex">
					<Typography className="community-name">{communityName}</Typography>
					<Typography className="activity-time">{time}</Typography>
				</Box>
			</Box>
			<Box className="activity-content-main">
				<Typography className="activity-content">{activityContent}</Typography>
			</Box>
		</Box>
	);
};
