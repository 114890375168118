import { useContext, useState } from 'react';
import { Box, Container, Grid, Button, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../fight.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { identifiers } from 'src/helpers/constants/identifier';
import { TextField } from 'src/components/ui';

// eslint-disable-next-line react/prop-types
export const AddWeight = ({ fetchAdminWeight, itemData, isUpdate, closeDialog, currentPage }) => {
	const [loader, setLoader] = useState(false);
	// eslint-disable-next-line camelcase
	const { t } = useTranslation();

	const addWeight = async (data) => {
		const requestParams = {
			...(isUpdate && { id: itemData?.id }),
			name: data?.weightname,
			description: data?.weightdescription,
			status: identifiers?.dropDown,
			lowBound: data?.lowBound,
			lowLb: data?.lowLb,
			lowKg: data?.lowKg,
			highBound: data?.highBound,
			highLb: data?.highLb,
			highKg: data?.highKg,
			allowanceLb: data?.allowanceLb,
			allowanceKg: data?.allowanceKg,
		};
		const method = isUpdate ? 'put' : 'post';
		const apiEndPoint = isUpdate ? 'updateMetaWeightClass' : 'createMetaWeightClass';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setLoader(false);
				setApiMessage('success', data?.message);
				fetchAdminWeight(currentPage);
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setLoader(false);
		}
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Box className="form-content">
						<Formik
							initialValues={{
								weightname: itemData?.name !== null ? itemData?.name : '',
								weightdescription: itemData?.description !== null ? itemData?.description : '',
								lowBound: itemData?.low_bound !== null ? itemData?.low_bound : '',
								lowLb: itemData?.low_lb !== null ? itemData?.low_lb : '',
								lowKg: itemData?.low_kg !== null ? itemData?.low_kg : '',
								highBound: itemData?.high_bound !== null ? itemData?.high_bound : '',
								highLb: itemData?.high_lb !== null ? itemData?.high_lb : '',
								highKg: itemData?.high_kg !== null ? itemData?.high_kg : '',
								allowanceLb: itemData?.allowance_lb !== null ? itemData?.allowance_lb : '',
								allowanceKg: itemData?.allowance_kg !== null ? itemData?.allowance_kg : '',
							}}
							validationSchema={Yup.object().shape({
								weightname: Yup.string().required(t('validation.required_message')),
								// weightdescription: Yup.string().required(
								//   t('validation.required_message')
								// ),
								// lowBound: Yup.string().required(
								//   t('validation.required_message')
								// ),
								// lowLb: Yup.number().required(
								//   t('validation.required_message')
								// ),
								// lowKg: Yup.number().required(
								//   t('validation.required_message')
								// ),
								// highBound: Yup.string().required(
								//   t('validation.required_message')
								// ),
								// highLb: Yup.number().required(
								//   t('validation.required_message')
								// ),
								// highKg: Yup.number().required(
								//   t('validation.required_message')
								// ),
								// allowanceLb: Yup.number().required(
								//   t('validation.required_message')
								// ),
								// allowanceKg: Yup.number().required(
								//   t('validation.required_message')
								// )
							})}
							onSubmit={(requestdata) => addWeight(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.weightname && errors.weightname)}
												fullWidth
												helperText={touched.weightname && errors.weightname}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.weightname')}
												label={t('admin_panel.combat_weight.weightname')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.weightname && errors.weightname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="weightname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.weightname}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.weightdescription && errors.weightdescription)}
												fullWidth
												helperText={touched.weightdescription && errors.weightdescription}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.weightdescription')}
												label={t('admin_panel.combat_weight.weightdescription')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.weightdescription && errors.weightdescription ? <ErrorIcon /> : null,
												}}
												size="small"
												name="weightdescription"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.weightdescription}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.lowBound && errors.lowBound)}
												fullWidth
												helperText={touched.lowBound && errors.lowBound}
												variant="filled"
												InputProps={{
													endAdornment: touched.lowBound && errors.lowBound ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_weight.lowBound')}
												placeholder={t('admin_panel.combat_weight.lowBound')}
												className="matrix-textfield"
												size="small"
												name="lowBound"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.lowBound}
												select
											>
												<MenuItem value="lt">{t('admin_panel.inequality_expressions.lt')}</MenuItem>
												<MenuItem value="lte">{t('admin_panel.inequality_expressions.lte')}</MenuItem>
												<MenuItem value="gt">{t('admin_panel.inequality_expressions.gt')}</MenuItem>
												<MenuItem value="gte">{t('admin_panel.inequality_expressions.gte')}</MenuItem>
											</TextField>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.lowLb && errors.lowLb)}
												fullWidth
												helperText={touched.lowLb && errors.lowLb}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.lowLb')}
												label={t('admin_panel.combat_weight.lowLb')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.lowLb && errors.lowLb ? <ErrorIcon /> : null,
												}}
												size="small"
												name="lowLb"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.lowLb}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.lowKg && errors.lowKg)}
												fullWidth
												helperText={touched.lowKg && errors.lowKg}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.lowKg')}
												label={t('admin_panel.combat_weight.lowKg')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.lowKg && errors.lowKg ? <ErrorIcon /> : null,
												}}
												size="small"
												name="lowKg"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.lowKg}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.highBound && errors.highBound)}
												fullWidth
												helperText={touched.highBound && errors.highBound}
												variant="filled"
												InputProps={{
													endAdornment: touched.highBound && errors.highBound ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_weight.highBound')}
												placeholder={t('admin_panel.combat_weight.highBound')}
												className="matrix-textfield"
												size="small"
												name="highBound"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.highBound}
												select
											>
												<MenuItem value="lt">{t('admin_panel.inequality_expressions.lt')}</MenuItem>
												<MenuItem value="lte">{t('admin_panel.inequality_expressions.lte')}</MenuItem>
												<MenuItem value="gt">{t('admin_panel.inequality_expressions.gt')}</MenuItem>
												<MenuItem value="gte">{t('admin_panel.inequality_expressions.gte')}</MenuItem>
											</TextField>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.highLb && errors.highLb)}
												fullWidth
												helperText={touched.highLb && errors.highLb}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.highLb')}
												label={t('admin_panel.combat_weight.highLb')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.highLb && errors.highLb ? <ErrorIcon /> : null,
												}}
												size="small"
												name="highLb"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.highLb}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.highKg && errors.highKg)}
												fullWidth
												helperText={touched.highKg && errors.highKg}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.highKg')}
												label={t('admin_panel.combat_weight.highKg')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.highKg && errors.highKg ? <ErrorIcon /> : null,
												}}
												size="small"
												name="highKg"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.highKg}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.allowanceLb && errors.allowanceLb)}
												fullWidth
												helperText={touched.allowanceLb && errors.allowanceLb}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.allowanceLb')}
												label={t('admin_panel.combat_weight.allowanceLb')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.allowanceLb && errors.allowanceLb ? <ErrorIcon /> : null,
												}}
												size="small"
												name="allowanceLb"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.allowanceLb}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.allowanceKg && errors.allowanceKg)}
												fullWidth
												helperText={touched.allowanceKg && errors.allowanceKg}
												variant="filled"
												placeholder={t('admin_panel.combat_weight.allowanceKg')}
												label={t('admin_panel.combat_weight.allowanceKg')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.allowanceKg && errors.allowanceKg ? <ErrorIcon /> : null,
												}}
												size="small"
												name="allowanceKg"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.allowanceKg}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{isUpdate ? t('button_text.update') : t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};
