import { Avatar, Box, Button, Grid, Typography } from '@material-ui/core';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { MentionsInput, Mention } from 'react-mentions';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

const AddComment = (props) => {
	const { t } = useTranslation();

	const {
		user,
		storage_authData,
		isJoin,
		formikRef,
		updateComment,
		createComment,
		onInputChange,
		editClick,
		userComment,
		allUsersList,
		loader,
	} = props;
	const defaultStyle = {
		control: {
			backgroundColor: '#1B1B1B',
			fontSize: 16,
			fontWeight: 'normal',
		},
		highlighter: {
			overflow: 'hidden',
		},
		input: {
			margin: 0,
			overflow: 'auto',
			minHeight: 100,
			color: '#fff',
		},
		'&multiLine': {
			control: {
				fontFamily: 'Roboto',
				border: '1px solid #2E353A',
			},
			highlighter: {
				padding: 9,
			},
			input: {
				padding: 9,
				minHeight: 100,
				outline: 0,
				border: 0,
			},
		},
		suggestions: {
			bottom: '0',
			top: 'unset',
			list: {
				backgroundColor: 'white',
				border: '1px solid rgba(0,0,0,0.15)',
				fontSize: 14,
			},
			item: {
				padding: '5px 15px',
				borderBottom: '1px solid rgba(0,0,0,0.15)',
				'&focused': {
					backgroundColor: '#cee4e5',
				},
			},
		},
	};
	// const [newComment, setNewComment] = useState("");
	// const handleChange = (event) => {
	//     const { value } = event.target;
	//     if (value) setNewComment(value);
	// };

	// const handleSubmit = () => {
	//     addComment(newComment);
	//     setNewComment("");
	// };
	return (
		<>
			{user && (
				<Box className="create-cmt-bx">
					<Box className="user-box">
						<Avatar src={storage_authData?.profile_pic} className="user-image" />
						<Typography className="user-name">{`${
							storage_authData?.OauthUserData?.first_name ? storage_authData?.OauthUserData?.first_name : ''
						} ${
							storage_authData?.OauthUserData?.last_name ? storage_authData?.OauthUserData?.last_name : ''
						}`}</Typography>
					</Box>
					<Box className="cmt-form">
						<Formik
							innerRef={formikRef}
							initialValues={{
								bio: '',
							}}
							onSubmit={(val) => {
								if (!isJoin) {
									if (editClick) {
										updateComment(val.bio);
									} else {
										createComment(val.bio);
									}
								}
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MentionsInput
												value={userComment}
												onChange={(e) => {
													onInputChange(e);
													setFieldValue('bio', e.target.value);
												}}
												style={defaultStyle}
												placeholder={t('community.comment')}
												rows={3}
											>
												<Mention
													markup="<a href='__id__'>__display__</a>"
													data={allUsersList}
													// onAdd={this.onAdd}
													// style={defaultMentionStyle}
												/>
											</MentionsInput>
										</Grid>
										<Grid item xs={12} md={12}>
											<Box className="comment-btnbox">
												<Button
													variant="contained"
													type="submit"
													className="cmt-btn"
													disabled={loader}
													onClick={() => {
														isJoin && setApiMessage('error', 'please join community');
													}}
												>
													{editClick ? 'Update comment' : t('community.comment')}
												</Button>
											</Box>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Box>
			)}
		</>
	);
};
export default AddComment;
