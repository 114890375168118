import {
	USER_VERIFY_COUNT,
	USER_STATICS_DATA,
	USER_DATA,
	USER_NEXT_PAGE_DATA,
	FOLLOWERS_DATA,
	FOLLOWERS_NEXT_PAGE_DATA,
} from '../constants';

export const userVerifyCount = (data) => {
	return {
		type: USER_VERIFY_COUNT,
		data: data,
	};
};

export const getUserStatistics = (data) => {
	return {
		type: USER_STATICS_DATA,
		data: data,
	};
};

export const setUserData = (data) => {
	return {
		type: USER_DATA,
		data: data,
	};
};

export const nextPageUserData = (data) => {
	return {
		type: USER_NEXT_PAGE_DATA,
		data: data,
	};
};
// followers
export const setFollowersData = (data) => {
	return {
		type: FOLLOWERS_DATA,
		data: data,
	};
};

export const nextPageFollowersData = (data) => {
	return {
		type: FOLLOWERS_NEXT_PAGE_DATA,
		data: data,
	};
};
