import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as SlateReact from 'slate-react';
import ReactDOM from 'react-dom';
import { Editable, ReactEditor, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor, Range, Transforms, Editor as StateEditor } from 'slate';
import { isKeyHotkey } from 'is-hotkey';
import * as rdd from 'react-device-detect';

import '../../textEditor.scss';
import { insertLink, withInlines } from '../../utils/editor';
import AddLinkButton from '../AddLinkButton';
import { CustomOption } from '../../../CustomOption';
import Element from '../Element';
import Text from '../Text';
import { useTranslation } from 'react-i18next';
import AddMentionButton from '../AddMentionButton';

const Editor = ({ setImageList, deletedItemArray, token, state, setState, allUsersList }) => {
	const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);
	const { t } = useTranslation();
	const ref = useRef();
	const textContainerRef = useRef();
	const [target, setTarget] = useState();
	const [search, setSearch] = useState('');
	const [chars, setChars] = useState([]);

	const onKeyDown = (event) => {
		const { selection } = editor;

		// Default left/right behavior is unit:'character'.
		// This fails to distinguish between two cursor positions, such as
		// <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
		// Here we modify the behavior to unit:'offset'.
		// This lets the user step into and out of the inline without stepping over characters.
		// You may wish to customize this further to only use unit:'offset' in specific cases.
		if (selection && Range.isCollapsed(selection)) {
			const { nativeEvent } = event;

			if (isKeyHotkey('left', nativeEvent)) {
				event.preventDefault();
				Transforms.move(editor, { unit: 'offset', reverse: true });

				return;
			}

			if (isKeyHotkey('right', nativeEvent)) {
				event.preventDefault();
				Transforms.move(editor, { unit: 'offset' });

				return;
			}
		}
	};

	useEffect(() => {
		setChars(allUsersList.filter((c) => c.text.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10));
	}, [allUsersList, search]);

	useEffect(() => {
		if (target && chars.length > 0) {
			const el = ref.current;
			const domRange = ReactEditor.toDOMRange(editor, target);
			const rect = domRange.getBoundingClientRect();

			el.style.top = `${rect.top - textContainerRef.current.getBoundingClientRect().y + 24}px`;

			el.style.left = `${rect.left - textContainerRef.current.getBoundingClientRect().x + 12}px`;
		}
	}, [chars.length, editor, search, target]);

	return (
		<SlateReact.Slate
			editor={editor}
			value={state}
			onChange={(event) => {
				const { selection } = editor;

				if (selection && Range.isCollapsed(selection) && !rdd.isAndroid) {
					const [start] = Range.edges(selection);
					const wordBefore = StateEditor.before(editor, start, { unit: 'word' });
					const before = wordBefore && StateEditor.before(editor, wordBefore);
					const beforeRange = before && StateEditor.range(editor, before, start);
					const beforeText = beforeRange && StateEditor.string(editor, beforeRange);
					const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
					const after = StateEditor.after(editor, start);
					const afterRange = StateEditor.range(editor, start, after);
					const afterText = StateEditor.string(editor, afterRange);
					const afterMatch = afterText.match(/^(\s|$)/);

					if (beforeMatch && afterMatch) {
						setTarget(beforeRange);
						setSearch(beforeMatch[1]);

						return;
					}
				}

				setTarget(null);
				setState(event);
			}}
		>
			<div className={'text-editor-toolbar'}>
				<AddLinkButton />
				{rdd.isAndroid && <AddMentionButton allUsers={allUsersList} setState={setState} />}
				<CustomOption setImageList={setImageList} deletedItemArray={deletedItemArray} token={token} />
			</div>
			<div style={{ position: 'relative' }} ref={textContainerRef}>
				<Editable
					className={'text-editor'}
					renderElement={(props) => <Element {...props} />}
					renderLeaf={(props) => <Text {...props} />}
					placeholder={t('community.text')}
					onKeyDown={onKeyDown}
				/>
				{!rdd.isAndroid && allUsersList.length > 0 && target && chars.length > 0 && (
					<div ref={ref} className={'mentions-list'} data-cy="mentions-portal">
						{chars.map((char, i) => (
							<div
								key={char.url}
								className={'mentions-list_item'}
								onClick={() => {
									const { selection } = editor;

									Transforms.delete(editor, {
										at: selection.anchor,
										unit: 'character',
										distance: search.length + 1,
										voids: true,
										reverse: true,
									});
									insertLink(editor, char.url, `@${char.text}`, false);
									setState(editor);
								}}
							>
								{char.text}
							</div>
						))}
					</div>
				)}
			</div>
		</SlateReact.Slate>
	);
};

export default Editor;

export const Portal = ({ children }) => {
	return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};
