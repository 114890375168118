import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField, Page } from 'src/components/ui';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import '../auth.scss';

export const ForgotPassword = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Page title="Forgot Password">
			<Box className="auth-section-box">
				<Grid container className="auth-grid-container">
					<Grid item xs={12} sm={12} md={12} lg={5} className="auth-left-grid">
						<Box className="content">
							<Typography className="title">{t('forgotpassword.forgot_password')}</Typography>
							<Typography className="desription">{t('forgotpassword.description')}</Typography>
							<Typography className="desription sub-desc">{t('forgotpassword.subdescription')}</Typography>
							<Box className="form-content">
								<Formik
									initialValues={{
										email: '',
									}}
									validationSchema={Yup.object().shape({
										email: Yup.string()
											.email(t('validation.valid_email'))
											.max(255)
											.required(t('validation.required_message')),
									})}
									onSubmit={async (requestData) => {
										try {
											const { status, data } = await axiosInstance.post(URLS.forgotPassword, requestData);

											if (status === 200) {
												setApiMessage('success', data?.message);
												data?.data?.status === 'cancelled'
													? navigate(`/verify-otp`, { state: `${requestData.email}` })
													: navigate(`/reset-password`, { state: `${requestData.email}` });
											}
										} catch (error) {
											setApiMessage('error', error?.response?.data?.message);
										}
									}}
								>
									{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
										<form onSubmit={handleSubmit}>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12} className="text-field-grid">
													<TextField
														error={Boolean(touched.email && errors.email)}
														fullWidth
														helperText={touched.email && errors.email}
														variant="filled"
														InputProps={{
															endAdornment: touched.email && errors.email ? <ErrorIcon /> : null,
														}}
														label={t('email')}
														placeholder={t('email')}
														className="matrix-textfield"
														size="small"
														name="email"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.email}
													/>
												</Grid>
												<Grid item xs={12} md={12}>
													<Button variant="contained" fullWidth type="submit" className="signup-button">
														{t('forgotpassword.submit')}
													</Button>
												</Grid>
											</Grid>
										</form>
									)}
								</Formik>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Page>
	);
};
