import CircularProgress from '@material-ui/core/CircularProgress';
import 'src/assets/scss/loader.scss';

export const Loader = () => {
	return (
		<div className="combat-loader">
			<CircularProgress />
		</div>
	);
};
