import { useState } from 'react';
import {
	Button,
	withStyles,
	ListItemIcon,
	Avatar,
	ListItemText,
	Checkbox,
	Box,
	Popover,
	InputAdornment,
	Typography,
	Grid,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import './dropdown.scss';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { TextField } from 'src/components/ui';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/community/arrow-down.svg';
import { useTranslation } from 'react-i18next';

export const DataRangeTime = ({ labelValue, count, formData, handleFormData }) => {
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<Button
				disableElevation
				disableTouchRipple
				disableRipple
				disableFocusRipple
				style={{ width: '100%' }}
				variant="contained"
				endIcon={<ArrowDown className={open ? 'toggle-down' : 'toggle-up'} />}
				startIcon={count > 0 && <Avatar className="circle-blue">{count}</Avatar>}
				className="filter-button"
				onClick={handleClick}
			>
				{labelValue}
			</Button>
			<Popover
				className="bcdp-popover"
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box className="checkmark-dropdown">
					<Box className="dropdown-header-sec">
						<Typography className="label-header">{labelValue}</Typography>
					</Box>
					<Box className="lower-upper-values">
						<Formik
							initialValues={{
								start_time: formData?.start_time ? formData?.start_time : '',
								end_time: formData?.end_time ? formData?.end_time : '',
							}}
							validationSchema={Yup.object().shape({
								// start_time: Yup.string().required(
								//     t('validation.required_message')
								// ),
								// end_time: Yup.string().required(
								//     t('validation.required_message')
								// )
							})}
							onSubmit={(requestdata) => {
								handleFormData(requestdata);
								setAnchorEl(null);
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.start_time && errors.start_time)}
												fullWidth
												helperText={touched.start_time && errors.start_time}
												variant="filled"
												// InputProps={{
												//   endAdornment: <ClockIcon />
												// }}
												label={t('events.start_time')}
												placeholder={t('events.start_time')}
												className="matrix-textfield"
												size="small"
												name="start_time"
												type="datetime-local"
												onBlur={handleBlur}
												InputLabelProps={{
													shrink: true,
												}}
												value={values.start_time}
												onChange={(e) => {
													handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<TextField
												error={Boolean(touched.end_time && errors.end_time)}
												fullWidth
												helperText={touched.end_time && errors.end_time}
												variant="filled"
												// InputProps={{
												//   endAdornment: <ClockIcon />
												// }}
												label={t('events.end_time')}
												placeholder={t('events.end_time')}
												className="matrix-textfield"
												size="small"
												name="end_time"
												onBlur={handleBlur}
												value={values.end_time}
												type="datetime-local"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) => {
													handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="apply-filter-sec">
											<Button variant="contained" type="submit" className="filter-btn">
												{t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Box>
			</Popover>
		</>
	);
};
