import { connect } from 'react-redux';
import { getCommunityDetails, getCommunityPostList } from 'src/redux-state/actions/actions';

import { CommunityView as CommunityViewComponent } from './CommunityView';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunityDetails: (data) => dispatch(getCommunityDetails(data)),
		getCommunityPostList: (data) => dispatch(getCommunityPostList(data)),
		// acceptInvitation: data => dispatch(acceptInvitation(data))
	};
};

export const CommunityView = connect(mapStateToProps, mapDispatchToProps)(CommunityViewComponent);
