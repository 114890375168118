import { useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CommunityPost } from 'src/components/ui';
import { PostDetailsRight } from './PostDetailsRight';
import './UserNewsFeed.scss';

export const UserNewsFeed = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Container maxWidth="lg">
				<Box className="combat-container newsfeed-container">
					<Box className="newsfeed-grid-container">
						<Box className="newsfeed-left">
							{/* COMMUNITY POSTS */}
							<CommunityPost
								isView={true}
								postName="[Post Name]"
								time="2 hours ago"
								postedBy="[Posted By]"
								topVal={45}
								downVal={10}
								// contentText="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity."
								contentImg="/static/collage.png"
								comment={58}
								shares={8}
								referrals={12}
								reactions={3}
							/>
						</Box>
						<Box className="newsfeed-right hide-1279">
							<PostDetailsRight />
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};
