import { EventEmitter } from 'src/helpers/EventEmitter';
import { Config } from './context/config';

// ONESIGNAL CONFIG START
window.OneSignal = window.OneSignal || [];
var OneSignal = window.OneSignal;

export const initOneSignalNotification = () => {
	OneSignal.push(function () {
		OneSignal.init({
			// appId: Config.oneSignalAppId,
			appId: '73bdce68-5703-4330-9453-0ceef1f6b6a9',
			// safari_web_id: config.ONE_SIGNAL_SAFARI_WEB_ID,
			// notifyButton: {
			//   enable: true,
			// },
			notificationClickHandlerMatch: 'origin',
			notificationClickHandlerAction: 'focus',
		});
	});
};

export const showNativePrompt = () => {
	OneSignal.push(function () {
		OneSignal.showNativePrompt();
	});
};
export const getUserId = () => {
	var user_Id;

	OneSignal.push(function () {
		user_Id = OneSignal.getUserId(function (userId) {
			return userId;
		});
	});

	return user_Id;
};
export function addListenerForNotificationOpened() {
	// OneSignal.push(function () {
	//   OneSignal.on('notificationDisplay', function (event) {
	//     console.warn('OneSignal notification displayed:', event);
	//   });
	//   //This event can be listened to via the `on()` or `once()` listener
	// });
	OneSignal.push([
		'addListenerForNotificationOpened',
		function (data) {
			// url={`${Config.BaseURL}event-details/${eventId}`}
			EventEmitter.dispatch('handlenotificationredirect', data);
			// handleNotificationRedirect();
		},
	]);
}
export function isPushNotificationsEnabled() {
	OneSignal.push(function () {
		OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
			if (isEnabled) {
				console.log('Push notifications are enabled!');
			} else {
				console.log('Push notifications are not enabled yet.');
			}
		});
	});
}
export function setDefaultNotificationUrl(urlValue) {
	initOneSignalNotification();
	var redirectUrl = urlValue === undefined ? `${Config.socketUrl}` : `${Config.socketUrl}${urlValue}`;

	OneSignal.push(function () {
		OneSignal.setDefaultNotificationUrl(redirectUrl);
	});
}
