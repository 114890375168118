import { Box, Grid } from '@material-ui/core';
import { EventCard } from 'src/components/ui';
import './my-events.scss';

export const MyEvents = () => {
	return (
		<Box className="my-events-box">
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<EventCard isDraft={true} />
				</Grid>

				<Grid item xs={12} sm={12} md={6} lg={6}>
					<EventCard isOwned={true} />
				</Grid>
			</Grid>
		</Box>
	);
};
