import { SocketEventEmitter } from 'src/helpers/EventEmitter';

export const initGroupSocketEvents = (userSocket) => {
	userSocket.on('add-message', (data) => {
		SocketEventEmitter.dispatch('AddMessage', data);
	});

	userSocket.on('DeleteMessageSocket', (data) => {
		SocketEventEmitter.dispatch('DeleteMessage', data);
	});
};
