import { Box } from '@material-ui/core';

import { Right } from '../Right';
import { Left } from '../Left';
import './mobileBlock.scss';

export const MobileBlock = ({
	fightTotalRecord,
	handlePaginationButtonOnClick,
	handlePaginationOnClick,
	recentPage,
	fightLimit,
	isView,
	user,
	loader,
	token,
	badgeLoader,
	badges,
	connectionCFFCLoader,
	followingLoader,
	followersLoader,
	fightLoader,
	following,
	followers,
	connections,
	communitiesList,
	fightData,
	fetchUserFight,
	ProfileAvatar,
	getMyProfile,
	fetchUserBadges,
	fetchUserDisciplines,
	disciplineLoader,
	disciplines,
	userType,
	fetchProfileTypeData,
	fetchSkills,
	fetchMetaFightMethod,
	fetchDisciplineTypesData,
	fetchEndorsementData,
	fetchRecentActivity,
	skillsData,
	fightMethod,
	endorseloader,
	endorsementData,
	disciplineData,
	recentActivityData,
	currentPage,
	list,
	handlePaginationButtonClick,
	handlePaginationClick,
	totalRecord,
	CPage,
	activityLimit,
	// paginationButtonClick,
	// paginationClick,
	activityTotal,
	endorsPage,
	tabHandlerForEndorsement,
	check,
	setRecentActivity,
	hasMore,
}) => {
	return (
		<Box className="myprofile-mobile-container">
			<Right
				fightTotalRecord={fightTotalRecord}
				handlePaginationButtonOnClick={handlePaginationButtonOnClick}
				handlePaginationOnClick={handlePaginationOnClick}
				recentPage={recentPage}
				fightLimit={fightLimit}
				isView={isView}
				user={user}
				loader={loader}
				token={token}
				badgeLoader={badgeLoader}
				badges={badges}
				connectionCFFCLoader={connectionCFFCLoader}
				followingCFFCLoader={followingLoader}
				followersCFFCLoader={followersLoader}
				fightLoader={fightLoader}
				followingCFFC={following}
				followersCFFC={followers}
				connectionsCFFC={connections}
				communitiesList={communitiesList}
				fightData={fightData}
				fetchUserFight={fetchUserFight}
				ProfileAvatar={ProfileAvatar}
				getMyProfile={getMyProfile}
			/>
			<Left
				hasMore={hasMore}
				setRecentActivity={setRecentActivity}
				fetchUserBadges={fetchUserBadges}
				isView={isView}
				user={user}
				loader={loader}
				token={token}
				getMyProfile={getMyProfile}
				fetchUserDisciplines={fetchUserDisciplines}
				disciplineLoader={disciplineLoader}
				disciplines={disciplines}
				userType={userType}
				fetchProfileTypeData={fetchProfileTypeData}
				fetchSkills={fetchSkills}
				fetchMetaFightMethod={fetchMetaFightMethod}
				fetchDisciplineTypesData={fetchDisciplineTypesData}
				fetchEndorsementData={fetchEndorsementData}
				fetchRecentActivity={fetchRecentActivity}
				skillsData={skillsData}
				fightMethod={fightMethod}
				endorseloader={endorseloader}
				endorsementData={endorsementData}
				disciplineData={disciplineData}
				recentActivityData={recentActivityData}
				currentPage={currentPage}
				limit={list}
				handlePaginationButtonClick={handlePaginationButtonClick}
				handlePaginationClick={handlePaginationClick}
				totalRecord={totalRecord}
				CPage={CPage}
				activityLimit={activityLimit}
				// paginationButtonClick={paginationButtonClick}
				// paginationClick={paginationClick}
				activityTotal={activityTotal}
				endorsPage={endorsPage}
				tabHandlerForEndorsement={tabHandlerForEndorsement}
			/>
		</Box>
	);
};
