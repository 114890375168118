import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	// CircularProgress,
	Tab,
	Typography,
	List,
} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { FollowerAsset } from 'src/components/ui';
// import { identifiers } from 'src/helpers/constants/identifier';
// import { fetchFromStorage, saveToStorage } from 'src/helpers/context';

export const CFFC = ({ followersData, followersTotalCount }) => {
	// const navigate = useNavigate();
	const { id } = useParams();

	const [tab, setTab] = useState('1');

	useEffect(() => {
		setTab('1');
		// eslint-disable-next-line
	}, [id]);

	return (
		<Box className="social-activity-container">
			<TabContext value={String(tab)}>
				<Box className="actions-header">
					<TabList variant="scrollable" scrollButtons="off" aria-label="action tabs">
						<Tab label="Followers" value={String(1)} />
					</TabList>
				</Box>

				<Box className="act-data-list">
					<TabPanel value="1">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{` Followers (${followersTotalCount})`}
								<Button
									disableElevation
									disableTouchRipple
									disableFocusRipple
									disableRipple
									className="see-all-btn-right"
								>
									See all
								</Button>
							</Typography>

							<Box className="cffc-data-container">
								<List dense>
									{followersData.map((x, i) => {
										return (
											<FollowerAsset
												key={i}
												img={x?.authUser?.item_profile_id}
												userId={x?.authUser?.id}
												uri=""
												name={x?.authUser?.user_name}
												data={x}
											/>
										);
									})}
								</List>
							</Box>
						</Box>
					</TabPanel>
				</Box>
			</TabContext>
		</Box>
	);
};
