import { connect } from 'react-redux';
import { getCommunitiesDirectoryList } from 'src/redux-state/actions/actions';
import { CommunityDirectory as CommunityDirectoryComponent } from './CommunityDirectory';

export * from './CommunityDirectory';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunitiesDirectoryList: (data) => dispatch(getCommunitiesDirectoryList(data)),
	};
};

export const CommunityDirectory = connect(mapStateToProps, mapDispatchToProps)(CommunityDirectoryComponent);
