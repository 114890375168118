import { Text } from 'slate';

import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';

export const serialize = (node) => {
	if (Text.isText(node)) {
		return escapeHtml(node.text);
	}

	const children = node.children.map((n) => serialize(n)).join('');

	switch (node.type) {
		case 'paragraph':
			return `<p>${children}</p>`;
		case 'link':
			// eslint-disable-next-line no-case-declarations
			const target = node.newWindow ? '_blank' : '_self';

			return `<a href="${escapeHtml(node.target ? node.target : node.url)}" target=${target}>${children}</a>`;
		default:
			return children;
	}
};

export const serializeText = (text) => {
	return text.reduce((acc, node) => {
		return acc + serialize(node);
	}, '');
};

export const deserialize = (el, markAttributes = {}) => {
	if (el.nodeType === Node.TEXT_NODE) {
		return jsx('text', markAttributes, el.textContent);
	} else if (el.nodeType !== Node.ELEMENT_NODE) {
		return null;
	}

	const nodeAttributes = { ...markAttributes };

	// define attributes for text nodes
	switch (el.nodeName) {
		case 'strong':
			nodeAttributes.bold = true;
	}

	const children = Array.from(el.childNodes)
		.map((node) => deserialize(node, nodeAttributes))
		.flat();

	if (children.length === 0) {
		children.push(jsx('text', nodeAttributes, ''));
	}

	switch (el.nodeName) {
		case 'BODY':
			return jsx('fragment', {}, children);
		case 'BR':
			return '\n';
		case 'BLOCKQUOTE':
			return jsx('element', { type: 'quote' }, children);
		case 'P':
			return jsx('element', { type: 'paragraph' }, children);
		case 'A':
			return jsx('element', { type: 'link', url: el.getAttribute('href') }, children);
		default:
			return children;
	}
};
