import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { EventDetails } from './EventDetails';
import { FightCard } from './FightCard';
import { Tickets } from './Tickets';
import { Confirm } from './Confirm';
import { EventAdmins } from './EventAdmins';
import { EventInvite } from './EventInvite';
import { EventFight } from './EventFight';
import { Category } from './Category';
import './new-event.scss';

export const NewEvent = ({
	token,
	currentCreateEventTab,
	eventDetails,
	eventType,
	eventFightCard,
	eventTicket,
	// eventConfirm,
	fetchEventUserCommunityOrg,
	eventAdminList,
	user,
	eventAdmin,
	eventInvite,
	fightTitleList,
	tourFightList,
	fightRuleList,
	titleTypeList,
	disciplinesData,
	eventFightSides,
	allUsers,
	timeZoneList,
	weightClasses,
	eventFightData,
	supurEventCardData,
}) => {
	const create_data = useSelector((state) => state.Events);
	let {
		current_tab,
		category,
		super_event_page,
		current_fight_tab,
		// event_details,
		// event_type,
		// event_fight_card,
		// event_ticket
	} = create_data;

	useEffect(() => {
		currentCreateEventTab(current_tab);
		// eslint-disable-next-line
	}, []);

	var eventIndex = `eventindex-${create_data?.super_event_page}`;
	var eventObj = {
		...create_data,
		event_details: create_data?.event_details,
	};
	var superEventObj = {
		...create_data,
		event_details: create_data?.super_event_details?.[eventIndex],
	};
	var dataToPass =
		create_data?.super_event_page !== 1 &&
		create_data?.super_event_status === true &&
		create_data?.category?.name === 'Card'
			? superEventObj
			: eventObj;
	const showFightHandler = (tabVal) => {
		switch (tabVal) {
			case tabVal:
				return (
					<EventFight
						fightIndex={tabVal}
						token={token}
						create_data={create_data}
						eventDetails={eventDetails}
						// eventFightCard={eventFightCard}
						fightTitleList={fightTitleList}
						tourFightList={tourFightList}
						fightRuleList={fightRuleList}
						titleTypeList={titleTypeList}
						disciplinesData={disciplinesData}
						eventFightSides={eventFightSides}
						allUsers={allUsers}
						weightClasses={weightClasses}
						// eventFightData={eventFightData}
					/>
				);
			default:
				return (
					<EventDetails
						token={token}
						create_data={create_data}
						// eventDetails={eventDetails}
						eventType={eventType}
						timeZoneList={timeZoneList}
					/>
					// <Category
					// />
				);
		}
	};
	const showFormHandler = (tabVal) => {
		if (category?.name === 'Card' && super_event_page !== 1) {
			switch (tabVal) {
				case 0:
					return (
						<EventDetails
							token={token}
							create_data={create_data}
							// eventDetails={eventDetails}
							eventType={eventType}
							timeZoneList={timeZoneList}
						/>
					);
				case 1:
					return <Category create_data={create_data} />;
				case 2:
					return showFightHandler(current_fight_tab);
				case 3:
					return (
						<Confirm
							token={token}
							// create_data={create_data}
							create_data={dataToPass}
							currentCreateEventTab={currentCreateEventTab}
						/>
					);
				default:
					return (
						<EventDetails token={token} create_data={create_data} eventType={eventType} timeZoneList={timeZoneList} />
					);
			}
		} else {
			switch (tabVal) {
				case 0:
					return (
						<EventDetails
							token={token}
							create_data={create_data}
							eventType={eventType}
							timeZoneList={timeZoneList}
							supurEventCardData={supurEventCardData}
						/>
					);
				case 1:
					return showFightHandler(current_fight_tab);
				// return (
				//   <EventFight
				//     token={token}
				//     create_data={create_data}
				//     eventDetails={eventDetails}
				//     eventFightCard={eventFightCard}
				//     fightTitleList={fightTitleList}
				//     tourFightList={tourFightList}
				//     fightRuleList={fightRuleList}
				//     titleTypeList={titleTypeList}
				//     disciplinesData={disciplinesData}
				//     eventFightSides={eventFightSides}
				//     allUsers={allUsers}
				//     weightClasses={weightClasses}
				//     eventFightData={eventFightData}
				//   />
				// )
				case 2:
					return <Tickets token={token} create_data={create_data} eventTicket={eventTicket} />;
				case 3:
					return (
						<EventAdmins
							token={token}
							create_data={create_data}
							fetchEventUserCommunityOrg={fetchEventUserCommunityOrg}
							eventAdminList={eventAdminList}
							user={user}
							// eventAdmin={eventAdmin}
						/>
					);
				case 4:
					return (
						<EventInvite
							token={token}
							create_data={create_data}
							fetchEventUserCommunityOrg={fetchEventUserCommunityOrg}
							eventAdminList={eventAdminList}
							user={user}
							// eventInvite={eventInvite}
						/>
					);
				case 5:
					return (
						<Confirm
							token={token}
							// create_data={create_data}
							create_data={dataToPass}
							currentCreateEventTab={currentCreateEventTab}
						/>
					);
				default:
					return (
						<EventDetails token={token} create_data={create_data} eventType={eventType} timeZoneList={timeZoneList} />
						// <Category
						// />
					);
			}
		}
	};

	return <Box className="create-event-box">{showFormHandler(current_tab)}</Box>;
};
