import { useState } from 'react';
import { Avatar, Box, Fade } from '@material-ui/core';
import './create-post.scss';
import { useTranslation } from 'react-i18next';
import { TextEditor, DialogBox } from 'src/components/ui';
import { OrganisationListModal } from '../../../Organization/OrganisationListModal';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';

export const CreatePost = ({ token, tab, user, fetchNewsfeedPostList, view, currentPage, allUsersList }) => {
	const { t } = useTranslation();
	const userImage = user?.profile_pic;
	const [createClick, setCreateClick] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState('');

	const initialRequestData = {
		receiver_type: 'post',
	};

	const createNewsfeedPost = async (requestParameter) => {
		try {
			const { status, data } = await axiosInstance.post(URLS.createPost, requestParameter);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchNewsfeedPostList(tabValue, view, currentPage);
				setCreateClick(false);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setCreateClick(false);
		}
	};

	const closeDialog = () => {
		setOpen(false);
	};

	const selectedOrgData = (data) => {
		setSelectedItem(data);
	};

	// eslint-disable-next-line no-constant-condition
	return createClick ? (
		<>
			<Fade in>
				<Box className="creating-post-box">
					<TextEditor
						token={token}
						user={user}
						submit={createNewsfeedPost}
						selectedItem={selectedItem}
						allUsersList={allUsersList}
						initialRequestData={initialRequestData}
					/>
					<DialogBox
						open={open}
						handleClose={closeDialog}
						title={t('organisation.all_organisation')}
						content={<OrganisationListModal selectedOrgData={selectedOrgData} />}
					/>
				</Box>
			</Fade>
		</>
	) : (
		<Box className="create-post-show-box">
			<Avatar src={userImage} />
			<Box
				onClick={() => {
					setCreateClick(true);
				}}
				className="create-post-text"
			>
				{t('newsfeed.create_post')}
			</Box>
		</Box>
	);
};
