import { Editor, Element as SlateElement, Range, Text, Transforms, Node } from 'slate';
import React from 'react';

export const insertLink = (editor, target, title, newWindow, addedByLinkButton) => {
	// to avoid automatic adding url in <a> tag
	// for example: target = 'google.com', default tag <a> will add base url to this string ('localhost/google.com')
	if (addedByLinkButton) {
		target = target.includes('http') ? target : `https://${target}`;
	}

	if (editor.selection) {
		wrapLink(editor, target, title, newWindow);
	}
};

export const isLinkActive = (editor) => {
	const [link] = Editor.nodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
	});

	return !!link;
};

export const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
	});
};

export const wrapLink = (editor, target, title, newWindow) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor);
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link = {
		type: 'link',
		target,
		newWindow,
		children: isCollapsed ? [{ text: title }] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link, { select: true });
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: 'end' });
	}
};

export const withInlines = (editor) => {
	const { isInline, normalizeNode } = editor;

	editor.isInline = (element) => ['link'].includes(element.type) || isInline(element);

	editor.normalizeNode = (entry) => {
		const [node, path] = entry;

		if (SlateElement.isElement(node) && node.type === 'paragraph') {
			const children = Array.from(Node.children(editor, path));

			for (const [child, childPath] of children) {
				// remove link nodes whose text value is empty string.
				// empty text links happen when you move from link to next line or delete link line.
				if (SlateElement.isElement(child) && child.type === 'link' && child.children[0].text === '') {
					if (children.length === 1) {
						Transforms.removeNodes(editor, { at: path });
						Transforms.insertNodes(editor, {
							type: 'paragraph',
							children: [{ text: '' }],
						});
					} else {
						Transforms.removeNodes(editor, { at: childPath });
					}

					return;
				}
			}
		}

		normalizeNode(entry);
	};

	return editor;
};

export const checkText = (state) => {
	return state.some((node) => Node.string(node));
};
