import 'react-perfect-scrollbar/dist/css/styles.css';
import { useState, useEffect, createContext } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import { connect } from 'react-redux';
import theme from 'src/theme';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { authRoute, adminRoutes, publicRoute, profileRoute } from 'src/routes';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { getUserStatistics } from 'src/redux-state/actions/userProfile';
import { fetchFromStorage, saveToStorage, clearStorage } from 'src/helpers/context';
import { parse } from 'query-string';
import 'src/assets/scss/global.scss';
import {
	initOneSignalNotification,
	addListenerForNotificationOpened,
	isPushNotificationsEnabled,
	getUserId,
} from 'src/helpers/NotificationListener';
import { Config } from 'src/helpers/context/config';
import { SocketContext, socket } from 'src/helpers/context/socket';
import { identifiers } from './helpers/constants/identifier';

// eslint-disable-next-line camelcase
const ViewPortContext = createContext({});

initOneSignalNotification();
addListenerForNotificationOpened();
isPushNotificationsEnabled();

const App = ({ auth, getUserStatistics }) => {
	const storage_authData = fetchFromStorage('authData');

	// SOCKET INITIALLISATION
	// let socketInitialize = null;

	// socketInitialize = socketio(Config.socketUrl, {
	//   jsonp: false,
	//   path: Config.socketPath,
	//   upgrade: false,
	//   reconnection: true,
	// });
	// // connect socket
	// const socket = socketInitialize.connect();

	const location = useLocation();
	const query = parse(location.search);
	let isAdmin = auth?.isAdmin ? auth?.isAdmin : storage_authData?.isAdmin;
	let token = auth?.token ? auth?.token : storage_authData?.token;
	let signup_complete = auth?.profile_status ? auth?.profile_status : storage_authData?.profile_status;
	let user_status = signup_complete ? true : false;

	const routing = useRoutes(
		isAdmin
			? adminRoutes
			: token && user_status
			? authRoute
			: token && !user_status
			? profileRoute
			: token === 'false'
			? publicRoute
			: publicRoute,
	);

	// STATE BIND WINDOW SIZE
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const breakPoint = 1020;
	const messagesBreakPoint = 960;

	// USER REFFERAL CODE
	const referralUse = async (item) => {
		let authUser = auth ? auth : storage_authData;
		var requestParameter = {
			referral_code: query?.referralcode,
			land_type: 'user_signup',
			land_id: null,
			user_id: authUser?.user_id ? authUser?.user_id : null,
		};

		if (query?.referralcode !== undefined) {
			try {
				const { status, data } = await axiosInstance.post(URLS.referralUse, requestParameter);

				if (status === 200) {
					// console.log(data)
				}
			} catch (error) {
				// setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	//  USER STATICS
	const fetchUserStatics = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getStatics, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				var isPost = true;
				var isEndorsement = true;
				var joinCommunity = true;

				if (
					data?.UserStatisticsDetails?.verification_wall === '1' ||
					data?.UserStatisticsDetails?.verification_wall === 0
				) {
					isPost = false;
					isEndorsement = false;

					if (data?.UserStatisticsDetails?.verification_data?.community_join_count >= 18) {
						joinCommunity = false;
					}
				} else if (data?.UserStatisticsDetails?.verification_wall === '2') {
					isPost = true;
					isEndorsement = true;
					joinCommunity = true;
				}

				var verificationWall = {
					isPost: isPost,
					isEndorsement: isEndorsement,
					joinCommunity: joinCommunity,
				};

				saveToStorage('verificationWall', verificationWall);
				saveToStorage('user_statics', data?.UserStatisticsDetails);
				getUserStatistics(data?.UserStatisticsDetails);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const handleNotificationRedirect = (item) => {
		var notificationObj = item?.data?.tag;
	};

	useEffect(() => {
		getUserId();
		// initSocketEvents(socket);
		EventEmitter.subscribe('handlenotificationredirect', (event) => {
			initOneSignalNotification();
			handleNotificationRedirect(event);
		});
	}, []);
	const setApiVersionToSession = async () => {
		try {
			let { status, headers } = await axiosInstance.get(URLS.getVerifyUsersList + `?page=1&limit=1`, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				var siteVersionServer = fetchFromStorage(identifiers?.siteversionserver);
				var siteVersionMinServer = fetchFromStorage(identifiers?.siteversionminserver);

				siteVersionServer === null && saveToStorage(identifiers?.siteversionserver, headers['combat-matrix-version']);
				siteVersionMinServer === null &&
					saveToStorage(identifiers?.siteversionminserver, headers['combat-matrix-min-version']);

				if (siteVersionServer !== null && siteVersionMinServer !== null) {
					if (
						headers['combat-matrix-min-version'] > siteVersionServer ||
						siteVersionServer > headers['combat-matrix-version']
					) {
						clearStorage();
						window.location = '/';
					}
				}
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		EventEmitter.subscribe('fetch_notification_count', fetchUserStatics);
		token && fetchUserStatics();
		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		let authUser = auth ? auth : storage_authData;
		var isUserLogin = authUser ? true : false;

		location && location?.pathname === '/sign-up' && !isUserLogin && referralUse();
		query?.referralcode !== undefined && saveToStorage(identifiers?.referralcode, query?.referralcode);
		var previousHitPath = location?.pathname + location?.search;

		!location?.search.includes('user_id') &&
			location?.search !== '' &&
			saveToStorage(identifiers?.previousPath, previousHitPath);
		var urlUserId = location?.search.includes('user_id') && location?.search.split('=');

		authUser !== null &&
			location?.search.includes('user_id') &&
			saveToStorage(identifiers?.userIdFromMail, urlUserId[1]);
		location?.search.includes('user_id') && saveToStorage(identifiers?.pathFromMail, previousHitPath);
	}, [location]);

	useEffect(() => {
		let authUser = auth ? auth : storage_authData;

		if (Number(fetchFromStorage(identifiers?.userIdFromMail)) !== authUser?.user_id) {
			if (authUser !== null && fetchFromStorage(identifiers?.userIdFromMail) !== null) {
				var prevPath = fetchFromStorage(identifiers?.pathFromMail);

				clearStorage();

				window.location = prevPath;
			}
		}

		var siteVersion = fetchFromStorage(identifiers?.siteversion);
		var siteVersionMin = fetchFromStorage(identifiers?.siteversionmin);

		if (siteVersion !== null && siteVersionMin !== null) {
			if (Config?.siteVersionMin > siteVersion || siteVersion > Config?.siteVersion) {
				clearStorage();
				window.location = '/';
				saveToStorage(identifiers?.siteversion, Config?.siteVersion);
				saveToStorage(identifiers?.siteversionmin, Config?.siteVersionMin);
			}
		} else {
			siteVersion === null && saveToStorage(identifiers?.siteversion, Config?.siteVersion);
			siteVersionMin === null && saveToStorage(identifiers?.siteversionmin, Config?.siteVersionMin);
		}

		setApiVersionToSession();
	}, []);

	// add event listener to monitor the window size change
	useEffect(() => {
		const handleWindowResize = () => {
			setWidth(document.documentElement.clientWidth);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ViewPortContext.Provider value={{ width, height, breakPoint, messagesBreakPoint }}>
				<SocketContext.Provider value={{ socket }}>
					<GlobalStyles />
					{routing}
				</SocketContext.Provider>
			</ViewPortContext.Provider>
		</ThemeProvider>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserStatistics: (data) => dispatch(getUserStatistics(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

export { ViewPortContext };
