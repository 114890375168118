import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchFromStorage } from 'src/helpers/context';
import { NavBar, useLayoutStyles } from '../components';

import { TopBar } from './TopBar';
import menu from './menu';

export const DashboardLayout = () => {
	const { t } = useTranslation();
	const classes = useLayoutStyles()();
	const redux_auth = useSelector((state) => state?.Combat?.authData);
	const auth = redux_auth ? redux_auth : fetchFromStorage('authData');
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const statics = useSelector((state) => state?.Combat?.user_statics);
	const user_statics = statics ? statics : fetchFromStorage('user_statics');

	const menuItems = useMemo(
		() => [
			...menu,
			{ href: `/user/${auth?.user_id}`, title: t('nav_menu_options.my_profile') },
			...[auth?.isAdmin ? [{ href: '/admin', title: t('nav_menu_options.dashboard') }] : []],
		],
		[auth],
	);

	return (
		<div className={classes.wrapper}>
			<ToastContainer />
			<TopBar auth={auth} user_statics={user_statics} onMobileNavOpen={() => setMobileNavOpen(true)} />
			<NavBar
				items={menuItems}
				onMobileClose={() => setMobileNavOpen(false)}
				openMobile={isMobileNavOpen}
				showLogout={true}
			/>
			<div className={classes.content}>
				<Outlet />
			</div>
		</div>
	);
};
