import { notificationTypes } from './constants/notificationTypes';

export const getRedirectPath = (user, notificationType) => {
	switch (notificationType.type) {
		case notificationTypes.account_verified: {
			return `/user/${user?.user_id}`;
		}
		case notificationTypes.friend_request: {
			return `/user/${notificationType?.from_userId}`;
		}
		case notificationTypes.post_reaction: {
			return `/post/${notificationType?.main_post_id}`;
		}
		case notificationTypes.post_report: {
			return `/post/${notificationType?.post_id}`;
		}
		case notificationTypes.follow_user: {
			return `/user/${notificationType?.from_userId}`;
		}
		case notificationTypes.community_assign_role: {
			return `/communities/${notificationType?.community_id}`;
		}
		case notificationTypes.post_delete: {
			return `/communities`;
		}
		case notificationTypes.accept_request: {
			return `/communities/${notificationType?.community_id}`;
		}
		case notificationTypes.reject_request: {
			return `/communities/${notificationType?.community_id}`;
		}
		case notificationTypes.block_request: {
			return `/communities/${notificationType?.community_id}`;
		}
		case notificationTypes.mention_community: {
			return `/post/${notificationType?.post_id}`;
		}
		case notificationTypes.community_connect: {
			return `/communities/${notificationType?.community_id}`;
		}
		case notificationTypes.invite_community: {
			return `/communities/${notificationType?.from_community_id}`;
		}
		case notificationTypes.transfer_owner: {
			return `/communities/${notificationType?.community_id}`;
		}
		case notificationTypes.post_comment: {
			return `/post/${notificationType?.main_post_id}`;
		}
		case notificationTypes.post_vote: {
			return `/post/${notificationType?.main_post_id}`;
		}
		case notificationTypes.mention_organisation: {
			return `/post/${notificationType?.post_id}`;
		}
		case notificationTypes.mention_user: {
			return `/post/${notificationType?.post_id}`;
		}

		case notificationTypes.invite_organisation: {
			return `/organisation-details/${notificationType?.organisation_id}`;
		}
		case notificationTypes.follow_organization: {
			return `/organisation-details/${notificationType?.organisation_id}`;
		}
		case notificationTypes.unblock_organisation: {
			return `/organisation-details/${notificationType?.organisation_id}`;
		}
		case notificationTypes.block_organisation: {
			return `/organisation-details/${notificationType?.organisation_id}`;
		}
		case notificationTypes.organisation_assign_role: {
			return `/organisation-details/${notificationType?.organisation_id}`;
		}
		case notificationTypes.chat_message: {
			return `/messages/${notificationType?.group_id}`;
		}
		case notificationTypes.event_invitation: {
			return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
		}
		case notificationTypes.event_reaction: {
			if (notificationType?.parent_event_id) {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`;
			} else {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
			}
		}
		case notificationTypes.event_vote: {
			if (notificationType?.parent_event_id) {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`;
			} else {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
			}
		}
		case notificationTypes.event_fight_reaction: {
			if (notificationType?.parent_event_id) {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`;
			} else {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
			}
		}
		case notificationTypes.event_fight_vote: {
			if (notificationType?.parent_event_id) {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`;
			} else {
				return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
			}
		}
		case notificationTypes.event_admin: {
			return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
		}
		case notificationTypes.event_comment: {
			return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
		}
		case notificationTypes.join_rsvp: {
			return `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`;
		}

		default: {
			return '/communities';
		}
	}
};
