import { useState, useRef, useEffect } from 'react';
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	Typography,
	Checkbox,
	ListItem,
	ListItemText,
	List,
	ListItemIcon,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { TextField } from 'src/components/ui';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/images/icons/down-icon.svg';
import { ReactComponent as UpArrowIcon } from 'src/assets/images/icons/up-icon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import './ticket.scss';
import { useTranslation } from 'react-i18next';

export const Tickets = ({ eventTicket, create_data }) => {
	const { t } = useTranslation();

	const [createdTicket, setCreatedTicket] = useState([
		// {
		//   // id: 1,
		//   ticket_name: '',
		//   number_of_ticket: '',
		//   currency: '',
		//   price: '',
		//   min_pur: '',
		//   max_pur: '',
		//   discount_bundle: [
		//     {
		//       // id: '',
		//       no_of_bundle: '2',
		//       bundle_price: ''
		//     }
		//   ]
		// }
	]);
	const [ticketsData, setTicketsData] = useState([]);
	// const [addBundle, setAddBundle] = useState(
	//   [
	//     {
	//       id: '',
	//       no_of_bundle: '2',
	//       bundle_price: ''
	//     }
	//   ]
	// );
	// const [submitTickets, setSubmitTickets] = useState(false);
	const currencyData = ['USD', 'AUD', 'INR'];
	const [tickets, setTickets] = useState(true);
	const formRef = useRef();
	const admissionDate = [
		{ id: 1, name: 'All' },
		{ id: 2, name: '07/05/2022 10:00PM - ' },
		{ id: 3, name: '07/05/2022 10:00PM - ' },
	];
	let { event_ticket } = create_data;

	// ADD NEW TICKET HANDLER
	const addTicketHandler = () => {
		// let NewId = createdTicket[createdTicket?.length - 1]?.id + 1;
		setCreatedTicket([
			...createdTicket,
			{
				// id: NewId,
				ticket_name: '',
				number_of_ticket: '',
				currency: '',
				price: '',
				discount_bundle: [],
			},
		]);
	};
	const addTicketsData = (e, index, fieldname, Bindex) => {
		var urlArray = [...ticketsData];

		if (fieldname === 'ticket_name') {
			urlArray[index] = { ...urlArray[index], name: e.target.value };
			setTicketsData(urlArray);
		}

		if (fieldname === 'number_of_tickets') {
			urlArray[index] = { ...urlArray[index], available: e.target.value };
			setTicketsData(urlArray);
		}

		if (fieldname === 'currency') {
			urlArray[index] = { ...urlArray[index], currency: e.target.value };
			setTicketsData(urlArray);
		}

		if (fieldname === 'price_per_ticket') {
			urlArray[index] = { ...urlArray[index], price: e.target.value };
			setTicketsData(urlArray);
		}

		if (fieldname === 'min_pur') {
			urlArray[index] = { ...urlArray[index], purchase_min: e.target.value };
			setTicketsData(urlArray);
		}

		if (fieldname === 'max_pur') {
			urlArray[index] = { ...urlArray[index], purchase_max: e.target.value };
			setTicketsData(urlArray);
		}

		if (fieldname === 'no_bundle') {
			var combs = createdTicket[index].discount_bundle;

			let newComb = combs?.map((x, xindex) => {
				if (xindex === Bindex) {
					return { ...x, bundle_price: x?.bundle_price, no_of_bundle: e.target.value };
				}

				return x;
			});
			let updateData = createdTicket.map((x, xind) => {
				if (xind === index) {
					return { ...x, discount_bundle: newComb };
				}

				return x;
			});

			setCreatedTicket(updateData);
			setTicketsData(updateData);
		}

		if (fieldname === 'bundle_price') {
			let combs = createdTicket[index].discount_bundle;
			let newComb = combs?.map((x, xindex) => {
				if (xindex === Bindex) {
					return { ...x, no_of_bundle: x?.no_of_bundle, bundle_price: e.target.value };
				}

				return x;
			});
			let updateData = createdTicket.map((x, xind) => {
				if (xind === index) {
					return { ...x, discount_bundle: newComb };
				}

				return x;
			});

			setCreatedTicket(updateData);
			setTicketsData(updateData);
		}
	};
	const addDisccoutHandler = (index) => {
		let newVal = createdTicket[index]?.discount_bundle || [];
		var obj = {
			no_of_bundle: '',
			bundle_price: '',
		};

		newVal.push(obj);
		var ticketData = createdTicket.map((tickets, tindex) => {
			if (index === tindex) {
				return { ...tickets, discount_bundle: newVal };
			}

			return tickets;
		});

		setCreatedTicket(ticketData);
	};

	const handleTickets = (index) => {
		tickets === index ? setTickets(false) : setTickets(index);
	};
	const TicketsSubmit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
			// setSubmitTickets(true);
		}
	};
	// REMOVE TICKET
	const removeTicketHandler = (id) => {
		if (createdTicket.length > 0) {
			let updatedData = createdTicket.filter((x, index) => index !== id);

			setCreatedTicket(updatedData);
		}
	};
	const removeBundleHandler = (index, bundleId) => {
		if (createdTicket[index]?.discount_bundle.length > 0) {
			let updateData = createdTicket.map((x, xindex) => {
				var bunData = x.discount_bundle.filter((item, index) => index === bundleId);

				if (xindex === index) {
					return { ...x, discount_bundle: bunData };
				}

				return x;
			});

			setCreatedTicket(updateData);
		}
	};

	useEffect(() => {
		EventEmitter.subscribe('submit_tickets', TicketsSubmit);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		ticketsData && setCreatedTicket(ticketsData);
	}, [ticketsData]);
	useEffect(() => {
		event_ticket && setCreatedTicket(event_ticket);
		event_ticket && setTicketsData(event_ticket);
	}, [event_ticket]);

	return (
		<Box className="fight-ticket-box">
			<Box className="ticket-title-box">
				<Typography className="ticket-title">{t('events.tickets')}</Typography>
				{/* <IconButton onClick={addTicketHandler}>
          <BaddIcon />
        </IconButton> */}
				<Button className="add-ticket-btn" onClick={addTicketHandler}>
					{t('events.add_ticket')}
				</Button>
			</Box>
			<Formik
				innerRef={formRef}
				initialValues={{
					ticket_name: '',
					number_of_tickets: '',
					price: '',
					currency: '',
					min_pur: '',
					max_pur: '',
					no_of_bundle: '',
					bundle_price: '',
				}}
				validationSchema={Yup.object().shape({
					// ticket_name: Yup.string().required(
					//   t('validation.required_message')
					// )
					// number_of_tickets: Yup.string().required(
					//   t('validation.required_message')
					// ),
					// min_pur: Yup.number().required(
					//   t('validation.required_message')
					// ),
					// max_pur: Yup.number().required(
					//   t('validation.required_message')
					// ),
					// no_of_bundle: Yup.string().required(
					//   t('validation.required_message')
					// ),
					// bundle_price: Yup.string().required(
					//   t('validation.required_message')
					// )
				})}
				onSubmit={(data) => {
					eventTicket(ticketsData);
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						{createdTicket.map((obj, index) => {
							var ticketName = `ticket_name-${index + 1}`;
							var numberOfTickets = `number_of_tickets-${index + 1}`;
							var currency = `currency-${index + 1}`;
							var price = `price-${index + 1}`;
							var minPurchase = `min_pur-${index + 1}`;
							var maxPurchase = `max_pur-${index + 1}`;

							return (
								<Box className="event-bg-sec">
									<Box className="event-bg-grid">
										<Typography className="date-bg-text">{t('events.ticket') + ` ${index + 1}`}</Typography>
										<Box>
											<IconButton
												className="delete-date"
												onClick={() => {
													removeTicketHandler(index);
												}}
											>
												<DeleteIcon />
											</IconButton>
											<IconButton className="delete-date" onClick={() => handleTickets(index)}>
												{tickets !== index ? <DownArrowIcon /> : <UpArrowIcon />}
											</IconButton>
										</Box>
									</Box>
									{tickets !== index && (
										<Box className="ticket-form-container">
											<Box>
												<Grid container spacing={3}>
													<Grid item xs={12} md={12} className="text-field-grid">
														<TextField
															error={Boolean(touched.ticket_name && errors.ticket_name)}
															fullWidth
															helperText={touched.ticket_name && errors.ticket_name}
															variant="filled"
															InputProps={{
																endAdornment: touched.ticket_name && errors.ticket_name ? <ErrorIcon /> : null,
															}}
															label={t('events.ticket_name')}
															placeholder={t('events.ticket_name')}
															className="matrix-textfield"
															size="small"
															name={ticketName}
															onBlur={handleBlur}
															onChange={(e) => {
																handleChange(e);
																addTicketsData(e, index, 'ticket_name');
															}}
															value={obj?.name}
														/>
													</Grid>
													<Grid item xs={12} md={12} className="text-field-grid">
														<TextField
															error={Boolean(touched.number_of_tickets && errors.number_of_tickets)}
															fullWidth
															helperText={touched.number_of_tickets && errors.number_of_tickets}
															variant="filled"
															InputProps={{
																endAdornment:
																	touched.number_of_tickets && errors.number_of_tickets ? <ErrorIcon /> : null,
															}}
															label={t('events.number_of_tickets')}
															placeholder={t('events.number_of_tickets')}
															className="matrix-textfield"
															size="small"
															type="number"
															name={numberOfTickets}
															onBlur={handleBlur}
															onChange={(e) => {
																handleChange(e);
																addTicketsData(e, index, 'number_of_tickets');
															}}
															value={obj?.available}
														/>
													</Grid>
													<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
														<Grid item xs={12} sm={12} md={3} lg={3} className="date-field">
															<TextField
																error={Boolean(touched.currency && errors.currency)}
																fullWidth
																helperText={touched.currency && errors.currency}
																variant="filled"
																InputProps={{
																	endAdornment: touched.currency && errors.currency ? <ErrorIcon /> : null,
																}}
																label={t('events.currency')}
																placeholder={t('events.currency')}
																className="matrix-textfield"
																size="small"
																select
																name={currency}
																onBlur={handleBlur}
																onChange={(e) => {
																	handleChange(e);
																	addTicketsData(e, index, 'currency');
																}}
																value={obj?.currency}
															>
																{currencyData.map((c, cindex) => {
																	return (
																		<MenuItem key={cindex} value={c}>
																			{c}
																		</MenuItem>
																	);
																})}
															</TextField>
														</Grid>
														<Grid item xs={12} sm={12} md={10} lg={10} className="age-field">
															<TextField
																error={Boolean(touched.price && errors.price)}
																fullWidth
																helperText={touched.price && errors.price}
																variant="filled"
																InputProps={{
																	endAdornment: touched.price && errors.price ? <ErrorIcon /> : null,
																}}
																label={t('events.price_per_ticket')}
																placeholder={t('events.price_per_ticket')}
																className="matrix-textfield"
																size="small"
																type="number"
																name={price}
																onBlur={handleBlur}
																onChange={(e) => {
																	handleChange(e);
																	addTicketsData(e, index, 'price_per_ticket');
																}}
																value={obj?.price}
															/>
														</Grid>
													</Grid>
													<Grid item xs={12} md={12} className="text-field-grid">
														<TextField
															error={Boolean(touched.min_pur && errors.min_pur)}
															fullWidth
															helperText={touched.min_pur && errors.min_pur}
															variant="filled"
															InputProps={{
																endAdornment: touched.min_pur && errors.min_pur ? <ErrorIcon /> : null,
															}}
															label={t('events.minimum_tickets_purchasable')}
															placeholder={t('events.minimum_tickets_purchasable')}
															className="matrix-textfield"
															size="small"
															type="number"
															name={minPurchase}
															onChange={(e) => {
																handleChange(e);
																addTicketsData(e, index, 'min_pur');
															}}
															value={obj?.purchase_min}
														/>
														<Typography className="ticket-min-max-text">{t('events.leave_blank_min')}</Typography>
													</Grid>
													<Grid item xs={12} md={12} className="text-field-grid">
														<TextField
															error={Boolean(touched.max_pur && errors.max_pur)}
															fullWidth
															helperText={touched.max_pur && errors.max_pur}
															variant="filled"
															InputProps={{
																endAdornment: touched.max_pur && errors.max_pur ? <ErrorIcon /> : null,
															}}
															label={t('events.maximum_tickets_purchasable')}
															placeholder={t('events.maximum_tickets_purchasable')}
															className="matrix-textfield"
															size="small"
															type="number"
															name={maxPurchase}
															onChange={(e) => {
																handleChange(e);
																addTicketsData(e, index, 'max_pur');
															}}
															value={obj?.purchase_max}
														/>
														<Typography className="ticket-min-max-text">{t('events.leave_blank_max')}</Typography>
													</Grid>

													{/* <Box className="delete-ticket-box">
                  <IconButton
                    onClick={() => {
                      removeTicketHandler(obj?.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box> */}
													{/* add_discount_bundle */}
													{/* <Grid item xs={12} md={12} className="text-right">
                          <Button className='add-ticket-btn' onClick={() => addDisccoutHandler(index)}>
                            {t('events.add_discount_bundle')}
                          </Button>
                        </Grid> */}
													{/* {
                          obj?.discount_bundle.map((bun, bindex) => {
                            var no_of_bundle = `no_of_bundle-${bindex + 1}`;
                            var bundle_price = `bundle_price-${bindex + 1}`;
                            return (
                              <>
                                <Grid item xs={12} md={12} >
                                  <Box className="event-bg-grid">
                                    <Typography className='date-bg-text'>
                                      {t('events.discount_bundle') + ` ${index + 1}+${bindex + 1}`}
                                    </Typography>
                                    <Box >
                                      <IconButton className='delete-date'
                                        onClick={() => {
                                          removeBundleHandler(index, bindex);
                                        }}>
                                        <DeleteIcon />
                                      </IconButton>
                                      <IconButton className='delete-date' onClick={() => handleTickets()}>
                                        {
                                          tickets ? <DownArrowIcon /> : <UpArrowIcon />
                                        }
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={12} className="text-field-grid">
                                  <TextField
                                    error={Boolean(
                                      touched.no_of_bundle && errors.no_of_bundle
                                    )}
                                    fullWidth
                                    helperText={touched.no_of_bundle && errors.no_of_bundle}
                                    variant="filled"
                                    InputProps={{
                                      endAdornment:
                                        touched.no_of_bundle && errors.no_of_bundle ? (
                                          <ErrorIcon />
                                        ) : null
                                    }}
                                    label={t('events.number_of_tickets_in_bundle_discount')}
                                    placeholder={t('events.number_of_tickets_in_bundle_discount')}
                                    className="matrix-textfield"
                                    size="small"
                                    name={no_of_bundle}
                                    onChange={(e) => {
                                      handleChange(e);
                                      addTicketsData(e, index, 'no_bundle', bindex)
                                    }}
                                  // value={values.no_of_bundle}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12} className="text-field-grid">
                                  <TextField
                                    error={Boolean(
                                      touched.bundle_price && errors.bundle_price
                                    )}
                                    fullWidth
                                    helperText={touched.bundle_price && errors.bundle_price}
                                    variant="filled"
                                    InputProps={{
                                      endAdornment:
                                        touched.bundle_price && errors.bundle_price ? (
                                          <ErrorIcon />
                                        ) : null
                                    }}
                                    label={t('events.bundle_discount_price')}
                                    placeholder={t('events.bundle_discount_price')}
                                    className="matrix-textfield"
                                    size="small"
                                    name={bundle_price}
                                    onChange={(e) => {
                                      handleChange(e);
                                      addTicketsData(e, index, 'bundle_price', bindex)
                                    }}
                                  // value={values.bundle_price}
                                  />
                                </Grid>
                              </>
                            )
                          })
                        } */}
													{/* <Grid item xs={12} md={12} className="pb-0">
                          <Typography className='admission-text'>
                            {t('events.admission_dates')}
                          </Typography>

                        </Grid> */}
													{/* admission_dates for future */}
													{/* <Grid item xs={12} md={12}>
                          <List>
                            {admissionDate.map((value, index) => {
                              const labelId = `checkbox-list-label-${index}`;
                              return (
                                <ListItem key={index} >
                                  <ListItemIcon >
                                    <Checkbox
                                      edge="start"
                                      // checked={checked.indexOf(value?.id) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} primary={value?.name} />
                                </ListItem>
                              )
                            })
                            }
                          </List>
                        </Grid> */}
												</Grid>
											</Box>
										</Box>
									)}
								</Box>
							);
						})}
					</form>
				)}
			</Formik>
		</Box>
	);
};
