import { connect } from 'react-redux';
import { adminFightMethod, adminFightWeight } from 'src/redux-state/actions/adminAction';
import { Fight as FightComponent } from './Fight';

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		adminFightMethod: (data) => dispatch(adminFightMethod(data)),
		adminFightWeight: (data) => dispatch(adminFightWeight(data)),
	};
};

export const Fight = connect(mapStateToProps, mapDispatchToProps)(FightComponent);
