import { useState } from 'react';
import { Box, Button, Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import styles from './report.module.scss';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useTranslation } from 'react-i18next';

export const Report = ({ reportReasonList, type, receiverId, handleClose }) => {
	const { t } = useTranslation();

	const [selected, setSelected] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const typeList = [
		{ id: 1, title: t('report_type.sexual_content') },
		{ id: 2, title: t('report_type.sensitive_information') },
		{ id: 3, title: t('report_type.self_harm') },
		{ id: 4, title: t('report_type.copyright') },
		{ id: 5, title: t('report_type.incorrect') },
		{ id: 6, title: t('report_type.claiming') },
		{ id: 7, title: t('report_type.copyright_owner') },
	];

	const handleClick = async () => {
		if (!selected) {
			return;
		}

		try {
			setIsLoading(true);

			const requestParams = {
				receiver_type: type,
				receiver_id: receiverId,
				reported_reason_id: +selected,
			};

			const { status, data } = await axiosInstance.post(URLS.addReport, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				handleClose();
			}
		} catch (error) {
			if (typeof error?.response?.data === 'string') {
				setApiMessage('error', error?.response?.statusText);
			} else {
				setApiMessage('error', error?.response?.data?.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const changeReasonHandler = (e) => {
		setSelected(e.target.value);
	};

	return (
		<Box className={styles['report-profile-dialog']}>
			<Typography className={styles['report-title-txt']}>{t('report_text')}</Typography>

			{/* REPORT FOR TYPE LIST */}
			<Box className={styles['select-report-for']}>
				<RadioGroup className={styles.reportRadio} value={selected} onChange={changeReasonHandler}>
					{typeList.map((item) => {
						return <FormControlLabel key={item.title} value={`${item.id}`} control={<Radio />} label={item.title} />;
					})}
				</RadioGroup>
			</Box>

			{/* REPORT BUTTON */}
			<Box className="dialog-submit-button dialog-sticky-btn">
				<Button
					disabled={!selected || isLoading}
					variant="contained"
					type="submit"
					className="dialog-submit"
					onClick={() => handleClick()}
				>
					{t('report')}
				</Button>
			</Box>
		</Box>
	);
};
