import { memo } from 'react';
import { connect } from 'react-redux';

import isEqual from 'src/utils/common/isEqual';
import { logoutUser } from 'src/redux-state/actions/actions';

import { TopBar as TopBarComponent } from './TopBar';

const mapDispatchToProps = (dispatch) => {
	return {
		logoutUser: (data) => dispatch(logoutUser(data)),
	};
};

export const TopBar = memo(connect(null, mapDispatchToProps)(TopBarComponent), isEqual);
