import { useState, useEffect, useContext } from 'react';
import {
	Box,
	Button,
	Container,
	Grid,
	Tab,
	Typography,
	// Button,
	withStyles,
	TextField,
	InputAdornment,
	useMediaQuery,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';
import { UserProfileCard, CustomPaginations, Page } from 'src/components/ui';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search-icon.svg';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
// HIDE-MVP: CM-71
// import OrgUser from 'src/views/component/application/Organisation/YourOrg/OrgUser';
import './connections.scss';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
// HIDE-MVP: CM-71
// import UserOrg from './userOrg';
import { UserProfile } from './UserProfile';
import { CommunityListView } from '../Community/CommunityList/CommunityListView';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

// const Connections = ({ setUserData, nextPageUserData }) => {
export const UserConnection = () => {
	const navigate = useNavigate();
	const { type } = useParams();
	const { state } = useLocation();
	const auth = useSelector((state) => state?.Combat?.authData);

	const isFullScreenScroll = useMediaQuery('(max-width: 1020px)');

	// const [hasMoreItems, setHasMoreItems] = useState(true);
	// const [page, setPageValue] = useState(1);
	const location = useLocation();
	const { t } = useTranslation();
	let ownerID = useSelector((state) => state?.Combat?.authData)?.user_id || fetchFromStorage('authData')?.user_id;
	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	// const allUsersList = useSelector(state => state?.Combat?.allUsersList);
	// const userPage = useSelector(state => state?.Combat?.userPage);

	const [loader, setLoader] = useState(false); //setLoader
	const [searchText, setSearchText] = useState('');
	const [usersData, setUsersData] = useState([]);
	const [tab, setTab] = useState('1');
	const [connectionData, setConnections] = useState([]);
	const [connectionLoader, setConnectionLoader] = useState(false);
	const [followingLoader, setFollowingLoader] = useState(false);
	const [following, setFollowing] = useState([]);
	const [followers, setFollowers] = useState([]);
	const [followersLoader, setFollowersLoader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords
	const [list] = useState(9); //setList
	const [pendingConnection, setPendingConnection] = useState('');
	// HIDE-MVP: CM-71
	// const [organisationData, setOrganisationData] = useState([]);
	const [orgLoader, setOrgLoader] = useState(false);
	const [pendingLoader, setPendingLoader] = useState('');
	const [userTotalRecord, setUserTotalRecord] = useState('');
	const [connectionTotalRecord, setConnectionTotalRecord] = useState('');
	const [pendingTotalRecord, setPendingTotalRecord] = useState('');
	const [followersTotalRecord, setFollowersTotalRecord] = useState('');
	const [communityData, setCommunityData] = useState([]);
	const [communityLoader, setCommunityLoader] = useState(false);
	const [commTotalRecord, setCommTotalRecord] = useState('');
	const [referralLoader, setReferralLoader] = useState(false);
	const [referralData, setReferralData] = useState([]);
	const [refTotalRecord, setRefTotalRecord] = useState('');
	const [allOrgData, setAllOrgData] = useState([]);
	const [allOrgLoader, setAllOrgLoader] = useState(false);
	const [userOrgRecord, setUserOrgToRecord] = useState('');
	const [hasMore, setHasMore] = useState(true);
	const [userHasMore, setUserHasMore] = useState(true);
	const [orgPage, setOrgPage] = useState(1);
	const [userPage, setUserPage] = useState(1);

	const [userFTab, setUserFtab] = useState('1');

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	let visitorId = state !== null ? state?.USERID : fetchFromStorage('authData')?.user_id;

	const previousPathHandler = () => {
		navigate(`/user/${visitorId}`);
	};
	// FETCH ALL USERLIST
	const fetchUsers = async () => {
		setLoader(true);

		// var page = userPage !== undefined ? userPage : 1;
		// searchText === '' && setUserPage(1);
		try {
			let { status, data } = await axiosInstance.get(
				URLS.getUserData,
				{
					params: {
						page: searchText === '' ? userPage : 1,
						limit: list,
						name: searchText !== '' ? searchText : null,
						type: userFTab,
					},
				},
				headers,
			);

			if (status === 200) {
				const user_HasMore = data?.getUserData?.length < 9 ? false : true;

				// setUsersData(data?.getUserData);
				setUserTotalRecord(data?.totalCount);

				var merged_Array = userPage === 1 ? data?.getUserData : usersData.concat(data?.getUserData);

				setUserHasMore(user_HasMore);
				searchText === '' ? setUserPage(userPage + 1) : setUserPage(1);
				searchText === '' ? setUsersData(merged_Array) : setUsersData(data?.getUserData);
				setLoader(false);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	// UPDATE USER DATA
	const updateUserData = async (page, singleuser) => {
		try {
			let { status, data } = await axiosInstance.get(
				URLS.getUserData,
				{
					params: {
						// page: 1,
						// limit: list,
						// name: searchText !== '' ? searchText : null,
						type: userFTab,
						user_id: singleuser?.user_id,
						user_type: singleuser?.type,
					},
				},
				headers,
			);

			if (status === 200) {
				var userObj = data?.getUserData[0];
				var filteredData = usersData.map((person) => (person.user_id === singleuser.user_id ? userObj : person));

				setUsersData(filteredData);
				// const user_HasMore = data?.getUserData?.length < 9 ? false : true;
				// setUserHasMore(user_HasMore);
				// setUserTotalRecord(data?.totalCount);
				// setUserPage(2)
				// setUsersData(data?.getUserData)
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH CONNECTIONS DATA
	const fetchConnectionData = async (pageNumber, check) => {
		setConnectionLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getConnection,
				{
					params: {
						userId: visitorId,
						page: pageNumber,
						limit: list,
						name: check ? null : searchText !== '' ? searchText : null,
					},
				},
				headers,
			);

			if (status === 200) {
				setConnections(data?.connectionUser);
				setConnectionLoader(false);
				setConnectionTotalRecord(data?.totalCount);
			}
		} catch (error) {
			setConnectionLoader(false);
		}
	};

	// FETCH USER FOLLOWING DATA
	const fetchFollowingData = async (pageNumber) => {
		setFollowingLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getFollowing,
				{
					params: {
						// userId: visitorId,
						userID: visitorId,
						page: pageNumber,
						limit: list,
						name: searchText !== '' ? searchText : null,
					},
				},
				headers,
			);

			if (status === 200) {
				setTotalRecords(data?.totalCount);
				setFollowing(data?.followingUser);
				setFollowingLoader(false);
			}
		} catch (error) {
			setFollowingLoader(false);
		}
	};

	useEffect(() => {
		location?.state?.search === 'search' && setUserFtab('5');
	}, [location?.state?.search]);

	// FETCH ORGANISATION DATA

	// HIDE-MVP: CM-79
	// const fetchOrganisationData = async (pageNumber) => {
	// 	setOrgLoader(true);
	//
	// 	try {
	// 		let { status, data } = await axiosInstance.get(
	// 			// URLS.getAllOrganisation,
	// 			URLS.getMyOrganisations,
	// 			{
	// 				params: {
	// 					page: pageNumber,
	// 					limit: list,
	// 					name: searchText !== '' ? searchText : null,
	// 				},
	// 			},
	// 			headers,
	// 		);
	//
	// 		if (status === 200) {
	// 			setTotalRecords(data?.totalRecords);
	// 			setOrganisationData(data?.orgData);
	// 			setOrgLoader(false);
	// 		}
	// 	} catch (error) {
	// 		setOrgLoader(false);
	// 	}
	// };
	// const fetchAllOrganisationData = async () => {
	// 	setAllOrgLoader(true);
	//
	// 	try {
	// 		let { status, data } = await axiosInstance.get(
	// 			URLS.getAllOrganisation,
	// 			{
	// 				params: {
	// 					page: searchText === '' ? orgPage : 1,
	// 					limit: list,
	// 					name: searchText !== '' ? searchText : null,
	// 				},
	// 			},
	// 			headers,
	// 		);
	//
	// 		if (status === 200) {
	// 			const hasMore = data?.orgData?.length < 9 ? false : true;
	//
	// 			setHasMore(hasMore);
	// 			var mergedArray = orgPage === 1 ? data?.orgData : allOrgData.concat(data?.orgData);
	//
	// 			setUserOrgToRecord(data?.totalRecords);
	// 			searchText === '' ? setOrgPage(orgPage + 1) : setOrgPage(1);
	// 			searchText === '' ? setAllOrgData(mergedArray) : setAllOrgData(data?.orgData);
	// 			setAllOrgLoader(false);
	// 		}
	// 	} catch (error) {
	// 		setAllOrgLoader(false);
	// 	}
	// };

	const fetchCommunitiesData = async (pageNumber) => {
		setCommunityLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getCommunitiesByUser + `?user_id=${visitorId}`,
				{
					params: {
						page: pageNumber,
						limit: list,
						name: searchText !== '' ? searchText : null,
					},
				},
				headers,
			);

			if (status === 200) {
				setCommunityData(data?.communitiesData);
				setCommunityLoader(false);
				setCommTotalRecord(data?.totalRecords);
			}
		} catch (error) {
			setCommunityLoader(false);
		}
	};

	// FETCH CONNECTION REQUEST
	const fetchPendingConnectionUser = async (pageNumber, check) => {
		setPendingLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getPendingConnectionUser,
				{
					params: {
						userId: visitorId,
						page: pageNumber,
						limit: list,
						name: check ? null : searchText !== '' ? searchText : null,
					},
				},
				headers,
			);

			if (status === 200) {
				setPendingTotalRecord(data?.totalCount);
				setPendingConnection(data?.connectionUser);
				setPendingLoader(false);
			}
		} catch (error) {
			setPendingLoader(false);
		}
	};

	// FETCH USER FOLLOWERS DATA
	const fetchFollowersData = async (pageNumber, check) => {
		setFollowersLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getFollowers,
				{
					params: {
						// userId: visitorId,
						userID: visitorId,
						page: pageNumber,
						limit: list,
						name: check ? null : searchText !== '' ? searchText : null,
					},
				},
				headers,
			);

			if (status === 200) {
				setFollowersTotalRecord(data?.totalCount);
				setFollowers(data?.followersUser);
				setFollowersLoader(false);
			}
		} catch (error) {
			setFollowersLoader(false);
		}
	};
	const fetchReferralData = async (pageNumber) => {
		setReferralLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getReferralUser + `?userId=${visitorId}`,
				{
					params: {
						page: pageNumber,
						limit: list,
						name: searchText !== '' ? searchText : null,
					},
				},
				headers,
			);

			if (status === 200) {
				setReferralData(data?.getReferralCode);
				setReferralLoader(false);
				setRefTotalRecord(data?.totalRecords);
			}
		} catch (error) {
			setReferralLoader(false);
		}
	};

	// SEARCH BUTTON CLICK HANDLER
	const searchButtonClickHandler = (e) => {
		e.preventDefault();
		setCurrentPage(1);

		if (Number(tab) === 1) {
			fetchConnectionData(1);
		}

		if (window.location.pathname === '/users') {
			// HIDE-MVP: CM-71
			// Number(userFTab) === 5 ? fetchAllOrganisationData() : fetchUsers();
			fetchUsers();
		}

		if (Number(tab) === 2) {
			fetchPendingConnectionUser(1);
		}

		if (Number(tab) === 3) {
			fetchFollowersData(1);
		}

		if (Number(tab) === 4) {
			fetchFollowingData(1);
		}

		if (Number(tab) === 5) {
			fetchCommunitiesData(1);
		}

		// HIDE-MVP: CM-79
		// if (Number(tab) === 6) {
		// 	fetchOrganisationData(1);
		// }

		if (Number(tab) === 7) {
			fetchReferralData(1);
		}
	};

	// PAGINATION HANDLERS
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);

				if (Number(tab) === 1) {
					fetchConnectionData(currentPage - 1);
				}

				// if (window.location.pathname === '/users') {
				//   Number(userFTab) === 5 ? fetchAllOrganisationData(currentPage - 1) : fetchUsers(currentPage - 1);
				// }
				if (Number(tab) === 2) {
					fetchPendingConnectionUser(currentPage - 1);
				}

				if (Number(tab) === 3) {
					fetchFollowersData(currentPage - 1);
				}

				if (Number(tab) === 4) {
					fetchFollowingData(currentPage - 1);
				}

				// HIDE-MVP: CM-71
				// if (Number(tab) === 5) {
				// 	fetchCommunitiesData(currentPage - 1);
				// }

				// HIDE-MVP: CM-79
				// if (Number(tab) === 6) {
				// 	fetchOrganisationData(currentPage - 1);
				// }

				if (Number(tab) === 7) {
					fetchReferralData(currentPage - 1);
				}
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(currentPage + 1);

				if (Number(tab) === 1) {
					fetchConnectionData(currentPage + 1);
				}

				// if (window.location.pathname === '/users') {
				//   Number(userFTab) === 5 ? fetchAllOrganisationData(currentPage + 1) : fetchUsers(currentPage + 1);
				// }
				if (Number(tab) === 2) {
					fetchPendingConnectionUser(currentPage + 1);
				}

				if (Number(tab) === 3) {
					fetchFollowersData(currentPage + 1);
				}

				if (Number(tab) === 4) {
					fetchFollowingData(currentPage + 1);
				}

				if (Number(tab) === 5) {
					fetchCommunitiesData(currentPage + 1);
				}

				// HIDE-MVP: CM-79
				// if (Number(tab) === 6) {
				// 	fetchOrganisationData(currentPage + 1);
				// }

				if (Number(tab) === 7) {
					fetchReferralData(currentPage + 1);
				}
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));

			if (Number(tab) === 1) {
				fetchConnectionData(Number(page));
			}

			// if (window.location.pathname === '/users') {
			//   Number(userFTab) === 5 ? fetchAllOrganisationData(Number(page)) : fetchUsers(Number(page));
			// }
			if (Number(tab) === 2) {
				fetchPendingConnectionUser(Number(page));
			}

			if (Number(tab) === 3) {
				fetchFollowersData(Number(page));
			}

			if (Number(tab) === 4) {
				fetchFollowingData(Number(page));
			}

			if (Number(tab) === 5) {
				fetchCommunitiesData(Number(page));
			}

			// HIDE-MVP: CM-79
			// if (Number(tab) === 6) {
			// 	fetchOrganisationData(Number(page));
			// }

			if (Number(tab) === 7) {
				fetchReferralData(Number(page));
			}
		}
	};

	useEffect(() => {
		let tabVal =
			type === 'connection'
				? 1
				: type === 'pending-connection'
				? 2
				: type === 'followers'
				? 3
				: type === 'following'
				? 4
				: type === 'community'
				? 5 // HIDE-MVP: CM-71
				: // ? 5
				// : type === 'organisation'
				// 6
				type === 'refferals'
				? 7
				: 1;

		setTab(tabVal); //tabDefaultVal

		Number(tabVal) === 1 && fetchConnectionData(1, true);
		Number(tabVal) === 2 && fetchPendingConnectionUser(1, true);
		Number(tabVal) === 3 && fetchFollowersData(1, true);
		Number(tabVal) === 4 && fetchFollowingData(1, true);
		Number(tabVal) === 5 && fetchCommunitiesData(1, true);
		// HIDE-MVP: CM-79
		// Number(tabVal) === 6 && fetchOrganisationData(1, true);
		Number(tabVal) === 7 && fetchReferralData(1, true);

		// window.location.pathname === '/users' && Number(userFTab) === 5 ? fetchAllOrganisationData(true) : fetchUsers(true);
		setCurrentPage(1);
		setSearchText('');
		// eslint-disable-next-line
	}, [type]);

	// useEffect(() => {
	//   setSearchText('');
	//   // eslint-disable-next-line
	// }, [tab]);

	useEffect(() => {
		if (window.location.pathname !== '/users' && !state) {
			navigate('/news-feed');
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// HIDE-MVP: CM-71
		// window.location.pathname === '/users' && Number(userFTab) === 5 ? fetchAllOrganisationData() : fetchUsers();
		if (window.location.pathname === '/users') {
			fetchUsers();
		}
		// eslint-disable-next-line
	}, [userFTab]);

	// USER FILTER TAB CHANGE HANDLER
	const userFilterTabHandler = (event, newValue) => {
		setUserFtab(newValue);
		setUserPage(1);
	};

	// TAB CHANGE HANDLER
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);

		Number(newValue) === 1 &&
			navigate('/connections/connection', {
				state: {
					USERID: visitorId,
				},
			});
		Number(newValue) === 2 &&
			navigate('/connections/pending-connection', {
				state: {
					USERID: visitorId,
				},
			});
		Number(newValue) === 3 &&
			navigate('/connections/followers', {
				state: {
					USERID: visitorId,
				},
			});
		Number(newValue) === 4 &&
			navigate('/connections/following', {
				state: {
					USERID: visitorId,
				},
			});
		Number(newValue) === 5 &&
			navigate('/connections/community', {
				state: {
					USERID: visitorId,
				},
			});
		// HIDE-MVP: CM-79
		// Number(newValue) === 6 &&
		// 	navigate('/connections/organisation', {
		// 		state: {
		// 			USERID: visitorId,
		// 		},
		// 	});
		Number(newValue) === 7 &&
			navigate('/connections/refferals', {
				state: {
					USERID: visitorId,
				},
			});
	};

	let isOwnerView = Number(visitorId) === Number(ownerID) ? true : false;

	// let visitorTab = [{ id: 1, name: t('myprofile.tab_connections') }];
	let visitorTab = [
		{ id: 1, name: t('myprofile.tab_connections') },
		// { id: 2, name: t('myprofile.connection_request') },
		{ id: 3, name: t('myprofile.folowers') },
		{ id: 4, name: t('myprofile.following') },
		{ id: 5, name: t('myprofile.tab_communities') },
		// HIDE-MVP: CM-73
		// { id: 6, name: t('myprofile.organisations') },
		// { id: 7, name: t('myprofile.tab_refferals') },
	];
	let ownerTab = [
		{ id: 1, name: t('myprofile.tab_connections') },
		{ id: 2, name: t('myprofile.connection_request') },
		{ id: 3, name: t('myprofile.folowers') },
		{ id: 4, name: t('myprofile.following') },
		{ id: 5, name: t('myprofile.tab_communities') },
		// HIDE-MVP: CM-73
		// { id: 6, name: t('myprofile.organisations') },
		{ id: 7, name: t('myprofile.tab_refferals') },
	];
	const tabdata = isOwnerView ? ownerTab : visitorTab;

	return (
		<Page title="Connections | Combat Matrix">
			<Container maxWidth="lg">
				<Box className="combat-container connection-page">
					<Typography className="connection-page-title">{t('connections.title')}</Typography>

					{window.location.pathname === '/users' ? (
						<Box className="connection-user-list">
							<Box className="back-to-profile">
								<Button
									disableElevation
									disableRipple
									disableTouchRipple
									disableFocusRipple
									startIcon={<BackIcon />}
									className="back-text"
									onClick={previousPathHandler}
								>
									{t('connections.backtoprofile')}
								</Button>
							</Box>
							{/* SEARCH FIELD */}

							<form onSubmit={searchButtonClickHandler} className="search-field-box">
								<SearchTextField
									fullWidth
									placeholder={t('search.title')}
									size="small"
									variant="outlined"
									value={searchText}
									onChange={(e) => {
										setSearchText(e.target.value);
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>

								<Button type="submit" className="search-btn">
									{t('search_btn')}
								</Button>
							</form>

							<Box className="select-search-tab user-filters">
								<TabContext value={userFTab}>
									<TabList variant="scrollable" scrollButtons="off" onChange={userFilterTabHandler}>
										{[
											{ name: t('connections.all_users'), value: '1' },
											{ name: t('connections.verified_users'), value: '2' },
											{ name: t('connections.unverified_users'), value: '3' },
											// HIDE-MVP: CM-71
											// { name: 'Shadow Users', value: '4' },
											// { name: 'Organisations', value: '5' },
										]?.map((data, index) => {
											return (
												<Tab
													key={index}
													label={data?.name}
													value={data?.value}
													disableRipple
													disableTouchRipple
													disableFocusRipple
												/>
											);
										})}
									</TabList>
								</TabContext>
							</Box>

							{/* {loader ? (
                [{}, {}, {}]?.map((data, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                      <Skeleton key={index} className="usercard-skeleton" />
                    </Grid>
                  );
                })
              ) :  */}
							<Box id="scrollableDiv" className="infinity-scroll-view-custom">
								{/* HIDE-MVP: CM-71 */}
								{/*{Number(userFTab) === 5 ? (*/}
								{/*	<>*/}
								{/*		{allOrgData && allOrgData?.length > 0 ? (*/}
								{/*			<InfiniteScroll*/}
								{/*				dataLength={allOrgData?.length}*/}
								{/*				next={fetchAllOrganisationData}*/}
								{/*				style={{ width: '100%' }} //To put endMessage and loader to the top.*/}
								{/*				inverse={false}*/}
								{/*				hasMore={hasMore}*/}
								{/*				loader={<h4>{t("loading")}...</h4>}*/}
								{/*				scrollableTarget="scrollableDiv"*/}
								{/*			>*/}
								{/*				<Grid container spacing={1}>*/}
								{/*					{allOrgData?.map((org, index) => {*/}
								{/*						const about = org?.PostLists.map((data, index) =>*/}
								{/*							data?.Item?.type === 'text' ? data?.Item?.data : '',*/}
								{/*						);*/}

								{/*						return (*/}
								{/*							<Grid item xs={12} sm={6} md={4} lg={4} key={index}>*/}
								{/*								<OrgUser*/}
								{/*									profile={org?.item_profile_id}*/}
								{/*									status={org?.status}*/}
								{/*									name={org?.name}*/}
								{/*									orgType={org?.org_type_name}*/}
								{/*									aboutData={about}*/}
								{/*									address={org?.address}*/}
								{/*									orgData={org}*/}
								{/*									orgId={org?.id}*/}
								{/*									editBtn={false}*/}
								{/*								/>*/}
								{/*							</Grid>*/}
								{/*						);*/}
								{/*					})}*/}
								{/*				</Grid>*/}
								{/*			</InfiniteScroll>*/}
								{/*		) : (*/}
								{/*			<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>*/}
								{/*		)}*/}
								{/*	</>*/}
								{/*) : */}
								{usersData && usersData?.length > 0 ? (
									<InfiniteScroll
										dataLength={usersData?.length}
										next={fetchUsers}
										style={{
											width: '100%',
											overflow: 'unset',
										}} //To put endMessage and loader to the top.
										inverse={false}
										hasMore={userHasMore}
										loader={<h4>{t('loading')}...</h4>}
										scrollableTarget={isFullScreenScroll ? undefined : 'scrollableDiv'}
									>
										<Grid container spacing={1}>
											{usersData?.map((userData, index) => {
												return (
													<Grid item xs={12} sm={6} md={6} lg={4} key={index}>
														{userData?.type === 'user_other' ? (
															<UserProfile
																user={userData}
																ownerID={ownerID}
																// fetchUsers={() => fetchUsers()}
																fetchUsers={updateUserData}
																currentPage={currentPage}
															/>
														) : (
															<UserProfileCard
																user={userData}
																// fetchUsers={fetchUsers}
																fetchUsers={updateUserData}
																ownerID={ownerID}
																currentPage={currentPage}
															/>
														)}
													</Grid>
												);
											})}
										</Grid>
									</InfiniteScroll>
								) : (
									<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
								)}
							</Box>

							{/* <CustomPagination
              paginationArray={Number(userFTab) === 5 ? allOrgData : usersData}
              currentPage={currentPage}
              list={list}
              handlePaginationButtonClick={handlePaginationButtonClick}
              handlePaginationClick={handlePaginationClick}
              totalRecord={Number(userFTab) === 5 ? userOrgRecord : userTotalRecord}
            /> */}
						</Box>
					) : (
						<Box className="connection-user-list">
							<TabContext value={String(tab)}>
								{state !== null && (
									<>
										<Box className="back-to-profile">
											<Button
												disableElevation
												disableRipple
												disableTouchRipple
												disableFocusRipple
												startIcon={<BackIcon />}
												className="back-text"
												onClick={previousPathHandler}
											>
												{t('connections.backtoprofile')}
											</Button>
										</Box>

										<Box className="actions-header mb-18">
											<TabList
												variant="scrollable"
												scrollButtons="off"
												onChange={tabChangeHandler}
												aria-label="action tabs"
											>
												{tabdata.map((obj, index) => {
													return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
												})}
											</TabList>
										</Box>
									</>
								)}

								{/* SEARCH FIELD */}

								<form onSubmit={searchButtonClickHandler} className="search-field-box">
									<SearchTextField
										fullWidth
										placeholder={t('search.title')}
										size="small"
										variant="outlined"
										value={searchText}
										onChange={(e) => {
											setSearchText(e.target.value);
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
									/>
									<Button type="submit" className="search-btn">
										{t('search.title')}
									</Button>
								</form>

								<TabPanel value="1" className="p-0">
									{/* <Box className="connection-user-list"> */}
									<Grid container spacing={3} className="connectin-userlist-inner">
										{connectionLoader ? (
											[{}, {}, {}]?.map((data, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<Skeleton key={index} className="usercard-skeleton" />
													</Grid>
												);
											})
										) : connectionData && connectionData?.length > 0 ? (
											connectionData?.map((user, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<UserProfileCard
															user={user}
															fetchConnectionData={fetchConnectionData}
															fetchFollowingData={fetchFollowingData}
															fetchPendingConnectionUser={fetchPendingConnectionUser}
															fetchFollowersData={fetchFollowersData}
															tab={tab}
															// fetchUsers={fetchUsers}
															fetchUsers={updateUserData}
															ownerID={ownerID}
															currentPage={currentPage}
															fetchReferralData={fetchReferralData}
														/>
													</Grid>
												);
											})
										) : (
											<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
										)}
									</Grid>
									<CustomPaginations
										paginationArray={connectionData}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={connectionTotalRecord}
									/>
									{/* </Box> */}
								</TabPanel>
								<TabPanel value="2" className="p-0">
									<Grid container spacing={3} className="connectin-userlist-inner">
										{pendingLoader ? (
											[{}, {}, {}]?.map((data, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<Skeleton key={index} className="usercard-skeleton" />
													</Grid>
												);
											})
										) : pendingConnection && pendingConnection?.length > 0 ? (
											pendingConnection?.map((user, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<UserProfileCard
															user={user}
															isPendingTab={true}
															fetchConnectionData={fetchConnectionData}
															fetchFollowingData={fetchFollowingData}
															fetchPendingConnectionUser={fetchPendingConnectionUser}
															fetchFollowersData={fetchFollowersData}
															tab={tab}
															// fetchUsers={fetchUsers}
															fetchUsers={updateUserData}
															ownerID={ownerID}
															currentPage={currentPage}
															fetchReferralData={fetchReferralData}
														/>
													</Grid>
												);
											})
										) : (
											<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
										)}
									</Grid>
									<CustomPaginations
										paginationArray={pendingConnection}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={pendingTotalRecord}
									/>
								</TabPanel>
								<TabPanel value="3" className="p-0">
									<Grid container spacing={3} className="connectin-userlist-inner">
										{followersLoader ? (
											[{}, {}, {}]?.map((data, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<Skeleton key={index} className="usercard-skeleton" />
													</Grid>
												);
											})
										) : followers && followers?.length > 0 ? (
											followers?.map((user, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<UserProfileCard
															user={user}
															fetchConnectionData={fetchConnectionData}
															fetchFollowingData={fetchFollowingData}
															fetchPendingConnectionUser={fetchPendingConnectionUser}
															fetchFollowersData={fetchFollowersData}
															tab={tab}
															// fetchUsers={fetchUsers}
															fetchUsers={updateUserData}
															ownerID={ownerID}
															currentPage={currentPage}
															fetchReferralData={fetchReferralData}
														/>
													</Grid>
												);
											})
										) : (
											<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
										)}
									</Grid>
									<CustomPaginations
										paginationArray={followers}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={followersTotalRecord}
									/>
								</TabPanel>
								<TabPanel value="4" className="p-0">
									<Grid container spacing={3} className="connectin-userlist-inner">
										{followingLoader ? (
											[{}, {}, {}]?.map((data, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<Skeleton key={index} className="usercard-skeleton" />
													</Grid>
												);
											})
										) : following && following?.length > 0 ? (
											following?.map((user, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														{user?.type === 'user_other' ? (
															<UserProfile
																user={user}
																ownerID={ownerID}
																fetchUsers={() => fetchFollowingData(currentPage)}
																currentPage={currentPage}
															/>
														) : (
															<UserProfileCard
																user={user}
																fetchConnectionData={fetchConnectionData}
																fetchFollowingData={fetchFollowingData}
																fetchPendingConnectionUser={fetchPendingConnectionUser}
																fetchFollowersData={fetchFollowersData}
																tab={tab}
																// fetchUsers={fetchUsers}
																fetchUsers={updateUserData}
																ownerID={ownerID}
																currentPage={currentPage}
																fetchReferralData={fetchReferralData}
															/>
														)}
													</Grid>
												);
											})
										) : (
											<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
										)}
									</Grid>
									<CustomPaginations
										paginationArray={following}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={totalRecord}
									/>
								</TabPanel>
								<TabPanel value="5" className="p-0">
									<Grid container spacing={3} className="connectin-userlist-inner">
										{communityLoader ? (
											[{}, {}, {}]?.map((data, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<Skeleton key={index} className="usercard-skeleton" />
													</Grid>
												);
											})
										) : communityData && communityData?.length > 0 ? (
											communityData?.map((user, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														{/* <UserProfileCard
                            user={user}
                            fetchConnectionData={fetchConnectionData}
                            fetchFollowingData={fetchFollowingData}
                            fetchPendingConnectionUser={
                              fetchPendingConnectionUser
                            }
                            fetchFollowersData={fetchFollowersData}
                            tab={tab}
                            fetchUsers={fetchUsers}
                            ownerID={ownerID}
                            currentPage={currentPage}
                          /> */}
														<CommunityListView
															data={user}
															communityAction={() => {
																console.log();
															}}
															name={user?.name}
															communityimage={user?.item_profile_data}
															createdAt={moment(user?.timestamp_created).format('MMM DD YYYY, h:mm')}
															showButton={false}
														/>
													</Grid>
												);
											})
										) : (
											<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
										)}
									</Grid>
									<CustomPaginations
										paginationArray={communityData}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={commTotalRecord}
									/>
								</TabPanel>
								{/* HIDE-MVP: CM-79 */}
								{/*<TabPanel value="6" className="p-0">*/}
								{/*	<Grid container spacing={3} className="connectin-userlist-inner">*/}
								{/*		{orgLoader ? (*/}
								{/*			[{}, {}, {}]?.map((data, index) => {*/}
								{/*				return (*/}
								{/*					<Grid item xs={12} sm={6} md={4} lg={4} key={index}>*/}
								{/*						<Skeleton key={index} className="usercard-skeleton" />*/}
								{/*					</Grid>*/}
								{/*				);*/}
								{/*			})*/}
								{/*		) : organisationData && organisationData?.length > 0 ? (*/}
								{/*			organisationData?.map((org, index) => {*/}
								{/*				const about = org?.PostLists.map((data, index) =>*/}
								{/*					data?.Item?.type === 'text' ? data?.Item?.data : '',*/}
								{/*				);*/}

								{/*				return (*/}
								{/*					<Grid item xs={12} sm={6} md={4} lg={4} key={index}>*/}
								{/*						<UserOrg*/}
								{/*							profile={org?.item_profile_id}*/}
								{/*							status={org?.status}*/}
								{/*							name={org?.name}*/}
								{/*							orgType={org?.org_type_name}*/}
								{/*							aboutData={about}*/}
								{/*							address={org?.address}*/}
								{/*							orgData={org}*/}
								{/*							orgId={org?.id}*/}
								{/*							// editBtn={false}*/}
								{/*						/>*/}
								{/*					</Grid>*/}
								{/*				);*/}
								{/*			})*/}
								{/*		) : (*/}
								{/*			<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>*/}
								{/*		)}*/}
								{/*	</Grid>*/}
								{/*	<CustomPagination*/}
								{/*		paginationArray={organisationData}*/}
								{/*		currentPage={currentPage}*/}
								{/*		list={list}*/}
								{/*		handlePaginationButtonClick={handlePaginationButtonClick}*/}
								{/*		handlePaginationClick={handlePaginationClick}*/}
								{/*		totalRecord={totalRecord}*/}
								{/*	/>*/}
								{/*</TabPanel>*/}
								<TabPanel value="7" className="p-0">
									{/* <Box className="connection-user-list"> */}
									<Grid container spacing={3} className="connectin-userlist-inner">
										{referralLoader ? (
											[{}, {}, {}]?.map((data, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<Skeleton key={index} className="usercard-skeleton" />
													</Grid>
												);
											})
										) : referralData && referralData?.length > 0 ? (
											referralData?.map((user, index) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
														<UserProfileCard
															user={user}
															fetchConnectionData={fetchConnectionData}
															fetchFollowingData={fetchFollowingData}
															fetchPendingConnectionUser={fetchPendingConnectionUser}
															fetchFollowersData={fetchFollowersData}
															fetchReferralData={fetchReferralData}
															tab={tab}
															// fetchUsers={fetchUsers}
															fetchUsers={updateUserData}
															ownerID={ownerID}
															currentPage={currentPage}
														/>
													</Grid>
												);
											})
										) : (
											<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
										)}
									</Grid>
									<CustomPaginations
										paginationArray={referralData}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={refTotalRecord}
									/>
								</TabPanel>
							</TabContext>
						</Box>
					)}
				</Box>
			</Container>
		</Page>
	);
};
