import { Box, Typography, Grid } from '@material-ui/core';
import { ReactComponent as BadgeBlueIcon } from 'src/assets/images/icons/badge_b_icon.svg';
import './generalbadge.scss';
import { AccountVerification } from './AccountVerification';
import { AccountType } from './AccountType';
import { useTranslation } from 'react-i18next';

export const GeneralBadge = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="generalbadge_block">
				<Typography className="general_title">{t('badge.general_title')}</Typography>

				{/* SIGN UP */}
				<Typography className="general_subtitle">{t('register.signup')}</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6} lg={4}>
						<Box className="badge_block badge_signup">
							<Box className="icon_block">
								<BadgeBlueIcon />
							</Box>
							<Box className="badge_content">
								<Typography className="block_title">nooB</Typography>
								<Typography className="nbsb_title">{t('register.signup')}</Typography>
								<Typography className="nbsb_title achieved_date">Achieved on DD MMM YYYY</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>

				{/* ACCOUNT VERIFICATION */}
				<AccountVerification />

				{/* ACCOUNT TYPE */}
				<AccountType />
			</Box>
		</>
	);
};
