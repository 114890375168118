import { useState, useEffect } from 'react';
import { Avatar, Box, Fade } from '@material-ui/core';

import './create-post.scss';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { fetchFromStorage } from 'src/helpers/context';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useTranslation } from 'react-i18next';
import { TextEditor } from 'src/components/ui';
import { useParams } from 'react-router-dom';

export const CreatePost = ({ loader, token, user, isView, isJoin, fetchCommunityPostList, tab, allUsersList }) => {
	const { t } = useTranslation();
	const { id } = useParams();

	const verificationWall = fetchFromStorage('verificationWall');
	const communityDetails = useSelector((state) => state.Combat.communityDetails);
	const userImage = user?.profile_pic;
	const [createClick, setCreateClick] = useState(false);
	const [flag, setFlag] = useState('');
	const [postFlag, setPostFlag] = useState(false);

	const initialRequestData = {
		receiver_type: 'community',
		community_id: Number(id),
	};

	const createCommunityPost = async (requestParameter) => {
		try {
			const { status, data } = await axiosInstance.post(URLS.createPost, requestParameter);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchCommunityPostList(tabValue);
				setCreateClick(false);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		var postFlag =
			communityDetails?.communityData?.user_access === 'post_create' ||
			communityDetails?.communityData?.user_owner_id === fetchFromStorage('authData')?.user_id
				? true
				: false;

		setPostFlag(postFlag);

		const flag =
			communityDetails?.communityData?.join_status === 'accepted' ||
			communityDetails?.communityData?.user_owner_id === fetchFromStorage('authData')?.user_id
				? true
				: false;

		setFlag(flag);
	}, [communityDetails]);

	return createClick ? (
		<Fade in>
			<Box className="creating-post-box" id="create-post-box">
				<TextEditor
					token={token}
					user={user}
					submit={createCommunityPost}
					selectedItem={''}
					allUsersList={allUsersList}
					initialRequestData={initialRequestData}
				/>
			</Box>
		</Fade>
	) : user ? (
		<Box className="create-post-show-box">
			<Avatar src={userImage} />
			<Box
				onClick={() => {
					communityDetails?.communityData?.status === 'active' &&
						(verificationWall?.isPost
							? postFlag && flag && setCreateClick(true)
							: isJoin
							? setCreateClick(true)
							: setApiMessage('success', 'You must be verified to make this action'));
				}}
				// onClick={() => {
				//   verificationWall?.isPost
				//     ? !isView && setCreateClick(true)
				//     : isJoin
				//       ? setCreateClick(true)
				//       : setApiMessage(
				//         'success',
				//         'You must be verified to make this action'
				//       );
				// }}
				className="create-post-text"
			>
				{t('newsfeed.create_post')}
			</Box>
		</Box>
	) : null;
};
