import { Box, Typography } from '@material-ui/core';
import './specialbadge.scss';
import { JournalistBadge } from './JournalistBadge';
import { SponsorBadge } from './SponsorBadge';
import { useTranslation } from 'react-i18next';

export const SpecialBadge = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="border-block"></Box>
			<Box className="generalbadge_block">
				<Typography className="general_title">{t('badge.special_title')}</Typography>

				{/* JOURNALIST BADGE */}
				<JournalistBadge />

				{/* SPONSOR BADGE */}
				<SponsorBadge />
			</Box>
		</>
	);
};
