export const 	preparePostListForRequest = (allImages, rawHTML, thumbnail) => {
	const postList = allImages.map((item, i) => {
		return {
			type: item?.type,
			item_id: item?.id,
		};
	});
	
	thumbnail && postList.push({type: thumbnail.type, item_id: thumbnail.id ? thumbnail.id : thumbnail.item_id})
	
	postList.push({
		type: 'text',
		data: rawHTML,
	});

	return postList;
};
