import { useRef, useState, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import { fetchFromStorage } from 'src/helpers/context';
import { ViewPortContext } from 'src/App';

import { Footer, useLayoutStyles } from '../components';
import { NavBar } from './NavBar';
import { TopBar } from './TopBar';
import { MobileMenu } from './MobileMenu';

export const MainLayout = () => {
	const classes = useLayoutStyles()();
	const navigate = useNavigate();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	const redux_auth = useSelector((state) => state?.Combat?.authData);
	const statics = useSelector((state) => state?.Combat?.user_statics);
	const user_statics = statics ? statics : fetchFromStorage('user_statics');
	const auth = redux_auth ? redux_auth : fetchFromStorage('authData');
	const verify_count = useSelector((state) => state?.Combat?.user_profile_count);
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const token = fetchFromStorage('authData')?.token;
	// ref, to use the openDialog state from the parent component.
	const topBarRef = useRef();
	// setOpenDialog attri is exposed by the child component TopBar.
	// the dialog window is in the TopBar component, but need to be opened in the NavBar component
	const openDialog = () => {
		topBarRef.current.setOpenDialog();
	};

	const redirectGuestToSignUp = (e) => {
		if (!token) {
			e.stopPropagation();
			e.preventDefault();
			navigate('/');
		}

		return undefined;
	};

	const openMobileMenu = (e) => {
		redirectGuestToSignUp(e);
		setMobileNavOpen(true);
	};

	return (
		<div className={classes.wrapper}>
			<ToastContainer style={{ width: '400px' }} />
			<TopBar
				auth={auth}
				user_statics={user_statics}
				verify_count={verify_count}
				onMobileNavOpen={openMobileMenu}
				onRef={(ref) => (topBarRef.current = ref)}
			>
				{windowWidth <= breakPoint ? <MobileMenu auth={auth} user_statics={user_statics} /> : <></>}
			</TopBar>
			<NavBar
				auth={auth}
				onMobileClose={() => setMobileNavOpen(false)}
				openShareDialog={openDialog}
				openMobile={isMobileNavOpen}
			/>
			<div className={classes.content} onClick={redirectGuestToSignUp}>
				<Outlet />
			</div>
			<Footer auth={auth} user_statics={user_statics} verify_count={verify_count} />
		</div>
	);
};
