import { useState } from 'react';
import {
	// Avatar,
	Box,
	// IconButton,
	// Table,
	// TableContainer,
	// TableHead,
	// TableRow,
	// TableCell,
	// TableBody,
	Typography,
} from '@material-ui/core';
import { DialogBox } from 'src/components/ui';
// import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
// import { ReactComponent as EditIcon } from 'src/assets/images/icons/smallBEdit.svg';
// import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { AddEditDiscipline } from './AddEditDiscipline';
import './discipline.scss';
import { useTranslation } from 'react-i18next';

export const Discipline = ({ token, isView }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [title] = useState(''); //setTitle

	// const combatDialogHandler = (type, title, modal) => {
	//   setOpen(true);
	//   setTitle(title);
	// };

	const closeDialog = () => {
		setOpen(false);
	};

	return (
		<>
			<Box className="discipline-tab">
				<Box className="title-block">
					<Typography className="tab-block-title">{t('myprofile.discipline')}</Typography>

					{/* {isView ? null : (
            <IconButton
              onClick={() => {
                combatDialogHandler(
                  'edit',
                  t('myprofile.add_discipline')
                );
              }}
            >
              <BaddIcon />
            </IconButton>
          )} */}
				</Box>
				{/*
        <Box className="discipline-table-container">
          <TableContainer className={isView ? 'user-discipline' : ''}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="style-cell">Fighting Style</TableCell>
                  <TableCell className="rank-cell">Rank</TableCell>
                  <TableCell className="gym-cell">Granted by/at</TableCell>
                  <TableCell className="approve-cell">Approved by</TableCell>
                  <TableCell className="date-generated-cell">
                    Date granted
                  </TableCell>
                  <TableCell className="endorsement-cell">
                    Endorsements
                  </TableCell>
                  {isView ? null : (
                    <TableCell className="discipline-actions"></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {[{ id: 1 }, { id: 2 }, { id: 3 }].map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="style-cell">
                        [Fighting Style]
                      </TableCell>
                      <TableCell className="rank-cell">[Rank]</TableCell>
                      <TableCell className="gym-cell">[Gym]</TableCell>
                      <TableCell className="approve-cell">
                        [Approved by]
                      </TableCell>
                      <TableCell className="date-generated-cell">
                        [Date granted]
                      </TableCell>
                      <TableCell className="endorsement-cell">
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="center"
                        >
                          <Avatar>8</Avatar>
                        </Box>
                      </TableCell>
                      {isView ? null : (
                        <TableCell className="discipline-actions">
                          <Box className="edit-delete">
                            <IconButton onClick={() => {}}>
                              <EditIcon />
                            </IconButton>

                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box> */}

				<Typography className="cmng-cntr-txt">This feature is coming soon.</Typography>

				{/* No data text */}
				{/* <Typography className="no-info-txt">
          {t('myprofile.no_discipline')}
        </Typography> */}
			</Box>

			<DialogBox open={open} handleClose={closeDialog} title={title} content={<AddEditDiscipline token={token} />} />
		</>
	);
};
