import { useSlate } from 'slate-react';
import { useState } from 'react';
import { Transforms } from 'slate';

import '../../textEditor.scss';

import { insertLink } from '../../utils/editor';

export const AddLinkButton = ({ setState, allUsers }) => {
	const editor = useSlate();

	const [opened, setOpened] = useState(false);
	const [value, setValue] = useState('');

	const chars = allUsers.filter((c) => c.text.toLowerCase().startsWith(value.toLowerCase())).slice(0, 6);

	const openPopup = () => {
		setOpened(true);
	};

	const closePopup = () => {
		setOpened(false);
	};

	const onChange = (e) => {
		setValue(e.currentTarget.value);
	};

	const insertLinkHandler = (char) => {
		const { selection } = editor;

		if (!selection) {
			Transforms.select(editor, { offset: 0, path: [0, 0] });
		}

		insertLink(editor, char.url, `@${char.text}`, false);
		setState(editor);
		closePopup();
	};

	return (
		<div className={'text-editor-toolbar_item-container'}>
			<div onClick={openPopup} className={'text-editor-toolbar_item'}>
				@
			</div>
			{opened && (
				<div className={'text-editor-popup'} onBlur={closePopup}>
					<label>
						Search
						<input
							className={'text-editor-popup_input'}
							type="text"
							style={{ width: '100%', marginTop: '4px' }}
							onChange={onChange}
							value={value}
						/>
					</label>

					{chars.map((char) => (
						<div
							className={'text-editor_mention-item'}
							key={char.url}
							style={{}}
							onClick={() => insertLinkHandler(char)}
						>
							{char.text}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default AddLinkButton;
