import {
	Box,
	IconButton,
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	LinearProgress,
	ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import { ReactComponent as ProfileCorrect } from 'src/assets/images/icons/profile-correct-circle.svg';
import { ReactComponent as ProfileCircle } from 'src/assets/images/icons/profile-circle.svg';

import './profilecompletion.scss';
import { useTranslation } from 'react-i18next';

function LinearProgressWithLabel(props) {
	return (
		<Box display="flex" alignItems="center" className="linear-progress-bar">
			<Box width="100%">
				<LinearProgress variant="determinate" {...props} />
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

export const ProfileCompletion = ({ profileCompletionpage, ProfileCompletionData }) => {
	const { t } = useTranslation();

	return (
		<Box className="left-profile-details-box">
			<Box className="profile-completion-sec">
				<IconButton onClick={() => profileCompletionpage()}>
					<BackIcon />
				</IconButton>
				<Typography className="profile-com-text">{t('myprofile.profile_completion')}</Typography>
			</Box>
			<Box className="progress-bar-sec">
				<Typography className="title-text">{ProfileCompletionData?.total_profile_completion + '%'}</Typography>
				<LinearProgressWithLabel value={ProfileCompletionData?.total_profile_completion} />
			</Box>
			<Box className="profile-details-sec">
				<List className="profile-details">
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.profile_photo ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.profile_photo')} secondary={t('myprofile.profile_photo_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.cover_photo ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.coverphoto')} secondary={t('myprofile.cover_photo_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>{ProfileCompletionData?.add_email ? <ProfileCorrect /> : <ProfileCircle />}</ListItemAvatar>
						<ListItemText primary={t('myprofile.add_email')} secondary={t('myprofile.add_email_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.add_social_link ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.add_social_link')} secondary={t('myprofile.add_social_link_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.complete_profile_information ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.complete_profile')} secondary={t('myprofile.complete_profile_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.add_your_bio ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.add_your_bio')} secondary={t('myprofile.add_your_bio_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.add_other_profile_types ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText
							primary={t('myprofile.add_other_profile_type')}
							secondary={t('myprofile.add_other_profile_type_text')}
						/>
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.add_discipline_ranks ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText
							primary={t('myprofile.add_discipline_rank')}
							secondary={t('myprofile.add_discipline_rank_text')}
						/>
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.endorsement ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.Endorsements')} secondary={t('myprofile.endorsements_text')} />
					</ListItem>
					{/* </List>
                <List className="profile-details"> */}
					<ListItem>
						<ListItemAvatar>
							{ProfileCompletionData?.interation ? <ProfileCorrect /> : <ProfileCircle />}
						</ListItemAvatar>
						<ListItemText primary={t('myprofile.interaction')} secondary={t('myprofile.interaction_text')} />
					</ListItem>
					<List className="interaction-sec">
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.getFollowStatus ? <ProfileCorrect /> : <ProfileCircle />}
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.getFollowCount}/15 `}</Typography>
										{t('myprofile.people_followed')}
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.getJoinCommunitySatus ? <ProfileCorrect /> : <ProfileCircle />}
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.getJoinCommunityCount}/6 `}</Typography>
										{t('myprofile.communities_joined')}
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.getConnectionStatus ? <ProfileCorrect /> : <ProfileCircle />}
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.getConnectionCount}/10 `}</Typography>
										{t('myprofile.connections_made')}
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.getUserCreatePostStatus ? (
									<ProfileCorrect />
								) : (
									<ProfileCircle />
								)}
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.createPostCount}/15 `}</Typography>
										{t('myprofile.posts_made')}
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.endorsements ? <ProfileCorrect /> : <ProfileCircle />}
							</ListItemAvatar>
							<ListItemText primary={t('myprofile.Endorsements')} />
						</ListItem>
						<List className="interaction-sec">
							<ListItem>
								<ListItemAvatar>
									{ProfileCompletionData?.interationData?.eventResultsEndorseStatus ? (
										<ProfileCorrect />
									) : (
										<ProfileCircle />
									)}
								</ListItemAvatar>
								<ListItemText
									primary={
										<>
											<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.getEventResultsCount}/15 `}</Typography>
											{t('myprofile.event_results_endorsed')}
										</>
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemAvatar>
									{ProfileCompletionData?.interationData?.disciplineEndorseStatus ? (
										<ProfileCorrect />
									) : (
										<ProfileCircle />
									)}
								</ListItemAvatar>
								<ListItemText
									primary={
										<>
											<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.getDisciplineEndorseCount}/15 `}</Typography>
											{t('myprofile.disciplines_endorsed')}
										</>
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemAvatar>
									{ProfileCompletionData?.interationData?.skillEndorseStatus ? <ProfileCorrect /> : <ProfileCircle />}
								</ListItemAvatar>
								<ListItemText
									primary={
										<>
											<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.getSkillEndorseCount}/15 `}</Typography>
											{t('myprofile.skills_endorsed')}
										</>
									}
								/>
							</ListItem>
						</List>
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.postUpvoteDownVoteStatus ? (
									<ProfileCorrect />
								) : (
									<ProfileCircle />
								)}
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.postUpvoteDownVoteCount}/70 `}</Typography>
										{t('myprofile.posts_upvoted_or_downvoted')}
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								{ProfileCompletionData?.interationData?.communityPostAwardedStatus ? (
									<ProfileCorrect />
								) : (
									<ProfileCircle />
								)}
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography className="interaction-numbers">{`${ProfileCompletionData?.interationData?.communityPostAwardedCount}/15 `}</Typography>
										{t('myprofile.community_posts_awarded')}
									</>
								}
							/>
						</ListItem>
					</List>
				</List>
			</Box>
		</Box>
	);
};
