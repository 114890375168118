import { Box, Typography } from '@material-ui/core';
import './activity.scss';
import { useNavigate } from 'react-router-dom';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { useTranslation } from 'react-i18next';

export const Activity = ({ type, community, owner, time, text, PostId, ComId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	var path;

	if (PostId !== '') {
		path = `/post/${PostId}`;
	}

	if (ComId !== '') {
		path = `/communities/${ComId}`;
	}

	var isRedirect =
		type === 'post' || type === 'user' || type === 'organisation' || type === 'community' || type === 'comments'
			? true
			: false;

	return (
		<Box
			className="recent-activity-container"
			onClick={() => {
				isRedirect && navigate(`${path}`);
			}}
		>
			<Box className="activity-header">
				<Box className="activity-type-name">
					<Typography className="activity-type-txt">{type === 'comments' ? t('myprofile.comment') : type}</Typography>
					<Typography className="community-name-txt">{community}</Typography>
				</Box>
				<Box className="activity-owner-time">
					<Typography className="activity-owner-time-txt">{owner}</Typography>
					<Typography className="activity-owner-time-txt">{time}</Typography>
				</Box>
			</Box>

			<Typography className="activity-body-txt">
				{text && // USER PROFILE TYPE ABOUT TEXT
					renderHTML(
						anchorme({
							input: text.toString().replace(/\n/g, '<br/>'),
							options: {
								attributes: {
									target: '_blank',
									class: 'detected',
								},
							},
						}),
					)}
			</Typography>
		</Box>
	);
};
